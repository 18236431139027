export interface GovernmentIdentifier {
  type?: GovernmentIdentifierType
  identifier?: string
  issuingState?: string
  expirationDate?: string
}
export enum GovernmentIdentifierType {
  Ssn = "ssn",
  DriversLicense = "drivers_license",
  StateId = "state_id",
  Other = "other"
}
