import { ApiPublicService } from "@/lib/api/public-service"
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from "axios"

import {
  JWTService,
  RequestConfig,
  RefreshTokenPayload,
  RefreshTokenResponse
} from "@/lib/api/types"
import { useLoginServer } from "@/composables/use-login-server"
import router, { ExternalRouteLinks } from "@/router"
import analytics, { logError } from "@/lib/analytics"

export class OrchestratorApiSecureService extends ApiPublicService {
  private readonly jwtService: JWTService

  public constructor(config: JWTService, instance?: AxiosInstance) {
    super(config, instance)

    this.jwtService = config

    this.instance.interceptors.request.use(
      this.addAuthorizationInterceptor.bind(this)
    )
    this.instance.interceptors.response.use(
      undefined,
      this.errorResponseInterceptor.bind(this)
    )
  }

  protected addAuthorizationInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
    if (this.isAuthDisabled(config)) return config

    if (config.headers?.Authorization) return config

    const token = this.jwtService.tokenStorage.getAccessToken()
    if (!token)
      return config

    if(config.headers === undefined) {
      config.headers = {}
    }
    config.headers.Authorization = `Bearer ${token}`
    return config
  }

  protected errorResponseInterceptor(error: AxiosError): Promise<AxiosResponse> {
    const refreshToken = this.jwtService.tokenStorage.getRefreshToken()

    if (!this.shouldRefreshAndRetry(error, refreshToken)) {
      return Promise.reject(error)
    }

    const requestConfig = error.config as RequestConfig
    requestConfig.retrying = true

    return axios
      .post<RefreshTokenPayload, AxiosResponse<RefreshTokenResponse>>(
        this.jwtService.refreshTokenURL,
        {
          client_id: this.jwtService.loginServerClientID,
          grant_type: "refresh_token",
          refresh_token: refreshToken
        }
      )
      .then((response: AxiosResponse<RefreshTokenResponse>) => {
        if (response.status === 200) {
          this.jwtService.tokenStorage.saveTokens(
            response.data.access_token,
            response.data.refresh_token
          )
          //Token refreshed, try request again
          if(requestConfig.headers === undefined) {
            requestConfig.headers = {}
          }
          requestConfig.headers.Authorization = ""
          return Promise.resolve(undefined)
        }
        return Promise.reject(error)
      })
      .catch((error) => {
        if(error.response?.status === 401) {
          // TODO: ACQ-567 runtime error causes "ApiSecureService is not a constructor"
          const { revokeRefreshToken } = useLoginServer()
          revokeRefreshToken(this.jwtService.tokenStorage.getRefreshToken())
          this.jwtService.tokenStorage.expire()
          this.jwtService.seoTrackingStorage.expire()
          analytics.track("auto_logout_due_to_inactivity")
          // TODO: ACQ-567 runtime error causes "ApiSecureService is not a constructor"
          router.push({ name: ExternalRouteLinks.LoginInactivity })
          return Promise.reject(undefined)
        }
        logError(error, error.message)
        return Promise.reject(error)
      })
      .then(() => this.instance.request(requestConfig))
  }

  private shouldRefreshAndRetry(error: AxiosError, refreshToken: string): boolean {
    const requestConfig = error.config as RequestConfig
    if (this.isAuthDisabled(requestConfig)) return false
    if (requestConfig.url === this.jwtService.refreshTokenURL) return false
    if (!error.response || error.response.status !== 401) return false
    if (this.isRetrying(requestConfig)) return false
    return !!refreshToken
  }

  private isAuthDisabled(config = {} as RequestConfig): boolean {
    return config.ignoreAuth != null && config.ignoreAuth
  }

  private isRetrying(config = {} as RequestConfig): boolean {
    return config.retrying != null && config.retrying
  }
}

let singleton : OrchestratorApiSecureService|null
export function setSingleton(s : typeof singleton): void{
  singleton = s
}
