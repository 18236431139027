export interface Address {
  line1?: string
  line2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
  housingType?: HousingTypeEnum
  startDate?: string
}
export enum HousingTypeEnum {
  Own = "own",
  Rent = "rent",
  Other = "other"
}
