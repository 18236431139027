export function customCheckedValidator(args) {
    const message = args?.message ?? "Please check the checkbox.";
    return (value) => {
        if (typeof value !== "boolean") {
            return message;
        }
        if (!value) {
            return message;
        }
        return true;
    };
}
