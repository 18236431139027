import { DirectiveBinding } from "vue/types/options"
export default {
  inserted(el: HTMLElement, binding: DirectiveBinding): void {
    const tooltip = document.createElement("div")
    tooltip.className = "v-tooltip__content menuable__content__active"
    tooltip.id = "lock_tooltip_" + binding.value
    tooltip.style.width = "170px"
    tooltip.style.display = "none"
    tooltip.style.opacity = "0.9"
    tooltip.style.zIndex = "8"
    tooltip.style.backgroundColor = "black"
    tooltip.style.textAlign = "center"
    tooltip.style.marginLeft = "-140px"

    const span = document.createElement("span")
    span.innerHTML =
      "We use AES 256-bit encryption and perform continuous monitoring of our systems to keep your information safe."

    tooltip.appendChild(span)

    const icon = document.createElement("div")
    icon.id = "lock_icon_" + binding.value
    icon.className = "material-icons icon"
    icon.innerHTML =
      "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"15pt\" height=\"19pt\" viewBox=\"0 0 15 19\" version=\"1.1\">" +
      "<g>" +
      "<path style=\" stroke:none;fill-rule:nonzero;fill:rgb(13%,67%,33%);fill-opacity:1;\" d=\"M 7.164062 0.0234375 C 5.660156 0.113281 4.339844 0.761719 3.496094 1.820312 C 2.890625 2.574219 2.515625 3.488281 2.355469 4.589844 C 2.292969 5.019531 2.28125 5.167969 2.277344 5.894531 L 2.269531 6.578125 L 4.507812 6.578125 L 4.507812 6.074219 C 4.507812 5.800781 4.515625 5.492188 4.519531 5.390625 C 4.589844 4.382812 4.867188 3.605469 5.363281 3.042969 C 5.796875 2.550781 6.347656 2.289062 7.101562 2.21875 C 7.265625 2.203125 7.75 2.203125 7.921875 2.21875 C 8.804688 2.296875 9.417969 2.640625 9.871094 3.308594 C 10.140625 3.703125 10.339844 4.253906 10.433594 4.835938 C 10.484375 5.171875 10.488281 5.261719 10.496094 5.925781 L 10.5 6.578125 L 12.746094 6.578125 L 12.746094 6.148438 C 12.746094 5.453125 12.722656 5.046875 12.65625 4.582031 C 12.421875 3 11.671875 1.695312 10.558594 0.921875 C 9.625 0.273438 8.414062 -0.046875 7.164062 0.0234375 Z M 7.164062 0.0234375 \"/>" +
      "<path style=\" stroke:none;fill-rule:nonzero;fill:rgb(13%,67%,33%);fill-opacity:1;\" d=\"M 2.070312 7.328125 C 1.011719 7.414062 0.15625 8.230469 0.0351562 9.273438 C 0.0195312 9.425781 0.0195312 16.894531 0.0351562 17.042969 C 0.148438 18.003906 0.867188 18.765625 1.84375 18.957031 L 1.984375 18.984375 L 13.050781 18.984375 L 13.179688 18.957031 C 13.796875 18.824219 14.289062 18.5 14.628906 17.996094 C 14.765625 17.789062 14.882812 17.515625 14.949219 17.238281 L 14.976562 17.117188 L 14.980469 13.230469 C 14.984375 10.386719 14.980469 9.3125 14.96875 9.234375 C 14.929688 8.914062 14.796875 8.5625 14.617188 8.300781 C 14.226562 7.734375 13.621094 7.386719 12.925781 7.328125 C 12.746094 7.3125 2.257812 7.3125 2.070312 7.328125 Z M 7.753906 10.984375 C 8.296875 11.074219 8.75 11.449219 8.921875 11.949219 C 9.074219 12.394531 9.019531 12.832031 8.761719 13.214844 C 8.679688 13.339844 8.46875 13.550781 8.351562 13.628906 L 8.269531 13.679688 L 8.261719 14.570312 C 8.253906 15.566406 8.257812 15.511719 8.167969 15.6875 C 8.101562 15.820312 7.980469 15.933594 7.84375 16 C 7.710938 16.0625 7.632812 16.078125 7.492188 16.078125 C 7.285156 16.074219 7.109375 15.996094 6.960938 15.847656 C 6.871094 15.753906 6.792969 15.609375 6.769531 15.5 C 6.761719 15.457031 6.757812 15.140625 6.753906 14.558594 L 6.753906 13.683594 L 6.667969 13.628906 C 6.550781 13.554688 6.402344 13.417969 6.304688 13.292969 C 6.167969 13.117188 6.082031 12.921875 6.03125 12.695312 C 6.003906 12.5625 6.003906 12.296875 6.03125 12.15625 C 6.136719 11.628906 6.519531 11.207031 7.035156 11.039062 C 7.253906 10.96875 7.53125 10.945312 7.753906 10.984375 Z M 7.753906 10.984375 \"/>" +
      "</g>" +
      "</svg>"
    icon.setAttribute("v-on", "on")
    icon.style.margin = "14px 0px 0px 0px"

    const element = document.getElementById(binding.value)
    element?.parentElement?.appendChild(icon)

    icon.appendChild(tooltip)

    icon.addEventListener("mouseover", function () {
      tooltip.style.display = "block"
    })

    icon.addEventListener("mouseout", function () {
      tooltip.style.display = "none"
    })
  }
}
