import { ApiSecureService } from "@/lib/api/secure-service"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import { logError } from "@/lib/analytics"
import { CNUApproval } from "generated-cnuapp-types"
interface CreateApprovalBody {
  now: string
}

export async function getApproval(accountId: string, approvalId: string): Promise<CNUApproval> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!

  try {
    return cnuSecureApiService.get<CNUApproval>(`/v4/secure/accounts/${accountId}/approvals/${approvalId}`)
  } catch(err: any) {
    if(err) {
      logError(err)
    }

    return Promise.reject(err)
  }
}

export async function createApproval(accountId: string): Promise<CNUApproval> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!

  try {
    return cnuSecureApiService.post<CreateApprovalBody, CNUApproval>(`/v4/secure/accounts/${accountId}/approvals`, { "now": Date.now().toString() })
  } catch(err) {
    if(err) {
      logError(err)
    }

    return Promise.reject(err)
  }
}
