import { ApiPublicService } from "@/lib/api/public-service"
import { createAccountRequestBody, CreateAccountRequest, AccountResponse } from "@/lib/api/adapters/accounts"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import { buildAccountRequestConfig } from "@/lib/api/public/helpers"
import analytics, { logError } from "@/lib/analytics"

const apiEndpoint = "/v4/public/accounts"

export async function createAccount(recaptchaResult: string): Promise<AccountResponse> {
  const { apiPublicServiceDepreciated } = useApiServicesDepreciated()
  const cnuPublicApiService: ApiPublicService = apiPublicServiceDepreciated.value!

  const requestBody: CreateAccountRequest = createAccountRequestBody(recaptchaResult)

  try {
    const response = await cnuPublicApiService.post<CreateAccountRequest, AccountResponse>(apiEndpoint, requestBody, buildAccountRequestConfig())
    return Promise.resolve(response)
  } catch(e: any) {
    //TODO Use code from JSON returned from an app as well to be more precise that this is a graceful error
    if (e.response?.status == 422) {
      analytics.track("create_account_failed", { errors: e.errors, error_code: e.error_code })
      return Promise.reject(e.response.data)
    }
    logError(e)
    return Promise.reject(e)
  }
}

