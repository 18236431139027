import { EventCallback } from "@/lib/analytics/analytics-types"
import analytics, { logError } from "@/lib/analytics"
import { CONSTANTS } from "analytics"
import useDataLayer from "@/composables/use-data-layer"
import { isRuntimeError } from "@/lib/util/errors"

/*  Background / WHY this exists:
 * Analytics library we are using has a one major oversight. It does not wait for asynchronous
 * operations to be completed so this mechanism in partnership with 'buildAnalyticsCallback' allows
 * all other operations to wait for identity call to complete so user().attributes have valid customer information.
 ** Root cause of this mess: https://github.com/DavidWells/analytics/blob/master/packages/analytics-core/src/index.js#L597
 **** Note how `next()` is called immediately without waiting for the before handler to complete
 */
analytics.on("identifyStart", ({ payload } : EventCallback) => {
  let onIdentityComplete : () => void
  const loaderPromise = new Promise<void>((resolver) => {
    onIdentityComplete = resolver
  })

  // Block all non-identity calls from completing
  const removeListeners = ["trackStart", "pageStart"].map(e => {
    return analytics.on(e, ({ payload } : EventCallback) => {
      payload.thenable = loaderPromise
    })
  })

  const { getAnalyticsDataLayer } = useDataLayer()

  // Pull updated customer information (from analytics service)
  payload.thenable = getAnalyticsDataLayer()
    .then(dataLayer => {
      Object.assign(payload.traits, dataLayer)
      // Identity fires immediately, and it never utilizes mutated payload
      analytics
        .storage
        .setItem(CONSTANTS.USER_TRAITS, { ...analytics.user().traits, ...payload.traits })
      return dataLayer
    })
    .catch(e => {
      /* istanbul ignore if */
      if(isRuntimeError(e)){
        logError(e)
      }
    })
    .then(() => {
      // Unlock all non-identity calls from completing and cancel any non-executed listeners
      onIdentityComplete()
      removeListeners.map(l => l())
    })
})

