import store, { SessionState } from "@/store"
import { newSession } from "@/store"
import { useNamespacedMutations, useNamespacedGetters } from "vuex-composition-helpers"
import { computed, ComputedRef, WritableComputedRef } from "vue"
import { CreateSeoTrackingResponse } from "@/lib/api/adapters/seo-tracking"
import { createSeoTracking } from "@/lib/api/public/create-seo-tracking-service"
import CookieSeoTrackingStorage from "@/lib/api/cookie-seo-tracking-storage"
import CookieJWTStorage from "@/lib/api/cookie-jwt-storage"
import { getFingerprint } from  "@/lib/iovation-fraud-force"
import { v4 as uuidv4 } from "uuid"
import analytics, { logError } from "@/lib/analytics"
import { isRuntimeError } from "@/lib/util/errors"
import { createOrchestratorFlow, OrchestratorResponse } from "@/lib/api/secure/orchestrator-service"

interface SessionStoreAccessors {
  accountId: WritableComputedRef<string>,
  isLoggedIn: ComputedRef<boolean>,
  sessionId: ComputedRef<string>,
  stateCd: WritableComputedRef<string|undefined>,
  iovationFraudForceFingerprint: WritableComputedRef<string|undefined>,
  campaign: WritableComputedRef<string|undefined>,
  gclid: WritableComputedRef<string|undefined>,
  medium: WritableComputedRef<string|undefined>,
  network: WritableComputedRef<string|undefined>,
  adposition: WritableComputedRef<string|undefined>,
  clickId: WritableComputedRef<string|undefined>,
  device: WritableComputedRef<string|undefined>,
  keyword: WritableComputedRef<string|undefined>,
  locPhysicalMs: WritableComputedRef<string|undefined>,
  matchType: WritableComputedRef<string|undefined>,
  orderItemId: WritableComputedRef<string|undefined>,
  trkid: WritableComputedRef<string|undefined>,
  trafficSource: WritableComputedRef<string|undefined>,
  groupCd: WritableComputedRef<string|undefined>,
  sourceTypeCd: WritableComputedRef<string|undefined>,
  orchestratorFlow: ComputedRef<string | undefined>,
  leadUUID: WritableComputedRef<string | undefined>,
  leadOfferId: WritableComputedRef<string | undefined>,
  scheduleOfferId: WritableComputedRef<number | undefined>,
  checkSeoTrackingCookie(): Promise<void>,
  tryFetchIovationFraudForceFingerprint(): Promise<void>,
  fetchOrchestratorFlow: (flowName: string, context: any) => Promise<OrchestratorResponse<void>>,
  clearOrchestratorFlow(): void,
  shouldCreateFlow(channel: string, query: Record<string, unknown>) : boolean,
  resetState(): void,
  orchestratorRoute: WritableComputedRef<string>
}

function useSessionStore(): SessionStoreAccessors {
  const { keys, getField } = useNamespacedGetters(store, "session", [
    "keys",
    "getField"
  ])

  const { updateField } = useNamespacedMutations(store, "session", ["updateField"])

  const mapFields: {
    [k in keyof SessionState]: WritableComputedRef<SessionState[k]>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = {} as any

  (keys?.value as (keyof SessionState)[]).forEach((key) => {
    mapFields[key] = computed({
      get: () => getField.value(key),
      set: (value: unknown) => { updateField({ path: key, value: value }) }
    })
  })

  const accountId: WritableComputedRef<string> = computed({
    get: () => getField.value("accountId"),
    set: (value: string) => {
      updateField({ path: "accountId", value: value })
    }
  })

  const orchestratorFlow: WritableComputedRef<string> = computed({
    get: () => getField.value("orchestratorFlow"),
    set: (value: string) => {
      updateField({ path: "orchestratorFlow", value: value })
    }
  })

  const isLoggedIn: ComputedRef<boolean> = computed(() => {
    return accountId.value.length > 0
  })

  const sessionId: ComputedRef<string> = computed(() => {
    const id = getField.value("sessionId").value ?? ""
    if(id.length === 0) {
      const sessionId = uuidv4()
      updateField({ path: "sessionId", value: sessionId })
      return sessionId
    }
    return id
  })

  async function checkSeoTrackingCookie(): Promise<void> {
    if (CookieSeoTrackingStorage.getSeoTrackingId() === null) {
      CookieSeoTrackingStorage.expire()
    }

    if (CookieSeoTrackingStorage.getSeoTrackingCookie() === "") {
      try {
        const response: CreateSeoTrackingResponse = await createSeoTracking()
        CookieSeoTrackingStorage.saveCookie({ seo_tracking_id: response.seo_tracking_id, tracking_complete: false })
      } catch(error: any) {
        /* istanbul ignore if */
        if(isRuntimeError(error)){
          logError(error)
        }
        return Promise.reject("Error creating SEO Tracking Cookie")
      }
    }
    return Promise.resolve(undefined)
  }

  async function tryFetchIovationFraudForceFingerprint(): Promise<void> {
    let result = undefined
    try {
      result = await getFingerprint()
    } catch(e: any){
      analytics.track("FAILED_TO_OBTAIN_IOVATION_FINGERPRINT", { error_message: e.message })
      /* istanbul ignore if */
      if(isRuntimeError(e)){
        logError(e)
      }
    }
    if(result){
      updateField({ path: "iovationFraudForceFingerprint", value: result })
    }
    return Promise.resolve(undefined)
  }

  const fetchOrchestratorFlow = async (channel: string, context: any) => {
    let flowResponse : OrchestratorResponse<void>
    try {
      flowResponse = await createOrchestratorFlow<void>(channel, context)
    }
    catch (error: any) {
      return Promise.reject(error)
    }
    if (flowResponse.errors.length == 0) {
      orchestratorFlow.value = flowResponse.flowUUID
    }
    return Promise.resolve(flowResponse)
  }

  const clearOrchestratorFlow = () => {
    orchestratorFlow.value = ""
  }

  const shouldCreateFlow = (channel: string, query: Record<string, unknown>) : boolean => {
    const flowName = `orchestrator_${ channel }_flow`
    const forceOptyOverride : string = query[`force-opty-${ flowName }`] as string

    const { orchestratorFlow } = useSessionStore()
    if (orchestratorFlow.value !== "") {
      return false
    }

    if (process.env.VUE_APP_ENVIRONMENT !== "production" && forceOptyOverride !== undefined) {
      return forceOptyOverride == "true"
    } else {
      const featureEnabled =  analytics.plugins.optimizely.isFeatureEnabled(flowName)
      const cohort = analytics.plugins.optimizely.activate(`${ flowName }_ab_test`)
      if (featureEnabled && cohort == "enabled") {
        return true
      }
      return false
    }
  }

  const resetState = () => {
    CookieJWTStorage.expire()
    CookieSeoTrackingStorage.expire()
    const newState = newSession()
    Object.keys(newState).forEach((key) => {
      updateField({ path: key, value: newState[key as keyof SessionState] })
    })
  }

  const leadUUID: WritableComputedRef<string> = computed({
    get: () => getField.value("leadUUID"),
    set: (value: string) => {
      updateField({ path: "leadUUID", value: value })
    }
  })
  const leadOfferId: WritableComputedRef<string> = computed({
    get: () => getField.value("leadOfferId"),
    set: (value: string) => {
      updateField({ path: "leadOfferId", value: value })
    }
  })

  const scheduleOfferId: WritableComputedRef<number> = computed({
    get: () => getField.value("scheduleOfferId"),
    set: (value: number) => {
      updateField({ path: "scheduleOfferId", value: value })
    }
  })

  const orchestratorRoute: WritableComputedRef<string> = computed({
    get: () => getField.value("orchestratorRoute"),
    set: (value: string) => {
      updateField({ path: "orchestratorRoute", value: value })
    }
  })

  return {
    ...mapFields,
    accountId,
    isLoggedIn,
    sessionId,
    checkSeoTrackingCookie,
    tryFetchIovationFraudForceFingerprint,
    orchestratorFlow,
    fetchOrchestratorFlow,
    clearOrchestratorFlow,
    shouldCreateFlow,
    resetState,
    leadUUID,
    leadOfferId,
    scheduleOfferId,
    orchestratorRoute
  } as SessionStoreAccessors
}

export { useSessionStore, SessionStoreAccessors }
