import { GetterTree } from "vuex"
import { RootState } from "@/store"
import { OffersState } from "@/store/offers"

interface OffersGetters extends GetterTree<OffersState, RootState> {
  keys(state:OffersState): Array<string>
}
const offersGetters: OffersGetters = {
  keys(state: OffersState): Array<string> {
    return Object.keys(state)
  }
}

export { OffersGetters, offersGetters }
