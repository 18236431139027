import { ApiSecureService } from "@/lib/api/secure-service"
import { useSessionStore } from "@/store"

import { MultiOfferResponse } from "generated-cnuapp-types"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import { logError } from "@/lib/analytics"

const url = "/v4/secure/accounts"
interface CreateOfferBody {
  now: string
}
export async function createOffers(): Promise<MultiOfferResponse> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!

  const { accountId } = useSessionStore()

  if (!accountId.value) {
    logError("getOffers attempted for non-logged in user")
    return Promise.reject("user not logged in")
  }

  const urlWithId = `${url}/${accountId.value}/offers`
  try {
    const response = await cnuSecureApiService.post<CreateOfferBody, MultiOfferResponse>(urlWithId, { "now": Date.now().toString() })
    return Promise.resolve(response)
  }
  catch (e: any) {
    logError(`Offers API Error: ${e}`)
    return Promise.reject(e)
  }
}
