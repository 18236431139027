import { AnalyticsPlugin } from "analytics"
import { Config, EventCallback } from "@/lib/analytics/analytics-types"
import { buildAnalyticsCallback } from "@/lib/analytics/helpers"


declare let window: { s: unknown, analyticsDataLayer: Record<string, unknown> } & Window

const adobeLaunchURL = process.env.VUE_APP_ADOBE_LAUNCH_URL || ""
const impl = {
  initialize: () => {
    const adobeLaunch = document.createElement("script")
    adobeLaunch.setAttribute("src", adobeLaunchURL)
    document.head.prepend(adobeLaunch)
  },

  identify: buildAnalyticsCallback(({ payload }: EventCallback) => {
    if (payload.traits){
      window.analyticsDataLayer = payload.traits
    } else if(!window.analyticsDataLayer) {
      /* optimizely web / adobe inject custom JS code which assumes this variable is always defined on window */
      window.analyticsDataLayer = {}
    }
  }),
  track: buildAnalyticsCallback(({ payload, instance }: EventCallback) => {
    /* optimizely web / adobe inject custom JS code which assumes this variable is always defined on window */
    window.analyticsDataLayer = instance.user().traits
    document
      .body
      .dispatchEvent(
        new CustomEvent(payload.event, { bubbles: true, composed: true, detail: payload.properties })
      )
  }),
  loaded: () => {
    return !!window.s
  }
}

export default function buildAdobePlugin(config: Config): AnalyticsPlugin & typeof impl {
  if (config.enabled && !adobeLaunchURL) {
    console.error("adobe not configured")
    config.enabled = false
  }
  return {
    name: "adobe",
    config: { ...config },
    ...impl
  }
}

