import { ContractData } from "generated-cnuapp-types"
import { Address } from "@/lib/models"

export interface RequestFundsInput {
  draw_amount: number
}

export interface AgreementInput {
  agreement_checked: boolean
  ssn_last4?: string
  spouseInfo?: SpouseInfo
}

export interface SignAgreementInput extends AgreementInput {
  ssn_last4: string
}

export type SpouseInfo = SpouseInfoWithSpouse | SpouseInfoWithoutSpouse;

interface SpouseInfoWithSpouse {
  maritalStatus: "married" | "legally separated";
  firstName: string;
  lastName: string;
  address?: Address;
}

interface SpouseInfoWithoutSpouse {
  maritalStatus: "single" | "divorced";
}

export interface FlexLoanAgreement {
  flex_loan_acknowledgement_completed: boolean
  has_existing_flex_loan: string
}

export interface ContractInput {
  request_funds:          RequestFundsInput;
  sign_agreement:         SignAgreementInput;
  disclosure_statement?:  AgreementInput;
  cab_agreement?:         AgreementInput;
  submitting:             boolean;
  flex_loan_agreement:    FlexLoanAgreement
}

export interface ContractAlert {
  show:    boolean,
  type:    string,
  message: string
}

export interface ContractErrors {
  alert:            ContractAlert,
  page_error_count: number
}


interface ContractGuards {
  last_successful_submit_id: string
}

interface ContractState {
  contractData?:           ContractData,
  customerInput:           ContractInput,
  contractErrors:          ContractErrors,
  contractGuards:          ContractGuards,
  viewedRbpPopup:          boolean,
  selectedRepaymentMethod: boolean,
}

const newContractStore = function (): ContractState {
  return {
    contractData:            undefined,
    customerInput:           defaultSignature,
    contractErrors:          defaultContractErrors,
    contractGuards:          defaultContractGuards,
    viewedRbpPopup:          false,
    selectedRepaymentMethod: false
  }
}

const defaultContractData: ContractData = {  }
const defaultSignature: ContractInput = {
  request_funds: { draw_amount: 0 },
  sign_agreement: {
    agreement_checked: false,
    ssn_last4: ""
  },
  submitting: false,
  flex_loan_agreement: {
    flex_loan_acknowledgement_completed: false,
    has_existing_flex_loan: "No"
  }
}
const defaultAlert: ContractAlert = {
  show: false,
  type: "success",
  message: ""
}

const defaultContractErrors: ContractErrors = {
  alert: defaultAlert,
  page_error_count: 0
}

const defaultContractGuards: ContractGuards = {
  last_successful_submit_id: ""
}

const contractState: ContractState = newContractStore()

export { ContractState, contractState, defaultContractData, defaultSignature, defaultContractErrors, newContractStore }
