import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import * as publicService from "@/lib/api/public-service"
import * as secureService from "@/lib/api/secure-service"
import * as internalService from "@/lib/api/internal-service"
import * as orchestratorPublicService from "@/lib/api/orchestrator-public-service"
import * as orchestratorSecureService from "@/lib/api/orchestrator-secure-service"
import { ApiInternalService } from "@/lib/api/internal-service"
import { defaultCnuConfig, baseCnuApiURL, defaultOrchestratorConfig } from "@/plugins/axios-config"

const { initializeServices, apiPublicServiceDepreciated, apiSecureServiceDepreciated, orchestratorApiPublicServiceDepreciated, orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()

initializeServices(defaultCnuConfig, defaultOrchestratorConfig)
publicService.setSingleton(apiPublicServiceDepreciated.value)
secureService.setSingleton(apiSecureServiceDepreciated.value)
orchestratorPublicService.setSingleton(orchestratorApiPublicServiceDepreciated.value)
orchestratorSecureService.setSingleton(orchestratorApiSecureServiceDepreciated.value)

const internalConfig = Object.assign({}, defaultCnuConfig, { baseURL: baseCnuApiURL.replace("://api-v4.", "://api.") })
internalService.setSingletons({ v1: new ApiInternalService(internalConfig) })

