import { Module } from "vuex"
import { RootState } from "@/store"
import { offersState, OffersState } from "./state"
import { updateField, getField } from "vuex-map-fields"
import { offersGetters } from "./getters"

const offersModule: Module<OffersState, RootState> = {
  namespaced: true,
  state: offersState,
  getters: { ...offersGetters, getField },
  mutations: { updateField }
}

export default offersModule
export * from "./state"
export * from "./getters"
export * from "./composables"
