import { JWTService } from "@/lib/api/types"
import CookieJWTStorage from "@/lib/api/cookie-jwt-storage"
import CookieSeoTrackingStorage from "@/lib/api/cookie-seo-tracking-storage"

export const baseCnuApiURL = process.env.VUE_APP_CNU_API_BASE_URL || ""
export const baseOrchestratorApiURL = process.env.VUE_APP_ORCHESTRATOR_API_BASE_URL || ""
const clientID = process.env.VUE_APP_CLIENT_ID || ""
const loginServerClientID = process.env.VUE_APP_CNU_CLIENT_ID || ""
const refreshTokenURL = process.env.VUE_APP_TOKEN_REFRESH_URL || ""

export const defaultCnuConfig: JWTService = {
  baseURL: baseCnuApiURL,
  headers: {
    ClientID: clientID,
    "X-APP-NAME": "web-acquisitions"
  },
  loginServerClientID: loginServerClientID,
  refreshTokenURL: refreshTokenURL,
  tokenStorage: CookieJWTStorage,
  seoTrackingStorage: CookieSeoTrackingStorage
}

export const defaultOrchestratorConfig: JWTService = {
  baseURL: baseOrchestratorApiURL,
  headers: {
    ClientID: clientID,
    "X-APP-NAME": "web-acquisitions"
  },
  loginServerClientID: loginServerClientID,
  refreshTokenURL: refreshTokenURL,
  tokenStorage: CookieJWTStorage,
  seoTrackingStorage: CookieSeoTrackingStorage
}