import moment from "moment";
export function customDateValidator(args) {
    const tooEarlyMessage = args?.tooEarlyMessage ?? args?.message ?? "Please enter a later date.";
    const tooLateMessage = args?.tooLateMessage ?? args?.message ?? "Please enter an earlier date.";
    const dateInvalidMessage = args?.dateInvalidMessage ?? args?.message ?? "Please enter a valid date.";
    const format = args?.format ?? "MM/DD/YYYY";
    return (value) => {
        if (typeof value !== "string") {
            return dateInvalidMessage;
        }
        const date = moment(value, format, true);
        if (!date.isValid()) {
            return dateInvalidMessage;
        }
        if (args?.minDate) {
            const minDate = moment(args.minDate, format, true);
            if (date.isBefore(minDate)) {
                return tooEarlyMessage;
            }
        }
        if (args?.maxDate) {
            const maxDate = moment(args.maxDate, format, true);
            if (date.isAfter(maxDate)) {
                return tooLateMessage;
            }
        }
        return true;
    };
}
