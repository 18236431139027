export interface HandleLeadsInterstitialPageSubmissionRequest {
  lead_uuid: string
  lead_offer_id: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HandleLeadsInterstitialPageSubmissionResponse extends Record<string, unknown> {}

export function mapStoreToLeadsInterstitialRequest(leadUUID: string, leadOfferId: string): HandleLeadsInterstitialPageSubmissionRequest {
  return {
    lead_uuid: leadUUID,
    lead_offer_id: leadOfferId
  }
}

export default {}
