export function customRequiredValidator(args) {
    const message = args?.message ?? "This field is required.";
    return (value) => {
        if (typeof value === "string" && value.trim().length === 0) {
            return message;
        }
        if (value === undefined || value === null) {
            return message;
        }
        return true;
    };
}
