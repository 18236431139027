import { GetterTree } from "vuex"
import { RootState } from "@/store"
import { ContractAlert, ContractErrors, ContractInput, ContractState } from "./state"
import { CreateLoanRequest } from "generated-cnuapp-types"
import { useSessionStore } from "@/store"
import { ContractData } from "generated-cnuapp-types"
import { SpouseMaritalStatusEnum } from "generated-cnuapp-types"


export type ContractGetters = {
  contractData(state: ContractState): ContractData | undefined,
  customerInput(state: ContractState): ContractInput,
  submissionData(state: ContractState): CreateLoanRequest,
  contractErrors(state: ContractState): ContractErrors
  contractAlert(state: ContractState): ContractAlert
  contractSubmitting(state: ContractState): boolean
  lastSuccessfulSubmitId(state: ContractState): string
  preSignatureSectionsCompleted(state: ContractState): boolean
  flexLoanAcknolwedgementCompleted(state: ContractState): boolean
  hasExistingFlexLoan(state: ContractState): string
  viewedRbpPopup(state: ContractState): boolean
  selectedRepaymentMethod(state: ContractState): boolean
}

export const getters: GetterTree<ContractState, RootState> & ContractGetters = {
  contractData: (state) => {
    return state.contractData
  },
  customerInput: (state) => {
    return state.customerInput
  },
  submissionData: (state) => {
    const output: CreateLoanRequest = {
      "black_box": useSessionStore().iovationFraudForceFingerprint.value,
      "draw_amount": state.customerInput.request_funds.draw_amount,
      "signature": {
        "last4": state.customerInput.sign_agreement.ssn_last4
      },
      "has_existing_flex_loan": state.customerInput.flex_loan_agreement.has_existing_flex_loan
    }

    if(useSessionStore().scheduleOfferId.value && state.customerInput.request_funds.draw_amount) {
      output.schedule_offer_id = useSessionStore().scheduleOfferId.value
    }
    const spouseInfo = state.customerInput.sign_agreement.spouseInfo
    if(spouseInfo?.maritalStatus === "married" || spouseInfo?.maritalStatus === "legally separated") {
      output.signature!.spouse = {
        "marital_status": spouseInfo.maritalStatus == "married" ? SpouseMaritalStatusEnum.Married : SpouseMaritalStatusEnum.Separated,
        "spouse_name": {
          first: spouseInfo.firstName,
          last: spouseInfo.lastName
        },
        "address": spouseInfo.address
      }
    } else if(spouseInfo?.maritalStatus === "single" || spouseInfo?.maritalStatus === "divorced") {
      output.signature!.spouse = {
        "marital_status": spouseInfo.maritalStatus == "single" ? SpouseMaritalStatusEnum.Single : SpouseMaritalStatusEnum.Divorced
      }
    }
    return output
  },
  contractErrors: (state) => {
    return state.contractErrors
  },
  contractAlert: (state) => {
    return state.contractErrors.alert
  },
  contractSubmitting: (state) => {
    return state.customerInput.submitting
  },
  lastSuccessfulSubmitId: (state) => {
    return state.contractGuards.last_successful_submit_id
  },
  flexLoanAcknolwedgementCompleted: (state) => {
    return state.customerInput.flex_loan_agreement.flex_loan_acknowledgement_completed
  },
  hasExistingFlexLoan: (state) => {
    return state.customerInput.flex_loan_agreement.has_existing_flex_loan
  },
  viewedRbpPopup: (state) => {
    return state.viewedRbpPopup
  },
  selectedRepaymentMethod: (state) => {
    return state.selectedRepaymentMethod
  }
}

