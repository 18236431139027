import { RequestConfig } from "@/lib/api/types/request-config"
import { useRegistrationStore } from "@/store"

export function buildAccountRequestConfig() : RequestConfig{
  const { flowName } = useRegistrationStore()
  const fallbackIntentName = "unknown"
  return {
    headers: { "X-Intent-Name": (flowName?.value != "" && flowName?.value != undefined) ? flowName?.value : fallbackIntentName }
  }
}

