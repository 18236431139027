import { ApiPublicService } from "@/lib/api/public-service"
export class ApiInternalService extends ApiPublicService {}

interface Singletons { v1: ApiInternalService | null }
const singletons : Singletons = {
  v1: null
}
export function setSingletons(s : Singletons): void {
  Object.assign(singletons, s)
}

export function getSingletons(): Singletons{
  return singletons
}
