import { ApiPublicService } from "@/lib/api/public-service"
import { useSessionStore, useRegistrationStore } from "@/store"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import { logError } from "@/lib/analytics"

export type DataLayer = {
  getAnalyticsDataLayer(): Promise<Record<string, unknown>>
}

export default function (): DataLayer {
  const getAnalyticsDataLayer = async (): Promise<Record<string, unknown>> => {
    const { apiSecureServiceDepreciated, apiPublicServiceDepreciated } = useApiServicesDepreciated()
    const { accountId, isLoggedIn } = useSessionStore()
    const { flowName } = useRegistrationStore()

    let cnuService : ApiPublicService | null
    let url: string

    const params = new URLSearchParams()
    params.set("user_agent", window.navigator.userAgent)

    if(isLoggedIn.value) {
      cnuService = apiSecureServiceDepreciated.value
      url = `/v4/secure/accounts/${accountId.value}/site_catalyst?${params.toString()}`
    } else {
      cnuService = apiPublicServiceDepreciated.value
      url = `/v4/public/site_catalyst?${params.toString()}`
    }

    let response = undefined
    try {
      response = await cnuService!.get<Record<string, unknown>>(url)
    } catch(e: any) {
      // Sometimes users visit page directly with expired tokens
      /* istanbul ignore if */
      if (e?.response?.status === 401) {
        return Promise.reject(e)
      }
      if(e)
        logError(e)
      return Promise.reject(e)
    }
    if(response)
      response["flowName"] = flowName.value
    return Promise.resolve(response)
  }

  return {
    getAnalyticsDataLayer
  }
}
