export enum eventNames {
  FATAL_ERROR = "FATAL_ERROR",
  WARN_ERROR = "WARN_ERROR",
  INFO = "INFO"
}

// TODO Consider moving all event names used in track calls as string enums and reference them
// from places where we use them.

export const AnalyticsEventNames = new Set()
export const ErrorEventNames = new Set<string>([
  eventNames.FATAL_ERROR,
  eventNames.WARN_ERROR,
  eventNames.INFO
])
