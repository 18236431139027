import store, { ContractGetters, MutationTypes } from "@/store"
import { computed, Ref, ComputedRef } from "vue"
import { ContractData } from "generated-cnuapp-types"
import { ContractAlert, ContractErrors, ContractInput } from "@/store/contract/state"
import { ContractMutations } from "@/store/contract/mutations"
import { CreateLoanRequest } from "generated-cnuapp-types"
import { LoanCreateResponse } from "generated-cnuapp-types"

interface ContractStoreAccessors {
  contractStoreData: Ref<ContractData|undefined>,
  customerInput: Ref<ContractInput|undefined>,
  contractErrors: Ref<ContractErrors>,
  contractAlert: Ref<ContractAlert>,
  submissionFormData: ComputedRef<CreateLoanRequest>,
  loadContractData(): Promise<void>,
  resetState(): void,
  submitContract: () => Promise<LoanCreateResponse>,
  switchRepaymentMethod: (newRepaymentMethod: string) => Promise<void>,
  submitting: Ref<boolean>,
  lastSuccessfulSubmitId: Ref<string>,
  flexLoanAcknolwedgementCompleted: Ref<boolean>,
  hasExistingFlexLoan: Ref<string>,
  viewedRbpPopup: Ref<boolean>,
  selectedRepaymentMethod: Ref<boolean>,
}

const useContractStore = function (): ContractStoreAccessors {
  const get = (key: keyof ContractGetters) => store.getters[`contract/${key}`]
  const mutate = <K extends keyof ContractMutations>(key: K, value: Parameters<ContractMutations[K]>[1]) => store.commit(`contract/${key}`, value)

  const contractStoreData: Ref<ContractData|undefined> = computed({
    get: () => get("contractData"),
    set: (value: ContractData) => mutate(MutationTypes.SET_CONTRACT_DATA, value)
  })

  const customerInput: Ref<ContractInput|undefined> = computed({
    get: () => get("customerInput"),
    set: (value: ContractInput) => mutate(MutationTypes.SET_CUSTOMER_INPUT, value)
  })

  const contractErrors: Ref<ContractErrors> = computed({
    get: () => get("contractErrors"),
    set: (value: ContractErrors) => mutate(MutationTypes.SET_CONTRACT_ERRORS, value)
  })

  const contractAlert: Ref<ContractAlert> = computed({
    get: () => get("contractAlert"),
    set: (value: ContractAlert) => mutate(MutationTypes.SET_CONTRACT_ALERT, value)
  })

  const submissionFormData: ComputedRef<CreateLoanRequest|undefined> = computed({
    get: () => get("submissionData"),
    set: (value?: CreateLoanRequest) => (value) // Does nothing
  })

  const submitting: ComputedRef<boolean> = computed({
    get: () => get("contractSubmitting"),
    set: (value: boolean) => mutate(MutationTypes.SET_SUBMIT_IN_PROGRESS, value)
  })

  const lastSuccessfulSubmitId: ComputedRef<string> = computed({
    get: () => get("lastSuccessfulSubmitId"),
    set: (value: string) => mutate(MutationTypes.SET_LAST_SUCCESSFUL_SUBMIT_ID, value)
  })

  const flexLoanAcknolwedgementCompleted: ComputedRef<boolean> = computed({
    get: () => get("flexLoanAcknolwedgementCompleted"),
    set: (value: boolean) => mutate(MutationTypes.SET_FLEX_LOAN_ACKNOWLEDGEMENT_COMPLETED, value)
  })

  const hasExistingFlexLoan: ComputedRef<string> = computed({
    get: () => get("hasExistingFlexLoan"),
    set: (value: string) => mutate(MutationTypes.HAS_EXISTING_FLEX_LOAN, value)
  })

  const viewedRbpPopup: Ref<boolean> = computed({
    get: () => get("viewedRbpPopup"),
    set: (payload: boolean) => mutate(MutationTypes.SET_VIEWED_RPB_POPUP, payload)
  })

  const selectedRepaymentMethod: Ref<boolean> = computed({
    get: () => get("selectedRepaymentMethod"),
    set: (payload: boolean) => {
      mutate(MutationTypes.SET_SELECTED_REPAYMENT_METHOD, payload)
    }
  })

  const loadContractData = async() => {
    return await store.dispatch("contract/populateContractData")
  }

  const resetState = async () => {
    return await store.dispatch("contract/clearContractData")
  }

  async function submitContract(): Promise<LoanCreateResponse> {
    return await store.dispatch("contract/submitContract")
  }

  async function switchRepaymentMethod(newRepaymentMethod: string): Promise<void> {
    return await store.dispatch("contract/switchRepaymentMethod", newRepaymentMethod)
  }

  return {
    contractStoreData,
    customerInput,
    contractErrors,
    contractAlert,
    submissionFormData,
    loadContractData,
    resetState,
    submitContract,
    switchRepaymentMethod,
    submitting,
    lastSuccessfulSubmitId,
    viewedRbpPopup,
    flexLoanAcknolwedgementCompleted,
    hasExistingFlexLoan,
    selectedRepaymentMethod
  } as ContractStoreAccessors
}

export { useContractStore, ContractStoreAccessors }
