import { Registration, FlowActions } from "@/lib/models"

const newRegistration = function (): Registration {
  return {
    addressLine1: "",
    addressLine2: "",
    city: "",
    contactPreferences: false,
    dateOfBirth: "",
    emailAddress: "",
    firstName: "",
    lastName: "",
    password: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
    ssn: "",
    stateCode: "",
    timeAtResidence: undefined,
    zipCode: "",
    licenseNumber: "",
    licenseIssuingStateCode: "",
    licenseExpirationDate: "",
    employmentStatus: undefined,
    employerCompanyName: "",
    workPosition: "",
    employerIndustry: undefined,
    timeAtJob: undefined,
    workPhone: "",
    followingPaydate: "",
    grossPayPerCheck: "",
    hourlyOrSalaried: undefined,
    netPayPerCheck: "",
    nextPaydate: "",
    paymentFrequency: undefined,
    wagePayment: undefined,
    bankAccountNumber: "",
    bankRoutingNumber: "",
    creditReportConsent: undefined,
    formSections: [],
    flowName: "organic",
    flowAction: FlowActions.Continue,
    flowButtonText: "",
    offerCode: undefined,
    lastFourSSN: "",
    preapprovalSsnMismatchesCount: 0,
    preapprovalLocatorMismatchesCount: 0,
    isPreapprovalMode: false,
    isPreapprovalModeUpswing: false,
    isFromReg1: false,
    leadUUID: "",
    leadOfferId: "",
    leadProviderId: "",
    leadProviderName: "",
    offerLead: false,
    shouldIgnoreValidation: false
  } as Registration
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RegistrationState extends Registration {}
const registrationState: RegistrationState = newRegistration()

export { registrationState, RegistrationState, newRegistration }
