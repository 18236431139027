import { ApiPublicService } from "@/lib/api/public-service"
import analytics, { logError } from "@/lib/analytics"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"

const apiEndpoint = "/v4/public/states"
const apiEndpointSuffix = "products"

export async function canStateRegister (stateCd: string): Promise<boolean> {
  const { apiPublicServiceDepreciated } = useApiServicesDepreciated()
  const cnuPublicApiService: ApiPublicService = apiPublicServiceDepreciated.value!
  const url = `${apiEndpoint}/${stateCd}/${apiEndpointSuffix}?flow_type=registration`

  try {
    await cnuPublicApiService.get<StateProductResponse>(url, "", { validateStatus: (status) => status === 200 })
    return true
  } catch(err: any) {
    if (err.response?.status == 404) {
      logError(err)
      if (err.response?.data?.error) {
        analytics.track("no_products_in_state", { error_message: err.message, state_cd: stateCd })
      }
      throw err
    }
    return true
  }
}

export type StateProductResponse = {
  data: Array<Record<string, unknown>>
}
