import Vue from "vue";
const Themeable = Vue.extend().extend({
  name: "themeable",
  provide() {
    return {
      theme: this.themeableProvide
    };
  },
  inject: {
    theme: {
      default: {
        isDark: false
      }
    }
  },
  props: {
    dark: {
      type: Boolean,
      default: null
    },
    light: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      themeableProvide: {
        isDark: false
      }
    };
  },
  computed: {
    appIsDark() {
      return this.$vuetify.theme.dark || false;
    },
    isDark() {
      if (this.dark === true) {
        return true;
      } else if (this.light === true) {
        return false;
      } else {
        return this.theme.isDark;
      }
    },
    themeClasses() {
      return {
        "theme--dark": this.isDark,
        "theme--light": !this.isDark
      };
    },
    /** Used by menus and dialogs, inherits from v-app instead of the parent */
    rootIsDark() {
      if (this.dark === true) {
        return true;
      } else if (this.light === true) {
        return false;
      } else {
        return this.appIsDark;
      }
    },
    rootThemeClasses() {
      return {
        "theme--dark": this.rootIsDark,
        "theme--light": !this.rootIsDark
      };
    }
  },
  watch: {
    isDark: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.themeableProvide.isDark = this.isDark;
        }
      },
      immediate: true
    }
  }
});
const Themeable$1 = Themeable;
function functionalThemeClasses(context) {
  const vm = {
    ...context.props,
    ...context.injections
  };
  const isDark = Themeable.options.computed.isDark.call(vm);
  return Themeable.options.computed.themeClasses.call({ isDark });
}
function mixins(...args) {
  return Vue.extend({ mixins: args });
}
const VApp = mixins(
  Themeable$1
).extend({
  name: "v-app",
  props: {
    dark: {
      type: Boolean,
      default: void 0
    },
    id: {
      type: String,
      default: "app"
    },
    light: {
      type: Boolean,
      default: void 0
    }
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    }
  },
  beforeCreate() {
    if (!this.$vuetify || this.$vuetify === this.$root) {
      throw new Error("Vuetify is not properly initialized, see https://v2.vuetifyjs.com/getting-started/quick-start#bootstrapping-the-vuetify-object");
    }
  },
  render(h) {
    const wrapper = h("div", { staticClass: "v-application--wrap" }, this.$slots.default);
    return h("div", {
      staticClass: "v-application",
      class: {
        "v-application--is-rtl": this.$vuetify.rtl,
        "v-application--is-ltr": !this.$vuetify.rtl,
        ...this.themeClasses
      },
      attrs: { "data-app": true },
      domProps: { id: this.id }
    }, [wrapper]);
  }
});
function makeWatcher(property) {
  return function(val, oldVal) {
    for (const attr in oldVal) {
      if (!Object.prototype.hasOwnProperty.call(val, attr)) {
        this.$delete(this.$data[property], attr);
      }
    }
    for (const attr in val) {
      this.$set(this.$data[property], attr, val[attr]);
    }
  };
}
const BindsAttrs = Vue.extend({
  data: () => ({
    attrs$: {},
    listeners$: {}
  }),
  created() {
    this.$watch("$attrs", makeWatcher("attrs$"), { immediate: true });
    this.$watch("$listeners", makeWatcher("listeners$"), { immediate: true });
  }
});
function install(Vue$1, args = {}) {
  if (install.installed)
    return;
  install.installed = true;
  if (Vue !== Vue$1) {
    consoleError(`Multiple instances of Vue detected
See https://github.com/vuetifyjs/vuetify/issues/4068

If you're seeing "$attrs is readonly", it's caused by this`);
  }
  const components = args.components || {};
  const directives = args.directives || {};
  for (const name in directives) {
    const directive2 = directives[name];
    Vue$1.directive(name, directive2);
  }
  (function registerComponents(components2) {
    if (components2) {
      for (const key in components2) {
        const component = components2[key];
        if (component && !registerComponents(component.$_vuetify_subcomponents)) {
          Vue$1.component(key, component);
        }
      }
      return true;
    }
    return false;
  })(components);
  if (Vue$1.$_vuetify_installed)
    return;
  Vue$1.$_vuetify_installed = true;
  Vue$1.mixin({
    beforeCreate() {
      const options = this.$options;
      if (options.vuetify) {
        options.vuetify.init(this, this.$ssrContext);
        this.$vuetify = Vue$1.observable(options.vuetify.framework);
      } else {
        this.$vuetify = options.parent && options.parent.$vuetify || this;
      }
    },
    beforeMount() {
      if (this.$options.vuetify && this.$el && this.$el.hasAttribute("data-server-rendered")) {
        this.$vuetify.isHydrating = true;
        this.$vuetify.breakpoint.update(true);
      }
    },
    mounted() {
      if (this.$options.vuetify && this.$vuetify.isHydrating) {
        this.$vuetify.isHydrating = false;
        this.$vuetify.breakpoint.update();
      }
    }
  });
}
class Service {
  constructor() {
    this.framework = {};
  }
  init(root, ssrContext) {
  }
}
class Application extends Service {
  constructor() {
    super(...arguments);
    this.bar = 0;
    this.top = 0;
    this.left = 0;
    this.insetFooter = 0;
    this.right = 0;
    this.bottom = 0;
    this.footer = 0;
    this.application = {
      bar: {},
      top: {},
      left: {},
      insetFooter: {},
      right: {},
      bottom: {},
      footer: {}
    };
  }
  static {
    this.property = "application";
  }
  register(uid, location, size) {
    this.application[location][uid] = size;
    this.update(location);
  }
  unregister(uid, location) {
    if (this.application[location][uid] == null)
      return;
    delete this.application[location][uid];
    this.update(location);
  }
  update(location) {
    this[location] = Object.values(this.application[location]).reduce((acc, cur) => acc + cur, 0);
  }
}
class Breakpoint extends Service {
  constructor(preset2) {
    super();
    this.xs = false;
    this.sm = false;
    this.md = false;
    this.lg = false;
    this.xl = false;
    this.xsOnly = false;
    this.smOnly = false;
    this.smAndDown = false;
    this.smAndUp = false;
    this.mdOnly = false;
    this.mdAndDown = false;
    this.mdAndUp = false;
    this.lgOnly = false;
    this.lgAndDown = false;
    this.lgAndUp = false;
    this.xlOnly = false;
    this.name = "xs";
    this.height = 0;
    this.width = 0;
    this.mobile = true;
    this.resizeTimeout = 0;
    const {
      mobileBreakpoint,
      scrollBarWidth,
      thresholds
    } = preset2[Breakpoint.property];
    this.mobileBreakpoint = mobileBreakpoint;
    this.scrollBarWidth = scrollBarWidth;
    this.thresholds = thresholds;
  }
  static {
    this.property = "breakpoint";
  }
  init() {
    this.update();
    if (typeof window === "undefined")
      return;
    window.addEventListener(
      "resize",
      this.onResize.bind(this),
      { passive: true }
    );
  }
  /* eslint-disable-next-line max-statements */
  update(ssr = false) {
    const height = ssr ? 0 : this.getClientHeight();
    const width = ssr ? 0 : this.getClientWidth();
    const xs = width < this.thresholds.xs;
    const sm = width < this.thresholds.sm && !xs;
    const md2 = width < this.thresholds.md - this.scrollBarWidth && !(sm || xs);
    const lg = width < this.thresholds.lg - this.scrollBarWidth && !(md2 || sm || xs);
    const xl = width >= this.thresholds.lg - this.scrollBarWidth;
    this.height = height;
    this.width = width;
    this.xs = xs;
    this.sm = sm;
    this.md = md2;
    this.lg = lg;
    this.xl = xl;
    this.xsOnly = xs;
    this.smOnly = sm;
    this.smAndDown = (xs || sm) && !(md2 || lg || xl);
    this.smAndUp = !xs && (sm || md2 || lg || xl);
    this.mdOnly = md2;
    this.mdAndDown = (xs || sm || md2) && !(lg || xl);
    this.mdAndUp = !(xs || sm) && (md2 || lg || xl);
    this.lgOnly = lg;
    this.lgAndDown = (xs || sm || md2 || lg) && !xl;
    this.lgAndUp = !(xs || sm || md2) && (lg || xl);
    this.xlOnly = xl;
    switch (true) {
      case xs:
        this.name = "xs";
        break;
      case sm:
        this.name = "sm";
        break;
      case md2:
        this.name = "md";
        break;
      case lg:
        this.name = "lg";
        break;
      default:
        this.name = "xl";
        break;
    }
    if (typeof this.mobileBreakpoint === "number") {
      this.mobile = width < parseInt(this.mobileBreakpoint, 10);
      return;
    }
    const breakpoints2 = {
      xs: 0,
      sm: 1,
      md: 2,
      lg: 3,
      xl: 4
    };
    const current = breakpoints2[this.name];
    const max = breakpoints2[this.mobileBreakpoint];
    this.mobile = current <= max;
  }
  onResize() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = window.setTimeout(this.update.bind(this), 200);
  }
  // Cross-browser support as described in:
  // https://stackoverflow.com/questions/1248081
  getClientWidth() {
    if (typeof document === "undefined")
      return 0;
    return Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
  }
  getClientHeight() {
    if (typeof document === "undefined")
      return 0;
    return Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
  }
}
const linear = (t) => t;
const easeInQuad = (t) => t ** 2;
const easeOutQuad = (t) => t * (2 - t);
const easeInOutQuad = (t) => t < 0.5 ? 2 * t ** 2 : -1 + (4 - 2 * t) * t;
const easeInCubic = (t) => t ** 3;
const easeOutCubic = (t) => --t ** 3 + 1;
const easeInOutCubic = (t) => t < 0.5 ? 4 * t ** 3 : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
const easeInQuart = (t) => t ** 4;
const easeOutQuart = (t) => 1 - --t ** 4;
const easeInOutQuart = (t) => t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
const easeInQuint = (t) => t ** 5;
const easeOutQuint = (t) => 1 + --t ** 5;
const easeInOutQuint = (t) => t < 0.5 ? 16 * t ** 5 : 1 + 16 * --t ** 5;
const easingPatterns = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  easeInCubic,
  easeInOutCubic,
  easeInOutQuad,
  easeInOutQuart,
  easeInOutQuint,
  easeInQuad,
  easeInQuart,
  easeInQuint,
  easeOutCubic,
  easeOutQuad,
  easeOutQuart,
  easeOutQuint,
  linear
}, Symbol.toStringTag, { value: "Module" }));
function getOffset(target) {
  if (typeof target === "number") {
    return target;
  }
  let el = $(target);
  if (!el) {
    throw typeof target === "string" ? new Error(`Target element "${target}" not found.`) : new TypeError(`Target must be a Number/Selector/HTMLElement/VueComponent, received ${type(target)} instead.`);
  }
  let totalOffset = 0;
  while (el) {
    totalOffset += el.offsetTop;
    el = el.offsetParent;
  }
  return totalOffset;
}
function getContainer(container) {
  const el = $(container);
  if (el)
    return el;
  throw typeof container === "string" ? new Error(`Container element "${container}" not found.`) : new TypeError(`Container must be a Selector/HTMLElement/VueComponent, received ${type(container)} instead.`);
}
function type(el) {
  return el == null ? el : el.constructor.name;
}
function $(el) {
  if (typeof el === "string") {
    return document.querySelector(el);
  } else if (el && el._isVue) {
    return el.$el;
  } else if (el instanceof HTMLElement) {
    return el;
  } else {
    return null;
  }
}
function goTo(_target, _settings = {}) {
  const settings = {
    container: document.scrollingElement || document.body || document.documentElement,
    duration: 500,
    offset: 0,
    easing: "easeInOutCubic",
    appOffset: true,
    ..._settings
  };
  const container = getContainer(settings.container);
  if (settings.appOffset && goTo.framework.application) {
    const isDrawer = container.classList.contains("v-navigation-drawer");
    const isClipped = container.classList.contains("v-navigation-drawer--clipped");
    const { bar, top } = goTo.framework.application;
    settings.offset += bar;
    if (!isDrawer || isClipped)
      settings.offset += top;
  }
  const startTime = performance.now();
  let targetLocation;
  if (typeof _target === "number") {
    targetLocation = getOffset(_target) - settings.offset;
  } else {
    targetLocation = getOffset(_target) - getOffset(container) - settings.offset;
  }
  const startLocation = container.scrollTop;
  if (targetLocation === startLocation)
    return Promise.resolve(targetLocation);
  const ease = typeof settings.easing === "function" ? settings.easing : easingPatterns[settings.easing];
  if (!ease)
    throw new TypeError(`Easing function "${settings.easing}" not found.`);
  return new Promise((resolve) => requestAnimationFrame(function step(currentTime) {
    const timeElapsed = currentTime - startTime;
    const progress = Math.abs(settings.duration ? Math.min(timeElapsed / settings.duration, 1) : 1);
    container.scrollTop = Math.floor(startLocation + (targetLocation - startLocation) * ease(progress));
    const clientHeight = container === document.body ? document.documentElement.clientHeight : container.clientHeight;
    const reachBottom = clientHeight + container.scrollTop >= container.scrollHeight;
    if (progress === 1 || // Need to go lower but reach bottom
    targetLocation > container.scrollTop && reachBottom) {
      return resolve(targetLocation);
    }
    requestAnimationFrame(step);
  }));
}
goTo.framework = {};
goTo.init = () => {
};
class Goto extends Service {
  static {
    this.property = "goTo";
  }
  constructor() {
    super();
    return goTo;
  }
}
function createSimpleFunctional(c, el = "div", name) {
  return Vue.extend({
    name: name || c.replace(/__/g, "-"),
    functional: true,
    props: {
      tag: {
        type: String,
        default: el
      }
    },
    render(h, { data, props: props2, children }) {
      data.staticClass = `${c} ${data.staticClass || ""}`.trim();
      return h(props2.tag, data, children);
    }
  });
}
function addOnceEventListener(el, eventName, cb, options = false) {
  const once = (event) => {
    cb(event);
    el.removeEventListener(eventName, once, options);
  };
  el.addEventListener(eventName, once, options);
}
let passiveSupported = false;
try {
  if (typeof window !== "undefined") {
    const testListenerOpts = Object.defineProperty({}, "passive", {
      get: () => {
        passiveSupported = true;
      }
    });
    window.addEventListener("testListener", testListenerOpts, testListenerOpts);
    window.removeEventListener("testListener", testListenerOpts, testListenerOpts);
  }
} catch (e) {
  console.warn(e);
}
function addPassiveEventListener(el, event, cb, options) {
  el.addEventListener(event, cb, passiveSupported ? options : false);
}
function getNestedValue(obj, path, fallback2) {
  const last = path.length - 1;
  if (last < 0)
    return obj === void 0 ? fallback2 : obj;
  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback2;
    }
    obj = obj[path[i]];
  }
  if (obj == null)
    return fallback2;
  return obj[path[last]] === void 0 ? fallback2 : obj[path[last]];
}
function deepEqual(a, b) {
  if (a === b)
    return true;
  if (a instanceof Date && b instanceof Date && a.getTime() !== b.getTime()) {
    return false;
  }
  if (a !== Object(a) || b !== Object(b)) {
    return false;
  }
  const props2 = Object.keys(a);
  if (props2.length !== Object.keys(b).length) {
    return false;
  }
  return props2.every((p) => deepEqual(a[p], b[p]));
}
function getObjectValueByPath(obj, path, fallback2) {
  if (obj == null || !path || typeof path !== "string")
    return fallback2;
  if (obj[path] !== void 0)
    return obj[path];
  path = path.replace(/\[(\w+)\]/g, ".$1");
  path = path.replace(/^\./, "");
  return getNestedValue(obj, path.split("."), fallback2);
}
function getPropertyFromItem(item, property, fallback2) {
  if (property == null)
    return item === void 0 ? fallback2 : item;
  if (item !== Object(item))
    return fallback2 === void 0 ? item : fallback2;
  if (typeof property === "string")
    return getObjectValueByPath(item, property, fallback2);
  if (Array.isArray(property))
    return getNestedValue(item, property, fallback2);
  if (typeof property !== "function")
    return fallback2;
  const value = property(item, fallback2);
  return typeof value === "undefined" ? fallback2 : value;
}
function createRange(length) {
  return Array.from({ length }, (v, k) => k);
}
function getZIndex(el) {
  if (!el || el.nodeType !== Node.ELEMENT_NODE)
    return 0;
  const index = +window.getComputedStyle(el).getPropertyValue("z-index");
  if (!index)
    return getZIndex(el.parentNode);
  return index;
}
function filterObjectOnKeys(obj, keys2) {
  const filtered = {};
  for (let i = 0; i < keys2.length; i++) {
    const key = keys2[i];
    if (typeof obj[key] !== "undefined") {
      filtered[key] = obj[key];
    }
  }
  return filtered;
}
function convertToUnit(str, unit = "px") {
  if (str == null || str === "") {
    return void 0;
  } else if (isNaN(+str)) {
    return String(str);
  } else {
    return `${Number(str)}${unit}`;
  }
}
function kebabCase(str) {
  return (str || "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}
function isObject(obj) {
  return obj !== null && typeof obj === "object";
}
const keyCodes = Object.freeze({
  enter: 13,
  tab: 9,
  delete: 46,
  esc: 27,
  space: 32,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  end: 35,
  home: 36,
  del: 46,
  backspace: 8,
  insert: 45,
  pageup: 33,
  pagedown: 34,
  shift: 16
});
function remapInternalIcon(vm, iconName) {
  const component = vm.$vuetify.icons.component;
  if (iconName.startsWith("$")) {
    const iconPath = `$vuetify.icons.values.${iconName.split("$").pop().split(".").pop()}`;
    const override = getObjectValueByPath(vm, iconPath, iconName);
    if (typeof override === "string")
      iconName = override;
    else
      return override;
  }
  if (component == null) {
    return iconName;
  }
  return {
    component,
    props: {
      icon: iconName
    }
  };
}
function keys(o) {
  return Object.keys(o);
}
const camelizeRE = /-(\w)/g;
const camelize = (str) => {
  return str.replace(camelizeRE, (_, c) => c ? c.toUpperCase() : "");
};
function arrayDiff(a, b) {
  const diff = [];
  for (let i = 0; i < b.length; i++) {
    if (a.indexOf(b[i]) < 0)
      diff.push(b[i]);
  }
  return diff;
}
function upperFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
function groupItems(items, groupBy, groupDesc) {
  const key = groupBy[0];
  const groups = [];
  let current;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const val = getObjectValueByPath(item, key, null);
    if (current !== val) {
      current = val;
      groups.push({
        name: val ?? "",
        items: []
      });
    }
    groups[groups.length - 1].items.push(item);
  }
  return groups;
}
function wrapInArray(v) {
  return v != null ? Array.isArray(v) ? v : [v] : [];
}
function sortItems(items, sortBy, sortDesc, locale, customSorters) {
  if (sortBy === null || !sortBy.length)
    return items;
  const stringCollator = new Intl.Collator(locale, { sensitivity: "accent", usage: "sort" });
  return items.sort((a, b) => {
    for (let i = 0; i < sortBy.length; i++) {
      const sortKey = sortBy[i];
      let sortA = getObjectValueByPath(a, sortKey);
      let sortB = getObjectValueByPath(b, sortKey);
      if (sortDesc[i]) {
        [sortA, sortB] = [sortB, sortA];
      }
      if (customSorters && customSorters[sortKey]) {
        const customResult = customSorters[sortKey](sortA, sortB);
        if (!customResult)
          continue;
        return customResult;
      }
      if (sortA === null && sortB === null) {
        continue;
      }
      if (sortA instanceof Date && sortB instanceof Date) {
        return sortA.getTime() - sortB.getTime();
      }
      [sortA, sortB] = [sortA, sortB].map((s) => (s || "").toString().toLocaleLowerCase());
      if (sortA !== sortB) {
        if (!isNaN(sortA) && !isNaN(sortB))
          return Number(sortA) - Number(sortB);
        return stringCollator.compare(sortA, sortB);
      }
    }
    return 0;
  });
}
function defaultFilter(value, search, item) {
  return value != null && search != null && typeof value !== "boolean" && value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
}
function searchItems(items, search) {
  if (!search)
    return items;
  search = search.toString().toLowerCase();
  if (search.trim() === "")
    return items;
  return items.filter((item) => Object.keys(item).some((key) => defaultFilter(getObjectValueByPath(item, key), search)));
}
function getSlotType(vm, name, split) {
  if (vm.$slots.hasOwnProperty(name) && vm.$scopedSlots.hasOwnProperty(name) && vm.$scopedSlots[name].name) {
    return split ? "v-slot" : "scoped";
  }
  if (vm.$slots.hasOwnProperty(name))
    return "normal";
  if (vm.$scopedSlots.hasOwnProperty(name))
    return "scoped";
}
function debounce(fn, delay) {
  let timeoutId = 0;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
}
function throttle(fn, limit) {
  let throttling = false;
  return (...args) => {
    if (!throttling) {
      throttling = true;
      setTimeout(() => throttling = false, limit);
      return fn(...args);
    }
  };
}
function getPrefixedScopedSlots(prefix, scopedSlots) {
  return Object.keys(scopedSlots).filter((k) => k.startsWith(prefix)).reduce((obj, k) => {
    obj[k.replace(prefix, "")] = scopedSlots[k];
    return obj;
  }, {});
}
function getSlot(vm, name = "default", data, optional = false) {
  if (vm.$scopedSlots.hasOwnProperty(name)) {
    return vm.$scopedSlots[name](data instanceof Function ? data() : data);
  } else if (vm.$slots.hasOwnProperty(name) && (!data || optional)) {
    return vm.$slots[name];
  }
  return void 0;
}
function clamp(value, min = 0, max = 1) {
  return Math.max(min, Math.min(max, value));
}
function padEnd(str, length, char = "0") {
  return str + char.repeat(Math.max(0, length - str.length));
}
function chunk(str, size = 1) {
  const chunked = [];
  let index = 0;
  while (index < str.length) {
    chunked.push(str.substr(index, size));
    index += size;
  }
  return chunked;
}
function humanReadableFileSize(bytes, binary = false) {
  const base2 = binary ? 1024 : 1e3;
  if (bytes < base2) {
    return `${bytes} B`;
  }
  const prefix = binary ? ["Ki", "Mi", "Gi"] : ["k", "M", "G"];
  let unit = -1;
  while (Math.abs(bytes) >= base2 && unit < prefix.length - 1) {
    bytes /= base2;
    ++unit;
  }
  return `${bytes.toFixed(1)} ${prefix[unit]}B`;
}
function camelizeObjectKeys(obj) {
  if (!obj)
    return {};
  return Object.keys(obj).reduce((o, key) => {
    o[camelize(key)] = obj[key];
    return o;
  }, {});
}
function mergeDeep(source = {}, target = {}) {
  for (const key in target) {
    const sourceProperty = source[key];
    const targetProperty = target[key];
    if (isObject(sourceProperty) && isObject(targetProperty)) {
      source[key] = mergeDeep(sourceProperty, targetProperty);
      continue;
    }
    source[key] = targetProperty;
  }
  return source;
}
function fillArray(length, obj) {
  return Array(length).fill(obj);
}
function composedPath(e) {
  if (e.composedPath)
    return e.composedPath();
  const path = [];
  let el = e.target;
  while (el) {
    path.push(el);
    if (el.tagName === "HTML") {
      path.push(document);
      path.push(window);
      return path;
    }
    el = el.parentElement;
  }
  return path;
}
const icons$4 = {
  complete: "M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z",
  cancel: "M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z",
  close: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
  delete: "M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z",
  // delete (e.g. v-chip close)
  clear: "M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z",
  success: "M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z",
  info: "M13,9H11V7H13M13,17H11V11H13M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2ZM13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z",
  warning: "M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z",
  error: "M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z",
  prev: "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z",
  next: "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z",
  checkboxOn: "M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z",
  checkboxOff: "M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z",
  checkboxIndeterminate: "M17,13H7V11H17M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z",
  delimiter: "M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2Z",
  // for carousel
  sort: "M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z",
  expand: "M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z",
  menu: "M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z",
  subgroup: "M7,10L12,15L17,10H7Z",
  dropdown: "M7,10L12,15L17,10H7Z",
  radioOn: "M12,20C7.58,20 4,16.42 4,12C4,7.58 7.58,4 12,4C16.42,4 20,7.58 20,12C20,16.42 16.42,20 12,20M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M12,7C9.24,7 7,9.24 7,12C7,14.76 9.24,17 12,17C14.76,17 17,14.76 17,12C17,9.24 14.76,7 12,7Z",
  radioOff: "M12,20C7.58,20 4,16.42 4,12C4,7.58 7.58,4 12,4C16.42,4 20,7.58 20,12C20,16.42 16.42,20 12,20M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2Z",
  edit: "M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z",
  ratingEmpty: "M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z",
  ratingFull: "M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z",
  ratingHalf: "M12,15.4V6.1L13.71,10.13L18.09,10.5L14.77,13.39L15.76,17.67M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z",
  loading: "M19,8L15,12H18C18,15.31 15.31,18 12,18C11,18 10.03,17.75 9.2,17.3L7.74,18.76C8.97,19.54 10.43,20 12,20C16.42,20 20,16.42 20,12H23M6,12C6,8.69 8.69,6 12,6C13,6 13.97,6.25 14.8,6.7L16.26,5.24C15.03,4.46 13.57,4 12,4C7.58,4 4,7.58 4,12H1L5,16L9,12",
  first: "M18.41,16.59L13.82,12L18.41,7.41L17,6L11,12L17,18L18.41,16.59M6,6H8V18H6V6Z",
  last: "M5.59,7.41L10.18,12L5.59,16.59L7,18L13,12L7,6L5.59,7.41M16,6H18V18H16V6Z",
  unfold: "M12,18.17L8.83,15L7.42,16.41L12,21L16.59,16.41L15.17,15M12,5.83L15.17,9L16.58,7.59L12,3L7.41,7.59L8.83,9L12,5.83Z",
  file: "M16.5,6V17.5C16.5,19.71 14.71,21.5 12.5,21.5C10.29,21.5 8.5,19.71 8.5,17.5V5C8.5,3.62 9.62,2.5 11,2.5C12.38,2.5 13.5,3.62 13.5,5V15.5C13.5,16.05 13.05,16.5 12.5,16.5C11.95,16.5 11.5,16.05 11.5,15.5V6H10V15.5C10,16.88 11.12,18 12.5,18C13.88,18 15,16.88 15,15.5V5C15,2.79 13.21,1 11,1C8.79,1 7,2.79 7,5V17.5C7,20.54 9.46,23 12.5,23C15.54,23 18,20.54 18,17.5V6H16.5Z",
  plus: "M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z",
  minus: "M19,13H5V11H19V13Z"
};
const mdiSvg = icons$4;
const icons$3 = {
  complete: "check",
  cancel: "cancel",
  close: "close",
  delete: "cancel",
  // delete (e.g. v-chip close)
  clear: "clear",
  success: "check_circle",
  info: "info",
  warning: "priority_high",
  error: "warning",
  prev: "chevron_left",
  next: "chevron_right",
  checkboxOn: "check_box",
  checkboxOff: "check_box_outline_blank",
  checkboxIndeterminate: "indeterminate_check_box",
  delimiter: "fiber_manual_record",
  // for carousel
  sort: "arrow_upward",
  expand: "keyboard_arrow_down",
  menu: "menu",
  subgroup: "arrow_drop_down",
  dropdown: "arrow_drop_down",
  radioOn: "radio_button_checked",
  radioOff: "radio_button_unchecked",
  edit: "edit",
  ratingEmpty: "star_border",
  ratingFull: "star",
  ratingHalf: "star_half",
  loading: "cached",
  first: "first_page",
  last: "last_page",
  unfold: "unfold_more",
  file: "attach_file",
  plus: "add",
  minus: "remove"
};
const md = icons$3;
const icons$2 = {
  complete: "mdi-check",
  cancel: "mdi-close-circle",
  close: "mdi-close",
  delete: "mdi-close-circle",
  // delete (e.g. v-chip close)
  clear: "mdi-close",
  success: "mdi-check-circle",
  info: "mdi-information",
  warning: "mdi-exclamation",
  error: "mdi-alert",
  prev: "mdi-chevron-left",
  next: "mdi-chevron-right",
  checkboxOn: "mdi-checkbox-marked",
  checkboxOff: "mdi-checkbox-blank-outline",
  checkboxIndeterminate: "mdi-minus-box",
  delimiter: "mdi-circle",
  // for carousel
  sort: "mdi-arrow-up",
  expand: "mdi-chevron-down",
  menu: "mdi-menu",
  subgroup: "mdi-menu-down",
  dropdown: "mdi-menu-down",
  radioOn: "mdi-radiobox-marked",
  radioOff: "mdi-radiobox-blank",
  edit: "mdi-pencil",
  ratingEmpty: "mdi-star-outline",
  ratingFull: "mdi-star",
  ratingHalf: "mdi-star-half-full",
  loading: "mdi-cached",
  first: "mdi-page-first",
  last: "mdi-page-last",
  unfold: "mdi-unfold-more-horizontal",
  file: "mdi-paperclip",
  plus: "mdi-plus",
  minus: "mdi-minus"
};
const mdi = icons$2;
const icons$1 = {
  complete: "fas fa-check",
  cancel: "fas fa-times-circle",
  close: "fas fa-times",
  delete: "fas fa-times-circle",
  // delete (e.g. v-chip close)
  clear: "fas fa-times-circle",
  // delete (e.g. v-chip close)
  success: "fas fa-check-circle",
  info: "fas fa-info-circle",
  warning: "fas fa-exclamation-circle",
  error: "fas fa-exclamation-triangle",
  prev: "fas fa-chevron-left",
  next: "fas fa-chevron-right",
  checkboxOn: "fas fa-check-square",
  checkboxOff: "far fa-square",
  // note 'far'
  checkboxIndeterminate: "fas fa-minus-square",
  delimiter: "fas fa-circle",
  // for carousel
  sort: "fas fa-sort-up",
  expand: "fas fa-chevron-down",
  menu: "fas fa-bars",
  subgroup: "fas fa-caret-down",
  dropdown: "fas fa-caret-down",
  radioOn: "far fa-dot-circle",
  radioOff: "far fa-circle",
  edit: "fas fa-edit",
  ratingEmpty: "far fa-star",
  ratingFull: "fas fa-star",
  ratingHalf: "fas fa-star-half",
  loading: "fas fa-sync",
  first: "fas fa-step-backward",
  last: "fas fa-step-forward",
  unfold: "fas fa-arrows-alt-v",
  file: "fas fa-paperclip",
  plus: "fas fa-plus",
  minus: "fas fa-minus"
};
const fa = icons$1;
const icons = {
  complete: "fa fa-check",
  cancel: "fa fa-times-circle",
  close: "fa fa-times",
  delete: "fa fa-times-circle",
  // delete (e.g. v-chip close)
  clear: "fa fa-times-circle",
  // delete (e.g. v-chip close)
  success: "fa fa-check-circle",
  info: "fa fa-info-circle",
  warning: "fa fa-exclamation",
  error: "fa fa-exclamation-triangle",
  prev: "fa fa-chevron-left",
  next: "fa fa-chevron-right",
  checkboxOn: "fa fa-check-square",
  checkboxOff: "fa fa-square-o",
  checkboxIndeterminate: "fa fa-minus-square",
  delimiter: "fa fa-circle",
  // for carousel
  sort: "fa fa-sort-up",
  expand: "fa fa-chevron-down",
  menu: "fa fa-bars",
  subgroup: "fa fa-caret-down",
  dropdown: "fa fa-caret-down",
  radioOn: "fa fa-dot-circle-o",
  radioOff: "fa fa-circle-o",
  edit: "fa fa-pencil",
  ratingEmpty: "fa fa-star-o",
  ratingFull: "fa fa-star",
  ratingHalf: "fa fa-star-half-o",
  loading: "fa fa-refresh",
  first: "fa fa-step-backward",
  last: "fa fa-step-forward",
  unfold: "fa fa-angle-double-down",
  file: "fa fa-paperclip",
  plus: "fa fa-plus",
  minus: "fa fa-minus"
};
const fa4 = icons;
function convertToComponentDeclarations(component, iconSet) {
  const result = {};
  for (const key in iconSet) {
    result[key] = {
      component,
      props: {
        icon: iconSet[key].split(" fa-")
      }
    };
  }
  return result;
}
const faSvg = convertToComponentDeclarations("font-awesome-icon", fa);
const presets = Object.freeze({
  mdiSvg,
  md,
  mdi,
  fa,
  fa4,
  faSvg
});
class Icons extends Service {
  static {
    this.property = "icons";
  }
  constructor(preset2) {
    super();
    const {
      iconfont,
      values,
      component
    } = preset2[Icons.property];
    this.component = component;
    this.iconfont = iconfont;
    this.values = mergeDeep(
      presets[iconfont],
      values
    );
  }
}
const LANG_PREFIX = "$vuetify.";
const fallback = Symbol("Lang fallback");
function getTranslation(locale, key, usingDefault = false, defaultLocale) {
  const shortKey = key.replace(LANG_PREFIX, "");
  let translation = getObjectValueByPath(locale, shortKey, fallback);
  if (translation === fallback) {
    if (usingDefault) {
      consoleError(`Translation key "${shortKey}" not found in fallback`);
      translation = key;
    } else {
      consoleWarn(`Translation key "${shortKey}" not found, falling back to default`);
      translation = getTranslation(defaultLocale, key, true, defaultLocale);
    }
  }
  return translation;
}
class Lang extends Service {
  constructor(preset2) {
    super();
    this.defaultLocale = "en";
    const {
      current,
      locales,
      t
    } = preset2[Lang.property];
    this.current = current;
    this.locales = locales;
    this.translator = t || this.defaultTranslator;
  }
  static {
    this.property = "lang";
  }
  currentLocale(key) {
    const translation = this.locales[this.current];
    const defaultLocale = this.locales[this.defaultLocale];
    return getTranslation(translation, key, false, defaultLocale);
  }
  t(key, ...params) {
    if (!key.startsWith(LANG_PREFIX))
      return this.replace(key, params);
    return this.translator(key, ...params);
  }
  defaultTranslator(key, ...params) {
    return this.replace(this.currentLocale(key), params);
  }
  replace(str, params) {
    return str.replace(/\{(\d+)\}/g, (match, index) => {
      return String(params[+index]);
    });
  }
}
const en = {
  badge: "Badge",
  close: "Close",
  dataIterator: {
    noResultsText: "No matching records found",
    loadingText: "Loading items..."
  },
  dataTable: {
    itemsPerPageText: "Rows per page:",
    ariaLabel: {
      sortDescending: "Sorted descending.",
      sortAscending: "Sorted ascending.",
      sortNone: "Not sorted.",
      activateNone: "Activate to remove sorting.",
      activateDescending: "Activate to sort descending.",
      activateAscending: "Activate to sort ascending."
    },
    sortBy: "Sort by"
  },
  dataFooter: {
    itemsPerPageText: "Items per page:",
    itemsPerPageAll: "All",
    nextPage: "Next page",
    prevPage: "Previous page",
    firstPage: "First page",
    lastPage: "Last page",
    pageText: "{0}-{1} of {2}"
  },
  datePicker: {
    itemsSelected: "{0} selected",
    nextMonthAriaLabel: "Next month",
    nextYearAriaLabel: "Next year",
    prevMonthAriaLabel: "Previous month",
    prevYearAriaLabel: "Previous year"
  },
  noDataText: "No data available",
  carousel: {
    prev: "Previous visual",
    next: "Next visual",
    ariaLabel: {
      delimiter: "Carousel slide {0} of {1}"
    }
  },
  calendar: {
    moreEvents: "{0} more"
  },
  input: {
    clear: "Clear {0}",
    prependAction: "{0} prepended action",
    appendAction: "{0} appended action"
  },
  fileInput: {
    counter: "{0} files",
    counterSize: "{0} files ({1} in total)"
  },
  timePicker: {
    am: "AM",
    pm: "PM"
  },
  pagination: {
    ariaLabel: {
      wrapper: "Pagination Navigation",
      next: "Next page",
      previous: "Previous page",
      page: "Goto Page {0}",
      currentPage: "Current Page, Page {0}"
    }
  },
  rating: {
    ariaLabel: {
      icon: "Rating {0} of {1}"
    }
  },
  loading: "Loading..."
};
const preset = {
  breakpoint: {
    // TODO: update to MD2 spec in v3 - 1280
    mobileBreakpoint: 1264,
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920
    }
  },
  icons: {
    // TODO: remove v3
    iconfont: "mdi",
    values: {}
  },
  lang: {
    current: "en",
    locales: { en },
    // Default translator exists in lang service
    t: void 0
  },
  rtl: false,
  theme: {
    dark: false,
    default: "light",
    disable: false,
    options: {
      cspNonce: void 0,
      customProperties: void 0,
      minifyTheme: void 0,
      themeCache: void 0,
      variations: true
    },
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00"
      },
      dark: {
        primary: "#2196F3",
        secondary: "#424242",
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00"
      }
    }
  }
};
class Presets extends Service {
  static {
    this.property = "presets";
  }
  constructor(parentPreset, parent) {
    super();
    const defaultPreset = mergeDeep({}, preset);
    const { userPreset } = parent;
    const {
      preset: globalPreset = {},
      ...preset$1
    } = userPreset;
    if (globalPreset.preset != null) {
      consoleWarn("Global presets do not support the **preset** option, it can be safely omitted");
    }
    parent.preset = mergeDeep(
      mergeDeep(defaultPreset, globalPreset),
      preset$1
    );
  }
}
const srgbForwardMatrix = [
  [3.2406, -1.5372, -0.4986],
  [-0.9689, 1.8758, 0.0415],
  [0.0557, -0.204, 1.057]
];
const srgbForwardTransform = (C) => C <= 31308e-7 ? C * 12.92 : 1.055 * C ** (1 / 2.4) - 0.055;
const srgbReverseMatrix = [
  [0.4124, 0.3576, 0.1805],
  [0.2126, 0.7152, 0.0722],
  [0.0193, 0.1192, 0.9505]
];
const srgbReverseTransform = (C) => C <= 0.04045 ? C / 12.92 : ((C + 0.055) / 1.055) ** 2.4;
function fromXYZ$1(xyz) {
  const rgb = Array(3);
  const transform2 = srgbForwardTransform;
  const matrix = srgbForwardMatrix;
  for (let i = 0; i < 3; ++i) {
    rgb[i] = Math.round(clamp(transform2(
      matrix[i][0] * xyz[0] + matrix[i][1] * xyz[1] + matrix[i][2] * xyz[2]
    )) * 255);
  }
  return (rgb[0] << 16) + (rgb[1] << 8) + (rgb[2] << 0);
}
function toXYZ$1(rgb) {
  const xyz = [0, 0, 0];
  const transform2 = srgbReverseTransform;
  const matrix = srgbReverseMatrix;
  const r = transform2((rgb >> 16 & 255) / 255);
  const g = transform2((rgb >> 8 & 255) / 255);
  const b = transform2((rgb >> 0 & 255) / 255);
  for (let i = 0; i < 3; ++i) {
    xyz[i] = matrix[i][0] * r + matrix[i][1] * g + matrix[i][2] * b;
  }
  return xyz;
}
function isCssColor(color) {
  return !!color && !!color.match(/^(#|var\(--|(rgb|hsl)a?\()/);
}
function colorToInt(color) {
  let rgb;
  if (typeof color === "number") {
    rgb = color;
  } else if (typeof color === "string") {
    let c = color[0] === "#" ? color.substring(1) : color;
    if (c.length === 3) {
      c = c.split("").map((char) => char + char).join("");
    }
    if (c.length !== 6) {
      consoleWarn(`'${color}' is not a valid rgb color`);
    }
    rgb = parseInt(c, 16);
  } else {
    throw new TypeError(`Colors can only be numbers or strings, recieved ${color == null ? color : color.constructor.name} instead`);
  }
  if (rgb < 0) {
    consoleWarn(`Colors cannot be negative: '${color}'`);
    rgb = 0;
  } else if (rgb > 16777215 || isNaN(rgb)) {
    consoleWarn(`'${color}' is not a valid rgb color`);
    rgb = 16777215;
  }
  return rgb;
}
function intToHex(color) {
  let hexColor = color.toString(16);
  if (hexColor.length < 6)
    hexColor = "0".repeat(6 - hexColor.length) + hexColor;
  return "#" + hexColor;
}
function colorToHex(color) {
  return intToHex(colorToInt(color));
}
function HSVAtoRGBA(hsva) {
  const { h, s, v, a } = hsva;
  const f = (n) => {
    const k = (n + h / 60) % 6;
    return v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  };
  const rgb = [f(5), f(3), f(1)].map((v2) => Math.round(v2 * 255));
  return { r: rgb[0], g: rgb[1], b: rgb[2], a };
}
function RGBAtoHSVA(rgba) {
  if (!rgba)
    return { h: 0, s: 1, v: 1, a: 1 };
  const r = rgba.r / 255;
  const g = rgba.g / 255;
  const b = rgba.b / 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  if (max !== min) {
    if (max === r) {
      h = 60 * (0 + (g - b) / (max - min));
    } else if (max === g) {
      h = 60 * (2 + (b - r) / (max - min));
    } else if (max === b) {
      h = 60 * (4 + (r - g) / (max - min));
    }
  }
  if (h < 0)
    h = h + 360;
  const s = max === 0 ? 0 : (max - min) / max;
  const hsv = [h, s, max];
  return { h: hsv[0], s: hsv[1], v: hsv[2], a: rgba.a };
}
function HSVAtoHSLA(hsva) {
  const { h, s, v, a } = hsva;
  const l = v - v * s / 2;
  const sprime = l === 1 || l === 0 ? 0 : (v - l) / Math.min(l, 1 - l);
  return { h, s: sprime, l, a };
}
function HSLAtoHSVA(hsl) {
  const { h, s, l, a } = hsl;
  const v = l + s * Math.min(l, 1 - l);
  const sprime = v === 0 ? 0 : 2 - 2 * l / v;
  return { h, s: sprime, v, a };
}
function RGBAtoCSS(rgba) {
  return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
}
function RGBtoCSS(rgba) {
  return RGBAtoCSS({ ...rgba, a: 1 });
}
function RGBAtoHex(rgba) {
  const toHex = (v) => {
    const h = Math.round(v).toString(16);
    return ("00".substr(0, 2 - h.length) + h).toUpperCase();
  };
  return `#${[
    toHex(rgba.r),
    toHex(rgba.g),
    toHex(rgba.b),
    toHex(Math.round(rgba.a * 255))
  ].join("")}`;
}
function HexToRGBA(hex) {
  const rgba = chunk(hex.slice(1), 2).map((c) => parseInt(c, 16));
  return {
    r: rgba[0],
    g: rgba[1],
    b: rgba[2],
    a: Math.round(rgba[3] / 255 * 100) / 100
  };
}
function HexToHSVA(hex) {
  const rgb = HexToRGBA(hex);
  return RGBAtoHSVA(rgb);
}
function HSVAtoHex(hsva) {
  return RGBAtoHex(HSVAtoRGBA(hsva));
}
function parseHex(hex) {
  if (hex.startsWith("#")) {
    hex = hex.slice(1);
  }
  hex = hex.replace(/([^0-9a-f])/gi, "F");
  if (hex.length === 3 || hex.length === 4) {
    hex = hex.split("").map((x) => x + x).join("");
  }
  if (hex.length === 6) {
    hex = padEnd(hex, 8, "F");
  } else {
    hex = padEnd(padEnd(hex, 6), 8, "F");
  }
  return `#${hex}`.toUpperCase().substr(0, 9);
}
function RGBtoInt(rgba) {
  return (rgba.r << 16) + (rgba.g << 8) + rgba.b;
}
function contrastRatio(c1, c2) {
  const [, y1] = toXYZ$1(RGBtoInt(c1));
  const [, y2] = toXYZ$1(RGBtoInt(c2));
  return (Math.max(y1, y2) + 0.05) / (Math.min(y1, y2) + 0.05);
}
const delta = 0.20689655172413793;
const cielabForwardTransform = (t) => t > delta ** 3 ? Math.cbrt(t) : t / (3 * delta ** 2) + 4 / 29;
const cielabReverseTransform = (t) => t > delta ? t ** 3 : 3 * delta ** 2 * (t - 4 / 29);
function fromXYZ(xyz) {
  const transform2 = cielabForwardTransform;
  const transformedY = transform2(xyz[1]);
  return [
    116 * transformedY - 16,
    500 * (transform2(xyz[0] / 0.95047) - transformedY),
    200 * (transformedY - transform2(xyz[2] / 1.08883))
  ];
}
function toXYZ(lab) {
  const transform2 = cielabReverseTransform;
  const Ln = (lab[0] + 16) / 116;
  return [
    transform2(Ln + lab[1] / 500) * 0.95047,
    transform2(Ln),
    transform2(Ln - lab[2] / 200) * 1.08883
  ];
}
function parse(theme, isItem = false, variations = true) {
  const { anchor, ...variant } = theme;
  const colors2 = Object.keys(variant);
  const parsedTheme = {};
  for (let i = 0; i < colors2.length; ++i) {
    const name = colors2[i];
    const value = theme[name];
    if (value == null)
      continue;
    if (!variations) {
      parsedTheme[name] = { base: intToHex(colorToInt(value)) };
    } else if (isItem) {
      if (name === "base" || name.startsWith("lighten") || name.startsWith("darken")) {
        parsedTheme[name] = colorToHex(value);
      }
    } else if (typeof value === "object") {
      parsedTheme[name] = parse(value, true, variations);
    } else {
      parsedTheme[name] = genVariations(name, colorToInt(value));
    }
  }
  if (!isItem) {
    parsedTheme.anchor = anchor || parsedTheme.base || parsedTheme.primary.base;
  }
  return parsedTheme;
}
const genBaseColor = (name, value) => {
  return `
.v-application .${name} {
  background-color: ${value} !important;
  border-color: ${value} !important;
}
.v-application .${name}--text {
  color: ${value} !important;
  caret-color: ${value} !important;
}`;
};
const genVariantColor = (name, variant, value) => {
  const [type2, n] = variant.split(/(\d)/, 2);
  return `
.v-application .${name}.${type2}-${n} {
  background-color: ${value} !important;
  border-color: ${value} !important;
}
.v-application .${name}--text.text--${type2}-${n} {
  color: ${value} !important;
  caret-color: ${value} !important;
}`;
};
const genColorVariableName = (name, variant = "base") => `--v-${name}-${variant}`;
const genColorVariable = (name, variant = "base") => `var(${genColorVariableName(name, variant)})`;
function genStyles(theme, cssVar = false) {
  const { anchor, ...variant } = theme;
  const colors2 = Object.keys(variant);
  if (!colors2.length)
    return "";
  let variablesCss = "";
  let css = "";
  const aColor = cssVar ? genColorVariable("anchor") : anchor;
  css += `.v-application a { color: ${aColor}; }`;
  cssVar && (variablesCss += `  ${genColorVariableName("anchor")}: ${anchor};
`);
  for (let i = 0; i < colors2.length; ++i) {
    const name = colors2[i];
    const value = theme[name];
    css += genBaseColor(name, cssVar ? genColorVariable(name) : value.base);
    cssVar && (variablesCss += `  ${genColorVariableName(name)}: ${value.base};
`);
    const variants = keys(value);
    for (let i2 = 0; i2 < variants.length; ++i2) {
      const variant2 = variants[i2];
      const variantValue = value[variant2];
      if (variant2 === "base")
        continue;
      css += genVariantColor(name, variant2, cssVar ? genColorVariable(name, variant2) : variantValue);
      cssVar && (variablesCss += `  ${genColorVariableName(name, variant2)}: ${variantValue};
`);
    }
  }
  if (cssVar) {
    variablesCss = `:root {
${variablesCss}}

`;
  }
  return variablesCss + css;
}
function genVariations(name, value) {
  const values = {
    base: intToHex(value)
  };
  for (let i = 5; i > 0; --i) {
    values[`lighten${i}`] = intToHex(lighten(value, i));
  }
  for (let i = 1; i <= 4; ++i) {
    values[`darken${i}`] = intToHex(darken(value, i));
  }
  return values;
}
function lighten(value, amount) {
  const lab = fromXYZ(toXYZ$1(value));
  lab[0] = lab[0] + amount * 10;
  return fromXYZ$1(toXYZ(lab));
}
function darken(value, amount) {
  const lab = fromXYZ(toXYZ$1(value));
  lab[0] = lab[0] - amount * 10;
  return fromXYZ$1(toXYZ(lab));
}
class Theme extends Service {
  constructor(preset2) {
    super();
    this.disabled = false;
    this.isDark = null;
    this.unwatch = null;
    this.vueMeta = null;
    const {
      dark,
      disable,
      options,
      themes
    } = preset2[Theme.property];
    this.dark = Boolean(dark);
    this.defaults = this.themes = themes;
    this.options = options;
    if (disable) {
      this.disabled = true;
      return;
    }
    this.themes = {
      dark: this.fillVariant(themes.dark, true),
      light: this.fillVariant(themes.light, false)
    };
  }
  static {
    this.property = "theme";
  }
  // When setting css, check for element and apply new values
  /* eslint-disable-next-line accessor-pairs */
  set css(val) {
    if (this.vueMeta) {
      if (this.isVueMeta23) {
        this.applyVueMeta23();
      }
      return;
    }
    this.checkOrCreateStyleElement() && (this.styleEl.innerHTML = val);
  }
  set dark(val) {
    const oldDark = this.isDark;
    this.isDark = val;
    oldDark != null && this.applyTheme();
  }
  get dark() {
    return Boolean(this.isDark);
  }
  // Apply current theme default
  // only called on client side
  applyTheme() {
    if (this.disabled)
      return this.clearCss();
    this.css = this.generatedStyles;
  }
  clearCss() {
    this.css = "";
  }
  // Initialize theme for SSR and SPA
  // Attach to ssrContext head or
  // apply new theme to document
  init(root, ssrContext) {
    if (this.disabled)
      return;
    if (root.$meta) {
      this.initVueMeta(root);
    } else if (ssrContext) {
      this.initSSR(ssrContext);
    }
    this.initTheme(root);
  }
  // Allows for you to set target theme
  setTheme(theme, value) {
    this.themes[theme] = Object.assign(this.themes[theme], value);
    this.applyTheme();
  }
  // Reset theme defaults
  resetThemes() {
    this.themes.light = Object.assign({}, this.defaults.light);
    this.themes.dark = Object.assign({}, this.defaults.dark);
    this.applyTheme();
  }
  // Check for existence of style element
  checkOrCreateStyleElement() {
    this.styleEl = document.getElementById("vuetify-theme-stylesheet");
    if (this.styleEl)
      return true;
    this.genStyleElement();
    return Boolean(this.styleEl);
  }
  fillVariant(theme = {}, dark) {
    const defaultTheme = this.themes[dark ? "dark" : "light"];
    return Object.assign(
      {},
      defaultTheme,
      theme
    );
  }
  // Generate the style element
  // if applicable
  genStyleElement() {
    if (typeof document === "undefined")
      return;
    this.styleEl = document.createElement("style");
    this.styleEl.type = "text/css";
    this.styleEl.id = "vuetify-theme-stylesheet";
    if (this.options.cspNonce) {
      this.styleEl.setAttribute("nonce", this.options.cspNonce);
    }
    document.head.appendChild(this.styleEl);
  }
  initVueMeta(root) {
    this.vueMeta = root.$meta();
    if (this.isVueMeta23) {
      root.$nextTick(() => {
        this.applyVueMeta23();
      });
      return;
    }
    const metaKeyName = typeof this.vueMeta.getOptions === "function" ? this.vueMeta.getOptions().keyName : "metaInfo";
    const metaInfo = root.$options[metaKeyName] || {};
    root.$options[metaKeyName] = () => {
      metaInfo.style = metaInfo.style || [];
      const vuetifyStylesheet = metaInfo.style.find((s) => s.id === "vuetify-theme-stylesheet");
      if (!vuetifyStylesheet) {
        metaInfo.style.push({
          cssText: this.generatedStyles,
          type: "text/css",
          id: "vuetify-theme-stylesheet",
          nonce: (this.options || {}).cspNonce
        });
      } else {
        vuetifyStylesheet.cssText = this.generatedStyles;
      }
      return metaInfo;
    };
  }
  applyVueMeta23() {
    const { set } = this.vueMeta.addApp("vuetify");
    set({
      style: [{
        cssText: this.generatedStyles,
        type: "text/css",
        id: "vuetify-theme-stylesheet",
        nonce: this.options.cspNonce
      }]
    });
  }
  initSSR(ssrContext) {
    const nonce = this.options.cspNonce ? ` nonce="${this.options.cspNonce}"` : "";
    ssrContext.head = ssrContext.head || "";
    ssrContext.head += `<style type="text/css" id="vuetify-theme-stylesheet"${nonce}>${this.generatedStyles}</style>`;
  }
  initTheme(root) {
    if (typeof document === "undefined")
      return;
    if (this.unwatch) {
      this.unwatch();
      this.unwatch = null;
    }
    root.$once("hook:created", () => {
      const obs = Vue.observable({ themes: this.themes });
      this.unwatch = root.$watch(() => obs.themes, () => this.applyTheme(), { deep: true });
    });
    this.applyTheme();
  }
  get currentTheme() {
    const target = this.dark ? "dark" : "light";
    return this.themes[target];
  }
  get generatedStyles() {
    const theme = this.parsedTheme;
    const options = this.options || {};
    let css;
    if (options.themeCache != null) {
      css = options.themeCache.get(theme);
      if (css != null)
        return css;
    }
    css = genStyles(theme, options.customProperties);
    if (options.minifyTheme != null) {
      css = options.minifyTheme(css);
    }
    if (options.themeCache != null) {
      options.themeCache.set(theme, css);
    }
    return css;
  }
  get parsedTheme() {
    return parse(
      this.currentTheme || {},
      void 0,
      getNestedValue(this.options, ["variations"], true)
    );
  }
  // Is using v2.3 of vue-meta
  // https://github.com/nuxt/vue-meta/releases/tag/v2.3.0
  get isVueMeta23() {
    return typeof this.vueMeta.addApp === "function";
  }
}
class Vuetify {
  constructor(userPreset = {}) {
    this.framework = {
      isHydrating: false
    };
    this.installed = [];
    this.preset = {};
    this.userPreset = {};
    this.userPreset = userPreset;
    this.use(Presets);
    this.use(Application);
    this.use(Breakpoint);
    this.use(Goto);
    this.use(Icons);
    this.use(Lang);
    this.use(Theme);
  }
  static {
    this.install = install;
  }
  static {
    this.installed = false;
  }
  static {
    this.version = "2.6.11_CNU";
  }
  static {
    this.config = {
      silent: false
    };
  }
  // Called on the new vuetify instance
  // bootstrap in install beforeCreate
  // Exposes ssrContext if available
  init(root, ssrContext) {
    this.installed.forEach((property) => {
      const service = this.framework[property];
      service.framework = this.framework;
      service.init(root, ssrContext);
    });
    this.framework.rtl = Boolean(this.preset.rtl);
  }
  // Instantiate a VuetifyService
  use(Service2) {
    const property = Service2.property;
    if (this.installed.includes(property))
      return;
    this.framework[property] = new Service2(this.preset, this);
    this.installed.push(property);
  }
}
function createMessage(message, vm, parent) {
  if (Vuetify.config.silent)
    return;
  if (parent) {
    vm = {
      _isVue: true,
      $parent: parent,
      $options: vm
    };
  }
  if (vm) {
    vm.$_alreadyWarned = vm.$_alreadyWarned || [];
    if (vm.$_alreadyWarned.includes(message))
      return;
    vm.$_alreadyWarned.push(message);
  }
  return `[Vuetify] ${message}` + (vm ? generateComponentTrace(vm) : "");
}
function consoleWarn(message, vm, parent) {
  const newMessage = createMessage(message, vm, parent);
  newMessage != null && console.warn(newMessage);
}
function consoleError(message, vm, parent) {
  const newMessage = createMessage(message, vm, parent);
  newMessage != null && console.error(newMessage);
}
function deprecate(original, replacement, vm, parent) {
  consoleWarn(`[UPGRADE] '${original}' is deprecated, use '${replacement}' instead.`, vm, parent);
}
function breaking(original, replacement, vm, parent) {
  consoleError(`[BREAKING] '${original}' has been removed, use '${replacement}' instead. For more information, see the upgrade guide https://github.com/vuetifyjs/vuetify/releases/tag/v2.0.0#user-content-upgrade-guide`, vm, parent);
}
function removed(original, vm, parent) {
  consoleWarn(`[REMOVED] '${original}' has been removed. You can safely omit it.`, vm, parent);
}
const classifyRE = /(?:^|[-_])(\w)/g;
const classify = (str) => str.replace(classifyRE, (c) => c.toUpperCase()).replace(/[-_]/g, "");
function formatComponentName(vm, includeFile) {
  if (vm.$root === vm) {
    return "<Root>";
  }
  const options = typeof vm === "function" && vm.cid != null ? vm.options : vm._isVue ? vm.$options || vm.constructor.options : vm || {};
  let name = options.name || options._componentTag;
  const file = options.__file;
  if (!name && file) {
    const match = file.match(/([^/\\]+)\.vue$/);
    name = match && match[1];
  }
  return (name ? `<${classify(name)}>` : `<Anonymous>`) + (file && includeFile !== false ? ` at ${file}` : "");
}
function generateComponentTrace(vm) {
  if (vm._isVue && vm.$parent) {
    const tree = [];
    let currentRecursiveSequence = 0;
    while (vm) {
      if (tree.length > 0) {
        const last = tree[tree.length - 1];
        if (last.constructor === vm.constructor) {
          currentRecursiveSequence++;
          vm = vm.$parent;
          continue;
        } else if (currentRecursiveSequence > 0) {
          tree[tree.length - 1] = [last, currentRecursiveSequence];
          currentRecursiveSequence = 0;
        }
      }
      tree.push(vm);
      vm = vm.$parent;
    }
    return "\n\nfound in\n\n" + tree.map((vm2, i) => `${i === 0 ? "---> " : " ".repeat(5 + i * 2)}${Array.isArray(vm2) ? `${formatComponentName(vm2[0])}... (${vm2[1]} recursive calls)` : formatComponentName(vm2)}`).join("\n");
  } else {
    return `

(found in ${formatComponentName(vm)})`;
  }
}
const Colorable = Vue.extend({
  name: "colorable",
  props: {
    color: String
  },
  methods: {
    setBackgroundColor(color, data = {}) {
      if (typeof data.style === "string") {
        consoleError("style must be an object", this);
        return data;
      }
      if (typeof data.class === "string") {
        consoleError("class must be an object", this);
        return data;
      }
      if (isCssColor(color)) {
        data.style = {
          ...data.style,
          "background-color": `${color}`,
          "border-color": `${color}`
        };
      } else if (color) {
        data.class = {
          ...data.class,
          [color]: true
        };
      }
      return data;
    },
    setTextColor(color, data = {}) {
      if (typeof data.style === "string") {
        consoleError("style must be an object", this);
        return data;
      }
      if (typeof data.class === "string") {
        consoleError("class must be an object", this);
        return data;
      }
      if (isCssColor(color)) {
        data.style = {
          ...data.style,
          color: `${color}`,
          "caret-color": `${color}`
        };
      } else if (color) {
        const [colorName, colorModifier] = color.toString().trim().split(" ", 2);
        data.class = {
          ...data.class,
          [colorName + "--text"]: true
        };
        if (colorModifier) {
          data.class["text--" + colorModifier] = true;
        }
      }
      return data;
    }
  }
});
const Elevatable = Vue.extend({
  name: "elevatable",
  props: {
    elevation: [Number, String]
  },
  computed: {
    computedElevation() {
      return this.elevation;
    },
    elevationClasses() {
      const elevation = this.computedElevation;
      if (elevation == null)
        return {};
      if (isNaN(parseInt(elevation)))
        return {};
      return { [`elevation-${this.elevation}`]: true };
    }
  }
});
const Measurable = Vue.extend({
  name: "measurable",
  props: {
    height: [Number, String],
    maxHeight: [Number, String],
    maxWidth: [Number, String],
    minHeight: [Number, String],
    minWidth: [Number, String],
    width: [Number, String]
  },
  computed: {
    measurableStyles() {
      const styles = {};
      const height = convertToUnit(this.height);
      const minHeight = convertToUnit(this.minHeight);
      const minWidth = convertToUnit(this.minWidth);
      const maxHeight = convertToUnit(this.maxHeight);
      const maxWidth = convertToUnit(this.maxWidth);
      const width = convertToUnit(this.width);
      if (height)
        styles.height = height;
      if (minHeight)
        styles.minHeight = minHeight;
      if (minWidth)
        styles.minWidth = minWidth;
      if (maxHeight)
        styles.maxHeight = maxHeight;
      if (maxWidth)
        styles.maxWidth = maxWidth;
      if (width)
        styles.width = width;
      return styles;
    }
  }
});
const Roundable = Vue.extend({
  name: "roundable",
  props: {
    rounded: [Boolean, String],
    tile: Boolean
  },
  computed: {
    roundedClasses() {
      const composite = [];
      const rounded = typeof this.rounded === "string" ? String(this.rounded) : this.rounded === true;
      if (this.tile) {
        composite.push("rounded-0");
      } else if (typeof rounded === "string") {
        const values = rounded.split(" ");
        for (const value of values) {
          composite.push(`rounded-${value}`);
        }
      } else if (rounded) {
        composite.push("rounded");
      }
      return composite.length > 0 ? {
        [composite.join(" ")]: true
      } : {};
    }
  }
});
const VSheet = mixins(
  BindsAttrs,
  Colorable,
  Elevatable,
  Measurable,
  Roundable,
  Themeable$1
).extend({
  name: "v-sheet",
  props: {
    outlined: Boolean,
    shaped: Boolean,
    tag: {
      type: String,
      default: "div"
    }
  },
  computed: {
    classes() {
      return {
        "v-sheet": true,
        "v-sheet--outlined": this.outlined,
        "v-sheet--shaped": this.shaped,
        ...this.themeClasses,
        ...this.elevationClasses,
        ...this.roundedClasses
      };
    },
    styles() {
      return this.measurableStyles;
    }
  },
  render(h) {
    const data = {
      class: this.classes,
      style: this.styles,
      on: this.listeners$
    };
    return h(
      this.tag,
      this.setBackgroundColor(this.color, data),
      this.$slots.default
    );
  }
});
function inserted$4(el, binding, vnode) {
  if (typeof window === "undefined" || !("IntersectionObserver" in window))
    return;
  const modifiers = binding.modifiers || {};
  const value = binding.value;
  const { handler, options } = typeof value === "object" ? value : { handler: value, options: {} };
  const observer = new IntersectionObserver((entries = [], observer2) => {
    const _observe = el._observe?.[vnode.context._uid];
    if (!_observe)
      return;
    const isIntersecting = entries.some((entry) => entry.isIntersecting);
    if (handler && (!modifiers.quiet || _observe.init) && (!modifiers.once || isIntersecting || _observe.init)) {
      handler(entries, observer2, isIntersecting);
    }
    if (isIntersecting && modifiers.once)
      unbind$5(el, binding, vnode);
    else
      _observe.init = true;
  }, options);
  el._observe = Object(el._observe);
  el._observe[vnode.context._uid] = { init: false, observer };
  observer.observe(el);
}
function unbind$5(el, binding, vnode) {
  const observe = el._observe?.[vnode.context._uid];
  if (!observe)
    return;
  observe.observer.unobserve(el);
  delete el._observe[vnode.context._uid];
}
const Intersect = {
  inserted: inserted$4,
  unbind: unbind$5
};
const intersect = Intersect;
const VResponsive = mixins(Measurable).extend({
  name: "v-responsive",
  props: {
    aspectRatio: [String, Number],
    contentClass: String
  },
  computed: {
    computedAspectRatio() {
      return Number(this.aspectRatio);
    },
    aspectStyle() {
      return this.computedAspectRatio ? { paddingBottom: 1 / this.computedAspectRatio * 100 + "%" } : void 0;
    },
    __cachedSizer() {
      if (!this.aspectStyle)
        return [];
      return this.$createElement("div", {
        style: this.aspectStyle,
        staticClass: "v-responsive__sizer"
      });
    }
  },
  methods: {
    genContent() {
      return this.$createElement("div", {
        staticClass: "v-responsive__content",
        class: this.contentClass
      }, getSlot(this));
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-responsive",
      style: this.measurableStyles,
      on: this.$listeners
    }, [
      this.__cachedSizer,
      this.genContent()
    ]);
  }
});
/**
 * @copyright 2017 Alex Regan
 * @license MIT
 * @see https://github.com/alexsasharegan/vue-functional-data-merge
 */
const pattern = {
  styleList: /;(?![^(]*\))/g,
  styleProp: /:(.*)/
};
function parseStyle(style) {
  const styleMap = {};
  for (const s of style.split(pattern.styleList)) {
    let [key, val] = s.split(pattern.styleProp);
    key = key.trim();
    if (!key) {
      continue;
    }
    if (typeof val === "string") {
      val = val.trim();
    }
    styleMap[camelize(key)] = val;
  }
  return styleMap;
}
function mergeData() {
  const mergeTarget = {};
  let i = arguments.length;
  let prop;
  while (i--) {
    for (prop of Object.keys(arguments[i])) {
      switch (prop) {
        case "class":
        case "directives":
          if (arguments[i][prop]) {
            mergeTarget[prop] = mergeClasses(mergeTarget[prop], arguments[i][prop]);
          }
          break;
        case "style":
          if (arguments[i][prop]) {
            mergeTarget[prop] = mergeStyles(mergeTarget[prop], arguments[i][prop]);
          }
          break;
        case "staticClass":
          if (!arguments[i][prop]) {
            break;
          }
          if (mergeTarget[prop] === void 0) {
            mergeTarget[prop] = "";
          }
          if (mergeTarget[prop]) {
            mergeTarget[prop] += " ";
          }
          mergeTarget[prop] += arguments[i][prop].trim();
          break;
        case "on":
        case "nativeOn":
          if (arguments[i][prop]) {
            mergeTarget[prop] = mergeListeners(mergeTarget[prop], arguments[i][prop]);
          }
          break;
        case "attrs":
        case "props":
        case "domProps":
        case "scopedSlots":
        case "staticStyle":
        case "hook":
        case "transition":
          if (!arguments[i][prop]) {
            break;
          }
          if (!mergeTarget[prop]) {
            mergeTarget[prop] = {};
          }
          mergeTarget[prop] = { ...arguments[i][prop], ...mergeTarget[prop] };
          break;
        default:
          if (!mergeTarget[prop]) {
            mergeTarget[prop] = arguments[i][prop];
          }
      }
    }
  }
  return mergeTarget;
}
function mergeStyles(target, source) {
  if (!target)
    return source;
  if (!source)
    return target;
  target = wrapInArray(typeof target === "string" ? parseStyle(target) : target);
  return target.concat(typeof source === "string" ? parseStyle(source) : source);
}
function mergeClasses(target, source) {
  if (!source)
    return target;
  if (!target)
    return source;
  return target ? wrapInArray(target).concat(source) : source;
}
function mergeListeners(...args) {
  if (!args[0])
    return args[1];
  if (!args[1])
    return args[0];
  const dest = {};
  for (let i = 2; i--; ) {
    const arg = args[i];
    for (const event in arg) {
      if (!arg[event])
        continue;
      if (dest[event]) {
        dest[event] = [].concat(arg[event], dest[event]);
      } else {
        dest[event] = arg[event];
      }
    }
  }
  return dest;
}
const hasIntersect = typeof window !== "undefined" && "IntersectionObserver" in window;
const VImg = mixins(
  VResponsive,
  Themeable$1
).extend({
  name: "v-img",
  directives: { intersect },
  props: {
    alt: String,
    contain: Boolean,
    eager: Boolean,
    gradient: String,
    lazySrc: String,
    options: {
      type: Object,
      // For more information on types, navigate to:
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      default: () => ({
        root: void 0,
        rootMargin: void 0,
        threshold: void 0
      })
    },
    position: {
      type: String,
      default: "center center"
    },
    sizes: String,
    src: {
      type: [String, Object],
      default: ""
    },
    srcset: String,
    transition: {
      type: [Boolean, String],
      default: "fade-transition"
    }
  },
  data() {
    return {
      currentSrc: "",
      // Set from srcset
      image: null,
      isLoading: true,
      calculatedAspectRatio: void 0,
      naturalWidth: void 0,
      hasError: false
    };
  },
  computed: {
    computedAspectRatio() {
      return Number(this.normalisedSrc.aspect || this.calculatedAspectRatio);
    },
    normalisedSrc() {
      return this.src && typeof this.src === "object" ? {
        src: this.src.src,
        srcset: this.srcset || this.src.srcset,
        lazySrc: this.lazySrc || this.src.lazySrc,
        aspect: Number(this.aspectRatio || this.src.aspect)
      } : {
        src: this.src,
        srcset: this.srcset,
        lazySrc: this.lazySrc,
        aspect: Number(this.aspectRatio || 0)
      };
    },
    __cachedImage() {
      if (!(this.normalisedSrc.src || this.normalisedSrc.lazySrc || this.gradient))
        return [];
      const backgroundImage = [];
      const src = this.isLoading ? this.normalisedSrc.lazySrc : this.currentSrc;
      if (this.gradient)
        backgroundImage.push(`linear-gradient(${this.gradient})`);
      if (src)
        backgroundImage.push(`url("${src}")`);
      const image = this.$createElement("div", {
        staticClass: "v-image__image",
        class: {
          "v-image__image--preload": this.isLoading,
          "v-image__image--contain": this.contain,
          "v-image__image--cover": !this.contain
        },
        style: {
          backgroundImage: backgroundImage.join(", "),
          backgroundPosition: this.position
        },
        key: +this.isLoading
      });
      if (!this.transition)
        return image;
      return this.$createElement("transition", {
        attrs: {
          name: this.transition,
          mode: "in-out"
        }
      }, [image]);
    }
  },
  watch: {
    src() {
      if (!this.isLoading)
        this.init(void 0, void 0, true);
      else
        this.loadImage();
    },
    "$vuetify.breakpoint.width": "getSrc"
  },
  mounted() {
    this.init();
  },
  methods: {
    init(entries, observer, isIntersecting) {
      if (hasIntersect && !isIntersecting && !this.eager)
        return;
      if (this.normalisedSrc.lazySrc) {
        const lazyImg = new Image();
        lazyImg.src = this.normalisedSrc.lazySrc;
        this.pollForSize(lazyImg, null);
      }
      if (this.normalisedSrc.src)
        this.loadImage();
    },
    onLoad() {
      this.getSrc();
      this.isLoading = false;
      this.$emit("load", this.src);
      if (this.image && (this.normalisedSrc.src.endsWith(".svg") || this.normalisedSrc.src.startsWith("data:image/svg+xml"))) {
        if (this.image.naturalHeight && this.image.naturalWidth) {
          this.naturalWidth = this.image.naturalWidth;
          this.calculatedAspectRatio = this.image.naturalWidth / this.image.naturalHeight;
        } else {
          this.calculatedAspectRatio = 1;
        }
      }
    },
    onError() {
      this.hasError = true;
      this.$emit("error", this.src);
    },
    getSrc() {
      if (this.image)
        this.currentSrc = this.image.currentSrc || this.image.src;
    },
    loadImage() {
      const image = new Image();
      this.image = image;
      image.onload = () => {
        if (image.decode) {
          image.decode().catch((err) => {
            consoleWarn(
              `Failed to decode image, trying to render anyway

src: ${this.normalisedSrc.src}` + (err.message ? `
Original error: ${err.message}` : ""),
              this
            );
          }).then(this.onLoad);
        } else {
          this.onLoad();
        }
      };
      image.onerror = this.onError;
      this.hasError = false;
      this.sizes && (image.sizes = this.sizes);
      this.normalisedSrc.srcset && (image.srcset = this.normalisedSrc.srcset);
      image.src = this.normalisedSrc.src;
      this.$emit("loadstart", this.normalisedSrc.src);
      this.aspectRatio || this.pollForSize(image);
      this.getSrc();
    },
    pollForSize(img, timeout = 100) {
      const poll = () => {
        const { naturalHeight, naturalWidth } = img;
        if (naturalHeight || naturalWidth) {
          this.naturalWidth = naturalWidth;
          this.calculatedAspectRatio = naturalWidth / naturalHeight;
        } else if (!img.complete && this.isLoading && !this.hasError && timeout != null) {
          setTimeout(poll, timeout);
        }
      };
      poll();
    },
    genContent() {
      const content = VResponsive.options.methods.genContent.call(this);
      if (this.naturalWidth) {
        this._b(content.data, "div", {
          style: { width: `${this.naturalWidth}px` }
        });
      }
      return content;
    },
    __genPlaceholder() {
      const slot = getSlot(this, "placeholder");
      if (slot) {
        const placeholder = this.isLoading ? [this.$createElement("div", {
          staticClass: "v-image__placeholder"
        }, slot)] : [];
        if (!this.transition)
          return placeholder[0];
        return this.$createElement("transition", {
          props: {
            appear: true,
            name: this.transition
          }
        }, placeholder);
      }
    }
  },
  render(h) {
    const node = VResponsive.options.render.call(this, h);
    const data = mergeData(node.data, {
      staticClass: "v-image",
      attrs: {
        "aria-label": this.alt,
        role: this.alt ? "img" : void 0
      },
      class: this.themeClasses,
      // Only load intersect directive if it
      // will work in the current browser.
      directives: hasIntersect ? [{
        name: "intersect",
        modifiers: { once: true },
        value: {
          handler: this.init,
          options: this.options
        }
      }] : void 0
    });
    node.children = [
      this.__cachedSizer,
      this.__cachedImage,
      this.__genPlaceholder(),
      this.genContent()
    ];
    return h(node.tag, data, node.children);
  }
});
const VToolbar = VSheet.extend({
  name: "v-toolbar",
  props: {
    absolute: Boolean,
    bottom: Boolean,
    collapse: Boolean,
    dense: Boolean,
    extended: Boolean,
    extensionHeight: {
      default: 48,
      type: [Number, String]
    },
    flat: Boolean,
    floating: Boolean,
    prominent: Boolean,
    short: Boolean,
    src: {
      type: [String, Object],
      default: ""
    },
    tag: {
      type: String,
      default: "header"
    }
  },
  data: () => ({
    isExtended: false
  }),
  computed: {
    computedHeight() {
      const height = this.computedContentHeight;
      if (!this.isExtended)
        return height;
      const extensionHeight = parseInt(this.extensionHeight);
      return this.isCollapsed ? height : height + (!isNaN(extensionHeight) ? extensionHeight : 0);
    },
    computedContentHeight() {
      if (this.height)
        return parseInt(this.height);
      if (this.isProminent && this.dense)
        return 96;
      if (this.isProminent && this.short)
        return 112;
      if (this.isProminent)
        return 128;
      if (this.dense)
        return 48;
      if (this.short || this.$vuetify.breakpoint.smAndDown)
        return 56;
      return 64;
    },
    classes() {
      return {
        ...VSheet.options.computed.classes.call(this),
        "v-toolbar": true,
        "v-toolbar--absolute": this.absolute,
        "v-toolbar--bottom": this.bottom,
        "v-toolbar--collapse": this.collapse,
        "v-toolbar--collapsed": this.isCollapsed,
        "v-toolbar--dense": this.dense,
        "v-toolbar--extended": this.isExtended,
        "v-toolbar--flat": this.flat,
        "v-toolbar--floating": this.floating,
        "v-toolbar--prominent": this.isProminent
      };
    },
    isCollapsed() {
      return this.collapse;
    },
    isProminent() {
      return this.prominent;
    },
    styles() {
      return {
        ...this.measurableStyles,
        height: convertToUnit(this.computedHeight)
      };
    }
  },
  created() {
    const breakingProps = [
      ["app", "<v-app-bar app>"],
      ["manual-scroll", '<v-app-bar :value="false">'],
      ["clipped-left", "<v-app-bar clipped-left>"],
      ["clipped-right", "<v-app-bar clipped-right>"],
      ["inverted-scroll", "<v-app-bar inverted-scroll>"],
      ["scroll-off-screen", "<v-app-bar scroll-off-screen>"],
      ["scroll-target", "<v-app-bar scroll-target>"],
      ["scroll-threshold", "<v-app-bar scroll-threshold>"],
      ["card", "<v-app-bar flat>"]
    ];
    breakingProps.forEach(([original, replacement]) => {
      if (this.$attrs.hasOwnProperty(original))
        breaking(original, replacement, this);
    });
  },
  methods: {
    genBackground() {
      const props2 = {
        height: convertToUnit(this.computedHeight),
        src: this.src
      };
      const image = this.$scopedSlots.img ? this.$scopedSlots.img({ props: props2 }) : this.$createElement(VImg, { props: props2 });
      return this.$createElement("div", {
        staticClass: "v-toolbar__image"
      }, [image]);
    },
    genContent() {
      return this.$createElement("div", {
        staticClass: "v-toolbar__content",
        style: {
          height: convertToUnit(this.computedContentHeight)
        }
      }, getSlot(this));
    },
    genExtension() {
      return this.$createElement("div", {
        staticClass: "v-toolbar__extension",
        style: {
          height: convertToUnit(this.extensionHeight)
        }
      }, getSlot(this, "extension"));
    }
  },
  render(h) {
    this.isExtended = this.extended || !!this.$scopedSlots.extension;
    const children = [this.genContent()];
    const data = this.setBackgroundColor(this.color, {
      class: this.classes,
      style: this.styles,
      on: this.$listeners
    });
    if (this.isExtended)
      children.push(this.genExtension());
    if (this.src || this.$scopedSlots.img)
      children.unshift(this.genBackground());
    return h(this.tag, data, children);
  }
});
function inserted$3(el, binding, vnode) {
  const { self = false } = binding.modifiers || {};
  const value = binding.value;
  const options = typeof value === "object" && value.options || { passive: true };
  const handler = typeof value === "function" || "handleEvent" in value ? value : value.handler;
  const target = self ? el : binding.arg ? document.querySelector(binding.arg) : window;
  if (!target)
    return;
  target.addEventListener("scroll", handler, options);
  el._onScroll = Object(el._onScroll);
  el._onScroll[vnode.context._uid] = {
    handler,
    options,
    // Don't reference self
    target: self ? void 0 : target
  };
}
function unbind$4(el, binding, vnode) {
  if (!el._onScroll?.[vnode.context._uid])
    return;
  const { handler, options, target = el } = el._onScroll[vnode.context._uid];
  target.removeEventListener("scroll", handler, options);
  delete el._onScroll[vnode.context._uid];
}
const Scroll = {
  inserted: inserted$3,
  unbind: unbind$4
};
const Scroll$1 = Scroll;
const availableProps = {
  absolute: Boolean,
  bottom: Boolean,
  fixed: Boolean,
  left: Boolean,
  right: Boolean,
  top: Boolean
};
function factory$3(selected = []) {
  return Vue.extend({
    name: "positionable",
    props: selected.length ? filterObjectOnKeys(availableProps, selected) : availableProps
  });
}
const Positionable = factory$3();
function applicationable(value, events = []) {
  return mixins(factory$3(["absolute", "fixed"])).extend({
    name: "applicationable",
    props: {
      app: Boolean
    },
    computed: {
      applicationProperty() {
        return value;
      }
    },
    watch: {
      // If previous value was app
      // reset the provided prop
      app(x, prev) {
        prev ? this.removeApplication(true) : this.callUpdate();
      },
      applicationProperty(newVal, oldVal) {
        this.$vuetify.application.unregister(this._uid, oldVal);
      }
    },
    activated() {
      this.callUpdate();
    },
    created() {
      for (let i = 0, length = events.length; i < length; i++) {
        this.$watch(events[i], this.callUpdate);
      }
      this.callUpdate();
    },
    mounted() {
      this.callUpdate();
    },
    deactivated() {
      this.removeApplication();
    },
    destroyed() {
      this.removeApplication();
    },
    methods: {
      callUpdate() {
        if (!this.app)
          return;
        this.$vuetify.application.register(
          this._uid,
          this.applicationProperty,
          this.updateApplication()
        );
      },
      removeApplication(force = false) {
        if (!force && !this.app)
          return;
        this.$vuetify.application.unregister(
          this._uid,
          this.applicationProperty
        );
      },
      updateApplication: () => 0
    }
  });
}
function attachedRoot(node) {
  if (typeof node.getRootNode !== "function") {
    while (node.parentNode)
      node = node.parentNode;
    if (node !== document)
      return null;
    return document;
  }
  const root = node.getRootNode();
  if (root !== document && root.getRootNode({ composed: true }) !== document)
    return null;
  return root;
}
function defaultConditional() {
  return true;
}
function checkEvent(e, el, binding) {
  if (!e || checkIsActive(e, binding) === false)
    return false;
  const root = attachedRoot(el);
  if (typeof ShadowRoot !== "undefined" && root instanceof ShadowRoot && root.host === e.target)
    return false;
  const elements = (typeof binding.value === "object" && binding.value.include || (() => []))();
  elements.push(el);
  return !elements.some((el2) => el2.contains(e.target));
}
function checkIsActive(e, binding) {
  const isActive = typeof binding.value === "object" && binding.value.closeConditional || defaultConditional;
  return isActive(e);
}
function directive$1(e, el, binding) {
  const handler = typeof binding.value === "function" ? binding.value : binding.value.handler;
  el._clickOutside.lastMousedownWasOutside && checkEvent(e, el, binding) && setTimeout(() => {
    checkIsActive(e, binding) && handler && handler(e);
  }, 0);
}
function handleShadow(el, callback) {
  const root = attachedRoot(el);
  callback(document);
  if (typeof ShadowRoot !== "undefined" && root instanceof ShadowRoot) {
    callback(root);
  }
}
const ClickOutside = {
  // [data-app] may not be found
  // if using bind, inserted makes
  // sure that the root element is
  // available, iOS does not support
  // clicks on body
  inserted(el, binding, vnode) {
    const onClick = (e) => directive$1(e, el, binding);
    const onMousedown = (e) => {
      el._clickOutside.lastMousedownWasOutside = checkEvent(e, el, binding);
    };
    handleShadow(el, (app) => {
      app.addEventListener("click", onClick, true);
      app.addEventListener("mousedown", onMousedown, true);
    });
    if (!el._clickOutside) {
      el._clickOutside = {
        lastMousedownWasOutside: true
      };
    }
    el._clickOutside[vnode.context._uid] = {
      onClick,
      onMousedown
    };
  },
  unbind(el, binding, vnode) {
    if (!el._clickOutside)
      return;
    handleShadow(el, (app) => {
      if (!app || !el._clickOutside?.[vnode.context._uid])
        return;
      const { onClick, onMousedown } = el._clickOutside[vnode.context._uid];
      app.removeEventListener("click", onClick, true);
      app.removeEventListener("mousedown", onMousedown, true);
    });
    delete el._clickOutside[vnode.context._uid];
  }
};
const ClickOutside$1 = ClickOutside;
function inserted$2(el, binding, vnode) {
  const modifiers = binding.modifiers || {};
  const value = binding.value;
  const callback = typeof value === "object" ? value.handler : value;
  const { once, ...modifierKeys } = modifiers;
  const hasModifiers = Object.keys(modifierKeys).length > 0;
  const options = typeof value === "object" && value.options ? value.options : hasModifiers ? {
    attributes: modifierKeys.attr,
    childList: modifierKeys.child,
    subtree: modifierKeys.sub,
    characterData: modifierKeys.char
  } : {
    attributes: true,
    childList: true,
    subtree: true,
    characterData: true
  };
  const observer = new MutationObserver((mutationsList, observer2) => {
    if (!el._mutate)
      return;
    callback(mutationsList, observer2);
    once && unbind$3(el, binding, vnode);
  });
  observer.observe(el, options);
  el._mutate = Object(el._mutate);
  el._mutate[vnode.context._uid] = { observer };
}
function unbind$3(el, binding, vnode) {
  if (!el._mutate?.[vnode.context._uid])
    return;
  el._mutate[vnode.context._uid].observer.disconnect();
  delete el._mutate[vnode.context._uid];
}
const Mutate = {
  inserted: inserted$2,
  unbind: unbind$3
};
function inserted$1(el, binding, vnode) {
  const callback = binding.value;
  const options = binding.options || { passive: true };
  window.addEventListener("resize", callback, options);
  el._onResize = Object(el._onResize);
  el._onResize[vnode.context._uid] = {
    callback,
    options
  };
  if (!binding.modifiers || !binding.modifiers.quiet) {
    callback();
  }
}
function unbind$2(el, binding, vnode) {
  if (!el._onResize?.[vnode.context._uid])
    return;
  const { callback, options } = el._onResize[vnode.context._uid];
  window.removeEventListener("resize", callback, options);
  delete el._onResize[vnode.context._uid];
}
const Resize = {
  inserted: inserted$1,
  unbind: unbind$2
};
const Resize$1 = Resize;
const DELAY_RIPPLE = 80;
function transform(el, value) {
  el.style.transform = value;
  el.style.webkitTransform = value;
}
function isTouchEvent(e) {
  return e.constructor.name === "TouchEvent";
}
function isKeyboardEvent(e) {
  return e.constructor.name === "KeyboardEvent";
}
const calculate = (e, el, value = {}) => {
  let localX = 0;
  let localY = 0;
  if (!isKeyboardEvent(e)) {
    const offset = el.getBoundingClientRect();
    const target = isTouchEvent(e) ? e.touches[e.touches.length - 1] : e;
    localX = target.clientX - offset.left;
    localY = target.clientY - offset.top;
  }
  let radius = 0;
  let scale = 0.3;
  if (el._ripple && el._ripple.circle) {
    scale = 0.15;
    radius = el.clientWidth / 2;
    radius = value.center ? radius : radius + Math.sqrt((localX - radius) ** 2 + (localY - radius) ** 2) / 4;
  } else {
    radius = Math.sqrt(el.clientWidth ** 2 + el.clientHeight ** 2) / 2;
  }
  const centerX = `${(el.clientWidth - radius * 2) / 2}px`;
  const centerY = `${(el.clientHeight - radius * 2) / 2}px`;
  const x = value.center ? centerX : `${localX - radius}px`;
  const y = value.center ? centerY : `${localY - radius}px`;
  return { radius, scale, x, y, centerX, centerY };
};
const ripples = {
  /* eslint-disable max-statements */
  show(e, el, value = {}) {
    if (!el._ripple || !el._ripple.enabled) {
      return;
    }
    const container = document.createElement("span");
    const animation = document.createElement("span");
    container.appendChild(animation);
    container.className = "v-ripple__container";
    if (value.class) {
      container.className += ` ${value.class}`;
    }
    const { radius, scale, x, y, centerX, centerY } = calculate(e, el, value);
    const size = `${radius * 2}px`;
    animation.className = "v-ripple__animation";
    animation.style.width = size;
    animation.style.height = size;
    el.appendChild(container);
    const computed = window.getComputedStyle(el);
    if (computed && computed.position === "static") {
      el.style.position = "relative";
      el.dataset.previousPosition = "static";
    }
    animation.classList.add("v-ripple__animation--enter");
    animation.classList.add("v-ripple__animation--visible");
    transform(animation, `translate(${x}, ${y}) scale3d(${scale},${scale},${scale})`);
    animation.dataset.activated = String(performance.now());
    setTimeout(() => {
      animation.classList.remove("v-ripple__animation--enter");
      animation.classList.add("v-ripple__animation--in");
      transform(animation, `translate(${centerX}, ${centerY}) scale3d(1,1,1)`);
    }, 0);
  },
  hide(el) {
    if (!el || !el._ripple || !el._ripple.enabled)
      return;
    const ripples2 = el.getElementsByClassName("v-ripple__animation");
    if (ripples2.length === 0)
      return;
    const animation = ripples2[ripples2.length - 1];
    if (animation.dataset.isHiding)
      return;
    else
      animation.dataset.isHiding = "true";
    const diff = performance.now() - Number(animation.dataset.activated);
    const delay = Math.max(250 - diff, 0);
    setTimeout(() => {
      animation.classList.remove("v-ripple__animation--in");
      animation.classList.add("v-ripple__animation--out");
      setTimeout(() => {
        const ripples3 = el.getElementsByClassName("v-ripple__animation");
        if (ripples3.length === 1 && el.dataset.previousPosition) {
          el.style.position = el.dataset.previousPosition;
          delete el.dataset.previousPosition;
        }
        if (animation.parentNode?.parentNode === el)
          el.removeChild(animation.parentNode);
      }, 300);
    }, delay);
  }
};
function isRippleEnabled(value) {
  return typeof value === "undefined" || !!value;
}
function rippleShow(e) {
  const value = {};
  const element = e.currentTarget;
  if (!element || !element._ripple || element._ripple.touched || e.rippleStop)
    return;
  e.rippleStop = true;
  if (isTouchEvent(e)) {
    element._ripple.touched = true;
    element._ripple.isTouch = true;
  } else {
    if (element._ripple.isTouch)
      return;
  }
  value.center = element._ripple.centered || isKeyboardEvent(e);
  if (element._ripple.class) {
    value.class = element._ripple.class;
  }
  if (isTouchEvent(e)) {
    if (element._ripple.showTimerCommit)
      return;
    element._ripple.showTimerCommit = () => {
      ripples.show(e, element, value);
    };
    element._ripple.showTimer = window.setTimeout(() => {
      if (element && element._ripple && element._ripple.showTimerCommit) {
        element._ripple.showTimerCommit();
        element._ripple.showTimerCommit = null;
      }
    }, DELAY_RIPPLE);
  } else {
    ripples.show(e, element, value);
  }
}
function rippleHide(e) {
  const element = e.currentTarget;
  if (!element || !element._ripple)
    return;
  window.clearTimeout(element._ripple.showTimer);
  if (e.type === "touchend" && element._ripple.showTimerCommit) {
    element._ripple.showTimerCommit();
    element._ripple.showTimerCommit = null;
    element._ripple.showTimer = setTimeout(() => {
      rippleHide(e);
    });
    return;
  }
  window.setTimeout(() => {
    if (element._ripple) {
      element._ripple.touched = false;
    }
  });
  ripples.hide(element);
}
function rippleCancelShow(e) {
  const element = e.currentTarget;
  if (!element || !element._ripple)
    return;
  if (element._ripple.showTimerCommit) {
    element._ripple.showTimerCommit = null;
  }
  window.clearTimeout(element._ripple.showTimer);
}
let keyboardRipple = false;
function keyboardRippleShow(e) {
  if (!keyboardRipple && (e.keyCode === keyCodes.enter || e.keyCode === keyCodes.space)) {
    keyboardRipple = true;
    rippleShow(e);
  }
}
function keyboardRippleHide(e) {
  keyboardRipple = false;
  rippleHide(e);
}
function focusRippleHide(e) {
  if (keyboardRipple === true) {
    keyboardRipple = false;
    rippleHide(e);
  }
}
function updateRipple(el, binding, wasEnabled) {
  const enabled = isRippleEnabled(binding.value);
  if (!enabled) {
    ripples.hide(el);
  }
  el._ripple = el._ripple || {};
  el._ripple.enabled = enabled;
  const value = binding.value || {};
  if (value.center) {
    el._ripple.centered = true;
  }
  if (value.class) {
    el._ripple.class = binding.value.class;
  }
  if (value.circle) {
    el._ripple.circle = value.circle;
  }
  if (enabled && !wasEnabled) {
    el.addEventListener("touchstart", rippleShow, { passive: true });
    el.addEventListener("touchend", rippleHide, { passive: true });
    el.addEventListener("touchmove", rippleCancelShow, { passive: true });
    el.addEventListener("touchcancel", rippleHide);
    el.addEventListener("mousedown", rippleShow);
    el.addEventListener("mouseup", rippleHide);
    el.addEventListener("mouseleave", rippleHide);
    el.addEventListener("keydown", keyboardRippleShow);
    el.addEventListener("keyup", keyboardRippleHide);
    el.addEventListener("blur", focusRippleHide);
    el.addEventListener("dragstart", rippleHide, { passive: true });
  } else if (!enabled && wasEnabled) {
    removeListeners(el);
  }
}
function removeListeners(el) {
  el.removeEventListener("mousedown", rippleShow);
  el.removeEventListener("touchstart", rippleShow);
  el.removeEventListener("touchend", rippleHide);
  el.removeEventListener("touchmove", rippleCancelShow);
  el.removeEventListener("touchcancel", rippleHide);
  el.removeEventListener("mouseup", rippleHide);
  el.removeEventListener("mouseleave", rippleHide);
  el.removeEventListener("keydown", keyboardRippleShow);
  el.removeEventListener("keyup", keyboardRippleHide);
  el.removeEventListener("dragstart", rippleHide);
  el.removeEventListener("blur", focusRippleHide);
}
function directive(el, binding, node) {
  updateRipple(el, binding, false);
  if (process.env.NODE_ENV === "development") {
    node.context && node.context.$nextTick(() => {
      const computed = window.getComputedStyle(el);
      if (computed && computed.display === "inline") {
        const context = node.fnOptions ? [node.fnOptions, node.context] : [node.componentInstance];
        consoleWarn("v-ripple can only be used on block-level elements", ...context);
      }
    });
  }
}
function unbind$1(el) {
  delete el._ripple;
  removeListeners(el);
}
function update(el, binding) {
  if (binding.value === binding.oldValue) {
    return;
  }
  const wasEnabled = isRippleEnabled(binding.oldValue);
  updateRipple(el, binding, wasEnabled);
}
const Ripple = {
  bind: directive,
  unbind: unbind$1,
  update
};
const ripple = Ripple;
const handleGesture = (wrapper) => {
  const { touchstartX, touchendX, touchstartY, touchendY } = wrapper;
  const dirRatio = 0.5;
  const minDistance = 16;
  wrapper.offsetX = touchendX - touchstartX;
  wrapper.offsetY = touchendY - touchstartY;
  if (Math.abs(wrapper.offsetY) < dirRatio * Math.abs(wrapper.offsetX)) {
    wrapper.left && touchendX < touchstartX - minDistance && wrapper.left(wrapper);
    wrapper.right && touchendX > touchstartX + minDistance && wrapper.right(wrapper);
  }
  if (Math.abs(wrapper.offsetX) < dirRatio * Math.abs(wrapper.offsetY)) {
    wrapper.up && touchendY < touchstartY - minDistance && wrapper.up(wrapper);
    wrapper.down && touchendY > touchstartY + minDistance && wrapper.down(wrapper);
  }
};
function touchstart(event, wrapper) {
  const touch = event.changedTouches[0];
  wrapper.touchstartX = touch.clientX;
  wrapper.touchstartY = touch.clientY;
  wrapper.start && wrapper.start(Object.assign(event, wrapper));
}
function touchend(event, wrapper) {
  const touch = event.changedTouches[0];
  wrapper.touchendX = touch.clientX;
  wrapper.touchendY = touch.clientY;
  wrapper.end && wrapper.end(Object.assign(event, wrapper));
  handleGesture(wrapper);
}
function touchmove(event, wrapper) {
  const touch = event.changedTouches[0];
  wrapper.touchmoveX = touch.clientX;
  wrapper.touchmoveY = touch.clientY;
  wrapper.move && wrapper.move(Object.assign(event, wrapper));
}
function createHandlers(value) {
  const wrapper = {
    touchstartX: 0,
    touchstartY: 0,
    touchendX: 0,
    touchendY: 0,
    touchmoveX: 0,
    touchmoveY: 0,
    offsetX: 0,
    offsetY: 0,
    left: value.left,
    right: value.right,
    up: value.up,
    down: value.down,
    start: value.start,
    move: value.move,
    end: value.end
  };
  return {
    touchstart: (e) => touchstart(e, wrapper),
    touchend: (e) => touchend(e, wrapper),
    touchmove: (e) => touchmove(e, wrapper)
  };
}
function inserted(el, binding, vnode) {
  const value = binding.value;
  const target = value.parent ? el.parentElement : el;
  const options = value.options || { passive: true };
  if (!target)
    return;
  const handlers = createHandlers(binding.value);
  target._touchHandlers = Object(target._touchHandlers);
  target._touchHandlers[vnode.context._uid] = handlers;
  keys(handlers).forEach((eventName) => {
    target.addEventListener(eventName, handlers[eventName], options);
  });
}
function unbind(el, binding, vnode) {
  const target = binding.value.parent ? el.parentElement : el;
  if (!target || !target._touchHandlers)
    return;
  const handlers = target._touchHandlers[vnode.context._uid];
  keys(handlers).forEach((eventName) => {
    target.removeEventListener(eventName, handlers[eventName]);
  });
  delete target._touchHandlers[vnode.context._uid];
}
const Touch = {
  inserted,
  unbind
};
const Touch$1 = Touch;
const Scrollable = Vue.extend({
  name: "scrollable",
  directives: { Scroll },
  props: {
    scrollTarget: String,
    scrollThreshold: [String, Number]
  },
  data: () => ({
    currentScroll: 0,
    currentThreshold: 0,
    isActive: false,
    isScrollingUp: false,
    previousScroll: 0,
    savedScroll: 0,
    target: null
  }),
  computed: {
    /**
     * A computed property that returns
     * whether scrolling features are
     * enabled or disabled
     */
    canScroll() {
      return typeof window !== "undefined";
    },
    /**
     * The threshold that must be met before
     * thresholdMet function is invoked
     */
    computedScrollThreshold() {
      return this.scrollThreshold ? Number(this.scrollThreshold) : 300;
    }
  },
  watch: {
    isScrollingUp() {
      this.savedScroll = this.savedScroll || this.currentScroll;
    },
    isActive() {
      this.savedScroll = 0;
    }
  },
  mounted() {
    if (this.scrollTarget) {
      this.target = document.querySelector(this.scrollTarget);
      if (!this.target) {
        consoleWarn(`Unable to locate element with identifier ${this.scrollTarget}`, this);
      }
    }
  },
  methods: {
    onScroll() {
      if (!this.canScroll)
        return;
      this.previousScroll = this.currentScroll;
      this.currentScroll = this.target ? this.target.scrollTop : window.pageYOffset;
      this.isScrollingUp = this.currentScroll < this.previousScroll;
      this.currentThreshold = Math.abs(this.currentScroll - this.computedScrollThreshold);
      this.$nextTick(() => {
        if (Math.abs(this.currentScroll - this.savedScroll) > this.computedScrollThreshold)
          this.thresholdMet();
      });
    },
    /**
     * The method invoked when
     * scrolling in any direction
     * has exceeded the threshold
     */
    thresholdMet() {
    }
  }
});
const SSRBootable = Vue.extend({
  name: "ssr-bootable",
  data: () => ({
    isBooted: false
  }),
  mounted() {
    window.requestAnimationFrame(() => {
      this.$el.setAttribute("data-booted", "true");
      this.isBooted = true;
    });
  }
});
function factory$2(prop = "value", event = "input") {
  return Vue.extend({
    name: "toggleable",
    model: { prop, event },
    props: {
      [prop]: { required: false }
    },
    data() {
      return {
        isActive: !!this[prop]
      };
    },
    watch: {
      [prop](val) {
        this.isActive = !!val;
      },
      isActive(val) {
        !!val !== this[prop] && this.$emit(event, val);
      }
    }
  });
}
const Toggleable = factory$2();
const Toggleable$1 = Toggleable;
const baseMixins$u = mixins(
  VToolbar,
  Scrollable,
  SSRBootable,
  Toggleable$1,
  applicationable("top", [
    "clippedLeft",
    "clippedRight",
    "computedHeight",
    "invertedScroll",
    "isExtended",
    "isProminent",
    "value"
  ])
);
const VAppBar = baseMixins$u.extend({
  name: "v-app-bar",
  directives: { Scroll: Scroll$1 },
  provide() {
    return { VAppBar: this };
  },
  props: {
    clippedLeft: Boolean,
    clippedRight: Boolean,
    collapseOnScroll: Boolean,
    elevateOnScroll: Boolean,
    fadeImgOnScroll: Boolean,
    hideOnScroll: Boolean,
    invertedScroll: Boolean,
    scrollOffScreen: Boolean,
    shrinkOnScroll: Boolean,
    value: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isActive: this.value
    };
  },
  computed: {
    applicationProperty() {
      return !this.bottom ? "top" : "bottom";
    },
    canScroll() {
      return Scrollable.options.computed.canScroll.call(this) && (this.invertedScroll || this.elevateOnScroll || this.hideOnScroll || this.collapseOnScroll || this.isBooted || // If falsy, user has provided an
      // explicit value which should
      // overwrite anything we do
      !this.value);
    },
    classes() {
      return {
        ...VToolbar.options.computed.classes.call(this),
        "v-toolbar--collapse": this.collapse || this.collapseOnScroll,
        "v-app-bar": true,
        "v-app-bar--clipped": this.clippedLeft || this.clippedRight,
        "v-app-bar--fade-img-on-scroll": this.fadeImgOnScroll,
        "v-app-bar--elevate-on-scroll": this.elevateOnScroll,
        "v-app-bar--fixed": !this.absolute && (this.app || this.fixed),
        "v-app-bar--hide-shadow": this.hideShadow,
        "v-app-bar--is-scrolled": this.currentScroll > 0,
        "v-app-bar--shrink-on-scroll": this.shrinkOnScroll
      };
    },
    scrollRatio() {
      const threshold = this.computedScrollThreshold;
      return Math.max((threshold - this.currentScroll) / threshold, 0);
    },
    computedContentHeight() {
      if (!this.shrinkOnScroll)
        return VToolbar.options.computed.computedContentHeight.call(this);
      const min = this.dense ? 48 : 56;
      const max = this.computedOriginalHeight;
      return min + (max - min) * this.scrollRatio;
    },
    computedFontSize() {
      if (!this.isProminent)
        return void 0;
      const min = 1.25;
      const max = 1.5;
      return min + (max - min) * this.scrollRatio;
    },
    computedLeft() {
      if (!this.app || this.clippedLeft)
        return 0;
      return this.$vuetify.application.left;
    },
    computedMarginTop() {
      if (!this.app)
        return 0;
      return this.$vuetify.application.bar;
    },
    computedOpacity() {
      if (!this.fadeImgOnScroll)
        return void 0;
      return this.scrollRatio;
    },
    computedOriginalHeight() {
      let height = VToolbar.options.computed.computedContentHeight.call(this);
      if (this.isExtended)
        height += parseInt(this.extensionHeight);
      return height;
    },
    computedRight() {
      if (!this.app || this.clippedRight)
        return 0;
      return this.$vuetify.application.right;
    },
    computedScrollThreshold() {
      if (this.scrollThreshold)
        return Number(this.scrollThreshold);
      return this.computedOriginalHeight - (this.dense ? 48 : 56);
    },
    computedTransform() {
      if (!this.canScroll || this.elevateOnScroll && this.currentScroll === 0 && this.isActive)
        return 0;
      if (this.isActive)
        return 0;
      const scrollOffScreen = this.scrollOffScreen ? this.computedHeight : this.computedContentHeight;
      return this.bottom ? scrollOffScreen : -scrollOffScreen;
    },
    hideShadow() {
      if (this.elevateOnScroll && this.isExtended) {
        return this.currentScroll < this.computedScrollThreshold;
      }
      if (this.elevateOnScroll) {
        return this.currentScroll === 0 || this.computedTransform < 0;
      }
      return (!this.isExtended || this.scrollOffScreen) && this.computedTransform !== 0;
    },
    isCollapsed() {
      if (!this.collapseOnScroll) {
        return VToolbar.options.computed.isCollapsed.call(this);
      }
      return this.currentScroll > 0;
    },
    isProminent() {
      return VToolbar.options.computed.isProminent.call(this) || this.shrinkOnScroll;
    },
    styles() {
      return {
        ...VToolbar.options.computed.styles.call(this),
        fontSize: convertToUnit(this.computedFontSize, "rem"),
        marginTop: convertToUnit(this.computedMarginTop),
        transform: `translateY(${convertToUnit(this.computedTransform)})`,
        left: convertToUnit(this.computedLeft),
        right: convertToUnit(this.computedRight)
      };
    }
  },
  watch: {
    canScroll: "onScroll",
    computedTransform() {
      if (!this.canScroll || !this.clippedLeft && !this.clippedRight)
        return;
      this.callUpdate();
    },
    invertedScroll(val) {
      this.isActive = !val || this.currentScroll !== 0;
    },
    hideOnScroll(val) {
      this.isActive = !val || this.currentScroll < this.computedScrollThreshold;
    }
  },
  created() {
    if (this.invertedScroll)
      this.isActive = false;
  },
  methods: {
    genBackground() {
      const render = VToolbar.options.methods.genBackground.call(this);
      render.data = this._b(render.data || {}, render.tag, {
        style: { opacity: this.computedOpacity }
      });
      return render;
    },
    updateApplication() {
      return this.invertedScroll ? 0 : this.computedHeight + this.computedTransform;
    },
    thresholdMet() {
      if (this.invertedScroll) {
        this.isActive = this.currentScroll > this.computedScrollThreshold;
        return;
      }
      if (this.hideOnScroll) {
        this.isActive = this.isScrollingUp || this.currentScroll < this.computedScrollThreshold;
      }
      if (this.currentThreshold < this.computedScrollThreshold)
        return;
      this.savedScroll = this.currentScroll;
    }
  },
  render(h) {
    const render = VToolbar.options.render.call(this, h);
    render.data = render.data || {};
    if (this.canScroll) {
      render.data.directives = render.data.directives || [];
      render.data.directives.push({
        arg: this.scrollTarget,
        name: "scroll",
        value: this.onScroll
      });
    }
    return render;
  }
});
const Sizeable = Vue.extend({
  name: "sizeable",
  props: {
    large: Boolean,
    small: Boolean,
    xLarge: Boolean,
    xSmall: Boolean
  },
  computed: {
    medium() {
      return Boolean(
        !this.xSmall && !this.small && !this.large && !this.xLarge
      );
    },
    sizeableClasses() {
      return {
        "v-size--x-small": this.xSmall,
        "v-size--small": this.small,
        "v-size--default": this.medium,
        "v-size--large": this.large,
        "v-size--x-large": this.xLarge
      };
    }
  }
});
var SIZE_MAP = /* @__PURE__ */ ((SIZE_MAP2) => {
  SIZE_MAP2["xSmall"] = "12px";
  SIZE_MAP2["small"] = "16px";
  SIZE_MAP2["default"] = "24px";
  SIZE_MAP2["medium"] = "28px";
  SIZE_MAP2["large"] = "36px";
  SIZE_MAP2["xLarge"] = "40px";
  return SIZE_MAP2;
})(SIZE_MAP || {});
function isFontAwesome5(iconType) {
  return ["fas", "far", "fal", "fab", "fad", "fak"].some((val) => iconType.includes(val));
}
function isSvgPath(icon) {
  return /^[mzlhvcsqta]\s*[-+.0-9][^mlhvzcsqta]+/i.test(icon) && /[\dz]$/i.test(icon) && icon.length > 4;
}
const VIcon = mixins(
  BindsAttrs,
  Colorable,
  Sizeable,
  Themeable$1
  /* @vue/component */
).extend({
  name: "v-icon",
  props: {
    dense: Boolean,
    disabled: Boolean,
    left: Boolean,
    right: Boolean,
    size: [Number, String],
    tag: {
      type: String,
      required: false,
      default: "i"
    }
  },
  computed: {
    medium() {
      return false;
    },
    hasClickListener() {
      return Boolean(
        this.listeners$.click || this.listeners$["!click"]
      );
    }
  },
  methods: {
    getIcon() {
      let iconName = "";
      if (this.$slots.default)
        iconName = this.$slots.default[0].text.trim();
      return remapInternalIcon(this, iconName);
    },
    getSize() {
      const sizes = {
        xSmall: this.xSmall,
        small: this.small,
        medium: this.medium,
        large: this.large,
        xLarge: this.xLarge
      };
      const explicitSize = keys(sizes).find((key) => sizes[key]);
      return explicitSize && SIZE_MAP[explicitSize] || convertToUnit(this.size);
    },
    // Component data for both font icon and SVG wrapper span
    getDefaultData() {
      return {
        staticClass: "v-icon notranslate",
        class: {
          "v-icon--disabled": this.disabled,
          "v-icon--left": this.left,
          "v-icon--link": this.hasClickListener,
          "v-icon--right": this.right,
          "v-icon--dense": this.dense
        },
        attrs: {
          "aria-hidden": !this.hasClickListener,
          disabled: this.hasClickListener && this.disabled,
          type: this.hasClickListener ? "button" : void 0,
          ...this.attrs$
        },
        on: this.listeners$
      };
    },
    getSvgWrapperData() {
      const fontSize = this.getSize();
      const wrapperData = {
        ...this.getDefaultData(),
        style: fontSize ? {
          fontSize,
          height: fontSize,
          width: fontSize
        } : void 0
      };
      this.applyColors(wrapperData);
      return wrapperData;
    },
    applyColors(data) {
      data.class = { ...data.class, ...this.themeClasses };
      this.setTextColor(this.color, data);
    },
    renderFontIcon(icon, h) {
      const newChildren = [];
      const data = this.getDefaultData();
      let iconType = "material-icons";
      const delimiterIndex = icon.indexOf("-");
      const isMaterialIcon = delimiterIndex <= -1;
      if (isMaterialIcon) {
        newChildren.push(icon);
      } else {
        iconType = icon.slice(0, delimiterIndex);
        if (isFontAwesome5(iconType))
          iconType = "";
      }
      data.class[iconType] = true;
      data.class[icon] = !isMaterialIcon;
      const fontSize = this.getSize();
      if (fontSize)
        data.style = { fontSize };
      this.applyColors(data);
      return h(this.hasClickListener ? "button" : this.tag, data, newChildren);
    },
    renderSvgIcon(icon, h) {
      const svgData = {
        class: "v-icon__svg",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 24 24",
          role: "img",
          "aria-hidden": true
        }
      };
      const size = this.getSize();
      if (size) {
        svgData.style = {
          fontSize: size,
          height: size,
          width: size
        };
      }
      return h(this.hasClickListener ? "button" : "span", this.getSvgWrapperData(), [
        h("svg", svgData, [
          h("path", {
            attrs: {
              d: icon
            }
          })
        ])
      ]);
    },
    renderSvgIconComponent(icon, h) {
      const data = {
        class: {
          "v-icon__component": true
        }
      };
      const size = this.getSize();
      if (size) {
        data.style = {
          fontSize: size,
          height: size,
          width: size
        };
      }
      this.applyColors(data);
      const component = icon.component;
      data.props = icon.props;
      data.nativeOn = data.on;
      return h(this.hasClickListener ? "button" : "span", this.getSvgWrapperData(), [
        h(component, data)
      ]);
    }
  },
  render(h) {
    const icon = this.getIcon();
    if (typeof icon === "string") {
      if (isSvgPath(icon)) {
        return this.renderSvgIcon(icon, h);
      }
      return this.renderFontIcon(icon, h);
    }
    return this.renderSvgIconComponent(icon, h);
  }
});
const VIcon$1 = Vue.extend({
  name: "v-icon",
  $_wrapperFor: VIcon,
  functional: true,
  render(h, { data, children }) {
    let iconName = "";
    if (data.domProps) {
      iconName = data.domProps.textContent || data.domProps.innerHTML || iconName;
      delete data.domProps.textContent;
      delete data.domProps.innerHTML;
    }
    return h(VIcon, data, iconName ? [iconName] : children);
  }
});
const VProgressCircular = Colorable.extend({
  name: "v-progress-circular",
  directives: { intersect },
  props: {
    button: Boolean,
    indeterminate: Boolean,
    rotate: {
      type: [Number, String],
      default: 0
    },
    size: {
      type: [Number, String],
      default: 32
    },
    width: {
      type: [Number, String],
      default: 4
    },
    value: {
      type: [Number, String],
      default: 0
    }
  },
  data: () => ({
    radius: 20,
    isVisible: true
  }),
  computed: {
    calculatedSize() {
      return Number(this.size) + (this.button ? 8 : 0);
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    classes() {
      return {
        "v-progress-circular--visible": this.isVisible,
        "v-progress-circular--indeterminate": this.indeterminate,
        "v-progress-circular--button": this.button
      };
    },
    normalizedValue() {
      if (this.value < 0) {
        return 0;
      }
      if (this.value > 100) {
        return 100;
      }
      return parseFloat(this.value);
    },
    strokeDashArray() {
      return Math.round(this.circumference * 1e3) / 1e3;
    },
    strokeDashOffset() {
      return (100 - this.normalizedValue) / 100 * this.circumference + "px";
    },
    strokeWidth() {
      return Number(this.width) / +this.size * this.viewBoxSize * 2;
    },
    styles() {
      return {
        height: convertToUnit(this.calculatedSize),
        width: convertToUnit(this.calculatedSize)
      };
    },
    svgStyles() {
      return {
        transform: `rotate(${Number(this.rotate)}deg)`
      };
    },
    viewBoxSize() {
      return this.radius / (1 - Number(this.width) / +this.size);
    }
  },
  methods: {
    genCircle(name, offset) {
      return this.$createElement("circle", {
        class: `v-progress-circular__${name}`,
        attrs: {
          fill: "transparent",
          cx: 2 * this.viewBoxSize,
          cy: 2 * this.viewBoxSize,
          r: this.radius,
          "stroke-width": this.strokeWidth,
          "stroke-dasharray": this.strokeDashArray,
          "stroke-dashoffset": offset
        }
      });
    },
    genSvg() {
      const children = [
        this.indeterminate || this.genCircle("underlay", 0),
        this.genCircle("overlay", this.strokeDashOffset)
      ];
      return this.$createElement("svg", {
        style: this.svgStyles,
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: `${this.viewBoxSize} ${this.viewBoxSize} ${2 * this.viewBoxSize} ${2 * this.viewBoxSize}`
        }
      }, children);
    },
    genInfo() {
      return this.$createElement("div", {
        staticClass: "v-progress-circular__info"
      }, this.$slots.default);
    },
    onObserve(entries, observer, isIntersecting) {
      this.isVisible = isIntersecting;
    }
  },
  render(h) {
    return h("div", this.setTextColor(this.color, {
      staticClass: "v-progress-circular",
      attrs: {
        role: "progressbar",
        "aria-valuemin": 0,
        "aria-valuemax": 100,
        "aria-valuenow": this.indeterminate ? void 0 : this.normalizedValue
      },
      class: this.classes,
      directives: [{
        name: "intersect",
        value: this.onObserve
      }],
      style: this.styles,
      on: this.$listeners
    }), [
      this.genSvg(),
      this.genInfo()
    ]);
  }
});
function generateWarning(child, parent) {
  return () => consoleWarn(`The ${child} component must be used inside a ${parent}`);
}
function inject(namespace, child, parent) {
  const defaultImpl = child && parent ? {
    register: generateWarning(child, parent),
    unregister: generateWarning(child, parent)
  } : null;
  return Vue.extend({
    name: "registrable-inject",
    inject: {
      [namespace]: {
        default: defaultImpl
      }
    }
  });
}
function provide(namespace, self = false) {
  return Vue.extend({
    name: "registrable-provide",
    provide() {
      return {
        [namespace]: self ? this : {
          register: this.register,
          unregister: this.unregister
        }
      };
    }
  });
}
function factory$1(namespace, child, parent) {
  return inject(namespace, child, parent).extend({
    name: "groupable",
    props: {
      activeClass: {
        type: String,
        default() {
          if (!this[namespace])
            return void 0;
          return this[namespace].activeClass;
        }
      },
      disabled: Boolean
    },
    data() {
      return {
        isActive: false
      };
    },
    computed: {
      groupClasses() {
        if (!this.activeClass)
          return {};
        return {
          [this.activeClass]: this.isActive
        };
      }
    },
    created() {
      this[namespace] && this[namespace].register(this);
    },
    beforeDestroy() {
      this[namespace] && this[namespace].unregister(this);
    },
    methods: {
      toggle(e) {
        if (this.disabled && e) {
          e.preventDefault();
          return;
        }
        this.$emit("change");
      }
    }
  });
}
factory$1("itemGroup");
const Routable = Vue.extend({
  name: "routable",
  directives: {
    Ripple: ripple
  },
  props: {
    activeClass: String,
    append: Boolean,
    disabled: Boolean,
    exact: {
      type: Boolean,
      default: void 0
    },
    exactPath: Boolean,
    exactActiveClass: String,
    link: Boolean,
    href: [String, Object],
    to: [String, Object],
    nuxt: Boolean,
    replace: Boolean,
    ripple: {
      type: [Boolean, Object],
      default: null
    },
    tag: String,
    target: String
  },
  data: () => ({
    isActive: false,
    proxyClass: ""
  }),
  computed: {
    classes() {
      const classes = {};
      if (this.to)
        return classes;
      if (this.activeClass)
        classes[this.activeClass] = this.isActive;
      if (this.proxyClass)
        classes[this.proxyClass] = this.isActive;
      return classes;
    },
    computedRipple() {
      return this.ripple ?? (!this.disabled && this.isClickable);
    },
    isClickable() {
      if (this.disabled)
        return false;
      return Boolean(
        this.isLink || this.$listeners.click || this.$listeners["!click"] || this.$attrs.tabindex
      );
    },
    isLink() {
      return this.to || this.href || this.link;
    },
    styles: () => ({})
  },
  watch: {
    $route: "onRouteChange"
  },
  mounted() {
    this.onRouteChange();
  },
  methods: {
    generateRouteLink() {
      let exact = this.exact;
      let tag;
      const data = {
        attrs: {
          tabindex: "tabindex" in this.$attrs ? this.$attrs.tabindex : void 0
        },
        class: this.classes,
        style: this.styles,
        props: {},
        directives: [{
          name: "ripple",
          value: this.computedRipple
        }],
        [this.to ? "nativeOn" : "on"]: {
          ...this.$listeners,
          ..."click" in this ? { click: this.click } : void 0
          // #14447
        },
        ref: "link"
      };
      if (typeof this.exact === "undefined") {
        exact = this.to === "/" || this.to === Object(this.to) && this.to.path === "/";
      }
      if (this.to) {
        let activeClass = this.activeClass;
        let exactActiveClass = this.exactActiveClass || activeClass;
        if (this.proxyClass) {
          activeClass = `${activeClass} ${this.proxyClass}`.trim();
          exactActiveClass = `${exactActiveClass} ${this.proxyClass}`.trim();
        }
        tag = this.nuxt ? "nuxt-link" : "router-link";
        Object.assign(data.props, {
          to: this.to,
          exact,
          exactPath: this.exactPath,
          activeClass,
          exactActiveClass,
          append: this.append,
          replace: this.replace
        });
      } else {
        tag = this.href && "a" || this.tag || "div";
        if (tag === "a" && this.href)
          data.attrs.href = this.href;
      }
      if (this.target)
        data.attrs.target = this.target;
      return { tag, data };
    },
    onRouteChange() {
      if (!this.to || !this.$refs.link || !this.$route)
        return;
      const activeClass = `${this.activeClass || ""} ${this.proxyClass || ""}`.trim();
      const exactActiveClass = `${this.exactActiveClass || ""} ${this.proxyClass || ""}`.trim() || activeClass;
      const path = "_vnode.data.class." + (this.exact ? exactActiveClass : activeClass);
      this.$nextTick(() => {
        if (!getObjectValueByPath(this.$refs.link, path) === this.isActive) {
          this.toggle();
        }
      });
    },
    toggle() {
      this.isActive = !this.isActive;
    }
  }
});
const baseMixins$t = mixins(
  VSheet,
  Routable,
  Positionable,
  Sizeable,
  factory$1("btnToggle"),
  factory$2("inputValue")
  /* @vue/component */
);
const VBtn = baseMixins$t.extend().extend({
  name: "v-btn",
  props: {
    activeClass: {
      type: String,
      default() {
        if (!this.btnToggle)
          return "";
        return this.btnToggle.activeClass;
      }
    },
    block: Boolean,
    depressed: Boolean,
    fab: Boolean,
    icon: Boolean,
    loading: Boolean,
    outlined: Boolean,
    plain: Boolean,
    retainFocusOnClick: Boolean,
    rounded: Boolean,
    tag: {
      type: String,
      default: "button"
    },
    text: Boolean,
    tile: Boolean,
    type: {
      type: String,
      default: "button"
    },
    value: null
  },
  data: () => ({
    proxyClass: "v-btn--active"
  }),
  computed: {
    classes() {
      return {
        "v-btn": true,
        ...Routable.options.computed.classes.call(this),
        "v-btn--absolute": this.absolute,
        "v-btn--block": this.block,
        "v-btn--bottom": this.bottom,
        "v-btn--disabled": this.disabled,
        "v-btn--is-elevated": this.isElevated,
        "v-btn--fab": this.fab,
        "v-btn--fixed": this.fixed,
        "v-btn--has-bg": this.hasBg,
        "v-btn--icon": this.icon,
        "v-btn--left": this.left,
        "v-btn--loading": this.loading,
        "v-btn--outlined": this.outlined,
        "v-btn--plain": this.plain,
        "v-btn--right": this.right,
        "v-btn--round": this.isRound,
        "v-btn--rounded": this.rounded,
        "v-btn--router": this.to,
        "v-btn--text": this.text,
        "v-btn--tile": this.tile,
        "v-btn--top": this.top,
        ...this.themeClasses,
        ...this.groupClasses,
        ...this.elevationClasses,
        ...this.sizeableClasses
      };
    },
    computedElevation() {
      if (this.disabled)
        return void 0;
      return Elevatable.options.computed.computedElevation.call(this);
    },
    computedRipple() {
      const defaultRipple = this.icon || this.fab ? { circle: true } : true;
      if (this.disabled)
        return false;
      else
        return this.ripple ?? defaultRipple;
    },
    hasBg() {
      return !this.text && !this.plain && !this.outlined && !this.icon;
    },
    isElevated() {
      return Boolean(
        !this.icon && !this.text && !this.outlined && !this.depressed && !this.disabled && !this.plain && (this.elevation == null || Number(this.elevation) > 0)
      );
    },
    isRound() {
      return Boolean(
        this.icon || this.fab
      );
    },
    styles() {
      return {
        ...this.measurableStyles
      };
    }
  },
  created() {
    const breakingProps = [
      ["flat", "text"],
      ["outline", "outlined"],
      ["round", "rounded"]
    ];
    breakingProps.forEach(([original, replacement]) => {
      if (this.$attrs.hasOwnProperty(original))
        breaking(original, replacement, this);
    });
  },
  methods: {
    click(e) {
      !this.retainFocusOnClick && !this.fab && e.detail && this.$el.blur();
      this.$emit("click", e);
      this.btnToggle && this.toggle();
    },
    genContent() {
      return this.$createElement("span", {
        staticClass: "v-btn__content"
      }, this.$slots.default);
    },
    genLoader() {
      return this.$createElement("span", {
        class: "v-btn__loader"
      }, this.$slots.loader || [this.$createElement(VProgressCircular, {
        props: {
          indeterminate: true,
          size: 23,
          width: 2
        }
      })]);
    }
  },
  render(h) {
    const children = [
      this.genContent(),
      this.loading && this.genLoader()
    ];
    const { tag, data } = this.generateRouteLink();
    const setColor = this.hasBg ? this.setBackgroundColor : this.setTextColor;
    if (tag === "button") {
      data.attrs.type = this.type;
      data.attrs.disabled = this.disabled;
    }
    data.attrs.value = ["string", "number"].includes(typeof this.value) ? this.value : JSON.stringify(this.value);
    return h(tag, this.disabled ? data : setColor(this.color, data), children);
  }
});
const VAppBarNavIcon = Vue.extend({
  name: "v-app-bar-nav-icon",
  functional: true,
  render(h, { slots, listeners, props: props2, data }) {
    const d = Object.assign(data, {
      staticClass: `v-app-bar__nav-icon ${data.staticClass || ""}`.trim(),
      props: {
        ...props2,
        icon: true
      },
      on: listeners
    });
    const defaultSlot = slots().default;
    return h(VBtn, d, defaultSlot || [h(VIcon$1, "$menu")]);
  }
});
const base = inject("VAppBar", "v-app-bar-title", "v-app-bar");
const VAppBarTitle = base.extend().extend({
  name: "v-app-bar-title",
  data: () => ({
    contentWidth: 0,
    left: 0,
    width: 0
  }),
  watch: {
    "$vuetify.breakpoint.width": "updateDimensions"
  },
  computed: {
    styles() {
      if (!this.contentWidth)
        return {};
      const min = this.width;
      const max = this.contentWidth;
      const ratio = easeInOutCubic(Math.min(1, this.VAppBar.scrollRatio * 1.5));
      return {
        width: convertToUnit(min + (max - min) * ratio),
        visibility: this.VAppBar.scrollRatio ? "visible" : "hidden"
      };
    }
  },
  mounted() {
    this.updateDimensions();
  },
  methods: {
    updateDimensions() {
      const dimensions = this.$refs.placeholder.getBoundingClientRect();
      this.width = dimensions.width;
      this.left = dimensions.left;
      this.contentWidth = this.$refs.content.scrollWidth;
    }
  },
  render(h) {
    return h("div", {
      class: "v-toolbar__title v-app-bar-title"
    }, [
      h("div", {
        class: "v-app-bar-title__content",
        style: this.styles,
        ref: "content"
      }, [this.$slots.default]),
      h("div", {
        class: "v-app-bar-title__placeholder",
        style: {
          visibility: this.VAppBar.scrollRatio ? "hidden" : "visible"
        },
        ref: "placeholder"
      }, [this.$slots.default])
    ]);
  }
});
const Transitionable = Vue.extend({
  name: "transitionable",
  props: {
    mode: String,
    origin: String,
    transition: String
  }
});
const VAlert = mixins(
  VSheet,
  Toggleable$1,
  Transitionable
).extend({
  name: "v-alert",
  props: {
    border: {
      type: String,
      validator(val) {
        return [
          "top",
          "right",
          "bottom",
          "left"
        ].includes(val);
      }
    },
    closeLabel: {
      type: String,
      default: "$vuetify.close"
    },
    coloredBorder: Boolean,
    dense: Boolean,
    dismissible: Boolean,
    closeIcon: {
      type: String,
      default: "$cancel"
    },
    icon: {
      default: "",
      type: [Boolean, String],
      validator(val) {
        return typeof val === "string" || val === false;
      }
    },
    outlined: Boolean,
    prominent: Boolean,
    text: Boolean,
    type: {
      type: String,
      validator(val) {
        return [
          "info",
          "error",
          "success",
          "warning"
        ].includes(val);
      }
    },
    value: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    __cachedBorder() {
      if (!this.border)
        return null;
      let data = {
        staticClass: "v-alert__border",
        class: {
          [`v-alert__border--${this.border}`]: true
        }
      };
      if (this.coloredBorder) {
        data = this.setBackgroundColor(this.computedColor, data);
        data.class["v-alert__border--has-color"] = true;
      }
      return this.$createElement("div", data);
    },
    __cachedDismissible() {
      if (!this.dismissible)
        return null;
      const color = this.iconColor;
      return this.$createElement(VBtn, {
        staticClass: "v-alert__dismissible",
        props: {
          color,
          icon: true,
          small: true
        },
        attrs: {
          "aria-label": this.$vuetify.lang.t(this.closeLabel)
        },
        on: {
          click: () => this.isActive = false
        }
      }, [
        this.$createElement(VIcon$1, {
          props: { color }
        }, this.closeIcon)
      ]);
    },
    __cachedIcon() {
      if (!this.computedIcon)
        return null;
      return this.$createElement(VIcon$1, {
        staticClass: "v-alert__icon",
        props: { color: this.iconColor }
      }, this.computedIcon);
    },
    classes() {
      const classes = {
        ...VSheet.options.computed.classes.call(this),
        "v-alert--border": Boolean(this.border),
        "v-alert--dense": this.dense,
        "v-alert--outlined": this.outlined,
        "v-alert--prominent": this.prominent,
        "v-alert--text": this.text
      };
      if (this.border) {
        classes[`v-alert--border-${this.border}`] = true;
      }
      return classes;
    },
    computedColor() {
      return this.color || this.type;
    },
    computedIcon() {
      if (this.icon === false)
        return false;
      if (typeof this.icon === "string" && this.icon)
        return this.icon;
      if (!["error", "info", "success", "warning"].includes(this.type))
        return false;
      return `$${this.type}`;
    },
    hasColoredIcon() {
      return this.hasText || Boolean(this.border) && this.coloredBorder;
    },
    hasText() {
      return this.text || this.outlined;
    },
    iconColor() {
      return this.hasColoredIcon ? this.computedColor : void 0;
    },
    isDark() {
      if (this.type && !this.coloredBorder && !this.outlined)
        return true;
      return Themeable$1.options.computed.isDark.call(this);
    }
  },
  created() {
    if (this.$attrs.hasOwnProperty("outline")) {
      breaking("outline", "outlined", this);
    }
  },
  methods: {
    genWrapper() {
      const children = [
        this.$slots.prepend || this.__cachedIcon,
        this.genContent(),
        this.__cachedBorder,
        this.$slots.append,
        this.$scopedSlots.close ? this.$scopedSlots.close({ toggle: this.toggle }) : this.__cachedDismissible
      ];
      const data = {
        staticClass: "v-alert__wrapper"
      };
      return this.$createElement("div", data, children);
    },
    genContent() {
      return this.$createElement("div", {
        staticClass: "v-alert__content"
      }, this.$slots.default);
    },
    genAlert() {
      let data = {
        staticClass: "v-alert",
        attrs: {
          role: "alert"
        },
        on: this.listeners$,
        class: this.classes,
        style: this.styles,
        directives: [{
          name: "show",
          value: this.isActive
        }]
      };
      if (!this.coloredBorder) {
        const setColor = this.hasText ? this.setTextColor : this.setBackgroundColor;
        data = setColor(this.computedColor, data);
      }
      return this.$createElement("div", data, [this.genWrapper()]);
    },
    /** @public */
    toggle() {
      this.isActive = !this.isActive;
    }
  },
  render(h) {
    const render = this.genAlert();
    if (!this.transition)
      return render;
    return h("transition", {
      props: {
        name: this.transition,
        origin: this.origin,
        mode: this.mode
      }
    }, [render]);
  }
});
function mergeTransitions(dest = [], ...transitions) {
  return Array().concat(dest, ...transitions);
}
function createSimpleTransition(name, origin = "top center 0", mode) {
  return {
    name,
    functional: true,
    props: {
      group: {
        type: Boolean,
        default: false
      },
      hideOnLeave: {
        type: Boolean,
        default: false
      },
      leaveAbsolute: {
        type: Boolean,
        default: false
      },
      mode: {
        type: String,
        default: mode
      },
      origin: {
        type: String,
        default: origin
      }
    },
    render(h, context) {
      const tag = `transition${context.props.group ? "-group" : ""}`;
      const data = {
        props: {
          name,
          mode: context.props.mode
        },
        on: {
          beforeEnter(el) {
            el.style.transformOrigin = context.props.origin;
            el.style.webkitTransformOrigin = context.props.origin;
          }
        }
      };
      if (context.props.leaveAbsolute) {
        data.on.leave = mergeTransitions(data.on.leave, (el) => {
          const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = el;
          el._transitionInitialStyles = {
            position: el.style.position,
            top: el.style.top,
            left: el.style.left,
            width: el.style.width,
            height: el.style.height
          };
          el.style.position = "absolute";
          el.style.top = offsetTop + "px";
          el.style.left = offsetLeft + "px";
          el.style.width = offsetWidth + "px";
          el.style.height = offsetHeight + "px";
        });
        data.on.afterLeave = mergeTransitions(data.on.afterLeave, (el) => {
          if (el && el._transitionInitialStyles) {
            const { position, top, left, width, height } = el._transitionInitialStyles;
            delete el._transitionInitialStyles;
            el.style.position = position || "";
            el.style.top = top || "";
            el.style.left = left || "";
            el.style.width = width || "";
            el.style.height = height || "";
          }
        });
      }
      if (context.props.hideOnLeave) {
        data.on.leave = mergeTransitions(data.on.leave, (el) => {
          el.style.setProperty("display", "none", "important");
        });
      }
      return h(tag, mergeData(context.data, data), context.children);
    }
  };
}
function createJavascriptTransition(name, functions, mode = "in-out") {
  return {
    name,
    functional: true,
    props: {
      mode: {
        type: String,
        default: mode
      }
    },
    render(h, context) {
      return h(
        "transition",
        mergeData(context.data, {
          props: { name },
          on: functions
        }),
        context.children
      );
    }
  };
}
function ExpandTransitionGenerator(expandedParentClass = "", x = false) {
  const sizeProperty = x ? "width" : "height";
  const offsetProperty = `offset${upperFirst(sizeProperty)}`;
  return {
    beforeEnter(el) {
      el._parent = el.parentNode;
      el._initialStyle = {
        transition: el.style.transition,
        overflow: el.style.overflow,
        [sizeProperty]: el.style[sizeProperty]
      };
    },
    enter(el) {
      const initialStyle = el._initialStyle;
      el.style.setProperty("transition", "none", "important");
      el.style.overflow = "hidden";
      const offset = `${el[offsetProperty]}px`;
      el.style[sizeProperty] = "0";
      void el.offsetHeight;
      el.style.transition = initialStyle.transition;
      if (expandedParentClass && el._parent) {
        el._parent.classList.add(expandedParentClass);
      }
      requestAnimationFrame(() => {
        el.style[sizeProperty] = offset;
      });
    },
    afterEnter: resetStyles,
    enterCancelled: resetStyles,
    leave(el) {
      el._initialStyle = {
        transition: "",
        overflow: el.style.overflow,
        [sizeProperty]: el.style[sizeProperty]
      };
      el.style.overflow = "hidden";
      el.style[sizeProperty] = `${el[offsetProperty]}px`;
      void el.offsetHeight;
      requestAnimationFrame(() => el.style[sizeProperty] = "0");
    },
    afterLeave,
    leaveCancelled: afterLeave
  };
  function afterLeave(el) {
    if (expandedParentClass && el._parent) {
      el._parent.classList.remove(expandedParentClass);
    }
    resetStyles(el);
  }
  function resetStyles(el) {
    const size = el._initialStyle[sizeProperty];
    el.style.overflow = el._initialStyle.overflow;
    if (size != null)
      el.style[sizeProperty] = size;
    delete el._initialStyle;
  }
}
const VCarouselTransition = createSimpleTransition("carousel-transition");
const VCarouselReverseTransition = createSimpleTransition("carousel-reverse-transition");
const VTabTransition = createSimpleTransition("tab-transition");
const VTabReverseTransition = createSimpleTransition("tab-reverse-transition");
const VMenuTransition = createSimpleTransition("menu-transition");
const VFabTransition = createSimpleTransition("fab-transition", "center center", "out-in");
const VDialogTransition = createSimpleTransition("dialog-transition");
const VDialogBottomTransition = createSimpleTransition("dialog-bottom-transition");
const VDialogTopTransition = createSimpleTransition("dialog-top-transition");
const VFadeTransition = createSimpleTransition("fade-transition");
const VScaleTransition = createSimpleTransition("scale-transition");
const VScrollXTransition = createSimpleTransition("scroll-x-transition");
const VScrollXReverseTransition = createSimpleTransition("scroll-x-reverse-transition");
const VScrollYTransition = createSimpleTransition("scroll-y-transition");
const VScrollYReverseTransition = createSimpleTransition("scroll-y-reverse-transition");
const VSlideXTransition = createSimpleTransition("slide-x-transition");
const VSlideXReverseTransition = createSimpleTransition("slide-x-reverse-transition");
const VSlideYTransition = createSimpleTransition("slide-y-transition");
const VSlideYReverseTransition = createSimpleTransition("slide-y-reverse-transition");
const VExpandTransition = createJavascriptTransition("expand-transition", ExpandTransitionGenerator());
const VExpandXTransition = createJavascriptTransition("expand-x-transition", ExpandTransitionGenerator("", true));
const VChip = mixins(
  Colorable,
  Sizeable,
  Routable,
  Themeable$1,
  factory$1("chipGroup"),
  factory$2("inputValue")
).extend({
  name: "v-chip",
  props: {
    active: {
      type: Boolean,
      default: true
    },
    activeClass: {
      type: String,
      default() {
        if (!this.chipGroup)
          return "";
        return this.chipGroup.activeClass;
      }
    },
    close: Boolean,
    closeIcon: {
      type: String,
      default: "$delete"
    },
    closeLabel: {
      type: String,
      default: "$vuetify.close"
    },
    disabled: Boolean,
    draggable: Boolean,
    filter: Boolean,
    filterIcon: {
      type: String,
      default: "$complete"
    },
    label: Boolean,
    link: Boolean,
    outlined: Boolean,
    pill: Boolean,
    tag: {
      type: String,
      default: "span"
    },
    textColor: String,
    value: null
  },
  data: () => ({
    proxyClass: "v-chip--active"
  }),
  computed: {
    classes() {
      return {
        "v-chip": true,
        ...Routable.options.computed.classes.call(this),
        "v-chip--clickable": this.isClickable,
        "v-chip--disabled": this.disabled,
        "v-chip--draggable": this.draggable,
        "v-chip--label": this.label,
        "v-chip--link": this.isLink,
        "v-chip--no-color": !this.color,
        "v-chip--outlined": this.outlined,
        "v-chip--pill": this.pill,
        "v-chip--removable": this.hasClose,
        ...this.themeClasses,
        ...this.sizeableClasses,
        ...this.groupClasses
      };
    },
    hasClose() {
      return Boolean(this.close);
    },
    isClickable() {
      return Boolean(
        Routable.options.computed.isClickable.call(this) || this.chipGroup
      );
    }
  },
  created() {
    const breakingProps = [
      ["outline", "outlined"],
      ["selected", "input-value"],
      ["value", "active"],
      ["@input", "@active.sync"]
    ];
    breakingProps.forEach(([original, replacement]) => {
      if (this.$attrs.hasOwnProperty(original))
        breaking(original, replacement, this);
    });
  },
  methods: {
    click(e) {
      this.$emit("click", e);
      this.chipGroup && this.toggle();
    },
    genFilter() {
      const children = [];
      if (this.isActive) {
        children.push(
          this.$createElement(VIcon$1, {
            staticClass: "v-chip__filter",
            props: { left: true }
          }, this.filterIcon)
        );
      }
      return this.$createElement(VExpandXTransition, children);
    },
    genClose() {
      return this.$createElement(VIcon$1, {
        staticClass: "v-chip__close",
        props: {
          right: true,
          size: 18
        },
        attrs: {
          "aria-label": this.$vuetify.lang.t(this.closeLabel)
        },
        on: {
          click: (e) => {
            e.stopPropagation();
            e.preventDefault();
            this.$emit("click:close");
            this.$emit("update:active", false);
          }
        }
      }, this.closeIcon);
    },
    genContent() {
      return this.$createElement("span", {
        staticClass: "v-chip__content"
      }, [
        this.filter && this.genFilter(),
        this.$slots.default,
        this.hasClose && this.genClose()
      ]);
    }
  },
  render(h) {
    const children = [this.genContent()];
    let { tag, data } = this.generateRouteLink();
    data.attrs = {
      ...data.attrs,
      draggable: this.draggable ? "true" : void 0,
      tabindex: this.chipGroup && !this.disabled ? 0 : data.attrs.tabindex
    };
    data.directives.push({
      name: "show",
      value: this.active
    });
    data = this.setBackgroundColor(this.color, data);
    const color = this.textColor || this.outlined && this.color;
    return h(tag, this.setTextColor(color, data), children);
  }
});
const VThemeProvider = Themeable$1.extend({
  name: "v-theme-provider",
  props: { root: Boolean },
  computed: {
    isDark() {
      return this.root ? this.rootIsDark : Themeable$1.options.computed.isDark.call(this);
    }
  },
  render() {
    return this.$slots.default && this.$slots.default.find((node) => !node.isComment && node.text !== " ");
  }
});
const Delayable = Vue.extend().extend({
  name: "delayable",
  props: {
    openDelay: {
      type: [Number, String],
      default: 0
    },
    closeDelay: {
      type: [Number, String],
      default: 0
    }
  },
  data: () => ({
    openTimeout: void 0,
    closeTimeout: void 0
  }),
  methods: {
    /**
     * Clear any pending delay timers from executing
     */
    clearDelay() {
      clearTimeout(this.openTimeout);
      clearTimeout(this.closeTimeout);
    },
    /**
     * Runs callback after a specified delay
     */
    runDelay(type2, cb) {
      this.clearDelay();
      const delay = parseInt(this[`${type2}Delay`], 10);
      this[`${type2}Timeout`] = setTimeout(cb || (() => {
        this.isActive = { open: true, close: false }[type2];
      }), delay);
    }
  }
});
const baseMixins$s = mixins(
  Delayable,
  Toggleable$1
);
const Activatable = baseMixins$s.extend({
  name: "activatable",
  props: {
    activator: {
      default: null,
      validator: (val) => {
        return ["string", "object"].includes(typeof val);
      }
    },
    disabled: Boolean,
    internalActivator: Boolean,
    openOnClick: {
      type: Boolean,
      default: true
    },
    openOnHover: Boolean,
    openOnFocus: Boolean
  },
  data: () => ({
    // Do not use this directly, call getActivator() instead
    activatorElement: null,
    activatorNode: [],
    events: ["click", "mouseenter", "mouseleave", "focus"],
    listeners: {}
  }),
  watch: {
    activator: "resetActivator",
    openOnFocus: "resetActivator",
    openOnHover: "resetActivator"
  },
  mounted() {
    const slotType = getSlotType(this, "activator", true);
    if (slotType && ["v-slot", "normal"].includes(slotType)) {
      consoleError(`The activator slot must be bound, try '<template v-slot:activator="{ on }"><v-btn v-on="on">'`, this);
    }
    this.addActivatorEvents();
  },
  beforeDestroy() {
    this.removeActivatorEvents();
  },
  methods: {
    addActivatorEvents() {
      if (!this.activator || this.disabled || !this.getActivator())
        return;
      this.listeners = this.genActivatorListeners();
      const keys2 = Object.keys(this.listeners);
      for (const key of keys2) {
        this.getActivator().addEventListener(key, this.listeners[key]);
      }
    },
    genActivator() {
      const node = getSlot(this, "activator", Object.assign(this.getValueProxy(), {
        on: this.genActivatorListeners(),
        attrs: this.genActivatorAttributes()
      })) || [];
      this.activatorNode = node;
      return node;
    },
    genActivatorAttributes() {
      return {
        role: this.openOnClick && !this.openOnHover ? "button" : void 0,
        "aria-haspopup": true,
        "aria-expanded": String(this.isActive)
      };
    },
    genActivatorListeners() {
      if (this.disabled)
        return {};
      const listeners = {};
      if (this.openOnHover) {
        listeners.mouseenter = (e) => {
          this.getActivator(e);
          this.runDelay("open");
        };
        listeners.mouseleave = (e) => {
          this.getActivator(e);
          this.runDelay("close");
        };
      } else if (this.openOnClick) {
        listeners.click = (e) => {
          const activator = this.getActivator(e);
          if (activator)
            activator.focus();
          e.stopPropagation();
          this.isActive = !this.isActive;
        };
      }
      if (this.openOnFocus) {
        listeners.focus = (e) => {
          this.getActivator(e);
          e.stopPropagation();
          this.isActive = !this.isActive;
        };
      }
      return listeners;
    },
    getActivator(e) {
      if (this.activatorElement)
        return this.activatorElement;
      let activator = null;
      if (this.activator) {
        const target = this.internalActivator ? this.$el : document;
        if (typeof this.activator === "string") {
          activator = target.querySelector(this.activator);
        } else if (this.activator.$el) {
          activator = this.activator.$el;
        } else {
          activator = this.activator;
        }
      } else if (this.activatorNode.length === 1 || this.activatorNode.length && !e) {
        const vm = this.activatorNode[0].componentInstance;
        if (vm && vm.$options.mixins && //                         Activatable is indirectly used via Menuable
        vm.$options.mixins.some((m) => m.options && ["activatable", "menuable"].includes(m.options.name))) {
          activator = vm.getActivator();
        } else {
          activator = this.activatorNode[0].elm;
        }
      } else if (e) {
        activator = e.currentTarget || e.target;
      }
      this.activatorElement = activator?.nodeType === Node.ELEMENT_NODE ? activator : null;
      return this.activatorElement;
    },
    getContentSlot() {
      return getSlot(this, "default", this.getValueProxy(), true);
    },
    getValueProxy() {
      const self = this;
      return {
        get value() {
          return self.isActive;
        },
        set value(isActive) {
          self.isActive = isActive;
        }
      };
    },
    removeActivatorEvents() {
      if (!this.activator || !this.activatorElement)
        return;
      const keys2 = Object.keys(this.listeners);
      for (const key of keys2) {
        this.activatorElement.removeEventListener(key, this.listeners[key]);
      }
      this.listeners = {};
    },
    resetActivator() {
      this.removeActivatorEvents();
      this.activatorElement = null;
      this.getActivator();
      this.addActivatorEvents();
    }
  }
});
function searchChildren(children) {
  const results = [];
  for (let index = 0; index < children.length; index++) {
    const child = children[index];
    if (child.isActive && child.isDependent) {
      results.push(child);
    } else {
      results.push(...searchChildren(child.$children));
    }
  }
  return results;
}
const Dependent = mixins().extend({
  name: "dependent",
  data() {
    return {
      closeDependents: true,
      isActive: false,
      isDependent: true
    };
  },
  watch: {
    isActive(val) {
      if (val)
        return;
      const openDependents = this.getOpenDependents();
      for (let index = 0; index < openDependents.length; index++) {
        openDependents[index].isActive = false;
      }
    }
  },
  methods: {
    getOpenDependents() {
      if (this.closeDependents)
        return searchChildren(this.$children);
      return [];
    },
    getOpenDependentElements() {
      const result = [];
      const openDependents = this.getOpenDependents();
      for (let index = 0; index < openDependents.length; index++) {
        result.push(...openDependents[index].getClickableDependentElements());
      }
      return result;
    },
    getClickableDependentElements() {
      const result = [this.$el];
      if (this.$refs.content)
        result.push(this.$refs.content);
      if (this.overlay)
        result.push(this.overlay.$el);
      result.push(...this.getOpenDependentElements());
      return result;
    }
  }
});
const Stackable = Vue.extend().extend({
  name: "stackable",
  data() {
    return {
      stackElement: null,
      stackExclude: null,
      stackMinZIndex: 0,
      isActive: false
    };
  },
  computed: {
    activeZIndex() {
      if (typeof window === "undefined")
        return 0;
      const content = this.stackElement || this.$refs.content;
      const index = !this.isActive ? getZIndex(content) : this.getMaxZIndex(this.stackExclude || [content]) + 2;
      if (index == null)
        return index;
      return parseInt(index);
    }
  },
  methods: {
    getMaxZIndex(exclude = []) {
      const base2 = this.$el;
      const zis = [this.stackMinZIndex, getZIndex(base2)];
      const activeElements = [
        ...document.getElementsByClassName("v-menu__content--active"),
        ...document.getElementsByClassName("v-dialog__content--active")
      ];
      for (let index = 0; index < activeElements.length; index++) {
        if (!exclude.includes(activeElements[index])) {
          zis.push(getZIndex(activeElements[index]));
        }
      }
      return Math.max(...zis);
    }
  }
});
const Bootable = Vue.extend().extend({
  name: "bootable",
  props: {
    eager: Boolean
  },
  data: () => ({
    isBooted: false
  }),
  computed: {
    hasContent() {
      return this.isBooted || this.eager || this.isActive;
    }
  },
  watch: {
    isActive() {
      this.isBooted = true;
    }
  },
  created() {
    if ("lazy" in this.$attrs) {
      removed("lazy", this);
    }
  },
  methods: {
    showLazyContent(content) {
      return this.hasContent && content ? content() : [this.$createElement()];
    }
  }
});
function validateAttachTarget(val) {
  const type2 = typeof val;
  if (type2 === "boolean" || type2 === "string")
    return true;
  return val.nodeType === Node.ELEMENT_NODE;
}
function removeActivator(activator) {
  activator.forEach((node) => {
    node.elm && node.elm.parentNode && node.elm.parentNode.removeChild(node.elm);
  });
}
const Detachable = mixins(Bootable).extend({
  name: "detachable",
  props: {
    attach: {
      default: false,
      validator: validateAttachTarget
    },
    contentClass: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    activatorNode: null,
    hasDetached: false
  }),
  watch: {
    attach() {
      this.hasDetached = false;
      this.initDetach();
    },
    hasContent() {
      this.$nextTick(this.initDetach);
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      if (this.activatorNode) {
        const activator = Array.isArray(this.activatorNode) ? this.activatorNode : [this.activatorNode];
        activator.forEach((node) => {
          if (!node.elm)
            return;
          if (!this.$el.parentNode)
            return;
          const target = this.$el === this.$el.parentNode.firstChild ? this.$el : this.$el.nextSibling;
          this.$el.parentNode.insertBefore(node.elm, target);
        });
      }
    });
  },
  mounted() {
    this.hasContent && this.initDetach();
  },
  deactivated() {
    this.isActive = false;
  },
  beforeDestroy() {
    if (this.$refs.content && this.$refs.content.parentNode) {
      this.$refs.content.parentNode.removeChild(this.$refs.content);
    }
  },
  destroyed() {
    if (this.activatorNode) {
      const activator = Array.isArray(this.activatorNode) ? this.activatorNode : [this.activatorNode];
      if (this.$el.isConnected) {
        const observer = new MutationObserver((list) => {
          if (list.some((record) => Array.from(record.removedNodes).includes(this.$el))) {
            observer.disconnect();
            removeActivator(activator);
          }
        });
        observer.observe(this.$el.parentNode, { subtree: false, childList: true });
      } else {
        removeActivator(activator);
      }
    }
  },
  methods: {
    getScopeIdAttrs() {
      const scopeId = getObjectValueByPath(this.$vnode, "context.$options._scopeId");
      return scopeId && {
        [scopeId]: ""
      };
    },
    initDetach() {
      if (this._isDestroyed || !this.$refs.content || this.hasDetached || // Leave menu in place if attached
      // and dev has not changed target
      this.attach === "" || // If used as a boolean prop (<v-menu attach>)
      this.attach === true || // If bound to a boolean (<v-menu :attach="true">)
      this.attach === "attach")
        return;
      let target;
      if (this.attach === false) {
        target = document.querySelector("[data-app]");
      } else if (typeof this.attach === "string") {
        target = document.querySelector(this.attach);
      } else {
        target = this.attach;
      }
      if (!target) {
        consoleWarn(`Unable to locate target ${this.attach || "[data-app]"}`, this);
        return;
      }
      target.appendChild(this.$refs.content);
      this.hasDetached = true;
    }
  }
});
const baseMixins$r = mixins(
  Stackable,
  factory$3(["top", "right", "bottom", "left", "absolute"]),
  Activatable,
  Detachable
);
const Menuable = baseMixins$r.extend().extend({
  name: "menuable",
  props: {
    allowOverflow: Boolean,
    light: Boolean,
    dark: Boolean,
    maxWidth: {
      type: [Number, String],
      default: "auto"
    },
    minWidth: [Number, String],
    nudgeBottom: {
      type: [Number, String],
      default: 0
    },
    nudgeLeft: {
      type: [Number, String],
      default: 0
    },
    nudgeRight: {
      type: [Number, String],
      default: 0
    },
    nudgeTop: {
      type: [Number, String],
      default: 0
    },
    nudgeWidth: {
      type: [Number, String],
      default: 0
    },
    offsetOverflow: Boolean,
    positionX: {
      type: Number,
      default: null
    },
    positionY: {
      type: Number,
      default: null
    },
    zIndex: {
      type: [Number, String],
      default: null
    }
  },
  data: () => ({
    activatorNode: [],
    absoluteX: 0,
    absoluteY: 0,
    activatedBy: null,
    activatorFixed: false,
    dimensions: {
      activator: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: 0,
        height: 0,
        offsetTop: 0,
        scrollHeight: 0,
        offsetLeft: 0
      },
      content: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: 0,
        height: 0,
        offsetTop: 0,
        scrollHeight: 0
      }
    },
    relativeYOffset: 0,
    hasJustFocused: false,
    hasWindow: false,
    inputActivator: false,
    isContentActive: false,
    pageWidth: 0,
    pageYOffset: 0,
    stackClass: "v-menu__content--active",
    stackMinZIndex: 6
  }),
  computed: {
    computedLeft() {
      const a = this.dimensions.activator;
      const c = this.dimensions.content;
      const activatorLeft = (this.attach !== false ? a.offsetLeft : a.left) || 0;
      const minWidth = Math.max(a.width, c.width);
      let left = 0;
      left += activatorLeft;
      if (this.left || this.$vuetify.rtl && !this.right)
        left -= minWidth - a.width;
      if (this.offsetX) {
        const maxWidth = isNaN(Number(this.maxWidth)) ? a.width : Math.min(a.width, Number(this.maxWidth));
        left += this.left ? -maxWidth : a.width;
      }
      if (this.nudgeLeft)
        left -= parseInt(this.nudgeLeft);
      if (this.nudgeRight)
        left += parseInt(this.nudgeRight);
      return left;
    },
    computedTop() {
      const a = this.dimensions.activator;
      const c = this.dimensions.content;
      let top = 0;
      if (this.top)
        top += a.height - c.height;
      if (this.attach !== false)
        top += a.offsetTop;
      else
        top += a.top + this.pageYOffset;
      if (this.offsetY)
        top += this.top ? -a.height : a.height;
      if (this.nudgeTop)
        top -= parseInt(this.nudgeTop);
      if (this.nudgeBottom)
        top += parseInt(this.nudgeBottom);
      return top;
    },
    hasActivator() {
      return !!this.$slots.activator || !!this.$scopedSlots.activator || !!this.activator || !!this.inputActivator;
    },
    absoluteYOffset() {
      return this.pageYOffset - this.relativeYOffset;
    }
  },
  watch: {
    disabled(val) {
      val && this.callDeactivate();
    },
    isActive(val) {
      if (this.disabled)
        return;
      val ? this.callActivate() : this.callDeactivate();
    },
    positionX: "updateDimensions",
    positionY: "updateDimensions"
  },
  beforeMount() {
    this.hasWindow = typeof window !== "undefined";
    if (this.hasWindow) {
      window.addEventListener("resize", this.updateDimensions, false);
    }
  },
  beforeDestroy() {
    if (this.hasWindow) {
      window.removeEventListener("resize", this.updateDimensions, false);
    }
  },
  methods: {
    absolutePosition() {
      return {
        offsetTop: this.positionY || this.absoluteY,
        offsetLeft: this.positionX || this.absoluteX,
        scrollHeight: 0,
        top: this.positionY || this.absoluteY,
        bottom: this.positionY || this.absoluteY,
        left: this.positionX || this.absoluteX,
        right: this.positionX || this.absoluteX,
        height: 0,
        width: 0
      };
    },
    activate() {
    },
    calcLeft(menuWidth) {
      return convertToUnit(this.attach !== false ? this.computedLeft : this.calcXOverflow(this.computedLeft, menuWidth));
    },
    calcTop() {
      return convertToUnit(this.attach !== false ? this.computedTop : this.calcYOverflow(this.computedTop));
    },
    calcXOverflow(left, menuWidth) {
      const xOverflow = left + menuWidth - this.pageWidth + 12;
      if ((!this.left || this.right) && xOverflow > 0) {
        left = Math.max(left - xOverflow, 0);
      } else {
        left = Math.max(left, 12);
      }
      return left + this.getOffsetLeft();
    },
    calcYOverflow(top) {
      const documentHeight = this.getInnerHeight();
      const toTop = this.absoluteYOffset + documentHeight;
      const activator = this.dimensions.activator;
      const contentHeight = this.dimensions.content.height;
      const totalHeight = top + contentHeight;
      const isOverflowing = toTop < totalHeight;
      if (isOverflowing && this.offsetOverflow && // If we don't have enough room to offset
      // the overflow, don't offset
      activator.top > contentHeight) {
        top = this.pageYOffset + (activator.top - contentHeight);
      } else if (isOverflowing && !this.allowOverflow) {
        top = toTop - contentHeight - 12;
      } else if (top < this.absoluteYOffset && !this.allowOverflow) {
        top = this.absoluteYOffset + 12;
      }
      return top < 12 ? 12 : top;
    },
    callActivate() {
      if (!this.hasWindow)
        return;
      this.activate();
    },
    callDeactivate() {
      this.isContentActive = false;
      this.deactivate();
    },
    checkForPageYOffset() {
      if (this.hasWindow) {
        this.pageYOffset = this.activatorFixed ? 0 : this.getOffsetTop();
      }
    },
    checkActivatorFixed() {
      if (this.attach !== false) {
        this.activatorFixed = false;
        return;
      }
      let el = this.getActivator();
      while (el) {
        if (window.getComputedStyle(el).position === "fixed") {
          this.activatorFixed = true;
          return;
        }
        el = el.offsetParent;
      }
      this.activatorFixed = false;
    },
    deactivate() {
    },
    genActivatorListeners() {
      const listeners = Activatable.options.methods.genActivatorListeners.call(this);
      const onClick = listeners.click;
      if (onClick) {
        listeners.click = (e) => {
          if (this.openOnClick) {
            onClick && onClick(e);
          }
          this.absoluteX = e.clientX;
          this.absoluteY = e.clientY;
        };
      }
      return listeners;
    },
    getInnerHeight() {
      if (!this.hasWindow)
        return 0;
      return window.innerHeight || document.documentElement.clientHeight;
    },
    getOffsetLeft() {
      if (!this.hasWindow)
        return 0;
      return window.pageXOffset || document.documentElement.scrollLeft;
    },
    getOffsetTop() {
      if (!this.hasWindow)
        return 0;
      return window.pageYOffset || document.documentElement.scrollTop;
    },
    getRoundedBoundedClientRect(el) {
      const rect = el.getBoundingClientRect();
      return {
        top: Math.round(rect.top),
        left: Math.round(rect.left),
        bottom: Math.round(rect.bottom),
        right: Math.round(rect.right),
        width: Math.round(rect.width),
        height: Math.round(rect.height)
      };
    },
    measure(el) {
      if (!el || !this.hasWindow)
        return null;
      const rect = this.getRoundedBoundedClientRect(el);
      if (this.attach !== false) {
        const style = window.getComputedStyle(el);
        rect.left = parseInt(style.marginLeft);
        rect.top = parseInt(style.marginTop);
      }
      return rect;
    },
    sneakPeek(cb) {
      requestAnimationFrame(() => {
        const el = this.$refs.content;
        if (!el || el.style.display !== "none") {
          cb();
          return;
        }
        el.style.display = "inline-block";
        cb();
        el.style.display = "none";
      });
    },
    startTransition() {
      return new Promise((resolve) => requestAnimationFrame(() => {
        this.isContentActive = this.hasJustFocused = this.isActive;
        resolve();
      }));
    },
    updateDimensions() {
      this.hasWindow = typeof window !== "undefined";
      this.checkActivatorFixed();
      this.checkForPageYOffset();
      this.pageWidth = document.documentElement.clientWidth;
      const dimensions = {
        activator: { ...this.dimensions.activator },
        content: { ...this.dimensions.content }
      };
      if (!this.hasActivator || this.absolute) {
        dimensions.activator = this.absolutePosition();
      } else {
        const activator = this.getActivator();
        if (!activator)
          return;
        dimensions.activator = this.measure(activator);
        dimensions.activator.offsetLeft = activator.offsetLeft;
        if (this.attach !== false) {
          dimensions.activator.offsetTop = activator.offsetTop;
        } else {
          dimensions.activator.offsetTop = 0;
        }
      }
      this.sneakPeek(() => {
        if (this.$refs.content) {
          if (this.$refs.content.offsetParent) {
            const offsetRect = this.getRoundedBoundedClientRect(this.$refs.content.offsetParent);
            this.relativeYOffset = window.pageYOffset + offsetRect.top;
            dimensions.activator.top -= this.relativeYOffset;
            dimensions.activator.left -= window.pageXOffset + offsetRect.left;
          }
          dimensions.content = this.measure(this.$refs.content);
        }
        this.dimensions = dimensions;
      });
    }
  }
});
const Returnable = Vue.extend({
  name: "returnable",
  props: {
    returnValue: null
  },
  data: () => ({
    isActive: false,
    originalValue: null
  }),
  watch: {
    isActive(val) {
      if (val) {
        this.originalValue = this.returnValue;
      } else {
        this.$emit("update:return-value", this.originalValue);
      }
    }
  },
  methods: {
    save(value) {
      this.originalValue = value;
      setTimeout(() => {
        this.isActive = false;
      });
    }
  }
});
const baseMixins$q = mixins(
  Dependent,
  Delayable,
  Returnable,
  Roundable,
  Themeable$1,
  Menuable
);
const VMenu = baseMixins$q.extend({
  name: "v-menu",
  directives: {
    ClickOutside: ClickOutside$1,
    Resize: Resize$1
  },
  provide() {
    return {
      isInMenu: true,
      // Pass theme through to default slot
      theme: this.theme
    };
  },
  props: {
    auto: Boolean,
    closeOnClick: {
      type: Boolean,
      default: true
    },
    closeOnContentClick: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    disableKeys: Boolean,
    maxHeight: {
      type: [Number, String],
      default: "auto"
    },
    offsetX: Boolean,
    offsetY: Boolean,
    openOnHover: Boolean,
    origin: {
      type: String,
      default: "top left"
    },
    transition: {
      type: [Boolean, String],
      default: "v-menu-transition"
    },
    contentProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      calculatedTopAuto: 0,
      defaultOffset: 8,
      hasJustFocused: false,
      listIndex: -1,
      resizeTimeout: 0,
      selectedIndex: null,
      tiles: []
    };
  },
  computed: {
    activeTile() {
      return this.tiles[this.listIndex];
    },
    calculatedLeft() {
      const menuWidth = Math.max(this.dimensions.content.width, parseFloat(this.calculatedMinWidth));
      if (!this.auto)
        return this.calcLeft(menuWidth) || "0";
      return convertToUnit(this.calcXOverflow(this.calcLeftAuto(), menuWidth)) || "0";
    },
    calculatedMaxHeight() {
      const height = this.auto ? "200px" : convertToUnit(this.maxHeight);
      return height || "0";
    },
    calculatedMaxWidth() {
      return convertToUnit(this.maxWidth) || "0";
    },
    calculatedMinWidth() {
      if (this.minWidth) {
        return convertToUnit(this.minWidth) || "0";
      }
      const minWidth = Math.min(
        this.dimensions.activator.width + Number(this.nudgeWidth) + (this.auto ? 16 : 0),
        Math.max(this.pageWidth - 24, 0)
      );
      const calculatedMaxWidth = isNaN(parseInt(this.calculatedMaxWidth)) ? minWidth : parseInt(this.calculatedMaxWidth);
      return convertToUnit(Math.min(
        calculatedMaxWidth,
        minWidth
      )) || "0";
    },
    calculatedTop() {
      const top = !this.auto ? this.calcTop() : convertToUnit(this.calcYOverflow(this.calculatedTopAuto));
      return top || "0";
    },
    hasClickableTiles() {
      return Boolean(this.tiles.find((tile) => tile.tabIndex > -1));
    },
    styles() {
      return {
        maxHeight: this.calculatedMaxHeight,
        minWidth: this.calculatedMinWidth,
        maxWidth: this.calculatedMaxWidth,
        top: this.calculatedTop,
        left: this.calculatedLeft,
        transformOrigin: this.origin,
        zIndex: this.zIndex || this.activeZIndex
      };
    }
  },
  watch: {
    isActive(val) {
      if (!val)
        this.listIndex = -1;
    },
    isContentActive(val) {
      this.hasJustFocused = val;
    },
    listIndex(next, prev) {
      if (next in this.tiles) {
        const tile = this.tiles[next];
        tile.classList.add("v-list-item--highlighted");
        const scrollTop = this.$refs.content.scrollTop;
        const contentHeight = this.$refs.content.clientHeight;
        if (scrollTop > tile.offsetTop - 8) {
          goTo(tile.offsetTop - tile.clientHeight, {
            appOffset: false,
            duration: 300,
            container: this.$refs.content
          });
        } else if (scrollTop + contentHeight < tile.offsetTop + tile.clientHeight + 8) {
          goTo(tile.offsetTop - contentHeight + tile.clientHeight * 2, {
            appOffset: false,
            duration: 300,
            container: this.$refs.content
          });
        }
      }
      prev in this.tiles && this.tiles[prev].classList.remove("v-list-item--highlighted");
    }
  },
  created() {
    if (this.$attrs.hasOwnProperty("full-width")) {
      removed("full-width", this);
    }
  },
  mounted() {
    this.isActive && this.callActivate();
  },
  methods: {
    activate() {
      this.updateDimensions();
      requestAnimationFrame(() => {
        this.startTransition().then(() => {
          if (this.$refs.content) {
            this.calculatedTopAuto = this.calcTopAuto();
            this.auto && (this.$refs.content.scrollTop = this.calcScrollPosition());
          }
        });
      });
    },
    calcScrollPosition() {
      const $el = this.$refs.content;
      const activeTile = $el.querySelector(".v-list-item--active");
      const maxScrollTop = $el.scrollHeight - $el.offsetHeight;
      return activeTile ? Math.min(maxScrollTop, Math.max(0, activeTile.offsetTop - $el.offsetHeight / 2 + activeTile.offsetHeight / 2)) : $el.scrollTop;
    },
    calcLeftAuto() {
      return parseInt(this.dimensions.activator.left - this.defaultOffset * 2);
    },
    calcTopAuto() {
      const $el = this.$refs.content;
      const activeTile = $el.querySelector(".v-list-item--active");
      if (!activeTile) {
        this.selectedIndex = null;
      }
      if (this.offsetY || !activeTile) {
        return this.computedTop;
      }
      this.selectedIndex = Array.from(this.tiles).indexOf(activeTile);
      const tileDistanceFromMenuTop = activeTile.offsetTop - this.calcScrollPosition();
      const firstTileOffsetTop = $el.querySelector(".v-list-item").offsetTop;
      return this.computedTop - tileDistanceFromMenuTop - firstTileOffsetTop - 1;
    },
    changeListIndex(e) {
      this.getTiles();
      if (!this.isActive || !this.hasClickableTiles) {
        return;
      } else if (e.keyCode === keyCodes.tab) {
        this.isActive = false;
        return;
      } else if (e.keyCode === keyCodes.down) {
        this.nextTile();
      } else if (e.keyCode === keyCodes.up) {
        this.prevTile();
      } else if (e.keyCode === keyCodes.end) {
        this.lastTile();
      } else if (e.keyCode === keyCodes.home) {
        this.firstTile();
      } else if (e.keyCode === keyCodes.enter && this.listIndex !== -1) {
        this.tiles[this.listIndex].click();
      } else {
        return;
      }
      e.preventDefault();
    },
    closeConditional(e) {
      const target = e.target;
      return this.isActive && !this._isDestroyed && this.closeOnClick && !this.$refs.content.contains(target);
    },
    genActivatorAttributes() {
      const attributes = Activatable.options.methods.genActivatorAttributes.call(this);
      if (this.activeTile && this.activeTile.id) {
        return {
          ...attributes,
          "aria-activedescendant": this.activeTile.id
        };
      }
      return attributes;
    },
    genActivatorListeners() {
      const listeners = Menuable.options.methods.genActivatorListeners.call(this);
      if (!this.disableKeys) {
        listeners.keydown = this.onKeyDown;
      }
      return listeners;
    },
    genTransition() {
      const content = this.genContent();
      if (!this.transition)
        return content;
      return this.$createElement("transition", {
        props: {
          name: this.transition
        }
      }, [content]);
    },
    genDirectives() {
      const directives = [{
        name: "show",
        value: this.isContentActive
      }];
      if (!this.openOnHover && this.closeOnClick) {
        directives.push({
          name: "click-outside",
          value: {
            handler: () => {
              this.isActive = false;
            },
            closeConditional: this.closeConditional,
            include: () => [this.$el, ...this.getOpenDependentElements()]
          }
        });
      }
      return directives;
    },
    genContent() {
      const options = {
        attrs: {
          ...this.getScopeIdAttrs(),
          ...this.contentProps,
          role: "role" in this.$attrs ? this.$attrs.role : "menu"
        },
        staticClass: "v-menu__content",
        class: {
          ...this.rootThemeClasses,
          ...this.roundedClasses,
          "v-menu__content--auto": this.auto,
          "v-menu__content--fixed": this.activatorFixed,
          menuable__content__active: this.isActive,
          [this.contentClass.trim()]: true
        },
        style: this.styles,
        directives: this.genDirectives(),
        ref: "content",
        on: {
          click: (e) => {
            const target = e.target;
            if (target.getAttribute("disabled"))
              return;
            if (this.closeOnContentClick)
              this.isActive = false;
          },
          keydown: this.onKeyDown
        }
      };
      if (this.$listeners.scroll) {
        options.on = options.on || {};
        options.on.scroll = this.$listeners.scroll;
      }
      if (!this.disabled && this.openOnHover) {
        options.on = options.on || {};
        options.on.mouseenter = this.mouseEnterHandler;
      }
      if (this.openOnHover) {
        options.on = options.on || {};
        options.on.mouseleave = this.mouseLeaveHandler;
      }
      return this.$createElement("div", options, this.getContentSlot());
    },
    getTiles() {
      if (!this.$refs.content)
        return;
      this.tiles = Array.from(this.$refs.content.querySelectorAll(".v-list-item, .v-divider, .v-subheader"));
    },
    mouseEnterHandler() {
      this.runDelay("open", () => {
        if (this.hasJustFocused)
          return;
        this.hasJustFocused = true;
      });
    },
    mouseLeaveHandler(e) {
      this.runDelay("close", () => {
        if (this.$refs.content?.contains(e.relatedTarget))
          return;
        requestAnimationFrame(() => {
          this.isActive = false;
          this.callDeactivate();
        });
      });
    },
    nextTile() {
      const tile = this.tiles[this.listIndex + 1];
      if (!tile) {
        if (!this.tiles.length)
          return;
        this.listIndex = -1;
        this.nextTile();
        return;
      }
      this.listIndex++;
      if (tile.tabIndex === -1)
        this.nextTile();
    },
    prevTile() {
      const tile = this.tiles[this.listIndex - 1];
      if (!tile) {
        if (!this.tiles.length)
          return;
        this.listIndex = this.tiles.length;
        this.prevTile();
        return;
      }
      this.listIndex--;
      if (tile.tabIndex === -1)
        this.prevTile();
    },
    lastTile() {
      const tile = this.tiles[this.tiles.length - 1];
      if (!tile)
        return;
      this.listIndex = this.tiles.length - 1;
      if (tile.tabIndex === -1)
        this.prevTile();
    },
    firstTile() {
      const tile = this.tiles[0];
      if (!tile)
        return;
      this.listIndex = 0;
      if (tile.tabIndex === -1)
        this.nextTile();
    },
    onKeyDown(e) {
      if (this.disableKeys)
        return;
      if (e.keyCode === keyCodes.esc) {
        setTimeout(() => {
          this.isActive = false;
        });
        const activator = this.getActivator();
        this.$nextTick(() => activator && activator.focus());
      } else if (!this.isActive && [keyCodes.up, keyCodes.down].includes(e.keyCode)) {
        this.isActive = true;
      }
      this.$nextTick(() => this.changeListIndex(e));
    },
    onResize() {
      if (!this.isActive)
        return;
      this.$refs.content.offsetWidth;
      this.updateDimensions();
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = window.setTimeout(this.updateDimensions, 100);
    }
  },
  render(h) {
    const data = {
      staticClass: "v-menu",
      class: {
        "v-menu--attached": this.attach === "" || this.attach === true || this.attach === "attach"
      },
      directives: [{
        arg: "500",
        name: "resize",
        value: this.onResize
      }]
    };
    return h("div", data, [
      !this.activator && this.genActivator(),
      this.showLazyContent(() => [
        this.$createElement(VThemeProvider, {
          props: {
            root: true,
            light: this.light,
            dark: this.dark
          }
        }, [this.genTransition()])
      ])
    ]);
  }
});
const VSimpleCheckbox = Vue.extend({
  name: "v-simple-checkbox",
  functional: true,
  directives: {
    Ripple: ripple
  },
  props: {
    ...Colorable.options.props,
    ...Themeable$1.options.props,
    disabled: Boolean,
    ripple: {
      type: Boolean,
      default: true
    },
    value: Boolean,
    indeterminate: Boolean,
    indeterminateIcon: {
      type: String,
      default: "$checkboxIndeterminate"
    },
    onIcon: {
      type: String,
      default: "$checkboxOn"
    },
    offIcon: {
      type: String,
      default: "$checkboxOff"
    }
  },
  render(h, { props: props2, data, listeners }) {
    const children = [];
    let icon = props2.offIcon;
    if (props2.indeterminate)
      icon = props2.indeterminateIcon;
    else if (props2.value)
      icon = props2.onIcon;
    children.push(h(VIcon$1, Colorable.options.methods.setTextColor(props2.value && props2.color, {
      props: {
        disabled: props2.disabled,
        dark: props2.dark,
        light: props2.light
      }
    }), icon));
    if (props2.ripple && !props2.disabled) {
      const ripple$1 = h("div", Colorable.options.methods.setTextColor(props2.color, {
        staticClass: "v-input--selection-controls__ripple",
        directives: [{
          def: ripple,
          name: "ripple",
          value: { center: true }
        }]
      }));
      children.push(ripple$1);
    }
    return h(
      "div",
      mergeData(data, {
        class: {
          "v-simple-checkbox": true,
          "v-simple-checkbox--disabled": props2.disabled
        },
        on: {
          click: (e) => {
            e.stopPropagation();
            if (data.on && data.on.input && !props2.disabled) {
              wrapInArray(data.on.input).forEach((f) => f(!props2.value));
            }
          }
        }
      }),
      [
        h("div", { staticClass: "v-input--selection-controls__input" }, children)
      ]
    );
  }
});
const VDivider = Themeable$1.extend({
  name: "v-divider",
  props: {
    inset: Boolean,
    vertical: Boolean
  },
  render(h) {
    let orientation;
    if (!this.$attrs.role || this.$attrs.role === "separator") {
      orientation = this.vertical ? "vertical" : "horizontal";
    }
    return h("hr", {
      class: {
        "v-divider": true,
        "v-divider--inset": this.inset,
        "v-divider--vertical": this.vertical,
        ...this.themeClasses
      },
      attrs: {
        role: "separator",
        "aria-orientation": orientation,
        ...this.$attrs
      },
      on: this.$listeners
    });
  }
});
const VSubheader = mixins(
  Themeable$1
  /* @vue/component */
).extend({
  name: "v-subheader",
  props: {
    inset: Boolean
  },
  render(h) {
    return h("div", {
      staticClass: "v-subheader",
      class: {
        "v-subheader--inset": this.inset,
        ...this.themeClasses
      },
      attrs: this.$attrs,
      on: this.$listeners
    }, this.$slots.default);
  }
});
const VList = VSheet.extend().extend({
  name: "v-list",
  provide() {
    return {
      isInList: true,
      list: this
    };
  },
  inject: {
    isInMenu: {
      default: false
    },
    isInNav: {
      default: false
    }
  },
  props: {
    dense: Boolean,
    disabled: Boolean,
    expand: Boolean,
    flat: Boolean,
    nav: Boolean,
    rounded: Boolean,
    subheader: Boolean,
    threeLine: Boolean,
    twoLine: Boolean
  },
  data: () => ({
    groups: []
  }),
  computed: {
    classes() {
      return {
        ...VSheet.options.computed.classes.call(this),
        "v-list--dense": this.dense,
        "v-list--disabled": this.disabled,
        "v-list--flat": this.flat,
        "v-list--nav": this.nav,
        "v-list--rounded": this.rounded,
        "v-list--subheader": this.subheader,
        "v-list--two-line": this.twoLine,
        "v-list--three-line": this.threeLine
      };
    }
  },
  methods: {
    register(content) {
      this.groups.push(content);
    },
    unregister(content) {
      const index = this.groups.findIndex((g) => g._uid === content._uid);
      if (index > -1)
        this.groups.splice(index, 1);
    },
    listClick(uid) {
      if (this.expand)
        return;
      for (const group of this.groups) {
        group.toggle(uid);
      }
    }
  },
  render(h) {
    const data = {
      staticClass: "v-list",
      class: this.classes,
      style: this.styles,
      attrs: {
        role: this.isInNav || this.isInMenu ? void 0 : "list",
        ...this.attrs$
      }
    };
    return h(this.tag, this.setBackgroundColor(this.color, data), [this.$slots.default]);
  }
});
const baseMixins$p = mixins(
  Colorable,
  Routable,
  Themeable$1,
  factory$1("listItemGroup"),
  factory$2("inputValue")
);
const VListItem = baseMixins$p.extend().extend({
  name: "v-list-item",
  directives: {
    Ripple: ripple
  },
  inject: {
    isInGroup: {
      default: false
    },
    isInList: {
      default: false
    },
    isInMenu: {
      default: false
    },
    isInNav: {
      default: false
    }
  },
  inheritAttrs: false,
  props: {
    activeClass: {
      type: String,
      default() {
        if (!this.listItemGroup)
          return "";
        return this.listItemGroup.activeClass;
      }
    },
    dense: Boolean,
    inactive: Boolean,
    link: Boolean,
    selectable: {
      type: Boolean
    },
    tag: {
      type: String,
      default: "div"
    },
    threeLine: Boolean,
    twoLine: Boolean,
    value: null
  },
  data: () => ({
    proxyClass: "v-list-item--active"
  }),
  computed: {
    classes() {
      return {
        "v-list-item": true,
        ...Routable.options.computed.classes.call(this),
        "v-list-item--dense": this.dense,
        "v-list-item--disabled": this.disabled,
        "v-list-item--link": this.isClickable && !this.inactive,
        "v-list-item--selectable": this.selectable,
        "v-list-item--three-line": this.threeLine,
        "v-list-item--two-line": this.twoLine,
        ...this.themeClasses
      };
    },
    isClickable() {
      return Boolean(
        Routable.options.computed.isClickable.call(this) || this.listItemGroup
      );
    }
  },
  created() {
    if (this.$attrs.hasOwnProperty("avatar")) {
      removed("avatar", this);
    }
  },
  methods: {
    click(e) {
      if (e.detail)
        this.$el.blur();
      this.$emit("click", e);
      this.to || this.toggle();
    },
    genAttrs() {
      const attrs = {
        "aria-disabled": this.disabled ? true : void 0,
        tabindex: this.isClickable && !this.disabled ? 0 : -1,
        ...this.$attrs
      };
      if (this.$attrs.hasOwnProperty("role"))
        ;
      else if (this.isInNav)
        ;
      else if (this.isInGroup) {
        attrs.role = "option";
        attrs["aria-selected"] = String(this.isActive);
      } else if (this.isInMenu) {
        attrs.role = this.isClickable ? "menuitem" : void 0;
        attrs.id = attrs.id || `list-item-${this._uid}`;
      } else if (this.isInList) {
        attrs.role = "listitem";
      }
      return attrs;
    },
    toggle() {
      if (this.to && this.inputValue === void 0) {
        this.isActive = !this.isActive;
      }
      this.$emit("change");
    }
  },
  render(h) {
    let { tag, data } = this.generateRouteLink();
    data.attrs = {
      ...data.attrs,
      ...this.genAttrs()
    };
    data[this.to ? "nativeOn" : "on"] = {
      ...data[this.to ? "nativeOn" : "on"],
      keydown: (e) => {
        if (!this.disabled) {
          if (e.keyCode === keyCodes.enter)
            this.click(e);
          this.$emit("keydown", e);
        }
      }
    };
    if (this.inactive)
      tag = "div";
    if (this.inactive && this.to) {
      data.on = data.nativeOn;
      delete data.nativeOn;
    }
    const children = this.$scopedSlots.default ? this.$scopedSlots.default({
      active: this.isActive,
      toggle: this.toggle
    }) : this.$slots.default;
    return h(tag, this.isActive ? this.setTextColor(this.color, data) : data, children);
  }
});
const VListItemIcon = Vue.extend({
  name: "v-list-item-icon",
  functional: true,
  render(h, { data, children }) {
    data.staticClass = `v-list-item__icon ${data.staticClass || ""}`.trim();
    return h("div", data, children);
  }
});
const baseMixins$o = mixins(
  BindsAttrs,
  Bootable,
  Colorable,
  inject("list"),
  Toggleable$1
);
const VListGroup = baseMixins$o.extend().extend({
  name: "v-list-group",
  directives: { ripple },
  props: {
    activeClass: {
      type: String,
      default: ""
    },
    appendIcon: {
      type: String,
      default: "$expand"
    },
    color: {
      type: String,
      default: "primary"
    },
    disabled: Boolean,
    group: [String, RegExp],
    noAction: Boolean,
    prependIcon: String,
    ripple: {
      type: [Boolean, Object],
      default: true
    },
    subGroup: Boolean
  },
  computed: {
    classes() {
      return {
        "v-list-group--active": this.isActive,
        "v-list-group--disabled": this.disabled,
        "v-list-group--no-action": this.noAction,
        "v-list-group--sub-group": this.subGroup
      };
    }
  },
  watch: {
    isActive(val) {
      if (!this.subGroup && val) {
        this.list && this.list.listClick(this._uid);
      }
    },
    $route: "onRouteChange"
  },
  created() {
    this.list && this.list.register(this);
    if (this.group && this.$route && this.value == null) {
      this.isActive = this.matchRoute(this.$route.path);
    }
  },
  beforeDestroy() {
    this.list && this.list.unregister(this);
  },
  methods: {
    click(e) {
      if (this.disabled)
        return;
      this.isBooted = true;
      this.$emit("click", e);
      this.$nextTick(() => this.isActive = !this.isActive);
    },
    genIcon(icon) {
      return this.$createElement(VIcon$1, icon);
    },
    genAppendIcon() {
      const icon = !this.subGroup ? this.appendIcon : false;
      if (!icon && !this.$slots.appendIcon)
        return null;
      return this.$createElement(VListItemIcon, {
        staticClass: "v-list-group__header__append-icon"
      }, [
        this.$slots.appendIcon || this.genIcon(icon)
      ]);
    },
    genHeader() {
      return this.$createElement(VListItem, {
        staticClass: "v-list-group__header",
        attrs: {
          "aria-expanded": String(this.isActive),
          role: "button"
        },
        class: {
          [this.activeClass]: this.isActive
        },
        props: {
          inputValue: this.isActive
        },
        directives: [{
          name: "ripple",
          value: this.ripple
        }],
        on: {
          ...this.listeners$,
          click: this.click
        }
      }, [
        this.genPrependIcon(),
        this.$slots.activator,
        this.genAppendIcon()
      ]);
    },
    genItems() {
      return this.showLazyContent(() => [
        this.$createElement("div", {
          staticClass: "v-list-group__items",
          directives: [{
            name: "show",
            value: this.isActive
          }]
        }, getSlot(this))
      ]);
    },
    genPrependIcon() {
      const icon = this.subGroup && this.prependIcon == null ? "$subgroup" : this.prependIcon;
      if (!icon && !this.$slots.prependIcon)
        return null;
      return this.$createElement(VListItemIcon, {
        staticClass: "v-list-group__header__prepend-icon"
      }, [
        this.$slots.prependIcon || this.genIcon(icon)
      ]);
    },
    onRouteChange(to) {
      if (!this.group)
        return;
      const isActive = this.matchRoute(to.path);
      if (isActive && this.isActive !== isActive) {
        this.list && this.list.listClick(this._uid);
      }
      this.isActive = isActive;
    },
    toggle(uid) {
      const isActive = this._uid === uid;
      if (isActive)
        this.isBooted = true;
      this.$nextTick(() => this.isActive = isActive);
    },
    matchRoute(to) {
      return to.match(this.group) !== null;
    }
  },
  render(h) {
    return h("div", this.setTextColor(this.isActive && this.color, {
      staticClass: "v-list-group",
      class: this.classes
    }), [
      this.genHeader(),
      h(VExpandTransition, this.genItems())
    ]);
  }
});
const Comparable = Vue.extend({
  name: "comparable",
  props: {
    valueComparator: {
      type: Function,
      default: deepEqual
    }
  }
});
function factory(prop = "value", event = "change") {
  return Vue.extend({
    name: "proxyable",
    model: {
      prop,
      event
    },
    props: {
      [prop]: {
        required: false
      }
    },
    data() {
      return {
        internalLazyValue: this[prop]
      };
    },
    computed: {
      internalValue: {
        get() {
          return this.internalLazyValue;
        },
        set(val) {
          if (val === this.internalLazyValue)
            return;
          this.internalLazyValue = val;
          this.$emit(event, val);
        }
      }
    },
    watch: {
      [prop](val) {
        this.internalLazyValue = val;
      }
    }
  });
}
const Proxyable = factory();
const Proxyable$1 = Proxyable;
const BaseItemGroup = mixins(
  Comparable,
  Proxyable$1,
  Themeable$1
).extend({
  name: "base-item-group",
  props: {
    activeClass: {
      type: String,
      default: "v-item--active"
    },
    mandatory: Boolean,
    max: {
      type: [Number, String],
      default: null
    },
    multiple: Boolean,
    tag: {
      type: String,
      default: "div"
    }
  },
  data() {
    return {
      // As long as a value is defined, show it
      // Otherwise, check if multiple
      // to determine which default to provide
      internalLazyValue: this.value !== void 0 ? this.value : this.multiple ? [] : void 0,
      items: []
    };
  },
  computed: {
    classes() {
      return {
        "v-item-group": true,
        ...this.themeClasses
      };
    },
    selectedIndex() {
      return this.selectedItem && this.items.indexOf(this.selectedItem) || -1;
    },
    selectedItem() {
      if (this.multiple)
        return void 0;
      return this.selectedItems[0];
    },
    selectedItems() {
      return this.items.filter((item, index) => {
        return this.toggleMethod(this.getValue(item, index));
      });
    },
    selectedValues() {
      if (this.internalValue == null)
        return [];
      return Array.isArray(this.internalValue) ? this.internalValue : [this.internalValue];
    },
    toggleMethod() {
      if (!this.multiple) {
        return (v) => this.valueComparator(this.internalValue, v);
      }
      const internalValue = this.internalValue;
      if (Array.isArray(internalValue)) {
        return (v) => internalValue.some((intern) => this.valueComparator(intern, v));
      }
      return () => false;
    }
  },
  watch: {
    internalValue: "updateItemsState",
    items: "updateItemsState"
  },
  created() {
    if (this.multiple && !Array.isArray(this.internalValue)) {
      consoleWarn("Model must be bound to an array if the multiple property is true.", this);
    }
  },
  methods: {
    genData() {
      return {
        class: this.classes
      };
    },
    getValue(item, i) {
      return item.value === void 0 ? i : item.value;
    },
    onClick(item) {
      this.updateInternalValue(
        this.getValue(item, this.items.indexOf(item))
      );
    },
    register(item) {
      const index = this.items.push(item) - 1;
      item.$on("change", () => this.onClick(item));
      if (this.mandatory && !this.selectedValues.length) {
        this.updateMandatory();
      }
      this.updateItem(item, index);
    },
    unregister(item) {
      if (this._isDestroyed)
        return;
      const index = this.items.indexOf(item);
      const value = this.getValue(item, index);
      this.items.splice(index, 1);
      const valueIndex = this.selectedValues.indexOf(value);
      if (valueIndex < 0)
        return;
      if (!this.mandatory) {
        return this.updateInternalValue(value);
      }
      if (this.multiple && Array.isArray(this.internalValue)) {
        this.internalValue = this.internalValue.filter((v) => v !== value);
      } else {
        this.internalValue = void 0;
      }
      if (!this.selectedItems.length) {
        this.updateMandatory(true);
      }
    },
    updateItem(item, index) {
      const value = this.getValue(item, index);
      item.isActive = this.toggleMethod(value);
    },
    // https://github.com/vuetifyjs/vuetify/issues/5352
    updateItemsState() {
      this.$nextTick(() => {
        if (this.mandatory && !this.selectedItems.length) {
          return this.updateMandatory();
        }
        this.items.forEach(this.updateItem);
      });
    },
    updateInternalValue(value) {
      this.multiple ? this.updateMultiple(value) : this.updateSingle(value);
    },
    updateMandatory(last) {
      if (!this.items.length)
        return;
      const items = this.items.slice();
      if (last)
        items.reverse();
      const item = items.find((item2) => !item2.disabled);
      if (!item)
        return;
      const index = this.items.indexOf(item);
      this.updateInternalValue(
        this.getValue(item, index)
      );
    },
    updateMultiple(value) {
      const defaultValue = Array.isArray(this.internalValue) ? this.internalValue : [];
      const internalValue = defaultValue.slice();
      const index = internalValue.findIndex((val) => this.valueComparator(val, value));
      if (this.mandatory && // Item already exists
      index > -1 && // value would be reduced below min
      internalValue.length - 1 < 1)
        return;
      if (
        // Max is set
        this.max != null && // Item doesn't exist
        index < 0 && // value would be increased above max
        internalValue.length + 1 > this.max
      )
        return;
      index > -1 ? internalValue.splice(index, 1) : internalValue.push(value);
      this.internalValue = internalValue;
    },
    updateSingle(value) {
      const isSame = this.valueComparator(this.internalValue, value);
      if (this.mandatory && isSame)
        return;
      this.internalValue = isSame ? void 0 : value;
    }
  },
  render(h) {
    return h(this.tag, this.genData(), this.$slots.default);
  }
});
const VItemGroup = BaseItemGroup.extend({
  name: "v-item-group",
  provide() {
    return {
      itemGroup: this
    };
  }
});
const VListItemGroup = mixins(
  BaseItemGroup,
  Colorable
).extend({
  name: "v-list-item-group",
  provide() {
    return {
      isInGroup: true,
      listItemGroup: this
    };
  },
  computed: {
    classes() {
      return {
        ...BaseItemGroup.options.computed.classes.call(this),
        "v-list-item-group": true
      };
    }
  },
  methods: {
    genData() {
      return this.setTextColor(this.color, {
        ...BaseItemGroup.options.methods.genData.call(this),
        attrs: {
          role: "listbox"
        }
      });
    }
  }
});
const VListItemAction = Vue.extend({
  name: "v-list-item-action",
  functional: true,
  render(h, { data, children = [] }) {
    data.staticClass = data.staticClass ? `v-list-item__action ${data.staticClass}` : "v-list-item__action";
    const filteredChild = children.filter((VNode2) => {
      return VNode2.isComment === false && VNode2.text !== " ";
    });
    if (filteredChild.length > 1)
      data.staticClass += " v-list-item__action--stack";
    return h("div", data, children);
  }
});
const VAvatar = mixins(
  Colorable,
  Measurable,
  Roundable
  /* @vue/component */
).extend({
  name: "v-avatar",
  props: {
    left: Boolean,
    right: Boolean,
    size: {
      type: [Number, String],
      default: 48
    }
  },
  computed: {
    classes() {
      return {
        "v-avatar--left": this.left,
        "v-avatar--right": this.right,
        ...this.roundedClasses
      };
    },
    styles() {
      return {
        height: convertToUnit(this.size),
        minWidth: convertToUnit(this.size),
        width: convertToUnit(this.size),
        ...this.measurableStyles
      };
    }
  },
  render(h) {
    const data = {
      staticClass: "v-avatar",
      class: this.classes,
      style: this.styles,
      on: this.$listeners
    };
    return h("div", this.setBackgroundColor(this.color, data), this.$slots.default);
  }
});
const VListItemAvatar = VAvatar.extend({
  name: "v-list-item-avatar",
  props: {
    horizontal: Boolean,
    size: {
      type: [Number, String],
      default: 40
    }
  },
  computed: {
    classes() {
      return {
        "v-list-item__avatar--horizontal": this.horizontal,
        ...VAvatar.options.computed.classes.call(this),
        "v-avatar--tile": this.tile || this.horizontal
      };
    }
  },
  render(h) {
    const render = VAvatar.options.render.call(this, h);
    render.data = render.data || {};
    render.data.staticClass += " v-list-item__avatar";
    return render;
  }
});
const VListItemActionText = createSimpleFunctional("v-list-item__action-text", "span");
const VListItemContent = createSimpleFunctional("v-list-item__content", "div");
const VListItemTitle = createSimpleFunctional("v-list-item__title", "div");
const VListItemSubtitle = createSimpleFunctional("v-list-item__subtitle", "div");
const VSelectList = mixins(Colorable, Themeable$1).extend({
  name: "v-select-list",
  // https://github.com/vuejs/vue/issues/6872
  directives: {
    ripple
  },
  props: {
    action: Boolean,
    dense: Boolean,
    hideSelected: Boolean,
    items: {
      type: Array,
      default: () => []
    },
    itemDisabled: {
      type: [String, Array, Function],
      default: "disabled"
    },
    itemText: {
      type: [String, Array, Function],
      default: "text"
    },
    itemValue: {
      type: [String, Array, Function],
      default: "value"
    },
    noDataText: String,
    noFilter: Boolean,
    searchInput: null,
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    parsedItems() {
      return this.selectedItems.map((item) => this.getValue(item));
    },
    tileActiveClass() {
      return Object.keys(this.setTextColor(this.color).class || {}).join(" ");
    },
    staticNoDataTile() {
      const tile = {
        attrs: {
          role: void 0
        },
        on: {
          mousedown: (e) => e.preventDefault()
          // Prevent onBlur from being called
        }
      };
      return this.$createElement(VListItem, tile, [
        this.genTileContent(this.noDataText)
      ]);
    }
  },
  methods: {
    genAction(item, inputValue) {
      return this.$createElement(VListItemAction, [
        this.$createElement(VSimpleCheckbox, {
          props: {
            color: this.color,
            value: inputValue,
            ripple: false
          },
          on: {
            input: () => this.$emit("select", item)
          }
        })
      ]);
    },
    genDivider(props2) {
      return this.$createElement(VDivider, { props: props2 });
    },
    genFilteredText(text) {
      text = text || "";
      if (!this.searchInput || this.noFilter)
        return text;
      const { start, middle, end } = this.getMaskedCharacters(text);
      return [start, this.genHighlight(middle), end];
    },
    genHeader(props2) {
      return this.$createElement(VSubheader, { props: props2 }, props2.header);
    },
    genHighlight(text) {
      return this.$createElement("span", { staticClass: "v-list-item__mask" }, text);
    },
    getMaskedCharacters(text) {
      const searchInput = (this.searchInput || "").toString().toLocaleLowerCase();
      const index = text.toLocaleLowerCase().indexOf(searchInput);
      if (index < 0)
        return { start: text, middle: "", end: "" };
      const start = text.slice(0, index);
      const middle = text.slice(index, index + searchInput.length);
      const end = text.slice(index + searchInput.length);
      return { start, middle, end };
    },
    genTile({
      item,
      index,
      disabled = null,
      value = false
    }) {
      if (!value)
        value = this.hasItem(item);
      if (item === Object(item)) {
        disabled = disabled !== null ? disabled : this.getDisabled(item);
      }
      const tile = {
        attrs: {
          // Default behavior in list does not
          // contain aria-selected by default
          "aria-selected": String(value),
          id: `list-item-${this._uid}-${index}`,
          role: "option"
        },
        on: {
          mousedown: (e) => {
            e.preventDefault();
          },
          click: () => disabled || this.$emit("select", item)
        },
        props: {
          activeClass: this.tileActiveClass,
          disabled,
          ripple: true,
          inputValue: value
        }
      };
      if (!this.$scopedSlots.item) {
        return this.$createElement(VListItem, tile, [
          this.action && !this.hideSelected && this.items.length > 0 ? this.genAction(item, value) : null,
          this.genTileContent(item, index)
        ]);
      }
      const parent = this;
      const scopedSlot = this.$scopedSlots.item({
        parent,
        item,
        attrs: {
          ...tile.attrs,
          ...tile.props
        },
        on: tile.on
      });
      return this.needsTile(scopedSlot) ? this.$createElement(VListItem, tile, scopedSlot) : scopedSlot;
    },
    genTileContent(item, index = 0) {
      return this.$createElement(VListItemContent, [
        this.$createElement(VListItemTitle, [
          this.genFilteredText(this.getText(item))
        ])
      ]);
    },
    hasItem(item) {
      return this.parsedItems.indexOf(this.getValue(item)) > -1;
    },
    needsTile(slot) {
      return slot.length !== 1 || slot[0].componentOptions == null || slot[0].componentOptions.Ctor.options.name !== "v-list-item";
    },
    getDisabled(item) {
      return Boolean(getPropertyFromItem(item, this.itemDisabled, false));
    },
    getText(item) {
      return String(getPropertyFromItem(item, this.itemText, item));
    },
    getValue(item) {
      return getPropertyFromItem(item, this.itemValue, this.getText(item));
    }
  },
  render() {
    const children = [];
    const itemsLength = this.items.length;
    for (let index = 0; index < itemsLength; index++) {
      const item = this.items[index];
      if (this.hideSelected && this.hasItem(item))
        continue;
      if (item == null)
        children.push(this.genTile({ item, index }));
      else if (item.header)
        children.push(this.genHeader(item));
      else if (item.divider)
        children.push(this.genDivider(item));
      else
        children.push(this.genTile({ item, index }));
    }
    children.length || children.push(this.$slots["no-data"] || this.staticNoDataTile);
    this.$slots["prepend-item"] && children.unshift(this.$slots["prepend-item"]);
    this.$slots["append-item"] && children.push(this.$slots["append-item"]);
    return this.$createElement(VList, {
      staticClass: "v-select-list",
      class: this.themeClasses,
      attrs: {
        role: "listbox",
        tabindex: -1
      },
      props: { dense: this.dense }
    }, children);
  }
});
const VLabel = mixins(Themeable$1).extend({
  name: "v-label",
  functional: true,
  props: {
    absolute: Boolean,
    color: {
      type: String,
      default: "primary"
    },
    disabled: Boolean,
    focused: Boolean,
    for: String,
    left: {
      type: [Number, String],
      default: 0
    },
    right: {
      type: [Number, String],
      default: "auto"
    },
    value: Boolean
  },
  render(h, ctx) {
    const { children, listeners, props: props2, data } = ctx;
    const newData = mergeData({
      staticClass: "v-label",
      class: {
        "v-label--active": props2.value,
        "v-label--is-disabled": props2.disabled,
        ...functionalThemeClasses(ctx)
      },
      attrs: {
        for: props2.for,
        "aria-hidden": !props2.for
      },
      on: listeners,
      style: {
        left: convertToUnit(props2.left),
        right: convertToUnit(props2.right),
        position: props2.absolute ? "absolute" : "relative"
      },
      ref: "label"
    }, data);
    return h("label", Colorable.options.methods.setTextColor(props2.focused && props2.color, newData), children);
  }
});
const VMessages = mixins(Colorable, Themeable$1).extend({
  name: "v-messages",
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    genChildren() {
      return this.$createElement("transition-group", {
        staticClass: "v-messages__wrapper",
        attrs: {
          name: "message-transition",
          tag: "div"
        }
      }, this.value.map(this.genMessage));
    },
    genMessage(message, key) {
      return this.$createElement("div", {
        staticClass: "v-messages__message",
        key
      }, getSlot(this, "default", { message, key }) || [message]);
    }
  },
  render(h) {
    return h("div", this.setTextColor(this.color, {
      staticClass: "v-messages",
      class: this.themeClasses
    }), [this.genChildren()]);
  }
});
const baseMixins$n = mixins(
  Colorable,
  inject("form"),
  Themeable$1
);
const Validatable = baseMixins$n.extend({
  name: "validatable",
  props: {
    disabled: {
      type: Boolean,
      default: null
    },
    error: Boolean,
    errorCount: {
      type: [Number, String],
      default: 1
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    messages: {
      type: [String, Array],
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    success: Boolean,
    successMessages: {
      type: [String, Array],
      default: () => []
    },
    validateOnBlur: Boolean,
    value: { required: false }
  },
  data() {
    return {
      errorBucket: [],
      hasColor: false,
      hasFocused: false,
      hasInput: false,
      isFocused: false,
      isResetting: false,
      lazyValue: this.value,
      valid: false
    };
  },
  computed: {
    computedColor() {
      if (this.isDisabled)
        return void 0;
      if (this.color)
        return this.color;
      if (this.isDark && !this.appIsDark)
        return "white";
      else
        return "primary";
    },
    hasError() {
      return this.internalErrorMessages.length > 0 || this.errorBucket.length > 0 || this.error;
    },
    // TODO: Add logic that allows the user to enable based
    // upon a good validation
    hasSuccess() {
      return this.internalSuccessMessages.length > 0 || this.success;
    },
    externalError() {
      return this.internalErrorMessages.length > 0 || this.error;
    },
    hasMessages() {
      return this.validationTarget.length > 0;
    },
    hasState() {
      if (this.isDisabled)
        return false;
      return this.hasSuccess || this.shouldValidate && this.hasError;
    },
    internalErrorMessages() {
      return this.genInternalMessages(this.errorMessages);
    },
    internalMessages() {
      return this.genInternalMessages(this.messages);
    },
    internalSuccessMessages() {
      return this.genInternalMessages(this.successMessages);
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(val) {
        this.lazyValue = val;
        this.$emit("input", val);
      }
    },
    isDisabled() {
      return this.disabled ?? (!!this.form && this.form.disabled);
    },
    isInteractive() {
      return !this.isDisabled && !this.isReadonly;
    },
    isReadonly() {
      return this.readonly ?? (!!this.form && this.form.readonly);
    },
    shouldValidate() {
      if (this.externalError)
        return true;
      if (this.isResetting)
        return false;
      return this.validateOnBlur ? this.hasFocused && !this.isFocused : this.hasInput || this.hasFocused;
    },
    validations() {
      return this.validationTarget.slice(0, Number(this.errorCount));
    },
    validationState() {
      if (this.isDisabled)
        return void 0;
      if (this.hasError && this.shouldValidate)
        return "error";
      if (this.hasSuccess)
        return "success";
      if (this.hasColor)
        return this.computedColor;
      return void 0;
    },
    validationTarget() {
      if (this.internalErrorMessages.length > 0) {
        return this.internalErrorMessages;
      } else if (this.successMessages && this.successMessages.length > 0) {
        return this.internalSuccessMessages;
      } else if (this.messages && this.messages.length > 0) {
        return this.internalMessages;
      } else if (this.shouldValidate) {
        return this.errorBucket;
      } else
        return [];
    }
  },
  watch: {
    rules: {
      handler(newVal, oldVal) {
        if (deepEqual(newVal, oldVal))
          return;
        this.validate();
      },
      deep: true
    },
    internalValue() {
      this.hasInput = true;
      this.validateOnBlur || this.$nextTick(this.validate);
    },
    isFocused(val) {
      if (!val && !this.isDisabled) {
        this.hasFocused = true;
        this.validateOnBlur && this.$nextTick(this.validate);
      }
    },
    isResetting() {
      setTimeout(() => {
        this.hasInput = false;
        this.hasFocused = false;
        this.isResetting = false;
        this.validate();
      }, 0);
    },
    hasError(val) {
      if (this.shouldValidate) {
        this.$emit("update:error", val);
      }
    },
    value(val) {
      this.lazyValue = val;
    }
  },
  beforeMount() {
    this.validate();
  },
  created() {
    this.form && this.form.register(this);
  },
  beforeDestroy() {
    this.form && this.form.unregister(this);
  },
  methods: {
    genInternalMessages(messages) {
      if (!messages)
        return [];
      else if (Array.isArray(messages))
        return messages;
      else
        return [messages];
    },
    /** @public */
    reset() {
      this.isResetting = true;
      this.internalValue = Array.isArray(this.internalValue) ? [] : null;
    },
    /** @public */
    resetValidation() {
      this.isResetting = true;
    },
    /** @public */
    validate(force = false, value) {
      const errorBucket = [];
      value = value || this.internalValue;
      if (force)
        this.hasInput = this.hasFocused = true;
      for (let index = 0; index < this.rules.length; index++) {
        const rule = this.rules[index];
        const valid = typeof rule === "function" ? rule(value) : rule;
        if (valid === false || typeof valid === "string") {
          errorBucket.push(valid || "");
        } else if (typeof valid !== "boolean") {
          consoleError(`Rules should return a string or boolean, received '${typeof valid}' instead`, this);
        }
      }
      this.errorBucket = errorBucket;
      this.valid = errorBucket.length === 0;
      return this.valid;
    }
  }
});
const baseMixins$m = mixins(
  BindsAttrs,
  Validatable
);
const VInput = baseMixins$m.extend().extend({
  name: "v-input",
  inheritAttrs: false,
  props: {
    appendIcon: String,
    backgroundColor: {
      type: String,
      default: ""
    },
    dense: Boolean,
    height: [Number, String],
    hideDetails: [Boolean, String],
    hideSpinButtons: Boolean,
    hint: String,
    id: String,
    label: String,
    loading: Boolean,
    persistentHint: Boolean,
    prependIcon: String,
    value: null
  },
  data() {
    return {
      lazyValue: this.value,
      hasMouseDown: false
    };
  },
  computed: {
    classes() {
      return {
        "v-input--has-state": this.hasState,
        "v-input--hide-details": !this.showDetails,
        "v-input--is-label-active": this.isLabelActive,
        "v-input--is-dirty": this.isDirty,
        "v-input--is-disabled": this.isDisabled,
        "v-input--is-focused": this.isFocused,
        // <v-switch loading>.loading === '' so we can't just cast to boolean
        "v-input--is-loading": this.loading !== false && this.loading != null,
        "v-input--is-readonly": this.isReadonly,
        "v-input--dense": this.dense,
        "v-input--hide-spin-buttons": this.hideSpinButtons,
        ...this.themeClasses
      };
    },
    computedId() {
      return this.id || `input-${this._uid}`;
    },
    hasDetails() {
      return this.messagesToDisplay.length > 0;
    },
    hasHint() {
      return !this.hasMessages && !!this.hint && (this.persistentHint || this.isFocused);
    },
    hasLabel() {
      return !!(this.$slots.label || this.label);
    },
    // Proxy for `lazyValue`
    // This allows an input
    // to function without
    // a provided model
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(val) {
        this.lazyValue = val;
        this.$emit(this.$_modelEvent, val);
      }
    },
    isDirty() {
      return !!this.lazyValue;
    },
    isLabelActive() {
      return this.isDirty;
    },
    messagesToDisplay() {
      if (this.hasHint)
        return [this.hint];
      if (!this.hasMessages)
        return [];
      return this.validations.map((validation) => {
        if (typeof validation === "string")
          return validation;
        const validationResult = validation(this.internalValue);
        return typeof validationResult === "string" ? validationResult : "";
      }).filter((message) => message !== "");
    },
    showDetails() {
      return this.hideDetails === false || this.hideDetails === "auto" && this.hasDetails;
    }
  },
  watch: {
    value(val) {
      this.lazyValue = val;
    }
  },
  beforeCreate() {
    this.$_modelEvent = this.$options.model && this.$options.model.event || "input";
  },
  methods: {
    genContent() {
      return [
        this.genPrependSlot(),
        this.genControl(),
        this.genAppendSlot()
      ];
    },
    genControl() {
      return this.$createElement("div", {
        staticClass: "v-input__control",
        attrs: { title: this.attrs$.title }
      }, [
        this.genInputSlot(),
        this.genMessages()
      ]);
    },
    genDefaultSlot() {
      return [
        this.genLabel(),
        this.$slots.default
      ];
    },
    genIcon(type2, cb, extraData = {}) {
      const icon = this[`${type2}Icon`];
      const eventName = `click:${kebabCase(type2)}`;
      const hasListener = !!(this.listeners$[eventName] || cb);
      const localeKey = {
        prepend: "prependAction",
        prependInner: "prependAction",
        append: "appendAction",
        appendOuter: "appendAction",
        clear: "clear"
      }[type2];
      const label = hasListener && localeKey ? this.$vuetify.lang.t(`$vuetify.input.${localeKey}`, this.label ?? "") : void 0;
      const data = mergeData({
        attrs: {
          "aria-label": label,
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          light: this.light,
          tabindex: type2 === "clear" ? -1 : void 0
        },
        on: !hasListener ? void 0 : {
          click: (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.$emit(eventName, e);
            cb && cb(e);
          },
          // Container has g event that will
          // trigger menu open if enclosed
          mouseup: (e) => {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      }, extraData);
      return this.$createElement("div", {
        staticClass: `v-input__icon`,
        class: type2 ? `v-input__icon--${kebabCase(type2)}` : void 0
      }, [
        this.$createElement(
          VIcon$1,
          data,
          icon
        )
      ]);
    },
    genInputSlot() {
      return this.$createElement("div", this.setBackgroundColor(this.backgroundColor, {
        staticClass: "v-input__slot",
        style: { height: convertToUnit(this.height) },
        on: {
          click: this.onClick,
          mousedown: this.onMouseDown,
          mouseup: this.onMouseUp
        },
        ref: "input-slot"
      }), [this.genDefaultSlot()]);
    },
    genLabel() {
      if (!this.hasLabel)
        return null;
      return this.$createElement(VLabel, {
        props: {
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          focused: this.hasState,
          for: this.computedId,
          light: this.light
        }
      }, this.$slots.label || this.label);
    },
    genMessages() {
      if (!this.showDetails)
        return null;
      return this.$createElement(VMessages, {
        props: {
          color: this.hasHint ? "" : this.validationState,
          dark: this.dark,
          light: this.light,
          value: this.messagesToDisplay
        },
        attrs: {
          role: this.hasMessages ? "alert" : null
        },
        scopedSlots: {
          default: (props2) => getSlot(this, "message", props2)
        }
      });
    },
    genSlot(type2, location, slot) {
      if (!slot.length)
        return null;
      const ref = `${type2}-${location}`;
      return this.$createElement("div", {
        staticClass: `v-input__${ref}`,
        ref
      }, slot);
    },
    genPrependSlot() {
      const slot = [];
      if (this.$slots.prepend) {
        slot.push(this.$slots.prepend);
      } else if (this.prependIcon) {
        slot.push(this.genIcon("prepend"));
      }
      return this.genSlot("prepend", "outer", slot);
    },
    genAppendSlot() {
      const slot = [];
      if (this.$slots.append) {
        slot.push(this.$slots.append);
      } else if (this.appendIcon) {
        slot.push(this.genIcon("append"));
      }
      return this.genSlot("append", "outer", slot);
    },
    onClick(e) {
      this.$emit("click", e);
    },
    onMouseDown(e) {
      this.hasMouseDown = true;
      this.$emit("mousedown", e);
    },
    onMouseUp(e) {
      this.hasMouseDown = false;
      this.$emit("mouseup", e);
    }
  },
  render(h) {
    return h("div", this.setTextColor(this.validationState, {
      staticClass: "v-input",
      class: this.classes
    }), this.genContent());
  }
});
const VCounter = mixins(Themeable$1).extend({
  name: "v-counter",
  functional: true,
  props: {
    value: {
      type: [Number, String],
      default: ""
    },
    max: [Number, String]
  },
  render(h, ctx) {
    const { props: props2 } = ctx;
    const max = parseInt(props2.max, 10);
    const value = parseInt(props2.value, 10);
    const content = max ? `${value} / ${max}` : String(props2.value);
    const isGreater = max && value > max;
    return h("div", {
      staticClass: "v-counter",
      class: {
        "error--text": isGreater,
        ...functionalThemeClasses(ctx)
      }
    }, content);
  }
});
function intersectable(options) {
  return Vue.extend({
    name: "intersectable",
    data: () => ({
      isIntersecting: false
    }),
    mounted() {
      intersect.inserted(this.$el, {
        name: "intersect",
        value: this.onObserve
      }, this.$vnode);
    },
    destroyed() {
      intersect.unbind(this.$el, {
        name: "intersect",
        value: this.onObserve
      }, this.$vnode);
    },
    methods: {
      onObserve(entries, observer, isIntersecting) {
        this.isIntersecting = isIntersecting;
        if (!isIntersecting)
          return;
        for (let i = 0, length = options.onVisible.length; i < length; i++) {
          const callback = this[options.onVisible[i]];
          if (typeof callback === "function") {
            callback();
            continue;
          }
          consoleWarn(options.onVisible[i] + " method is not available on the instance but referenced in intersectable mixin options");
        }
      }
    }
  });
}
const baseMixins$l = mixins(
  Colorable,
  factory$3(["absolute", "fixed", "top", "bottom"]),
  Proxyable$1,
  Themeable$1
);
const VProgressLinear = baseMixins$l.extend({
  name: "v-progress-linear",
  directives: { intersect },
  props: {
    active: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: null
    },
    backgroundOpacity: {
      type: [Number, String],
      default: null
    },
    bufferValue: {
      type: [Number, String],
      default: 100
    },
    color: {
      type: String,
      default: "primary"
    },
    height: {
      type: [Number, String],
      default: 4
    },
    indeterminate: Boolean,
    query: Boolean,
    reverse: Boolean,
    rounded: Boolean,
    stream: Boolean,
    striped: Boolean,
    value: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      internalLazyValue: this.value || 0,
      isVisible: true
    };
  },
  computed: {
    __cachedBackground() {
      return this.$createElement("div", this.setBackgroundColor(this.backgroundColor || this.color, {
        staticClass: "v-progress-linear__background",
        style: this.backgroundStyle
      }));
    },
    __cachedBar() {
      return this.$createElement(this.computedTransition, [this.__cachedBarType]);
    },
    __cachedBarType() {
      return this.indeterminate ? this.__cachedIndeterminate : this.__cachedDeterminate;
    },
    __cachedBuffer() {
      return this.$createElement("div", {
        staticClass: "v-progress-linear__buffer",
        style: this.styles
      });
    },
    __cachedDeterminate() {
      return this.$createElement("div", this.setBackgroundColor(this.color, {
        staticClass: `v-progress-linear__determinate`,
        style: {
          width: convertToUnit(this.normalizedValue, "%")
        }
      }));
    },
    __cachedIndeterminate() {
      return this.$createElement("div", {
        staticClass: "v-progress-linear__indeterminate",
        class: {
          "v-progress-linear__indeterminate--active": this.active
        }
      }, [
        this.genProgressBar("long"),
        this.genProgressBar("short")
      ]);
    },
    __cachedStream() {
      if (!this.stream)
        return null;
      return this.$createElement("div", this.setTextColor(this.color, {
        staticClass: "v-progress-linear__stream",
        style: {
          width: convertToUnit(100 - this.normalizedBuffer, "%")
        }
      }));
    },
    backgroundStyle() {
      const backgroundOpacity = this.backgroundOpacity == null ? this.backgroundColor ? 1 : 0.3 : parseFloat(this.backgroundOpacity);
      return {
        opacity: backgroundOpacity,
        [this.isReversed ? "right" : "left"]: convertToUnit(this.normalizedValue, "%"),
        width: convertToUnit(Math.max(0, this.normalizedBuffer - this.normalizedValue), "%")
      };
    },
    classes() {
      return {
        "v-progress-linear--absolute": this.absolute,
        "v-progress-linear--fixed": this.fixed,
        "v-progress-linear--query": this.query,
        "v-progress-linear--reactive": this.reactive,
        "v-progress-linear--reverse": this.isReversed,
        "v-progress-linear--rounded": this.rounded,
        "v-progress-linear--striped": this.striped,
        "v-progress-linear--visible": this.isVisible,
        ...this.themeClasses
      };
    },
    computedTransition() {
      return this.indeterminate ? VFadeTransition : VSlideXTransition;
    },
    isReversed() {
      return this.$vuetify.rtl !== this.reverse;
    },
    normalizedBuffer() {
      return this.normalize(this.bufferValue);
    },
    normalizedValue() {
      return this.normalize(this.internalLazyValue);
    },
    reactive() {
      return Boolean(this.$listeners.change);
    },
    styles() {
      const styles = {};
      if (!this.active) {
        styles.height = 0;
      }
      if (!this.indeterminate && parseFloat(this.normalizedBuffer) !== 100) {
        styles.width = convertToUnit(this.normalizedBuffer, "%");
      }
      return styles;
    }
  },
  methods: {
    genContent() {
      const slot = getSlot(this, "default", { value: this.internalLazyValue });
      if (!slot)
        return null;
      return this.$createElement("div", {
        staticClass: "v-progress-linear__content"
      }, slot);
    },
    genListeners() {
      const listeners = this.$listeners;
      if (this.reactive) {
        listeners.click = this.onClick;
      }
      return listeners;
    },
    genProgressBar(name) {
      return this.$createElement("div", this.setBackgroundColor(this.color, {
        staticClass: "v-progress-linear__indeterminate",
        class: {
          [name]: true
        }
      }));
    },
    onClick(e) {
      if (!this.reactive)
        return;
      const { width } = this.$el.getBoundingClientRect();
      this.internalValue = e.offsetX / width * 100;
    },
    onObserve(entries, observer, isIntersecting) {
      this.isVisible = isIntersecting;
    },
    normalize(value) {
      if (value < 0)
        return 0;
      if (value > 100)
        return 100;
      return parseFloat(value);
    }
  },
  render(h) {
    const data = {
      staticClass: "v-progress-linear",
      attrs: {
        role: "progressbar",
        "aria-valuemin": 0,
        "aria-valuemax": this.normalizedBuffer,
        "aria-valuenow": this.indeterminate ? void 0 : this.normalizedValue
      },
      class: this.classes,
      directives: [{
        name: "intersect",
        value: this.onObserve
      }],
      style: {
        bottom: this.bottom ? 0 : void 0,
        height: this.active ? convertToUnit(this.height) : 0,
        top: this.top ? 0 : void 0
      },
      on: this.genListeners()
    };
    return h("div", data, [
      this.__cachedStream,
      this.__cachedBackground,
      this.__cachedBuffer,
      this.__cachedBar,
      this.genContent()
    ]);
  }
});
const Loadable = Vue.extend().extend({
  name: "loadable",
  props: {
    loading: {
      type: [Boolean, String],
      default: false
    },
    loaderHeight: {
      type: [Number, String],
      default: 2
    }
  },
  methods: {
    genProgress() {
      if (this.loading === false)
        return null;
      return this.$slots.progress || this.$createElement(VProgressLinear, {
        props: {
          absolute: true,
          color: this.loading === true || this.loading === "" ? this.color || "primary" : this.loading,
          height: this.loaderHeight,
          indeterminate: true
        }
      });
    }
  }
});
const baseMixins$k = mixins(
  VInput,
  intersectable({
    onVisible: [
      "onResize",
      "tryAutofocus"
    ]
  }),
  Loadable
);
const dirtyTypes = ["color", "file", "time", "date", "datetime-local", "week", "month"];
const VTextField = baseMixins$k.extend().extend({
  name: "v-text-field",
  directives: {
    resize: Resize$1,
    ripple
  },
  inheritAttrs: false,
  props: {
    appendOuterIcon: String,
    autofocus: Boolean,
    clearable: Boolean,
    clearIcon: {
      type: String,
      default: "$clear"
    },
    counter: [Boolean, Number, String],
    counterValue: Function,
    filled: Boolean,
    flat: Boolean,
    fullWidth: Boolean,
    label: String,
    outlined: Boolean,
    placeholder: String,
    prefix: String,
    prependInnerIcon: String,
    persistentPlaceholder: Boolean,
    reverse: Boolean,
    rounded: Boolean,
    shaped: Boolean,
    singleLine: Boolean,
    solo: Boolean,
    soloInverted: Boolean,
    suffix: String,
    type: {
      type: String,
      default: "text"
    }
  },
  data: () => ({
    badInput: false,
    labelWidth: 0,
    prefixWidth: 0,
    prependWidth: 0,
    initialValue: null,
    isBooted: false,
    isClearing: false
  }),
  computed: {
    classes() {
      return {
        ...VInput.options.computed.classes.call(this),
        "v-text-field": true,
        "v-text-field--full-width": this.fullWidth,
        "v-text-field--prefix": this.prefix,
        "v-text-field--single-line": this.isSingle,
        "v-text-field--solo": this.isSolo,
        "v-text-field--solo-inverted": this.soloInverted,
        "v-text-field--solo-flat": this.flat,
        "v-text-field--filled": this.filled,
        "v-text-field--is-booted": this.isBooted,
        "v-text-field--enclosed": this.isEnclosed,
        "v-text-field--reverse": this.reverse,
        "v-text-field--outlined": this.outlined,
        "v-text-field--placeholder": this.placeholder,
        "v-text-field--rounded": this.rounded,
        "v-text-field--shaped": this.shaped
      };
    },
    computedColor() {
      const computedColor = Validatable.options.computed.computedColor.call(this);
      if (!this.soloInverted || !this.isFocused)
        return computedColor;
      return this.color || "primary";
    },
    computedCounterValue() {
      if (typeof this.counterValue === "function") {
        return this.counterValue(this.internalValue);
      }
      return [...(this.internalValue || "").toString()].length;
    },
    hasCounter() {
      return this.counter !== false && this.counter != null;
    },
    hasDetails() {
      return VInput.options.computed.hasDetails.call(this) || this.hasCounter;
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(val) {
        this.lazyValue = val;
        this.$emit("input", this.lazyValue);
      }
    },
    isDirty() {
      return this.lazyValue?.toString().length > 0 || this.badInput;
    },
    isEnclosed() {
      return this.filled || this.isSolo || this.outlined;
    },
    isLabelActive() {
      return this.isDirty || dirtyTypes.includes(this.type);
    },
    isSingle() {
      return this.isSolo || this.singleLine || this.fullWidth || // https://material.io/components/text-fields/#filled-text-field
      this.filled && !this.hasLabel;
    },
    isSolo() {
      return this.solo || this.soloInverted;
    },
    labelPosition() {
      let offset = this.prefix && !this.labelValue ? this.prefixWidth : 0;
      if (this.labelValue && this.prependWidth)
        offset -= this.prependWidth;
      return this.$vuetify.rtl === this.reverse ? {
        left: offset,
        right: "auto"
      } : {
        left: "auto",
        right: offset
      };
    },
    showLabel() {
      return this.hasLabel && !(this.isSingle && this.labelValue);
    },
    labelValue() {
      return this.isFocused || this.isLabelActive || this.persistentPlaceholder;
    }
  },
  watch: {
    // labelValue: 'setLabelWidth', // moved to mounted, see #11533
    outlined: "setLabelWidth",
    label() {
      this.$nextTick(this.setLabelWidth);
    },
    prefix() {
      this.$nextTick(this.setPrefixWidth);
    },
    isFocused: "updateValue",
    value(val) {
      this.lazyValue = val;
    }
  },
  created() {
    if (this.$attrs.hasOwnProperty("box")) {
      breaking("box", "filled", this);
    }
    if (this.$attrs.hasOwnProperty("browser-autocomplete")) {
      breaking("browser-autocomplete", "autocomplete", this);
    }
    if (this.shaped && !(this.filled || this.outlined || this.isSolo)) {
      consoleWarn("shaped should be used with either filled or outlined", this);
    }
  },
  mounted() {
    this.$watch(() => this.labelValue, this.setLabelWidth);
    this.autofocus && this.tryAutofocus();
    requestAnimationFrame(() => {
      this.isBooted = true;
      requestAnimationFrame(() => {
        if (!this.isIntersecting) {
          this.onResize();
        }
      });
    });
  },
  methods: {
    /** @public */
    focus() {
      this.onFocus();
    },
    /** @public */
    blur(e) {
      window.requestAnimationFrame(() => {
        this.$refs.input && this.$refs.input.blur();
      });
    },
    clearableCallback() {
      this.$refs.input && this.$refs.input.focus();
      this.$nextTick(() => this.internalValue = null);
    },
    genAppendSlot() {
      const slot = [];
      if (this.$slots["append-outer"]) {
        slot.push(this.$slots["append-outer"]);
      } else if (this.appendOuterIcon) {
        slot.push(this.genIcon("appendOuter"));
      }
      return this.genSlot("append", "outer", slot);
    },
    genPrependInnerSlot() {
      const slot = [];
      if (this.$slots["prepend-inner"]) {
        slot.push(this.$slots["prepend-inner"]);
      } else if (this.prependInnerIcon) {
        slot.push(this.genIcon("prependInner"));
      }
      return this.genSlot("prepend", "inner", slot);
    },
    genIconSlot() {
      const slot = [];
      if (this.$slots.append) {
        slot.push(this.$slots.append);
      } else if (this.appendIcon) {
        slot.push(this.genIcon("append"));
      }
      return this.genSlot("append", "inner", slot);
    },
    genInputSlot() {
      const input = VInput.options.methods.genInputSlot.call(this);
      const prepend = this.genPrependInnerSlot();
      if (prepend) {
        input.children = input.children || [];
        input.children.unshift(prepend);
      }
      return input;
    },
    genClearIcon() {
      if (!this.clearable)
        return null;
      if (!this.isDirty) {
        return this.genSlot("append", "inner", [
          this.$createElement("div")
        ]);
      }
      return this.genSlot("append", "inner", [
        this.genIcon("clear", this.clearableCallback)
      ]);
    },
    genCounter() {
      if (!this.hasCounter)
        return null;
      const max = this.counter === true ? this.attrs$.maxlength : this.counter;
      const props2 = {
        dark: this.dark,
        light: this.light,
        max,
        value: this.computedCounterValue
      };
      return this.$scopedSlots.counter?.({ props: props2 }) ?? this.$createElement(VCounter, { props: props2 });
    },
    genControl() {
      return VInput.options.methods.genControl.call(this);
    },
    genDefaultSlot() {
      return [
        this.genFieldset(),
        this.genTextFieldSlot(),
        this.genClearIcon(),
        this.genIconSlot(),
        this.genProgress()
      ];
    },
    genFieldset() {
      if (!this.outlined)
        return null;
      return this.$createElement("fieldset", {
        attrs: {
          "aria-hidden": true
        }
      }, [this.genLegend()]);
    },
    genLabel() {
      if (!this.showLabel)
        return null;
      const data = {
        props: {
          absolute: true,
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          focused: !this.isSingle && (this.isFocused || !!this.validationState),
          for: this.computedId,
          left: this.labelPosition.left,
          light: this.light,
          right: this.labelPosition.right,
          value: this.labelValue
        }
      };
      return this.$createElement(VLabel, data, this.$slots.label || this.label);
    },
    genLegend() {
      const width = !this.singleLine && (this.labelValue || this.isDirty) ? this.labelWidth : 0;
      const span = this.$createElement("span", {
        domProps: { innerHTML: "&#8203;" },
        staticClass: "notranslate"
      });
      return this.$createElement("legend", {
        style: {
          width: !this.isSingle ? convertToUnit(width) : void 0
        }
      }, [span]);
    },
    genInput() {
      const listeners = Object.assign({}, this.listeners$);
      delete listeners.change;
      const { title, ...inputAttrs } = this.attrs$;
      return this.$createElement("input", {
        style: {},
        domProps: {
          value: this.type === "number" && Object.is(this.lazyValue, -0) ? "-0" : this.lazyValue
        },
        attrs: {
          ...inputAttrs,
          autofocus: this.autofocus,
          disabled: this.isDisabled,
          id: this.computedId,
          placeholder: this.persistentPlaceholder || this.isFocused || !this.hasLabel ? this.placeholder : void 0,
          readonly: this.isReadonly,
          type: this.type
        },
        on: Object.assign(listeners, {
          blur: this.onBlur,
          input: this.onInput,
          focus: this.onFocus,
          keydown: this.onKeyDown
        }),
        ref: "input",
        directives: [{
          name: "resize",
          modifiers: { quiet: true },
          value: this.onResize
        }]
      });
    },
    genMessages() {
      if (!this.showDetails)
        return null;
      const messagesNode = VInput.options.methods.genMessages.call(this);
      const counterNode = this.genCounter();
      return this.$createElement("div", {
        staticClass: "v-text-field__details"
      }, [
        messagesNode,
        counterNode
      ]);
    },
    genTextFieldSlot() {
      return this.$createElement("div", {
        staticClass: "v-text-field__slot"
      }, [
        this.genLabel(),
        this.prefix ? this.genAffix("prefix") : null,
        this.genInput(),
        this.suffix ? this.genAffix("suffix") : null
      ]);
    },
    genAffix(type2) {
      return this.$createElement("div", {
        class: `v-text-field__${type2}`,
        ref: type2
      }, this[type2]);
    },
    onBlur(e) {
      this.isFocused = false;
      e && this.$nextTick(() => this.$emit("blur", e));
    },
    onClick() {
      if (this.isFocused || this.isDisabled || !this.$refs.input)
        return;
      this.$refs.input.focus();
    },
    onFocus(e) {
      if (!this.$refs.input)
        return;
      const root = attachedRoot(this.$el);
      if (!root)
        return;
      if (root.activeElement !== this.$refs.input) {
        return this.$refs.input.focus();
      }
      if (!this.isFocused) {
        this.isFocused = true;
        e && this.$emit("focus", e);
      }
    },
    onInput(e) {
      const target = e.target;
      this.internalValue = target.value;
      this.badInput = target.validity && target.validity.badInput;
    },
    onKeyDown(e) {
      if (e.keyCode === keyCodes.enter && this.lazyValue !== this.initialValue) {
        this.initialValue = this.lazyValue;
        this.$emit("change", this.initialValue);
      }
      this.$emit("keydown", e);
    },
    onMouseDown(e) {
      if (e.target !== this.$refs.input) {
        e.preventDefault();
        e.stopPropagation();
      }
      VInput.options.methods.onMouseDown.call(this, e);
    },
    onMouseUp(e) {
      if (this.hasMouseDown)
        this.focus();
      VInput.options.methods.onMouseUp.call(this, e);
    },
    setLabelWidth() {
      if (!this.outlined)
        return;
      this.labelWidth = this.$refs.label ? Math.min(this.$refs.label.scrollWidth * 0.75 + 6, this.$el.offsetWidth - 24) : 0;
    },
    setPrefixWidth() {
      if (!this.$refs.prefix)
        return;
      this.prefixWidth = this.$refs.prefix.offsetWidth;
    },
    setPrependWidth() {
      if (!this.outlined || !this.$refs["prepend-inner"])
        return;
      this.prependWidth = this.$refs["prepend-inner"].offsetWidth;
    },
    tryAutofocus() {
      if (!this.autofocus || typeof document === "undefined" || !this.$refs.input)
        return false;
      const root = attachedRoot(this.$el);
      if (!root || root.activeElement === this.$refs.input)
        return false;
      this.$refs.input.focus();
      return true;
    },
    updateValue(val) {
      this.hasColor = val;
      if (val) {
        this.initialValue = this.lazyValue;
      } else if (this.initialValue !== this.lazyValue) {
        this.$emit("change", this.lazyValue);
      }
    },
    onResize() {
      this.setLabelWidth();
      this.setPrefixWidth();
      this.setPrependWidth();
    }
  }
});
const Filterable = Vue.extend({
  name: "filterable",
  props: {
    noDataText: {
      type: String,
      default: "$vuetify.noDataText"
    }
  }
});
const defaultMenuProps$1 = {
  closeOnClick: false,
  closeOnContentClick: false,
  disableKeys: true,
  openOnClick: false,
  maxHeight: 304
};
const baseMixins$j = mixins(
  VTextField,
  Comparable,
  Dependent,
  Filterable
);
const VSelect = baseMixins$j.extend().extend({
  name: "v-select",
  directives: {
    ClickOutside: ClickOutside$1
  },
  props: {
    appendIcon: {
      type: String,
      default: "$dropdown"
    },
    attach: {
      type: null,
      default: false
    },
    cacheItems: Boolean,
    chips: Boolean,
    clearable: Boolean,
    deletableChips: Boolean,
    disableLookup: Boolean,
    eager: Boolean,
    hideSelected: Boolean,
    items: {
      type: Array,
      default: () => []
    },
    itemColor: {
      type: String,
      default: "primary"
    },
    itemDisabled: {
      type: [String, Array, Function],
      default: "disabled"
    },
    itemText: {
      type: [String, Array, Function],
      default: "text"
    },
    itemValue: {
      type: [String, Array, Function],
      default: "value"
    },
    menuProps: {
      type: [String, Array, Object],
      default: () => defaultMenuProps$1
    },
    multiple: Boolean,
    openOnClear: Boolean,
    returnObject: Boolean,
    smallChips: Boolean
  },
  data() {
    return {
      cachedItems: this.cacheItems ? this.items : [],
      menuIsBooted: false,
      isMenuActive: false,
      lastItem: 20,
      // As long as a value is defined, show it
      // Otherwise, check if multiple
      // to determine which default to provide
      lazyValue: this.value !== void 0 ? this.value : this.multiple ? [] : void 0,
      selectedIndex: -1,
      selectedItems: [],
      keyboardLookupPrefix: "",
      keyboardLookupLastTime: 0
    };
  },
  computed: {
    /* All items that the select has */
    allItems() {
      return this.filterDuplicates(this.cachedItems.concat(this.items));
    },
    classes() {
      return {
        ...VTextField.options.computed.classes.call(this),
        "v-select": true,
        "v-select--chips": this.hasChips,
        "v-select--chips--small": this.smallChips,
        "v-select--is-menu-active": this.isMenuActive,
        "v-select--is-multi": this.multiple
      };
    },
    /* Used by other components to overwrite */
    computedItems() {
      return this.allItems;
    },
    computedOwns() {
      return `list-${this._uid}`;
    },
    computedCounterValue() {
      const value = this.multiple ? this.selectedItems : (this.getText(this.selectedItems[0]) ?? "").toString();
      if (typeof this.counterValue === "function") {
        return this.counterValue(value);
      }
      return value.length;
    },
    directives() {
      return this.isFocused ? [{
        name: "click-outside",
        value: {
          handler: this.blur,
          closeConditional: this.closeConditional,
          include: () => this.getOpenDependentElements()
        }
      }] : void 0;
    },
    dynamicHeight() {
      return "auto";
    },
    hasChips() {
      return this.chips || this.smallChips;
    },
    hasSlot() {
      return Boolean(this.hasChips || this.$scopedSlots.selection);
    },
    isDirty() {
      return this.selectedItems.length > 0;
    },
    listData() {
      const scopeId = this.$vnode && this.$vnode.context.$options._scopeId;
      const attrs = scopeId ? {
        [scopeId]: true
      } : {};
      return {
        attrs: {
          ...attrs,
          id: this.computedOwns
        },
        props: {
          action: this.multiple,
          color: this.itemColor,
          dense: this.dense,
          hideSelected: this.hideSelected,
          items: this.virtualizedItems,
          itemDisabled: this.itemDisabled,
          itemText: this.itemText,
          itemValue: this.itemValue,
          noDataText: this.$vuetify.lang.t(this.noDataText),
          selectedItems: this.selectedItems
        },
        on: {
          select: this.selectItem
        },
        scopedSlots: {
          item: this.$scopedSlots.item
        }
      };
    },
    staticList() {
      if (this.$slots["no-data"] || this.$slots["prepend-item"] || this.$slots["append-item"]) {
        consoleError("assert: staticList should not be called if slots are used");
      }
      return this.$createElement(VSelectList, this.listData);
    },
    virtualizedItems() {
      return this.$_menuProps.auto ? this.computedItems : this.computedItems.slice(0, this.lastItem);
    },
    menuCanShow: () => true,
    $_menuProps() {
      let normalisedProps = typeof this.menuProps === "string" ? this.menuProps.split(",") : this.menuProps;
      if (Array.isArray(normalisedProps)) {
        normalisedProps = normalisedProps.reduce((acc, p) => {
          acc[p.trim()] = true;
          return acc;
        }, {});
      }
      return {
        ...defaultMenuProps$1,
        eager: this.eager,
        value: this.menuCanShow && this.isMenuActive,
        nudgeBottom: normalisedProps.offsetY ? 1 : 0,
        // convert to int
        ...normalisedProps
      };
    }
  },
  watch: {
    internalValue(val) {
      this.initialValue = val;
      this.setSelectedItems();
      if (this.multiple) {
        this.$nextTick(() => {
          this.$refs.menu?.updateDimensions();
        });
      }
      if (this.hideSelected) {
        this.$nextTick(() => {
          this.onScroll();
        });
      }
    },
    isMenuActive(val) {
      window.setTimeout(() => this.onMenuActiveChange(val));
    },
    items: {
      immediate: true,
      handler(val) {
        if (this.cacheItems) {
          this.$nextTick(() => {
            this.cachedItems = this.filterDuplicates(this.cachedItems.concat(val));
          });
        }
        this.setSelectedItems();
      }
    }
  },
  methods: {
    /** @public */
    blur(e) {
      VTextField.options.methods.blur.call(this, e);
      this.isMenuActive = false;
      this.isFocused = false;
      this.selectedIndex = -1;
      this.setMenuIndex(-1);
    },
    /** @public */
    activateMenu() {
      if (!this.isInteractive || this.isMenuActive)
        return;
      this.isMenuActive = true;
    },
    clearableCallback() {
      this.setValue(this.multiple ? [] : null);
      this.setMenuIndex(-1);
      this.$nextTick(() => this.$refs.input && this.$refs.input.focus());
      if (this.openOnClear)
        this.isMenuActive = true;
    },
    closeConditional(e) {
      if (!this.isMenuActive)
        return true;
      return !this._isDestroyed && // Click originates from outside the menu content
      // Multiple selects don't close when an item is clicked
      (!this.getContent() || !this.getContent().contains(e.target)) && // Click originates from outside the element
      this.$el && !this.$el.contains(e.target) && e.target !== this.$el;
    },
    filterDuplicates(arr) {
      const uniqueValues = /* @__PURE__ */ new Map();
      for (let index = 0; index < arr.length; ++index) {
        const item = arr[index];
        if (item == null) {
          continue;
        }
        if (item.header || item.divider) {
          uniqueValues.set(item, item);
          continue;
        }
        const val = this.getValue(item);
        !uniqueValues.has(val) && uniqueValues.set(val, item);
      }
      return Array.from(uniqueValues.values());
    },
    findExistingIndex(item) {
      const itemValue = this.getValue(item);
      return (this.internalValue || []).findIndex((i) => this.valueComparator(this.getValue(i), itemValue));
    },
    getContent() {
      return this.$refs.menu && this.$refs.menu.$refs.content;
    },
    genChipSelection(item, index) {
      const isDisabled = this.isDisabled || this.getDisabled(item);
      const isInteractive = !isDisabled && this.isInteractive;
      return this.$createElement(VChip, {
        staticClass: "v-chip--select",
        attrs: { tabindex: -1 },
        props: {
          close: this.deletableChips && isInteractive,
          disabled: isDisabled,
          inputValue: index === this.selectedIndex,
          small: this.smallChips
        },
        on: {
          click: (e) => {
            if (!isInteractive)
              return;
            e.stopPropagation();
            this.selectedIndex = index;
          },
          "click:close": () => this.onChipInput(item)
        },
        key: JSON.stringify(this.getValue(item))
      }, this.getText(item));
    },
    genCommaSelection(item, index, last) {
      const color = index === this.selectedIndex && this.computedColor;
      const isDisabled = this.isDisabled || this.getDisabled(item);
      return this.$createElement("div", this.setTextColor(color, {
        staticClass: "v-select__selection v-select__selection--comma",
        class: {
          "v-select__selection--disabled": isDisabled
        },
        key: JSON.stringify(this.getValue(item))
      }), `${this.getText(item)}${last ? "" : ", "}`);
    },
    genDefaultSlot() {
      const selections = this.genSelections();
      const input = this.genInput();
      if (Array.isArray(selections)) {
        selections.push(input);
      } else {
        selections.children = selections.children || [];
        selections.children.push(input);
      }
      return [
        this.genFieldset(),
        this.$createElement("div", {
          staticClass: "v-select__slot",
          directives: this.directives
        }, [
          this.genLabel(),
          this.prefix ? this.genAffix("prefix") : null,
          selections,
          this.suffix ? this.genAffix("suffix") : null,
          this.genClearIcon(),
          this.genIconSlot(),
          this.genHiddenInput()
        ]),
        this.genMenu(),
        this.genProgress()
      ];
    },
    genIcon(type2, cb, extraData) {
      const icon = VInput.options.methods.genIcon.call(this, type2, cb, extraData);
      if (type2 === "append") {
        icon.children[0].data = mergeData(icon.children[0].data, {
          attrs: {
            tabindex: icon.children[0].componentOptions.listeners && "-1",
            "aria-hidden": "true",
            "aria-label": void 0
          }
        });
      }
      return icon;
    },
    genInput() {
      const input = VTextField.options.methods.genInput.call(this);
      delete input.data.attrs.name;
      input.data = mergeData(input.data, {
        domProps: { value: null },
        attrs: {
          readonly: true,
          type: "text",
          "aria-readonly": String(this.isReadonly),
          "aria-activedescendant": getObjectValueByPath(this.$refs.menu, "activeTile.id"),
          autocomplete: getObjectValueByPath(input.data, "attrs.autocomplete", "off"),
          placeholder: !this.isDirty && (this.persistentPlaceholder || this.isFocused || !this.hasLabel) ? this.placeholder : void 0
        },
        on: { keypress: this.onKeyPress }
      });
      return input;
    },
    genHiddenInput() {
      return this.$createElement("input", {
        domProps: { value: this.lazyValue },
        attrs: {
          type: "hidden",
          name: this.attrs$.name
        }
      });
    },
    genInputSlot() {
      const render = VTextField.options.methods.genInputSlot.call(this);
      render.data.attrs = {
        ...render.data.attrs,
        role: "button",
        "aria-haspopup": "listbox",
        "aria-expanded": String(this.isMenuActive),
        "aria-owns": this.computedOwns
      };
      return render;
    },
    genList() {
      if (this.$slots["no-data"] || this.$slots["prepend-item"] || this.$slots["append-item"]) {
        return this.genListWithSlot();
      } else {
        return this.staticList;
      }
    },
    genListWithSlot() {
      const slots = ["prepend-item", "no-data", "append-item"].filter((slotName) => this.$slots[slotName]).map((slotName) => this.$createElement("template", {
        slot: slotName
      }, this.$slots[slotName]));
      return this.$createElement(VSelectList, {
        ...this.listData
      }, slots);
    },
    genMenu() {
      const props2 = this.$_menuProps;
      props2.activator = this.$refs["input-slot"];
      if ("attach" in props2)
        ;
      else if (
        // TODO: make this a computed property or helper or something
        this.attach === "" || // If used as a boolean prop (<v-menu attach>)
        this.attach === true || // If bound to a boolean (<v-menu :attach="true">)
        this.attach === "attach"
      ) {
        props2.attach = this.$el;
      } else {
        props2.attach = this.attach;
      }
      return this.$createElement(VMenu, {
        attrs: { role: void 0 },
        props: props2,
        on: {
          input: (val) => {
            this.isMenuActive = val;
            this.isFocused = val;
          },
          scroll: this.onScroll
        },
        ref: "menu"
      }, [this.genList()]);
    },
    genSelections() {
      let length = this.selectedItems.length;
      const children = new Array(length);
      let genSelection;
      if (this.$scopedSlots.selection) {
        genSelection = this.genSlotSelection;
      } else if (this.hasChips) {
        genSelection = this.genChipSelection;
      } else {
        genSelection = this.genCommaSelection;
      }
      while (length--) {
        children[length] = genSelection(
          this.selectedItems[length],
          length,
          length === children.length - 1
        );
      }
      return this.$createElement("div", {
        staticClass: "v-select__selections"
      }, children);
    },
    genSlotSelection(item, index) {
      return this.$scopedSlots.selection({
        attrs: {
          class: "v-chip--select"
        },
        parent: this,
        item,
        index,
        select: (e) => {
          e.stopPropagation();
          this.selectedIndex = index;
        },
        selected: index === this.selectedIndex,
        disabled: !this.isInteractive
      });
    },
    getMenuIndex() {
      return this.$refs.menu ? this.$refs.menu.listIndex : -1;
    },
    getDisabled(item) {
      return getPropertyFromItem(item, this.itemDisabled, false);
    },
    getText(item) {
      return getPropertyFromItem(item, this.itemText, item);
    },
    getValue(item) {
      return getPropertyFromItem(item, this.itemValue, this.getText(item));
    },
    onBlur(e) {
      e && this.$emit("blur", e);
    },
    onChipInput(item) {
      if (this.multiple)
        this.selectItem(item);
      else
        this.setValue(null);
      if (this.selectedItems.length === 0) {
        this.isMenuActive = true;
      } else {
        this.isMenuActive = false;
      }
      this.selectedIndex = -1;
    },
    onClick(e) {
      if (!this.isInteractive)
        return;
      if (!this.isAppendInner(e.target)) {
        this.isMenuActive = true;
      }
      if (!this.isFocused) {
        this.isFocused = true;
        this.$emit("focus");
      }
      this.$emit("click", e);
    },
    onEscDown(e) {
      e.preventDefault();
      if (this.isMenuActive) {
        e.stopPropagation();
        this.isMenuActive = false;
      }
    },
    onKeyPress(e) {
      if (this.multiple || !this.isInteractive || this.disableLookup || e.key.length > 1 || e.ctrlKey || e.metaKey || e.altKey)
        return;
      const KEYBOARD_LOOKUP_THRESHOLD = 1e3;
      const now = performance.now();
      if (now - this.keyboardLookupLastTime > KEYBOARD_LOOKUP_THRESHOLD) {
        this.keyboardLookupPrefix = "";
      }
      this.keyboardLookupPrefix += e.key.toLowerCase();
      this.keyboardLookupLastTime = now;
      const index = this.allItems.findIndex((item2) => {
        const text = (this.getText(item2) ?? "").toString();
        return text.toLowerCase().startsWith(this.keyboardLookupPrefix);
      });
      const item = this.allItems[index];
      if (index !== -1) {
        this.lastItem = Math.max(this.lastItem, index + 5);
        this.setValue(this.returnObject ? item : this.getValue(item));
        this.$nextTick(() => this.$refs.menu.getTiles());
        setTimeout(() => this.setMenuIndex(index));
      }
    },
    onKeyDown(e) {
      if (this.isReadonly && e.keyCode !== keyCodes.tab)
        return;
      const keyCode = e.keyCode;
      const menu = this.$refs.menu;
      this.$emit("keydown", e);
      if (!menu)
        return;
      if (this.isMenuActive && [keyCodes.up, keyCodes.down, keyCodes.home, keyCodes.end, keyCodes.enter].includes(keyCode)) {
        this.$nextTick(() => {
          menu.changeListIndex(e);
          this.$emit("update:list-index", menu.listIndex);
        });
      }
      if ([
        keyCodes.enter,
        keyCodes.space
      ].includes(keyCode))
        this.activateMenu();
      if (!this.isMenuActive && [keyCodes.up, keyCodes.down, keyCodes.home, keyCodes.end].includes(keyCode))
        return this.onUpDown(e);
      if (keyCode === keyCodes.esc)
        return this.onEscDown(e);
      if (keyCode === keyCodes.tab)
        return this.onTabDown(e);
      if (keyCode === keyCodes.space)
        return this.onSpaceDown(e);
    },
    onMenuActiveChange(val) {
      if (this.multiple && !val || this.getMenuIndex() > -1)
        return;
      const menu = this.$refs.menu;
      if (!menu || !this.isDirty)
        return;
      this.$refs.menu.getTiles();
      for (let i = 0; i < menu.tiles.length; i++) {
        if (menu.tiles[i].getAttribute("aria-selected") === "true") {
          this.setMenuIndex(i);
          break;
        }
      }
    },
    onMouseUp(e) {
      if (this.hasMouseDown && e.which !== 3 && this.isInteractive) {
        if (this.isAppendInner(e.target)) {
          this.$nextTick(() => this.isMenuActive = !this.isMenuActive);
        }
      }
      VTextField.options.methods.onMouseUp.call(this, e);
    },
    onScroll() {
      if (!this.isMenuActive) {
        requestAnimationFrame(() => this.getContent().scrollTop = 0);
      } else {
        if (this.lastItem > this.computedItems.length)
          return;
        const showMoreItems = this.getContent().scrollHeight - (this.getContent().scrollTop + this.getContent().clientHeight) < 200;
        if (showMoreItems) {
          this.lastItem += 20;
        }
      }
    },
    onSpaceDown(e) {
      e.preventDefault();
    },
    onTabDown(e) {
      const menu = this.$refs.menu;
      if (!menu)
        return;
      const activeTile = menu.activeTile;
      if (!this.multiple && activeTile && this.isMenuActive) {
        e.preventDefault();
        e.stopPropagation();
        activeTile.click();
      } else {
        this.blur(e);
      }
    },
    onUpDown(e) {
      const menu = this.$refs.menu;
      if (!menu)
        return;
      e.preventDefault();
      if (this.multiple)
        return this.activateMenu();
      const keyCode = e.keyCode;
      menu.isBooted = true;
      window.requestAnimationFrame(() => {
        menu.getTiles();
        if (!menu.hasClickableTiles)
          return this.activateMenu();
        switch (keyCode) {
          case keyCodes.up:
            menu.prevTile();
            break;
          case keyCodes.down:
            menu.nextTile();
            break;
          case keyCodes.home:
            menu.firstTile();
            break;
          case keyCodes.end:
            menu.lastTile();
            break;
        }
        this.selectItem(this.allItems[this.getMenuIndex()]);
      });
    },
    selectItem(item) {
      if (!this.multiple) {
        this.setValue(this.returnObject ? item : this.getValue(item));
        this.isMenuActive = false;
      } else {
        const internalValue = (this.internalValue || []).slice();
        const i = this.findExistingIndex(item);
        i !== -1 ? internalValue.splice(i, 1) : internalValue.push(item);
        this.setValue(internalValue.map((i2) => {
          return this.returnObject ? i2 : this.getValue(i2);
        }));
        if (this.hideSelected) {
          this.setMenuIndex(-1);
        } else {
          const index = this.allItems.indexOf(item);
          if (~index) {
            this.$nextTick(() => this.$refs.menu.getTiles());
            setTimeout(() => this.setMenuIndex(index));
          }
        }
      }
    },
    setMenuIndex(index) {
      this.$refs.menu && (this.$refs.menu.listIndex = index);
    },
    setSelectedItems() {
      const selectedItems = [];
      const values = !this.multiple || !Array.isArray(this.internalValue) ? [this.internalValue] : this.internalValue;
      for (const value of values) {
        const index = this.allItems.findIndex((v) => this.valueComparator(
          this.getValue(v),
          this.getValue(value)
        ));
        if (index > -1) {
          selectedItems.push(this.allItems[index]);
        }
      }
      this.selectedItems = selectedItems;
    },
    setValue(value) {
      if (!this.valueComparator(value, this.internalValue)) {
        this.internalValue = value;
        this.$emit("change", value);
      }
    },
    isAppendInner(target) {
      const appendInner = this.$refs["append-inner"];
      return appendInner && (appendInner === target || appendInner.contains(target));
    }
  }
});
const defaultMenuProps = {
  ...defaultMenuProps$1,
  offsetY: true,
  offsetOverflow: true,
  transition: false
};
const VAutocomplete = VSelect.extend({
  name: "v-autocomplete",
  props: {
    autoSelectFirst: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Function,
      default: (item, queryText, itemText) => {
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
      }
    },
    hideNoData: Boolean,
    menuProps: {
      type: VSelect.options.props.menuProps.type,
      default: () => defaultMenuProps
    },
    noFilter: Boolean,
    searchInput: {
      type: String
    }
  },
  data() {
    return {
      lazySearch: this.searchInput
    };
  },
  computed: {
    classes() {
      return {
        ...VSelect.options.computed.classes.call(this),
        "v-autocomplete": true,
        "v-autocomplete--is-selecting-index": this.selectedIndex > -1
      };
    },
    computedItems() {
      return this.filteredItems;
    },
    selectedValues() {
      return this.selectedItems.map((item) => this.getValue(item));
    },
    hasDisplayedItems() {
      return this.hideSelected ? this.filteredItems.some((item) => !this.hasItem(item)) : this.filteredItems.length > 0;
    },
    currentRange() {
      if (this.selectedItem == null)
        return 0;
      return String(this.getText(this.selectedItem)).length;
    },
    filteredItems() {
      if (!this.isSearching || this.noFilter || this.internalSearch == null)
        return this.allItems;
      return this.allItems.filter((item) => {
        const value = getPropertyFromItem(item, this.itemText);
        const text = value != null ? String(value) : "";
        return this.filter(item, String(this.internalSearch), text);
      });
    },
    internalSearch: {
      get() {
        return this.lazySearch;
      },
      set(val) {
        if (this.lazySearch !== val) {
          this.lazySearch = val;
          this.$emit("update:search-input", val);
        }
      }
    },
    isAnyValueAllowed() {
      return false;
    },
    isDirty() {
      return this.searchIsDirty || this.selectedItems.length > 0;
    },
    isSearching() {
      return this.multiple && this.searchIsDirty || this.searchIsDirty && this.internalSearch !== this.getText(this.selectedItem);
    },
    menuCanShow() {
      if (!this.isFocused)
        return false;
      return this.hasDisplayedItems || !this.hideNoData;
    },
    $_menuProps() {
      const props2 = VSelect.options.computed.$_menuProps.call(this);
      props2.contentClass = `v-autocomplete__content ${props2.contentClass || ""}`.trim();
      return {
        ...defaultMenuProps,
        ...props2
      };
    },
    searchIsDirty() {
      return this.internalSearch != null && this.internalSearch !== "";
    },
    selectedItem() {
      if (this.multiple)
        return null;
      return this.selectedItems.find((i) => {
        return this.valueComparator(this.getValue(i), this.getValue(this.internalValue));
      });
    },
    listData() {
      const data = VSelect.options.computed.listData.call(this);
      data.props = {
        ...data.props,
        items: this.virtualizedItems,
        noFilter: this.noFilter || !this.isSearching || !this.filteredItems.length,
        searchInput: this.internalSearch
      };
      return data;
    }
  },
  watch: {
    filteredItems: "onFilteredItemsChanged",
    internalValue: "setSearch",
    isFocused(val) {
      if (val) {
        document.addEventListener("copy", this.onCopy);
        this.$refs.input && this.$refs.input.select();
      } else {
        document.removeEventListener("copy", this.onCopy);
        this.blur();
        this.updateSelf();
      }
    },
    isMenuActive(val) {
      if (val || !this.hasSlot)
        return;
      this.lazySearch = null;
    },
    items(val, oldVal) {
      if (!(oldVal && oldVal.length) && this.hideNoData && this.isFocused && !this.isMenuActive && val.length)
        this.activateMenu();
    },
    searchInput(val) {
      this.lazySearch = val;
    },
    internalSearch: "onInternalSearchChanged",
    itemText: "updateSelf"
  },
  created() {
    this.setSearch();
  },
  destroyed() {
    document.removeEventListener("copy", this.onCopy);
  },
  methods: {
    onFilteredItemsChanged(val, oldVal) {
      if (val === oldVal)
        return;
      if (!this.autoSelectFirst) {
        const preSelectedItem = oldVal[this.$refs.menu.listIndex];
        if (preSelectedItem) {
          this.setMenuIndex(val.findIndex((i) => i === preSelectedItem));
        } else {
          this.setMenuIndex(-1);
        }
        this.$emit("update:list-index", this.$refs.menu.listIndex);
      }
      this.$nextTick(() => {
        if (!this.internalSearch || val.length !== 1 && !this.autoSelectFirst)
          return;
        this.$refs.menu.getTiles();
        if (this.autoSelectFirst && val.length) {
          this.setMenuIndex(0);
          this.$emit("update:list-index", this.$refs.menu.listIndex);
        }
      });
    },
    onInternalSearchChanged() {
      this.updateMenuDimensions();
    },
    updateMenuDimensions() {
      this.isMenuActive && this.$refs.menu && this.$refs.menu.updateDimensions();
    },
    changeSelectedIndex(keyCode) {
      if (this.searchIsDirty)
        return;
      if (this.multiple && keyCode === keyCodes.left) {
        if (this.selectedIndex === -1) {
          this.selectedIndex = this.selectedItems.length - 1;
        } else {
          this.selectedIndex--;
        }
      } else if (this.multiple && keyCode === keyCodes.right) {
        if (this.selectedIndex >= this.selectedItems.length - 1) {
          this.selectedIndex = -1;
        } else {
          this.selectedIndex++;
        }
      } else if (keyCode === keyCodes.backspace || keyCode === keyCodes.delete) {
        this.deleteCurrentItem();
      }
    },
    deleteCurrentItem() {
      const curIndex = this.selectedIndex;
      const curItem = this.selectedItems[curIndex];
      if (!this.isInteractive || this.getDisabled(curItem))
        return;
      const lastIndex = this.selectedItems.length - 1;
      if (this.selectedIndex === -1 && lastIndex !== 0) {
        this.selectedIndex = lastIndex;
        return;
      }
      const length = this.selectedItems.length;
      const nextIndex = curIndex !== length - 1 ? curIndex : curIndex - 1;
      const nextItem = this.selectedItems[nextIndex];
      if (!nextItem) {
        this.setValue(this.multiple ? [] : null);
      } else {
        this.selectItem(curItem);
      }
      this.selectedIndex = nextIndex;
    },
    clearableCallback() {
      this.internalSearch = null;
      VSelect.options.methods.clearableCallback.call(this);
    },
    genInput() {
      const input = VTextField.options.methods.genInput.call(this);
      input.data = mergeData(input.data, {
        attrs: {
          "aria-activedescendant": getObjectValueByPath(this.$refs.menu, "activeTile.id"),
          autocomplete: getObjectValueByPath(input.data, "attrs.autocomplete", "off")
        },
        domProps: { value: this.internalSearch }
      });
      return input;
    },
    genInputSlot() {
      const slot = VSelect.options.methods.genInputSlot.call(this);
      slot.data.attrs.role = "combobox";
      return slot;
    },
    genSelections() {
      return this.hasSlot || this.multiple ? VSelect.options.methods.genSelections.call(this) : [];
    },
    onClick(e) {
      if (!this.isInteractive)
        return;
      this.selectedIndex > -1 ? this.selectedIndex = -1 : this.onFocus();
      if (!this.isAppendInner(e.target))
        this.activateMenu();
    },
    onInput(e) {
      if (this.selectedIndex > -1 || !e.target)
        return;
      const target = e.target;
      const value = target.value;
      if (target.value)
        this.activateMenu();
      if (!this.multiple && value === "")
        this.deleteCurrentItem();
      this.internalSearch = value;
      this.badInput = target.validity && target.validity.badInput;
    },
    onKeyDown(e) {
      const keyCode = e.keyCode;
      if (e.ctrlKey || ![keyCodes.home, keyCodes.end].includes(keyCode)) {
        VSelect.options.methods.onKeyDown.call(this, e);
      }
      this.changeSelectedIndex(keyCode);
    },
    onSpaceDown(e) {
    },
    onTabDown(e) {
      VSelect.options.methods.onTabDown.call(this, e);
      this.updateSelf();
    },
    onUpDown(e) {
      e.preventDefault();
      this.activateMenu();
    },
    selectItem(item) {
      VSelect.options.methods.selectItem.call(this, item);
      this.setSearch();
    },
    setSelectedItems() {
      VSelect.options.methods.setSelectedItems.call(this);
      if (!this.isFocused)
        this.setSearch();
    },
    setSearch() {
      this.$nextTick(() => {
        if (!this.multiple || !this.internalSearch || !this.isMenuActive) {
          this.internalSearch = !this.selectedItems.length || this.multiple || this.hasSlot ? null : this.getText(this.selectedItem);
        }
      });
    },
    updateSelf() {
      if (!this.searchIsDirty && !this.internalValue)
        return;
      if (!this.multiple && !this.valueComparator(
        this.internalSearch,
        this.getValue(this.internalValue)
      )) {
        this.setSearch();
      }
    },
    hasItem(item) {
      return this.selectedValues.indexOf(this.getValue(item)) > -1;
    },
    onCopy(event) {
      if (this.selectedIndex === -1)
        return;
      const currentItem = this.selectedItems[this.selectedIndex];
      const currentItemText = this.getText(currentItem);
      event.clipboardData?.setData("text/plain", currentItemText);
      event.clipboardData?.setData("text/vnd.vuetify.autocomplete.item+plain", currentItemText);
      event.preventDefault();
    }
  }
});
const VBadge = mixins(
  Colorable,
  factory$3(["left", "bottom"]),
  Themeable$1,
  Toggleable$1,
  Transitionable
  /* @vue/component */
).extend({
  name: "v-badge",
  props: {
    avatar: Boolean,
    bordered: Boolean,
    color: {
      type: String,
      default: "primary"
    },
    content: { required: false },
    dot: Boolean,
    label: {
      type: String,
      default: "$vuetify.badge"
    },
    icon: String,
    inline: Boolean,
    offsetX: [Number, String],
    offsetY: [Number, String],
    overlap: Boolean,
    tile: Boolean,
    transition: {
      type: String,
      default: "scale-rotate-transition"
    },
    value: { default: true }
  },
  computed: {
    classes() {
      return {
        "v-badge--avatar": this.avatar,
        "v-badge--bordered": this.bordered,
        "v-badge--bottom": this.bottom,
        "v-badge--dot": this.dot,
        "v-badge--icon": this.icon != null,
        "v-badge--inline": this.inline,
        "v-badge--left": this.left,
        "v-badge--overlap": this.overlap,
        "v-badge--tile": this.tile,
        ...this.themeClasses
      };
    },
    computedBottom() {
      return this.bottom ? "auto" : this.computedYOffset;
    },
    computedLeft() {
      if (this.isRtl) {
        return this.left ? this.computedXOffset : "auto";
      }
      return this.left ? "auto" : this.computedXOffset;
    },
    computedRight() {
      if (this.isRtl) {
        return this.left ? "auto" : this.computedXOffset;
      }
      return !this.left ? "auto" : this.computedXOffset;
    },
    computedTop() {
      return this.bottom ? this.computedYOffset : "auto";
    },
    computedXOffset() {
      return this.calcPosition(this.offsetX);
    },
    computedYOffset() {
      return this.calcPosition(this.offsetY);
    },
    isRtl() {
      return this.$vuetify.rtl;
    },
    // Default fallback if offsetX
    // or offsetY are undefined.
    offset() {
      if (this.overlap)
        return this.dot ? 8 : 12;
      return this.dot ? 2 : 4;
    },
    styles() {
      if (this.inline)
        return {};
      return {
        bottom: this.computedBottom,
        left: this.computedLeft,
        right: this.computedRight,
        top: this.computedTop
      };
    }
  },
  methods: {
    calcPosition(offset) {
      return `calc(100% - ${convertToUnit(offset || this.offset)})`;
    },
    genBadge() {
      const lang = this.$vuetify.lang;
      const label = this.$attrs["aria-label"] || lang.t(this.label);
      const data = this.setBackgroundColor(this.color, {
        staticClass: "v-badge__badge",
        style: this.styles,
        attrs: {
          "aria-atomic": this.$attrs["aria-atomic"] || "true",
          "aria-label": label,
          "aria-live": this.$attrs["aria-live"] || "polite",
          title: this.$attrs.title,
          role: this.$attrs.role || "status"
        },
        directives: [{
          name: "show",
          value: this.isActive
        }]
      });
      const badge = this.$createElement("span", data, [this.genBadgeContent()]);
      if (!this.transition)
        return badge;
      return this.$createElement("transition", {
        props: {
          name: this.transition,
          origin: this.origin,
          mode: this.mode
        }
      }, [badge]);
    },
    genBadgeContent() {
      if (this.dot)
        return void 0;
      const slot = getSlot(this, "badge");
      if (slot)
        return slot;
      if (this.content)
        return String(this.content);
      if (this.icon)
        return this.$createElement(VIcon$1, this.icon);
      return void 0;
    },
    genBadgeWrapper() {
      return this.$createElement("span", {
        staticClass: "v-badge__wrapper"
      }, [this.genBadge()]);
    }
  },
  render(h) {
    const badge = [this.genBadgeWrapper()];
    const children = [getSlot(this)];
    const {
      "aria-atomic": _x,
      "aria-label": _y,
      "aria-live": _z,
      role,
      title,
      ...attrs
    } = this.$attrs;
    if (this.inline && this.left)
      children.unshift(badge);
    else
      children.push(badge);
    return h("span", {
      staticClass: "v-badge",
      attrs,
      class: this.classes
    }, children);
  }
});
const Mobile = Vue.extend({
  name: "mobile",
  props: {
    mobileBreakpoint: {
      type: [Number, String],
      default() {
        return this.$vuetify ? this.$vuetify.breakpoint.mobileBreakpoint : void 0;
      },
      validator: (v) => !isNaN(Number(v)) || ["xs", "sm", "md", "lg", "xl"].includes(String(v))
    }
  },
  computed: {
    isMobile() {
      const {
        mobile,
        width,
        name,
        mobileBreakpoint
      } = this.$vuetify.breakpoint;
      if (mobileBreakpoint === this.mobileBreakpoint)
        return mobile;
      const mobileWidth = parseInt(this.mobileBreakpoint, 10);
      const isNumber = !isNaN(mobileWidth);
      return isNumber ? width < mobileWidth : name === this.mobileBreakpoint;
    }
  },
  created() {
    if (this.$attrs.hasOwnProperty("mobile-break-point")) {
      deprecate("mobile-break-point", "mobile-breakpoint", this);
    }
  }
});
const VBanner = mixins(
  VSheet,
  Mobile,
  Toggleable$1
).extend({
  name: "v-banner",
  inheritAttrs: false,
  props: {
    app: Boolean,
    icon: String,
    iconColor: String,
    singleLine: Boolean,
    sticky: Boolean,
    value: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes() {
      return {
        ...VSheet.options.computed.classes.call(this),
        "v-banner--has-icon": this.hasIcon,
        "v-banner--is-mobile": this.isMobile,
        "v-banner--single-line": this.singleLine,
        "v-banner--sticky": this.isSticky
      };
    },
    hasIcon() {
      return Boolean(this.icon || this.$slots.icon);
    },
    isSticky() {
      return this.sticky || this.app;
    },
    styles() {
      const styles = { ...VSheet.options.computed.styles.call(this) };
      if (this.isSticky) {
        const top = !this.app ? 0 : this.$vuetify.application.bar + this.$vuetify.application.top;
        styles.top = convertToUnit(top);
        styles.position = "sticky";
        styles.zIndex = 1;
      }
      return styles;
    }
  },
  methods: {
    /** @public */
    toggle() {
      this.isActive = !this.isActive;
    },
    iconClick(e) {
      this.$emit("click:icon", e);
    },
    genIcon() {
      if (!this.hasIcon)
        return void 0;
      let content;
      if (this.icon) {
        content = this.$createElement(VIcon$1, {
          props: {
            color: this.iconColor,
            size: 28
          }
        }, [this.icon]);
      } else {
        content = this.$slots.icon;
      }
      return this.$createElement(VAvatar, {
        staticClass: "v-banner__icon",
        props: {
          color: this.color,
          size: 40
        },
        on: {
          click: this.iconClick
        }
      }, [content]);
    },
    genText() {
      return this.$createElement("div", {
        staticClass: "v-banner__text"
      }, this.$slots.default);
    },
    genActions() {
      const children = getSlot(this, "actions", {
        dismiss: () => this.isActive = false
      });
      if (!children)
        return void 0;
      return this.$createElement("div", {
        staticClass: "v-banner__actions"
      }, children);
    },
    genContent() {
      return this.$createElement("div", {
        staticClass: "v-banner__content"
      }, [
        this.genIcon(),
        this.genText()
      ]);
    },
    genWrapper() {
      return this.$createElement("div", {
        staticClass: "v-banner__wrapper"
      }, [
        this.genContent(),
        this.genActions()
      ]);
    }
  },
  render(h) {
    const data = {
      staticClass: "v-banner",
      attrs: this.attrs$,
      class: this.classes,
      style: this.styles,
      directives: [{
        name: "show",
        value: this.isActive
      }]
    };
    return h(VExpandTransition, [
      h(
        "div",
        this.outlined ? data : this.setBackgroundColor(this.color, data),
        [this.genWrapper()]
      )
    ]);
  }
});
const ButtonGroup = BaseItemGroup.extend({
  name: "button-group",
  provide() {
    return {
      btnToggle: this
    };
  },
  computed: {
    classes() {
      return BaseItemGroup.options.computed.classes.call(this);
    }
  },
  methods: {
    // Isn't being passed down through types
    genData: BaseItemGroup.options.methods.genData
  }
});
const VBottomNavigation = mixins(
  applicationable("bottom", [
    "height",
    "inputValue"
  ]),
  Colorable,
  Measurable,
  factory$2("inputValue"),
  Proxyable$1,
  Scrollable,
  Themeable$1
  /* @vue/component */
).extend({
  name: "v-bottom-navigation",
  props: {
    activeClass: {
      type: String,
      default: "v-btn--active"
    },
    backgroundColor: String,
    grow: Boolean,
    height: {
      type: [Number, String],
      default: 56
    },
    hideOnScroll: Boolean,
    horizontal: Boolean,
    inputValue: {
      type: Boolean,
      default: true
    },
    mandatory: Boolean,
    shift: Boolean,
    tag: {
      type: String,
      default: "div"
    }
  },
  data() {
    return {
      isActive: this.inputValue
    };
  },
  computed: {
    canScroll() {
      return Scrollable.options.computed.canScroll.call(this) && (this.hideOnScroll || !this.inputValue);
    },
    classes() {
      return {
        "v-bottom-navigation--absolute": this.absolute,
        "v-bottom-navigation--grow": this.grow,
        "v-bottom-navigation--fixed": !this.absolute && (this.app || this.fixed),
        "v-bottom-navigation--horizontal": this.horizontal,
        "v-bottom-navigation--shift": this.shift
      };
    },
    styles() {
      return {
        ...this.measurableStyles,
        transform: this.isActive ? "none" : "translateY(100%)"
      };
    }
  },
  watch: {
    canScroll: "onScroll"
  },
  created() {
    if (this.$attrs.hasOwnProperty("active")) {
      breaking("active.sync", "value or v-model", this);
    }
  },
  methods: {
    thresholdMet() {
      if (this.hideOnScroll) {
        this.isActive = !this.isScrollingUp || this.currentScroll > this.computedScrollThreshold;
        this.$emit("update:input-value", this.isActive);
      }
      if (this.currentThreshold < this.computedScrollThreshold)
        return;
      this.savedScroll = this.currentScroll;
    },
    updateApplication() {
      return this.$el ? this.$el.clientHeight : 0;
    },
    updateValue(val) {
      this.$emit("change", val);
    }
  },
  render(h) {
    const data = this.setBackgroundColor(this.backgroundColor, {
      staticClass: "v-bottom-navigation",
      class: this.classes,
      style: this.styles,
      props: {
        activeClass: this.activeClass,
        mandatory: Boolean(
          this.mandatory || this.value !== void 0
        ),
        tag: this.tag,
        value: this.internalValue
      },
      on: { change: this.updateValue }
    });
    if (this.canScroll) {
      data.directives = data.directives || [];
      data.directives.push({
        arg: this.scrollTarget,
        name: "scroll",
        value: this.onScroll
      });
    }
    return h(ButtonGroup, this.setTextColor(this.color, data), this.$slots.default);
  }
});
const VOverlay = mixins(
  Colorable,
  Themeable$1,
  Toggleable$1
).extend({
  name: "v-overlay",
  props: {
    absolute: Boolean,
    color: {
      type: String,
      default: "#212121"
    },
    dark: {
      type: Boolean,
      default: true
    },
    opacity: {
      type: [Number, String],
      default: 0.46
    },
    value: {
      default: true
    },
    zIndex: {
      type: [Number, String],
      default: 5
    }
  },
  computed: {
    __scrim() {
      const data = this.setBackgroundColor(this.color, {
        staticClass: "v-overlay__scrim",
        style: {
          opacity: this.computedOpacity
        }
      });
      return this.$createElement("div", data);
    },
    classes() {
      return {
        "v-overlay--absolute": this.absolute,
        "v-overlay--active": this.isActive,
        ...this.themeClasses
      };
    },
    computedOpacity() {
      return Number(this.isActive ? this.opacity : 0);
    },
    styles() {
      return {
        zIndex: this.zIndex
      };
    }
  },
  methods: {
    genContent() {
      return this.$createElement("div", {
        staticClass: "v-overlay__content"
      }, this.$slots.default);
    }
  },
  render(h) {
    const children = [this.__scrim];
    if (this.isActive)
      children.push(this.genContent());
    return h("div", {
      staticClass: "v-overlay",
      on: this.$listeners,
      class: this.classes,
      style: this.styles
    }, children);
  }
});
const Overlayable = Vue.extend().extend({
  name: "overlayable",
  props: {
    hideOverlay: Boolean,
    overlayColor: String,
    overlayOpacity: [Number, String]
  },
  data() {
    return {
      animationFrame: 0,
      overlay: null
    };
  },
  watch: {
    hideOverlay(value) {
      if (!this.isActive)
        return;
      if (value)
        this.removeOverlay();
      else
        this.genOverlay();
    }
  },
  beforeDestroy() {
    this.removeOverlay();
  },
  methods: {
    createOverlay() {
      const overlay = new VOverlay({
        propsData: {
          absolute: this.absolute,
          value: false,
          color: this.overlayColor,
          opacity: this.overlayOpacity
        }
      });
      overlay.$mount();
      const parent = this.absolute ? this.$el.parentNode : document.querySelector("[data-app]");
      parent && parent.insertBefore(overlay.$el, parent.firstChild);
      this.overlay = overlay;
    },
    genOverlay() {
      this.hideScroll();
      if (this.hideOverlay)
        return;
      if (!this.overlay)
        this.createOverlay();
      this.animationFrame = requestAnimationFrame(() => {
        if (!this.overlay)
          return;
        if (this.activeZIndex !== void 0) {
          this.overlay.zIndex = String(this.activeZIndex - 1);
        } else if (this.$el) {
          this.overlay.zIndex = getZIndex(this.$el);
        }
        this.overlay.value = true;
      });
      return true;
    },
    /** removeOverlay(false) will not restore the scollbar afterwards */
    removeOverlay(showScroll = true) {
      if (this.overlay) {
        addOnceEventListener(this.overlay.$el, "transitionend", () => {
          if (!this.overlay || !this.overlay.$el || !this.overlay.$el.parentNode || this.overlay.value || this.isActive)
            return;
          this.overlay.$el.parentNode.removeChild(this.overlay.$el);
          this.overlay.$destroy();
          this.overlay = null;
        });
        cancelAnimationFrame(this.animationFrame);
        this.overlay.value = false;
      }
      showScroll && this.showScroll();
    },
    scrollListener(e) {
      if ("key" in e) {
        if (["INPUT", "TEXTAREA", "SELECT"].includes(e.target.tagName) || // https://github.com/vuetifyjs/vuetify/issues/4715
        e.target.isContentEditable)
          return;
        const up = [keyCodes.up, keyCodes.pageup];
        const down = [keyCodes.down, keyCodes.pagedown];
        if (up.includes(e.keyCode)) {
          e.deltaY = -1;
        } else if (down.includes(e.keyCode)) {
          e.deltaY = 1;
        } else {
          return;
        }
      }
      if (e.target === this.overlay || e.type !== "keydown" && e.target === document.body || this.checkPath(e))
        e.preventDefault();
    },
    hasScrollbar(el) {
      if (!el || el.nodeType !== Node.ELEMENT_NODE)
        return false;
      const style = window.getComputedStyle(el);
      return (["auto", "scroll"].includes(style.overflowY) || el.tagName === "SELECT") && el.scrollHeight > el.clientHeight || ["auto", "scroll"].includes(style.overflowX) && el.scrollWidth > el.clientWidth;
    },
    shouldScroll(el, e) {
      if (el.hasAttribute("data-app"))
        return false;
      const dir = e.shiftKey || e.deltaX ? "x" : "y";
      const delta2 = dir === "y" ? e.deltaY : e.deltaX || e.deltaY;
      let alreadyAtStart;
      let alreadyAtEnd;
      if (dir === "y") {
        alreadyAtStart = el.scrollTop === 0;
        alreadyAtEnd = el.scrollTop + el.clientHeight === el.scrollHeight;
      } else {
        alreadyAtStart = el.scrollLeft === 0;
        alreadyAtEnd = el.scrollLeft + el.clientWidth === el.scrollWidth;
      }
      const scrollingUp = delta2 < 0;
      const scrollingDown = delta2 > 0;
      if (!alreadyAtStart && scrollingUp)
        return true;
      if (!alreadyAtEnd && scrollingDown)
        return true;
      if ((alreadyAtStart || alreadyAtEnd) && el.parentNode) {
        return this.shouldScroll(el.parentNode, e);
      }
      return false;
    },
    isInside(el, parent) {
      if (el === parent) {
        return true;
      } else if (el === null || el === document.body) {
        return false;
      } else {
        return this.isInside(el.parentNode, parent);
      }
    },
    checkPath(e) {
      const path = composedPath(e);
      if (e.type === "keydown" && path[0] === document.body) {
        const dialog = this.$refs.dialog;
        const selected = window.getSelection().anchorNode;
        if (dialog && this.hasScrollbar(dialog) && this.isInside(selected, dialog)) {
          return !this.shouldScroll(dialog, e);
        }
        return true;
      }
      for (let index = 0; index < path.length; index++) {
        const el = path[index];
        if (el === document)
          return true;
        if (el === document.documentElement)
          return true;
        if (el === this.$refs.content)
          return true;
        if (this.hasScrollbar(el))
          return !this.shouldScroll(el, e);
      }
      return true;
    },
    hideScroll() {
      if (this.$vuetify.breakpoint.smAndDown) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        addPassiveEventListener(window, "wheel", this.scrollListener, { passive: false });
        window.addEventListener("keydown", this.scrollListener);
      }
    },
    showScroll() {
      document.documentElement.classList.remove("overflow-y-hidden");
      window.removeEventListener("wheel", this.scrollListener);
      window.removeEventListener("keydown", this.scrollListener);
    }
  }
});
const baseMixins$i = mixins(
  Dependent,
  Detachable,
  Overlayable,
  Returnable,
  Stackable,
  Activatable
);
const VDialog = baseMixins$i.extend({
  name: "v-dialog",
  directives: { ClickOutside: ClickOutside$1 },
  props: {
    dark: Boolean,
    disabled: Boolean,
    fullscreen: Boolean,
    light: Boolean,
    maxWidth: [String, Number],
    noClickAnimation: Boolean,
    origin: {
      type: String,
      default: "center center"
    },
    persistent: Boolean,
    retainFocus: {
      type: Boolean,
      default: true
    },
    scrollable: Boolean,
    transition: {
      type: [String, Boolean],
      default: "dialog-transition"
    },
    width: [String, Number]
  },
  data() {
    return {
      activatedBy: null,
      animate: false,
      animateTimeout: -1,
      stackMinZIndex: 200,
      previousActiveElement: null
    };
  },
  computed: {
    classes() {
      return {
        [`v-dialog ${this.contentClass}`.trim()]: true,
        "v-dialog--active": this.isActive,
        "v-dialog--persistent": this.persistent,
        "v-dialog--fullscreen": this.fullscreen,
        "v-dialog--scrollable": this.scrollable,
        "v-dialog--animated": this.animate
      };
    },
    contentClasses() {
      return {
        "v-dialog__content": true,
        "v-dialog__content--active": this.isActive
      };
    },
    hasActivator() {
      return Boolean(
        !!this.$slots.activator || !!this.$scopedSlots.activator
      );
    }
  },
  watch: {
    isActive(val) {
      if (val) {
        this.show();
        this.hideScroll();
      } else {
        this.removeOverlay();
        this.unbind();
        this.previousActiveElement?.focus();
      }
    },
    fullscreen(val) {
      if (!this.isActive)
        return;
      if (val) {
        this.hideScroll();
        this.removeOverlay(false);
      } else {
        this.showScroll();
        this.genOverlay();
      }
    }
  },
  created() {
    if (this.$attrs.hasOwnProperty("full-width")) {
      removed("full-width", this);
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      this.isBooted = this.isActive;
      this.isActive && this.show();
    });
  },
  beforeDestroy() {
    if (typeof window !== "undefined")
      this.unbind();
  },
  methods: {
    animateClick() {
      this.animate = false;
      this.$nextTick(() => {
        this.animate = true;
        window.clearTimeout(this.animateTimeout);
        this.animateTimeout = window.setTimeout(() => this.animate = false, 150);
      });
    },
    closeConditional(e) {
      const target = e.target;
      return !(this._isDestroyed || !this.isActive || this.$refs.content.contains(target) || this.overlay && target && !this.overlay.$el.contains(target)) && this.activeZIndex >= this.getMaxZIndex();
    },
    hideScroll() {
      if (this.fullscreen) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        Overlayable.options.methods.hideScroll.call(this);
      }
    },
    show() {
      !this.fullscreen && !this.hideOverlay && this.genOverlay();
      this.$nextTick(() => {
        this.$nextTick(() => {
          if (!this.$refs.dialog?.contains(document.activeElement)) {
            this.previousActiveElement = document.activeElement;
            this.$refs.dialog?.focus();
          }
          this.bind();
        });
      });
    },
    bind() {
      window.addEventListener("focusin", this.onFocusin);
    },
    unbind() {
      window.removeEventListener("focusin", this.onFocusin);
    },
    onClickOutside(e) {
      this.$emit("click:outside", e);
      if (this.persistent) {
        this.noClickAnimation || this.animateClick();
      } else {
        this.isActive = false;
      }
    },
    onKeydown(e) {
      if (e.keyCode === keyCodes.esc && !this.getOpenDependents().length) {
        if (!this.persistent) {
          this.isActive = false;
          const activator = this.getActivator();
          this.$nextTick(() => activator && activator.focus());
        } else if (!this.noClickAnimation) {
          this.animateClick();
        }
      }
      this.$emit("keydown", e);
    },
    // On focus change, wrap focus to stay inside the dialog
    // https://github.com/vuetifyjs/vuetify/issues/6892
    onFocusin(e) {
      if (!e || !this.retainFocus)
        return;
      const target = e.target;
      if (!!target && this.$refs.dialog && // It isn't the document or the dialog body
      ![document, this.$refs.dialog].includes(target) && // It isn't inside the dialog body
      !this.$refs.dialog.contains(target) && // We're the topmost dialog
      this.activeZIndex >= this.getMaxZIndex() && // It isn't inside a dependent element (like a menu)
      !this.getOpenDependentElements().some((el) => el.contains(target))) {
        const focusable = this.$refs.dialog.querySelectorAll('button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])');
        const el = [...focusable].find((el2) => !el2.hasAttribute("disabled") && !el2.matches('[tabindex="-1"]'));
        el && el.focus();
      }
    },
    genContent() {
      return this.showLazyContent(() => [
        this.$createElement(VThemeProvider, {
          props: {
            root: true,
            light: this.light,
            dark: this.dark
          }
        }, [
          this.$createElement("div", {
            class: this.contentClasses,
            attrs: {
              role: "dialog",
              "aria-modal": this.hideOverlay ? void 0 : "true",
              ...this.getScopeIdAttrs()
            },
            on: { keydown: this.onKeydown },
            style: { zIndex: this.activeZIndex },
            ref: "content"
          }, [this.genTransition()])
        ])
      ]);
    },
    genTransition() {
      const content = this.genInnerContent();
      if (!this.transition)
        return content;
      return this.$createElement("transition", {
        props: {
          name: this.transition,
          origin: this.origin,
          appear: true
        }
      }, [content]);
    },
    genInnerContent() {
      const data = {
        class: this.classes,
        attrs: {
          tabindex: this.isActive ? 0 : void 0
        },
        ref: "dialog",
        directives: [
          {
            name: "click-outside",
            value: {
              handler: this.onClickOutside,
              closeConditional: this.closeConditional,
              include: this.getOpenDependentElements
            }
          },
          { name: "show", value: this.isActive }
        ],
        style: {
          transformOrigin: this.origin
        }
      };
      if (!this.fullscreen) {
        data.style = {
          ...data.style,
          maxWidth: convertToUnit(this.maxWidth),
          width: convertToUnit(this.width)
        };
      }
      return this.$createElement("div", data, this.getContentSlot());
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-dialog__container",
      class: {
        "v-dialog__container--attached": this.attach === "" || this.attach === true || this.attach === "attach"
      }
    }, [
      this.genActivator(),
      this.genContent()
    ]);
  }
});
const VBottomSheet = VDialog.extend({
  name: "v-bottom-sheet",
  props: {
    inset: Boolean,
    maxWidth: [String, Number],
    transition: {
      type: String,
      default: "bottom-sheet-transition"
    }
  },
  computed: {
    classes() {
      return {
        ...VDialog.options.computed.classes.call(this),
        "v-bottom-sheet": true,
        "v-bottom-sheet--inset": this.inset
      };
    }
  }
});
const VBreadcrumbsItem = mixins(Routable).extend({
  name: "v-breadcrumbs-item",
  props: {
    // In a breadcrumb, the currently
    // active item should be dimmed
    activeClass: {
      type: String,
      default: "v-breadcrumbs__item--disabled"
    },
    ripple: {
      type: [Boolean, Object],
      default: false
    }
  },
  computed: {
    classes() {
      return {
        "v-breadcrumbs__item": true,
        [this.activeClass]: this.disabled
      };
    }
  },
  render(h) {
    const { tag, data } = this.generateRouteLink();
    return h("li", [
      h(tag, {
        ...data,
        attrs: {
          ...data.attrs,
          "aria-current": this.isActive && this.isLink ? "page" : void 0
        }
      }, this.$slots.default)
    ]);
  }
});
const VBreadcrumbsDivider = createSimpleFunctional("v-breadcrumbs__divider", "li");
const VBreadcrumbs = mixins(
  Themeable$1
  /* @vue/component */
).extend({
  name: "v-breadcrumbs",
  props: {
    divider: {
      type: String,
      default: "/"
    },
    items: {
      type: Array,
      default: () => []
    },
    large: Boolean
  },
  computed: {
    classes() {
      return {
        "v-breadcrumbs--large": this.large,
        ...this.themeClasses
      };
    }
  },
  methods: {
    genDivider() {
      return this.$createElement(VBreadcrumbsDivider, this.$slots.divider ? this.$slots.divider : this.divider);
    },
    genItems() {
      const items = [];
      const hasSlot = !!this.$scopedSlots.item;
      const keys2 = [];
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        keys2.push(item.text);
        if (hasSlot)
          items.push(this.$scopedSlots.item({ item }));
        else
          items.push(this.$createElement(VBreadcrumbsItem, { key: keys2.join("."), props: item }, [item.text]));
        if (i < this.items.length - 1)
          items.push(this.genDivider());
      }
      return items;
    }
  },
  render(h) {
    const children = this.$slots.default || this.genItems();
    return h("ul", {
      staticClass: "v-breadcrumbs",
      class: this.classes
    }, children);
  }
});
const VBtnToggle = mixins(
  ButtonGroup,
  Colorable
).extend({
  name: "v-btn-toggle",
  props: {
    backgroundColor: String,
    borderless: Boolean,
    dense: Boolean,
    group: Boolean,
    rounded: Boolean,
    shaped: Boolean,
    tile: Boolean
  },
  computed: {
    classes() {
      return {
        ...ButtonGroup.options.computed.classes.call(this),
        "v-btn-toggle": true,
        "v-btn-toggle--borderless": this.borderless,
        "v-btn-toggle--dense": this.dense,
        "v-btn-toggle--group": this.group,
        "v-btn-toggle--rounded": this.rounded,
        "v-btn-toggle--shaped": this.shaped,
        "v-btn-toggle--tile": this.tile,
        ...this.themeClasses
      };
    }
  },
  methods: {
    genData() {
      const data = this.setTextColor(this.color, {
        ...ButtonGroup.options.methods.genData.call(this)
      });
      if (this.group)
        return data;
      return this.setBackgroundColor(this.backgroundColor, data);
    }
  }
});
const Localable = Vue.extend({
  name: "localable",
  props: {
    locale: String
  },
  computed: {
    currentLocale() {
      return this.locale || this.$vuetify.lang.current;
    }
  }
});
const Mouse = Vue.extend({
  name: "mouse",
  methods: {
    getDefaultMouseEventHandlers(suffix, getData, eventFirst = false) {
      const listeners = Object.keys(this.$listeners).filter((key) => key.endsWith(suffix)).reduce((acc, key) => {
        acc[key] = { event: key.slice(0, -suffix.length) };
        return acc;
      }, {});
      return this.getMouseEventHandlers({
        ...listeners,
        ["contextmenu" + suffix]: { event: "contextmenu", prevent: true, result: false }
      }, getData, eventFirst);
    },
    getMouseEventHandlers(events, getData, eventFirst = false) {
      const on = {};
      for (const event in events) {
        const eventOptions = events[event];
        if (!this.$listeners[event])
          continue;
        const prefix = eventOptions.passive ? "&" : (eventOptions.once ? "~" : "") + (eventOptions.capture ? "!" : "");
        const key = prefix + eventOptions.event;
        const handler = (e) => {
          const mouseEvent = e;
          if (eventOptions.button === void 0 || mouseEvent.buttons > 0 && mouseEvent.button === eventOptions.button) {
            if (eventOptions.prevent) {
              e.preventDefault();
            }
            if (eventOptions.stop) {
              e.stopPropagation();
            }
            if (e && "touches" in e) {
              const classSeparator = " ";
              const eventTargetClasses = e.currentTarget?.className.split(classSeparator);
              const currentTargets = document.elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
              const currentTarget = currentTargets.find((t) => t.className.split(classSeparator).some((c) => eventTargetClasses.includes(c)));
              if (currentTarget && !e.target?.isSameNode(currentTarget)) {
                currentTarget.dispatchEvent(new TouchEvent(e.type, {
                  changedTouches: e.changedTouches,
                  targetTouches: e.targetTouches,
                  touches: e.touches
                }));
                return;
              }
            }
            if (eventFirst) {
              this.$emit(event, e, getData(e));
            } else {
              this.$emit(event, getData(e), e);
            }
          }
          return eventOptions.result;
        };
        if (key in on) {
          if (Array.isArray(on[key])) {
            on[key].push(handler);
          } else {
            on[key] = [on[key], handler];
          }
        } else {
          on[key] = handler;
        }
      }
      return on;
    }
  }
});
function createUTCDate(year, month = 0, day = 1) {
  let date;
  if (year < 100 && year >= 0) {
    date = new Date(Date.UTC(year, month, day));
    if (isFinite(date.getUTCFullYear())) {
      date.setUTCFullYear(year);
    }
  } else {
    date = new Date(Date.UTC(year, month, day));
  }
  return date;
}
function firstWeekOffset(year, firstDayOfWeek, firstDayOfYear) {
  const firstWeekDayInFirstWeek = 7 + firstDayOfWeek - firstDayOfYear;
  const firstWeekDayOfYear = (7 + createUTCDate(year, 0, firstWeekDayInFirstWeek).getUTCDay() - firstDayOfWeek) % 7;
  return -firstWeekDayOfYear + firstWeekDayInFirstWeek - 1;
}
function dayOfYear(year, month, day, firstDayOfWeek) {
  let dayOfYear2 = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334][month];
  if (month > 1 && isLeapYear(year)) {
    dayOfYear2++;
  }
  return dayOfYear2 + day;
}
function weeksInYear(year, firstDayOfWeek, firstDayOfYear) {
  const weekOffset = firstWeekOffset(year, firstDayOfWeek, firstDayOfYear);
  const weekOffsetNext = firstWeekOffset(year + 1, firstDayOfWeek, firstDayOfYear);
  const daysInYear = isLeapYear(year) ? 366 : 365;
  return (daysInYear - weekOffset + weekOffsetNext) / 7;
}
function weekNumber(year, month, day, firstDayOfWeek, localeFirstDayOfYear) {
  const weekOffset = firstWeekOffset(year, firstDayOfWeek, localeFirstDayOfYear);
  const week = Math.ceil((dayOfYear(year, month, day) - weekOffset) / 7);
  if (week < 1) {
    return week + weeksInYear(year - 1, firstDayOfWeek, localeFirstDayOfYear);
  } else if (week > weeksInYear(year, firstDayOfWeek, localeFirstDayOfYear)) {
    return week - weeksInYear(year, firstDayOfWeek, localeFirstDayOfYear);
  } else {
    return week;
  }
}
function isLeapYear(year) {
  return year % 4 === 0 && year % 100 !== 0 || year % 400 === 0;
}
const PARSE_REGEX = /^(\d{4})-(\d{1,2})(-(\d{1,2}))?([^\d]+(\d{1,2}))?(:(\d{1,2}))?(:(\d{1,2}))?$/;
const PARSE_TIME = /(\d\d?)(:(\d\d?)|)(:(\d\d?)|)/;
const DAYS_IN_MONTH = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const DAYS_IN_MONTH_LEAP = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const DAYS_IN_MONTH_MIN = 28;
const DAYS_IN_MONTH_MAX = 31;
const MONTH_MAX = 12;
const MONTH_MIN = 1;
const DAY_MIN = 1;
const DAYS_IN_WEEK = 7;
const MINUTES_IN_HOUR = 60;
const MINUTE_MAX = 59;
const MINUTES_IN_DAY$1 = 24 * 60;
const HOUR_MAX = 23;
const OFFSET_YEAR = 1e4;
const OFFSET_MONTH = 100;
const OFFSET_HOUR = 100;
const OFFSET_TIME = 1e4;
function getStartOfWeek(timestamp, weekdays, today) {
  const start = copyTimestamp(timestamp);
  findWeekday(start, weekdays[0], prevDay);
  updateFormatted(start);
  if (today) {
    updateRelative(start, today, start.hasTime);
  }
  return start;
}
function getEndOfWeek(timestamp, weekdays, today) {
  const end = copyTimestamp(timestamp);
  findWeekday(end, weekdays[weekdays.length - 1]);
  updateFormatted(end);
  if (today) {
    updateRelative(end, today, end.hasTime);
  }
  return end;
}
function getStartOfMonth(timestamp) {
  const start = copyTimestamp(timestamp);
  start.day = DAY_MIN;
  updateWeekday(start);
  updateFormatted(start);
  return start;
}
function getEndOfMonth(timestamp) {
  const end = copyTimestamp(timestamp);
  end.day = daysInMonth(end.year, end.month);
  updateWeekday(end);
  updateFormatted(end);
  return end;
}
function validateTime(input) {
  return typeof input === "number" && isFinite(input) || !!PARSE_TIME.exec(input) || typeof input === "object" && isFinite(input.hour) && isFinite(input.minute);
}
function parseTime(input) {
  if (typeof input === "number") {
    return input;
  } else if (typeof input === "string") {
    const parts = PARSE_TIME.exec(input);
    if (!parts) {
      return false;
    }
    return parseInt(parts[1]) * 60 + parseInt(parts[3] || 0);
  } else if (typeof input === "object") {
    if (typeof input.hour !== "number" || typeof input.minute !== "number") {
      return false;
    }
    return input.hour * 60 + input.minute;
  } else {
    return false;
  }
}
function validateTimestamp(input) {
  return typeof input === "number" && isFinite(input) || typeof input === "string" && !!PARSE_REGEX.exec(input) || input instanceof Date;
}
function parseTimestamp(input, required = false, now) {
  if (typeof input === "number" && isFinite(input)) {
    input = new Date(input);
  }
  if (input instanceof Date) {
    const date = parseDate(input);
    if (now) {
      updateRelative(date, now, date.hasTime);
    }
    return date;
  }
  if (typeof input !== "string") {
    if (required) {
      throw new Error(`${input} is not a valid timestamp. It must be a Date, number of milliseconds since Epoch, or a string in the format of YYYY-MM-DD or YYYY-MM-DD hh:mm. Zero-padding is optional and seconds are ignored.`);
    }
    return null;
  }
  const parts = PARSE_REGEX.exec(input);
  if (!parts) {
    if (required) {
      throw new Error(`${input} is not a valid timestamp. It must be a Date, number of milliseconds since Epoch, or a string in the format of YYYY-MM-DD or YYYY-MM-DD hh:mm. Zero-padding is optional and seconds are ignored.`);
    }
    return null;
  }
  const timestamp = {
    date: input,
    time: "",
    year: parseInt(parts[1]),
    month: parseInt(parts[2]),
    day: parseInt(parts[4]) || 1,
    hour: parseInt(parts[6]) || 0,
    minute: parseInt(parts[8]) || 0,
    weekday: 0,
    hasDay: !!parts[4],
    hasTime: !!(parts[6] && parts[8]),
    past: false,
    present: false,
    future: false
  };
  updateWeekday(timestamp);
  updateFormatted(timestamp);
  if (now) {
    updateRelative(timestamp, now, timestamp.hasTime);
  }
  return timestamp;
}
function parseDate(date) {
  return updateFormatted({
    date: "",
    time: "",
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    weekday: date.getDay(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    hasDay: true,
    hasTime: true,
    past: false,
    present: true,
    future: false
  });
}
function getDayIdentifier(timestamp) {
  return timestamp.year * OFFSET_YEAR + timestamp.month * OFFSET_MONTH + timestamp.day;
}
function getTimeIdentifier(timestamp) {
  return timestamp.hour * OFFSET_HOUR + timestamp.minute;
}
function getTimestampIdentifier(timestamp) {
  return getDayIdentifier(timestamp) * OFFSET_TIME + getTimeIdentifier(timestamp);
}
function updateRelative(timestamp, now, time = false) {
  let a = getDayIdentifier(now);
  let b = getDayIdentifier(timestamp);
  let present = a === b;
  if (timestamp.hasTime && time && present) {
    a = getTimeIdentifier(now);
    b = getTimeIdentifier(timestamp);
    present = a === b;
  }
  timestamp.past = b < a;
  timestamp.present = present;
  timestamp.future = b > a;
  return timestamp;
}
function isTimedless(input) {
  return input instanceof Date || typeof input === "number" && isFinite(input);
}
function updateHasTime(timestamp, hasTime, now) {
  if (timestamp.hasTime !== hasTime) {
    timestamp.hasTime = hasTime;
    if (!hasTime) {
      timestamp.hour = HOUR_MAX;
      timestamp.minute = MINUTE_MAX;
      timestamp.time = getTime(timestamp);
    }
    if (now) {
      updateRelative(timestamp, now, timestamp.hasTime);
    }
  }
  return timestamp;
}
function updateMinutes(timestamp, minutes, now) {
  timestamp.hasTime = true;
  timestamp.hour = Math.floor(minutes / MINUTES_IN_HOUR);
  timestamp.minute = minutes % MINUTES_IN_HOUR;
  timestamp.time = getTime(timestamp);
  if (now) {
    updateRelative(timestamp, now, true);
  }
  return timestamp;
}
function updateWeekday(timestamp) {
  timestamp.weekday = getWeekday(timestamp);
  return timestamp;
}
function updateFormatted(timestamp) {
  timestamp.time = getTime(timestamp);
  timestamp.date = getDate(timestamp);
  return timestamp;
}
function getWeekday(timestamp) {
  if (timestamp.hasDay) {
    const _ = Math.floor;
    const k = timestamp.day;
    const m = (timestamp.month + 9) % MONTH_MAX + 1;
    const C = _(timestamp.year / 100);
    const Y = timestamp.year % 100 - (timestamp.month <= 2 ? 1 : 0);
    return ((k + _(2.6 * m - 0.2) - 2 * C + Y + _(Y / 4) + _(C / 4)) % 7 + 7) % 7;
  }
  return timestamp.weekday;
}
function daysInMonth(year, month) {
  return isLeapYear(year) ? DAYS_IN_MONTH_LEAP[month] : DAYS_IN_MONTH[month];
}
function copyTimestamp(timestamp) {
  const { date, time, year, month, day, weekday, hour, minute, hasDay, hasTime, past, present, future } = timestamp;
  return { date, time, year, month, day, weekday, hour, minute, hasDay, hasTime, past, present, future };
}
function padNumber(x, length) {
  let padded = String(x);
  while (padded.length < length) {
    padded = "0" + padded;
  }
  return padded;
}
function getDate(timestamp) {
  let str = `${padNumber(timestamp.year, 4)}-${padNumber(timestamp.month, 2)}`;
  if (timestamp.hasDay)
    str += `-${padNumber(timestamp.day, 2)}`;
  return str;
}
function getTime(timestamp) {
  if (!timestamp.hasTime) {
    return "";
  }
  return `${padNumber(timestamp.hour, 2)}:${padNumber(timestamp.minute, 2)}`;
}
function nextDay(timestamp) {
  timestamp.day++;
  timestamp.weekday = (timestamp.weekday + 1) % DAYS_IN_WEEK;
  if (timestamp.day > DAYS_IN_MONTH_MIN && timestamp.day > daysInMonth(timestamp.year, timestamp.month)) {
    timestamp.day = DAY_MIN;
    timestamp.month++;
    if (timestamp.month > MONTH_MAX) {
      timestamp.month = MONTH_MIN;
      timestamp.year++;
    }
  }
  return timestamp;
}
function prevDay(timestamp) {
  timestamp.day--;
  timestamp.weekday = (timestamp.weekday + 6) % DAYS_IN_WEEK;
  if (timestamp.day < DAY_MIN) {
    timestamp.month--;
    if (timestamp.month < MONTH_MIN) {
      timestamp.year--;
      timestamp.month = MONTH_MAX;
    }
    timestamp.day = daysInMonth(timestamp.year, timestamp.month);
  }
  return timestamp;
}
function relativeDays(timestamp, mover = nextDay, days = 1) {
  while (--days >= 0)
    mover(timestamp);
  return timestamp;
}
function diffMinutes(min, max) {
  const Y = (max.year - min.year) * 525600;
  const M = (max.month - min.month) * 43800;
  const D = (max.day - min.day) * 1440;
  const h = (max.hour - min.hour) * 60;
  const m = max.minute - min.minute;
  return Y + M + D + h + m;
}
function findWeekday(timestamp, weekday, mover = nextDay, maxDays = 6) {
  while (timestamp.weekday !== weekday && --maxDays >= 0)
    mover(timestamp);
  return timestamp;
}
function getWeekdaySkips(weekdays) {
  const skips = [1, 1, 1, 1, 1, 1, 1];
  const filled = [0, 0, 0, 0, 0, 0, 0];
  for (let i = 0; i < weekdays.length; i++) {
    filled[weekdays[i]] = 1;
  }
  for (let k = 0; k < DAYS_IN_WEEK; k++) {
    let skip = 1;
    for (let j = 1; j < DAYS_IN_WEEK; j++) {
      const next = (k + j) % DAYS_IN_WEEK;
      if (filled[next]) {
        break;
      }
      skip++;
    }
    skips[k] = filled[k] * skip;
  }
  return skips;
}
function timestampToDate(timestamp) {
  const time = `${padNumber(timestamp.hour, 2)}:${padNumber(timestamp.minute, 2)}`;
  const date = timestamp.date;
  return /* @__PURE__ */ new Date(`${date}T${time}:00+00:00`);
}
function createDayList(start, end, now, weekdaySkips, max = 42, min = 0) {
  const stop = getDayIdentifier(end);
  const days = [];
  let current = copyTimestamp(start);
  let currentIdentifier = 0;
  let stopped = currentIdentifier === stop;
  if (stop < getDayIdentifier(start)) {
    throw new Error("End date is earlier than start date.");
  }
  while ((!stopped || days.length < min) && days.length < max) {
    currentIdentifier = getDayIdentifier(current);
    stopped = stopped || currentIdentifier === stop;
    if (weekdaySkips[current.weekday] === 0) {
      current = nextDay(current);
      continue;
    }
    const day = copyTimestamp(current);
    updateFormatted(day);
    updateRelative(day, now);
    days.push(day);
    current = relativeDays(current, nextDay, weekdaySkips[current.weekday]);
  }
  if (!days.length)
    throw new Error("No dates found using specified start date, end date, and weekdays.");
  return days;
}
function createIntervalList(timestamp, first, minutes, count, now) {
  const intervals = [];
  for (let i = 0; i < count; i++) {
    const mins = first + i * minutes;
    const int2 = copyTimestamp(timestamp);
    intervals.push(updateMinutes(int2, mins, now));
  }
  return intervals;
}
function createNativeLocaleFormatter$1(locale, getOptions) {
  const emptyFormatter = (_t, _s) => "";
  if (typeof Intl === "undefined" || typeof Intl.DateTimeFormat === "undefined") {
    return emptyFormatter;
  }
  return (timestamp, short) => {
    try {
      const intlFormatter = new Intl.DateTimeFormat(locale || void 0, getOptions(timestamp, short));
      return intlFormatter.format(timestampToDate(timestamp));
    } catch (e) {
      return "";
    }
  };
}
const Times = Vue.extend({
  name: "times",
  props: {
    now: {
      type: String,
      validator: validateTimestamp
    }
  },
  data: () => ({
    times: {
      now: parseTimestamp("0000-00-00 00:00", true),
      today: parseTimestamp("0000-00-00", true)
    }
  }),
  computed: {
    parsedNow() {
      return this.now ? parseTimestamp(this.now, true) : null;
    }
  },
  watch: {
    parsedNow: "updateTimes"
  },
  created() {
    this.updateTimes();
    this.setPresent();
  },
  methods: {
    setPresent() {
      this.times.now.present = this.times.today.present = true;
      this.times.now.past = this.times.today.past = false;
      this.times.now.future = this.times.today.future = false;
    },
    updateTimes() {
      const now = this.parsedNow || this.getNow();
      this.updateDay(now, this.times.now);
      this.updateTime(now, this.times.now);
      this.updateDay(now, this.times.today);
    },
    getNow() {
      return parseDate(/* @__PURE__ */ new Date());
    },
    updateDay(now, target) {
      if (now.date !== target.date) {
        target.year = now.year;
        target.month = now.month;
        target.day = now.day;
        target.weekday = now.weekday;
        target.date = now.date;
      }
    },
    updateTime(now, target) {
      if (now.time !== target.time) {
        target.hour = now.hour;
        target.minute = now.minute;
        target.time = now.time;
      }
    }
  }
});
const MILLIS_IN_DAY = 864e5;
function getVisuals(events, minStart = 0) {
  const visuals = events.map((event) => ({
    event,
    columnCount: 0,
    column: 0,
    left: 0,
    width: 100
  }));
  visuals.sort((a, b) => {
    return Math.max(minStart, a.event.startTimestampIdentifier) - Math.max(minStart, b.event.startTimestampIdentifier) || b.event.endTimestampIdentifier - a.event.endTimestampIdentifier;
  });
  return visuals;
}
function hasOverlap(s0, e0, s1, e1, exclude = true) {
  return exclude ? !(s0 >= e1 || e0 <= s1) : !(s0 > e1 || e0 < s1);
}
function setColumnCount(groups) {
  groups.forEach((group) => {
    group.visuals.forEach((groupVisual) => {
      groupVisual.columnCount = groups.length;
    });
  });
}
function getRange(event) {
  return [event.startTimestampIdentifier, event.endTimestampIdentifier];
}
function getDayRange(event) {
  return [event.startIdentifier, event.endIdentifier];
}
function getNormalizedRange(event, dayStart) {
  return [Math.max(dayStart, event.startTimestampIdentifier), Math.min(dayStart + MILLIS_IN_DAY, event.endTimestampIdentifier)];
}
function getOpenGroup(groups, start, end, timed) {
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    let intersected = false;
    if (hasOverlap(start, end, group.start, group.end, timed)) {
      for (let k = 0; k < group.visuals.length; k++) {
        const groupVisual = group.visuals[k];
        const [groupStart, groupEnd] = timed ? getRange(groupVisual.event) : getDayRange(groupVisual.event);
        if (hasOverlap(start, end, groupStart, groupEnd, timed)) {
          intersected = true;
          break;
        }
      }
    }
    if (!intersected) {
      return i;
    }
  }
  return -1;
}
function getOverlapGroupHandler(firstWeekday) {
  const handler = {
    groups: [],
    min: -1,
    max: -1,
    reset: () => {
      handler.groups = [];
      handler.min = handler.max = -1;
    },
    getVisuals: (day, dayEvents, timed, reset = false) => {
      if (day.weekday === firstWeekday || reset) {
        handler.reset();
      }
      const dayStart = getTimestampIdentifier(day);
      const visuals = getVisuals(dayEvents, dayStart);
      visuals.forEach((visual) => {
        const [start, end] = timed ? getRange(visual.event) : getDayRange(visual.event);
        if (handler.groups.length > 0 && !hasOverlap(start, end, handler.min, handler.max, timed)) {
          setColumnCount(handler.groups);
          handler.reset();
        }
        let targetGroup = getOpenGroup(handler.groups, start, end, timed);
        if (targetGroup === -1) {
          targetGroup = handler.groups.length;
          handler.groups.push({ start, end, visuals: [] });
        }
        const target = handler.groups[targetGroup];
        target.visuals.push(visual);
        target.start = Math.min(target.start, start);
        target.end = Math.max(target.end, end);
        visual.column = targetGroup;
        if (handler.min === -1) {
          handler.min = start;
          handler.max = end;
        } else {
          handler.min = Math.min(handler.min, start);
          handler.max = Math.max(handler.max, end);
        }
      });
      setColumnCount(handler.groups);
      if (timed) {
        handler.reset();
      }
      return visuals;
    }
  };
  return handler;
}
const FULL_WIDTH$1 = 100;
const DEFAULT_OFFSET = 5;
const WIDTH_MULTIPLIER = 1.7;
const stack = (events, firstWeekday, overlapThreshold) => {
  const handler = getOverlapGroupHandler(firstWeekday);
  return (day, dayEvents, timed, reset) => {
    if (!timed) {
      return handler.getVisuals(day, dayEvents, timed, reset);
    }
    const dayStart = getTimestampIdentifier(day);
    const visuals = getVisuals(dayEvents, dayStart);
    const groups = getGroups(visuals, dayStart);
    for (const group of groups) {
      const nodes = [];
      for (const visual of group.visuals) {
        const child = getNode(visual, dayStart);
        const index = getNextIndex(child, nodes);
        if (index === false) {
          const parent = getParent(child, nodes);
          if (parent) {
            child.parent = parent;
            child.sibling = hasOverlap(child.start, child.end, parent.start, addTime(parent.start, overlapThreshold));
            child.index = parent.index + 1;
            parent.children.push(child);
          }
        } else {
          const [parent] = getOverlappingRange(child, nodes, index - 1, index - 1);
          const children = getOverlappingRange(child, nodes, index + 1, index + nodes.length, true);
          child.children = children;
          child.index = index;
          if (parent) {
            child.parent = parent;
            child.sibling = hasOverlap(child.start, child.end, parent.start, addTime(parent.start, overlapThreshold));
            parent.children.push(child);
          }
          for (const grand of children) {
            if (grand.parent === parent) {
              grand.parent = child;
            }
            const grandNext = grand.index - child.index <= 1;
            if (grandNext && child.sibling && hasOverlap(child.start, addTime(child.start, overlapThreshold), grand.start, grand.end)) {
              grand.sibling = true;
            }
          }
        }
        nodes.push(child);
      }
      calculateBounds(nodes, overlapThreshold);
    }
    visuals.sort((a, b) => a.left - b.left || a.event.startTimestampIdentifier - b.event.startTimestampIdentifier);
    return visuals;
  };
};
function calculateBounds(nodes, overlapThreshold) {
  for (const node of nodes) {
    const { visual, parent } = node;
    const columns = getMaxChildIndex(node) + 1;
    const spaceLeft = parent ? parent.visual.left : 0;
    const spaceWidth = FULL_WIDTH$1 - spaceLeft;
    const offset = Math.min(DEFAULT_OFFSET, FULL_WIDTH$1 / columns);
    const columnWidthMultiplier = getColumnWidthMultiplier(node, nodes);
    const columnOffset = spaceWidth / (columns - node.index + 1);
    const columnWidth = spaceWidth / (columns - node.index + (node.sibling ? 1 : 0)) * columnWidthMultiplier;
    if (parent) {
      visual.left = node.sibling ? spaceLeft + columnOffset : spaceLeft + offset;
    }
    visual.width = hasFullWidth(node, nodes, overlapThreshold) ? FULL_WIDTH$1 - visual.left : Math.min(FULL_WIDTH$1 - visual.left, columnWidth * WIDTH_MULTIPLIER);
  }
}
function getColumnWidthMultiplier(node, nodes) {
  if (!node.children.length) {
    return 1;
  }
  const maxColumn = node.index + nodes.length;
  const minColumn = node.children.reduce((min, c) => Math.min(min, c.index), maxColumn);
  return minColumn - node.index;
}
function getOverlappingIndices(node, nodes) {
  const indices = [];
  for (const other of nodes) {
    if (hasOverlap(node.start, node.end, other.start, other.end)) {
      indices.push(other.index);
    }
  }
  return indices;
}
function getNextIndex(node, nodes) {
  const indices = getOverlappingIndices(node, nodes);
  indices.sort();
  for (let i = 0; i < indices.length; i++) {
    if (i < indices[i]) {
      return i;
    }
  }
  return false;
}
function getOverlappingRange(node, nodes, indexMin, indexMax, returnFirstColumn = false) {
  const overlapping = [];
  for (const other of nodes) {
    if (other.index >= indexMin && other.index <= indexMax && hasOverlap(node.start, node.end, other.start, other.end)) {
      overlapping.push(other);
    }
  }
  if (returnFirstColumn && overlapping.length > 0) {
    const first = overlapping.reduce((min, n) => Math.min(min, n.index), overlapping[0].index);
    return overlapping.filter((n) => n.index === first);
  }
  return overlapping;
}
function getParent(node, nodes) {
  let parent = null;
  for (const other of nodes) {
    if (hasOverlap(node.start, node.end, other.start, other.end) && (parent === null || other.index > parent.index)) {
      parent = other;
    }
  }
  return parent;
}
function hasFullWidth(node, nodes, overlapThreshold) {
  for (const other of nodes) {
    if (other !== node && other.index > node.index && hasOverlap(node.start, addTime(node.start, overlapThreshold), other.start, other.end)) {
      return false;
    }
  }
  return true;
}
function getGroups(visuals, dayStart) {
  const groups = [];
  for (const visual of visuals) {
    const [start, end] = getNormalizedRange(visual.event, dayStart);
    let added = false;
    for (const group of groups) {
      if (hasOverlap(start, end, group.start, group.end)) {
        group.visuals.push(visual);
        group.end = Math.max(group.end, end);
        added = true;
        break;
      }
    }
    if (!added) {
      groups.push({ start, end, visuals: [visual] });
    }
  }
  return groups;
}
function getNode(visual, dayStart) {
  const [start, end] = getNormalizedRange(visual.event, dayStart);
  return {
    parent: null,
    sibling: true,
    index: 0,
    visual,
    start,
    end,
    children: []
  };
}
function getMaxChildIndex(node) {
  let max = node.index;
  for (const child of node.children) {
    const childMax = getMaxChildIndex(child);
    if (childMax > max) {
      max = childMax;
    }
  }
  return max;
}
function addTime(identifier, minutes) {
  const removeMinutes = identifier % 100;
  const totalMinutes = removeMinutes + minutes;
  const addHours = Math.floor(totalMinutes / 60);
  const addMinutes = totalMinutes % 60;
  return identifier - removeMinutes + addHours * 100 + addMinutes;
}
const FULL_WIDTH = 100;
const column = (events, firstWeekday, overlapThreshold) => {
  const handler = getOverlapGroupHandler(firstWeekday);
  return (day, dayEvents, timed, reset) => {
    const visuals = handler.getVisuals(day, dayEvents, timed, reset);
    if (timed) {
      visuals.forEach((visual) => {
        visual.left = visual.column * FULL_WIDTH / visual.columnCount;
        visual.width = FULL_WIDTH / visual.columnCount;
      });
    }
    return visuals;
  };
};
const CalendarEventOverlapModes = {
  stack,
  column
};
const props = {
  base: {
    start: {
      type: [String, Number, Date],
      validate: validateTimestamp,
      default: () => parseDate(/* @__PURE__ */ new Date()).date
    },
    end: {
      type: [String, Number, Date],
      validate: validateTimestamp
    },
    weekdays: {
      type: [Array, String],
      default: () => [0, 1, 2, 3, 4, 5, 6],
      validate: validateWeekdays
    },
    hideHeader: {
      type: Boolean
    },
    shortWeekdays: {
      type: Boolean,
      default: true
    },
    weekdayFormat: {
      type: Function,
      default: null
    },
    dayFormat: {
      type: Function,
      default: null
    }
  },
  intervals: {
    maxDays: {
      type: Number,
      default: 7
    },
    shortIntervals: {
      type: Boolean,
      default: true
    },
    intervalHeight: {
      type: [Number, String],
      default: 48,
      validate: validateNumber
    },
    intervalWidth: {
      type: [Number, String],
      default: 60,
      validate: validateNumber
    },
    intervalMinutes: {
      type: [Number, String],
      default: 60,
      validate: validateNumber
    },
    firstInterval: {
      type: [Number, String],
      default: 0,
      validate: validateNumber
    },
    firstTime: {
      type: [Number, String, Object],
      validate: validateTime
    },
    intervalCount: {
      type: [Number, String],
      default: 24,
      validate: validateNumber
    },
    intervalFormat: {
      type: Function,
      default: null
    },
    intervalStyle: {
      type: Function,
      default: null
    },
    showIntervalLabel: {
      type: Function,
      default: null
    }
  },
  weeks: {
    localeFirstDayOfYear: {
      type: [String, Number],
      default: 0
    },
    minWeeks: {
      validate: validateNumber,
      default: 1
    },
    shortMonths: {
      type: Boolean,
      default: true
    },
    showMonthOnFirst: {
      type: Boolean,
      default: true
    },
    showWeek: Boolean,
    monthFormat: {
      type: Function,
      default: null
    }
  },
  calendar: {
    type: {
      type: String,
      default: "month"
    },
    value: {
      type: [String, Number, Date],
      validate: validateTimestamp
    }
  },
  category: {
    categories: {
      type: [Array, String],
      default: ""
    },
    categoryText: {
      type: [String, Function]
    },
    categoryHideDynamic: {
      type: Boolean
    },
    categoryShowAll: {
      type: Boolean
    },
    categoryForInvalid: {
      type: String,
      default: ""
    },
    categoryDays: {
      type: [Number, String],
      default: 1,
      validate: (x) => isFinite(parseInt(x)) && parseInt(x) > 0
    }
  },
  events: {
    events: {
      type: Array,
      default: () => []
    },
    eventStart: {
      type: String,
      default: "start"
    },
    eventEnd: {
      type: String,
      default: "end"
    },
    eventTimed: {
      type: [String, Function],
      default: "timed"
    },
    eventCategory: {
      type: [String, Function],
      default: "category"
    },
    eventHeight: {
      type: Number,
      default: 20
    },
    eventColor: {
      type: [String, Function],
      default: "primary"
    },
    eventTextColor: {
      type: [String, Function],
      default: "white"
    },
    eventName: {
      type: [String, Function],
      default: "name"
    },
    eventOverlapThreshold: {
      type: [String, Number],
      default: 60
    },
    eventOverlapMode: {
      type: [String, Function],
      default: "stack",
      validate: (mode) => mode in CalendarEventOverlapModes || typeof mode === "function"
    },
    eventMore: {
      type: Boolean,
      default: true
    },
    eventMoreText: {
      type: String,
      default: "$vuetify.calendar.moreEvents"
    },
    eventRipple: {
      type: [Boolean, Object],
      default: null
    },
    eventMarginBottom: {
      type: Number,
      default: 1
    }
  }
};
function validateNumber(input) {
  return isFinite(parseInt(input));
}
function validateWeekdays(input) {
  if (typeof input === "string") {
    input = input.split(",");
  }
  if (Array.isArray(input)) {
    const ints = input.map((x) => parseInt(x));
    if (ints.length > DAYS_IN_WEEK || ints.length === 0) {
      return false;
    }
    const visited = {};
    let wrapped = false;
    for (let i = 0; i < ints.length; i++) {
      const x = ints[i];
      if (!isFinite(x) || x < 0 || x >= DAYS_IN_WEEK) {
        return false;
      }
      if (i > 0) {
        const d = x - ints[i - 1];
        if (d < 0) {
          if (wrapped) {
            return false;
          }
          wrapped = true;
        } else if (d === 0) {
          return false;
        }
      }
      if (visited[x]) {
        return false;
      }
      visited[x] = true;
    }
    return true;
  }
  return false;
}
const CalendarBase = mixins(
  Colorable,
  Localable,
  Mouse,
  Themeable$1,
  Times
  /* @vue/component */
).extend({
  name: "calendar-base",
  directives: {
    Resize: Resize$1
  },
  props: props.base,
  computed: {
    parsedWeekdays() {
      return Array.isArray(this.weekdays) ? this.weekdays : (this.weekdays || "").split(",").map((x) => parseInt(x, 10));
    },
    weekdaySkips() {
      return getWeekdaySkips(this.parsedWeekdays);
    },
    weekdaySkipsReverse() {
      const reversed = this.weekdaySkips.slice();
      reversed.reverse();
      return reversed;
    },
    parsedStart() {
      return parseTimestamp(this.start, true);
    },
    parsedEnd() {
      const start = this.parsedStart;
      const end = this.end ? parseTimestamp(this.end) || start : start;
      return getTimestampIdentifier(end) < getTimestampIdentifier(start) ? start : end;
    },
    days() {
      return createDayList(
        this.parsedStart,
        this.parsedEnd,
        this.times.today,
        this.weekdaySkips
      );
    },
    dayFormatter() {
      if (this.dayFormat) {
        return this.dayFormat;
      }
      const options = { timeZone: "UTC", day: "numeric" };
      return createNativeLocaleFormatter$1(
        this.currentLocale,
        (_tms, _short) => options
      );
    },
    weekdayFormatter() {
      if (this.weekdayFormat) {
        return this.weekdayFormat;
      }
      const longOptions = { timeZone: "UTC", weekday: "long" };
      const shortOptions = { timeZone: "UTC", weekday: "short" };
      return createNativeLocaleFormatter$1(
        this.currentLocale,
        (_tms, short) => short ? shortOptions : longOptions
      );
    }
  },
  methods: {
    getRelativeClasses(timestamp, outside = false) {
      return {
        "v-present": timestamp.present,
        "v-past": timestamp.past,
        "v-future": timestamp.future,
        "v-outside": outside
      };
    },
    getStartOfWeek(timestamp) {
      return getStartOfWeek(timestamp, this.parsedWeekdays, this.times.today);
    },
    getEndOfWeek(timestamp) {
      return getEndOfWeek(timestamp, this.parsedWeekdays, this.times.today);
    },
    getFormatter(options) {
      return createNativeLocaleFormatter$1(
        this.locale,
        (_tms, _short) => options
      );
    }
  }
});
function parseEvent(input, index, startProperty, endProperty, timed = false, category = false) {
  const startInput = input[startProperty];
  const endInput = input[endProperty];
  const startParsed = parseTimestamp(startInput, true);
  const endParsed = endInput ? parseTimestamp(endInput, true) : startParsed;
  const start = isTimedless(startInput) ? updateHasTime(startParsed, timed) : startParsed;
  const end = isTimedless(endInput) ? updateHasTime(endParsed, timed) : endParsed;
  const startIdentifier = getDayIdentifier(start);
  const startTimestampIdentifier = getTimestampIdentifier(start);
  const endIdentifier = getDayIdentifier(end);
  const endOffset = start.hasTime ? 0 : 2359;
  const endTimestampIdentifier = getTimestampIdentifier(end) + endOffset;
  const allDay = !start.hasTime;
  return { input, start, startIdentifier, startTimestampIdentifier, end, endIdentifier, endTimestampIdentifier, allDay, index, category };
}
function isEventOn(event, dayIdentifier) {
  return dayIdentifier >= event.startIdentifier && dayIdentifier <= event.endIdentifier;
}
function isEventHiddenOn(event, day) {
  return event.end.time === "00:00" && event.end.date === day.date && event.start.date !== day.date;
}
function isEventStart(event, day, dayIdentifier, firstWeekday) {
  return dayIdentifier === event.startIdentifier || firstWeekday === day.weekday && isEventOn(event, dayIdentifier);
}
function isEventOverlapping(event, startIdentifier, endIdentifier) {
  return startIdentifier <= event.endIdentifier && endIdentifier >= event.startIdentifier;
}
const WIDTH_FULL = 100;
const WIDTH_START = 95;
const MINUTES_IN_DAY = 1440;
const CalendarWithEvents = CalendarBase.extend({
  name: "calendar-with-events",
  directives: {
    ripple
  },
  props: {
    ...props.events,
    ...props.calendar,
    ...props.category
  },
  computed: {
    noEvents() {
      return this.events.length === 0;
    },
    parsedEvents() {
      return this.events.map(this.parseEvent);
    },
    parsedEventOverlapThreshold() {
      return parseInt(this.eventOverlapThreshold);
    },
    eventTimedFunction() {
      return typeof this.eventTimed === "function" ? this.eventTimed : (event) => !!event[this.eventTimed];
    },
    eventCategoryFunction() {
      return typeof this.eventCategory === "function" ? this.eventCategory : (event) => event[this.eventCategory];
    },
    eventTextColorFunction() {
      return typeof this.eventTextColor === "function" ? this.eventTextColor : () => this.eventTextColor;
    },
    eventNameFunction() {
      return typeof this.eventName === "function" ? this.eventName : (event, timedEvent) => event.input[this.eventName] || "";
    },
    eventModeFunction() {
      return typeof this.eventOverlapMode === "function" ? this.eventOverlapMode : CalendarEventOverlapModes[this.eventOverlapMode];
    },
    eventWeekdays() {
      return this.parsedWeekdays;
    },
    categoryMode() {
      return this.type === "category";
    }
  },
  methods: {
    eventColorFunction(e) {
      return typeof this.eventColor === "function" ? this.eventColor(e) : e.color || this.eventColor;
    },
    parseEvent(input, index = 0) {
      return parseEvent(
        input,
        index,
        this.eventStart,
        this.eventEnd,
        this.eventTimedFunction(input),
        this.categoryMode ? this.eventCategoryFunction(input) : false
      );
    },
    formatTime(withTime, ampm) {
      const formatter = this.getFormatter({
        timeZone: "UTC",
        hour: "numeric",
        minute: withTime.minute > 0 ? "numeric" : void 0
      });
      return formatter(withTime, true);
    },
    updateEventVisibility() {
      if (this.noEvents || !this.eventMore) {
        return;
      }
      const eventHeight = this.eventHeight;
      const eventsMap = this.getEventsMap();
      for (const date in eventsMap) {
        const { parent, events, more } = eventsMap[date];
        if (!more) {
          break;
        }
        const parentBounds = parent.getBoundingClientRect();
        const last = events.length - 1;
        const eventsSorted = events.map((event) => ({
          event,
          bottom: event.getBoundingClientRect().bottom
        })).sort((a, b) => a.bottom - b.bottom);
        let hidden = 0;
        for (let i = 0; i <= last; i++) {
          const bottom = eventsSorted[i].bottom;
          const hide = i === last ? bottom > parentBounds.bottom : bottom + eventHeight > parentBounds.bottom;
          if (hide) {
            eventsSorted[i].event.style.display = "none";
            hidden++;
          }
        }
        if (hidden) {
          more.style.display = "";
          more.innerHTML = this.$vuetify.lang.t(this.eventMoreText, hidden);
        } else {
          more.style.display = "none";
        }
      }
    },
    getEventsMap() {
      const eventsMap = {};
      const elements = this.$refs.events;
      if (!elements || !elements.forEach) {
        return eventsMap;
      }
      elements.forEach((el) => {
        const date = el.getAttribute("data-date");
        if (el.parentElement && date) {
          if (!(date in eventsMap)) {
            eventsMap[date] = {
              parent: el.parentElement,
              more: null,
              events: []
            };
          }
          if (el.getAttribute("data-more")) {
            eventsMap[date].more = el;
          } else {
            eventsMap[date].events.push(el);
            el.style.display = "";
          }
        }
      });
      return eventsMap;
    },
    genDayEvent({ event }, day) {
      const eventHeight = this.eventHeight;
      const eventMarginBottom = this.eventMarginBottom;
      const dayIdentifier = getDayIdentifier(day);
      const week = day.week;
      const start = dayIdentifier === event.startIdentifier;
      let end = dayIdentifier === event.endIdentifier;
      let width = WIDTH_START;
      if (!this.categoryMode) {
        for (let i = day.index + 1; i < week.length; i++) {
          const weekdayIdentifier = getDayIdentifier(week[i]);
          if (event.endIdentifier >= weekdayIdentifier) {
            width += WIDTH_FULL;
            end = end || weekdayIdentifier === event.endIdentifier;
          } else {
            end = true;
            break;
          }
        }
      }
      const scope = { eventParsed: event, day, start, end, timed: false };
      return this.genEvent(event, scope, false, {
        staticClass: "v-event",
        class: {
          "v-event-start": start,
          "v-event-end": end
        },
        style: {
          height: `${eventHeight}px`,
          width: `${width}%`,
          "margin-bottom": `${eventMarginBottom}px`
        },
        attrs: {
          "data-date": day.date
        },
        key: event.index,
        ref: "events",
        refInFor: true
      });
    },
    genTimedEvent({ event, left, width }, day) {
      if (day.timeDelta(event.end) < 0 || day.timeDelta(event.start) >= 1 || isEventHiddenOn(event, day)) {
        return false;
      }
      const dayIdentifier = getDayIdentifier(day);
      const start = event.startIdentifier >= dayIdentifier;
      const end = event.endIdentifier > dayIdentifier;
      const top = start ? day.timeToY(event.start) : 0;
      const bottom = end ? day.timeToY(MINUTES_IN_DAY) : day.timeToY(event.end);
      const height = Math.max(this.eventHeight, bottom - top);
      const scope = { eventParsed: event, day, start, end, timed: true };
      return this.genEvent(event, scope, true, {
        staticClass: "v-event-timed",
        style: {
          top: `${top}px`,
          height: `${height}px`,
          left: `${left}%`,
          width: `${width}%`
        }
      });
    },
    genEvent(event, scopeInput, timedEvent, data) {
      const slot = this.$scopedSlots.event;
      const text = this.eventTextColorFunction(event.input);
      const background = this.eventColorFunction(event.input);
      const overlapsNoon = event.start.hour < 12 && event.end.hour >= 12;
      const singline = diffMinutes(event.start, event.end) <= this.parsedEventOverlapThreshold;
      const formatTime = this.formatTime;
      const timeSummary = () => formatTime(event.start, overlapsNoon) + " - " + formatTime(event.end, true);
      const eventSummary = () => {
        const name = this.eventNameFunction(event, timedEvent);
        if (event.start.hasTime) {
          if (timedEvent) {
            const time = timeSummary();
            const delimiter = singline ? ", " : this.$createElement("br");
            return this.$createElement("span", { staticClass: "v-event-summary" }, [
              this.$createElement("strong", [name]),
              delimiter,
              time
            ]);
          } else {
            const time = formatTime(event.start, true);
            return this.$createElement("span", { staticClass: "v-event-summary" }, [
              this.$createElement("strong", [time]),
              " ",
              name
            ]);
          }
        }
        return this.$createElement("span", { staticClass: "v-event-summary" }, [name]);
      };
      const scope = {
        ...scopeInput,
        event: event.input,
        outside: scopeInput.day.outside,
        singline,
        overlapsNoon,
        formatTime,
        timeSummary,
        eventSummary
      };
      return this.$createElement(
        "div",
        this.setTextColor(
          text,
          this.setBackgroundColor(background, {
            on: this.getDefaultMouseEventHandlers(":event", (nativeEvent) => ({ ...scope, nativeEvent })),
            directives: [{
              name: "ripple",
              value: this.eventRipple ?? true
            }],
            ...data
          })
        ),
        slot ? slot(scope) : [this.genName(eventSummary)]
      );
    },
    genName(eventSummary) {
      return this.$createElement("div", {
        staticClass: "pl-1"
      }, [eventSummary()]);
    },
    genPlaceholder(day) {
      const height = this.eventHeight + this.eventMarginBottom;
      return this.$createElement("div", {
        style: {
          height: `${height}px`
        },
        attrs: {
          "data-date": day.date
        },
        ref: "events",
        refInFor: true
      });
    },
    genMore(day) {
      const eventHeight = this.eventHeight;
      const eventMarginBottom = this.eventMarginBottom;
      return this.$createElement("div", {
        staticClass: "v-event-more pl-1",
        class: {
          "v-outside": day.outside
        },
        attrs: {
          "data-date": day.date,
          "data-more": 1
        },
        directives: [{
          name: "ripple",
          value: this.eventRipple ?? true
        }],
        on: this.getDefaultMouseEventHandlers(":more", (nativeEvent) => {
          return { nativeEvent, ...day };
        }),
        style: {
          display: "none",
          height: `${eventHeight}px`,
          "margin-bottom": `${eventMarginBottom}px`
        },
        ref: "events",
        refInFor: true
      });
    },
    getVisibleEvents() {
      const start = getDayIdentifier(this.days[0]);
      const end = getDayIdentifier(this.days[this.days.length - 1]);
      return this.parsedEvents.filter(
        (event) => isEventOverlapping(event, start, end)
      );
    },
    isEventForCategory(event, category) {
      return !this.categoryMode || typeof category === "object" && category.categoryName && category.categoryName === event.category || typeof event.category === "string" && category === event.category || typeof event.category !== "string" && category === null;
    },
    getEventsForDay(day) {
      const identifier = getDayIdentifier(day);
      const firstWeekday = this.eventWeekdays[0];
      return this.parsedEvents.filter(
        (event) => isEventStart(event, day, identifier, firstWeekday)
      );
    },
    getEventsForDayAll(day) {
      const identifier = getDayIdentifier(day);
      const firstWeekday = this.eventWeekdays[0];
      return this.parsedEvents.filter(
        (event) => event.allDay && (this.categoryMode ? isEventOn(event, identifier) : isEventStart(event, day, identifier, firstWeekday)) && this.isEventForCategory(event, day.category)
      );
    },
    getEventsForDayTimed(day) {
      const identifier = getDayIdentifier(day);
      return this.parsedEvents.filter(
        (event) => !event.allDay && isEventOn(event, identifier) && this.isEventForCategory(event, day.category)
      );
    },
    getScopedSlots() {
      if (this.noEvents) {
        return { ...this.$scopedSlots };
      }
      const mode = this.eventModeFunction(
        this.parsedEvents,
        this.eventWeekdays[0],
        this.parsedEventOverlapThreshold
      );
      const isNode = (input) => !!input;
      const getSlotChildren = (day, getter, mapper, timed) => {
        const events = getter(day);
        const visuals = mode(day, events, timed, this.categoryMode);
        if (timed) {
          return visuals.map((visual) => mapper(visual, day)).filter(isNode);
        }
        const children = [];
        visuals.forEach((visual, index) => {
          while (children.length < visual.column) {
            children.push(this.genPlaceholder(day));
          }
          const mapped = mapper(visual, day);
          if (mapped) {
            children.push(mapped);
          }
        });
        return children;
      };
      const slots = this.$scopedSlots;
      const slotDay = slots.day;
      const slotDayHeader = slots["day-header"];
      const slotDayBody = slots["day-body"];
      return {
        ...slots,
        day: (day) => {
          let children = getSlotChildren(day, this.getEventsForDay, this.genDayEvent, false);
          if (children && children.length > 0 && this.eventMore) {
            children.push(this.genMore(day));
          }
          if (slotDay) {
            const slot = slotDay(day);
            if (slot) {
              children = children ? children.concat(slot) : slot;
            }
          }
          return children;
        },
        "day-header": (day) => {
          let children = getSlotChildren(day, this.getEventsForDayAll, this.genDayEvent, false);
          if (slotDayHeader) {
            const slot = slotDayHeader(day);
            if (slot) {
              children = children ? children.concat(slot) : slot;
            }
          }
          return children;
        },
        "day-body": (day) => {
          const events = getSlotChildren(day, this.getEventsForDayTimed, this.genTimedEvent, true);
          let children = [
            this.$createElement("div", {
              staticClass: "v-event-timed-container"
            }, events)
          ];
          if (slotDayBody) {
            const slot = slotDayBody(day);
            if (slot) {
              children = children.concat(slot);
            }
          }
          return children;
        }
      };
    }
  }
});
const VCalendarWeekly = CalendarBase.extend({
  name: "v-calendar-weekly",
  props: props.weeks,
  computed: {
    staticClass() {
      return "v-calendar-weekly";
    },
    classes() {
      return this.themeClasses;
    },
    parsedMinWeeks() {
      return parseInt(this.minWeeks);
    },
    days() {
      const minDays = this.parsedMinWeeks * this.parsedWeekdays.length;
      const start = this.getStartOfWeek(this.parsedStart);
      const end = this.getEndOfWeek(this.parsedEnd);
      return createDayList(
        start,
        end,
        this.times.today,
        this.weekdaySkips,
        Number.MAX_SAFE_INTEGER,
        minDays
      );
    },
    todayWeek() {
      const today = this.times.today;
      const start = this.getStartOfWeek(today);
      const end = this.getEndOfWeek(today);
      return createDayList(
        start,
        end,
        today,
        this.weekdaySkips,
        this.parsedWeekdays.length,
        this.parsedWeekdays.length
      );
    },
    monthFormatter() {
      if (this.monthFormat) {
        return this.monthFormat;
      }
      const longOptions = { timeZone: "UTC", month: "long" };
      const shortOptions = { timeZone: "UTC", month: "short" };
      return createNativeLocaleFormatter$1(
        this.currentLocale,
        (_tms, short) => short ? shortOptions : longOptions
      );
    }
  },
  methods: {
    isOutside(day) {
      const dayIdentifier = getDayIdentifier(day);
      return dayIdentifier < getDayIdentifier(this.parsedStart) || dayIdentifier > getDayIdentifier(this.parsedEnd);
    },
    genHead() {
      return this.$createElement("div", {
        staticClass: "v-calendar-weekly__head",
        attrs: {
          role: "row"
        }
      }, this.genHeadDays());
    },
    genHeadDays() {
      const header2 = this.todayWeek.map(this.genHeadDay);
      if (this.showWeek) {
        header2.unshift(this.$createElement("div", {
          staticClass: "v-calendar-weekly__head-weeknumber"
        }));
      }
      return header2;
    },
    genHeadDay(day, index) {
      const outside = this.isOutside(this.days[index]);
      const color = day.present ? this.color : void 0;
      return this.$createElement("div", this.setTextColor(color, {
        key: day.date,
        staticClass: "v-calendar-weekly__head-weekday",
        class: this.getRelativeClasses(day, outside),
        attrs: {
          role: "columnheader"
        }
      }), this.weekdayFormatter(day, this.shortWeekdays));
    },
    genWeeks() {
      const days = this.days;
      const weekDays = this.parsedWeekdays.length;
      const weeks = [];
      for (let i = 0; i < days.length; i += weekDays) {
        weeks.push(this.genWeek(days.slice(i, i + weekDays), this.getWeekNumber(days[i])));
      }
      return weeks;
    },
    genWeek(week, weekNumber2) {
      const weekNodes = week.map((day, index) => this.genDay(day, index, week));
      if (this.showWeek) {
        weekNodes.unshift(this.genWeekNumber(weekNumber2));
      }
      return this.$createElement("div", {
        key: week[0].date,
        staticClass: "v-calendar-weekly__week",
        attrs: {
          role: "row"
        }
      }, weekNodes);
    },
    getWeekNumber(determineDay) {
      return weekNumber(
        determineDay.year,
        determineDay.month - 1,
        determineDay.day,
        this.parsedWeekdays[0],
        parseInt(this.localeFirstDayOfYear)
      );
    },
    genWeekNumber(weekNumber2) {
      return this.$createElement("div", {
        staticClass: "v-calendar-weekly__weeknumber"
      }, [
        this.$createElement("small", String(weekNumber2))
      ]);
    },
    genDay(day, index, week) {
      const outside = this.isOutside(day);
      return this.$createElement("div", {
        key: day.date,
        staticClass: "v-calendar-weekly__day",
        class: this.getRelativeClasses(day, outside),
        attrs: {
          role: "cell"
        },
        on: this.getDefaultMouseEventHandlers(":day", (nativeEvent) => {
          return { nativeEvent, ...day };
        })
      }, [
        this.genDayLabel(day),
        ...getSlot(this, "day", () => ({ outside, index, week, ...day })) || []
      ]);
    },
    genDayLabel(day) {
      return this.$createElement("div", {
        staticClass: "v-calendar-weekly__day-label"
      }, getSlot(this, "day-label", day) || [this.genDayLabelButton(day)]);
    },
    genDayLabelButton(day) {
      const color = day.present ? this.color : "transparent";
      const hasMonth = day.day === 1 && this.showMonthOnFirst;
      return this.$createElement(
        VBtn,
        {
          props: {
            color,
            fab: true,
            depressed: true,
            small: true
          },
          on: this.getMouseEventHandlers({
            "click:date": { event: "click", stop: true },
            "contextmenu:date": { event: "contextmenu", stop: true, prevent: true, result: false }
          }, (nativeEvent) => ({ nativeEvent, ...day }))
        },
        hasMonth ? this.monthFormatter(day, this.shortMonths) + " " + this.dayFormatter(day, false) : this.dayFormatter(day, false)
      );
    },
    genDayMonth(day) {
      const color = day.present ? this.color : void 0;
      return this.$createElement("div", this.setTextColor(color, {
        staticClass: "v-calendar-weekly__day-month"
      }), getSlot(this, "day-month", day) || this.monthFormatter(day, this.shortMonths));
    }
  },
  render(h) {
    return h("div", {
      staticClass: this.staticClass,
      class: this.classes,
      on: {
        dragstart: (e) => {
          e.preventDefault();
        }
      }
    }, [
      !this.hideHeader ? this.genHead() : "",
      ...this.genWeeks()
    ]);
  }
});
const VCalendarMonthly = VCalendarWeekly.extend({
  name: "v-calendar-monthly",
  computed: {
    staticClass() {
      return "v-calendar-monthly v-calendar-weekly";
    },
    parsedStart() {
      return getStartOfMonth(parseTimestamp(this.start, true));
    },
    parsedEnd() {
      return getEndOfMonth(parseTimestamp(this.end, true));
    }
  }
});
const CalendarWithIntervals = CalendarBase.extend({
  name: "calendar-with-intervals",
  props: props.intervals,
  computed: {
    parsedFirstInterval() {
      return parseInt(this.firstInterval);
    },
    parsedIntervalMinutes() {
      return parseInt(this.intervalMinutes);
    },
    parsedIntervalCount() {
      return parseInt(this.intervalCount);
    },
    parsedIntervalHeight() {
      return parseFloat(this.intervalHeight);
    },
    parsedFirstTime() {
      return parseTime(this.firstTime);
    },
    firstMinute() {
      const time = this.parsedFirstTime;
      return time !== false && time >= 0 && time <= MINUTES_IN_DAY$1 ? time : this.parsedFirstInterval * this.parsedIntervalMinutes;
    },
    bodyHeight() {
      return this.parsedIntervalCount * this.parsedIntervalHeight;
    },
    days() {
      return createDayList(
        this.parsedStart,
        this.parsedEnd,
        this.times.today,
        this.weekdaySkips,
        this.maxDays
      );
    },
    intervals() {
      const days = this.days;
      const first = this.firstMinute;
      const minutes = this.parsedIntervalMinutes;
      const count = this.parsedIntervalCount;
      const now = this.times.now;
      return days.map((d) => createIntervalList(d, first, minutes, count, now));
    },
    intervalFormatter() {
      if (this.intervalFormat) {
        return this.intervalFormat;
      }
      const longOptions = { timeZone: "UTC", hour: "2-digit", minute: "2-digit" };
      const shortOptions = { timeZone: "UTC", hour: "numeric", minute: "2-digit" };
      const shortHourOptions = { timeZone: "UTC", hour: "numeric" };
      return createNativeLocaleFormatter$1(
        this.currentLocale,
        (tms, short) => short ? tms.minute === 0 ? shortHourOptions : shortOptions : longOptions
      );
    }
  },
  methods: {
    showIntervalLabelDefault(interval) {
      const first = this.intervals[0][0];
      const isFirst = first.hour === interval.hour && first.minute === interval.minute;
      return !isFirst;
    },
    intervalStyleDefault(_interval) {
      return void 0;
    },
    getTimestampAtEvent(e, day) {
      const timestamp = copyTimestamp(day);
      const bounds = e.currentTarget.getBoundingClientRect();
      const baseMinutes = this.firstMinute;
      const touchEvent = e;
      const mouseEvent = e;
      const touches = touchEvent.changedTouches || touchEvent.touches;
      const clientY = touches && touches[0] ? touches[0].clientY : mouseEvent.clientY;
      const addIntervals = (clientY - bounds.top) / this.parsedIntervalHeight;
      const addMinutes = Math.floor(addIntervals * this.parsedIntervalMinutes);
      const minutes = baseMinutes + addMinutes;
      return updateMinutes(timestamp, minutes, this.times.now);
    },
    getSlotScope(timestamp) {
      const scope = copyTimestamp(timestamp);
      scope.timeToY = this.timeToY;
      scope.timeDelta = this.timeDelta;
      scope.minutesToPixels = this.minutesToPixels;
      scope.week = this.days;
      return scope;
    },
    scrollToTime(time) {
      const y = this.timeToY(time);
      const pane = this.$refs.scrollArea;
      if (y === false || !pane) {
        return false;
      }
      pane.scrollTop = y;
      return true;
    },
    minutesToPixels(minutes) {
      return minutes / this.parsedIntervalMinutes * this.parsedIntervalHeight;
    },
    timeToY(time, clamp2 = true) {
      let y = this.timeDelta(time);
      if (y !== false) {
        y *= this.bodyHeight;
        if (clamp2) {
          if (y < 0) {
            y = 0;
          }
          if (y > this.bodyHeight) {
            y = this.bodyHeight;
          }
        }
      }
      return y;
    },
    timeDelta(time) {
      const minutes = parseTime(time);
      if (minutes === false) {
        return false;
      }
      const min = this.firstMinute;
      const gap = this.parsedIntervalCount * this.parsedIntervalMinutes;
      return (minutes - min) / gap;
    }
  }
});
const VCalendarDaily = CalendarWithIntervals.extend({
  name: "v-calendar-daily",
  directives: { Resize: Resize$1 },
  data: () => ({
    scrollPush: 0
  }),
  computed: {
    classes() {
      return {
        "v-calendar-daily": true,
        ...this.themeClasses
      };
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(this.onResize);
    },
    onResize() {
      this.scrollPush = this.getScrollPush();
    },
    getScrollPush() {
      const area = this.$refs.scrollArea;
      const pane = this.$refs.pane;
      return area && pane ? area.offsetWidth - pane.offsetWidth : 0;
    },
    genHead() {
      return this.$createElement("div", {
        staticClass: "v-calendar-daily__head",
        style: {
          marginRight: this.scrollPush + "px"
        }
      }, [
        this.genHeadIntervals(),
        ...this.genHeadDays()
      ]);
    },
    genHeadIntervals() {
      const width = convertToUnit(this.intervalWidth);
      return this.$createElement("div", {
        staticClass: "v-calendar-daily__intervals-head",
        style: {
          width
        }
      }, getSlot(this, "interval-header"));
    },
    genHeadDays() {
      return this.days.map(this.genHeadDay);
    },
    genHeadDay(day, index) {
      return this.$createElement("div", {
        key: day.date,
        staticClass: "v-calendar-daily_head-day",
        class: this.getRelativeClasses(day),
        on: this.getDefaultMouseEventHandlers(":day", (nativeEvent) => {
          return { nativeEvent, ...this.getSlotScope(day) };
        })
      }, [
        this.genHeadWeekday(day),
        this.genHeadDayLabel(day),
        ...this.genDayHeader(day, index)
      ]);
    },
    genDayHeader(day, index) {
      return getSlot(this, "day-header", () => ({
        week: this.days,
        ...day,
        index
      })) || [];
    },
    genHeadWeekday(day) {
      const color = day.present ? this.color : void 0;
      return this.$createElement("div", this.setTextColor(color, {
        staticClass: "v-calendar-daily_head-weekday"
      }), this.weekdayFormatter(day, this.shortWeekdays));
    },
    genHeadDayLabel(day) {
      return this.$createElement("div", {
        staticClass: "v-calendar-daily_head-day-label"
      }, getSlot(this, "day-label-header", day) || [this.genHeadDayButton(day)]);
    },
    genHeadDayButton(day) {
      const color = day.present ? this.color : "transparent";
      return this.$createElement(VBtn, {
        props: {
          color,
          fab: true,
          depressed: true
        },
        on: this.getMouseEventHandlers({
          "click:date": { event: "click", stop: true },
          "contextmenu:date": { event: "contextmenu", stop: true, prevent: true, result: false }
        }, (nativeEvent) => {
          return { nativeEvent, ...day };
        })
      }, this.dayFormatter(day, false));
    },
    genBody() {
      return this.$createElement("div", {
        staticClass: "v-calendar-daily__body"
      }, [
        this.genScrollArea()
      ]);
    },
    genScrollArea() {
      return this.$createElement("div", {
        ref: "scrollArea",
        staticClass: "v-calendar-daily__scroll-area"
      }, [
        this.genPane()
      ]);
    },
    genPane() {
      return this.$createElement("div", {
        ref: "pane",
        staticClass: "v-calendar-daily__pane",
        style: {
          height: convertToUnit(this.bodyHeight)
        }
      }, [
        this.genDayContainer()
      ]);
    },
    genDayContainer() {
      return this.$createElement("div", {
        staticClass: "v-calendar-daily__day-container"
      }, [
        this.genBodyIntervals(),
        ...this.genDays()
      ]);
    },
    genDays() {
      return this.days.map(this.genDay);
    },
    genDay(day, index) {
      return this.$createElement("div", {
        key: day.date,
        staticClass: "v-calendar-daily__day",
        class: this.getRelativeClasses(day),
        on: this.getDefaultMouseEventHandlers(":time", (nativeEvent) => {
          return { nativeEvent, ...this.getSlotScope(this.getTimestampAtEvent(nativeEvent, day)) };
        })
      }, [
        ...this.genDayIntervals(index),
        ...this.genDayBody(day)
      ]);
    },
    genDayBody(day) {
      return getSlot(this, "day-body", () => this.getSlotScope(day)) || [];
    },
    genDayIntervals(index) {
      return this.intervals[index].map(this.genDayInterval);
    },
    genDayInterval(interval) {
      const height = convertToUnit(this.intervalHeight);
      const styler = this.intervalStyle || this.intervalStyleDefault;
      const data = {
        key: interval.time,
        staticClass: "v-calendar-daily__day-interval",
        style: {
          height,
          ...styler(interval)
        }
      };
      const children = getSlot(this, "interval", () => this.getSlotScope(interval));
      return this.$createElement("div", data, children);
    },
    genBodyIntervals() {
      const width = convertToUnit(this.intervalWidth);
      const data = {
        staticClass: "v-calendar-daily__intervals-body",
        style: {
          width
        },
        on: this.getDefaultMouseEventHandlers(":interval", (nativeEvent) => {
          return { nativeEvent, ...this.getTimestampAtEvent(nativeEvent, this.parsedStart) };
        })
      };
      return this.$createElement("div", data, this.genIntervalLabels());
    },
    genIntervalLabels() {
      if (!this.intervals.length)
        return null;
      return this.intervals[0].map(this.genIntervalLabel);
    },
    genIntervalLabel(interval) {
      const height = convertToUnit(this.intervalHeight);
      const short = this.shortIntervals;
      const shower = this.showIntervalLabel || this.showIntervalLabelDefault;
      const show = shower(interval);
      const label = show ? this.intervalFormatter(interval, short) : void 0;
      return this.$createElement("div", {
        key: interval.time,
        staticClass: "v-calendar-daily__interval",
        style: {
          height
        }
      }, [
        this.$createElement("div", {
          staticClass: "v-calendar-daily__interval-text"
        }, label)
      ]);
    }
  },
  render(h) {
    return h("div", {
      class: this.classes,
      on: {
        dragstart: (e) => {
          e.preventDefault();
        }
      },
      directives: [{
        modifiers: { quiet: true },
        name: "resize",
        value: this.onResize
      }]
    }, [
      !this.hideHeader ? this.genHead() : "",
      this.genBody()
    ]);
  }
});
function parsedCategoryText(category, categoryText) {
  return typeof categoryText === "string" && typeof category === "object" && category ? category[categoryText] : typeof categoryText === "function" ? categoryText(category) : category;
}
function getParsedCategories(categories, categoryText) {
  if (typeof categories === "string")
    return categories.split(/\s*,\s/);
  if (Array.isArray(categories)) {
    return categories.map((category) => {
      if (typeof category === "string")
        return category;
      const categoryName = typeof category.categoryName === "string" ? category.categoryName : parsedCategoryText(category, categoryText);
      return { ...category, categoryName };
    });
  }
  return [];
}
const VCalendarCategory = VCalendarDaily.extend({
  name: "v-calendar-category",
  props: props.category,
  computed: {
    classes() {
      return {
        "v-calendar-daily": true,
        "v-calendar-category": true,
        ...this.themeClasses
      };
    },
    parsedCategories() {
      return getParsedCategories(this.categories, this.categoryText);
    }
  },
  methods: {
    genDayHeader(day, index) {
      const data = {
        staticClass: "v-calendar-category__columns"
      };
      const scope = {
        week: this.days,
        ...day,
        index
      };
      const children = this.parsedCategories.map((category) => {
        return this.genDayHeaderCategory(day, this.getCategoryScope(scope, category));
      });
      return [this.$createElement("div", data, children)];
    },
    getCategoryScope(scope, category) {
      const cat = typeof category === "object" && category && category.categoryName === this.categoryForInvalid ? null : category;
      return {
        ...scope,
        category: cat
      };
    },
    genDayHeaderCategory(day, scope) {
      const headerTitle = typeof scope.category === "object" ? scope.category.categoryName : scope.category;
      return this.$createElement("div", {
        staticClass: "v-calendar-category__column-header",
        on: this.getDefaultMouseEventHandlers(":day-category", (e) => {
          return this.getCategoryScope(this.getSlotScope(day), scope.category);
        })
      }, [
        getSlot(this, "category", scope) || this.genDayHeaderCategoryTitle(headerTitle),
        getSlot(this, "day-header", scope)
      ]);
    },
    genDayHeaderCategoryTitle(categoryName) {
      return this.$createElement("div", {
        staticClass: "v-calendar-category__category"
      }, categoryName === null ? this.categoryForInvalid : categoryName);
    },
    genDays() {
      const days = [];
      this.days.forEach((d, j) => {
        const day = new Array(this.parsedCategories.length || 1);
        day.fill(d);
        days.push(...day.map((v, i) => this.genDay(v, j, i)));
      });
      return days;
    },
    genDay(day, index, categoryIndex) {
      const category = this.parsedCategories[categoryIndex];
      return this.$createElement("div", {
        key: day.date + "-" + categoryIndex,
        staticClass: "v-calendar-daily__day",
        class: this.getRelativeClasses(day),
        on: this.getDefaultMouseEventHandlers(":time", (e) => {
          return this.getSlotScope(this.getTimestampAtEvent(e, day));
        })
      }, [
        ...this.genDayIntervals(index, category),
        ...this.genDayBody(day, category)
      ]);
    },
    genDayIntervals(index, category) {
      return this.intervals[index].map((v) => this.genDayInterval(v, category));
    },
    genDayInterval(interval, category) {
      const height = convertToUnit(this.intervalHeight);
      const styler = this.intervalStyle || this.intervalStyleDefault;
      const data = {
        key: interval.time,
        staticClass: "v-calendar-daily__day-interval",
        style: {
          height,
          ...styler({ ...interval, category })
        }
      };
      const children = getSlot(
        this,
        "interval",
        () => this.getCategoryScope(this.getSlotScope(interval), category)
      );
      return this.$createElement("div", data, children);
    },
    genDayBody(day, category) {
      const data = {
        staticClass: "v-calendar-category__columns"
      };
      const children = [this.genDayBodyCategory(day, category)];
      return [this.$createElement("div", data, children)];
    },
    genDayBodyCategory(day, category) {
      const data = {
        staticClass: "v-calendar-category__column",
        on: this.getDefaultMouseEventHandlers(":time-category", (e) => {
          return this.getCategoryScope(this.getSlotScope(this.getTimestampAtEvent(e, day)), category);
        })
      };
      const children = getSlot(this, "day-body", () => this.getCategoryScope(this.getSlotScope(day), category));
      return this.$createElement("div", data, children);
    }
  }
});
const VCalendar = CalendarWithEvents.extend({
  name: "v-calendar",
  props: {
    ...props.calendar,
    ...props.weeks,
    ...props.intervals,
    ...props.category
  },
  data: () => ({
    lastStart: null,
    lastEnd: null
  }),
  computed: {
    parsedValue() {
      return validateTimestamp(this.value) ? parseTimestamp(this.value, true) : this.parsedStart || this.times.today;
    },
    parsedCategoryDays() {
      return parseInt(this.categoryDays) || 1;
    },
    renderProps() {
      const around = this.parsedValue;
      let component = null;
      let maxDays = this.maxDays;
      let weekdays = this.parsedWeekdays;
      let categories = this.parsedCategories;
      let start = around;
      let end = around;
      switch (this.type) {
        case "month":
          component = VCalendarMonthly;
          start = getStartOfMonth(around);
          end = getEndOfMonth(around);
          break;
        case "week":
          component = VCalendarDaily;
          start = this.getStartOfWeek(around);
          end = this.getEndOfWeek(around);
          maxDays = 7;
          break;
        case "day":
          component = VCalendarDaily;
          maxDays = 1;
          weekdays = [start.weekday];
          break;
        case "4day":
          component = VCalendarDaily;
          end = relativeDays(copyTimestamp(end), nextDay, 3);
          updateFormatted(end);
          maxDays = 4;
          weekdays = [
            start.weekday,
            (start.weekday + 1) % 7,
            (start.weekday + 2) % 7,
            (start.weekday + 3) % 7
          ];
          break;
        case "custom-weekly":
          component = VCalendarWeekly;
          start = this.parsedStart || around;
          end = this.parsedEnd;
          break;
        case "custom-daily":
          component = VCalendarDaily;
          start = this.parsedStart || around;
          end = this.parsedEnd;
          break;
        case "category":
          const days = this.parsedCategoryDays;
          component = VCalendarCategory;
          end = relativeDays(copyTimestamp(end), nextDay, days);
          updateFormatted(end);
          maxDays = days;
          weekdays = [];
          for (let i = 0; i < days; i++) {
            weekdays.push((start.weekday + i) % 7);
          }
          categories = this.getCategoryList(categories);
          break;
        default:
          throw new Error(this.type + " is not a valid Calendar type");
      }
      return { component, start, end, maxDays, weekdays, categories };
    },
    eventWeekdays() {
      return this.renderProps.weekdays;
    },
    categoryMode() {
      return this.type === "category";
    },
    title() {
      const { start, end } = this.renderProps;
      const spanYears = start.year !== end.year;
      const spanMonths = spanYears || start.month !== end.month;
      if (spanYears) {
        return this.monthShortFormatter(start, true) + " " + start.year + " - " + this.monthShortFormatter(end, true) + " " + end.year;
      }
      if (spanMonths) {
        return this.monthShortFormatter(start, true) + " - " + this.monthShortFormatter(end, true) + " " + end.year;
      } else {
        return this.monthLongFormatter(start, false) + " " + start.year;
      }
    },
    monthLongFormatter() {
      return this.getFormatter({
        timeZone: "UTC",
        month: "long"
      });
    },
    monthShortFormatter() {
      return this.getFormatter({
        timeZone: "UTC",
        month: "short"
      });
    },
    parsedCategories() {
      return getParsedCategories(this.categories, this.categoryText);
    }
  },
  watch: {
    renderProps: "checkChange"
  },
  mounted() {
    this.updateEventVisibility();
    this.checkChange();
  },
  updated() {
    window.requestAnimationFrame(this.updateEventVisibility);
  },
  methods: {
    checkChange() {
      const { lastStart, lastEnd } = this;
      const { start, end } = this.renderProps;
      if (!lastStart || !lastEnd || start.date !== lastStart.date || end.date !== lastEnd.date) {
        this.lastStart = start;
        this.lastEnd = end;
        this.$emit("change", { start, end });
      }
    },
    move(amount = 1) {
      const moved = copyTimestamp(this.parsedValue);
      const forward = amount > 0;
      const mover = forward ? nextDay : prevDay;
      const limit = forward ? DAYS_IN_MONTH_MAX : DAY_MIN;
      let times = forward ? amount : -amount;
      while (--times >= 0) {
        switch (this.type) {
          case "month":
            moved.day = limit;
            mover(moved);
            break;
          case "week":
            relativeDays(moved, mover, DAYS_IN_WEEK);
            break;
          case "day":
            relativeDays(moved, mover, 1);
            break;
          case "4day":
            relativeDays(moved, mover, 4);
            break;
          case "category":
            relativeDays(moved, mover, this.parsedCategoryDays);
            break;
        }
      }
      updateWeekday(moved);
      updateFormatted(moved);
      updateRelative(moved, this.times.now);
      if (this.value instanceof Date) {
        this.$emit("input", timestampToDate(moved));
      } else if (typeof this.value === "number") {
        this.$emit("input", timestampToDate(moved).getTime());
      } else {
        this.$emit("input", moved.date);
      }
      this.$emit("moved", moved);
    },
    next(amount = 1) {
      this.move(amount);
    },
    prev(amount = 1) {
      this.move(-amount);
    },
    timeToY(time, clamp2 = true) {
      const c = this.$children[0];
      if (c && c.timeToY) {
        return c.timeToY(time, clamp2);
      } else {
        return false;
      }
    },
    timeDelta(time) {
      const c = this.$children[0];
      if (c && c.timeDelta) {
        return c.timeDelta(time);
      } else {
        return false;
      }
    },
    minutesToPixels(minutes) {
      const c = this.$children[0];
      if (c && c.minutesToPixels) {
        return c.minutesToPixels(minutes);
      } else {
        return -1;
      }
    },
    scrollToTime(time) {
      const c = this.$children[0];
      if (c && c.scrollToTime) {
        return c.scrollToTime(time);
      } else {
        return false;
      }
    },
    parseTimestamp(input, required) {
      return parseTimestamp(input, required, this.times.now);
    },
    timestampToDate(timestamp) {
      return timestampToDate(timestamp);
    },
    getCategoryList(categories) {
      if (!this.noEvents) {
        const categoryMap = categories.reduce((map, category, index) => {
          if (typeof category === "object" && category.categoryName)
            map[category.categoryName] = { index, count: 0 };
          else if (typeof category === "string")
            map[category] = { index, count: 0 };
          return map;
        }, {});
        if (!this.categoryHideDynamic || !this.categoryShowAll) {
          let categoryLength = categories.length;
          this.parsedEvents.forEach((ev) => {
            let category = ev.category;
            if (typeof category !== "string") {
              category = this.categoryForInvalid;
            }
            if (!category) {
              return;
            }
            if (category in categoryMap) {
              categoryMap[category].count++;
            } else if (!this.categoryHideDynamic) {
              categoryMap[category] = {
                index: categoryLength++,
                count: 1
              };
            }
          });
        }
        if (!this.categoryShowAll) {
          for (const category in categoryMap) {
            if (categoryMap[category].count === 0) {
              delete categoryMap[category];
            }
          }
        }
        categories = categories.filter((category) => {
          if (typeof category === "object" && category.categoryName) {
            return categoryMap.hasOwnProperty(category.categoryName);
          } else if (typeof category === "string") {
            return categoryMap.hasOwnProperty(category);
          }
          return false;
        });
      }
      return categories;
    }
  },
  render(h) {
    const { start, end, maxDays, component, weekdays, categories } = this.renderProps;
    return h(component, {
      staticClass: "v-calendar",
      class: {
        "v-calendar-events": !this.noEvents
      },
      props: {
        ...this.$props,
        start: start.date,
        end: end.date,
        maxDays,
        weekdays,
        categories
      },
      attrs: {
        role: "grid"
      },
      directives: [{
        modifiers: { quiet: true },
        name: "resize",
        value: this.updateEventVisibility
      }],
      on: {
        ...this.$listeners,
        "click:date": (day, e) => {
          if (this.$listeners.input) {
            this.$emit("input", day.date);
          }
          if (this.$listeners["click:date"]) {
            this.$emit("click:date", day, e);
          }
        }
      },
      scopedSlots: this.getScopedSlots()
    });
  }
});
const VCard = mixins(
  Loadable,
  Routable,
  VSheet
).extend({
  name: "v-card",
  props: {
    flat: Boolean,
    hover: Boolean,
    img: String,
    link: Boolean,
    loaderHeight: {
      type: [Number, String],
      default: 4
    },
    raised: Boolean
  },
  computed: {
    classes() {
      return {
        "v-card": true,
        ...Routable.options.computed.classes.call(this),
        "v-card--flat": this.flat,
        "v-card--hover": this.hover,
        "v-card--link": this.isClickable,
        "v-card--loading": this.loading,
        "v-card--disabled": this.disabled,
        "v-card--raised": this.raised,
        ...VSheet.options.computed.classes.call(this)
      };
    },
    styles() {
      const style = {
        ...VSheet.options.computed.styles.call(this)
      };
      if (this.img) {
        style.background = `url("${this.img}") center center / cover no-repeat`;
      }
      return style;
    }
  },
  methods: {
    genProgress() {
      const render = Loadable.options.methods.genProgress.call(this);
      if (!render)
        return null;
      return this.$createElement("div", {
        staticClass: "v-card__progress",
        key: "progress"
      }, [render]);
    }
  },
  render(h) {
    const { tag, data } = this.generateRouteLink();
    data.style = this.styles;
    if (this.isClickable) {
      data.attrs = data.attrs || {};
      data.attrs.tabindex = 0;
    }
    return h(tag, this.setBackgroundColor(this.color, data), [
      this.genProgress(),
      this.$slots.default
    ]);
  }
});
const VCardActions = createSimpleFunctional("v-card__actions");
const VCardSubtitle = createSimpleFunctional("v-card__subtitle");
const VCardText = createSimpleFunctional("v-card__text");
const VCardTitle = createSimpleFunctional("v-card__title");
const VWindow = BaseItemGroup.extend({
  name: "v-window",
  directives: { Touch: Touch$1 },
  provide() {
    return {
      windowGroup: this
    };
  },
  props: {
    activeClass: {
      type: String,
      default: "v-window-item--active"
    },
    continuous: Boolean,
    mandatory: {
      type: Boolean,
      default: true
    },
    nextIcon: {
      type: [Boolean, String],
      default: "$next"
    },
    prevIcon: {
      type: [Boolean, String],
      default: "$prev"
    },
    reverse: Boolean,
    showArrows: Boolean,
    showArrowsOnHover: Boolean,
    touch: Object,
    touchless: Boolean,
    value: {
      required: false
    },
    vertical: Boolean
  },
  data() {
    return {
      changedByDelimiters: false,
      internalHeight: void 0,
      // This can be fixed by child class.
      transitionHeight: void 0,
      // Intermediate height during transition.
      transitionCount: 0,
      // Number of windows in transition state.
      isBooted: false,
      isReverse: false
    };
  },
  computed: {
    isActive() {
      return this.transitionCount > 0;
    },
    classes() {
      return {
        ...BaseItemGroup.options.computed.classes.call(this),
        "v-window--show-arrows-on-hover": this.showArrowsOnHover
      };
    },
    computedTransition() {
      if (!this.isBooted)
        return "";
      const axis = this.vertical ? "y" : "x";
      const reverse = this.internalReverse ? !this.isReverse : this.isReverse;
      const direction = reverse ? "-reverse" : "";
      return `v-window-${axis}${direction}-transition`;
    },
    hasActiveItems() {
      return Boolean(
        this.items.find((item) => !item.disabled)
      );
    },
    hasNext() {
      return this.continuous || this.internalIndex < this.items.length - 1;
    },
    hasPrev() {
      return this.continuous || this.internalIndex > 0;
    },
    internalIndex() {
      return this.items.findIndex((item, i) => {
        return this.internalValue === this.getValue(item, i);
      });
    },
    internalReverse() {
      return this.$vuetify.rtl ? !this.reverse : this.reverse;
    }
  },
  watch: {
    internalIndex(val, oldVal) {
      this.isReverse = this.updateReverse(val, oldVal);
    }
  },
  mounted() {
    window.requestAnimationFrame(() => this.isBooted = true);
  },
  methods: {
    genDefaultSlot() {
      return this.$slots.default;
    },
    genContainer() {
      const children = [this.genDefaultSlot()];
      if (this.showArrows) {
        children.push(this.genControlIcons());
      }
      return this.$createElement("div", {
        staticClass: "v-window__container",
        class: {
          "v-window__container--is-active": this.isActive
        },
        style: {
          height: this.internalHeight || this.transitionHeight
        }
      }, children);
    },
    genIcon(direction, icon, click) {
      const on = {
        click: (e) => {
          e.stopPropagation();
          this.changedByDelimiters = true;
          click();
        }
      };
      const attrs = {
        "aria-label": this.$vuetify.lang.t(`$vuetify.carousel.${direction}`)
      };
      const children = this.$scopedSlots[direction]?.({
        on,
        attrs
      }) ?? [this.$createElement(VBtn, {
        props: { icon: true },
        attrs,
        on
      }, [
        this.$createElement(VIcon$1, {
          props: { large: true }
        }, icon)
      ])];
      return this.$createElement("div", {
        staticClass: `v-window__${direction}`
      }, children);
    },
    genControlIcons() {
      const icons2 = [];
      const prevIcon = this.$vuetify.rtl ? this.nextIcon : this.prevIcon;
      if (this.hasPrev && prevIcon && typeof prevIcon === "string") {
        const icon = this.genIcon("prev", prevIcon, this.prev);
        icon && icons2.push(icon);
      }
      const nextIcon = this.$vuetify.rtl ? this.prevIcon : this.nextIcon;
      if (this.hasNext && nextIcon && typeof nextIcon === "string") {
        const icon = this.genIcon("next", nextIcon, this.next);
        icon && icons2.push(icon);
      }
      return icons2;
    },
    getNextIndex(index) {
      const nextIndex = (index + 1) % this.items.length;
      const item = this.items[nextIndex];
      if (item.disabled)
        return this.getNextIndex(nextIndex);
      return nextIndex;
    },
    getPrevIndex(index) {
      const prevIndex = (index + this.items.length - 1) % this.items.length;
      const item = this.items[prevIndex];
      if (item.disabled)
        return this.getPrevIndex(prevIndex);
      return prevIndex;
    },
    next() {
      if (!this.hasActiveItems || !this.hasNext)
        return;
      const nextIndex = this.getNextIndex(this.internalIndex);
      const item = this.items[nextIndex];
      this.internalValue = this.getValue(item, nextIndex);
    },
    prev() {
      if (!this.hasActiveItems || !this.hasPrev)
        return;
      const lastIndex = this.getPrevIndex(this.internalIndex);
      const item = this.items[lastIndex];
      this.internalValue = this.getValue(item, lastIndex);
    },
    updateReverse(val, oldVal) {
      const itemsLength = this.items.length;
      const lastIndex = itemsLength - 1;
      if (itemsLength <= 2)
        return val < oldVal;
      if (val === lastIndex && oldVal === 0) {
        return true;
      } else if (val === 0 && oldVal === lastIndex) {
        return false;
      } else {
        return val < oldVal;
      }
    }
  },
  render(h) {
    const data = {
      staticClass: "v-window",
      class: this.classes,
      directives: []
    };
    if (!this.touchless) {
      const value = this.touch || {
        left: () => {
          this.$vuetify.rtl ? this.prev() : this.next();
        },
        right: () => {
          this.$vuetify.rtl ? this.next() : this.prev();
        },
        end: (e) => {
          e.stopPropagation();
        },
        start: (e) => {
          e.stopPropagation();
        }
      };
      data.directives.push({
        name: "touch",
        value
      });
    }
    return h("div", data, [this.genContainer()]);
  }
});
const VCarousel = VWindow.extend({
  name: "v-carousel",
  props: {
    continuous: {
      type: Boolean,
      default: true
    },
    cycle: Boolean,
    delimiterIcon: {
      type: String,
      default: "$delimiter"
    },
    height: {
      type: [Number, String],
      default: 500
    },
    hideDelimiters: Boolean,
    hideDelimiterBackground: Boolean,
    interval: {
      type: [Number, String],
      default: 6e3,
      validator: (value) => value > 0
    },
    mandatory: {
      type: Boolean,
      default: true
    },
    progress: Boolean,
    progressColor: String,
    showArrows: {
      type: Boolean,
      default: true
    },
    verticalDelimiters: {
      type: String,
      default: void 0
    }
  },
  // pass down the parent's theme
  provide() {
    return {
      parentTheme: this.theme
    };
  },
  data() {
    return {
      internalHeight: this.height,
      slideTimeout: void 0
    };
  },
  computed: {
    classes() {
      return {
        ...VWindow.options.computed.classes.call(this),
        "v-carousel": true,
        "v-carousel--hide-delimiter-background": this.hideDelimiterBackground,
        "v-carousel--vertical-delimiters": this.isVertical
      };
    },
    isDark() {
      return this.dark || !this.light;
    },
    isVertical() {
      return this.verticalDelimiters != null;
    }
  },
  watch: {
    internalValue: "restartTimeout",
    interval: "restartTimeout",
    height(val, oldVal) {
      if (val === oldVal || !val)
        return;
      this.internalHeight = val;
    },
    cycle(val) {
      if (val) {
        this.restartTimeout();
      } else {
        clearTimeout(this.slideTimeout);
        this.slideTimeout = void 0;
      }
    }
  },
  created() {
    if (this.$attrs.hasOwnProperty("hide-controls")) {
      breaking("hide-controls", ':show-arrows="false"', this);
    }
  },
  mounted() {
    this.startTimeout();
  },
  methods: {
    genControlIcons() {
      if (this.isVertical)
        return null;
      return VWindow.options.methods.genControlIcons.call(this);
    },
    genDelimiters() {
      return this.$createElement("div", {
        staticClass: "v-carousel__controls",
        style: {
          left: this.verticalDelimiters === "left" && this.isVertical ? 0 : "auto",
          right: this.verticalDelimiters === "right" ? 0 : "auto"
        }
      }, [this.genItems()]);
    },
    genItems() {
      const length = this.items.length;
      const children = [];
      for (let i = 0; i < length; i++) {
        const child = this.$createElement(VBtn, {
          staticClass: "v-carousel__controls__item",
          attrs: {
            "aria-label": this.$vuetify.lang.t("$vuetify.carousel.ariaLabel.delimiter", i + 1, length)
          },
          props: {
            icon: true,
            small: true,
            value: this.getValue(this.items[i], i)
          },
          key: i
        }, [
          this.$createElement(VIcon$1, {
            props: { size: 18 }
          }, this.delimiterIcon)
        ]);
        children.push(child);
      }
      return this.$createElement(ButtonGroup, {
        props: {
          value: this.internalValue,
          mandatory: this.mandatory
        },
        on: {
          change: (val) => {
            this.internalValue = val;
          }
        }
      }, children);
    },
    genProgress() {
      return this.$createElement(VProgressLinear, {
        staticClass: "v-carousel__progress",
        props: {
          color: this.progressColor,
          value: (this.internalIndex + 1) / this.items.length * 100
        }
      });
    },
    restartTimeout() {
      this.slideTimeout && clearTimeout(this.slideTimeout);
      this.slideTimeout = void 0;
      window.requestAnimationFrame(this.startTimeout);
    },
    startTimeout() {
      if (!this.cycle)
        return;
      this.slideTimeout = window.setTimeout(this.next, +this.interval > 0 ? +this.interval : 6e3);
    }
  },
  render(h) {
    const render = VWindow.options.render.call(this, h);
    render.data.style = `height: ${convertToUnit(this.height)};`;
    if (!this.hideDelimiters) {
      render.children.push(this.genDelimiters());
    }
    if (this.progress || this.progressColor) {
      render.children.push(this.genProgress());
    }
    return render;
  }
});
const baseMixins$h = mixins(
  Bootable,
  factory$1("windowGroup", "v-window-item", "v-window")
);
const VWindowItem = baseMixins$h.extend().extend(
  /* @vue/component */
).extend({
  name: "v-window-item",
  directives: {
    Touch: Touch$1
  },
  props: {
    disabled: Boolean,
    reverseTransition: {
      type: [Boolean, String],
      default: void 0
    },
    transition: {
      type: [Boolean, String],
      default: void 0
    },
    value: {
      required: false
    }
  },
  data() {
    return {
      isActive: false,
      inTransition: false
    };
  },
  computed: {
    classes() {
      return this.groupClasses;
    },
    computedTransition() {
      if (!this.windowGroup.internalReverse) {
        return typeof this.transition !== "undefined" ? this.transition || "" : this.windowGroup.computedTransition;
      }
      return typeof this.reverseTransition !== "undefined" ? this.reverseTransition || "" : this.windowGroup.computedTransition;
    }
  },
  methods: {
    genDefaultSlot() {
      return this.$slots.default;
    },
    genWindowItem() {
      return this.$createElement("div", {
        staticClass: "v-window-item",
        class: this.classes,
        directives: [{
          name: "show",
          value: this.isActive
        }],
        on: this.$listeners
      }, this.genDefaultSlot());
    },
    onAfterTransition() {
      if (!this.inTransition) {
        return;
      }
      this.inTransition = false;
      if (this.windowGroup.transitionCount > 0) {
        this.windowGroup.transitionCount--;
        if (this.windowGroup.transitionCount === 0) {
          this.windowGroup.transitionHeight = void 0;
        }
      }
    },
    onBeforeTransition() {
      if (this.inTransition) {
        return;
      }
      this.inTransition = true;
      if (this.windowGroup.transitionCount === 0) {
        this.windowGroup.transitionHeight = convertToUnit(this.windowGroup.$el.clientHeight);
      }
      this.windowGroup.transitionCount++;
    },
    onTransitionCancelled() {
      this.onAfterTransition();
    },
    onEnter(el) {
      if (!this.inTransition) {
        return;
      }
      this.$nextTick(() => {
        if (!this.computedTransition || !this.inTransition) {
          return;
        }
        this.windowGroup.transitionHeight = convertToUnit(el.clientHeight);
      });
    }
  },
  render(h) {
    return h("transition", {
      props: {
        name: this.computedTransition
      },
      on: {
        // Handlers for enter windows.
        beforeEnter: this.onBeforeTransition,
        afterEnter: this.onAfterTransition,
        enterCancelled: this.onTransitionCancelled,
        // Handlers for leave windows.
        beforeLeave: this.onBeforeTransition,
        afterLeave: this.onAfterTransition,
        leaveCancelled: this.onTransitionCancelled,
        // Enter handler for height transition.
        enter: this.onEnter
      }
    }, this.showLazyContent(() => [this.genWindowItem()]));
  }
});
const baseMixins$g = mixins(
  VWindowItem,
  Routable
);
const VCarouselItem = baseMixins$g.extend().extend({
  name: "v-carousel-item",
  inject: {
    parentTheme: {
      default: {
        isDark: false
      }
    }
  },
  // pass down the parent's theme
  provide() {
    return {
      theme: this.parentTheme
    };
  },
  inheritAttrs: false,
  methods: {
    genDefaultSlot() {
      return [
        this.$createElement(VImg, {
          staticClass: "v-carousel__item",
          props: {
            ...this.$attrs,
            height: this.windowGroup.internalHeight
          },
          on: this.$listeners,
          scopedSlots: {
            placeholder: this.$scopedSlots.placeholder
          }
        }, getSlot(this))
      ];
    },
    genWindowItem() {
      const { tag, data } = this.generateRouteLink();
      data.staticClass = "v-window-item";
      data.directives.push({
        name: "show",
        value: this.isActive
      });
      return this.$createElement(tag, data, this.genDefaultSlot());
    }
  }
});
const Rippleable = Vue.extend({
  name: "rippleable",
  directives: { ripple },
  props: {
    ripple: {
      type: [Boolean, Object],
      default: true
    }
  },
  methods: {
    genRipple(data = {}) {
      if (!this.ripple)
        return null;
      data.staticClass = "v-input--selection-controls__ripple";
      data.directives = data.directives || [];
      data.directives.push({
        name: "ripple",
        value: { center: true }
      });
      return this.$createElement("div", data);
    }
  }
});
function prevent(e) {
  e.preventDefault();
}
const Selectable = mixins(
  VInput,
  Rippleable,
  Comparable
).extend({
  name: "selectable",
  model: {
    prop: "inputValue",
    event: "change"
  },
  props: {
    id: String,
    inputValue: null,
    falseValue: null,
    trueValue: null,
    multiple: {
      type: Boolean,
      default: null
    },
    label: String
  },
  data() {
    return {
      hasColor: this.inputValue,
      lazyValue: this.inputValue
    };
  },
  computed: {
    computedColor() {
      if (!this.isActive)
        return void 0;
      if (this.color)
        return this.color;
      if (this.isDark && !this.appIsDark)
        return "white";
      return "primary";
    },
    isMultiple() {
      return this.multiple === true || this.multiple === null && Array.isArray(this.internalValue);
    },
    isActive() {
      const value = this.value;
      const input = this.internalValue;
      if (this.isMultiple) {
        if (!Array.isArray(input))
          return false;
        return input.some((item) => this.valueComparator(item, value));
      }
      if (this.trueValue === void 0 || this.falseValue === void 0) {
        return value ? this.valueComparator(value, input) : Boolean(input);
      }
      return this.valueComparator(input, this.trueValue);
    },
    isDirty() {
      return this.isActive;
    },
    rippleState() {
      return !this.isDisabled && !this.validationState ? void 0 : this.validationState;
    }
  },
  watch: {
    inputValue(val) {
      this.lazyValue = val;
      this.hasColor = val;
    }
  },
  methods: {
    genLabel() {
      const label = VInput.options.methods.genLabel.call(this);
      if (!label)
        return label;
      label.data.on = {
        // Label shouldn't cause the input to focus
        click: prevent
      };
      return label;
    },
    genInput(type2, attrs) {
      return this.$createElement("input", {
        attrs: Object.assign({
          "aria-checked": this.isActive.toString(),
          disabled: this.isDisabled,
          id: this.computedId,
          role: type2,
          type: type2
        }, attrs),
        domProps: {
          value: this.value,
          checked: this.isActive
        },
        on: {
          blur: this.onBlur,
          change: this.onChange,
          focus: this.onFocus,
          keydown: this.onKeydown,
          click: prevent
        },
        ref: "input"
      });
    },
    onClick(e) {
      this.onChange();
      this.$emit("click", e);
    },
    onChange() {
      if (!this.isInteractive)
        return;
      const value = this.value;
      let input = this.internalValue;
      if (this.isMultiple) {
        if (!Array.isArray(input)) {
          input = [];
        }
        const length = input.length;
        input = input.filter((item) => !this.valueComparator(item, value));
        if (input.length === length) {
          input.push(value);
        }
      } else if (this.trueValue !== void 0 && this.falseValue !== void 0) {
        input = this.valueComparator(input, this.trueValue) ? this.falseValue : this.trueValue;
      } else if (value) {
        input = this.valueComparator(input, value) ? null : value;
      } else {
        input = !input;
      }
      this.validate(true, input);
      this.internalValue = input;
      this.hasColor = input;
    },
    onFocus(e) {
      this.isFocused = true;
      this.$emit("focus", e);
    },
    onBlur(e) {
      this.isFocused = false;
      this.$emit("blur", e);
    },
    /** @abstract */
    onKeydown(e) {
    }
  }
});
const VCheckbox = Selectable.extend({
  name: "v-checkbox",
  props: {
    indeterminate: Boolean,
    indeterminateIcon: {
      type: String,
      default: "$checkboxIndeterminate"
    },
    offIcon: {
      type: String,
      default: "$checkboxOff"
    },
    onIcon: {
      type: String,
      default: "$checkboxOn"
    }
  },
  data() {
    return {
      inputIndeterminate: this.indeterminate
    };
  },
  computed: {
    classes() {
      return {
        ...VInput.options.computed.classes.call(this),
        "v-input--selection-controls": true,
        "v-input--checkbox": true,
        "v-input--indeterminate": this.inputIndeterminate
      };
    },
    computedIcon() {
      if (this.inputIndeterminate) {
        return this.indeterminateIcon;
      } else if (this.isActive) {
        return this.onIcon;
      } else {
        return this.offIcon;
      }
    },
    // Do not return undefined if disabled,
    // according to spec, should still show
    // a color when disabled and active
    validationState() {
      if (this.isDisabled && !this.inputIndeterminate)
        return void 0;
      if (this.hasError && this.shouldValidate)
        return "error";
      if (this.hasSuccess)
        return "success";
      if (this.hasColor !== null)
        return this.computedColor;
      return void 0;
    }
  },
  watch: {
    indeterminate(val) {
      this.$nextTick(() => this.inputIndeterminate = val);
    },
    inputIndeterminate(val) {
      this.$emit("update:indeterminate", val);
    },
    isActive() {
      if (!this.indeterminate)
        return;
      this.inputIndeterminate = false;
    }
  },
  methods: {
    genCheckbox() {
      const { title, ...checkboxAttrs } = this.attrs$;
      return this.$createElement("div", {
        staticClass: "v-input--selection-controls__input"
      }, [
        this.$createElement(VIcon$1, this.setTextColor(this.validationState, {
          props: {
            dense: this.dense,
            dark: this.dark,
            light: this.light
          }
        }), this.computedIcon),
        this.genInput("checkbox", {
          ...checkboxAttrs,
          "aria-checked": this.inputIndeterminate ? "mixed" : this.isActive.toString()
        }),
        this.genRipple(this.setTextColor(this.rippleState))
      ]);
    },
    genDefaultSlot() {
      return [
        this.genCheckbox(),
        this.genLabel()
      ];
    }
  }
});
function bias(val) {
  const c = 0.501;
  const x = Math.abs(val);
  return Math.sign(val) * (x / ((1 / c - 2) * (1 - x) + 1));
}
function calculateUpdatedOffset(selectedElement, widths, rtl, currentScrollOffset) {
  const clientWidth = selectedElement.clientWidth;
  const offsetLeft = rtl ? widths.content - selectedElement.offsetLeft - clientWidth : selectedElement.offsetLeft;
  if (rtl) {
    currentScrollOffset = -currentScrollOffset;
  }
  const totalWidth = widths.wrapper + currentScrollOffset;
  const itemOffset = clientWidth + offsetLeft;
  const additionalOffset = clientWidth * 0.4;
  if (offsetLeft <= currentScrollOffset) {
    currentScrollOffset = Math.max(offsetLeft - additionalOffset, 0);
  } else if (totalWidth <= itemOffset) {
    currentScrollOffset = Math.min(currentScrollOffset - (totalWidth - itemOffset - additionalOffset), widths.content - widths.wrapper);
  }
  return rtl ? -currentScrollOffset : currentScrollOffset;
}
function calculateCenteredOffset(selectedElement, widths, rtl) {
  const { offsetLeft, clientWidth } = selectedElement;
  if (rtl) {
    const offsetCentered = widths.content - offsetLeft - clientWidth / 2 - widths.wrapper / 2;
    return -Math.min(widths.content - widths.wrapper, Math.max(0, offsetCentered));
  } else {
    const offsetCentered = offsetLeft + clientWidth / 2 - widths.wrapper / 2;
    return Math.min(widths.content - widths.wrapper, Math.max(0, offsetCentered));
  }
}
const BaseSlideGroup = mixins(
  BaseItemGroup,
  Mobile
  /* @vue/component */
).extend({
  name: "base-slide-group",
  directives: {
    Resize: Resize$1,
    Touch: Touch$1
  },
  props: {
    activeClass: {
      type: String,
      default: "v-slide-item--active"
    },
    centerActive: Boolean,
    nextIcon: {
      type: String,
      default: "$next"
    },
    prevIcon: {
      type: String,
      default: "$prev"
    },
    showArrows: {
      type: [Boolean, String],
      validator: (v) => typeof v === "boolean" || [
        "always",
        "never",
        "desktop",
        "mobile"
      ].includes(v)
    }
  },
  data: () => ({
    isOverflowing: false,
    resizeTimeout: 0,
    startX: 0,
    isSwipingHorizontal: false,
    isSwiping: false,
    scrollOffset: 0,
    widths: {
      content: 0,
      wrapper: 0
    }
  }),
  computed: {
    canTouch() {
      return typeof window !== "undefined";
    },
    __cachedNext() {
      return this.genTransition("next");
    },
    __cachedPrev() {
      return this.genTransition("prev");
    },
    classes() {
      return {
        ...BaseItemGroup.options.computed.classes.call(this),
        "v-slide-group": true,
        "v-slide-group--has-affixes": this.hasAffixes,
        "v-slide-group--is-overflowing": this.isOverflowing
      };
    },
    hasAffixes() {
      switch (this.showArrows) {
        case "always":
          return true;
        case "desktop":
          return !this.isMobile;
        case true:
          return this.isOverflowing || Math.abs(this.scrollOffset) > 0;
        case "mobile":
          return this.isMobile || (this.isOverflowing || Math.abs(this.scrollOffset) > 0);
        case "never":
          return false;
        default:
          return !this.isMobile && (this.isOverflowing || Math.abs(this.scrollOffset) > 0);
      }
    },
    hasNext() {
      if (!this.hasAffixes)
        return false;
      const { content, wrapper } = this.widths;
      return content > Math.abs(this.scrollOffset) + wrapper;
    },
    hasPrev() {
      return this.hasAffixes && this.scrollOffset !== 0;
    }
  },
  watch: {
    internalValue: "setWidths",
    // When overflow changes, the arrows alter
    // the widths of the content and wrapper
    // and need to be recalculated
    isOverflowing: "setWidths",
    scrollOffset(val) {
      if (this.$vuetify.rtl)
        val = -val;
      let scroll = val <= 0 ? bias(-val) : val > this.widths.content - this.widths.wrapper ? -(this.widths.content - this.widths.wrapper) + bias(this.widths.content - this.widths.wrapper - val) : -val;
      if (this.$vuetify.rtl)
        scroll = -scroll;
      this.$refs.content.style.transform = `translateX(${scroll}px)`;
    }
  },
  mounted() {
    if (typeof ResizeObserver !== "undefined") {
      const obs = new ResizeObserver(() => {
        this.onResize();
      });
      obs.observe(this.$el);
      obs.observe(this.$refs.content);
      this.$on("hook:destroyed", () => {
        obs.disconnect();
      });
    } else {
      let itemsLength = 0;
      this.$on("hook:beforeUpdate", () => {
        itemsLength = (this.$refs.content?.children || []).length;
      });
      this.$on("hook:updated", () => {
        if (itemsLength === (this.$refs.content?.children || []).length)
          return;
        this.setWidths();
      });
    }
  },
  methods: {
    onScroll() {
      this.$refs.wrapper.scrollLeft = 0;
    },
    onFocusin(e) {
      if (!this.isOverflowing)
        return;
      for (const el of composedPath(e)) {
        for (const vm of this.items) {
          if (vm.$el === el) {
            this.scrollOffset = calculateUpdatedOffset(
              vm.$el,
              this.widths,
              this.$vuetify.rtl,
              this.scrollOffset
            );
            return;
          }
        }
      }
    },
    // Always generate next for scrollable hint
    genNext() {
      const slot = this.$scopedSlots.next ? this.$scopedSlots.next({}) : this.$slots.next || this.__cachedNext;
      return this.$createElement("div", {
        staticClass: "v-slide-group__next",
        class: {
          "v-slide-group__next--disabled": !this.hasNext
        },
        on: {
          click: () => this.onAffixClick("next")
        },
        key: "next"
      }, [slot]);
    },
    genContent() {
      return this.$createElement("div", {
        staticClass: "v-slide-group__content",
        ref: "content",
        on: {
          focusin: this.onFocusin
        }
      }, this.$slots.default);
    },
    genData() {
      return {
        class: this.classes,
        directives: [{
          name: "resize",
          value: this.onResize
        }]
      };
    },
    genIcon(location) {
      let icon = location;
      if (this.$vuetify.rtl && location === "prev") {
        icon = "next";
      } else if (this.$vuetify.rtl && location === "next") {
        icon = "prev";
      }
      const upperLocation = `${location[0].toUpperCase()}${location.slice(1)}`;
      const hasAffix = this[`has${upperLocation}`];
      if (!this.showArrows && !hasAffix)
        return null;
      return this.$createElement(VIcon$1, {
        props: {
          disabled: !hasAffix
        }
      }, this[`${icon}Icon`]);
    },
    // Always generate prev for scrollable hint
    genPrev() {
      const slot = this.$scopedSlots.prev ? this.$scopedSlots.prev({}) : this.$slots.prev || this.__cachedPrev;
      return this.$createElement("div", {
        staticClass: "v-slide-group__prev",
        class: {
          "v-slide-group__prev--disabled": !this.hasPrev
        },
        on: {
          click: () => this.onAffixClick("prev")
        },
        key: "prev"
      }, [slot]);
    },
    genTransition(location) {
      return this.$createElement(VFadeTransition, [this.genIcon(location)]);
    },
    genWrapper() {
      return this.$createElement("div", {
        staticClass: "v-slide-group__wrapper",
        directives: [{
          name: "touch",
          value: {
            start: (e) => this.overflowCheck(e, this.onTouchStart),
            move: (e) => this.overflowCheck(e, this.onTouchMove),
            end: (e) => this.overflowCheck(e, this.onTouchEnd)
          }
        }],
        ref: "wrapper",
        on: {
          scroll: this.onScroll
        }
      }, [this.genContent()]);
    },
    calculateNewOffset(direction, widths, rtl, currentScrollOffset) {
      const sign = rtl ? -1 : 1;
      const newAbosluteOffset = sign * currentScrollOffset + (direction === "prev" ? -1 : 1) * widths.wrapper;
      return sign * Math.max(Math.min(newAbosluteOffset, widths.content - widths.wrapper), 0);
    },
    onAffixClick(location) {
      this.$emit(`click:${location}`);
      this.scrollTo(location);
    },
    onResize() {
      if (this._isDestroyed)
        return;
      this.setWidths();
    },
    onTouchStart(e) {
      const { content } = this.$refs;
      this.startX = this.scrollOffset + e.touchstartX;
      content.style.setProperty("transition", "none");
      content.style.setProperty("willChange", "transform");
    },
    onTouchMove(e) {
      if (!this.canTouch)
        return;
      if (!this.isSwiping) {
        const diffX = e.touchmoveX - e.touchstartX;
        const diffY = e.touchmoveY - e.touchstartY;
        this.isSwipingHorizontal = Math.abs(diffX) > Math.abs(diffY);
        this.isSwiping = true;
      }
      if (this.isSwipingHorizontal) {
        this.scrollOffset = this.startX - e.touchmoveX;
        document.documentElement.style.overflowY = "hidden";
      }
    },
    onTouchEnd() {
      if (!this.canTouch)
        return;
      const { content, wrapper } = this.$refs;
      const maxScrollOffset = content.clientWidth - wrapper.clientWidth;
      content.style.setProperty("transition", null);
      content.style.setProperty("willChange", null);
      if (this.$vuetify.rtl) {
        if (this.scrollOffset > 0 || !this.isOverflowing) {
          this.scrollOffset = 0;
        } else if (this.scrollOffset <= -maxScrollOffset) {
          this.scrollOffset = -maxScrollOffset;
        }
      } else {
        if (this.scrollOffset < 0 || !this.isOverflowing) {
          this.scrollOffset = 0;
        } else if (this.scrollOffset >= maxScrollOffset) {
          this.scrollOffset = maxScrollOffset;
        }
      }
      this.isSwiping = false;
      document.documentElement.style.removeProperty("overflow-y");
    },
    overflowCheck(e, fn) {
      e.stopPropagation();
      this.isOverflowing && fn(e);
    },
    scrollIntoView() {
      if (!this.selectedItem && this.items.length) {
        const lastItemPosition = this.items[this.items.length - 1].$el.getBoundingClientRect();
        const wrapperPosition = this.$refs.wrapper.getBoundingClientRect();
        if (this.$vuetify.rtl && wrapperPosition.right < lastItemPosition.right || !this.$vuetify.rtl && wrapperPosition.left > lastItemPosition.left) {
          this.scrollTo("prev");
        }
      }
      if (!this.selectedItem) {
        return;
      }
      if (this.selectedIndex === 0 || !this.centerActive && !this.isOverflowing) {
        this.scrollOffset = 0;
      } else if (this.centerActive) {
        this.scrollOffset = calculateCenteredOffset(
          this.selectedItem.$el,
          this.widths,
          this.$vuetify.rtl
        );
      } else if (this.isOverflowing) {
        this.scrollOffset = calculateUpdatedOffset(
          this.selectedItem.$el,
          this.widths,
          this.$vuetify.rtl,
          this.scrollOffset
        );
      }
    },
    scrollTo(location) {
      this.scrollOffset = this.calculateNewOffset(location, {
        // Force reflow
        content: this.$refs.content ? this.$refs.content.clientWidth : 0,
        wrapper: this.$refs.wrapper ? this.$refs.wrapper.clientWidth : 0
      }, this.$vuetify.rtl, this.scrollOffset);
    },
    setWidths() {
      window.requestAnimationFrame(() => {
        if (this._isDestroyed)
          return;
        const { content, wrapper } = this.$refs;
        this.widths = {
          content: content ? content.clientWidth : 0,
          wrapper: wrapper ? wrapper.clientWidth : 0
        };
        this.isOverflowing = this.widths.wrapper + 1 < this.widths.content;
        this.scrollIntoView();
      });
    }
  },
  render(h) {
    return h("div", this.genData(), [
      this.genPrev(),
      this.genWrapper(),
      this.genNext()
    ]);
  }
});
const VSlideGroup = BaseSlideGroup.extend({
  name: "v-slide-group",
  provide() {
    return {
      slideGroup: this
    };
  }
});
const VChipGroup = mixins(
  BaseSlideGroup,
  Colorable
).extend({
  name: "v-chip-group",
  provide() {
    return {
      chipGroup: this
    };
  },
  props: {
    column: Boolean
  },
  computed: {
    classes() {
      return {
        ...BaseSlideGroup.options.computed.classes.call(this),
        "v-chip-group": true,
        "v-chip-group--column": this.column
      };
    }
  },
  watch: {
    column(val) {
      if (val)
        this.scrollOffset = 0;
      this.$nextTick(this.onResize);
    }
  },
  methods: {
    genData() {
      return this.setTextColor(this.color, {
        ...BaseSlideGroup.options.methods.genData.call(this)
      });
    }
  }
});
const VSlider = mixins(
  VInput,
  Loadable
  /* @vue/component */
).extend({
  name: "v-slider",
  directives: {
    ClickOutside: ClickOutside$1
  },
  mixins: [Loadable],
  props: {
    disabled: Boolean,
    inverseLabel: Boolean,
    max: {
      type: [Number, String],
      default: 100
    },
    min: {
      type: [Number, String],
      default: 0
    },
    step: {
      type: [Number, String],
      default: 1
    },
    thumbColor: String,
    thumbLabel: {
      type: [Boolean, String],
      default: void 0,
      validator: (v) => typeof v === "boolean" || v === "always"
    },
    thumbSize: {
      type: [Number, String],
      default: 32
    },
    tickLabels: {
      type: Array,
      default: () => []
    },
    ticks: {
      type: [Boolean, String],
      default: false,
      validator: (v) => typeof v === "boolean" || v === "always"
    },
    tickSize: {
      type: [Number, String],
      default: 2
    },
    trackColor: String,
    trackFillColor: String,
    value: [Number, String],
    vertical: Boolean
  },
  data: () => ({
    app: null,
    oldValue: null,
    thumbPressed: false,
    mouseTimeout: -1,
    isFocused: false,
    isActive: false,
    noClick: false,
    // Prevent click event if dragging took place, hack for #7915
    startOffset: 0
  }),
  computed: {
    classes() {
      return {
        ...VInput.options.computed.classes.call(this),
        "v-input__slider": true,
        "v-input__slider--vertical": this.vertical,
        "v-input__slider--inverse-label": this.inverseLabel
      };
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(val) {
        val = isNaN(val) ? this.minValue : val;
        const value = this.roundValue(Math.min(Math.max(val, this.minValue), this.maxValue));
        if (value === this.lazyValue)
          return;
        this.lazyValue = value;
        this.$emit("input", value);
      }
    },
    trackTransition() {
      return this.thumbPressed ? this.showTicks || this.stepNumeric ? "0.1s cubic-bezier(0.25, 0.8, 0.5, 1)" : "none" : "";
    },
    minValue() {
      return parseFloat(this.min);
    },
    maxValue() {
      return parseFloat(this.max);
    },
    stepNumeric() {
      return this.step > 0 ? parseFloat(this.step) : 0;
    },
    inputWidth() {
      const inputWidth = (this.roundValue(this.internalValue) - this.minValue) / (this.maxValue - this.minValue) * 100;
      return isNaN(inputWidth) ? 0 : inputWidth;
    },
    trackFillStyles() {
      const startDir = this.vertical ? "bottom" : "left";
      const endDir = this.vertical ? "top" : "right";
      const valueDir = this.vertical ? "height" : "width";
      const start = this.$vuetify.rtl ? "auto" : "0";
      const end = this.$vuetify.rtl ? "0" : "auto";
      const value = this.isDisabled ? `calc(${this.inputWidth}% - 10px)` : `${this.inputWidth}%`;
      return {
        transition: this.trackTransition,
        [startDir]: start,
        [endDir]: end,
        [valueDir]: value
      };
    },
    trackStyles() {
      const startDir = this.vertical ? this.$vuetify.rtl ? "bottom" : "top" : this.$vuetify.rtl ? "left" : "right";
      const endDir = this.vertical ? "height" : "width";
      const start = "0px";
      const end = this.isDisabled ? `calc(${100 - this.inputWidth}% - 10px)` : `calc(${100 - this.inputWidth}%)`;
      return {
        transition: this.trackTransition,
        [startDir]: start,
        [endDir]: end
      };
    },
    showTicks() {
      return this.tickLabels.length > 0 || !!(!this.isDisabled && this.stepNumeric && this.ticks);
    },
    numTicks() {
      return Math.ceil((this.maxValue - this.minValue) / this.stepNumeric);
    },
    showThumbLabel() {
      return !this.isDisabled && !!(this.thumbLabel || this.$scopedSlots["thumb-label"]);
    },
    computedTrackColor() {
      if (this.isDisabled)
        return void 0;
      if (this.trackColor)
        return this.trackColor;
      if (this.isDark)
        return this.validationState;
      return this.validationState || "primary lighten-3";
    },
    computedTrackFillColor() {
      if (this.isDisabled)
        return void 0;
      if (this.trackFillColor)
        return this.trackFillColor;
      return this.validationState || this.computedColor;
    },
    computedThumbColor() {
      if (this.thumbColor)
        return this.thumbColor;
      return this.validationState || this.computedColor;
    }
  },
  watch: {
    min(val) {
      const parsed = parseFloat(val);
      parsed > this.internalValue && this.$emit("input", parsed);
    },
    max(val) {
      const parsed = parseFloat(val);
      parsed < this.internalValue && this.$emit("input", parsed);
    },
    value: {
      handler(v) {
        this.internalValue = v;
      },
      immediate: true
    }
  },
  mounted() {
    this.app = document.querySelector("[data-app]") || consoleWarn("Missing v-app or a non-body wrapping element with the [data-app] attribute", this);
  },
  methods: {
    genDefaultSlot() {
      const children = [this.genLabel()];
      const slider = this.genSlider();
      this.inverseLabel ? children.unshift(slider) : children.push(slider);
      children.push(this.genProgress());
      return children;
    },
    genSlider() {
      return this.$createElement("div", {
        class: {
          "v-slider": true,
          "v-slider--horizontal": !this.vertical,
          "v-slider--vertical": this.vertical,
          "v-slider--focused": this.isFocused,
          "v-slider--active": this.isActive,
          "v-slider--disabled": this.isDisabled,
          "v-slider--readonly": this.isReadonly,
          ...this.themeClasses
        },
        directives: [{
          name: "click-outside",
          value: this.onBlur
        }],
        on: {
          click: this.onSliderClick,
          mousedown: this.onSliderMouseDown,
          touchstart: this.onSliderMouseDown
        }
      }, this.genChildren());
    },
    genChildren() {
      return [
        this.genInput(),
        this.genTrackContainer(),
        this.genSteps(),
        this.genThumbContainer(
          this.internalValue,
          this.inputWidth,
          this.isActive,
          this.isFocused,
          this.onFocus,
          this.onBlur
        )
      ];
    },
    genInput() {
      return this.$createElement("input", {
        attrs: {
          value: this.internalValue,
          id: this.computedId,
          disabled: true,
          readonly: true,
          tabindex: -1,
          ...this.$attrs
        }
        // on: this.genListeners(), // TODO: do we need to attach the listeners to input?
      });
    },
    genTrackContainer() {
      const children = [
        this.$createElement("div", this.setBackgroundColor(this.computedTrackColor, {
          staticClass: "v-slider__track-background",
          style: this.trackStyles
        })),
        this.$createElement("div", this.setBackgroundColor(this.computedTrackFillColor, {
          staticClass: "v-slider__track-fill",
          style: this.trackFillStyles
        }))
      ];
      return this.$createElement("div", {
        staticClass: "v-slider__track-container",
        ref: "track"
      }, children);
    },
    genSteps() {
      if (!this.step || !this.showTicks)
        return null;
      const tickSize = parseFloat(this.tickSize);
      const range = createRange(this.numTicks + 1);
      const direction = this.vertical ? "bottom" : this.$vuetify.rtl ? "right" : "left";
      const offsetDirection = this.vertical ? this.$vuetify.rtl ? "left" : "right" : "top";
      if (this.vertical)
        range.reverse();
      const ticks = range.map((index) => {
        const children = [];
        if (this.tickLabels[index]) {
          children.push(this.$createElement("div", {
            staticClass: "v-slider__tick-label"
          }, this.tickLabels[index]));
        }
        const width = index * (100 / this.numTicks);
        const filled = this.$vuetify.rtl ? 100 - this.inputWidth < width : width < this.inputWidth;
        return this.$createElement("span", {
          key: index,
          staticClass: "v-slider__tick",
          class: {
            "v-slider__tick--filled": filled
          },
          style: {
            width: `${tickSize}px`,
            height: `${tickSize}px`,
            [direction]: `calc(${width}% - ${tickSize / 2}px)`,
            [offsetDirection]: `calc(50% - ${tickSize / 2}px)`
          }
        }, children);
      });
      return this.$createElement("div", {
        staticClass: "v-slider__ticks-container",
        class: {
          "v-slider__ticks-container--always-show": this.ticks === "always" || this.tickLabels.length > 0
        }
      }, ticks);
    },
    genThumbContainer(value, valueWidth, isActive, isFocused, onFocus, onBlur, ref = "thumb") {
      const children = [this.genThumb()];
      const thumbLabelContent = this.genThumbLabelContent(value);
      this.showThumbLabel && children.push(this.genThumbLabel(thumbLabelContent));
      return this.$createElement("div", this.setTextColor(this.computedThumbColor, {
        ref,
        key: ref,
        staticClass: "v-slider__thumb-container",
        class: {
          "v-slider__thumb-container--active": isActive,
          "v-slider__thumb-container--focused": isFocused,
          "v-slider__thumb-container--show-label": this.showThumbLabel
        },
        style: this.getThumbContainerStyles(valueWidth),
        attrs: {
          role: "slider",
          tabindex: this.isDisabled ? -1 : this.$attrs.tabindex ? this.$attrs.tabindex : 0,
          "aria-label": this.$attrs["aria-label"] || this.label,
          "aria-valuemin": this.min,
          "aria-valuemax": this.max,
          "aria-valuenow": this.internalValue,
          "aria-readonly": String(this.isReadonly),
          "aria-orientation": this.vertical ? "vertical" : "horizontal"
        },
        on: {
          focus: onFocus,
          blur: onBlur,
          keydown: this.onKeyDown
        }
      }), children);
    },
    genThumbLabelContent(value) {
      return this.$scopedSlots["thumb-label"] ? this.$scopedSlots["thumb-label"]({ value }) : [this.$createElement("span", [String(value)])];
    },
    genThumbLabel(content) {
      const size = convertToUnit(this.thumbSize);
      const transform2 = this.vertical ? `translateY(20%) translateY(${Number(this.thumbSize) / 3 - 1}px) translateX(55%) rotate(135deg)` : `translateY(-20%) translateY(-12px) translateX(-50%) rotate(45deg)`;
      return this.$createElement(VScaleTransition, {
        props: { origin: "bottom center" }
      }, [
        this.$createElement("div", {
          staticClass: "v-slider__thumb-label-container",
          directives: [{
            name: "show",
            value: this.isFocused || this.isActive || this.thumbLabel === "always"
          }]
        }, [
          this.$createElement("div", this.setBackgroundColor(this.computedThumbColor, {
            staticClass: "v-slider__thumb-label",
            style: {
              height: size,
              width: size,
              transform: transform2
            }
          }), [this.$createElement("div", content)])
        ])
      ]);
    },
    genThumb() {
      return this.$createElement("div", this.setBackgroundColor(this.computedThumbColor, {
        staticClass: "v-slider__thumb"
      }));
    },
    getThumbContainerStyles(width) {
      const direction = this.vertical ? "top" : "left";
      let value = this.$vuetify.rtl ? 100 - width : width;
      value = this.vertical ? 100 - value : value;
      return {
        transition: this.trackTransition,
        [direction]: `${value}%`
      };
    },
    onSliderMouseDown(e) {
      e.preventDefault();
      this.oldValue = this.internalValue;
      this.isActive = true;
      if (e.target?.matches(".v-slider__thumb-container, .v-slider__thumb-container *")) {
        this.thumbPressed = true;
        const domRect = e.target.getBoundingClientRect();
        const touch = "touches" in e ? e.touches[0] : e;
        this.startOffset = this.vertical ? touch.clientY - (domRect.top + domRect.height / 2) : touch.clientX - (domRect.left + domRect.width / 2);
      } else {
        this.startOffset = 0;
        window.clearTimeout(this.mouseTimeout);
        this.mouseTimeout = window.setTimeout(() => {
          this.thumbPressed = true;
        }, 300);
      }
      const mouseUpOptions = passiveSupported ? { passive: true, capture: true } : true;
      const mouseMoveOptions = passiveSupported ? { passive: true } : false;
      const isTouchEvent2 = "touches" in e;
      this.onMouseMove(e);
      this.app.addEventListener(isTouchEvent2 ? "touchmove" : "mousemove", this.onMouseMove, mouseMoveOptions);
      addOnceEventListener(this.app, isTouchEvent2 ? "touchend" : "mouseup", this.onSliderMouseUp, mouseUpOptions);
      this.$emit("start", this.internalValue);
    },
    onSliderMouseUp(e) {
      e.stopPropagation();
      window.clearTimeout(this.mouseTimeout);
      this.thumbPressed = false;
      const mouseMoveOptions = passiveSupported ? { passive: true } : false;
      this.app.removeEventListener("touchmove", this.onMouseMove, mouseMoveOptions);
      this.app.removeEventListener("mousemove", this.onMouseMove, mouseMoveOptions);
      this.$emit("mouseup", e);
      this.$emit("end", this.internalValue);
      if (!deepEqual(this.oldValue, this.internalValue)) {
        this.$emit("change", this.internalValue);
        this.noClick = true;
      }
      this.isActive = false;
    },
    onMouseMove(e) {
      if (e.type === "mousemove") {
        this.thumbPressed = true;
      }
      this.internalValue = this.parseMouseMove(e);
    },
    onKeyDown(e) {
      if (!this.isInteractive)
        return;
      const value = this.parseKeyDown(e, this.internalValue);
      if (value == null || value < this.minValue || value > this.maxValue)
        return;
      this.internalValue = value;
      this.$emit("change", value);
    },
    onSliderClick(e) {
      if (this.noClick) {
        this.noClick = false;
        return;
      }
      const thumb = this.$refs.thumb;
      thumb.focus();
      this.onMouseMove(e);
      this.$emit("change", this.internalValue);
    },
    onBlur(e) {
      this.isFocused = false;
      this.$emit("blur", e);
    },
    onFocus(e) {
      this.isFocused = true;
      this.$emit("focus", e);
    },
    parseMouseMove(e) {
      const start = this.vertical ? "top" : "left";
      const length = this.vertical ? "height" : "width";
      const click = this.vertical ? "clientY" : "clientX";
      const {
        [start]: trackStart,
        [length]: trackLength
      } = this.$refs.track.getBoundingClientRect();
      const clickOffset = "touches" in e ? e.touches[0][click] : e[click];
      let clickPos = Math.min(Math.max((clickOffset - trackStart - this.startOffset) / trackLength, 0), 1) || 0;
      if (this.vertical)
        clickPos = 1 - clickPos;
      if (this.$vuetify.rtl)
        clickPos = 1 - clickPos;
      return parseFloat(this.min) + clickPos * (this.maxValue - this.minValue);
    },
    parseKeyDown(e, value) {
      if (!this.isInteractive)
        return;
      const { pageup, pagedown, end, home, left, right, down, up } = keyCodes;
      if (![pageup, pagedown, end, home, left, right, down, up].includes(e.keyCode))
        return;
      e.preventDefault();
      const step = this.stepNumeric || 1;
      const steps = (this.maxValue - this.minValue) / step;
      if ([left, right, down, up].includes(e.keyCode)) {
        const increase = this.$vuetify.rtl ? [left, up] : [right, up];
        const direction = increase.includes(e.keyCode) ? 1 : -1;
        const multiplier = e.shiftKey ? 3 : e.ctrlKey ? 2 : 1;
        value = value + direction * step * multiplier;
      } else if (e.keyCode === home) {
        value = this.minValue;
      } else if (e.keyCode === end) {
        value = this.maxValue;
      } else {
        const direction = e.keyCode === pagedown ? 1 : -1;
        value = value - direction * step * (steps > 100 ? steps / 10 : 10);
      }
      return value;
    },
    roundValue(value) {
      if (!this.stepNumeric)
        return value;
      const trimmedStep = this.step.toString().trim();
      const decimals = trimmedStep.indexOf(".") > -1 ? trimmedStep.length - trimmedStep.indexOf(".") - 1 : 0;
      const offset = this.minValue % this.stepNumeric;
      const newValue = Math.round((value - offset) / this.stepNumeric) * this.stepNumeric + offset;
      return parseFloat(Math.min(newValue, this.maxValue).toFixed(decimals));
    }
  }
});
function fromHSVA(hsva) {
  hsva = { ...hsva };
  const hexa = HSVAtoHex(hsva);
  const hsla = HSVAtoHSLA(hsva);
  const rgba = HSVAtoRGBA(hsva);
  return {
    alpha: hsva.a,
    hex: hexa.substr(0, 7),
    hexa,
    hsla,
    hsva,
    hue: hsva.h,
    rgba
  };
}
function fromHSLA(hsla) {
  const hsva = HSLAtoHSVA(hsla);
  const hexa = HSVAtoHex(hsva);
  const rgba = HSVAtoRGBA(hsva);
  return {
    alpha: hsva.a,
    hex: hexa.substr(0, 7),
    hexa,
    hsla,
    hsva,
    hue: hsva.h,
    rgba
  };
}
function fromRGBA(rgba) {
  const hsva = RGBAtoHSVA(rgba);
  const hexa = RGBAtoHex(rgba);
  const hsla = HSVAtoHSLA(hsva);
  return {
    alpha: hsva.a,
    hex: hexa.substr(0, 7),
    hexa,
    hsla,
    hsva,
    hue: hsva.h,
    rgba
  };
}
function fromHexa(hexa) {
  const hsva = HexToHSVA(hexa);
  const hsla = HSVAtoHSLA(hsva);
  const rgba = HSVAtoRGBA(hsva);
  return {
    alpha: hsva.a,
    hex: hexa.substr(0, 7),
    hexa,
    hsla,
    hsva,
    hue: hsva.h,
    rgba
  };
}
function fromHex(hex) {
  return fromHexa(parseHex(hex));
}
function has(obj, key) {
  return key.every((k) => obj.hasOwnProperty(k));
}
function parseColor(color, oldColor) {
  if (!color)
    return fromRGBA({ r: 255, g: 0, b: 0, a: 1 });
  if (typeof color === "string") {
    if (color === "transparent")
      return fromHexa("#00000000");
    const hex = parseHex(color);
    if (oldColor && hex === oldColor.hexa)
      return oldColor;
    else
      return fromHexa(hex);
  }
  if (typeof color === "object") {
    if (color.hasOwnProperty("alpha"))
      return color;
    const a = color.hasOwnProperty("a") ? parseFloat(color.a) : 1;
    if (has(color, ["r", "g", "b"])) {
      if (oldColor && color === oldColor.rgba)
        return oldColor;
      else
        return fromRGBA({ ...color, a });
    } else if (has(color, ["h", "s", "l"])) {
      if (oldColor && color === oldColor.hsla)
        return oldColor;
      else
        return fromHSLA({ ...color, a });
    } else if (has(color, ["h", "s", "v"])) {
      if (oldColor && color === oldColor.hsva)
        return oldColor;
      else
        return fromHSVA({ ...color, a });
    }
  }
  return fromRGBA({ r: 255, g: 0, b: 0, a: 1 });
}
function stripAlpha(color, stripAlpha2) {
  if (stripAlpha2) {
    const { a, ...rest } = color;
    return rest;
  }
  return color;
}
function extractColor(color, input) {
  if (input == null)
    return color;
  if (typeof input === "string") {
    return input.length === 7 ? color.hex : color.hexa;
  }
  if (typeof input === "object") {
    const shouldStrip = typeof input.a === "number" && input.a === 0 ? !!input.a : !input.a;
    if (has(input, ["r", "g", "b"]))
      return stripAlpha(color.rgba, shouldStrip);
    else if (has(input, ["h", "s", "l"]))
      return stripAlpha(color.hsla, shouldStrip);
    else if (has(input, ["h", "s", "v"]))
      return stripAlpha(color.hsva, shouldStrip);
  }
  return color;
}
function hasAlpha(color) {
  if (!color)
    return false;
  if (typeof color === "string") {
    return color.length > 7;
  }
  if (typeof color === "object") {
    return has(color, ["a"]) || has(color, ["alpha"]);
  }
  return false;
}
const VColorPickerPreview = Vue.extend({
  name: "v-color-picker-preview",
  props: {
    color: Object,
    disabled: Boolean,
    hideAlpha: Boolean
  },
  methods: {
    genAlpha() {
      return this.genTrack({
        staticClass: "v-color-picker__alpha",
        props: {
          thumbColor: "grey lighten-2",
          hideDetails: true,
          value: this.color.alpha,
          step: 0,
          min: 0,
          max: 1
        },
        style: {
          backgroundImage: this.disabled ? void 0 : `linear-gradient(to ${this.$vuetify.rtl ? "left" : "right"}, transparent, ${RGBtoCSS(this.color.rgba)})`
        },
        on: {
          input: (val) => this.color.alpha !== val && this.$emit("update:color", fromHSVA({ ...this.color.hsva, a: val }))
        }
      });
    },
    genSliders() {
      return this.$createElement("div", {
        staticClass: "v-color-picker__sliders"
      }, [
        this.genHue(),
        !this.hideAlpha && this.genAlpha()
      ]);
    },
    genDot() {
      return this.$createElement("div", {
        staticClass: "v-color-picker__dot"
      }, [
        this.$createElement("div", {
          style: {
            background: RGBAtoCSS(this.color.rgba)
          }
        })
      ]);
    },
    genHue() {
      return this.genTrack({
        staticClass: "v-color-picker__hue",
        props: {
          thumbColor: "grey lighten-2",
          hideDetails: true,
          value: this.color.hue,
          step: 0,
          min: 0,
          max: 360
        },
        on: {
          input: (val) => this.color.hue !== val && this.$emit("update:color", fromHSVA({ ...this.color.hsva, h: val }))
        }
      });
    },
    genTrack(options) {
      return this.$createElement(VSlider, {
        class: "v-color-picker__track",
        ...options,
        props: {
          disabled: this.disabled,
          ...options.props
        }
      });
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-color-picker__preview",
      class: {
        "v-color-picker__preview--hide-alpha": this.hideAlpha
      }
    }, [
      this.genDot(),
      this.genSliders()
    ]);
  }
});
const VColorPickerCanvas = Vue.extend({
  name: "v-color-picker-canvas",
  props: {
    color: {
      type: Object,
      default: () => fromRGBA({ r: 255, g: 0, b: 0, a: 1 })
    },
    disabled: Boolean,
    dotSize: {
      type: [Number, String],
      default: 10
    },
    height: {
      type: [Number, String],
      default: 150
    },
    width: {
      type: [Number, String],
      default: 300
    }
  },
  data() {
    return {
      boundingRect: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      }
    };
  },
  computed: {
    dot() {
      if (!this.color)
        return { x: 0, y: 0 };
      return {
        x: this.color.hsva.s * parseInt(this.width, 10),
        y: (1 - this.color.hsva.v) * parseInt(this.height, 10)
      };
    }
  },
  watch: {
    "color.hue": "updateCanvas"
  },
  mounted() {
    this.updateCanvas();
  },
  methods: {
    emitColor(x, y) {
      const { left, top, width, height } = this.boundingRect;
      this.$emit("update:color", fromHSVA({
        h: this.color.hue,
        s: clamp(x - left, 0, width) / width,
        v: 1 - clamp(y - top, 0, height) / height,
        a: this.color.alpha
      }));
    },
    updateCanvas() {
      if (!this.color)
        return;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      if (!ctx)
        return;
      const saturationGradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      saturationGradient.addColorStop(0, "hsla(0, 0%, 100%, 1)");
      saturationGradient.addColorStop(1, `hsla(${this.color.hue}, 100%, 50%, 1)`);
      ctx.fillStyle = saturationGradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      const valueGradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      valueGradient.addColorStop(0, "hsla(0, 0%, 100%, 0)");
      valueGradient.addColorStop(1, "hsla(0, 0%, 0%, 1)");
      ctx.fillStyle = valueGradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    },
    handleClick(e) {
      if (this.disabled)
        return;
      this.boundingRect = this.$el.getBoundingClientRect();
      this.emitColor(e.clientX, e.clientY);
    },
    handleMouseDown(e) {
      e.preventDefault();
      if (this.disabled)
        return;
      this.boundingRect = this.$el.getBoundingClientRect();
      window.addEventListener("mousemove", this.handleMouseMove);
      window.addEventListener("mouseup", this.handleMouseUp);
    },
    handleMouseMove(e) {
      if (this.disabled)
        return;
      this.emitColor(e.clientX, e.clientY);
    },
    handleMouseUp() {
      window.removeEventListener("mousemove", this.handleMouseMove);
      window.removeEventListener("mouseup", this.handleMouseUp);
    },
    genCanvas() {
      return this.$createElement("canvas", {
        ref: "canvas",
        attrs: {
          width: this.width,
          height: this.height
        }
      });
    },
    genDot() {
      const radius = parseInt(this.dotSize, 10) / 2;
      const x = convertToUnit(this.dot.x - radius);
      const y = convertToUnit(this.dot.y - radius);
      return this.$createElement("div", {
        staticClass: "v-color-picker__canvas-dot",
        class: {
          "v-color-picker__canvas-dot--disabled": this.disabled
        },
        style: {
          width: convertToUnit(this.dotSize),
          height: convertToUnit(this.dotSize),
          transform: `translate(${x}, ${y})`
        }
      });
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-color-picker__canvas",
      style: {
        width: convertToUnit(this.width),
        height: convertToUnit(this.height)
      },
      on: {
        click: this.handleClick,
        mousedown: this.handleMouseDown
      }
    }, [
      this.genCanvas(),
      this.genDot()
    ]);
  }
});
const modes = {
  rgba: {
    inputs: [
      ["r", 255, "int"],
      ["g", 255, "int"],
      ["b", 255, "int"],
      ["a", 1, "float"]
    ],
    from: fromRGBA
  },
  hsla: {
    inputs: [
      ["h", 360, "int"],
      ["s", 1, "float"],
      ["l", 1, "float"],
      ["a", 1, "float"]
    ],
    from: fromHSLA
  },
  hexa: {
    from: fromHexa
  }
};
const VColorPickerEdit = Vue.extend({
  name: "v-color-picker-edit",
  props: {
    color: Object,
    disabled: Boolean,
    hideAlpha: Boolean,
    hideModeSwitch: Boolean,
    mode: {
      type: String,
      default: "rgba",
      validator: (v) => Object.keys(modes).includes(v)
    }
  },
  data() {
    return {
      modes,
      internalMode: this.mode
    };
  },
  computed: {
    currentMode() {
      return this.modes[this.internalMode];
    }
  },
  watch: {
    mode(mode) {
      this.internalMode = mode;
    }
  },
  created() {
    this.internalMode = this.mode;
  },
  methods: {
    getValue(v, type2) {
      if (type2 === "float")
        return Math.round(v * 100) / 100;
      else if (type2 === "int")
        return Math.round(v);
      else
        return 0;
    },
    parseValue(v, type2) {
      if (type2 === "float")
        return parseFloat(v);
      else if (type2 === "int")
        return parseInt(v, 10) || 0;
      else
        return 0;
    },
    changeMode() {
      const modes2 = Object.keys(this.modes);
      const index = modes2.indexOf(this.internalMode);
      const newMode = modes2[(index + 1) % modes2.length];
      this.internalMode = newMode;
      this.$emit("update:mode", newMode);
    },
    genInput(target, attrs, value, on) {
      return this.$createElement("div", {
        staticClass: "v-color-picker__input"
      }, [
        this.$createElement("input", {
          key: target,
          attrs,
          domProps: {
            value
          },
          on
        }),
        this.$createElement("span", target.toUpperCase())
      ]);
    },
    genInputs() {
      if (this.internalMode === "hexa") {
        const hex = this.color.hexa;
        const value = this.hideAlpha && hex.endsWith("FF") ? hex.substr(0, 7) : hex;
        return this.genInput(
          "hex",
          {
            maxlength: this.hideAlpha ? 7 : 9,
            disabled: this.disabled
          },
          value,
          {
            change: (e) => {
              const el = e.target;
              this.$emit("update:color", this.currentMode.from(parseHex(el.value)));
            }
          }
        );
      } else {
        const inputs = this.hideAlpha ? this.currentMode.inputs.slice(0, -1) : this.currentMode.inputs;
        return inputs.map(([target, max, type2]) => {
          const value = this.color[this.internalMode];
          return this.genInput(
            target,
            {
              type: "number",
              min: 0,
              max,
              step: type2 === "float" ? "0.01" : type2 === "int" ? "1" : void 0,
              disabled: this.disabled
            },
            this.getValue(value[target], type2),
            {
              input: (e) => {
                const el = e.target;
                const newVal = this.parseValue(el.value || "0", type2);
                this.$emit("update:color", this.currentMode.from(
                  Object.assign({}, value, { [target]: newVal }),
                  this.color.alpha
                ));
              }
            }
          );
        });
      }
    },
    genSwitch() {
      return this.$createElement(VBtn, {
        props: {
          small: true,
          icon: true,
          disabled: this.disabled
        },
        on: {
          click: this.changeMode
        }
      }, [
        this.$createElement(VIcon$1, "$unfold")
      ]);
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-color-picker__edit"
    }, [
      this.genInputs(),
      !this.hideModeSwitch && this.genSwitch()
    ]);
  }
});
const red = Object.freeze({
  base: "#f44336",
  lighten5: "#ffebee",
  lighten4: "#ffcdd2",
  lighten3: "#ef9a9a",
  lighten2: "#e57373",
  lighten1: "#ef5350",
  darken1: "#e53935",
  darken2: "#d32f2f",
  darken3: "#c62828",
  darken4: "#b71c1c",
  accent1: "#ff8a80",
  accent2: "#ff5252",
  accent3: "#ff1744",
  accent4: "#d50000"
});
const pink = Object.freeze({
  base: "#e91e63",
  lighten5: "#fce4ec",
  lighten4: "#f8bbd0",
  lighten3: "#f48fb1",
  lighten2: "#f06292",
  lighten1: "#ec407a",
  darken1: "#d81b60",
  darken2: "#c2185b",
  darken3: "#ad1457",
  darken4: "#880e4f",
  accent1: "#ff80ab",
  accent2: "#ff4081",
  accent3: "#f50057",
  accent4: "#c51162"
});
const purple = Object.freeze({
  base: "#9c27b0",
  lighten5: "#f3e5f5",
  lighten4: "#e1bee7",
  lighten3: "#ce93d8",
  lighten2: "#ba68c8",
  lighten1: "#ab47bc",
  darken1: "#8e24aa",
  darken2: "#7b1fa2",
  darken3: "#6a1b9a",
  darken4: "#4a148c",
  accent1: "#ea80fc",
  accent2: "#e040fb",
  accent3: "#d500f9",
  accent4: "#aa00ff"
});
const deepPurple = Object.freeze({
  base: "#673ab7",
  lighten5: "#ede7f6",
  lighten4: "#d1c4e9",
  lighten3: "#b39ddb",
  lighten2: "#9575cd",
  lighten1: "#7e57c2",
  darken1: "#5e35b1",
  darken2: "#512da8",
  darken3: "#4527a0",
  darken4: "#311b92",
  accent1: "#b388ff",
  accent2: "#7c4dff",
  accent3: "#651fff",
  accent4: "#6200ea"
});
const indigo = Object.freeze({
  base: "#3f51b5",
  lighten5: "#e8eaf6",
  lighten4: "#c5cae9",
  lighten3: "#9fa8da",
  lighten2: "#7986cb",
  lighten1: "#5c6bc0",
  darken1: "#3949ab",
  darken2: "#303f9f",
  darken3: "#283593",
  darken4: "#1a237e",
  accent1: "#8c9eff",
  accent2: "#536dfe",
  accent3: "#3d5afe",
  accent4: "#304ffe"
});
const blue = Object.freeze({
  base: "#2196f3",
  lighten5: "#e3f2fd",
  lighten4: "#bbdefb",
  lighten3: "#90caf9",
  lighten2: "#64b5f6",
  lighten1: "#42a5f5",
  darken1: "#1e88e5",
  darken2: "#1976d2",
  darken3: "#1565c0",
  darken4: "#0d47a1",
  accent1: "#82b1ff",
  accent2: "#448aff",
  accent3: "#2979ff",
  accent4: "#2962ff"
});
const lightBlue = Object.freeze({
  base: "#03a9f4",
  lighten5: "#e1f5fe",
  lighten4: "#b3e5fc",
  lighten3: "#81d4fa",
  lighten2: "#4fc3f7",
  lighten1: "#29b6f6",
  darken1: "#039be5",
  darken2: "#0288d1",
  darken3: "#0277bd",
  darken4: "#01579b",
  accent1: "#80d8ff",
  accent2: "#40c4ff",
  accent3: "#00b0ff",
  accent4: "#0091ea"
});
const cyan = Object.freeze({
  base: "#00bcd4",
  lighten5: "#e0f7fa",
  lighten4: "#b2ebf2",
  lighten3: "#80deea",
  lighten2: "#4dd0e1",
  lighten1: "#26c6da",
  darken1: "#00acc1",
  darken2: "#0097a7",
  darken3: "#00838f",
  darken4: "#006064",
  accent1: "#84ffff",
  accent2: "#18ffff",
  accent3: "#00e5ff",
  accent4: "#00b8d4"
});
const teal = Object.freeze({
  base: "#009688",
  lighten5: "#e0f2f1",
  lighten4: "#b2dfdb",
  lighten3: "#80cbc4",
  lighten2: "#4db6ac",
  lighten1: "#26a69a",
  darken1: "#00897b",
  darken2: "#00796b",
  darken3: "#00695c",
  darken4: "#004d40",
  accent1: "#a7ffeb",
  accent2: "#64ffda",
  accent3: "#1de9b6",
  accent4: "#00bfa5"
});
const green = Object.freeze({
  base: "#4caf50",
  lighten5: "#e8f5e9",
  lighten4: "#c8e6c9",
  lighten3: "#a5d6a7",
  lighten2: "#81c784",
  lighten1: "#66bb6a",
  darken1: "#43a047",
  darken2: "#388e3c",
  darken3: "#2e7d32",
  darken4: "#1b5e20",
  accent1: "#b9f6ca",
  accent2: "#69f0ae",
  accent3: "#00e676",
  accent4: "#00c853"
});
const lightGreen = Object.freeze({
  base: "#8bc34a",
  lighten5: "#f1f8e9",
  lighten4: "#dcedc8",
  lighten3: "#c5e1a5",
  lighten2: "#aed581",
  lighten1: "#9ccc65",
  darken1: "#7cb342",
  darken2: "#689f38",
  darken3: "#558b2f",
  darken4: "#33691e",
  accent1: "#ccff90",
  accent2: "#b2ff59",
  accent3: "#76ff03",
  accent4: "#64dd17"
});
const lime = Object.freeze({
  base: "#cddc39",
  lighten5: "#f9fbe7",
  lighten4: "#f0f4c3",
  lighten3: "#e6ee9c",
  lighten2: "#dce775",
  lighten1: "#d4e157",
  darken1: "#c0ca33",
  darken2: "#afb42b",
  darken3: "#9e9d24",
  darken4: "#827717",
  accent1: "#f4ff81",
  accent2: "#eeff41",
  accent3: "#c6ff00",
  accent4: "#aeea00"
});
const yellow = Object.freeze({
  base: "#ffeb3b",
  lighten5: "#fffde7",
  lighten4: "#fff9c4",
  lighten3: "#fff59d",
  lighten2: "#fff176",
  lighten1: "#ffee58",
  darken1: "#fdd835",
  darken2: "#fbc02d",
  darken3: "#f9a825",
  darken4: "#f57f17",
  accent1: "#ffff8d",
  accent2: "#ffff00",
  accent3: "#ffea00",
  accent4: "#ffd600"
});
const amber = Object.freeze({
  base: "#ffc107",
  lighten5: "#fff8e1",
  lighten4: "#ffecb3",
  lighten3: "#ffe082",
  lighten2: "#ffd54f",
  lighten1: "#ffca28",
  darken1: "#ffb300",
  darken2: "#ffa000",
  darken3: "#ff8f00",
  darken4: "#ff6f00",
  accent1: "#ffe57f",
  accent2: "#ffd740",
  accent3: "#ffc400",
  accent4: "#ffab00"
});
const orange = Object.freeze({
  base: "#ff9800",
  lighten5: "#fff3e0",
  lighten4: "#ffe0b2",
  lighten3: "#ffcc80",
  lighten2: "#ffb74d",
  lighten1: "#ffa726",
  darken1: "#fb8c00",
  darken2: "#f57c00",
  darken3: "#ef6c00",
  darken4: "#e65100",
  accent1: "#ffd180",
  accent2: "#ffab40",
  accent3: "#ff9100",
  accent4: "#ff6d00"
});
const deepOrange = Object.freeze({
  base: "#ff5722",
  lighten5: "#fbe9e7",
  lighten4: "#ffccbc",
  lighten3: "#ffab91",
  lighten2: "#ff8a65",
  lighten1: "#ff7043",
  darken1: "#f4511e",
  darken2: "#e64a19",
  darken3: "#d84315",
  darken4: "#bf360c",
  accent1: "#ff9e80",
  accent2: "#ff6e40",
  accent3: "#ff3d00",
  accent4: "#dd2c00"
});
const brown = Object.freeze({
  base: "#795548",
  lighten5: "#efebe9",
  lighten4: "#d7ccc8",
  lighten3: "#bcaaa4",
  lighten2: "#a1887f",
  lighten1: "#8d6e63",
  darken1: "#6d4c41",
  darken2: "#5d4037",
  darken3: "#4e342e",
  darken4: "#3e2723"
});
const blueGrey = Object.freeze({
  base: "#607d8b",
  lighten5: "#eceff1",
  lighten4: "#cfd8dc",
  lighten3: "#b0bec5",
  lighten2: "#90a4ae",
  lighten1: "#78909c",
  darken1: "#546e7a",
  darken2: "#455a64",
  darken3: "#37474f",
  darken4: "#263238"
});
const grey = Object.freeze({
  base: "#9e9e9e",
  lighten5: "#fafafa",
  lighten4: "#f5f5f5",
  lighten3: "#eeeeee",
  lighten2: "#e0e0e0",
  lighten1: "#bdbdbd",
  darken1: "#757575",
  darken2: "#616161",
  darken3: "#424242",
  darken4: "#212121"
});
const shades = Object.freeze({
  black: "#000000",
  white: "#ffffff",
  transparent: "transparent"
});
const colors = Object.freeze({
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  blueGrey,
  grey,
  shades
});
function parseDefaultColors(colors2) {
  return Object.keys(colors2).map((key) => {
    const color = colors2[key];
    return color.base ? [
      color.base,
      color.darken4,
      color.darken3,
      color.darken2,
      color.darken1,
      color.lighten1,
      color.lighten2,
      color.lighten3,
      color.lighten4,
      color.lighten5
    ] : [
      color.black,
      color.white,
      color.transparent
    ];
  });
}
const white = fromHex("#FFFFFF").rgba;
const black = fromHex("#000000").rgba;
const VColorPickerSwatches = mixins(Themeable$1).extend({
  name: "v-color-picker-swatches",
  props: {
    swatches: {
      type: Array,
      default: () => parseDefaultColors(colors)
    },
    disabled: Boolean,
    color: Object,
    maxWidth: [Number, String],
    maxHeight: [Number, String]
  },
  methods: {
    genColor(color) {
      const content = this.$createElement("div", {
        style: {
          background: color
        }
      }, [
        deepEqual(this.color, parseColor(color, null)) && this.$createElement(VIcon$1, {
          props: {
            small: true,
            dark: contrastRatio(this.color.rgba, white) > 2 && this.color.alpha > 0.5,
            light: contrastRatio(this.color.rgba, black) > 2 && this.color.alpha > 0.5
          }
        }, "$success")
      ]);
      return this.$createElement("div", {
        staticClass: "v-color-picker__color",
        on: {
          // TODO: Less hacky way of catching transparent
          click: () => this.disabled || this.$emit("update:color", fromHex(color === "transparent" ? "#00000000" : color))
        }
      }, [content]);
    },
    genSwatches() {
      return this.swatches.map((swatch) => {
        const colors2 = swatch.map(this.genColor);
        return this.$createElement("div", {
          staticClass: "v-color-picker__swatch"
        }, colors2);
      });
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-color-picker__swatches",
      style: {
        maxWidth: convertToUnit(this.maxWidth),
        maxHeight: convertToUnit(this.maxHeight)
      }
    }, [
      this.$createElement("div", this.genSwatches())
    ]);
  }
});
const VColorPicker = mixins(Elevatable, Themeable$1).extend({
  name: "v-color-picker",
  props: {
    canvasHeight: {
      type: [String, Number],
      default: 150
    },
    disabled: Boolean,
    dotSize: {
      type: [Number, String],
      default: 10
    },
    flat: Boolean,
    hideCanvas: Boolean,
    hideSliders: Boolean,
    hideInputs: Boolean,
    hideModeSwitch: Boolean,
    mode: {
      type: String,
      default: "rgba",
      validator: (v) => Object.keys(modes).includes(v)
    },
    showSwatches: Boolean,
    swatches: Array,
    swatchesMaxHeight: {
      type: [Number, String],
      default: 150
    },
    value: {
      type: [Object, String]
    },
    width: {
      type: [Number, String],
      default: 300
    }
  },
  data: () => ({
    internalValue: fromRGBA({ r: 255, g: 0, b: 0, a: 1 })
  }),
  computed: {
    hideAlpha() {
      if (!this.value)
        return false;
      return !hasAlpha(this.value);
    }
  },
  watch: {
    value: {
      handler(color) {
        this.updateColor(parseColor(color, this.internalValue));
      },
      immediate: true
    }
  },
  methods: {
    updateColor(color) {
      this.internalValue = color;
      const value = extractColor(this.internalValue, this.value);
      if (!deepEqual(value, this.value)) {
        this.$emit("input", value);
        this.$emit("update:color", this.internalValue);
      }
    },
    genCanvas() {
      return this.$createElement(VColorPickerCanvas, {
        props: {
          color: this.internalValue,
          disabled: this.disabled,
          dotSize: this.dotSize,
          width: this.width,
          height: this.canvasHeight
        },
        on: {
          "update:color": this.updateColor
        }
      });
    },
    genControls() {
      return this.$createElement("div", {
        staticClass: "v-color-picker__controls"
      }, [
        !this.hideSliders && this.genPreview(),
        !this.hideInputs && this.genEdit()
      ]);
    },
    genEdit() {
      return this.$createElement(VColorPickerEdit, {
        props: {
          color: this.internalValue,
          disabled: this.disabled,
          hideAlpha: this.hideAlpha,
          hideModeSwitch: this.hideModeSwitch,
          mode: this.mode
        },
        on: {
          "update:color": this.updateColor,
          "update:mode": (v) => this.$emit("update:mode", v)
        }
      });
    },
    genPreview() {
      return this.$createElement(VColorPickerPreview, {
        props: {
          color: this.internalValue,
          disabled: this.disabled,
          hideAlpha: this.hideAlpha
        },
        on: {
          "update:color": this.updateColor
        }
      });
    },
    genSwatches() {
      return this.$createElement(VColorPickerSwatches, {
        props: {
          dark: this.dark,
          light: this.light,
          disabled: this.disabled,
          swatches: this.swatches,
          color: this.internalValue,
          maxHeight: this.swatchesMaxHeight
        },
        on: {
          "update:color": this.updateColor
        }
      });
    }
  },
  render(h) {
    return h(VSheet, {
      staticClass: "v-color-picker",
      class: {
        "v-color-picker--flat": this.flat,
        ...this.themeClasses,
        ...this.elevationClasses
      },
      props: {
        maxWidth: this.width
      }
    }, [
      !this.hideCanvas && this.genCanvas(),
      (!this.hideSliders || !this.hideInputs) && this.genControls(),
      this.showSwatches && this.genSwatches()
    ]);
  }
});
const VMain = SSRBootable.extend({
  name: "v-main",
  props: {
    tag: {
      type: String,
      default: "main"
    }
  },
  computed: {
    styles() {
      const {
        bar,
        top,
        right,
        footer,
        insetFooter,
        bottom,
        left
      } = this.$vuetify.application;
      return {
        paddingTop: `${top + bar}px`,
        paddingRight: `${right}px`,
        paddingBottom: `${footer + insetFooter + bottom}px`,
        paddingLeft: `${left}px`
      };
    }
  },
  render(h) {
    const data = {
      staticClass: "v-main",
      style: this.styles,
      ref: "main"
    };
    return h(this.tag, data, [
      h(
        "div",
        { staticClass: "v-main__wrap" },
        this.$slots.default
      )
    ]);
  }
});
const VContent = VMain.extend({
  name: "v-main",
  created() {
    deprecate("v-content", "v-main", this);
  },
  render(h) {
    const node = VMain.options.render.call(this, h);
    node.data.staticClass += " v-content";
    node.children[0].data.staticClass += " v-content__wrap";
    return h(node.tag, node.data, node.children);
  }
});
const VCombobox = VAutocomplete.extend({
  name: "v-combobox",
  props: {
    delimiters: {
      type: Array,
      default: () => []
    },
    returnObject: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    editingIndex: -1
  }),
  computed: {
    computedCounterValue() {
      return this.multiple ? this.selectedItems.length : (this.internalSearch || "").toString().length;
    },
    hasSlot() {
      return VSelect.options.computed.hasSlot.call(this) || this.multiple;
    },
    isAnyValueAllowed() {
      return true;
    },
    menuCanShow() {
      if (!this.isFocused)
        return false;
      return this.hasDisplayedItems || !!this.$slots["no-data"] && !this.hideNoData;
    },
    searchIsDirty() {
      return this.internalSearch != null;
    }
  },
  methods: {
    onInternalSearchChanged(val) {
      if (val && this.multiple && this.delimiters.length) {
        const delimiter = this.delimiters.find((d) => val.endsWith(d));
        if (delimiter != null) {
          this.internalSearch = val.slice(0, val.length - delimiter.length);
          this.updateTags();
        }
      }
      this.updateMenuDimensions();
    },
    genInput() {
      const input = VAutocomplete.options.methods.genInput.call(this);
      delete input.data.attrs.name;
      input.data.on.paste = this.onPaste;
      return input;
    },
    genChipSelection(item, index) {
      const chip = VSelect.options.methods.genChipSelection.call(this, item, index);
      if (this.multiple) {
        chip.componentOptions.listeners = {
          ...chip.componentOptions.listeners,
          dblclick: () => {
            this.editingIndex = index;
            this.internalSearch = this.getText(item);
            this.selectedIndex = -1;
          }
        };
      }
      return chip;
    },
    onChipInput(item) {
      VSelect.options.methods.onChipInput.call(this, item);
      this.editingIndex = -1;
    },
    // Requires a manual definition
    // to overwrite removal in v-autocomplete
    onEnterDown(e) {
      e.preventDefault();
      if (this.getMenuIndex() > -1)
        return;
      this.$nextTick(this.updateSelf);
    },
    onKeyDown(e) {
      const keyCode = e.keyCode;
      if (e.ctrlKey || ![keyCodes.home, keyCodes.end].includes(keyCode)) {
        VSelect.options.methods.onKeyDown.call(this, e);
      }
      if (this.multiple && keyCode === keyCodes.left && this.$refs.input.selectionStart === 0) {
        this.updateSelf();
      } else if (keyCode === keyCodes.enter) {
        this.onEnterDown(e);
      }
      this.changeSelectedIndex(keyCode);
    },
    onTabDown(e) {
      if (this.multiple && this.internalSearch && this.getMenuIndex() === -1) {
        e.preventDefault();
        e.stopPropagation();
        return this.updateTags();
      }
      VAutocomplete.options.methods.onTabDown.call(this, e);
    },
    selectItem(item) {
      if (this.editingIndex > -1) {
        this.updateEditing();
      } else {
        VAutocomplete.options.methods.selectItem.call(this, item);
        if (this.internalSearch && this.multiple && this.getText(item).toLocaleLowerCase().includes(this.internalSearch.toLocaleLowerCase())) {
          this.internalSearch = null;
        }
      }
    },
    setSelectedItems() {
      if (this.internalValue == null || this.internalValue === "") {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.multiple ? this.internalValue : [this.internalValue];
      }
    },
    setValue(value) {
      VSelect.options.methods.setValue.call(this, value === void 0 ? this.internalSearch : value);
    },
    updateEditing() {
      const value = this.internalValue.slice();
      const index = this.selectedItems.findIndex((item) => this.getText(item) === this.internalSearch);
      if (index > -1) {
        const item = typeof value[index] === "object" ? Object.assign({}, value[index]) : value[index];
        value.splice(index, 1);
        value.push(item);
      } else {
        value[this.editingIndex] = this.internalSearch;
      }
      this.setValue(value);
      this.editingIndex = -1;
      this.internalSearch = null;
    },
    updateCombobox() {
      if (!this.searchIsDirty)
        return;
      if (this.internalSearch !== this.getText(this.internalValue))
        this.setValue();
      const isUsingSlot = Boolean(this.$scopedSlots.selection) || this.hasChips;
      if (isUsingSlot)
        this.internalSearch = null;
    },
    updateSelf() {
      this.multiple ? this.updateTags() : this.updateCombobox();
    },
    updateTags() {
      const menuIndex = this.getMenuIndex();
      if (menuIndex < 0 && !this.searchIsDirty || !this.internalSearch)
        return;
      if (this.editingIndex > -1) {
        return this.updateEditing();
      }
      const index = this.selectedItems.findIndex((item) => this.internalSearch === this.getText(item));
      const itemToSelect = index > -1 && typeof this.selectedItems[index] === "object" ? Object.assign({}, this.selectedItems[index]) : this.internalSearch;
      if (index > -1) {
        const internalValue = this.internalValue.slice();
        internalValue.splice(index, 1);
        this.setValue(internalValue);
      }
      if (menuIndex > -1)
        return this.internalSearch = null;
      this.selectItem(itemToSelect);
      this.internalSearch = null;
    },
    onPaste(event) {
      this.$emit("paste", event);
      if (!this.multiple || this.searchIsDirty)
        return;
      const pastedItemText = event.clipboardData?.getData("text/vnd.vuetify.autocomplete.item+plain");
      if (pastedItemText && this.findExistingIndex(pastedItemText) === -1) {
        event.preventDefault();
        VSelect.options.methods.selectItem.call(this, pastedItemText);
      }
    },
    clearableCallback() {
      this.editingIndex = -1;
      VAutocomplete.options.methods.clearableCallback.call(this);
    }
  }
});
const VData = Vue.extend({
  name: "v-data",
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    },
    sortBy: {
      type: [String, Array]
    },
    sortDesc: {
      type: [Boolean, Array]
    },
    customSort: {
      type: Function,
      default: sortItems
    },
    mustSort: Boolean,
    multiSort: Boolean,
    page: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    groupBy: {
      type: [String, Array],
      default: () => []
    },
    groupDesc: {
      type: [Boolean, Array],
      default: () => []
    },
    customGroup: {
      type: Function,
      default: groupItems
    },
    locale: {
      type: String,
      default: "en-US"
    },
    disableSort: Boolean,
    disablePagination: Boolean,
    disableFiltering: Boolean,
    search: String,
    customFilter: {
      type: Function,
      default: searchItems
    },
    serverItemsLength: {
      type: Number,
      default: -1
    }
  },
  data() {
    let internalOptions = {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      sortBy: wrapInArray(this.sortBy),
      sortDesc: wrapInArray(this.sortDesc),
      groupBy: wrapInArray(this.groupBy),
      groupDesc: wrapInArray(this.groupDesc),
      mustSort: this.mustSort,
      multiSort: this.multiSort
    };
    if (this.options) {
      internalOptions = Object.assign(internalOptions, this.options);
    }
    const { sortBy, sortDesc, groupBy, groupDesc } = internalOptions;
    const sortDiff = sortBy.length - sortDesc.length;
    const groupDiff = groupBy.length - groupDesc.length;
    if (sortDiff > 0) {
      internalOptions.sortDesc.push(...fillArray(sortDiff, false));
    }
    if (groupDiff > 0) {
      internalOptions.groupDesc.push(...fillArray(groupDiff, false));
    }
    return {
      internalOptions
    };
  },
  computed: {
    itemsLength() {
      return this.serverItemsLength >= 0 ? this.serverItemsLength : this.filteredItems.length;
    },
    pageCount() {
      return this.internalOptions.itemsPerPage <= 0 ? 1 : Math.ceil(this.itemsLength / this.internalOptions.itemsPerPage);
    },
    pageStart() {
      if (this.internalOptions.itemsPerPage === -1 || !this.items.length)
        return 0;
      return (this.internalOptions.page - 1) * this.internalOptions.itemsPerPage;
    },
    pageStop() {
      if (this.internalOptions.itemsPerPage === -1)
        return this.itemsLength;
      if (!this.items.length)
        return 0;
      return Math.min(this.itemsLength, this.internalOptions.page * this.internalOptions.itemsPerPage);
    },
    isGrouped() {
      return !!this.internalOptions.groupBy.length;
    },
    pagination() {
      return {
        page: this.internalOptions.page,
        itemsPerPage: this.internalOptions.itemsPerPage,
        pageStart: this.pageStart,
        pageStop: this.pageStop,
        pageCount: this.pageCount,
        itemsLength: this.itemsLength
      };
    },
    filteredItems() {
      let items = this.items.slice();
      if (!this.disableFiltering && this.serverItemsLength <= 0) {
        items = this.customFilter(items, this.search);
      }
      return items;
    },
    computedItems() {
      let items = this.filteredItems.slice();
      if ((!this.disableSort || this.internalOptions.groupBy.length) && this.serverItemsLength <= 0) {
        items = this.sortItems(items);
      }
      if (!this.disablePagination && this.serverItemsLength <= 0) {
        items = this.paginateItems(items);
      }
      return items;
    },
    groupedItems() {
      return this.isGrouped ? this.groupItems(this.computedItems) : null;
    },
    scopedProps() {
      return {
        sort: this.sort,
        sortArray: this.sortArray,
        group: this.group,
        items: this.computedItems,
        options: this.internalOptions,
        updateOptions: this.updateOptions,
        pagination: this.pagination,
        groupedItems: this.groupedItems,
        originalItemsLength: this.items.length
      };
    },
    computedOptions() {
      return { ...this.options };
    }
  },
  watch: {
    computedOptions: {
      handler(options, old) {
        if (deepEqual(options, old))
          return;
        this.updateOptions(options);
      },
      deep: true,
      immediate: true
    },
    internalOptions: {
      handler(options, old) {
        if (deepEqual(options, old))
          return;
        this.$emit("update:options", options);
      },
      deep: true,
      immediate: true
    },
    page(page) {
      this.updateOptions({ page });
    },
    "internalOptions.page"(page) {
      this.$emit("update:page", page);
    },
    itemsPerPage(itemsPerPage) {
      this.updateOptions({ itemsPerPage });
    },
    "internalOptions.itemsPerPage"(itemsPerPage) {
      this.$emit("update:items-per-page", itemsPerPage);
    },
    sortBy(sortBy) {
      this.updateOptions({ sortBy: wrapInArray(sortBy) });
    },
    "internalOptions.sortBy"(sortBy, old) {
      !deepEqual(sortBy, old) && this.$emit("update:sort-by", Array.isArray(this.sortBy) ? sortBy : sortBy[0]);
    },
    sortDesc(sortDesc) {
      this.updateOptions({ sortDesc: wrapInArray(sortDesc) });
    },
    "internalOptions.sortDesc"(sortDesc, old) {
      !deepEqual(sortDesc, old) && this.$emit("update:sort-desc", Array.isArray(this.sortDesc) ? sortDesc : sortDesc[0]);
    },
    groupBy(groupBy) {
      this.updateOptions({ groupBy: wrapInArray(groupBy) });
    },
    "internalOptions.groupBy"(groupBy, old) {
      !deepEqual(groupBy, old) && this.$emit("update:group-by", Array.isArray(this.groupBy) ? groupBy : groupBy[0]);
    },
    groupDesc(groupDesc) {
      this.updateOptions({ groupDesc: wrapInArray(groupDesc) });
    },
    "internalOptions.groupDesc"(groupDesc, old) {
      !deepEqual(groupDesc, old) && this.$emit("update:group-desc", Array.isArray(this.groupDesc) ? groupDesc : groupDesc[0]);
    },
    multiSort(multiSort) {
      this.updateOptions({ multiSort });
    },
    "internalOptions.multiSort"(multiSort) {
      this.$emit("update:multi-sort", multiSort);
    },
    mustSort(mustSort) {
      this.updateOptions({ mustSort });
    },
    "internalOptions.mustSort"(mustSort) {
      this.$emit("update:must-sort", mustSort);
    },
    pageCount: {
      handler(pageCount) {
        this.$emit("page-count", pageCount);
      },
      immediate: true
    },
    computedItems: {
      handler(computedItems) {
        this.$emit("current-items", computedItems);
      },
      immediate: true
    },
    pagination: {
      handler(pagination, old) {
        if (deepEqual(pagination, old))
          return;
        this.$emit("pagination", this.pagination);
      },
      immediate: true
    }
  },
  methods: {
    toggle(key, oldBy, oldDesc, page, mustSort, multiSort) {
      let by = oldBy.slice();
      let desc = oldDesc.slice();
      const byIndex = by.findIndex((k) => k === key);
      if (byIndex < 0) {
        if (!multiSort) {
          by = [];
          desc = [];
        }
        by.push(key);
        desc.push(false);
      } else if (byIndex >= 0 && !desc[byIndex]) {
        desc[byIndex] = true;
      } else if (!mustSort) {
        by.splice(byIndex, 1);
        desc.splice(byIndex, 1);
      } else {
        desc[byIndex] = false;
      }
      if (!deepEqual(by, oldBy) || !deepEqual(desc, oldDesc)) {
        page = 1;
      }
      return { by, desc, page };
    },
    group(key) {
      const { by: groupBy, desc: groupDesc, page } = this.toggle(
        key,
        this.internalOptions.groupBy,
        this.internalOptions.groupDesc,
        this.internalOptions.page,
        true,
        false
      );
      this.updateOptions({ groupBy, groupDesc, page });
    },
    sort(key) {
      if (Array.isArray(key))
        return this.sortArray(key);
      const { by: sortBy, desc: sortDesc, page } = this.toggle(
        key,
        this.internalOptions.sortBy,
        this.internalOptions.sortDesc,
        this.internalOptions.page,
        this.internalOptions.mustSort,
        this.internalOptions.multiSort
      );
      this.updateOptions({ sortBy, sortDesc, page });
    },
    sortArray(sortBy) {
      const sortDesc = sortBy.map((s) => {
        const i = this.internalOptions.sortBy.findIndex((k) => k === s);
        return i > -1 ? this.internalOptions.sortDesc[i] : false;
      });
      this.updateOptions({ sortBy, sortDesc });
    },
    updateOptions(options) {
      this.internalOptions = {
        ...this.internalOptions,
        ...options,
        page: this.serverItemsLength < 0 ? Math.max(1, Math.min(options.page || this.internalOptions.page, this.pageCount)) : options.page || this.internalOptions.page
      };
    },
    sortItems(items) {
      let sortBy = [];
      let sortDesc = [];
      if (!this.disableSort) {
        sortBy = this.internalOptions.sortBy;
        sortDesc = this.internalOptions.sortDesc;
      }
      if (this.internalOptions.groupBy.length) {
        sortBy = [...this.internalOptions.groupBy, ...sortBy];
        sortDesc = [...this.internalOptions.groupDesc, ...sortDesc];
      }
      return this.customSort(items, sortBy, sortDesc, this.locale);
    },
    groupItems(items) {
      return this.customGroup(items, this.internalOptions.groupBy, this.internalOptions.groupDesc);
    },
    paginateItems(items) {
      if (this.serverItemsLength === -1 && items.length <= this.pageStart) {
        this.internalOptions.page = Math.max(1, Math.ceil(items.length / this.internalOptions.itemsPerPage)) || 1;
      }
      return items.slice(this.pageStart, this.pageStop);
    }
  },
  render() {
    return this.$scopedSlots.default && this.$scopedSlots.default(this.scopedProps);
  }
});
const VDataFooter = Vue.extend({
  name: "v-data-footer",
  props: {
    options: {
      type: Object,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [5, 10, 15, -1]
    },
    prevIcon: {
      type: String,
      default: "$prev"
    },
    nextIcon: {
      type: String,
      default: "$next"
    },
    firstIcon: {
      type: String,
      default: "$first"
    },
    lastIcon: {
      type: String,
      default: "$last"
    },
    itemsPerPageText: {
      type: String,
      default: "$vuetify.dataFooter.itemsPerPageText"
    },
    itemsPerPageAllText: {
      type: String,
      default: "$vuetify.dataFooter.itemsPerPageAll"
    },
    showFirstLastPage: Boolean,
    showCurrentPage: Boolean,
    disablePagination: Boolean,
    disableItemsPerPage: Boolean,
    pageText: {
      type: String,
      default: "$vuetify.dataFooter.pageText"
    }
  },
  computed: {
    disableNextPageIcon() {
      return this.options.itemsPerPage <= 0 || this.options.page * this.options.itemsPerPage >= this.pagination.itemsLength || this.pagination.pageStop < 0;
    },
    computedDataItemsPerPageOptions() {
      return this.itemsPerPageOptions.map((option) => {
        if (typeof option === "object")
          return option;
        else
          return this.genDataItemsPerPageOption(option);
      });
    }
  },
  methods: {
    updateOptions(obj) {
      this.$emit("update:options", Object.assign({}, this.options, obj));
    },
    onFirstPage() {
      this.updateOptions({ page: 1 });
    },
    onPreviousPage() {
      this.updateOptions({ page: this.options.page - 1 });
    },
    onNextPage() {
      this.updateOptions({ page: this.options.page + 1 });
    },
    onLastPage() {
      this.updateOptions({ page: this.pagination.pageCount });
    },
    onChangeItemsPerPage(itemsPerPage) {
      this.updateOptions({ itemsPerPage, page: 1 });
    },
    genDataItemsPerPageOption(option) {
      return {
        text: option === -1 ? this.$vuetify.lang.t(this.itemsPerPageAllText) : String(option),
        value: option
      };
    },
    genItemsPerPageSelect() {
      let value = this.options.itemsPerPage;
      const computedIPPO = this.computedDataItemsPerPageOptions;
      if (computedIPPO.length <= 1)
        return null;
      if (!computedIPPO.find((ippo) => ippo.value === value))
        value = computedIPPO[0];
      return this.$createElement("div", {
        staticClass: "v-data-footer__select"
      }, [
        this.$vuetify.lang.t(this.itemsPerPageText),
        this.$createElement(VSelect, {
          attrs: {
            "aria-label": this.$vuetify.lang.t(this.itemsPerPageText)
          },
          props: {
            disabled: this.disableItemsPerPage,
            items: computedIPPO,
            value,
            hideDetails: true,
            auto: true,
            minWidth: "75px"
          },
          on: {
            input: this.onChangeItemsPerPage
          }
        })
      ]);
    },
    genPaginationInfo() {
      let children = ["–"];
      const itemsLength = this.pagination.itemsLength;
      let pageStart = this.pagination.pageStart;
      let pageStop = this.pagination.pageStop;
      if (this.pagination.itemsLength && this.pagination.itemsPerPage) {
        pageStart = this.pagination.pageStart + 1;
        pageStop = itemsLength < this.pagination.pageStop || this.pagination.pageStop < 0 ? itemsLength : this.pagination.pageStop;
        children = this.$scopedSlots["page-text"] ? [this.$scopedSlots["page-text"]({ pageStart, pageStop, itemsLength })] : [this.$vuetify.lang.t(this.pageText, pageStart, pageStop, itemsLength)];
      } else if (this.$scopedSlots["page-text"]) {
        children = [this.$scopedSlots["page-text"]({ pageStart, pageStop, itemsLength })];
      }
      return this.$createElement("div", {
        class: "v-data-footer__pagination"
      }, children);
    },
    genIcon(click, disabled, label, icon) {
      return this.$createElement(VBtn, {
        props: {
          disabled: disabled || this.disablePagination,
          icon: true,
          text: true
          // dark: this.dark, // TODO: add mixin
          // light: this.light // TODO: add mixin
        },
        on: {
          click
        },
        attrs: {
          "aria-label": label
          // TODO: Localization
        }
      }, [this.$createElement(VIcon$1, icon)]);
    },
    genIcons() {
      const before = [];
      const after = [];
      before.push(this.genIcon(
        this.onPreviousPage,
        this.options.page === 1,
        this.$vuetify.lang.t("$vuetify.dataFooter.prevPage"),
        this.$vuetify.rtl ? this.nextIcon : this.prevIcon
      ));
      after.push(this.genIcon(
        this.onNextPage,
        this.disableNextPageIcon,
        this.$vuetify.lang.t("$vuetify.dataFooter.nextPage"),
        this.$vuetify.rtl ? this.prevIcon : this.nextIcon
      ));
      if (this.showFirstLastPage) {
        before.unshift(this.genIcon(
          this.onFirstPage,
          this.options.page === 1,
          this.$vuetify.lang.t("$vuetify.dataFooter.firstPage"),
          this.$vuetify.rtl ? this.lastIcon : this.firstIcon
        ));
        after.push(this.genIcon(
          this.onLastPage,
          this.options.page >= this.pagination.pageCount || this.options.itemsPerPage === -1,
          this.$vuetify.lang.t("$vuetify.dataFooter.lastPage"),
          this.$vuetify.rtl ? this.firstIcon : this.lastIcon
        ));
      }
      return [
        this.$createElement("div", {
          staticClass: "v-data-footer__icons-before"
        }, before),
        this.showCurrentPage && this.$createElement("span", [this.options.page.toString()]),
        this.$createElement("div", {
          staticClass: "v-data-footer__icons-after"
        }, after)
      ];
    }
  },
  render() {
    return this.$createElement("div", {
      staticClass: "v-data-footer"
    }, [
      getSlot(this, "prepend"),
      this.genItemsPerPageSelect(),
      this.genPaginationInfo(),
      this.genIcons()
    ]);
  }
});
const VDataIterator = mixins(
  Mobile,
  Themeable$1
).extend({
  name: "v-data-iterator",
  props: {
    ...VData.options.props,
    // TODO: filter out props not used
    itemKey: {
      type: String,
      default: "id"
    },
    value: {
      type: Array,
      default: () => []
    },
    singleSelect: Boolean,
    expanded: {
      type: Array,
      default: () => []
    },
    mobileBreakpoint: {
      ...Mobile.options.props.mobileBreakpoint,
      default: 600
    },
    singleExpand: Boolean,
    loading: [Boolean, String],
    noResultsText: {
      type: String,
      default: "$vuetify.dataIterator.noResultsText"
    },
    noDataText: {
      type: String,
      default: "$vuetify.noDataText"
    },
    loadingText: {
      type: String,
      default: "$vuetify.dataIterator.loadingText"
    },
    hideDefaultFooter: Boolean,
    footerProps: Object,
    selectableKey: {
      type: String,
      default: "isSelectable"
    }
  },
  data: () => ({
    selection: {},
    expansion: {},
    internalCurrentItems: [],
    shiftKeyDown: false,
    lastEntry: -1
  }),
  computed: {
    everyItem() {
      return !!this.selectableItems.length && this.selectableItems.every((i) => this.isSelected(i));
    },
    someItems() {
      return this.selectableItems.some((i) => this.isSelected(i));
    },
    sanitizedFooterProps() {
      return camelizeObjectKeys(this.footerProps);
    },
    selectableItems() {
      return this.internalCurrentItems.filter((item) => this.isSelectable(item));
    }
  },
  watch: {
    value: {
      handler(value) {
        this.selection = value.reduce((selection, item) => {
          selection[getObjectValueByPath(item, this.itemKey)] = item;
          return selection;
        }, {});
      },
      immediate: true
    },
    selection(value, old) {
      if (deepEqual(Object.keys(value), Object.keys(old)))
        return;
      this.$emit("input", Object.values(value));
    },
    expanded: {
      handler(value) {
        this.expansion = value.reduce((expansion, item) => {
          expansion[getObjectValueByPath(item, this.itemKey)] = true;
          return expansion;
        }, {});
      },
      immediate: true
    },
    expansion(value, old) {
      if (deepEqual(value, old))
        return;
      const keys2 = Object.keys(value).filter((k) => value[k]);
      const expanded = !keys2.length ? [] : this.items.filter((i) => keys2.includes(String(getObjectValueByPath(i, this.itemKey))));
      this.$emit("update:expanded", expanded);
    }
  },
  created() {
    const breakingProps = [
      ["disable-initial-sort", "sort-by"],
      ["filter", "custom-filter"],
      ["pagination", "options"],
      ["total-items", "server-items-length"],
      ["hide-actions", "hide-default-footer"],
      ["rows-per-page-items", "footer-props.items-per-page-options"],
      ["rows-per-page-text", "footer-props.items-per-page-text"],
      ["prev-icon", "footer-props.prev-icon"],
      ["next-icon", "footer-props.next-icon"]
    ];
    breakingProps.forEach(([original, replacement]) => {
      if (this.$attrs.hasOwnProperty(original))
        breaking(original, replacement, this);
    });
    const removedProps = [
      "expand",
      "content-class",
      "content-props",
      "content-tag"
    ];
    removedProps.forEach((prop) => {
      if (this.$attrs.hasOwnProperty(prop))
        removed(prop);
    });
  },
  mounted() {
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
  },
  methods: {
    onKeyDown(e) {
      this.shiftKeyDown = e.keyCode === keyCodes.shift || e.shiftKey;
    },
    onKeyUp(e) {
      if (e.keyCode === keyCodes.shift || !e.shiftKey) {
        this.shiftKeyDown = false;
      }
    },
    toggleSelectAll(value) {
      const selection = Object.assign({}, this.selection);
      for (let i = 0; i < this.selectableItems.length; i++) {
        const item = this.selectableItems[i];
        if (!this.isSelectable(item))
          continue;
        const key = getObjectValueByPath(item, this.itemKey);
        if (value)
          selection[key] = item;
        else
          delete selection[key];
      }
      this.selection = selection;
      this.$emit("toggle-select-all", { items: this.internalCurrentItems, value });
    },
    isSelectable(item) {
      return getObjectValueByPath(item, this.selectableKey) !== false;
    },
    isSelected(item) {
      return !!this.selection[getObjectValueByPath(item, this.itemKey)] || false;
    },
    select(item, value = true, emit = true) {
      if (!this.isSelectable(item))
        return;
      const selection = this.singleSelect ? {} : Object.assign({}, this.selection);
      const key = getObjectValueByPath(item, this.itemKey);
      if (value)
        selection[key] = item;
      else
        delete selection[key];
      const index = this.selectableItems.findIndex((x) => getObjectValueByPath(x, this.itemKey) === key);
      if (this.lastEntry === -1)
        this.lastEntry = index;
      else if (this.shiftKeyDown && !this.singleSelect && emit) {
        const lastEntryKey = getObjectValueByPath(this.selectableItems[this.lastEntry], this.itemKey);
        const lastEntryKeySelected = Object.keys(this.selection).includes(String(lastEntryKey));
        this.multipleSelect(lastEntryKeySelected, emit, selection, index);
      }
      this.lastEntry = index;
      if (this.singleSelect && emit) {
        const keys2 = Object.keys(this.selection);
        const old = keys2.length && getObjectValueByPath(this.selection[keys2[0]], this.itemKey);
        old && old !== key && this.$emit("item-selected", { item: this.selection[old], value: false });
      }
      this.selection = selection;
      emit && this.$emit("item-selected", { item, value });
    },
    multipleSelect(value = true, emit = true, selection, index) {
      const start = index < this.lastEntry ? index : this.lastEntry;
      const end = index < this.lastEntry ? this.lastEntry : index;
      for (let i = start; i <= end; i++) {
        const currentItem = this.selectableItems[i];
        const key = getObjectValueByPath(currentItem, this.itemKey);
        if (value)
          selection[key] = currentItem;
        else
          delete selection[key];
        emit && this.$emit("item-selected", { currentItem, value });
      }
    },
    isExpanded(item) {
      return this.expansion[getObjectValueByPath(item, this.itemKey)] || false;
    },
    expand(item, value = true) {
      const expansion = this.singleExpand ? {} : Object.assign({}, this.expansion);
      const key = getObjectValueByPath(item, this.itemKey);
      if (value)
        expansion[key] = true;
      else
        delete expansion[key];
      this.expansion = expansion;
      this.$emit("item-expanded", { item, value });
    },
    createItemProps(item, index) {
      return {
        item,
        index,
        select: (v) => this.select(item, v),
        isSelected: this.isSelected(item),
        expand: (v) => this.expand(item, v),
        isExpanded: this.isExpanded(item),
        isMobile: this.isMobile
      };
    },
    genEmptyWrapper(content) {
      return this.$createElement("div", content);
    },
    genEmpty(originalItemsLength, filteredItemsLength) {
      if (originalItemsLength === 0 && this.loading) {
        const loading = this.$slots.loading || this.$vuetify.lang.t(this.loadingText);
        return this.genEmptyWrapper(loading);
      } else if (originalItemsLength === 0) {
        const noData = this.$slots["no-data"] || this.$vuetify.lang.t(this.noDataText);
        return this.genEmptyWrapper(noData);
      } else if (filteredItemsLength === 0) {
        const noResults = this.$slots["no-results"] || this.$vuetify.lang.t(this.noResultsText);
        return this.genEmptyWrapper(noResults);
      }
      return null;
    },
    genItems(props2) {
      const empty = this.genEmpty(props2.originalItemsLength, props2.pagination.itemsLength);
      if (empty)
        return [empty];
      if (this.$scopedSlots.default) {
        return this.$scopedSlots.default({
          ...props2,
          isSelected: this.isSelected,
          select: this.select,
          isExpanded: this.isExpanded,
          isMobile: this.isMobile,
          expand: this.expand
        });
      }
      if (this.$scopedSlots.item) {
        return props2.items.map((item, index) => this.$scopedSlots.item(this.createItemProps(
          item,
          index
        )));
      }
      return [];
    },
    genFooter(props2) {
      if (this.hideDefaultFooter)
        return null;
      const data = {
        props: {
          ...this.sanitizedFooterProps,
          options: props2.options,
          pagination: props2.pagination
        },
        on: {
          "update:options": (value) => props2.updateOptions(value)
        }
      };
      const scopedSlots = getPrefixedScopedSlots("footer.", this.$scopedSlots);
      return this.$createElement(VDataFooter, {
        scopedSlots,
        ...data
      });
    },
    genDefaultScopedSlot(props2) {
      const outerProps = {
        ...props2,
        someItems: this.someItems,
        everyItem: this.everyItem,
        toggleSelectAll: this.toggleSelectAll
      };
      return this.$createElement("div", {
        staticClass: "v-data-iterator"
      }, [
        getSlot(this, "header", outerProps, true),
        this.genItems(props2),
        this.genFooter(props2),
        getSlot(this, "footer", outerProps, true)
      ]);
    }
  },
  render() {
    return this.$createElement(VData, {
      props: this.$props,
      on: {
        "update:options": (v, old) => !deepEqual(v, old) && this.$emit("update:options", v),
        "update:page": (v) => this.$emit("update:page", v),
        "update:items-per-page": (v) => this.$emit("update:items-per-page", v),
        "update:sort-by": (v) => this.$emit("update:sort-by", v),
        "update:sort-desc": (v) => this.$emit("update:sort-desc", v),
        "update:group-by": (v) => this.$emit("update:group-by", v),
        "update:group-desc": (v) => this.$emit("update:group-desc", v),
        pagination: (v, old) => !deepEqual(v, old) && this.$emit("pagination", v),
        "current-items": (v) => {
          this.internalCurrentItems = v;
          this.$emit("current-items", v);
        },
        "page-count": (v) => this.$emit("page-count", v)
      },
      scopedSlots: {
        default: this.genDefaultScopedSlot
      }
    });
  }
});
const header = mixins().extend({
  // https://github.com/vuejs/vue/issues/6872
  directives: {
    ripple
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      })
    },
    checkboxColor: String,
    sortIcon: {
      type: String,
      default: "$sort"
    },
    everyItem: Boolean,
    someItems: Boolean,
    showGroupBy: Boolean,
    singleSelect: Boolean,
    disableSort: Boolean
  },
  methods: {
    genSelectAll() {
      const data = {
        props: {
          value: this.everyItem,
          indeterminate: !this.everyItem && this.someItems,
          color: this.checkboxColor ?? ""
        },
        on: {
          input: (v) => this.$emit("toggle-select-all", v)
        }
      };
      if (this.$scopedSlots["data-table-select"]) {
        return this.$scopedSlots["data-table-select"](data);
      }
      return this.$createElement(VSimpleCheckbox, {
        staticClass: "v-data-table__checkbox",
        ...data
      });
    },
    genSortIcon() {
      return this.$createElement(VIcon$1, {
        staticClass: "v-data-table-header__icon",
        props: {
          size: 18
        }
      }, [this.sortIcon]);
    }
  }
});
const VDataTableHeaderMobile = mixins(header).extend({
  name: "v-data-table-header-mobile",
  props: {
    sortByText: {
      type: String,
      default: "$vuetify.dataTable.sortBy"
    }
  },
  methods: {
    genSortChip(props2) {
      const children = [props2.item.text];
      const sortIndex = this.options.sortBy.findIndex((k) => k === props2.item.value);
      const beingSorted = sortIndex >= 0;
      const isDesc = this.options.sortDesc[sortIndex];
      children.push(this.$createElement("div", {
        staticClass: "v-chip__close",
        class: {
          sortable: true,
          active: beingSorted,
          asc: beingSorted && !isDesc,
          desc: beingSorted && isDesc
        }
      }, [this.genSortIcon()]));
      return this.$createElement(VChip, {
        staticClass: "sortable",
        on: {
          click: (e) => {
            e.stopPropagation();
            this.$emit("sort", props2.item.value);
          }
        }
      }, children);
    },
    genSortSelect(items) {
      return this.$createElement(VSelect, {
        props: {
          label: this.$vuetify.lang.t(this.sortByText),
          items,
          hideDetails: true,
          multiple: this.options.multiSort,
          value: this.options.multiSort ? this.options.sortBy : this.options.sortBy[0],
          menuProps: { closeOnContentClick: true }
        },
        on: {
          change: (v) => this.$emit("sort", v)
        },
        scopedSlots: {
          selection: (props2) => this.genSortChip(props2)
        }
      });
    }
  },
  render(h) {
    const children = [];
    const header2 = this.headers.find((h2) => h2.value === "data-table-select");
    if (header2 && !this.singleSelect) {
      children.push(this.$createElement("div", {
        class: [
          "v-data-table-header-mobile__select",
          ...wrapInArray(header2.class)
        ],
        attrs: {
          width: header2.width
        }
      }, [this.genSelectAll()]));
    }
    const sortHeaders = this.headers.filter((h2) => h2.sortable !== false && h2.value !== "data-table-select").map((h2) => ({
      text: h2.text,
      value: h2.value
    }));
    if (!this.disableSort && sortHeaders.length) {
      children.push(this.genSortSelect(sortHeaders));
    }
    const th = children.length ? h("th", [h("div", { staticClass: "v-data-table-header-mobile__wrapper" }, children)]) : void 0;
    const tr = h("tr", [th]);
    return h("thead", {
      staticClass: "v-data-table-header v-data-table-header-mobile"
    }, [tr]);
  }
});
const VDataTableHeaderDesktop = mixins(header).extend({
  name: "v-data-table-header-desktop",
  methods: {
    genGroupByToggle(header2) {
      return this.$createElement("span", {
        on: {
          click: (e) => {
            e.stopPropagation();
            this.$emit("group", header2.value);
          }
        }
      }, ["group"]);
    },
    getAria(beingSorted, isDesc) {
      const $t = (key) => this.$vuetify.lang.t(`$vuetify.dataTable.ariaLabel.${key}`);
      let ariaSort = "none";
      let ariaLabel = [
        $t("sortNone"),
        $t("activateAscending")
      ];
      if (!beingSorted) {
        return { ariaSort, ariaLabel: ariaLabel.join(" ") };
      }
      if (isDesc) {
        ariaSort = "descending";
        ariaLabel = [
          $t("sortDescending"),
          $t(this.options.mustSort ? "activateAscending" : "activateNone")
        ];
      } else {
        ariaSort = "ascending";
        ariaLabel = [
          $t("sortAscending"),
          $t("activateDescending")
        ];
      }
      return { ariaSort, ariaLabel: ariaLabel.join(" ") };
    },
    genHeader(header2) {
      const data = {
        attrs: {
          role: "columnheader",
          scope: "col",
          "aria-label": header2.text || ""
        },
        style: {
          width: convertToUnit(header2.width),
          minWidth: convertToUnit(header2.width)
        },
        class: [
          `text-${header2.align || "start"}`,
          ...wrapInArray(header2.class),
          header2.divider && "v-data-table__divider"
        ],
        on: {}
      };
      const children = [];
      if (header2.value === "data-table-select" && !this.singleSelect) {
        return this.$createElement("th", data, [this.genSelectAll()]);
      }
      children.push(
        this.$scopedSlots.hasOwnProperty(header2.value) ? this.$scopedSlots[header2.value]({ header: header2 }) : this.$createElement("span", [header2.text])
      );
      if (!this.disableSort && (header2.sortable || !header2.hasOwnProperty("sortable"))) {
        data.on.click = () => this.$emit("sort", header2.value);
        const sortIndex = this.options.sortBy.findIndex((k) => k === header2.value);
        const beingSorted = sortIndex >= 0;
        const isDesc = this.options.sortDesc[sortIndex];
        data.class.push("sortable");
        const { ariaLabel, ariaSort } = this.getAria(beingSorted, isDesc);
        data.attrs["aria-label"] += `${header2.text ? ": " : ""}${ariaLabel}`;
        data.attrs["aria-sort"] = ariaSort;
        if (beingSorted) {
          data.class.push("active");
          data.class.push(isDesc ? "desc" : "asc");
        }
        if (header2.align === "end")
          children.unshift(this.genSortIcon());
        else
          children.push(this.genSortIcon());
        if (this.options.multiSort && beingSorted) {
          children.push(this.$createElement("span", { class: "v-data-table-header__sort-badge" }, [String(sortIndex + 1)]));
        }
      }
      if (this.showGroupBy && header2.groupable !== false)
        children.push(this.genGroupByToggle(header2));
      return this.$createElement("th", data, children);
    }
  },
  render() {
    return this.$createElement("thead", {
      staticClass: "v-data-table-header"
    }, [
      this.$createElement("tr", this.headers.map((header2) => this.genHeader(header2)))
    ]);
  }
});
function dedupeModelListeners(data) {
  if (data.model && data.on && data.on.input) {
    if (Array.isArray(data.on.input)) {
      const i = data.on.input.indexOf(data.model.callback);
      if (i > -1)
        data.on.input.splice(i, 1);
    } else {
      delete data.on.input;
    }
  }
}
function rebuildFunctionalSlots(slots, h) {
  const children = [];
  for (const slot in slots) {
    if (slots.hasOwnProperty(slot)) {
      children.push(h("template", { slot }, slots[slot]));
    }
  }
  return children;
}
const VDataTableHeader = Vue.extend({
  name: "v-data-table-header",
  functional: true,
  props: {
    ...header.options.props,
    mobile: Boolean
  },
  render(h, { props: props2, data, slots }) {
    dedupeModelListeners(data);
    const children = rebuildFunctionalSlots(slots(), h);
    data = mergeData(data, { props: props2 });
    if (props2.mobile) {
      return h(VDataTableHeaderMobile, data, children);
    } else {
      return h(VDataTableHeaderDesktop, data, children);
    }
  }
});
function needsTd(slot) {
  return slot.length !== 1 || !["td", "th"].includes(slot[0]?.tag);
}
const Row = Vue.extend({
  name: "row",
  functional: true,
  props: {
    headers: Array,
    index: Number,
    item: Object,
    rtl: Boolean
  },
  render(h, { props: props2, slots, data }) {
    const computedSlots = slots();
    const columns = props2.headers.map((header2) => {
      const children = [];
      const value = getObjectValueByPath(props2.item, header2.value);
      const slotName = header2.value;
      const scopedSlot = data.scopedSlots && data.scopedSlots.hasOwnProperty(slotName) && data.scopedSlots[slotName];
      const regularSlot = computedSlots.hasOwnProperty(slotName) && computedSlots[slotName];
      if (scopedSlot) {
        children.push(...wrapInArray(scopedSlot({
          item: props2.item,
          isMobile: false,
          header: header2,
          index: props2.index,
          value
        })));
      } else if (regularSlot) {
        children.push(...wrapInArray(regularSlot));
      } else {
        children.push(value == null ? value : String(value));
      }
      const textAlign = `text-${header2.align || "start"}`;
      return needsTd(children) ? h("td", {
        class: [
          textAlign,
          header2.cellClass,
          {
            "v-data-table__divider": header2.divider
          }
        ]
      }, children) : children;
    });
    return h("tr", data, columns);
  }
});
const RowGroup = Vue.extend({
  name: "row-group",
  functional: true,
  props: {
    value: {
      type: Boolean,
      default: true
    },
    headerClass: {
      type: String,
      default: "v-row-group__header"
    },
    contentClass: String,
    summaryClass: {
      type: String,
      default: "v-row-group__summary"
    }
  },
  render(h, { slots, props: props2 }) {
    const computedSlots = slots();
    const children = [];
    if (computedSlots["column.header"]) {
      children.push(h("tr", {
        staticClass: props2.headerClass
      }, computedSlots["column.header"]));
    } else if (computedSlots["row.header"]) {
      children.push(...computedSlots["row.header"]);
    }
    if (computedSlots["row.content"] && props2.value)
      children.push(...computedSlots["row.content"]);
    if (computedSlots["column.summary"]) {
      children.push(h("tr", {
        staticClass: props2.summaryClass
      }, computedSlots["column.summary"]));
    } else if (computedSlots["row.summary"]) {
      children.push(...computedSlots["row.summary"]);
    }
    return children;
  }
});
const VSimpleTable = mixins(Themeable$1).extend({
  name: "v-simple-table",
  props: {
    dense: Boolean,
    fixedHeader: Boolean,
    height: [Number, String]
  },
  computed: {
    classes() {
      return {
        "v-data-table--dense": this.dense,
        "v-data-table--fixed-height": !!this.height && !this.fixedHeader,
        "v-data-table--fixed-header": this.fixedHeader,
        "v-data-table--has-top": !!this.$slots.top,
        "v-data-table--has-bottom": !!this.$slots.bottom,
        ...this.themeClasses
      };
    }
  },
  methods: {
    genWrapper() {
      return this.$slots.wrapper || this.$createElement("div", {
        staticClass: "v-data-table__wrapper",
        style: {
          height: convertToUnit(this.height)
        }
      }, [
        this.$createElement("table", this.$slots.default)
      ]);
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-data-table",
      class: this.classes
    }, [
      this.$slots.top,
      this.genWrapper(),
      this.$slots.bottom
    ]);
  }
});
const MobileRow = Vue.extend({
  name: "row",
  functional: true,
  props: {
    headers: Array,
    hideDefaultHeader: Boolean,
    index: Number,
    item: Object,
    rtl: Boolean
  },
  render(h, { props: props2, slots, data }) {
    const computedSlots = slots();
    const columns = props2.headers.map((header2) => {
      const classes = {
        "v-data-table__mobile-row": true
      };
      const children = [];
      const value = getObjectValueByPath(props2.item, header2.value);
      const slotName = header2.value;
      const scopedSlot = data.scopedSlots && data.scopedSlots.hasOwnProperty(slotName) && data.scopedSlots[slotName];
      const regularSlot = computedSlots.hasOwnProperty(slotName) && computedSlots[slotName];
      if (scopedSlot) {
        children.push(scopedSlot({
          item: props2.item,
          isMobile: true,
          header: header2,
          index: props2.index,
          value
        }));
      } else if (regularSlot) {
        children.push(regularSlot);
      } else {
        children.push(value == null ? value : String(value));
      }
      const mobileRowChildren = [
        h("div", {
          staticClass: "v-data-table__mobile-row__cell"
        }, children)
      ];
      if (header2.value !== "dataTableSelect" && !props2.hideDefaultHeader) {
        mobileRowChildren.unshift(
          h("div", {
            staticClass: "v-data-table__mobile-row__header"
          }, [header2.text])
        );
      }
      return h("td", { class: classes }, mobileRowChildren);
    });
    return h("tr", { ...data, staticClass: "v-data-table__mobile-table-row" }, columns);
  }
});
function filterFn(item, search, filter) {
  return (header2) => {
    const value = getObjectValueByPath(item, header2.value);
    return header2.filter ? header2.filter(value, search, item) : filter(value, search, item);
  };
}
function searchTableItems(items, search, headersWithCustomFilters, headersWithoutCustomFilters, customFilter, filterMode) {
  search = typeof search === "string" ? search.trim() : null;
  if (filterMode === "union") {
    if (!(search && headersWithoutCustomFilters.length) && !headersWithCustomFilters.length)
      return items;
    return items.filter((item) => {
      if (headersWithCustomFilters.length && headersWithCustomFilters.every(filterFn(item, search, defaultFilter))) {
        return true;
      }
      return search && headersWithoutCustomFilters.some(filterFn(item, search, customFilter));
    });
  } else if (filterMode === "intersection") {
    return items.filter((item) => {
      const matchesColumnFilters = headersWithCustomFilters.every(filterFn(item, search, defaultFilter));
      const matchesSearchTerm = !search || headersWithoutCustomFilters.some(filterFn(item, search, customFilter));
      return matchesColumnFilters && matchesSearchTerm;
    });
  } else {
    return items;
  }
}
const VDataTable = mixins(
  VDataIterator,
  Loadable,
  Mouse
).extend({
  name: "v-data-table",
  // https://github.com/vuejs/vue/issues/6872
  directives: {
    ripple
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    showSelect: Boolean,
    checkboxColor: String,
    showExpand: Boolean,
    showGroupBy: Boolean,
    // TODO: Fix
    // virtualRows: Boolean,
    height: [Number, String],
    hideDefaultHeader: Boolean,
    caption: String,
    dense: Boolean,
    headerProps: Object,
    calculateWidths: Boolean,
    fixedHeader: Boolean,
    headersLength: Number,
    expandIcon: {
      type: String,
      default: "$expand"
    },
    customFilter: {
      type: Function,
      default: defaultFilter
    },
    filterMode: {
      type: String,
      default: "intersection"
    },
    itemClass: {
      type: [String, Function],
      default: () => ""
    },
    itemStyle: {
      type: [String, Function],
      default: () => ""
    },
    loaderHeight: {
      type: [Number, String],
      default: 4
    }
  },
  data() {
    return {
      internalGroupBy: [],
      openCache: {},
      widths: []
    };
  },
  computed: {
    computedHeaders() {
      if (!this.headers)
        return [];
      const headers = this.headers.filter((h) => h.value === void 0 || !this.internalGroupBy.find((v) => v === h.value));
      const defaultHeader = { text: "", sortable: false, width: "1px" };
      if (this.showSelect) {
        const index = headers.findIndex((h) => h.value === "data-table-select");
        if (index < 0)
          headers.unshift({ ...defaultHeader, value: "data-table-select" });
        else
          headers.splice(index, 1, { ...defaultHeader, ...headers[index] });
      }
      if (this.showExpand) {
        const index = headers.findIndex((h) => h.value === "data-table-expand");
        if (index < 0)
          headers.unshift({ ...defaultHeader, value: "data-table-expand" });
        else
          headers.splice(index, 1, { ...defaultHeader, ...headers[index] });
      }
      return headers;
    },
    colspanAttrs() {
      return this.isMobile ? void 0 : {
        colspan: this.headersLength || this.computedHeaders.length
      };
    },
    columnSorters() {
      return this.computedHeaders.reduce((acc, header2) => {
        if (header2.sort)
          acc[header2.value] = header2.sort;
        return acc;
      }, {});
    },
    headersWithCustomFilters() {
      return this.headers.filter((header2) => header2.filter && (!header2.hasOwnProperty("filterable") || header2.filterable === true));
    },
    headersWithoutCustomFilters() {
      return this.headers.filter((header2) => !header2.filter && (!header2.hasOwnProperty("filterable") || header2.filterable === true));
    },
    sanitizedHeaderProps() {
      return camelizeObjectKeys(this.headerProps);
    },
    computedItemsPerPage() {
      const itemsPerPage = this.options && this.options.itemsPerPage ? this.options.itemsPerPage : this.itemsPerPage;
      const itemsPerPageOptions = this.sanitizedFooterProps.itemsPerPageOptions;
      if (itemsPerPageOptions && !itemsPerPageOptions.find((item) => typeof item === "number" ? item === itemsPerPage : item.value === itemsPerPage)) {
        const firstOption = itemsPerPageOptions[0];
        return typeof firstOption === "object" ? firstOption.value : firstOption;
      }
      return itemsPerPage;
    },
    groupByText() {
      return this.headers?.find((header2) => header2.value === this.internalGroupBy?.[0])?.text ?? "";
    }
  },
  created() {
    const breakingProps = [
      ["sort-icon", "header-props.sort-icon"],
      ["hide-headers", "hide-default-header"],
      ["select-all", "show-select"]
    ];
    breakingProps.forEach(([original, replacement]) => {
      if (this.$attrs.hasOwnProperty(original))
        breaking(original, replacement, this);
    });
  },
  mounted() {
    if (this.calculateWidths) {
      window.addEventListener("resize", this.calcWidths);
      this.calcWidths();
    }
  },
  beforeDestroy() {
    if (this.calculateWidths) {
      window.removeEventListener("resize", this.calcWidths);
    }
  },
  methods: {
    calcWidths() {
      this.widths = Array.from(this.$el.querySelectorAll("th")).map((e) => e.clientWidth);
    },
    customFilterWithColumns(items, search) {
      return searchTableItems(
        items,
        search,
        this.headersWithCustomFilters,
        this.headersWithoutCustomFilters,
        this.customFilter,
        this.filterMode
      );
    },
    customSortWithHeaders(items, sortBy, sortDesc, locale) {
      return this.customSort(items, sortBy, sortDesc, locale, this.columnSorters);
    },
    createItemProps(item, index) {
      const data = {
        ...VDataIterator.options.methods.createItemProps.call(this, item, index),
        headers: this.computedHeaders
      };
      return {
        ...data,
        attrs: {
          class: {
            "v-data-table__selected": data.isSelected
          }
        },
        on: {
          ...this.getDefaultMouseEventHandlers(":row", () => data, true),
          // TODO: the first argument should be the event, and the second argument should be data,
          // but this is a breaking change so it's for v3
          click: (event) => this.$emit("click:row", item, data, event)
        }
      };
    },
    genCaption(props2) {
      if (this.caption)
        return [this.$createElement("caption", [this.caption])];
      return getSlot(this, "caption", props2, true);
    },
    genColgroup(props2) {
      return this.$createElement("colgroup", this.computedHeaders.map((header2) => {
        return this.$createElement("col", {
          class: {
            divider: header2.divider
          }
        });
      }));
    },
    genLoading() {
      const th = this.$createElement("th", {
        staticClass: "column",
        attrs: this.colspanAttrs
      }, [this.genProgress()]);
      const tr = this.$createElement("tr", {
        staticClass: "v-data-table__progress"
      }, [th]);
      return this.$createElement("thead", [tr]);
    },
    genHeaders(props2) {
      const data = {
        props: {
          ...this.sanitizedHeaderProps,
          headers: this.computedHeaders,
          options: props2.options,
          mobile: this.isMobile,
          showGroupBy: this.showGroupBy,
          checkboxColor: this.checkboxColor,
          someItems: this.someItems,
          everyItem: this.everyItem,
          singleSelect: this.singleSelect,
          disableSort: this.disableSort
        },
        on: {
          sort: props2.sort,
          group: props2.group,
          "toggle-select-all": this.toggleSelectAll
        }
      };
      const children = [getSlot(this, "header", {
        ...data,
        isMobile: this.isMobile
      })];
      if (!this.hideDefaultHeader) {
        const scopedSlots = getPrefixedScopedSlots("header.", this.$scopedSlots);
        children.push(this.$createElement(VDataTableHeader, {
          ...data,
          scopedSlots
        }));
      }
      if (this.loading)
        children.push(this.genLoading());
      return children;
    },
    genEmptyWrapper(content) {
      return this.$createElement("tr", {
        staticClass: "v-data-table__empty-wrapper"
      }, [
        this.$createElement("td", {
          attrs: this.colspanAttrs
        }, content)
      ]);
    },
    genItems(items, props2) {
      const empty = this.genEmpty(props2.originalItemsLength, props2.pagination.itemsLength);
      if (empty)
        return [empty];
      return props2.groupedItems ? this.genGroupedRows(props2.groupedItems, props2) : this.genRows(items, props2);
    },
    genGroupedRows(groupedItems, props2) {
      return groupedItems.map((group) => {
        if (!this.openCache.hasOwnProperty(group.name))
          this.$set(this.openCache, group.name, true);
        if (this.$scopedSlots.group) {
          return this.$scopedSlots.group({
            group: group.name,
            options: props2.options,
            isMobile: this.isMobile,
            items: group.items,
            headers: this.computedHeaders
          });
        } else {
          return this.genDefaultGroupedRow(group.name, group.items, props2);
        }
      });
    },
    genDefaultGroupedRow(group, items, props2) {
      const isOpen = !!this.openCache[group];
      const children = [
        this.$createElement("template", { slot: "row.content" }, this.genRows(items, props2))
      ];
      const toggleFn = () => this.$set(this.openCache, group, !this.openCache[group]);
      const removeFn = () => props2.updateOptions({ groupBy: [], groupDesc: [] });
      if (this.$scopedSlots["group.header"]) {
        children.unshift(this.$createElement("template", { slot: "column.header" }, [
          this.$scopedSlots["group.header"]({
            group,
            groupBy: props2.options.groupBy,
            isMobile: this.isMobile,
            items,
            headers: this.computedHeaders,
            isOpen,
            toggle: toggleFn,
            remove: removeFn
          })
        ]));
      } else {
        const toggle = this.$createElement(VBtn, {
          staticClass: "ma-0",
          props: {
            icon: true,
            small: true
          },
          on: {
            click: toggleFn
          }
        }, [this.$createElement(VIcon$1, [isOpen ? "$minus" : "$plus"])]);
        const remove = this.$createElement(VBtn, {
          staticClass: "ma-0",
          props: {
            icon: true,
            small: true
          },
          on: {
            click: removeFn
          }
        }, [this.$createElement(VIcon$1, ["$close"])]);
        const column2 = this.$createElement("td", {
          staticClass: "text-start",
          attrs: this.colspanAttrs
        }, [toggle, `${this.groupByText}: ${group}`, remove]);
        children.unshift(this.$createElement("template", { slot: "column.header" }, [column2]));
      }
      if (this.$scopedSlots["group.summary"]) {
        children.push(this.$createElement("template", { slot: "column.summary" }, [
          this.$scopedSlots["group.summary"]({
            group,
            groupBy: props2.options.groupBy,
            isMobile: this.isMobile,
            items,
            headers: this.computedHeaders,
            isOpen,
            toggle: toggleFn
          })
        ]));
      }
      return this.$createElement(RowGroup, {
        key: group,
        props: {
          value: isOpen
        }
      }, children);
    },
    genRows(items, props2) {
      return this.$scopedSlots.item ? this.genScopedRows(items, props2) : this.genDefaultRows(items, props2);
    },
    genScopedRows(items, props2) {
      const rows = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        rows.push(this.$scopedSlots.item({
          ...this.createItemProps(item, i),
          isMobile: this.isMobile
        }));
        if (this.isExpanded(item)) {
          rows.push(this.$scopedSlots["expanded-item"]({
            headers: this.computedHeaders,
            isMobile: this.isMobile,
            index: i,
            item
          }));
        }
      }
      return rows;
    },
    genDefaultRows(items, props2) {
      return this.$scopedSlots["expanded-item"] ? items.map((item, index) => this.genDefaultExpandedRow(item, index)) : items.map((item, index) => this.genDefaultSimpleRow(item, index));
    },
    genDefaultExpandedRow(item, index) {
      const isExpanded = this.isExpanded(item);
      const classes = {
        "v-data-table__expanded v-data-table__expanded__row": isExpanded
      };
      const headerRow = this.genDefaultSimpleRow(item, index, classes);
      const expandedRow = this.$createElement("tr", {
        staticClass: "v-data-table__expanded v-data-table__expanded__content"
      }, [this.$scopedSlots["expanded-item"]({
        headers: this.computedHeaders,
        isMobile: this.isMobile,
        item
      })]);
      return this.$createElement(RowGroup, {
        props: {
          value: isExpanded
        }
      }, [
        this.$createElement("template", { slot: "row.header" }, [headerRow]),
        this.$createElement("template", { slot: "row.content" }, [expandedRow])
      ]);
    },
    genDefaultSimpleRow(item, index, classes = {}) {
      const scopedSlots = getPrefixedScopedSlots("item.", this.$scopedSlots);
      const data = this.createItemProps(item, index);
      if (this.showSelect) {
        const slot = scopedSlots["data-table-select"];
        scopedSlots["data-table-select"] = slot ? () => slot({
          ...data,
          isMobile: this.isMobile
        }) : () => this.$createElement(VSimpleCheckbox, {
          staticClass: "v-data-table__checkbox",
          props: {
            value: data.isSelected,
            disabled: !this.isSelectable(item),
            color: this.checkboxColor ?? ""
          },
          on: {
            input: (val) => data.select(val)
          }
        });
      }
      if (this.showExpand) {
        const slot = scopedSlots["data-table-expand"];
        scopedSlots["data-table-expand"] = slot ? () => slot(data) : () => this.$createElement(VIcon$1, {
          staticClass: "v-data-table__expand-icon",
          class: {
            "v-data-table__expand-icon--active": data.isExpanded
          },
          on: {
            click: (e) => {
              e.stopPropagation();
              data.expand(!data.isExpanded);
            }
          }
        }, [this.expandIcon]);
      }
      return this.$createElement(this.isMobile ? MobileRow : Row, {
        key: getObjectValueByPath(item, this.itemKey),
        class: mergeClasses(
          { ...classes, "v-data-table__selected": data.isSelected },
          getPropertyFromItem(item, this.itemClass)
        ),
        style: mergeStyles({}, getPropertyFromItem(item, this.itemStyle)),
        props: {
          headers: this.computedHeaders,
          hideDefaultHeader: this.hideDefaultHeader,
          index,
          item,
          rtl: this.$vuetify.rtl
        },
        scopedSlots,
        on: data.on
      });
    },
    genBody(props2) {
      const data = {
        ...props2,
        expand: this.expand,
        headers: this.computedHeaders,
        isExpanded: this.isExpanded,
        isMobile: this.isMobile,
        isSelected: this.isSelected,
        select: this.select
      };
      if (this.$scopedSlots.body) {
        return this.$scopedSlots.body(data);
      }
      return this.$createElement("tbody", [
        getSlot(this, "body.prepend", data, true),
        this.genItems(props2.items, props2),
        getSlot(this, "body.append", data, true)
      ]);
    },
    genFoot(props2) {
      return this.$scopedSlots.foot?.(props2);
    },
    genFooters(props2) {
      const data = {
        props: {
          options: props2.options,
          pagination: props2.pagination,
          itemsPerPageText: "$vuetify.dataTable.itemsPerPageText",
          ...this.sanitizedFooterProps
        },
        on: {
          "update:options": (value) => props2.updateOptions(value)
        },
        widths: this.widths,
        headers: this.computedHeaders
      };
      const children = [
        getSlot(this, "footer", data, true)
      ];
      if (!this.hideDefaultFooter) {
        children.push(this.$createElement(VDataFooter, {
          ...data,
          scopedSlots: getPrefixedScopedSlots("footer.", this.$scopedSlots)
        }));
      }
      return children;
    },
    genDefaultScopedSlot(props2) {
      const simpleProps = {
        height: this.height,
        fixedHeader: this.fixedHeader,
        dense: this.dense
      };
      return this.$createElement(VSimpleTable, {
        props: simpleProps,
        class: {
          "v-data-table--mobile": this.isMobile,
          "v-data-table--selectable": this.showSelect
        }
      }, [
        this.proxySlot("top", getSlot(this, "top", {
          ...props2,
          isMobile: this.isMobile
        }, true)),
        this.genCaption(props2),
        this.genColgroup(props2),
        this.genHeaders(props2),
        this.genBody(props2),
        this.genFoot(props2),
        this.proxySlot("bottom", this.genFooters(props2))
      ]);
    },
    proxySlot(slot, content) {
      return this.$createElement("template", { slot }, content);
    }
  },
  render() {
    return this.$createElement(VData, {
      props: {
        ...this.$props,
        customFilter: this.customFilterWithColumns,
        customSort: this.customSortWithHeaders,
        itemsPerPage: this.computedItemsPerPage
      },
      on: {
        "update:options": (v, old) => {
          this.internalGroupBy = v.groupBy || [];
          !deepEqual(v, old) && this.$emit("update:options", v);
        },
        "update:page": (v) => this.$emit("update:page", v),
        "update:items-per-page": (v) => this.$emit("update:items-per-page", v),
        "update:sort-by": (v) => this.$emit("update:sort-by", v),
        "update:sort-desc": (v) => this.$emit("update:sort-desc", v),
        "update:group-by": (v) => this.$emit("update:group-by", v),
        "update:group-desc": (v) => this.$emit("update:group-desc", v),
        pagination: (v, old) => !deepEqual(v, old) && this.$emit("pagination", v),
        "current-items": (v) => {
          this.internalCurrentItems = v;
          this.$emit("current-items", v);
        },
        "page-count": (v) => this.$emit("page-count", v)
      },
      scopedSlots: {
        default: this.genDefaultScopedSlot
      }
    });
  }
});
const VEditDialog = mixins(Returnable, Themeable$1).extend({
  name: "v-edit-dialog",
  props: {
    cancelText: {
      default: "Cancel"
    },
    large: Boolean,
    eager: Boolean,
    persistent: Boolean,
    saveText: {
      default: "Save"
    },
    transition: {
      type: String,
      default: "slide-x-reverse-transition"
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  watch: {
    isActive(val) {
      if (val) {
        this.$emit("open");
        setTimeout(this.focus, 50);
      } else {
        this.$emit("close");
      }
    }
  },
  methods: {
    cancel() {
      this.isActive = false;
      this.$emit("cancel");
    },
    focus() {
      const input = this.$refs.content.querySelector("input");
      input && input.focus();
    },
    genButton(fn, text) {
      return this.$createElement(VBtn, {
        props: {
          text: true,
          color: "primary",
          light: true
        },
        on: { click: fn }
      }, text);
    },
    genActions() {
      return this.$createElement("div", {
        class: "v-small-dialog__actions"
      }, [
        this.genButton(this.cancel, this.cancelText),
        this.genButton(() => {
          this.save(this.returnValue);
          this.$emit("save");
        }, this.saveText)
      ]);
    },
    genContent() {
      return this.$createElement("div", {
        staticClass: "v-small-dialog__content",
        on: {
          keydown: (e) => {
            e.keyCode === keyCodes.esc && this.cancel();
            if (e.keyCode === keyCodes.enter) {
              this.save(this.returnValue);
              this.$emit("save");
            }
          }
        },
        ref: "content"
      }, [this.$slots.input]);
    }
  },
  render(h) {
    return h(VMenu, {
      staticClass: "v-small-dialog",
      class: this.themeClasses,
      props: {
        contentClass: "v-small-dialog__menu-content",
        transition: this.transition,
        origin: "top right",
        right: true,
        value: this.isActive,
        closeOnClick: !this.persistent,
        closeOnContentClick: false,
        eager: this.eager,
        light: this.light,
        dark: this.dark
      },
      on: {
        input: (val) => this.isActive = val
      },
      scopedSlots: {
        activator: ({ on }) => {
          return h("div", {
            staticClass: "v-small-dialog__activator",
            on
          }, [
            h("span", {
              staticClass: "v-small-dialog__activator__content"
            }, this.$slots.default)
          ]);
        }
      }
    }, [
      this.genContent(),
      this.large ? this.genActions() : null
    ]);
  }
});
const baseMixins$f = mixins(VSimpleTable);
const VVirtualTable = baseMixins$f.extend().extend({
  name: "v-virtual-table",
  props: {
    chunkSize: {
      type: Number,
      default: 25
    },
    headerHeight: {
      type: Number,
      default: 48
    },
    items: {
      type: Array,
      default: () => []
    },
    rowHeight: {
      type: Number,
      default: 48
    }
  },
  data: () => ({
    scrollTop: 0,
    oldChunk: 0,
    scrollDebounce: null,
    invalidateCache: false
  }),
  computed: {
    itemsLength() {
      return this.items.length;
    },
    totalHeight() {
      return this.itemsLength * this.rowHeight + this.headerHeight;
    },
    topIndex() {
      return Math.floor(this.scrollTop / this.rowHeight);
    },
    chunkIndex() {
      return Math.floor(this.topIndex / this.chunkSize);
    },
    startIndex() {
      return Math.max(0, this.chunkIndex * this.chunkSize - this.chunkSize);
    },
    offsetTop() {
      return Math.max(0, this.startIndex * this.rowHeight);
    },
    stopIndex() {
      return Math.min(this.startIndex + this.chunkSize * 3, this.itemsLength);
    },
    offsetBottom() {
      return Math.max(0, (this.itemsLength - this.stopIndex - this.startIndex) * this.rowHeight);
    }
  },
  watch: {
    chunkIndex(newValue, oldValue) {
      this.oldChunk = oldValue;
    },
    items() {
      this.cachedItems = null;
      this.$refs.table.scrollTop = 0;
    }
  },
  created() {
    this.cachedItems = null;
  },
  mounted() {
    this.scrollDebounce = debounce(this.onScroll, 50);
    this.$refs.table.addEventListener("scroll", this.scrollDebounce, { passive: true });
  },
  beforeDestroy() {
    this.$refs.table.removeEventListener("scroll", this.scrollDebounce);
  },
  methods: {
    createStyleHeight(height) {
      return {
        height: `${height}px`
      };
    },
    genBody() {
      if (this.cachedItems === null || this.chunkIndex !== this.oldChunk) {
        this.cachedItems = this.genItems();
        this.oldChunk = this.chunkIndex;
      }
      return this.$createElement("tbody", [
        this.$createElement("tr", { style: this.createStyleHeight(this.offsetTop) }),
        this.cachedItems,
        this.$createElement("tr", { style: this.createStyleHeight(this.offsetBottom) })
      ]);
    },
    genItems() {
      return this.$scopedSlots.items({ items: this.items.slice(this.startIndex, this.stopIndex) });
    },
    onScroll(e) {
      const target = e.target;
      this.scrollTop = target.scrollTop;
    },
    genTable() {
      return this.$createElement("div", {
        ref: "table",
        staticClass: "v-virtual-table__table"
      }, [
        this.$createElement("table", [
          this.$slots["body.before"],
          this.genBody(),
          this.$slots["body.after"]
        ])
      ]);
    },
    genWrapper() {
      return this.$createElement("div", {
        staticClass: "v-virtual-table__wrapper",
        style: {
          height: convertToUnit(this.height)
        }
      }, [
        this.genTable()
      ]);
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-data-table v-virtual-table",
      class: this.classes
    }, [
      this.$slots.top,
      this.genWrapper(),
      this.$slots.bottom
    ]);
  }
});
const VTableOverflow = createSimpleFunctional("v-table__overflow");
const PickerButton = mixins(
  Colorable
).extend({
  methods: {
    genPickerButton(prop, value, content, readonly = false, staticClass = "") {
      const active = this[prop] === value;
      const click = (event) => {
        event.stopPropagation();
        this.$emit(`update:${kebabCase(prop)}`, value);
      };
      return this.$createElement("div", {
        staticClass: `v-picker__title__btn ${staticClass}`.trim(),
        class: {
          "v-picker__title__btn--active": active,
          "v-picker__title__btn--readonly": readonly
        },
        on: active || readonly ? void 0 : { click }
      }, Array.isArray(content) ? content : [content]);
    }
  }
});
const VDatePickerTitle = mixins(
  PickerButton
  /* @vue/component */
).extend({
  name: "v-date-picker-title",
  props: {
    date: {
      type: String,
      default: ""
    },
    disabled: Boolean,
    readonly: Boolean,
    selectingYear: Boolean,
    value: {
      type: String
    },
    year: {
      type: [Number, String],
      default: ""
    },
    yearIcon: {
      type: String
    }
  },
  data: () => ({
    isReversing: false
  }),
  computed: {
    computedTransition() {
      return this.isReversing ? "picker-reverse-transition" : "picker-transition";
    }
  },
  watch: {
    value(val, prev) {
      this.isReversing = val < prev;
    }
  },
  methods: {
    genYearIcon() {
      return this.$createElement(VIcon$1, {
        props: {
          dark: true
        }
      }, this.yearIcon);
    },
    getYearBtn() {
      return this.genPickerButton("selectingYear", true, [
        String(this.year),
        this.yearIcon ? this.genYearIcon() : null
      ], false, "v-date-picker-title__year");
    },
    genTitleText() {
      return this.$createElement("transition", {
        props: {
          name: this.computedTransition
        }
      }, [
        this.$createElement("div", {
          domProps: { innerHTML: this.date || "&nbsp;" },
          key: this.value
        })
      ]);
    },
    genTitleDate() {
      return this.genPickerButton("selectingYear", false, [this.genTitleText()], false, "v-date-picker-title__date");
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-date-picker-title",
      class: {
        "v-date-picker-title--disabled": this.disabled
      }
    }, [
      this.getYearBtn(),
      this.genTitleDate()
    ]);
  }
});
function createItemTypeNativeListeners(instance, itemTypeSuffix, value) {
  return Object.keys(instance.$listeners).reduce((on, eventName) => {
    if (eventName.endsWith(itemTypeSuffix)) {
      on[eventName.slice(0, -itemTypeSuffix.length)] = (event) => instance.$emit(eventName, value, event);
    }
    return on;
  }, {});
}
function createItemTypeListeners(instance, itemTypeSuffix) {
  return Object.keys(instance.$listeners).reduce((on, eventName) => {
    if (eventName.endsWith(itemTypeSuffix)) {
      on[eventName] = instance.$listeners[eventName];
    }
    return on;
  }, {});
}
const padStart = (string, targetLength, padString) => {
  targetLength = targetLength >> 0;
  string = String(string);
  padString = String(padString);
  if (string.length > targetLength) {
    return String(string);
  }
  targetLength = targetLength - string.length;
  if (targetLength > padString.length) {
    padString += padString.repeat(targetLength / padString.length);
  }
  return padString.slice(0, targetLength) + String(string);
};
const pad = (n, length = 2) => padStart(n, length, "0");
function createNativeLocaleFormatter(locale, options, substrOptions = { start: 0, length: 0 }) {
  const makeIsoString = (dateString) => {
    const [year, month, date] = dateString.trim().split(" ")[0].split("-");
    return [pad(year, 4), pad(month || 1), pad(date || 1)].join("-");
  };
  try {
    const intlFormatter = new Intl.DateTimeFormat(locale || void 0, options);
    return (dateString) => intlFormatter.format(/* @__PURE__ */ new Date(`${makeIsoString(dateString)}T00:00:00+00:00`));
  } catch (e) {
    return substrOptions.start || substrOptions.length ? (dateString) => makeIsoString(dateString).substr(substrOptions.start || 0, substrOptions.length) : void 0;
  }
}
const monthChange = (value, sign) => {
  const [year, month] = value.split("-").map(Number);
  if (month + sign === 0) {
    return `${year - 1}-12`;
  } else if (month + sign === 13) {
    return `${year + 1}-01`;
  } else {
    return `${year}-${pad(month + sign)}`;
  }
};
const sanitizeDateString = (dateString, type2) => {
  const [year, month = 1, date = 1] = dateString.split("-");
  return `${year}-${pad(month)}-${pad(date)}`.substr(0, { date: 10, month: 7, year: 4 }[type2]);
};
const VDatePickerHeader = mixins(
  Colorable,
  Localable,
  Themeable$1
  /* @vue/component */
).extend({
  name: "v-date-picker-header",
  props: {
    disabled: Boolean,
    format: Function,
    min: String,
    max: String,
    nextAriaLabel: String,
    nextIcon: {
      type: String,
      default: "$next"
    },
    prevAriaLabel: String,
    prevIcon: {
      type: String,
      default: "$prev"
    },
    readonly: Boolean,
    value: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      isReversing: false
    };
  },
  computed: {
    formatter() {
      if (this.format) {
        return this.format;
      } else if (String(this.value).split("-")[1]) {
        return createNativeLocaleFormatter(this.currentLocale, { month: "long", year: "numeric", timeZone: "UTC" }, { length: 7 });
      } else {
        return createNativeLocaleFormatter(this.currentLocale, { year: "numeric", timeZone: "UTC" }, { length: 4 });
      }
    }
  },
  watch: {
    value(newVal, oldVal) {
      this.isReversing = newVal < oldVal;
    }
  },
  methods: {
    genBtn(change) {
      const ariaLabelId = change > 0 ? this.nextAriaLabel : this.prevAriaLabel;
      const ariaLabel = ariaLabelId ? this.$vuetify.lang.t(ariaLabelId) : void 0;
      const disabled = this.disabled || change < 0 && this.min && this.calculateChange(change) < this.min || change > 0 && this.max && this.calculateChange(change) > this.max;
      return this.$createElement(VBtn, {
        attrs: { "aria-label": ariaLabel },
        props: {
          dark: this.dark,
          disabled,
          icon: true,
          light: this.light
        },
        on: {
          click: (e) => {
            e.stopPropagation();
            this.$emit("input", this.calculateChange(change));
          }
        }
      }, [
        this.$createElement(VIcon$1, change < 0 === !this.$vuetify.rtl ? this.prevIcon : this.nextIcon)
      ]);
    },
    calculateChange(sign) {
      const [year, month] = String(this.value).split("-").map(Number);
      if (month == null) {
        return `${year + sign}`;
      } else {
        return monthChange(String(this.value), sign);
      }
    },
    genHeader() {
      const color = !this.disabled && (this.color || "accent");
      const header2 = this.$createElement("div", this.setTextColor(color, {
        key: String(this.value)
      }), [this.$createElement("button", {
        attrs: {
          type: "button"
        },
        on: {
          click: () => this.$emit("toggle")
        }
      }, [this.$slots.default || this.formatter(String(this.value))])]);
      const transition = this.$createElement("transition", {
        props: {
          name: this.isReversing === !this.$vuetify.rtl ? "tab-reverse-transition" : "tab-transition"
        }
      }, [header2]);
      return this.$createElement("div", {
        staticClass: "v-date-picker-header__value",
        class: {
          "v-date-picker-header__value--disabled": this.disabled
        }
      }, [transition]);
    }
  },
  render() {
    return this.$createElement("div", {
      staticClass: "v-date-picker-header",
      class: {
        "v-date-picker-header--disabled": this.disabled,
        ...this.themeClasses
      }
    }, [
      this.genBtn(-1),
      this.genHeader(),
      this.genBtn(1)
    ]);
  }
});
function isDateAllowed(date, min, max, allowedFn) {
  return (!allowedFn || allowedFn(date)) && (!min || date >= min.substr(0, 10)) && (!max || date <= max);
}
const DatePickerTable = mixins(
  Colorable,
  Localable,
  Themeable$1
  /* @vue/component */
).extend({
  directives: { Touch: Touch$1 },
  props: {
    allowedDates: Function,
    current: String,
    disabled: Boolean,
    format: Function,
    events: {
      type: [Array, Function, Object],
      default: () => null
    },
    eventColor: {
      type: [Array, Function, Object, String],
      default: () => "warning"
    },
    min: String,
    max: String,
    range: Boolean,
    readonly: Boolean,
    scrollable: Boolean,
    tableDate: {
      type: String,
      required: true
    },
    value: [String, Array]
  },
  data: () => ({
    isReversing: false,
    wheelThrottle: null
  }),
  computed: {
    computedTransition() {
      return this.isReversing === !this.$vuetify.rtl ? "tab-reverse-transition" : "tab-transition";
    },
    displayedMonth() {
      return Number(this.tableDate.split("-")[1]) - 1;
    },
    displayedYear() {
      return Number(this.tableDate.split("-")[0]);
    }
  },
  watch: {
    tableDate(newVal, oldVal) {
      this.isReversing = newVal < oldVal;
    }
  },
  mounted() {
    this.wheelThrottle = throttle(this.wheel, 250);
  },
  methods: {
    genButtonClasses(isAllowed, isFloating, isSelected, isCurrent, isFirst, isLast) {
      return {
        "v-size--default": !isFloating,
        "v-date-picker-table__current": isCurrent,
        "v-btn--active": isSelected,
        "v-btn--flat": !isAllowed || this.disabled,
        "v-btn--text": isSelected === isCurrent,
        "v-btn--rounded": isFloating,
        "v-btn--disabled": !isAllowed || this.disabled,
        "v-btn--outlined": isCurrent && !isSelected,
        "v-date-picker--first-in-range": isFirst,
        "v-date-picker--last-in-range": isLast,
        ...this.themeClasses
      };
    },
    genButtonEvents(value, isAllowed, mouseEventType) {
      if (this.disabled)
        return void 0;
      return mergeListeners({
        click: () => {
          if (isAllowed && !this.readonly)
            this.$emit("input", value);
        }
      }, createItemTypeNativeListeners(this, `:${mouseEventType}`, value));
    },
    genButton(value, isFloating, mouseEventType, formatter, isOtherMonth = false) {
      const isAllowed = isDateAllowed(value, this.min, this.max, this.allowedDates);
      const isSelected = this.isSelected(value) && isAllowed;
      const isCurrent = value === this.current;
      const setColor = isSelected ? this.setBackgroundColor : this.setTextColor;
      const color = (isSelected || isCurrent) && (this.color || "accent");
      let isFirst = false;
      let isLast = false;
      if (this.range && !!this.value && Array.isArray(this.value)) {
        isFirst = value === this.value[0];
        isLast = value === this.value[this.value.length - 1];
      }
      return this.$createElement("button", setColor(color, {
        staticClass: "v-btn",
        class: this.genButtonClasses(
          isAllowed && !isOtherMonth,
          isFloating,
          isSelected,
          isCurrent,
          isFirst,
          isLast
        ),
        attrs: {
          type: "button"
        },
        domProps: {
          disabled: this.disabled || !isAllowed || isOtherMonth
        },
        on: this.genButtonEvents(value, isAllowed, mouseEventType)
      }), [
        this.$createElement("div", {
          staticClass: "v-btn__content"
        }, [formatter(value)]),
        this.genEvents(value)
      ]);
    },
    getEventColors(date) {
      const arrayize = (v) => Array.isArray(v) ? v : [v];
      let eventData;
      let eventColors = [];
      if (Array.isArray(this.events)) {
        eventData = this.events.includes(date);
      } else if (this.events instanceof Function) {
        eventData = this.events(date) || false;
      } else if (this.events) {
        eventData = this.events[date] || false;
      } else {
        eventData = false;
      }
      if (!eventData) {
        return [];
      } else if (eventData !== true) {
        eventColors = arrayize(eventData);
      } else if (typeof this.eventColor === "string") {
        eventColors = [this.eventColor];
      } else if (typeof this.eventColor === "function") {
        eventColors = arrayize(this.eventColor(date));
      } else if (Array.isArray(this.eventColor)) {
        eventColors = this.eventColor;
      } else {
        eventColors = arrayize(this.eventColor[date]);
      }
      return eventColors.filter((v) => v);
    },
    genEvents(date) {
      const eventColors = this.getEventColors(date);
      return eventColors.length ? this.$createElement("div", {
        staticClass: "v-date-picker-table__events"
      }, eventColors.map((color) => this.$createElement("div", this.setBackgroundColor(color)))) : null;
    },
    isValidScroll(value, calculateTableDate) {
      const tableDate = calculateTableDate(value);
      const sanitizeType = tableDate.split("-").length === 1 ? "year" : "month";
      return value < 0 && (this.min ? tableDate >= sanitizeDateString(this.min, sanitizeType) : true) || value > 0 && (this.max ? tableDate <= sanitizeDateString(this.max, sanitizeType) : true);
    },
    wheel(e, calculateTableDate) {
      this.$emit("update:table-date", calculateTableDate(e.deltaY));
    },
    touch(value, calculateTableDate) {
      this.$emit("update:table-date", calculateTableDate(value));
    },
    genTable(staticClass, children, calculateTableDate) {
      const transition = this.$createElement("transition", {
        props: { name: this.computedTransition }
      }, [this.$createElement("table", { key: this.tableDate }, children)]);
      const touchDirective = {
        name: "touch",
        value: {
          left: (e) => e.offsetX < -15 && (this.isValidScroll(1, calculateTableDate) && this.touch(1, calculateTableDate)),
          right: (e) => e.offsetX > 15 && (this.isValidScroll(-1, calculateTableDate) && this.touch(-1, calculateTableDate))
        }
      };
      return this.$createElement("div", {
        staticClass,
        class: {
          "v-date-picker-table--disabled": this.disabled,
          ...this.themeClasses
        },
        on: !this.disabled && this.scrollable ? {
          wheel: (e) => {
            e.preventDefault();
            if (this.isValidScroll(e.deltaY, calculateTableDate)) {
              this.wheelThrottle(e, calculateTableDate);
            }
          }
        } : void 0,
        directives: [touchDirective]
      }, [transition]);
    },
    isSelected(value) {
      if (Array.isArray(this.value)) {
        if (this.range && this.value.length === 2) {
          const [from, to] = [...this.value].sort();
          return from <= value && value <= to;
        } else {
          return this.value.indexOf(value) !== -1;
        }
      }
      return value === this.value;
    }
  }
});
const VDatePickerDateTable = mixins(
  DatePickerTable
  /* @vue/component */
).extend({
  name: "v-date-picker-date-table",
  props: {
    firstDayOfWeek: {
      type: [String, Number],
      default: 0
    },
    localeFirstDayOfYear: {
      type: [String, Number],
      default: 0
    },
    showAdjacentMonths: Boolean,
    showWeek: Boolean,
    weekdayFormat: Function
  },
  computed: {
    formatter() {
      return this.format || createNativeLocaleFormatter(this.currentLocale, { day: "numeric", timeZone: "UTC" }, { start: 8, length: 2 });
    },
    weekdayFormatter() {
      return this.weekdayFormat || createNativeLocaleFormatter(this.currentLocale, { weekday: "narrow", timeZone: "UTC" });
    },
    weekDays() {
      const first = parseInt(this.firstDayOfWeek, 10);
      return this.weekdayFormatter ? createRange(7).map((i) => this.weekdayFormatter(`2017-01-${first + i + 15}`)) : createRange(7).map((i) => ["S", "M", "T", "W", "T", "F", "S"][(i + first) % 7]);
    }
  },
  methods: {
    calculateTableDate(delta2) {
      return monthChange(this.tableDate, Math.sign(delta2 || 1));
    },
    genTHead() {
      const days = this.weekDays.map((day) => this.$createElement("th", day));
      if (this.showWeek) {
        days.unshift(this.$createElement("th"));
      }
      return this.$createElement("thead", this.genTR(days));
    },
    // Returns number of the days from the firstDayOfWeek to the first day of the current month
    weekDaysBeforeFirstDayOfTheMonth() {
      const firstDayOfTheMonth = /* @__PURE__ */ new Date(`${this.displayedYear}-${pad(this.displayedMonth + 1)}-01T00:00:00+00:00`);
      const weekDay = firstDayOfTheMonth.getUTCDay();
      return (weekDay - parseInt(this.firstDayOfWeek) + 7) % 7;
    },
    getWeekNumber(dayInMonth) {
      return weekNumber(
        this.displayedYear,
        this.displayedMonth,
        dayInMonth,
        parseInt(this.firstDayOfWeek),
        parseInt(this.localeFirstDayOfYear)
      );
    },
    genWeekNumber(weekNumber2) {
      return this.$createElement("td", [
        this.$createElement("small", {
          staticClass: "v-date-picker-table--date__week"
        }, String(weekNumber2).padStart(2, "0"))
      ]);
    },
    // eslint-disable-next-line max-statements
    genTBody() {
      const children = [];
      const daysInMonth2 = new Date(this.displayedYear, this.displayedMonth + 1, 0).getDate();
      let rows = [];
      let day = this.weekDaysBeforeFirstDayOfTheMonth();
      if (this.showWeek) {
        rows.push(this.genWeekNumber(this.getWeekNumber(1)));
      }
      const prevMonthYear = this.displayedMonth ? this.displayedYear : this.displayedYear - 1;
      const prevMonth = (this.displayedMonth + 11) % 12;
      const firstDayFromPreviousMonth = new Date(this.displayedYear, this.displayedMonth, 0).getDate();
      const cellsInRow = this.showWeek ? 8 : 7;
      while (day--) {
        const date = `${prevMonthYear}-${pad(prevMonth + 1)}-${pad(firstDayFromPreviousMonth - day)}`;
        rows.push(this.$createElement("td", this.showAdjacentMonths ? [
          this.genButton(date, true, "date", this.formatter, true)
        ] : []));
      }
      for (day = 1; day <= daysInMonth2; day++) {
        const date = `${this.displayedYear}-${pad(this.displayedMonth + 1)}-${pad(day)}`;
        rows.push(this.$createElement("td", [
          this.genButton(date, true, "date", this.formatter)
        ]));
        if (rows.length % cellsInRow === 0) {
          children.push(this.genTR(rows));
          rows = [];
          if (this.showWeek && (day < daysInMonth2 || this.showAdjacentMonths)) {
            rows.push(this.genWeekNumber(this.getWeekNumber(day + 7)));
          }
        }
      }
      const nextMonthYear = this.displayedMonth === 11 ? this.displayedYear + 1 : this.displayedYear;
      const nextMonth = (this.displayedMonth + 1) % 12;
      let nextMonthDay = 1;
      while (rows.length < cellsInRow) {
        const date = `${nextMonthYear}-${pad(nextMonth + 1)}-${pad(nextMonthDay++)}`;
        rows.push(this.$createElement("td", this.showAdjacentMonths ? [
          this.genButton(date, true, "date", this.formatter, true)
        ] : []));
      }
      if (rows.length) {
        children.push(this.genTR(rows));
      }
      return this.$createElement("tbody", children);
    },
    genTR(children) {
      return [this.$createElement("tr", children)];
    }
  },
  render() {
    return this.genTable("v-date-picker-table v-date-picker-table--date", [
      this.genTHead(),
      this.genTBody()
    ], this.calculateTableDate);
  }
});
const VDatePickerMonthTable = mixins(
  DatePickerTable
  /* @vue/component */
).extend({
  name: "v-date-picker-month-table",
  computed: {
    formatter() {
      return this.format || createNativeLocaleFormatter(this.currentLocale, { month: "short", timeZone: "UTC" }, { start: 5, length: 2 });
    }
  },
  methods: {
    calculateTableDate(delta2) {
      return `${parseInt(this.tableDate, 10) + Math.sign(delta2 || 1)}`;
    },
    genTBody() {
      const children = [];
      const cols = Array(3).fill(null);
      const rows = 12 / cols.length;
      for (let row = 0; row < rows; row++) {
        const tds = cols.map((_, col) => {
          const month = row * cols.length + col;
          const date = `${this.displayedYear}-${pad(month + 1)}`;
          return this.$createElement("td", {
            key: month
          }, [
            this.genButton(date, false, "month", this.formatter)
          ]);
        });
        children.push(this.$createElement("tr", {
          key: row
        }, tds));
      }
      return this.$createElement("tbody", children);
    }
  },
  render() {
    return this.genTable("v-date-picker-table v-date-picker-table--month", [
      this.genTBody()
    ], this.calculateTableDate);
  }
});
const VDatePickerYears = mixins(
  Colorable,
  Localable
  /* @vue/component */
).extend({
  name: "v-date-picker-years",
  props: {
    format: Function,
    min: [Number, String],
    max: [Number, String],
    readonly: Boolean,
    value: [Number, String]
  },
  data() {
    return {
      defaultColor: "primary"
    };
  },
  computed: {
    formatter() {
      return this.format || createNativeLocaleFormatter(this.currentLocale, { year: "numeric", timeZone: "UTC" }, { length: 4 });
    }
  },
  mounted() {
    setTimeout(() => {
      const activeItem = this.$el.getElementsByClassName("active")[0];
      if (activeItem) {
        this.$el.scrollTop = activeItem.offsetTop - this.$el.offsetHeight / 2 + activeItem.offsetHeight / 2;
      } else if (this.min && !this.max) {
        this.$el.scrollTop = this.$el.scrollHeight;
      } else if (!this.min && this.max) {
        this.$el.scrollTop = 0;
      } else {
        this.$el.scrollTop = this.$el.scrollHeight / 2 - this.$el.offsetHeight / 2;
      }
    });
  },
  methods: {
    genYearItem(year) {
      const formatted = this.formatter(`${year}`);
      const active = parseInt(this.value, 10) === year;
      const color = active && (this.color || "primary");
      return this.$createElement("li", this.setTextColor(color, {
        key: year,
        class: { active },
        on: mergeListeners({
          click: () => this.$emit("input", year)
        }, createItemTypeNativeListeners(this, ":year", year))
      }), formatted);
    },
    genYearItems() {
      const children = [];
      const selectedYear = this.value ? parseInt(this.value, 10) : (/* @__PURE__ */ new Date()).getFullYear();
      const maxYear = this.max ? parseInt(this.max, 10) : selectedYear + 100;
      const minYear = Math.min(maxYear, this.min ? parseInt(this.min, 10) : selectedYear - 100);
      for (let year = maxYear; year >= minYear; year--) {
        children.push(this.genYearItem(year));
      }
      return children;
    }
  },
  render() {
    return this.$createElement("ul", {
      staticClass: "v-date-picker-years",
      ref: "years"
    }, this.genYearItems());
  }
});
const VPicker = mixins(
  Colorable,
  Elevatable,
  Themeable$1
).extend({
  name: "v-picker",
  props: {
    flat: Boolean,
    fullWidth: Boolean,
    landscape: Boolean,
    noTitle: Boolean,
    transition: {
      type: String,
      default: "fade-transition"
    },
    width: {
      type: [Number, String],
      default: 290
    }
  },
  computed: {
    computedTitleColor() {
      const defaultTitleColor = this.isDark ? false : this.color || "primary";
      return this.color || defaultTitleColor;
    }
  },
  methods: {
    genTitle() {
      return this.$createElement("div", this.setBackgroundColor(this.computedTitleColor, {
        staticClass: "v-picker__title",
        class: {
          "v-picker__title--landscape": this.landscape
        }
      }), this.$slots.title);
    },
    genBodyTransition() {
      return this.$createElement("transition", {
        props: {
          name: this.transition
        }
      }, this.$slots.default);
    },
    genBody() {
      return this.$createElement("div", {
        staticClass: "v-picker__body",
        class: {
          "v-picker__body--no-title": this.noTitle,
          ...this.themeClasses
        },
        style: this.fullWidth ? void 0 : {
          width: convertToUnit(this.width)
        }
      }, [
        this.genBodyTransition()
      ]);
    },
    genActions() {
      return this.$createElement("div", {
        staticClass: "v-picker__actions v-card__actions",
        class: {
          "v-picker__actions--no-title": this.noTitle
        }
      }, this.$slots.actions);
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-picker v-card",
      class: {
        "v-picker--flat": this.flat,
        "v-picker--landscape": this.landscape,
        "v-picker--full-width": this.fullWidth,
        ...this.themeClasses,
        ...this.elevationClasses
      }
    }, [
      this.$slots.title ? this.genTitle() : null,
      this.genBody(),
      this.$slots.actions ? this.genActions() : null
    ]);
  }
});
const Picker = mixins(
  Colorable,
  Elevatable,
  Themeable$1
  /* @vue/component */
).extend({
  name: "picker",
  props: {
    flat: Boolean,
    fullWidth: Boolean,
    headerColor: String,
    landscape: Boolean,
    noTitle: Boolean,
    width: {
      type: [Number, String],
      default: 290
    }
  },
  methods: {
    genPickerTitle() {
      return null;
    },
    genPickerBody() {
      return null;
    },
    genPickerActionsSlot() {
      return this.$scopedSlots.default ? this.$scopedSlots.default({
        save: this.save,
        cancel: this.cancel
      }) : this.$slots.default;
    },
    genPicker(staticClass) {
      const children = [];
      if (!this.noTitle) {
        const title = this.genPickerTitle();
        title && children.push(title);
      }
      const body = this.genPickerBody();
      body && children.push(body);
      children.push(this.$createElement("template", { slot: "actions" }, [this.genPickerActionsSlot()]));
      return this.$createElement(VPicker, {
        staticClass,
        props: {
          color: this.headerColor || this.color,
          dark: this.dark,
          elevation: this.elevation,
          flat: this.flat,
          fullWidth: this.fullWidth,
          landscape: this.landscape,
          light: this.light,
          width: this.width,
          noTitle: this.noTitle
        }
      }, children);
    }
  }
});
const VDatePicker = mixins(
  Localable,
  Picker
  /* @vue/component */
).extend({
  name: "v-date-picker",
  props: {
    activePicker: String,
    allowedDates: Function,
    // Function formatting the day in date picker table
    dayFormat: Function,
    disabled: Boolean,
    events: {
      type: [Array, Function, Object],
      default: () => null
    },
    eventColor: {
      type: [Array, Function, Object, String],
      default: () => "warning"
    },
    firstDayOfWeek: {
      type: [String, Number],
      default: 0
    },
    // Function formatting the tableDate in the day/month table header
    headerDateFormat: Function,
    localeFirstDayOfYear: {
      type: [String, Number],
      default: 0
    },
    max: String,
    min: String,
    // Function formatting month in the months table
    monthFormat: Function,
    multiple: Boolean,
    nextIcon: {
      type: String,
      default: "$next"
    },
    nextMonthAriaLabel: {
      type: String,
      default: "$vuetify.datePicker.nextMonthAriaLabel"
    },
    nextYearAriaLabel: {
      type: String,
      default: "$vuetify.datePicker.nextYearAriaLabel"
    },
    pickerDate: String,
    prevIcon: {
      type: String,
      default: "$prev"
    },
    prevMonthAriaLabel: {
      type: String,
      default: "$vuetify.datePicker.prevMonthAriaLabel"
    },
    prevYearAriaLabel: {
      type: String,
      default: "$vuetify.datePicker.prevYearAriaLabel"
    },
    range: Boolean,
    reactive: Boolean,
    readonly: Boolean,
    scrollable: Boolean,
    showCurrent: {
      type: [Boolean, String],
      default: true
    },
    selectedItemsText: {
      type: String,
      default: "$vuetify.datePicker.itemsSelected"
    },
    showAdjacentMonths: Boolean,
    showWeek: Boolean,
    // Function formatting currently selected date in the picker title
    titleDateFormat: Function,
    type: {
      type: String,
      default: "date",
      validator: (type2) => ["date", "month"].includes(type2)
      // TODO: year
    },
    value: [Array, String],
    weekdayFormat: Function,
    // Function formatting the year in table header and pickup title
    yearFormat: Function,
    yearIcon: String
  },
  data() {
    const now = /* @__PURE__ */ new Date();
    return {
      internalActivePicker: this.type.toUpperCase(),
      inputDay: null,
      inputMonth: null,
      inputYear: null,
      isReversing: false,
      now,
      // tableDate is a string in 'YYYY' / 'YYYY-M' format (leading zero for month is not required)
      tableDate: (() => {
        if (this.pickerDate) {
          return this.pickerDate;
        }
        const multipleValue = wrapInArray(this.value);
        const date = multipleValue[multipleValue.length - 1] || (typeof this.showCurrent === "string" ? this.showCurrent : `${now.getFullYear()}-${now.getMonth() + 1}`);
        return sanitizeDateString(date, this.type === "date" ? "month" : "year");
      })()
    };
  },
  computed: {
    multipleValue() {
      return wrapInArray(this.value);
    },
    isMultiple() {
      return this.multiple || this.range;
    },
    lastValue() {
      return this.isMultiple ? this.multipleValue[this.multipleValue.length - 1] : this.value;
    },
    selectedMonths() {
      if (!this.value || this.type === "month") {
        return this.value;
      } else if (this.isMultiple) {
        return this.multipleValue.map((val) => val.substr(0, 7));
      } else {
        return this.value.substr(0, 7);
      }
    },
    current() {
      if (this.showCurrent === true) {
        return sanitizeDateString(`${this.now.getFullYear()}-${this.now.getMonth() + 1}-${this.now.getDate()}`, this.type);
      }
      return this.showCurrent || null;
    },
    inputDate() {
      return this.type === "date" ? `${this.inputYear}-${pad(this.inputMonth + 1)}-${pad(this.inputDay)}` : `${this.inputYear}-${pad(this.inputMonth + 1)}`;
    },
    tableMonth() {
      return Number((this.pickerDate || this.tableDate).split("-")[1]) - 1;
    },
    tableYear() {
      return Number((this.pickerDate || this.tableDate).split("-")[0]);
    },
    minMonth() {
      return this.min ? sanitizeDateString(this.min, "month") : null;
    },
    maxMonth() {
      return this.max ? sanitizeDateString(this.max, "month") : null;
    },
    minYear() {
      return this.min ? sanitizeDateString(this.min, "year") : null;
    },
    maxYear() {
      return this.max ? sanitizeDateString(this.max, "year") : null;
    },
    formatters() {
      return {
        year: this.yearFormat || createNativeLocaleFormatter(this.currentLocale, { year: "numeric", timeZone: "UTC" }, { length: 4 }),
        titleDate: this.titleDateFormat || (this.isMultiple ? this.defaultTitleMultipleDateFormatter : this.defaultTitleDateFormatter)
      };
    },
    defaultTitleMultipleDateFormatter() {
      return (dates) => {
        if (!dates.length) {
          return "-";
        }
        if (dates.length === 1) {
          return this.defaultTitleDateFormatter(dates[0]);
        }
        return this.$vuetify.lang.t(this.selectedItemsText, dates.length);
      };
    },
    defaultTitleDateFormatter() {
      const titleFormats = {
        year: { year: "numeric", timeZone: "UTC" },
        month: { month: "long", timeZone: "UTC" },
        date: { weekday: "short", month: "short", day: "numeric", timeZone: "UTC" }
      };
      const titleDateFormatter = createNativeLocaleFormatter(this.currentLocale, titleFormats[this.type], {
        start: 0,
        length: { date: 10, month: 7, year: 4 }[this.type]
      });
      const landscapeFormatter = (date) => titleDateFormatter(date).replace(/([^\d\s])([\d])/g, (match, nonDigit, digit) => `${nonDigit} ${digit}`).replace(", ", ",<br>");
      return this.landscape ? landscapeFormatter : titleDateFormatter;
    }
  },
  watch: {
    internalActivePicker: {
      immediate: true,
      handler(val) {
        this.$emit("update:active-picker", val);
      }
    },
    activePicker(val) {
      this.internalActivePicker = val;
    },
    tableDate(val, prev) {
      const sanitizeType = this.type === "month" ? "year" : "month";
      this.isReversing = sanitizeDateString(val, sanitizeType) < sanitizeDateString(prev, sanitizeType);
      this.$emit("update:picker-date", val);
    },
    pickerDate(val) {
      if (val) {
        this.tableDate = val;
      } else if (this.lastValue && this.type === "date") {
        this.tableDate = sanitizeDateString(this.lastValue, "month");
      } else if (this.lastValue && this.type === "month") {
        this.tableDate = sanitizeDateString(this.lastValue, "year");
      }
    },
    value(newValue, oldValue) {
      this.checkMultipleProp();
      this.setInputDate();
      if (!this.isMultiple && this.value && !this.pickerDate || this.isMultiple && this.multipleValue.length && (!oldValue || !oldValue.length) && !this.pickerDate) {
        this.tableDate = sanitizeDateString(this.inputDate, this.type === "month" ? "year" : "month");
      }
    },
    type(type2) {
      this.internalActivePicker = type2.toUpperCase();
      if (this.value && this.value.length) {
        const output = this.multipleValue.map((val) => sanitizeDateString(val, type2)).filter(this.isDateAllowed);
        this.$emit("input", this.isMultiple ? output : output[0]);
      }
    }
  },
  created() {
    this.checkMultipleProp();
    if (this.pickerDate !== this.tableDate) {
      this.$emit("update:picker-date", this.tableDate);
    }
    this.setInputDate();
  },
  methods: {
    emitInput(newInput) {
      if (this.range) {
        if (this.multipleValue.length !== 1) {
          this.$emit("input", [newInput]);
        } else {
          const output2 = [this.multipleValue[0], newInput];
          this.$emit("input", output2);
          this.$emit("change", output2);
        }
        return;
      }
      const output = this.multiple ? this.multipleValue.indexOf(newInput) === -1 ? this.multipleValue.concat([newInput]) : this.multipleValue.filter((x) => x !== newInput) : newInput;
      this.$emit("input", output);
      this.multiple || this.$emit("change", newInput);
    },
    checkMultipleProp() {
      if (this.value == null)
        return;
      const valueType = this.value.constructor.name;
      const expected = this.isMultiple ? "Array" : "String";
      if (valueType !== expected) {
        consoleWarn(`Value must be ${this.isMultiple ? "an" : "a"} ${expected}, got ${valueType}`, this);
      }
    },
    isDateAllowed(value) {
      return isDateAllowed(value, this.min, this.max, this.allowedDates);
    },
    yearClick(value) {
      this.inputYear = value;
      if (this.type === "month") {
        this.tableDate = `${value}`;
      } else {
        this.tableDate = `${value}-${pad((this.tableMonth || 0) + 1)}`;
      }
      this.internalActivePicker = "MONTH";
      if (this.reactive && !this.readonly && !this.isMultiple && this.isDateAllowed(this.inputDate)) {
        this.$emit("input", this.inputDate);
      }
    },
    monthClick(value) {
      const [year, month] = value.split("-");
      this.inputYear = parseInt(year, 10);
      this.inputMonth = parseInt(month, 10) - 1;
      if (this.type === "date") {
        if (this.inputDay) {
          this.inputDay = Math.min(this.inputDay, daysInMonth(this.inputYear, this.inputMonth + 1));
        }
        this.tableDate = value;
        this.internalActivePicker = "DATE";
        if (this.reactive && !this.readonly && !this.isMultiple && this.isDateAllowed(this.inputDate)) {
          this.$emit("input", this.inputDate);
        }
      } else {
        this.emitInput(this.inputDate);
      }
    },
    dateClick(value) {
      const [year, month, day] = value.split("-");
      this.inputYear = parseInt(year, 10);
      this.inputMonth = parseInt(month, 10) - 1;
      this.inputDay = parseInt(day, 10);
      this.emitInput(this.inputDate);
    },
    genPickerTitle() {
      return this.$createElement(VDatePickerTitle, {
        props: {
          date: this.value ? this.formatters.titleDate(this.isMultiple ? this.multipleValue : this.value) : "",
          disabled: this.disabled,
          readonly: this.readonly,
          selectingYear: this.internalActivePicker === "YEAR",
          year: this.formatters.year(this.multipleValue.length ? `${this.inputYear}` : this.tableDate),
          yearIcon: this.yearIcon,
          value: this.multipleValue[0]
        },
        slot: "title",
        on: {
          "update:selecting-year": (value) => this.internalActivePicker = value ? "YEAR" : this.type.toUpperCase()
        }
      });
    },
    genTableHeader() {
      return this.$createElement(VDatePickerHeader, {
        props: {
          nextIcon: this.nextIcon,
          color: this.color,
          dark: this.dark,
          disabled: this.disabled,
          format: this.headerDateFormat,
          light: this.light,
          locale: this.locale,
          min: this.internalActivePicker === "DATE" ? this.minMonth : this.minYear,
          max: this.internalActivePicker === "DATE" ? this.maxMonth : this.maxYear,
          nextAriaLabel: this.internalActivePicker === "DATE" ? this.nextMonthAriaLabel : this.nextYearAriaLabel,
          prevAriaLabel: this.internalActivePicker === "DATE" ? this.prevMonthAriaLabel : this.prevYearAriaLabel,
          prevIcon: this.prevIcon,
          readonly: this.readonly,
          value: this.internalActivePicker === "DATE" ? `${pad(this.tableYear, 4)}-${pad(this.tableMonth + 1)}` : `${pad(this.tableYear, 4)}`
        },
        on: {
          toggle: () => this.internalActivePicker = this.internalActivePicker === "DATE" ? "MONTH" : "YEAR",
          input: (value) => this.tableDate = value
        }
      });
    },
    genDateTable() {
      return this.$createElement(VDatePickerDateTable, {
        props: {
          allowedDates: this.allowedDates,
          color: this.color,
          current: this.current,
          dark: this.dark,
          disabled: this.disabled,
          events: this.events,
          eventColor: this.eventColor,
          firstDayOfWeek: this.firstDayOfWeek,
          format: this.dayFormat,
          light: this.light,
          locale: this.locale,
          localeFirstDayOfYear: this.localeFirstDayOfYear,
          min: this.min,
          max: this.max,
          range: this.range,
          readonly: this.readonly,
          scrollable: this.scrollable,
          showAdjacentMonths: this.showAdjacentMonths,
          showWeek: this.showWeek,
          tableDate: `${pad(this.tableYear, 4)}-${pad(this.tableMonth + 1)}`,
          value: this.value,
          weekdayFormat: this.weekdayFormat
        },
        ref: "table",
        on: {
          input: this.dateClick,
          "update:table-date": (value) => this.tableDate = value,
          ...createItemTypeListeners(this, ":date")
        }
      });
    },
    genMonthTable() {
      return this.$createElement(VDatePickerMonthTable, {
        props: {
          allowedDates: this.type === "month" ? this.allowedDates : null,
          color: this.color,
          current: this.current ? sanitizeDateString(this.current, "month") : null,
          dark: this.dark,
          disabled: this.disabled,
          events: this.type === "month" ? this.events : null,
          eventColor: this.type === "month" ? this.eventColor : null,
          format: this.monthFormat,
          light: this.light,
          locale: this.locale,
          min: this.minMonth,
          max: this.maxMonth,
          range: this.range,
          readonly: this.readonly && this.type === "month",
          scrollable: this.scrollable,
          value: this.selectedMonths,
          tableDate: `${pad(this.tableYear, 4)}`
        },
        ref: "table",
        on: {
          input: this.monthClick,
          "update:table-date": (value) => this.tableDate = value,
          ...createItemTypeListeners(this, ":month")
        }
      });
    },
    genYears() {
      return this.$createElement(VDatePickerYears, {
        props: {
          color: this.color,
          format: this.yearFormat,
          locale: this.locale,
          min: this.minYear,
          max: this.maxYear,
          value: this.tableYear
        },
        on: {
          input: this.yearClick,
          ...createItemTypeListeners(this, ":year")
        }
      });
    },
    genPickerBody() {
      const children = this.internalActivePicker === "YEAR" ? [
        this.genYears()
      ] : [
        this.genTableHeader(),
        this.internalActivePicker === "DATE" ? this.genDateTable() : this.genMonthTable()
      ];
      return this.$createElement("div", {
        key: this.internalActivePicker
      }, children);
    },
    setInputDate() {
      if (this.lastValue) {
        const array = this.lastValue.split("-");
        this.inputYear = parseInt(array[0], 10);
        this.inputMonth = parseInt(array[1], 10) - 1;
        if (this.type === "date") {
          this.inputDay = parseInt(array[2], 10);
        }
      } else {
        this.inputYear = this.inputYear || this.now.getFullYear();
        this.inputMonth = this.inputMonth == null ? this.inputMonth : this.now.getMonth();
        this.inputDay = this.inputDay || this.now.getDate();
      }
    }
  },
  render() {
    return this.genPicker("v-picker--date");
  }
});
const VExpansionPanels = BaseItemGroup.extend({
  name: "v-expansion-panels",
  provide() {
    return {
      expansionPanels: this
    };
  },
  props: {
    accordion: Boolean,
    disabled: Boolean,
    flat: Boolean,
    hover: Boolean,
    focusable: Boolean,
    inset: Boolean,
    popout: Boolean,
    readonly: Boolean,
    tile: Boolean
  },
  computed: {
    classes() {
      return {
        ...BaseItemGroup.options.computed.classes.call(this),
        "v-expansion-panels": true,
        "v-expansion-panels--accordion": this.accordion,
        "v-expansion-panels--flat": this.flat,
        "v-expansion-panels--hover": this.hover,
        "v-expansion-panels--focusable": this.focusable,
        "v-expansion-panels--inset": this.inset,
        "v-expansion-panels--popout": this.popout,
        "v-expansion-panels--tile": this.tile
      };
    }
  },
  created() {
    if (this.$attrs.hasOwnProperty("expand")) {
      breaking("expand", "multiple", this);
    }
    if (Array.isArray(this.value) && this.value.length > 0 && typeof this.value[0] === "boolean") {
      breaking(':value="[true, false, true]"', ':value="[0, 2]"', this);
    }
  },
  methods: {
    updateItem(item, index) {
      const value = this.getValue(item, index);
      const nextValue = this.getValue(item, index + 1);
      item.isActive = this.toggleMethod(value);
      item.nextIsActive = this.toggleMethod(nextValue);
    }
  }
});
const VExpansionPanel = mixins(
  factory$1("expansionPanels", "v-expansion-panel", "v-expansion-panels"),
  provide("expansionPanel", true)
  /* @vue/component */
).extend({
  name: "v-expansion-panel",
  props: {
    disabled: Boolean,
    readonly: Boolean
  },
  data() {
    return {
      content: null,
      header: null,
      nextIsActive: false
    };
  },
  computed: {
    classes() {
      return {
        "v-expansion-panel--active": this.isActive,
        "v-expansion-panel--next-active": this.nextIsActive,
        "v-expansion-panel--disabled": this.isDisabled,
        ...this.groupClasses
      };
    },
    isDisabled() {
      return this.expansionPanels.disabled || this.disabled;
    },
    isReadonly() {
      return this.expansionPanels.readonly || this.readonly;
    }
  },
  methods: {
    registerContent(vm) {
      this.content = vm;
    },
    unregisterContent() {
      this.content = null;
    },
    registerHeader(vm) {
      this.header = vm;
      vm.$on("click", this.onClick);
    },
    unregisterHeader() {
      this.header = null;
    },
    onClick(e) {
      if (e.detail)
        this.header.$el.blur();
      this.$emit("click", e);
      this.isReadonly || this.isDisabled || this.toggle();
    },
    toggle() {
      this.$nextTick(() => this.$emit("change"));
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-expansion-panel",
      class: this.classes,
      attrs: {
        "aria-expanded": String(this.isActive)
      }
    }, getSlot(this));
  }
});
const baseMixins$e = mixins(
  Bootable,
  Colorable,
  inject("expansionPanel", "v-expansion-panel-content", "v-expansion-panel")
);
const VExpansionPanelContent = baseMixins$e.extend().extend({
  name: "v-expansion-panel-content",
  data: () => ({
    isActive: false
  }),
  computed: {
    parentIsActive() {
      return this.expansionPanel.isActive;
    }
  },
  watch: {
    parentIsActive: {
      immediate: true,
      handler(val, oldVal) {
        if (val)
          this.isBooted = true;
        if (oldVal == null)
          this.isActive = val;
        else
          this.$nextTick(() => this.isActive = val);
      }
    }
  },
  created() {
    this.expansionPanel.registerContent(this);
  },
  beforeDestroy() {
    this.expansionPanel.unregisterContent();
  },
  render(h) {
    return h(VExpandTransition, this.showLazyContent(() => [
      h("div", this.setBackgroundColor(this.color, {
        staticClass: "v-expansion-panel-content",
        directives: [{
          name: "show",
          value: this.isActive
        }]
      }), [
        h("div", { class: "v-expansion-panel-content__wrap" }, getSlot(this, "default", { open: this.isActive }))
      ])
    ]));
  }
});
const baseMixins$d = mixins(
  Colorable,
  inject("expansionPanel", "v-expansion-panel-header", "v-expansion-panel")
);
const VExpansionPanelHeader = baseMixins$d.extend().extend({
  name: "v-expansion-panel-header",
  directives: { ripple },
  props: {
    disableIconRotate: Boolean,
    expandIcon: {
      type: String,
      default: "$expand"
    },
    hideActions: Boolean,
    ripple: {
      type: [Boolean, Object],
      default: false
    }
  },
  data: () => ({
    hasMousedown: false
  }),
  computed: {
    classes() {
      return {
        "v-expansion-panel-header--active": this.isActive,
        "v-expansion-panel-header--mousedown": this.hasMousedown
      };
    },
    isActive() {
      return this.expansionPanel.isActive;
    },
    isDisabled() {
      return this.expansionPanel.isDisabled;
    },
    isReadonly() {
      return this.expansionPanel.isReadonly;
    }
  },
  created() {
    this.expansionPanel.registerHeader(this);
  },
  beforeDestroy() {
    this.expansionPanel.unregisterHeader();
  },
  methods: {
    onClick(e) {
      this.$emit("click", e);
    },
    genIcon() {
      const icon = getSlot(this, "actions", { open: this.isActive }) || [this.$createElement(VIcon$1, this.expandIcon)];
      return this.$createElement(VFadeTransition, [
        this.$createElement("div", {
          staticClass: "v-expansion-panel-header__icon",
          class: {
            "v-expansion-panel-header__icon--disable-rotate": this.disableIconRotate
          },
          directives: [{
            name: "show",
            value: !this.isDisabled
          }]
        }, icon)
      ]);
    }
  },
  render(h) {
    return h("button", this.setBackgroundColor(this.color, {
      staticClass: "v-expansion-panel-header",
      class: this.classes,
      attrs: {
        tabindex: this.isDisabled ? -1 : null,
        type: "button",
        "aria-expanded": this.isActive
      },
      directives: [{
        name: "ripple",
        value: this.ripple
      }],
      on: {
        ...this.$listeners,
        click: this.onClick,
        mousedown: () => this.hasMousedown = true,
        mouseup: () => this.hasMousedown = false
      }
    }), [
      getSlot(this, "default", { open: this.isActive }, true),
      this.hideActions || this.genIcon()
    ]);
  }
});
const VFileInput = VTextField.extend({
  name: "v-file-input",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    chips: Boolean,
    clearable: {
      type: Boolean,
      default: true
    },
    counterSizeString: {
      type: String,
      default: "$vuetify.fileInput.counterSize"
    },
    counterString: {
      type: String,
      default: "$vuetify.fileInput.counter"
    },
    hideInput: Boolean,
    multiple: Boolean,
    placeholder: String,
    prependIcon: {
      type: String,
      default: "$file"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showSize: {
      type: [Boolean, Number],
      default: false,
      validator: (v) => {
        return typeof v === "boolean" || [1e3, 1024].includes(v);
      }
    },
    smallChips: Boolean,
    truncateLength: {
      type: [Number, String],
      default: 22
    },
    type: {
      type: String,
      default: "file"
    },
    value: {
      default: void 0,
      validator: (val) => {
        return wrapInArray(val).every((v) => v != null && typeof v === "object");
      }
    }
  },
  computed: {
    classes() {
      return {
        ...VTextField.options.computed.classes.call(this),
        "v-file-input": true
      };
    },
    computedCounterValue() {
      const fileCount = this.multiple && this.lazyValue ? this.lazyValue.length : this.lazyValue instanceof File ? 1 : 0;
      if (!this.showSize)
        return this.$vuetify.lang.t(this.counterString, fileCount);
      const bytes = this.internalArrayValue.reduce((bytes2, { size = 0 }) => {
        return bytes2 + size;
      }, 0);
      return this.$vuetify.lang.t(
        this.counterSizeString,
        fileCount,
        humanReadableFileSize(bytes, this.base === 1024)
      );
    },
    internalArrayValue() {
      return wrapInArray(this.internalValue);
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(val) {
        this.lazyValue = val;
        this.$emit("change", this.lazyValue);
      }
    },
    isDirty() {
      return this.internalArrayValue.length > 0;
    },
    isLabelActive() {
      return this.isDirty;
    },
    text() {
      if (!this.isDirty && (this.persistentPlaceholder || this.isFocused || !this.hasLabel))
        return [this.placeholder];
      return this.internalArrayValue.map((file) => {
        const {
          name = "",
          size = 0
        } = file;
        const truncatedText = this.truncateText(name);
        return !this.showSize ? truncatedText : `${truncatedText} (${humanReadableFileSize(size, this.base === 1024)})`;
      });
    },
    base() {
      return typeof this.showSize !== "boolean" ? this.showSize : void 0;
    },
    hasChips() {
      return this.chips || this.smallChips;
    }
  },
  watch: {
    readonly: {
      handler(v) {
        if (v === true)
          consoleError("readonly is not supported on <v-file-input>", this);
      },
      immediate: true
    },
    value(v) {
      const value = this.multiple ? v : v ? [v] : [];
      if (!deepEqual(value, this.$refs.input.files)) {
        this.$refs.input.value = "";
      }
    }
  },
  methods: {
    clearableCallback() {
      this.internalValue = this.multiple ? [] : null;
      this.$refs.input.value = "";
    },
    genChips() {
      if (!this.isDirty)
        return [];
      return this.text.map((text, index) => this.$createElement(VChip, {
        props: { small: this.smallChips },
        on: {
          "click:close": () => {
            const internalValue = this.internalValue;
            internalValue.splice(index, 1);
            this.internalValue = internalValue;
          }
        }
      }, [text]));
    },
    genControl() {
      const render = VTextField.options.methods.genControl.call(this);
      if (this.hideInput) {
        render.data.style = mergeStyles(
          render.data.style,
          { display: "none" }
        );
      }
      return render;
    },
    genInput() {
      const input = VTextField.options.methods.genInput.call(this);
      input.data.attrs.multiple = this.multiple;
      delete input.data.domProps.value;
      delete input.data.on.input;
      input.data.on.change = this.onInput;
      return [this.genSelections(), input];
    },
    genPrependSlot() {
      if (!this.prependIcon)
        return null;
      const icon = this.genIcon("prepend", () => {
        this.$refs.input.click();
      });
      return this.genSlot("prepend", "outer", [icon]);
    },
    genSelectionText() {
      const length = this.text.length;
      if (length < 2)
        return this.text;
      if (this.showSize && !this.counter)
        return [this.computedCounterValue];
      return [this.$vuetify.lang.t(this.counterString, length)];
    },
    genSelections() {
      const children = [];
      if (this.isDirty && this.$scopedSlots.selection) {
        this.internalArrayValue.forEach((file, index) => {
          if (!this.$scopedSlots.selection)
            return;
          children.push(
            this.$scopedSlots.selection({
              text: this.text[index],
              file,
              index
            })
          );
        });
      } else {
        children.push(this.hasChips && this.isDirty ? this.genChips() : this.genSelectionText());
      }
      return this.$createElement("div", {
        staticClass: "v-file-input__text",
        class: {
          "v-file-input__text--placeholder": this.placeholder && !this.isDirty,
          "v-file-input__text--chips": this.hasChips && !this.$scopedSlots.selection
        }
      }, children);
    },
    genTextFieldSlot() {
      const node = VTextField.options.methods.genTextFieldSlot.call(this);
      node.data.on = {
        ...node.data.on || {},
        click: (e) => {
          if (e.target && e.target.nodeName === "LABEL")
            return;
          this.$refs.input.click();
        }
      };
      return node;
    },
    onInput(e) {
      const files = [...e.target.files || []];
      this.internalValue = this.multiple ? files : files[0];
      this.initialValue = this.internalValue;
    },
    onKeyDown(e) {
      this.$emit("keydown", e);
    },
    truncateText(str) {
      if (str.length < Number(this.truncateLength))
        return str;
      const charsKeepOneSide = Math.floor((Number(this.truncateLength) - 1) / 2);
      return `${str.slice(0, charsKeepOneSide)}…${str.slice(str.length - charsKeepOneSide)}`;
    }
  }
});
const VFooter = mixins(
  VSheet,
  applicationable("footer", [
    "height",
    "inset"
  ]),
  SSRBootable
).extend({
  name: "v-footer",
  props: {
    height: {
      default: "auto",
      type: [Number, String]
    },
    inset: Boolean,
    padless: Boolean,
    tag: {
      type: String,
      default: "footer"
    }
  },
  computed: {
    applicationProperty() {
      return this.inset ? "insetFooter" : "footer";
    },
    classes() {
      return {
        ...VSheet.options.computed.classes.call(this),
        "v-footer--absolute": this.absolute,
        "v-footer--fixed": !this.absolute && (this.app || this.fixed),
        "v-footer--padless": this.padless,
        "v-footer--inset": this.inset
      };
    },
    computedBottom() {
      if (!this.isPositioned)
        return void 0;
      return this.app ? this.$vuetify.application.bottom : 0;
    },
    computedLeft() {
      if (!this.isPositioned)
        return void 0;
      return this.app && this.inset ? this.$vuetify.application.left : 0;
    },
    computedRight() {
      if (!this.isPositioned)
        return void 0;
      return this.app && this.inset ? this.$vuetify.application.right : 0;
    },
    isPositioned() {
      return Boolean(
        this.absolute || this.fixed || this.app
      );
    },
    styles() {
      const height = parseInt(this.height);
      return {
        ...VSheet.options.computed.styles.call(this),
        height: isNaN(height) ? height : convertToUnit(height),
        left: convertToUnit(this.computedLeft),
        right: convertToUnit(this.computedRight),
        bottom: convertToUnit(this.computedBottom)
      };
    }
  },
  methods: {
    updateApplication() {
      const height = parseInt(this.height);
      return isNaN(height) ? this.$el ? this.$el.clientHeight : 0 : height;
    }
  },
  render(h) {
    const data = this.setBackgroundColor(this.color, {
      staticClass: "v-footer",
      class: this.classes,
      style: this.styles
    });
    return h(this.tag, data, this.$slots.default);
  }
});
const VForm = mixins(
  BindsAttrs,
  provide("form")
  /* @vue/component */
).extend({
  name: "v-form",
  provide() {
    return { form: this };
  },
  inheritAttrs: false,
  props: {
    disabled: Boolean,
    lazyValidation: Boolean,
    readonly: Boolean,
    value: Boolean
  },
  data: () => ({
    inputs: [],
    watchers: [],
    errorBag: {}
  }),
  watch: {
    errorBag: {
      handler(val) {
        const errors = Object.values(val).includes(true);
        this.$emit("input", !errors);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    watchInput(input) {
      const watcher = (input2) => {
        return input2.$watch("hasError", (val) => {
          this.$set(this.errorBag, input2._uid, val);
        }, { immediate: true });
      };
      const watchers = {
        _uid: input._uid,
        valid: () => {
        },
        shouldValidate: () => {
        }
      };
      if (this.lazyValidation) {
        watchers.shouldValidate = input.$watch("shouldValidate", (val) => {
          if (!val)
            return;
          if (this.errorBag.hasOwnProperty(input._uid))
            return;
          watchers.valid = watcher(input);
        });
      } else {
        watchers.valid = watcher(input);
      }
      return watchers;
    },
    /** @public */
    validate() {
      return this.inputs.filter((input) => !input.validate(true)).length === 0;
    },
    /** @public */
    reset() {
      this.inputs.forEach((input) => input.reset());
      this.resetErrorBag();
    },
    resetErrorBag() {
      if (this.lazyValidation) {
        setTimeout(() => {
          this.errorBag = {};
        }, 0);
      }
    },
    /** @public */
    resetValidation() {
      this.inputs.forEach((input) => input.resetValidation());
      this.resetErrorBag();
    },
    register(input) {
      this.inputs.push(input);
      this.watchers.push(this.watchInput(input));
    },
    unregister(input) {
      const found = this.inputs.find((i) => i._uid === input._uid);
      if (!found)
        return;
      const unwatch = this.watchers.find((i) => i._uid === found._uid);
      if (unwatch) {
        unwatch.valid();
        unwatch.shouldValidate();
      }
      this.watchers = this.watchers.filter((i) => i._uid !== found._uid);
      this.inputs = this.inputs.filter((i) => i._uid !== found._uid);
      this.$delete(this.errorBag, found._uid);
    }
  },
  render(h) {
    return h("form", {
      staticClass: "v-form",
      attrs: {
        novalidate: true,
        ...this.attrs$
      },
      on: {
        submit: (e) => this.$emit("submit", e)
      }
    }, this.$slots.default);
  }
});
function VGrid(name) {
  return Vue.extend({
    name: `v-${name}`,
    functional: true,
    props: {
      id: String,
      tag: {
        type: String,
        default: "div"
      }
    },
    render(h, { props: props2, data, children }) {
      data.staticClass = `${name} ${data.staticClass || ""}`.trim();
      const { attrs } = data;
      if (attrs) {
        data.attrs = {};
        const classes = Object.keys(attrs).filter((key) => {
          if (key === "slot")
            return false;
          const value = attrs[key];
          if (key.startsWith("data-")) {
            data.attrs[key] = value;
            return false;
          }
          return value || typeof value === "string";
        });
        if (classes.length)
          data.staticClass += ` ${classes.join(" ")}`;
      }
      if (props2.id) {
        data.domProps = data.domProps || {};
        data.domProps.id = props2.id;
      }
      return h(props2.tag, data, children);
    }
  });
}
const VContainer = VGrid("container").extend({
  name: "v-container",
  functional: true,
  props: {
    id: String,
    tag: {
      type: String,
      default: "div"
    },
    fluid: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props: props2, data, children }) {
    let classes;
    const { attrs } = data;
    if (attrs) {
      data.attrs = {};
      classes = Object.keys(attrs).filter((key) => {
        if (key === "slot")
          return false;
        const value = attrs[key];
        if (key.startsWith("data-")) {
          data.attrs[key] = value;
          return false;
        }
        return value || typeof value === "string";
      });
    }
    if (props2.id) {
      data.domProps = data.domProps || {};
      data.domProps.id = props2.id;
    }
    return h(
      props2.tag,
      mergeData(data, {
        staticClass: "container",
        class: Array({
          "container--fluid": props2.fluid
        }).concat(classes || [])
      }),
      children
    );
  }
});
const breakpoints$1 = ["sm", "md", "lg", "xl"];
const breakpointProps = (() => {
  return breakpoints$1.reduce((props2, val) => {
    props2[val] = {
      type: [Boolean, String, Number],
      default: false
    };
    return props2;
  }, {});
})();
const offsetProps = (() => {
  return breakpoints$1.reduce((props2, val) => {
    props2["offset" + upperFirst(val)] = {
      type: [String, Number],
      default: null
    };
    return props2;
  }, {});
})();
const orderProps = (() => {
  return breakpoints$1.reduce((props2, val) => {
    props2["order" + upperFirst(val)] = {
      type: [String, Number],
      default: null
    };
    return props2;
  }, {});
})();
const propMap$1 = {
  col: Object.keys(breakpointProps),
  offset: Object.keys(offsetProps),
  order: Object.keys(orderProps)
};
function breakpointClass$1(type2, prop, val) {
  let className = type2;
  if (val == null || val === false) {
    return void 0;
  }
  if (prop) {
    const breakpoint = prop.replace(type2, "");
    className += `-${breakpoint}`;
  }
  if (type2 === "col" && (val === "" || val === true)) {
    return className.toLowerCase();
  }
  className += `-${val}`;
  return className.toLowerCase();
}
const cache$1 = /* @__PURE__ */ new Map();
const VCol = Vue.extend({
  name: "v-col",
  functional: true,
  props: {
    cols: {
      type: [Boolean, String, Number],
      default: false
    },
    ...breakpointProps,
    offset: {
      type: [String, Number],
      default: null
    },
    ...offsetProps,
    order: {
      type: [String, Number],
      default: null
    },
    ...orderProps,
    alignSelf: {
      type: String,
      default: null,
      validator: (str) => ["auto", "start", "end", "center", "baseline", "stretch"].includes(str)
    },
    tag: {
      type: String,
      default: "div"
    }
  },
  render(h, { props: props2, data, children, parent }) {
    let cacheKey = "";
    for (const prop in props2) {
      cacheKey += String(props2[prop]);
    }
    let classList = cache$1.get(cacheKey);
    if (!classList) {
      classList = [];
      let type2;
      for (type2 in propMap$1) {
        propMap$1[type2].forEach((prop) => {
          const value = props2[prop];
          const className = breakpointClass$1(type2, prop, value);
          if (className)
            classList.push(className);
        });
      }
      const hasColClasses = classList.some((className) => className.startsWith("col-"));
      classList.push({
        // Default to .col if no other col-{bp}-* classes generated nor `cols` specified.
        col: !hasColClasses || !props2.cols,
        [`col-${props2.cols}`]: props2.cols,
        [`offset-${props2.offset}`]: props2.offset,
        [`order-${props2.order}`]: props2.order,
        [`align-self-${props2.alignSelf}`]: props2.alignSelf
      });
      cache$1.set(cacheKey, classList);
    }
    return h(props2.tag, mergeData(data, { class: classList }), children);
  }
});
const breakpoints = ["sm", "md", "lg", "xl"];
const ALIGNMENT = ["start", "end", "center"];
function makeProps(prefix, def) {
  return breakpoints.reduce((props2, val) => {
    props2[prefix + upperFirst(val)] = def();
    return props2;
  }, {});
}
const alignValidator = (str) => [...ALIGNMENT, "baseline", "stretch"].includes(str);
const alignProps = makeProps("align", () => ({
  type: String,
  default: null,
  validator: alignValidator
}));
const justifyValidator = (str) => [...ALIGNMENT, "space-between", "space-around"].includes(str);
const justifyProps = makeProps("justify", () => ({
  type: String,
  default: null,
  validator: justifyValidator
}));
const alignContentValidator = (str) => [...ALIGNMENT, "space-between", "space-around", "stretch"].includes(str);
const alignContentProps = makeProps("alignContent", () => ({
  type: String,
  default: null,
  validator: alignContentValidator
}));
const propMap = {
  align: Object.keys(alignProps),
  justify: Object.keys(justifyProps),
  alignContent: Object.keys(alignContentProps)
};
const classMap = {
  align: "align",
  justify: "justify",
  alignContent: "align-content"
};
function breakpointClass(type2, prop, val) {
  let className = classMap[type2];
  if (val == null) {
    return void 0;
  }
  if (prop) {
    const breakpoint = prop.replace(type2, "");
    className += `-${breakpoint}`;
  }
  className += `-${val}`;
  return className.toLowerCase();
}
const cache = /* @__PURE__ */ new Map();
const VRow = Vue.extend({
  name: "v-row",
  functional: true,
  props: {
    tag: {
      type: String,
      default: "div"
    },
    dense: Boolean,
    noGutters: Boolean,
    align: {
      type: String,
      default: null,
      validator: alignValidator
    },
    ...alignProps,
    justify: {
      type: String,
      default: null,
      validator: justifyValidator
    },
    ...justifyProps,
    alignContent: {
      type: String,
      default: null,
      validator: alignContentValidator
    },
    ...alignContentProps
  },
  render(h, { props: props2, data, children }) {
    let cacheKey = "";
    for (const prop in props2) {
      cacheKey += String(props2[prop]);
    }
    let classList = cache.get(cacheKey);
    if (!classList) {
      classList = [];
      let type2;
      for (type2 in propMap) {
        propMap[type2].forEach((prop) => {
          const value = props2[prop];
          const className = breakpointClass(type2, prop, value);
          if (className)
            classList.push(className);
        });
      }
      classList.push({
        "no-gutters": props2.noGutters,
        "row--dense": props2.dense,
        [`align-${props2.align}`]: props2.align,
        [`justify-${props2.justify}`]: props2.justify,
        [`align-content-${props2.alignContent}`]: props2.alignContent
      });
      cache.set(cacheKey, classList);
    }
    return h(
      props2.tag,
      mergeData(data, {
        staticClass: "row",
        class: classList
      }),
      children
    );
  }
});
const VSpacer = createSimpleFunctional("spacer", "div", "v-spacer");
const VLayout = VGrid("layout");
const VFlex = VGrid("flex");
const VHover = mixins(
  Delayable,
  Toggleable$1
  /* @vue/component */
).extend({
  name: "v-hover",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: void 0
    }
  },
  methods: {
    onMouseEnter() {
      this.runDelay("open");
    },
    onMouseLeave() {
      this.runDelay("close");
    }
  },
  render() {
    if (!this.$scopedSlots.default && this.value === void 0) {
      consoleWarn("v-hover is missing a default scopedSlot or bound value", this);
      return null;
    }
    let element;
    if (this.$scopedSlots.default) {
      element = this.$scopedSlots.default({ hover: this.isActive });
    }
    if (Array.isArray(element) && element.length === 1) {
      element = element[0];
    }
    if (!element || Array.isArray(element) || !element.tag) {
      consoleWarn("v-hover should only contain a single element", this);
      return element;
    }
    if (!this.disabled) {
      element.data = element.data || {};
      this._g(element.data, {
        mouseenter: this.onMouseEnter,
        mouseleave: this.onMouseLeave
      });
    }
    return element;
  }
});
const BaseItem = Vue.extend({
  props: {
    activeClass: String,
    value: {
      required: false
    },
    disabled: Boolean
  },
  data: () => ({
    isActive: false
  }),
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    }
  },
  render() {
    if (!this.$scopedSlots.default) {
      consoleWarn("v-item is missing a default scopedSlot", this);
      return null;
    }
    let element;
    if (this.$scopedSlots.default) {
      element = this.$scopedSlots.default({
        active: this.isActive,
        toggle: this.toggle
      });
    }
    if (Array.isArray(element) && element.length === 1) {
      element = element[0];
    }
    if (!element || Array.isArray(element) || !element.tag) {
      consoleWarn("v-item should only contain a single element", this);
      return element;
    }
    element.data = this._b(element.data || {}, element.tag, {
      class: {
        [this.activeClass]: this.isActive,
        "v-item--disabled": this.disabled
      }
    });
    if (this.disabled) {
      element.data.attrs = { ...element.data.attrs, tabindex: -1 };
    }
    return element;
  }
});
const VItem = mixins(
  BaseItem,
  factory$1("itemGroup", "v-item", "v-item-group")
).extend({
  name: "v-item"
});
const VLazy = mixins(
  Measurable,
  Toggleable$1
).extend({
  name: "VLazy",
  directives: { intersect },
  props: {
    options: {
      type: Object,
      // For more information on types, navigate to:
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      default: () => ({
        root: void 0,
        rootMargin: void 0,
        threshold: void 0
      })
    },
    tag: {
      type: String,
      default: "div"
    },
    transition: {
      type: String,
      default: "fade-transition"
    }
  },
  computed: {
    styles() {
      return {
        ...this.measurableStyles
      };
    }
  },
  methods: {
    genContent() {
      const children = this.isActive && getSlot(this);
      return this.transition ? this.$createElement("transition", {
        props: { name: this.transition }
      }, children) : children;
    },
    onObserve(entries, observer, isIntersecting) {
      if (this.isActive)
        return;
      this.isActive = isIntersecting;
    }
  },
  render(h) {
    return h(this.tag, {
      staticClass: "v-lazy",
      attrs: this.$attrs,
      directives: [{
        name: "intersect",
        value: {
          handler: this.onObserve,
          options: this.options
        }
      }],
      on: this.$listeners,
      style: this.styles
    }, [this.genContent()]);
  }
});
const baseMixins$c = mixins(
  applicationable("left", [
    "isActive",
    "isMobile",
    "miniVariant",
    "expandOnHover",
    "permanent",
    "right",
    "temporary",
    "width"
  ]),
  Colorable,
  Dependent,
  Mobile,
  Overlayable,
  SSRBootable,
  Themeable$1
);
const VNavigationDrawer = baseMixins$c.extend({
  name: "v-navigation-drawer",
  directives: {
    ClickOutside: ClickOutside$1,
    Resize: Resize$1,
    Touch: Touch$1
  },
  provide() {
    return {
      isInNav: this.tag === "nav"
    };
  },
  props: {
    bottom: Boolean,
    clipped: Boolean,
    disableResizeWatcher: Boolean,
    disableRouteWatcher: Boolean,
    expandOnHover: Boolean,
    floating: Boolean,
    height: {
      type: [Number, String],
      default() {
        return this.app ? "100vh" : "100%";
      }
    },
    miniVariant: Boolean,
    miniVariantWidth: {
      type: [Number, String],
      default: 56
    },
    permanent: Boolean,
    right: Boolean,
    src: {
      type: [String, Object],
      default: ""
    },
    stateless: Boolean,
    tag: {
      type: String,
      default() {
        return this.app ? "nav" : "aside";
      }
    },
    temporary: Boolean,
    touchless: Boolean,
    width: {
      type: [Number, String],
      default: 256
    },
    value: null
  },
  data: () => ({
    isMouseover: false,
    touchArea: {
      left: 0,
      right: 0
    },
    stackMinZIndex: 6
  }),
  computed: {
    /**
     * Used for setting an app value from a dynamic
     * property. Called from applicationable.js
     */
    applicationProperty() {
      return this.right ? "right" : "left";
    },
    classes() {
      return {
        "v-navigation-drawer": true,
        "v-navigation-drawer--absolute": this.absolute,
        "v-navigation-drawer--bottom": this.bottom,
        "v-navigation-drawer--clipped": this.clipped,
        "v-navigation-drawer--close": !this.isActive,
        "v-navigation-drawer--fixed": !this.absolute && (this.app || this.fixed),
        "v-navigation-drawer--floating": this.floating,
        "v-navigation-drawer--is-mobile": this.isMobile,
        "v-navigation-drawer--is-mouseover": this.isMouseover,
        "v-navigation-drawer--mini-variant": this.isMiniVariant,
        "v-navigation-drawer--custom-mini-variant": Number(this.miniVariantWidth) !== 56,
        "v-navigation-drawer--open": this.isActive,
        "v-navigation-drawer--open-on-hover": this.expandOnHover,
        "v-navigation-drawer--right": this.right,
        "v-navigation-drawer--temporary": this.temporary,
        ...this.themeClasses
      };
    },
    computedMaxHeight() {
      if (!this.hasApp)
        return null;
      const computedMaxHeight = this.$vuetify.application.bottom + this.$vuetify.application.footer + this.$vuetify.application.bar;
      if (!this.clipped)
        return computedMaxHeight;
      return computedMaxHeight + this.$vuetify.application.top;
    },
    computedTop() {
      if (!this.hasApp)
        return 0;
      let computedTop = this.$vuetify.application.bar;
      computedTop += this.clipped ? this.$vuetify.application.top : 0;
      return computedTop;
    },
    computedTransform() {
      if (this.isActive)
        return 0;
      if (this.isBottom)
        return 100;
      return this.right ? 100 : -100;
    },
    computedWidth() {
      return this.isMiniVariant ? this.miniVariantWidth : this.width;
    },
    hasApp() {
      return this.app && (!this.isMobile && !this.temporary);
    },
    isBottom() {
      return this.bottom && this.isMobile;
    },
    isMiniVariant() {
      return !this.expandOnHover && this.miniVariant || this.expandOnHover && !this.isMouseover;
    },
    isMobile() {
      return !this.stateless && !this.permanent && Mobile.options.computed.isMobile.call(this);
    },
    reactsToClick() {
      return !this.stateless && !this.permanent && (this.isMobile || this.temporary);
    },
    reactsToMobile() {
      return this.app && !this.disableResizeWatcher && !this.permanent && !this.stateless && !this.temporary;
    },
    reactsToResize() {
      return !this.disableResizeWatcher && !this.stateless;
    },
    reactsToRoute() {
      return !this.disableRouteWatcher && !this.stateless && (this.temporary || this.isMobile);
    },
    showOverlay() {
      return !this.hideOverlay && this.isActive && (this.isMobile || this.temporary);
    },
    styles() {
      const translate = this.isBottom ? "translateY" : "translateX";
      return {
        height: convertToUnit(this.height),
        top: !this.isBottom ? convertToUnit(this.computedTop) : "auto",
        maxHeight: this.computedMaxHeight != null ? `calc(100% - ${convertToUnit(this.computedMaxHeight)})` : void 0,
        transform: `${translate}(${convertToUnit(this.computedTransform, "%")})`,
        width: convertToUnit(this.computedWidth)
      };
    }
  },
  watch: {
    $route: "onRouteChange",
    isActive(val) {
      this.$emit("input", val);
    },
    /**
     * When mobile changes, adjust the active state
     * only when there has been a previous value
     */
    isMobile(val, prev) {
      !val && this.isActive && !this.temporary && this.removeOverlay();
      if (prev == null || !this.reactsToResize || !this.reactsToMobile)
        return;
      this.isActive = !val;
    },
    permanent(val) {
      if (val)
        this.isActive = true;
    },
    showOverlay(val) {
      if (val)
        this.genOverlay();
      else
        this.removeOverlay();
    },
    value(val) {
      if (this.permanent)
        return;
      if (val == null) {
        this.init();
        return;
      }
      if (val !== this.isActive)
        this.isActive = val;
    },
    expandOnHover: "updateMiniVariant",
    isMouseover(val) {
      this.updateMiniVariant(!val);
    }
  },
  beforeMount() {
    this.init();
  },
  methods: {
    calculateTouchArea() {
      const parent = this.$el.parentNode;
      if (!parent)
        return;
      const parentRect = parent.getBoundingClientRect();
      this.touchArea = {
        left: parentRect.left + 50,
        right: parentRect.right - 50
      };
    },
    closeConditional() {
      return this.isActive && !this._isDestroyed && this.reactsToClick;
    },
    genAppend() {
      return this.genPosition("append");
    },
    genBackground() {
      const props2 = {
        height: "100%",
        width: "100%",
        src: this.src
      };
      const image = this.$scopedSlots.img ? this.$scopedSlots.img(props2) : this.$createElement(VImg, { props: props2 });
      return this.$createElement("div", {
        staticClass: "v-navigation-drawer__image"
      }, [image]);
    },
    genDirectives() {
      const directives = [{
        name: "click-outside",
        value: {
          handler: () => {
            this.isActive = false;
          },
          closeConditional: this.closeConditional,
          include: this.getOpenDependentElements
        }
      }];
      if (!this.touchless && !this.stateless) {
        directives.push({
          name: "touch",
          value: {
            parent: true,
            left: this.swipeLeft,
            right: this.swipeRight
          }
        });
      }
      return directives;
    },
    genListeners() {
      const on = {
        mouseenter: () => this.isMouseover = true,
        mouseleave: () => this.isMouseover = false,
        transitionend: (e) => {
          if (e.target !== e.currentTarget)
            return;
          this.$emit("transitionend", e);
          const resizeEvent = document.createEvent("UIEvents");
          resizeEvent.initUIEvent("resize", true, false, window, 0);
          window.dispatchEvent(resizeEvent);
        }
      };
      if (this.miniVariant) {
        on.click = () => this.$emit("update:mini-variant", false);
      }
      return on;
    },
    genPosition(name) {
      const slot = getSlot(this, name);
      if (!slot)
        return slot;
      return this.$createElement("div", {
        staticClass: `v-navigation-drawer__${name}`
      }, slot);
    },
    genPrepend() {
      return this.genPosition("prepend");
    },
    genContent() {
      return this.$createElement("div", {
        staticClass: "v-navigation-drawer__content"
      }, this.$slots.default);
    },
    genBorder() {
      return this.$createElement("div", {
        staticClass: "v-navigation-drawer__border"
      });
    },
    init() {
      if (this.permanent) {
        this.isActive = true;
      } else if (this.stateless || this.value != null) {
        this.isActive = this.value;
      } else if (!this.temporary) {
        this.isActive = !this.isMobile;
      }
    },
    onRouteChange() {
      if (this.reactsToRoute && this.closeConditional()) {
        this.isActive = false;
      }
    },
    swipeLeft(e) {
      if (this.isActive && this.right)
        return;
      this.calculateTouchArea();
      if (Math.abs(e.touchendX - e.touchstartX) < 100)
        return;
      if (this.right && e.touchstartX >= this.touchArea.right)
        this.isActive = true;
      else if (!this.right && this.isActive)
        this.isActive = false;
    },
    swipeRight(e) {
      if (this.isActive && !this.right)
        return;
      this.calculateTouchArea();
      if (Math.abs(e.touchendX - e.touchstartX) < 100)
        return;
      if (!this.right && e.touchstartX <= this.touchArea.left)
        this.isActive = true;
      else if (this.right && this.isActive)
        this.isActive = false;
    },
    /**
     * Update the application layout
     */
    updateApplication() {
      if (!this.isActive || this.isMobile || this.temporary || !this.$el)
        return 0;
      const width = Number(this.miniVariant ? this.miniVariantWidth : this.width);
      return isNaN(width) ? this.$el.clientWidth : width;
    },
    updateMiniVariant(val) {
      if (this.expandOnHover && this.miniVariant !== val)
        this.$emit("update:mini-variant", val);
    }
  },
  render(h) {
    const children = [
      this.genPrepend(),
      this.genContent(),
      this.genAppend(),
      this.genBorder()
    ];
    if (this.src || getSlot(this, "img"))
      children.unshift(this.genBackground());
    return h(this.tag, this.setBackgroundColor(this.color, {
      class: this.classes,
      style: this.styles,
      directives: this.genDirectives(),
      on: this.genListeners()
    }), children);
  }
});
const baseMixins$b = mixins(
  VInput
);
const VOtpInput = baseMixins$b.extend().extend({
  name: "v-otp-input",
  directives: {
    ripple
  },
  inheritAttrs: false,
  props: {
    length: {
      type: [Number, String],
      default: 6
    },
    type: {
      type: String,
      default: "text"
    },
    plain: Boolean
  },
  data: () => ({
    initialValue: null,
    isBooted: false,
    otp: []
  }),
  computed: {
    outlined() {
      return !this.plain;
    },
    classes() {
      return {
        ...VInput.options.computed.classes.call(this),
        ...VTextField.options.computed.classes.call(this),
        "v-otp-input--plain": this.plain
      };
    }
  },
  watch: {
    isFocused: "updateValue",
    value(val) {
      this.lazyValue = val;
      this.otp = val?.split("") || [];
    }
  },
  created() {
    if (this.$attrs.hasOwnProperty("browser-autocomplete")) {
      breaking("browser-autocomplete", "autocomplete", this);
    }
    this.otp = this.internalValue?.split("") || [];
  },
  mounted() {
    requestAnimationFrame(() => this.isBooted = true);
  },
  methods: {
    /** @public */
    focus(e, otpIdx) {
      this.onFocus(e, otpIdx || 0);
    },
    genInputSlot(otpIdx) {
      return this.$createElement("div", this.setBackgroundColor(this.backgroundColor, {
        staticClass: "v-input__slot",
        style: { height: convertToUnit(this.height) },
        on: {
          click: () => this.onClick(otpIdx),
          mousedown: (e) => this.onMouseDown(e, otpIdx),
          mouseup: (e) => this.onMouseUp(e, otpIdx)
        }
      }), [this.genDefaultSlot(otpIdx)]);
    },
    genControl(otpIdx) {
      return this.$createElement("div", {
        staticClass: "v-input__control"
      }, [
        this.genInputSlot(otpIdx)
      ]);
    },
    genDefaultSlot(otpIdx) {
      return [
        this.genFieldset(),
        this.genTextFieldSlot(otpIdx)
      ];
    },
    genContent() {
      return Array.from({ length: +this.length }, (_, i) => {
        return this.$createElement("div", this.setTextColor(this.validationState, {
          staticClass: "v-input",
          class: this.classes
        }), [this.genControl(i)]);
      });
    },
    genFieldset() {
      return this.$createElement("fieldset", {
        attrs: {
          "aria-hidden": true
        }
      }, [this.genLegend()]);
    },
    genLegend() {
      const span = this.$createElement("span", {
        domProps: { innerHTML: "&#8203;" }
      });
      return this.$createElement("legend", {
        style: {
          width: "0px"
        }
      }, [span]);
    },
    genInput(otpIdx) {
      const listeners = Object.assign({}, this.listeners$);
      delete listeners.change;
      return this.$createElement("input", {
        style: {},
        domProps: {
          value: this.otp[otpIdx],
          min: this.type === "number" ? 0 : null
        },
        attrs: {
          ...this.attrs$,
          autocomplete: "one-time-code",
          disabled: this.isDisabled,
          readonly: this.isReadonly,
          type: this.type,
          id: `${this.computedId}--${otpIdx}`,
          class: `otp-field-box--${otpIdx}`
        },
        on: Object.assign(listeners, {
          blur: this.onBlur,
          input: (e) => this.onInput(e, otpIdx),
          focus: (e) => this.onFocus(e, otpIdx),
          keydown: this.onKeyDown,
          keyup: (e) => this.onKeyUp(e, otpIdx)
        }),
        ref: "input",
        refInFor: true
      });
    },
    genTextFieldSlot(otpIdx) {
      return this.$createElement("div", {
        staticClass: "v-text-field__slot"
      }, [
        this.genInput(otpIdx)
      ]);
    },
    onBlur(e) {
      this.isFocused = false;
      e && this.$nextTick(() => this.$emit("blur", e));
    },
    onClick(otpIdx) {
      if (this.isFocused || this.isDisabled || !this.$refs.input[otpIdx])
        return;
      this.onFocus(void 0, otpIdx);
    },
    onFocus(e, otpIdx) {
      e?.preventDefault();
      e?.stopPropagation();
      const elements = this.$refs.input;
      const ref = this.$refs.input && elements[otpIdx || 0];
      if (!ref)
        return;
      if (document.activeElement !== ref) {
        ref.focus();
        return ref.select();
      }
      if (!this.isFocused) {
        this.isFocused = true;
        ref.select();
        e && this.$emit("focus", e);
      }
    },
    onInput(e, index) {
      const maxCursor = +this.length - 1;
      const target = e.target;
      const value = target.value;
      const inputDataArray = value?.split("") || [];
      const newOtp = [...this.otp];
      for (let i = 0; i < inputDataArray.length; i++) {
        const appIdx = index + i;
        if (appIdx > maxCursor)
          break;
        newOtp[appIdx] = inputDataArray[i].toString();
      }
      if (!inputDataArray.length) {
        newOtp.splice(index, 1);
      }
      this.otp = newOtp;
      this.internalValue = this.otp.join("");
      if (index + inputDataArray.length >= +this.length) {
        this.onCompleted();
        this.clearFocus(index);
      } else if (inputDataArray.length) {
        this.changeFocus(index + inputDataArray.length);
      }
    },
    clearFocus(index) {
      const input = this.$refs.input[index];
      input.blur();
    },
    onKeyDown(e) {
      if (e.keyCode === keyCodes.enter) {
        this.$emit("change", this.internalValue);
      }
      this.$emit("keydown", e);
    },
    onMouseDown(e, otpIdx) {
      if (e.target !== this.$refs.input[otpIdx]) {
        e.preventDefault();
        e.stopPropagation();
      }
      VInput.options.methods.onMouseDown.call(this, e);
    },
    onMouseUp(e, otpIdx) {
      if (this.hasMouseDown)
        this.focus(e, otpIdx);
      VInput.options.methods.onMouseUp.call(this, e);
    },
    changeFocus(index) {
      this.onFocus(void 0, index || 0);
    },
    updateValue(val) {
      this.hasColor = val;
      if (val) {
        this.initialValue = this.lazyValue;
      } else if (this.initialValue !== this.lazyValue) {
        this.$emit("change", this.lazyValue);
      }
    },
    onKeyUp(event, index) {
      event.preventDefault();
      const eventKey = event.key;
      if (["Tab", "Shift", "Meta", "Control", "Alt"].includes(eventKey)) {
        return;
      }
      if (["Delete"].includes(eventKey)) {
        return;
      }
      if (eventKey === "ArrowLeft" || eventKey === "Backspace" && !this.otp[index]) {
        return index > 0 && this.changeFocus(index - 1);
      }
      if (eventKey === "ArrowRight") {
        return index + 1 < +this.length && this.changeFocus(index + 1);
      }
    },
    onCompleted() {
      const rsp = this.otp.join("");
      if (rsp.length === +this.length) {
        this.$emit("finish", rsp);
      }
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-otp-input",
      class: this.themeClasses
    }, this.genContent());
  }
});
const VOverflowBtn = VAutocomplete.extend({
  name: "v-overflow-btn",
  props: {
    editable: Boolean,
    segmented: Boolean
  },
  computed: {
    classes() {
      return {
        ...VAutocomplete.options.computed.classes.call(this),
        "v-overflow-btn": true,
        "v-overflow-btn--segmented": this.segmented,
        "v-overflow-btn--editable": this.editable
      };
    },
    isAnyValueAllowed() {
      return this.editable || VAutocomplete.options.computed.isAnyValueAllowed.call(this);
    },
    isSingle() {
      return true;
    },
    computedItems() {
      return this.segmented ? this.allItems : this.filteredItems;
    },
    labelValue() {
      return this.isFocused && !this.persistentPlaceholder || this.isLabelActive;
    }
  },
  methods: {
    genSelections() {
      return this.editable ? VAutocomplete.options.methods.genSelections.call(this) : VSelect.options.methods.genSelections.call(this);
    },
    genCommaSelection(item, index, last) {
      return this.segmented ? this.genSegmentedBtn(item) : VSelect.options.methods.genCommaSelection.call(this, item, index, last);
    },
    genInput() {
      const input = VTextField.options.methods.genInput.call(this);
      input.data = input.data || {};
      input.data.domProps.value = this.editable ? this.internalSearch : "";
      input.data.attrs.readonly = !this.isAnyValueAllowed;
      return input;
    },
    genLabel() {
      if (this.editable && this.isFocused)
        return null;
      const label = VTextField.options.methods.genLabel.call(this);
      if (!label)
        return label;
      label.data = label.data || {};
      label.data.style = {};
      return label;
    },
    genSegmentedBtn(item) {
      const itemValue = this.getValue(item);
      const itemObj = this.computedItems.find((i) => this.getValue(i) === itemValue) || item;
      if (!itemObj.text || !itemObj.callback) {
        consoleWarn('When using "segmented" prop without a selection slot, items must contain both a text and callback property', this);
        return null;
      }
      return this.$createElement(VBtn, {
        props: { text: true },
        on: {
          click(e) {
            e.stopPropagation();
            itemObj.callback(e);
          }
        }
      }, [itemObj.text]);
    },
    updateValue(val) {
      if (val) {
        this.initialValue = this.lazyValue;
      } else if (this.initialValue !== this.lazyValue) {
        this.$emit("change", this.lazyValue);
      }
    }
  }
});
const VPagination = mixins(
  Colorable,
  intersectable({ onVisible: ["init"] }),
  Themeable$1
).extend({
  name: "v-pagination",
  directives: { Resize: Resize$1 },
  props: {
    circle: Boolean,
    disabled: Boolean,
    navigationColor: String,
    navigationTextColor: String,
    length: {
      type: Number,
      default: 0,
      validator: (val) => val % 1 === 0
    },
    nextIcon: {
      type: String,
      default: "$next"
    },
    prevIcon: {
      type: String,
      default: "$prev"
    },
    totalVisible: [Number, String],
    value: {
      type: Number,
      default: 0
    },
    pageAriaLabel: {
      type: String,
      default: "$vuetify.pagination.ariaLabel.page"
    },
    currentPageAriaLabel: {
      type: String,
      default: "$vuetify.pagination.ariaLabel.currentPage"
    },
    previousAriaLabel: {
      type: String,
      default: "$vuetify.pagination.ariaLabel.previous"
    },
    nextAriaLabel: {
      type: String,
      default: "$vuetify.pagination.ariaLabel.next"
    },
    wrapperAriaLabel: {
      type: String,
      default: "$vuetify.pagination.ariaLabel.wrapper"
    }
  },
  data() {
    return {
      maxButtons: 0,
      selected: null
    };
  },
  computed: {
    classes() {
      return {
        "v-pagination": true,
        "v-pagination--circle": this.circle,
        "v-pagination--disabled": this.disabled,
        ...this.themeClasses
      };
    },
    items() {
      const totalVisible = parseInt(this.totalVisible, 10);
      if (totalVisible === 0 || isNaN(this.length) || this.length > Number.MAX_SAFE_INTEGER) {
        return [];
      }
      const maxLength = Math.min(
        Math.max(0, totalVisible) || this.length,
        Math.max(0, this.maxButtons) || this.length,
        this.length
      );
      if (this.length <= maxLength) {
        return this.range(1, this.length);
      }
      const even = maxLength % 2 === 0 ? 1 : 0;
      const left = Math.floor(maxLength / 2);
      const right = this.length - left + 1 + even;
      if (this.value > left && this.value < right) {
        const firstItem = 1;
        const lastItem = this.length;
        const start = this.value - left + 2;
        const end = this.value + left - 2 - even;
        const secondItem = start - 1 === firstItem + 1 ? 2 : "...";
        const beforeLastItem = end + 1 === lastItem - 1 ? end + 1 : "...";
        return [1, secondItem, ...this.range(start, end), beforeLastItem, this.length];
      } else if (this.value === left) {
        const end = this.value + left - 1 - even;
        return [...this.range(1, end), "...", this.length];
      } else if (this.value === right) {
        const start = this.value - left + 1;
        return [1, "...", ...this.range(start, this.length)];
      } else {
        return [
          ...this.range(1, left),
          "...",
          ...this.range(right, this.length)
        ];
      }
    }
  },
  watch: {
    value() {
      this.init();
    }
  },
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      this.selected = null;
      this.onResize();
      this.$nextTick(this.onResize);
      setTimeout(() => this.selected = this.value, 100);
    },
    onResize() {
      const width = this.$el && this.$el.parentElement ? this.$el.parentElement.clientWidth : window.innerWidth;
      this.maxButtons = Math.floor((width - 96) / 42);
    },
    next(e) {
      e.preventDefault();
      this.$emit("input", this.value + 1);
      this.$emit("next");
    },
    previous(e) {
      e.preventDefault();
      this.$emit("input", this.value - 1);
      this.$emit("previous");
    },
    range(from, to) {
      const range = [];
      from = from > 0 ? from : 1;
      for (let i = from; i <= to; i++) {
        range.push(i);
      }
      return range;
    },
    genIcon(h, icon, disabled, fn, label) {
      return h("li", [
        h(
          "button",
          this.setBackgroundColor(this.navigationColor, {
            staticClass: "v-pagination__navigation",
            class: {
              "v-pagination__navigation--disabled": disabled
            },
            attrs: {
              disabled,
              type: "button",
              "aria-label": label
            },
            on: disabled ? {} : { click: fn }
          }),
          [h(VIcon$1, { props: { color: this.navigationTextColor } }, [icon])]
        )
      ]);
    },
    genItem(h, i) {
      const color = i === this.value && (this.color || "primary");
      const isCurrentPage = i === this.value;
      const ariaLabel = isCurrentPage ? this.currentPageAriaLabel : this.pageAriaLabel;
      return h("button", this.setBackgroundColor(color, {
        staticClass: "v-pagination__item",
        class: {
          "v-pagination__item--active": i === this.value
        },
        attrs: {
          type: "button",
          "aria-current": isCurrentPage,
          "aria-label": this.$vuetify.lang.t(ariaLabel, i)
        },
        on: {
          click: () => this.$emit("input", i)
        }
      }), [i.toString()]);
    },
    genItems(h) {
      return this.items.map((i, index) => {
        return h("li", { key: index }, [
          isNaN(Number(i)) ? h("span", { class: "v-pagination__more" }, [i.toString()]) : this.genItem(h, i)
        ]);
      });
    },
    genList(h, children) {
      return h("ul", {
        directives: [{
          modifiers: { quiet: true },
          name: "resize",
          value: this.onResize
        }],
        class: this.classes
      }, children);
    }
  },
  render(h) {
    const children = [
      this.genIcon(
        h,
        this.$vuetify.rtl ? this.nextIcon : this.prevIcon,
        this.value <= 1,
        this.previous,
        this.$vuetify.lang.t(this.previousAriaLabel)
      ),
      this.genItems(h),
      this.genIcon(
        h,
        this.$vuetify.rtl ? this.prevIcon : this.nextIcon,
        this.value >= this.length,
        this.next,
        this.$vuetify.lang.t(this.nextAriaLabel)
      )
    ];
    return h("nav", {
      attrs: {
        role: "navigation",
        "aria-label": this.$vuetify.lang.t(this.wrapperAriaLabel)
      }
    }, [this.genList(h, children)]);
  }
});
const Translatable = Vue.extend({
  name: "translatable",
  props: {
    height: Number
  },
  data: () => ({
    elOffsetTop: 0,
    parallax: 0,
    parallaxDist: 0,
    percentScrolled: 0,
    scrollTop: 0,
    windowHeight: 0,
    windowBottom: 0
  }),
  computed: {
    imgHeight() {
      return this.objHeight();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.translate, false);
    window.removeEventListener("resize", this.translate, false);
  },
  methods: {
    calcDimensions() {
      const offset = this.$el.getBoundingClientRect();
      this.scrollTop = window.pageYOffset;
      this.parallaxDist = this.imgHeight - this.height;
      this.elOffsetTop = offset.top + this.scrollTop;
      this.windowHeight = window.innerHeight;
      this.windowBottom = this.scrollTop + this.windowHeight;
    },
    listeners() {
      window.addEventListener("scroll", this.translate, false);
      window.addEventListener("resize", this.translate, false);
    },
    /** @abstract **/
    objHeight() {
      throw new Error("Not implemented !");
    },
    translate() {
      this.calcDimensions();
      this.percentScrolled = (this.windowBottom - this.elOffsetTop) / (parseInt(this.height) + this.windowHeight);
      this.parallax = Math.round(this.parallaxDist * this.percentScrolled);
    }
  }
});
const baseMixins$a = mixins(
  Translatable
);
const VParallax = baseMixins$a.extend().extend({
  name: "v-parallax",
  props: {
    alt: {
      type: String,
      default: ""
    },
    height: {
      type: [String, Number],
      default: 500
    },
    src: String,
    srcset: String
  },
  data: () => ({
    isBooted: false
  }),
  computed: {
    styles() {
      return {
        display: "block",
        opacity: this.isBooted ? 1 : 0,
        transform: `translate(-50%, ${this.parallax}px)`
      };
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const img = this.$refs.img;
      if (!img)
        return;
      if (img.complete) {
        this.translate();
        this.listeners();
      } else {
        img.addEventListener("load", () => {
          this.translate();
          this.listeners();
        }, false);
      }
      this.isBooted = true;
    },
    objHeight() {
      return this.$refs.img.naturalHeight;
    }
  },
  render(h) {
    const imgData = {
      staticClass: "v-parallax__image",
      style: this.styles,
      attrs: {
        src: this.src,
        srcset: this.srcset,
        alt: this.alt
      },
      ref: "img"
    };
    const container = h("div", {
      staticClass: "v-parallax__image-container"
    }, [
      h("img", imgData)
    ]);
    const content = h("div", {
      staticClass: "v-parallax__content"
    }, this.$slots.default);
    return h("div", {
      staticClass: "v-parallax",
      style: {
        height: `${this.height}px`
      },
      on: this.$listeners
    }, [container, content]);
  }
});
const baseMixins$9 = mixins(
  BaseItemGroup,
  VInput
);
const VRadioGroup = baseMixins$9.extend({
  name: "v-radio-group",
  provide() {
    return {
      radioGroup: this
    };
  },
  props: {
    column: {
      type: Boolean,
      default: true
    },
    height: {
      type: [Number, String],
      default: "auto"
    },
    name: String,
    row: Boolean,
    // If no value set on VRadio
    // will match valueComparator
    // force default to null
    value: null
  },
  computed: {
    classes() {
      return {
        ...VInput.options.computed.classes.call(this),
        "v-input--selection-controls v-input--radio-group": true,
        "v-input--radio-group--column": this.column && !this.row,
        "v-input--radio-group--row": this.row
      };
    }
  },
  methods: {
    genDefaultSlot() {
      return this.$createElement("div", {
        staticClass: "v-input--radio-group__input",
        attrs: {
          id: this.id,
          role: "radiogroup",
          "aria-labelledby": this.computedId
        }
      }, VInput.options.methods.genDefaultSlot.call(this));
    },
    genInputSlot() {
      const render = VInput.options.methods.genInputSlot.call(this);
      delete render.data.on.click;
      return render;
    },
    genLabel() {
      const label = VInput.options.methods.genLabel.call(this);
      if (!label)
        return null;
      label.data.attrs.id = this.computedId;
      delete label.data.attrs.for;
      label.tag = "legend";
      return label;
    },
    onClick: BaseItemGroup.options.methods.onClick
  },
  render(h) {
    const vnode = VInput.options.render.call(this, h);
    this._b(vnode.data, "div", this.attrs$);
    return vnode;
  }
});
const baseMixins$8 = mixins(
  BindsAttrs,
  Colorable,
  Rippleable,
  factory$1("radioGroup"),
  Themeable$1
);
const VRadio = baseMixins$8.extend().extend({
  name: "v-radio",
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: null
    },
    id: String,
    label: String,
    name: String,
    offIcon: {
      type: String,
      default: "$radioOff"
    },
    onIcon: {
      type: String,
      default: "$radioOn"
    },
    readonly: {
      type: Boolean,
      default: null
    },
    value: {
      default: null
    }
  },
  data: () => ({
    isFocused: false
  }),
  computed: {
    classes() {
      return {
        "v-radio--is-disabled": this.isDisabled,
        "v-radio--is-focused": this.isFocused,
        ...this.themeClasses,
        ...this.groupClasses
      };
    },
    computedColor() {
      if (this.isDisabled)
        return void 0;
      return Selectable.options.computed.computedColor.call(this);
    },
    computedIcon() {
      return this.isActive ? this.onIcon : this.offIcon;
    },
    computedId() {
      return VInput.options.computed.computedId.call(this);
    },
    hasLabel: VInput.options.computed.hasLabel,
    hasState() {
      return (this.radioGroup || {}).hasState;
    },
    isDisabled() {
      return this.disabled ?? (!!this.radioGroup && this.radioGroup.isDisabled);
    },
    isReadonly() {
      return this.readonly ?? (!!this.radioGroup && this.radioGroup.isReadonly);
    },
    computedName() {
      if (this.name || !this.radioGroup) {
        return this.name;
      }
      return this.radioGroup.name || `radio-${this.radioGroup._uid}`;
    },
    rippleState() {
      return Selectable.options.computed.rippleState.call(this);
    },
    validationState() {
      return (this.radioGroup || {}).validationState || this.computedColor;
    }
  },
  methods: {
    genInput(args) {
      return Selectable.options.methods.genInput.call(this, "radio", args);
    },
    genLabel() {
      if (!this.hasLabel)
        return null;
      return this.$createElement(VLabel, {
        on: {
          // Label shouldn't cause the input to focus
          click: prevent
        },
        attrs: {
          for: this.computedId
        },
        props: {
          color: this.validationState,
          focused: this.hasState
        }
      }, getSlot(this, "label") || this.label);
    },
    genRadio() {
      const { title, ...radioAttrs } = this.attrs$;
      return this.$createElement("div", {
        staticClass: "v-input--selection-controls__input"
      }, [
        this.$createElement(VIcon$1, this.setTextColor(this.validationState, {
          props: {
            dense: this.radioGroup && this.radioGroup.dense
          }
        }), this.computedIcon),
        this.genInput({
          name: this.computedName,
          value: this.value,
          ...radioAttrs
        }),
        this.genRipple(this.setTextColor(this.rippleState))
      ]);
    },
    onFocus(e) {
      this.isFocused = true;
      this.$emit("focus", e);
    },
    onBlur(e) {
      this.isFocused = false;
      this.$emit("blur", e);
    },
    onChange() {
      if (this.isDisabled || this.isReadonly || this.isActive)
        return;
      this.toggle();
    },
    onKeydown: () => {
    }
    // Override default with noop
  },
  render(h) {
    const data = {
      staticClass: "v-radio",
      class: this.classes,
      on: mergeListeners({
        click: this.onChange
      }, this.listeners$),
      attrs: { title: this.attrs$.title }
    };
    return h("div", data, [
      this.genRadio(),
      this.genLabel()
    ]);
  }
});
const VRangeSlider = VSlider.extend({
  name: "v-range-slider",
  props: {
    value: {
      type: Array,
      default: () => [0, 0]
    }
  },
  data() {
    return {
      activeThumb: null,
      lazyValue: this.value
    };
  },
  computed: {
    classes() {
      return {
        ...VSlider.options.computed.classes.call(this),
        "v-input--range-slider": true
      };
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(val) {
        let value = val.map((v = 0) => this.roundValue(Math.min(Math.max(v, this.minValue), this.maxValue)));
        if (value[0] > value[1] || value[1] < value[0]) {
          if (this.activeThumb !== null) {
            const toFocus = this.activeThumb === 1 ? 0 : 1;
            const el = this.$refs[`thumb_${toFocus}`];
            el.focus();
          }
          value = [value[1], value[0]];
        }
        this.lazyValue = value;
        if (!deepEqual(value, this.value))
          this.$emit("input", value);
        this.validate();
      }
    },
    inputWidth() {
      return this.internalValue.map(
        (v) => (this.roundValue(v) - this.minValue) / (this.maxValue - this.minValue) * 100
      );
    }
  },
  methods: {
    getTrackStyle(startLength, endLength, startPadding = 0, endPadding = 0) {
      const startDir = this.vertical ? this.$vuetify.rtl ? "top" : "bottom" : this.$vuetify.rtl ? "right" : "left";
      const endDir = this.vertical ? "height" : "width";
      const start = `calc(${startLength}% + ${startPadding}px)`;
      const end = `calc(${endLength}% + ${endPadding}px)`;
      return {
        transition: this.trackTransition,
        [startDir]: start,
        [endDir]: end
      };
    },
    getIndexOfClosestValue(arr, v) {
      if (Math.abs(arr[0] - v) < Math.abs(arr[1] - v))
        return 0;
      else
        return 1;
    },
    genInput() {
      return createRange(2).map((i) => {
        const input = VSlider.options.methods.genInput.call(this);
        input.data = input.data || {};
        input.data.attrs = input.data.attrs || {};
        input.data.attrs.value = this.internalValue[i];
        input.data.attrs.id = `input-${i ? "max" : "min"}-${this._uid}`;
        return input;
      });
    },
    genTrackContainer() {
      const children = [];
      const padding = this.isDisabled ? 10 : 0;
      const sections = [
        {
          class: "v-slider__track-background",
          color: this.computedTrackColor,
          styles: [0, this.inputWidth[0], 0, -padding]
        },
        {
          class: this.isDisabled ? "v-slider__track-background" : "v-slider__track-fill",
          color: this.isDisabled ? this.computedTrackColor : this.computedTrackFillColor,
          styles: [this.inputWidth[0], Math.abs(this.inputWidth[1] - this.inputWidth[0]), padding, padding * -2]
        },
        {
          class: "v-slider__track-background",
          color: this.computedTrackColor,
          styles: [this.inputWidth[1], Math.abs(100 - this.inputWidth[1]), padding, -padding]
        }
      ];
      if (this.$vuetify.rtl)
        sections.reverse();
      children.push(...sections.map((section) => this.$createElement("div", this.setBackgroundColor(section.color, {
        staticClass: section.class,
        style: this.getTrackStyle(...section.styles)
      }))));
      return this.$createElement("div", {
        staticClass: "v-slider__track-container",
        ref: "track"
      }, children);
    },
    genChildren() {
      return [
        this.genInput(),
        this.genTrackContainer(),
        this.genSteps(),
        createRange(2).map((index) => {
          const value = this.internalValue[index];
          const onFocus = (e) => {
            this.isFocused = true;
            this.activeThumb = index;
            this.$emit("focus", e);
          };
          const onBlur = (e) => {
            this.isFocused = false;
            this.activeThumb = null;
            this.$emit("blur", e);
          };
          const valueWidth = this.inputWidth[index];
          const isActive = this.isActive && this.activeThumb === index;
          const isFocused = this.isFocused && this.activeThumb === index;
          return this.genThumbContainer(value, valueWidth, isActive, isFocused, onFocus, onBlur, `thumb_${index}`);
        })
      ];
    },
    reevaluateSelected(value) {
      this.activeThumb = this.getIndexOfClosestValue(this.internalValue, value);
      const refName = `thumb_${this.activeThumb}`;
      const thumbRef = this.$refs[refName];
      thumbRef.focus();
    },
    onSliderMouseDown(e) {
      const value = this.parseMouseMove(e);
      this.reevaluateSelected(value);
      this.oldValue = this.internalValue;
      this.isActive = true;
      if (e.target?.matches(".v-slider__thumb-container, .v-slider__thumb-container *")) {
        this.thumbPressed = true;
        const domRect = e.target.getBoundingClientRect();
        const touch = "touches" in e ? e.touches[0] : e;
        this.startOffset = this.vertical ? touch.clientY - (domRect.top + domRect.height / 2) : touch.clientX - (domRect.left + domRect.width / 2);
      } else {
        this.startOffset = 0;
        window.clearTimeout(this.mouseTimeout);
        this.mouseTimeout = window.setTimeout(() => {
          this.thumbPressed = true;
        }, 300);
      }
      const mouseUpOptions = passiveSupported ? { passive: true, capture: true } : true;
      const mouseMoveOptions = passiveSupported ? { passive: true } : false;
      const isTouchEvent2 = "touches" in e;
      this.onMouseMove(e);
      this.app.addEventListener(isTouchEvent2 ? "touchmove" : "mousemove", this.onMouseMove, mouseMoveOptions);
      addOnceEventListener(this.app, isTouchEvent2 ? "touchend" : "mouseup", this.onSliderMouseUp, mouseUpOptions);
      this.$emit("start", this.internalValue);
    },
    onSliderClick(e) {
      if (!this.isActive) {
        if (this.noClick) {
          this.noClick = false;
          return;
        }
        const value = this.parseMouseMove(e);
        this.reevaluateSelected(value);
        this.setInternalValue(value);
        this.$emit("change", this.internalValue);
      }
    },
    onMouseMove(e) {
      const value = this.parseMouseMove(e);
      if (e.type === "mousemove") {
        this.thumbPressed = true;
      }
      if (this.activeThumb === null) {
        this.activeThumb = this.getIndexOfClosestValue(this.internalValue, value);
      }
      this.setInternalValue(value);
    },
    onKeyDown(e) {
      if (this.activeThumb === null)
        return;
      const value = this.parseKeyDown(e, this.internalValue[this.activeThumb]);
      if (value == null)
        return;
      this.setInternalValue(value);
      this.$emit("change", this.internalValue);
    },
    setInternalValue(value) {
      this.internalValue = this.internalValue.map((v, i) => {
        if (i === this.activeThumb)
          return value;
        else
          return Number(v);
      });
    }
  }
});
const VRating = mixins(
  Colorable,
  Delayable,
  Rippleable,
  Sizeable,
  Themeable$1
).extend({
  name: "v-rating",
  props: {
    backgroundColor: {
      type: String,
      default: "accent"
    },
    color: {
      type: String,
      default: "primary"
    },
    clearable: Boolean,
    dense: Boolean,
    emptyIcon: {
      type: String,
      default: "$ratingEmpty"
    },
    fullIcon: {
      type: String,
      default: "$ratingFull"
    },
    halfIcon: {
      type: String,
      default: "$ratingHalf"
    },
    halfIncrements: Boolean,
    hover: Boolean,
    length: {
      type: [Number, String],
      default: 5
    },
    readonly: Boolean,
    size: [Number, String],
    value: {
      type: Number,
      default: 0
    },
    iconLabel: {
      type: String,
      default: "$vuetify.rating.ariaLabel.icon"
    }
  },
  data() {
    return {
      hoverIndex: -1,
      internalValue: this.value
    };
  },
  computed: {
    directives() {
      if (this.readonly || !this.ripple)
        return [];
      return [{
        name: "ripple",
        value: { circle: true }
      }];
    },
    iconProps() {
      const {
        dark,
        large,
        light,
        medium,
        small,
        size,
        xLarge,
        xSmall
      } = this.$props;
      return {
        dark,
        large,
        light,
        medium,
        size,
        small,
        xLarge,
        xSmall
      };
    },
    isHovering() {
      return this.hover && this.hoverIndex >= 0;
    }
  },
  watch: {
    internalValue(val) {
      val !== this.value && this.$emit("input", val);
    },
    value(val) {
      this.internalValue = val;
    }
  },
  methods: {
    createClickFn(i) {
      return (e) => {
        if (this.readonly)
          return;
        const newValue = this.genHoverIndex(e, i);
        if (this.clearable && this.internalValue === newValue) {
          this.internalValue = 0;
        } else {
          this.internalValue = newValue;
        }
      };
    },
    createProps(i) {
      const props2 = {
        index: i,
        value: this.internalValue,
        click: this.createClickFn(i),
        isFilled: Math.floor(this.internalValue) > i,
        isHovered: Math.floor(this.hoverIndex) > i
      };
      if (this.halfIncrements) {
        props2.isHalfHovered = !props2.isHovered && (this.hoverIndex - i) % 1 > 0;
        props2.isHalfFilled = !props2.isFilled && (this.internalValue - i) % 1 > 0;
      }
      return props2;
    },
    genHoverIndex(e, i) {
      let isHalf = this.isHalfEvent(e);
      if (this.halfIncrements && this.$vuetify.rtl) {
        isHalf = !isHalf;
      }
      return i + (isHalf ? 0.5 : 1);
    },
    getIconName(props2) {
      const isFull = this.isHovering ? props2.isHovered : props2.isFilled;
      const isHalf = this.isHovering ? props2.isHalfHovered : props2.isHalfFilled;
      return isFull ? this.fullIcon : isHalf ? this.halfIcon : this.emptyIcon;
    },
    getColor(props2) {
      if (this.isHovering) {
        if (props2.isHovered || props2.isHalfHovered)
          return this.color;
      } else {
        if (props2.isFilled || props2.isHalfFilled)
          return this.color;
      }
      return this.backgroundColor;
    },
    isHalfEvent(e) {
      if (this.halfIncrements) {
        const rect = e.target && e.target.getBoundingClientRect();
        if (rect && e.pageX - rect.left < rect.width / 2)
          return true;
      }
      return false;
    },
    onMouseEnter(e, i) {
      this.runDelay("open", () => {
        this.hoverIndex = this.genHoverIndex(e, i);
      });
    },
    onMouseLeave() {
      this.runDelay("close", () => this.hoverIndex = -1);
    },
    genItem(i) {
      const props2 = this.createProps(i);
      if (this.$scopedSlots.item)
        return this.$scopedSlots.item(props2);
      const listeners = {
        click: props2.click
      };
      if (this.hover) {
        listeners.mouseenter = (e) => this.onMouseEnter(e, i);
        listeners.mouseleave = this.onMouseLeave;
        if (this.halfIncrements) {
          listeners.mousemove = (e) => this.onMouseEnter(e, i);
        }
      }
      return this.$createElement(VIcon$1, this.setTextColor(this.getColor(props2), {
        attrs: {
          "aria-label": this.$vuetify.lang.t(this.iconLabel, i + 1, Number(this.length))
        },
        directives: this.directives,
        props: this.iconProps,
        on: listeners
      }), [this.getIconName(props2)]);
    }
  },
  render(h) {
    const children = createRange(Number(this.length)).map((i) => this.genItem(i));
    return h("div", {
      staticClass: "v-rating",
      class: {
        "v-rating--readonly": this.readonly,
        "v-rating--dense": this.dense
      }
    }, children);
  }
});
const VSkeletonLoader = mixins(
  Elevatable,
  Measurable,
  Themeable$1
).extend({
  name: "VSkeletonLoader",
  props: {
    boilerplate: Boolean,
    loading: Boolean,
    loadingText: {
      type: String,
      default: "$vuetify.loading"
    },
    tile: Boolean,
    transition: String,
    type: String,
    types: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    attrs() {
      if (!this.isLoading)
        return this.$attrs;
      return {
        "aria-busy": !this.boilerplate ? true : void 0,
        "aria-live": !this.boilerplate ? "polite" : void 0,
        "aria-label": !this.boilerplate ? this.$vuetify.lang.t(this.loadingText) : void 0,
        role: !this.boilerplate ? "alert" : void 0,
        ...this.$attrs
      };
    },
    classes() {
      return {
        "v-skeleton-loader--boilerplate": this.boilerplate,
        "v-skeleton-loader--is-loading": this.isLoading,
        "v-skeleton-loader--tile": this.tile,
        ...this.themeClasses,
        ...this.elevationClasses
      };
    },
    isLoading() {
      return !("default" in this.$scopedSlots) || this.loading;
    },
    rootTypes() {
      return {
        actions: "button@2",
        article: "heading, paragraph",
        avatar: "avatar",
        button: "button",
        card: "image, card-heading",
        "card-avatar": "image, list-item-avatar",
        "card-heading": "heading",
        chip: "chip",
        "date-picker": "list-item, card-heading, divider, date-picker-options, date-picker-days, actions",
        "date-picker-options": "text, avatar@2",
        "date-picker-days": "avatar@28",
        heading: "heading",
        image: "image",
        "list-item": "text",
        "list-item-avatar": "avatar, text",
        "list-item-two-line": "sentences",
        "list-item-avatar-two-line": "avatar, sentences",
        "list-item-three-line": "paragraph",
        "list-item-avatar-three-line": "avatar, paragraph",
        paragraph: "text@3",
        sentences: "text@2",
        table: "table-heading, table-thead, table-tbody, table-tfoot",
        "table-heading": "heading, text",
        "table-thead": "heading@6",
        "table-tbody": "table-row-divider@6",
        "table-row-divider": "table-row, divider",
        "table-row": "table-cell@6",
        "table-cell": "text",
        "table-tfoot": "text@2, avatar@2",
        text: "text",
        ...this.types
      };
    }
  },
  methods: {
    genBone(text, children) {
      return this.$createElement("div", {
        staticClass: `v-skeleton-loader__${text} v-skeleton-loader__bone`
      }, children);
    },
    genBones(bone) {
      const [type2, length] = bone.split("@");
      const generator = () => this.genStructure(type2);
      return Array.from({ length }).map(generator);
    },
    // Fix type when this is merged
    // https://github.com/microsoft/TypeScript/pull/33050
    genStructure(type2) {
      let children = [];
      type2 = type2 || this.type || "";
      const bone = this.rootTypes[type2] || "";
      if (type2 === bone)
        ;
      else if (type2.indexOf(",") > -1)
        return this.mapBones(type2);
      else if (type2.indexOf("@") > -1)
        return this.genBones(type2);
      else if (bone.indexOf(",") > -1)
        children = this.mapBones(bone);
      else if (bone.indexOf("@") > -1)
        children = this.genBones(bone);
      else if (bone)
        children.push(this.genStructure(bone));
      return [this.genBone(type2, children)];
    },
    genSkeleton() {
      const children = [];
      if (!this.isLoading)
        children.push(getSlot(this));
      else
        children.push(this.genStructure());
      if (!this.transition)
        return children;
      return this.$createElement("transition", {
        props: {
          name: this.transition
        },
        // Only show transition when
        // content has been loaded
        on: {
          afterEnter: this.resetStyles,
          beforeEnter: this.onBeforeEnter,
          beforeLeave: this.onBeforeLeave,
          leaveCancelled: this.resetStyles
        }
      }, children);
    },
    mapBones(bones) {
      return bones.replace(/\s/g, "").split(",").map(this.genStructure);
    },
    onBeforeEnter(el) {
      this.resetStyles(el);
      if (!this.isLoading)
        return;
      el._initialStyle = {
        display: el.style.display,
        transition: el.style.transition
      };
      el.style.setProperty("transition", "none", "important");
    },
    onBeforeLeave(el) {
      el.style.setProperty("display", "none", "important");
    },
    resetStyles(el) {
      if (!el._initialStyle)
        return;
      el.style.display = el._initialStyle.display || "";
      el.style.transition = el._initialStyle.transition;
      delete el._initialStyle;
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-skeleton-loader",
      attrs: this.attrs,
      on: this.$listeners,
      class: this.classes,
      style: this.isLoading ? this.measurableStyles : void 0
    }, [this.genSkeleton()]);
  }
});
const VSlideItem = mixins(
  BaseItem,
  factory$1("slideGroup")
  /* @vue/component */
).extend({
  name: "v-slide-item"
});
const VSnackbar = mixins(
  VSheet,
  Colorable,
  Toggleable$1,
  factory$3([
    "absolute",
    "bottom",
    "left",
    "right",
    "top"
  ])
  /* @vue/component */
).extend({
  name: "v-snackbar",
  props: {
    app: Boolean,
    centered: Boolean,
    contentClass: {
      type: String,
      default: ""
    },
    multiLine: Boolean,
    text: Boolean,
    timeout: {
      type: [Number, String],
      default: 5e3
    },
    transition: {
      type: [Boolean, String],
      default: "v-snack-transition",
      validator: (v) => typeof v === "string" || v === false
    },
    vertical: Boolean
  },
  data: () => ({
    activeTimeout: -1
  }),
  computed: {
    classes() {
      return {
        "v-snack--absolute": this.absolute,
        "v-snack--active": this.isActive,
        "v-snack--bottom": this.bottom || !this.top,
        "v-snack--centered": this.centered,
        "v-snack--has-background": this.hasBackground,
        "v-snack--left": this.left,
        "v-snack--multi-line": this.multiLine && !this.vertical,
        "v-snack--right": this.right,
        "v-snack--text": this.text,
        "v-snack--top": this.top,
        "v-snack--vertical": this.vertical
      };
    },
    // Text and outlined styles both
    // use transparent backgrounds
    hasBackground() {
      return !this.text && !this.outlined;
    },
    // Snackbar is dark by default
    // override themeable logic.
    isDark() {
      return this.hasBackground ? !this.light : Themeable$1.options.computed.isDark.call(this);
    },
    styles() {
      if (this.absolute || !this.app)
        return {};
      const {
        bar,
        bottom,
        footer,
        insetFooter,
        left,
        right,
        top
      } = this.$vuetify.application;
      return {
        paddingBottom: convertToUnit(bottom + footer + insetFooter),
        paddingLeft: convertToUnit(left),
        paddingRight: convertToUnit(right),
        paddingTop: convertToUnit(bar + top)
      };
    }
  },
  watch: {
    isActive: "setTimeout",
    timeout: "setTimeout"
  },
  mounted() {
    if (this.isActive)
      this.setTimeout();
  },
  created() {
    if (this.$attrs.hasOwnProperty("auto-height")) {
      removed("auto-height", this);
    }
    if (this.timeout == 0) {
      deprecate('timeout="0"', "-1", this);
    }
  },
  methods: {
    genActions() {
      return this.$createElement("div", {
        staticClass: "v-snack__action "
      }, [
        getSlot(this, "action", {
          attrs: { class: "v-snack__btn" }
        })
      ]);
    },
    genContent() {
      return this.$createElement("div", {
        staticClass: "v-snack__content",
        class: {
          [this.contentClass]: true
        },
        attrs: {
          role: "status",
          "aria-live": "polite"
        }
      }, [getSlot(this)]);
    },
    genWrapper() {
      const setColor = this.hasBackground ? this.setBackgroundColor : this.setTextColor;
      const data = setColor(this.color, {
        staticClass: "v-snack__wrapper",
        class: VSheet.options.computed.classes.call(this),
        style: VSheet.options.computed.styles.call(this),
        directives: [{
          name: "show",
          value: this.isActive
        }],
        on: {
          pointerenter: () => window.clearTimeout(this.activeTimeout),
          pointerleave: this.setTimeout
        }
      });
      return this.$createElement("div", data, [
        this.genContent(),
        this.genActions()
      ]);
    },
    genTransition() {
      return this.$createElement("transition", {
        props: { name: this.transition }
      }, [this.genWrapper()]);
    },
    setTimeout() {
      window.clearTimeout(this.activeTimeout);
      const timeout = Number(this.timeout);
      if (!this.isActive || // TODO: remove 0 in v3
      [0, -1].includes(timeout)) {
        return;
      }
      this.activeTimeout = window.setTimeout(() => {
        this.isActive = false;
      }, timeout);
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-snack",
      class: this.classes,
      style: this.styles
    }, [
      this.transition !== false ? this.genTransition() : this.genWrapper()
    ]);
  }
});
function genPoints(values, boundary) {
  const { minX, maxX, minY, maxY } = boundary;
  const totalValues = values.length;
  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);
  const gridX = (maxX - minX) / (totalValues - 1);
  const gridY = (maxY - minY) / (maxValue - minValue || 1);
  return values.map((value, index) => {
    return {
      x: minX + index * gridX,
      y: maxY - (value - minValue) * gridY,
      value
    };
  });
}
function genBars(values, boundary) {
  const { minX, maxX, minY, maxY } = boundary;
  const totalValues = values.length;
  let maxValue = Math.max(...values);
  let minValue = Math.min(...values);
  if (minValue > 0)
    minValue = 0;
  if (maxValue < 0)
    maxValue = 0;
  const gridX = maxX / totalValues;
  const gridY = (maxY - minY) / (maxValue - minValue || 1);
  const horizonY = maxY - Math.abs(minValue * gridY);
  return values.map((value, index) => {
    const height = Math.abs(gridY * value);
    return {
      x: minX + index * gridX,
      y: horizonY - height + +(value < 0) * height,
      height,
      value
    };
  });
}
function int(value) {
  return parseInt(value, 10);
}
function checkCollinear(p0, p1, p2) {
  return int(p0.x + p2.x) === int(2 * p1.x) && int(p0.y + p2.y) === int(2 * p1.y);
}
function getDistance(p1, p2) {
  return Math.sqrt(
    Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)
  );
}
function moveTo(to, from, radius) {
  const vector = { x: to.x - from.x, y: to.y - from.y };
  const length = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
  const unitVector = { x: vector.x / length, y: vector.y / length };
  return {
    x: from.x + unitVector.x * radius,
    y: from.y + unitVector.y * radius
  };
}
function genPath(points, radius, fill = false, height = 75) {
  const start = points.shift();
  const end = points[points.length - 1];
  return (fill ? `M${start.x} ${height - start.x + 2} L${start.x} ${start.y}` : `M${start.x} ${start.y}`) + points.map((point, index) => {
    const next = points[index + 1];
    const prev = points[index - 1] || start;
    const isCollinear = next && checkCollinear(next, point, prev);
    if (!next || isCollinear) {
      return `L${point.x} ${point.y}`;
    }
    const threshold = Math.min(
      getDistance(prev, point),
      getDistance(next, point)
    );
    const isTooCloseForRadius = threshold / 2 < radius;
    const radiusForPoint = isTooCloseForRadius ? threshold / 2 : radius;
    const before = moveTo(prev, point, radiusForPoint);
    const after = moveTo(next, point, radiusForPoint);
    return `L${before.x} ${before.y}S${point.x} ${point.y} ${after.x} ${after.y}`;
  }).join("") + (fill ? `L${end.x} ${height - start.x + 2} Z` : "");
}
const VSparkline = mixins(
  Colorable
).extend({
  name: "VSparkline",
  inheritAttrs: false,
  props: {
    autoDraw: Boolean,
    autoDrawDuration: {
      type: Number,
      default: 2e3
    },
    autoDrawEasing: {
      type: String,
      default: "ease"
    },
    autoLineWidth: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    fill: {
      type: Boolean,
      default: false
    },
    gradient: {
      type: Array,
      default: () => []
    },
    gradientDirection: {
      type: String,
      validator: (val) => ["top", "bottom", "left", "right"].includes(val),
      default: "top"
    },
    height: {
      type: [String, Number],
      default: 75
    },
    labels: {
      type: Array,
      default: () => []
    },
    labelSize: {
      type: [Number, String],
      default: 7
    },
    lineWidth: {
      type: [String, Number],
      default: 4
    },
    padding: {
      type: [String, Number],
      default: 8
    },
    showLabels: Boolean,
    smooth: {
      type: [Boolean, Number, String],
      default: false
    },
    type: {
      type: String,
      default: "trend",
      validator: (val) => ["trend", "bar"].includes(val)
    },
    value: {
      type: Array,
      default: () => []
    },
    width: {
      type: [Number, String],
      default: 300
    }
  },
  data: () => ({
    lastLength: 0
  }),
  computed: {
    parsedPadding() {
      return Number(this.padding);
    },
    parsedWidth() {
      return Number(this.width);
    },
    parsedHeight() {
      return parseInt(this.height, 10);
    },
    parsedLabelSize() {
      return parseInt(this.labelSize, 10) || 7;
    },
    totalHeight() {
      let height = this.parsedHeight;
      if (this.hasLabels)
        height += parseInt(this.labelSize, 10) * 1.5;
      return height;
    },
    totalWidth() {
      let width = this.parsedWidth;
      if (this.type === "bar")
        width = Math.max(this.value.length * this._lineWidth, width);
      return width;
    },
    totalValues() {
      return this.value.length;
    },
    _lineWidth() {
      if (this.autoLineWidth && this.type !== "trend") {
        const totalPadding = this.parsedPadding * (this.totalValues + 1);
        return (this.parsedWidth - totalPadding) / this.totalValues;
      } else {
        return parseFloat(this.lineWidth) || 4;
      }
    },
    boundary() {
      if (this.type === "bar")
        return { minX: 0, maxX: this.totalWidth, minY: 0, maxY: this.parsedHeight };
      const padding = this.parsedPadding;
      return {
        minX: padding,
        maxX: this.totalWidth - padding,
        minY: padding,
        maxY: this.parsedHeight - padding
      };
    },
    hasLabels() {
      return Boolean(
        this.showLabels || this.labels.length > 0 || this.$scopedSlots.label
      );
    },
    parsedLabels() {
      const labels = [];
      const points = this._values;
      const len = points.length;
      for (let i = 0; labels.length < len; i++) {
        const item = points[i];
        let value = this.labels[i];
        if (!value) {
          value = typeof item === "object" ? item.value : item;
        }
        labels.push({
          x: item.x,
          value: String(value)
        });
      }
      return labels;
    },
    normalizedValues() {
      return this.value.map((item) => typeof item === "number" ? item : item.value);
    },
    _values() {
      return this.type === "trend" ? genPoints(this.normalizedValues, this.boundary) : genBars(this.normalizedValues, this.boundary);
    },
    textY() {
      let y = this.parsedHeight;
      if (this.type === "trend")
        y -= 4;
      return y;
    },
    _radius() {
      return this.smooth === true ? 8 : Number(this.smooth);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (!this.autoDraw || this.type === "bar" || !this.$refs.path)
            return;
          const path = this.$refs.path;
          const length = path.getTotalLength();
          if (!this.fill) {
            path.style.transition = "none";
            path.style.strokeDasharray = length + " " + length;
            path.style.strokeDashoffset = Math.abs(length - (this.lastLength || 0)).toString();
            path.getBoundingClientRect();
            path.style.transition = `stroke-dashoffset ${this.autoDrawDuration}ms ${this.autoDrawEasing}`;
            path.style.strokeDashoffset = "0";
          } else {
            path.style.transformOrigin = "bottom center";
            path.style.transition = "none";
            path.style.transform = `scaleY(0)`;
            path.getBoundingClientRect();
            path.style.transition = `transform ${this.autoDrawDuration}ms ${this.autoDrawEasing}`;
            path.style.transform = `scaleY(1)`;
          }
          this.lastLength = length;
        });
      }
    }
  },
  methods: {
    genGradient() {
      const gradientDirection = this.gradientDirection;
      const gradient = this.gradient.slice();
      if (!gradient.length)
        gradient.push("");
      const len = Math.max(gradient.length - 1, 1);
      const stops = gradient.reverse().map(
        (color, index) => this.$createElement("stop", {
          attrs: {
            offset: index / len,
            "stop-color": color || "currentColor"
          }
        })
      );
      return this.$createElement("defs", [
        this.$createElement("linearGradient", {
          attrs: {
            id: this._uid,
            gradientUnits: "userSpaceOnUse",
            x1: gradientDirection === "left" ? "100%" : "0",
            y1: gradientDirection === "top" ? "100%" : "0",
            x2: gradientDirection === "right" ? "100%" : "0",
            y2: gradientDirection === "bottom" ? "100%" : "0"
          }
        }, stops)
      ]);
    },
    genG(children) {
      return this.$createElement("g", {
        style: {
          fontSize: "8",
          textAnchor: "middle",
          dominantBaseline: "mathematical",
          fill: "currentColor"
        }
        // TODO: TS 3.5 is too eager with the array type here
      }, children);
    },
    genPath() {
      const points = genPoints(this.normalizedValues, this.boundary);
      return this.$createElement("path", {
        attrs: {
          d: genPath(points, this._radius, this.fill, this.parsedHeight),
          fill: this.fill ? `url(#${this._uid})` : "none",
          stroke: this.fill ? "none" : `url(#${this._uid})`
        },
        ref: "path"
      });
    },
    genLabels(offsetX) {
      const children = this.parsedLabels.map((item, i) => this.$createElement("text", {
        attrs: {
          x: item.x + offsetX + this._lineWidth / 2,
          y: this.textY + this.parsedLabelSize * 0.75,
          "font-size": Number(this.labelSize) || 7
        }
      }, [this.genLabel(item, i)]));
      return this.genG(children);
    },
    genLabel(item, index) {
      return this.$scopedSlots.label ? this.$scopedSlots.label({ index, value: item.value }) : item.value;
    },
    genBars() {
      if (!this.value || this.totalValues < 2)
        return void 0;
      const bars = genBars(this.normalizedValues, this.boundary);
      const offsetX = (Math.abs(bars[0].x - bars[1].x) - this._lineWidth) / 2;
      return this.$createElement("svg", {
        attrs: {
          display: "block",
          viewBox: `0 0 ${this.totalWidth} ${this.totalHeight}`
        }
      }, [
        this.genGradient(),
        this.genClipPath(bars, offsetX, this._lineWidth, "sparkline-bar-" + this._uid),
        this.hasLabels ? this.genLabels(offsetX) : void 0,
        this.$createElement("g", {
          attrs: {
            "clip-path": `url(#sparkline-bar-${this._uid}-clip)`,
            fill: `url(#${this._uid})`
          }
        }, [
          this.$createElement("rect", {
            attrs: {
              x: 0,
              y: 0,
              width: this.totalWidth,
              height: this.height
            }
          })
        ])
      ]);
    },
    genClipPath(bars, offsetX, lineWidth, id) {
      const rounding = typeof this.smooth === "number" ? this.smooth : this.smooth ? 2 : 0;
      return this.$createElement("clipPath", {
        attrs: {
          id: `${id}-clip`
        }
      }, bars.map((item) => {
        return this.$createElement("rect", {
          attrs: {
            x: item.x + offsetX,
            y: item.y,
            width: lineWidth,
            height: item.height,
            rx: rounding,
            ry: rounding
          }
        }, [
          this.autoDraw ? this.$createElement("animate", {
            attrs: {
              attributeName: "height",
              from: 0,
              to: item.height,
              dur: `${this.autoDrawDuration}ms`,
              fill: "freeze"
            }
          }) : void 0
        ]);
      }));
    },
    genTrend() {
      return this.$createElement("svg", this.setTextColor(this.color, {
        attrs: {
          ...this.$attrs,
          display: "block",
          "stroke-width": this._lineWidth || 1,
          viewBox: `0 0 ${this.width} ${this.totalHeight}`
        }
      }), [
        this.genGradient(),
        this.hasLabels && this.genLabels(-(this._lineWidth / 2)),
        this.genPath()
      ]);
    }
  },
  render(h) {
    if (this.totalValues < 2)
      return void 0;
    return this.type === "trend" ? this.genTrend() : this.genBars();
  }
});
const VSpeedDial = mixins(Positionable, Toggleable$1, Transitionable).extend({
  name: "v-speed-dial",
  directives: { ClickOutside: ClickOutside$1 },
  props: {
    direction: {
      type: String,
      default: "top",
      validator: (val) => {
        return ["top", "right", "bottom", "left"].includes(val);
      }
    },
    openOnHover: Boolean,
    transition: {
      type: String,
      default: "scale-transition"
    }
  },
  computed: {
    classes() {
      return {
        "v-speed-dial": true,
        "v-speed-dial--top": this.top,
        "v-speed-dial--right": this.right,
        "v-speed-dial--bottom": this.bottom,
        "v-speed-dial--left": this.left,
        "v-speed-dial--absolute": this.absolute,
        "v-speed-dial--fixed": this.fixed,
        [`v-speed-dial--direction-${this.direction}`]: true,
        "v-speed-dial--is-active": this.isActive
      };
    }
  },
  render(h) {
    let children = [];
    const data = {
      class: this.classes,
      directives: [{
        name: "click-outside",
        value: () => this.isActive = false
      }],
      on: {
        click: () => this.isActive = !this.isActive
      }
    };
    if (this.openOnHover) {
      data.on.mouseenter = () => this.isActive = true;
      data.on.mouseleave = () => this.isActive = false;
    }
    if (this.isActive) {
      let btnCount = 0;
      children = (this.$slots.default || []).map((b, i) => {
        if (b.tag && typeof b.componentOptions !== "undefined" && (b.componentOptions.Ctor.options.name === "v-btn" || b.componentOptions.Ctor.options.name === "v-tooltip")) {
          btnCount++;
          return h("div", {
            style: {
              transitionDelay: btnCount * 0.05 + "s"
            },
            key: i
          }, [b]);
        } else {
          b.key = i;
          return b;
        }
      });
    }
    const list = h("transition-group", {
      class: "v-speed-dial__list",
      props: {
        name: this.transition,
        mode: this.mode,
        origin: this.origin,
        tag: "div"
      }
    }, children);
    return h("div", data, [this.$slots.activator, list]);
  }
});
const baseMixins$7 = mixins(
  VSheet,
  provide("stepper"),
  Proxyable$1
);
const VStepper = baseMixins$7.extend({
  name: "v-stepper",
  provide() {
    return {
      stepClick: this.stepClick,
      isVertical: this.vertical
    };
  },
  props: {
    altLabels: Boolean,
    nonLinear: Boolean,
    flat: Boolean,
    vertical: Boolean
  },
  data() {
    const data = {
      isBooted: false,
      steps: [],
      content: [],
      isReverse: false
    };
    data.internalLazyValue = this.value != null ? this.value : (data[0] || {}).step || 1;
    return data;
  },
  computed: {
    classes() {
      return {
        "v-stepper--flat": this.flat,
        "v-stepper--is-booted": this.isBooted,
        "v-stepper--vertical": this.vertical,
        "v-stepper--alt-labels": this.altLabels,
        "v-stepper--non-linear": this.nonLinear,
        ...VSheet.options.computed.classes.call(this)
      };
    },
    styles() {
      return {
        ...VSheet.options.computed.styles.call(this)
      };
    }
  },
  watch: {
    internalValue(val, oldVal) {
      this.isReverse = Number(val) < Number(oldVal);
      oldVal && (this.isBooted = true);
      this.updateView();
    }
  },
  created() {
    if (this.$listeners.input) {
      breaking("@input", "@change", this);
    }
  },
  mounted() {
    this.updateView();
  },
  methods: {
    register(item) {
      if (item.$options.name === "v-stepper-step") {
        this.steps.push(item);
      } else if (item.$options.name === "v-stepper-content") {
        item.isVertical = this.vertical;
        this.content.push(item);
      }
    },
    unregister(item) {
      if (item.$options.name === "v-stepper-step") {
        this.steps = this.steps.filter((i) => i !== item);
      } else if (item.$options.name === "v-stepper-content") {
        item.isVertical = this.vertical;
        this.content = this.content.filter((i) => i !== item);
      }
    },
    stepClick(step) {
      this.$nextTick(() => this.internalValue = step);
    },
    updateView() {
      for (let index = this.steps.length; --index >= 0; ) {
        this.steps[index].toggle(this.internalValue);
      }
      for (let index = this.content.length; --index >= 0; ) {
        this.content[index].toggle(this.internalValue, this.isReverse);
      }
    }
  },
  render(h) {
    return h(this.tag, {
      staticClass: "v-stepper",
      class: this.classes,
      style: this.styles
    }, this.$slots.default);
  }
});
const baseMixins$6 = mixins(
  Colorable,
  inject("stepper", "v-stepper-step", "v-stepper")
);
const VStepperStep = baseMixins$6.extend().extend({
  name: "v-stepper-step",
  directives: { ripple },
  inject: ["stepClick"],
  props: {
    color: {
      type: String,
      default: "primary"
    },
    complete: Boolean,
    completeIcon: {
      type: String,
      default: "$complete"
    },
    editable: Boolean,
    editIcon: {
      type: String,
      default: "$edit"
    },
    errorIcon: {
      type: String,
      default: "$error"
    },
    rules: {
      type: Array,
      default: () => []
    },
    step: [Number, String]
  },
  data() {
    return {
      isActive: false,
      isInactive: true
    };
  },
  computed: {
    classes() {
      return {
        "v-stepper__step--active": this.isActive,
        "v-stepper__step--editable": this.editable,
        "v-stepper__step--inactive": this.isInactive,
        "v-stepper__step--error error--text": this.hasError,
        "v-stepper__step--complete": this.complete
      };
    },
    hasError() {
      return this.rules.some((validate) => validate() !== true);
    }
  },
  mounted() {
    this.stepper && this.stepper.register(this);
  },
  beforeDestroy() {
    this.stepper && this.stepper.unregister(this);
  },
  methods: {
    click(e) {
      e.stopPropagation();
      this.$emit("click", e);
      if (this.editable) {
        this.stepClick(this.step);
      }
    },
    genIcon(icon) {
      return this.$createElement(VIcon$1, icon);
    },
    genLabel() {
      return this.$createElement("div", {
        staticClass: "v-stepper__label"
      }, this.$slots.default);
    },
    genStep() {
      const color = !this.hasError && (this.complete || this.isActive) ? this.color : false;
      return this.$createElement("span", this.setBackgroundColor(color, {
        staticClass: "v-stepper__step__step"
      }), this.genStepContent());
    },
    genStepContent() {
      const children = [];
      if (this.hasError) {
        children.push(this.genIcon(this.errorIcon));
      } else if (this.complete) {
        if (this.editable) {
          children.push(this.genIcon(this.editIcon));
        } else {
          children.push(this.genIcon(this.completeIcon));
        }
      } else {
        children.push(String(this.step));
      }
      return children;
    },
    keyboardClick(e) {
      if (e.keyCode === keyCodes.space) {
        this.click(e);
      }
    },
    toggle(step) {
      this.isActive = step.toString() === this.step.toString();
      this.isInactive = Number(step) < Number(this.step);
    }
  },
  render(h) {
    return h("div", {
      attrs: {
        tabindex: this.editable ? 0 : -1
      },
      staticClass: "v-stepper__step",
      class: this.classes,
      directives: [{
        name: "ripple",
        value: this.editable
      }],
      on: {
        click: this.click,
        keydown: this.keyboardClick
      }
    }, [
      this.genStep(),
      this.genLabel()
    ]);
  }
});
const baseMixins$5 = mixins(
  inject("stepper", "v-stepper-content", "v-stepper")
);
const VStepperContent = baseMixins$5.extend().extend({
  name: "v-stepper-content",
  inject: {
    isVerticalProvided: {
      from: "isVertical"
    }
  },
  props: {
    step: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      height: 0,
      // Must be null to allow
      // previous comparison
      isActive: null,
      isReverse: false,
      isVertical: this.isVerticalProvided
    };
  },
  computed: {
    computedTransition() {
      const reverse = this.$vuetify.rtl ? !this.isReverse : this.isReverse;
      return reverse ? VTabReverseTransition : VTabTransition;
    },
    styles() {
      if (!this.isVertical)
        return {};
      return {
        height: convertToUnit(this.height)
      };
    }
  },
  watch: {
    isActive(current, previous) {
      if (current && previous == null) {
        this.height = "auto";
        return;
      }
      if (!this.isVertical)
        return;
      if (this.isActive)
        this.enter();
      else
        this.leave();
    }
  },
  mounted() {
    this.$refs.wrapper.addEventListener(
      "transitionend",
      this.onTransition,
      false
    );
    this.stepper && this.stepper.register(this);
  },
  beforeDestroy() {
    this.$refs.wrapper.removeEventListener(
      "transitionend",
      this.onTransition,
      false
    );
    this.stepper && this.stepper.unregister(this);
  },
  methods: {
    onTransition(e) {
      if (!this.isActive || e.propertyName !== "height")
        return;
      this.height = "auto";
    },
    enter() {
      let scrollHeight = 0;
      requestAnimationFrame(() => {
        scrollHeight = this.$refs.wrapper.scrollHeight;
      });
      this.height = 0;
      setTimeout(() => this.isActive && (this.height = scrollHeight || "auto"), 450);
    },
    leave() {
      this.height = this.$refs.wrapper.clientHeight;
      setTimeout(() => this.height = 0, 10);
    },
    toggle(step, reverse) {
      this.isActive = step.toString() === this.step.toString();
      this.isReverse = reverse;
    }
  },
  render(h) {
    const contentData = {
      staticClass: "v-stepper__content"
    };
    const wrapperData = {
      staticClass: "v-stepper__wrapper",
      style: this.styles,
      ref: "wrapper"
    };
    if (!this.isVertical) {
      contentData.directives = [{
        name: "show",
        value: this.isActive
      }];
    }
    const wrapper = h("div", wrapperData, [this.$slots.default]);
    const content = h("div", contentData, [wrapper]);
    return h(this.computedTransition, {
      on: this.$listeners
    }, [content]);
  }
});
const VStepperHeader = createSimpleFunctional("v-stepper__header");
const VStepperItems = createSimpleFunctional("v-stepper__items");
const VSwitch = Selectable.extend({
  name: "v-switch",
  directives: { Touch: Touch$1 },
  props: {
    inset: Boolean,
    loading: {
      type: [Boolean, String],
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        ...VInput.options.computed.classes.call(this),
        "v-input--selection-controls v-input--switch": true,
        "v-input--switch--flat": this.flat,
        "v-input--switch--inset": this.inset
      };
    },
    attrs() {
      return {
        "aria-checked": String(this.isActive),
        "aria-disabled": String(this.isDisabled),
        role: "switch"
      };
    },
    // Do not return undefined if disabled,
    // according to spec, should still show
    // a color when disabled and active
    validationState() {
      if (this.hasError && this.shouldValidate)
        return "error";
      if (this.hasSuccess)
        return "success";
      if (this.hasColor !== null)
        return this.computedColor;
      return void 0;
    },
    switchData() {
      return this.setTextColor(this.loading ? void 0 : this.validationState, {
        class: this.themeClasses
      });
    }
  },
  methods: {
    genDefaultSlot() {
      return [
        this.genSwitch(),
        this.genLabel()
      ];
    },
    genSwitch() {
      const { title, ...switchAttrs } = this.attrs$;
      return this.$createElement("div", {
        staticClass: "v-input--selection-controls__input"
      }, [
        this.genInput("checkbox", {
          ...this.attrs,
          ...switchAttrs
        }),
        this.genRipple(this.setTextColor(this.validationState, {
          directives: [{
            name: "touch",
            value: {
              left: this.onSwipeLeft,
              right: this.onSwipeRight
            }
          }]
        })),
        this.$createElement("div", {
          staticClass: "v-input--switch__track",
          ...this.switchData
        }),
        this.$createElement("div", {
          staticClass: "v-input--switch__thumb",
          ...this.switchData
        }, [this.genProgress()])
      ]);
    },
    genProgress() {
      return this.$createElement(VFabTransition, {}, [
        this.loading === false ? null : this.$slots.progress || this.$createElement(VProgressCircular, {
          props: {
            color: this.loading === true || this.loading === "" ? this.color || "primary" : this.loading,
            size: 16,
            width: 2,
            indeterminate: true
          }
        })
      ]);
    },
    onSwipeLeft() {
      if (this.isActive)
        this.onChange();
    },
    onSwipeRight() {
      if (!this.isActive)
        this.onChange();
    },
    onKeydown(e) {
      if (e.keyCode === keyCodes.left && this.isActive || e.keyCode === keyCodes.right && !this.isActive)
        this.onChange();
    }
  }
});
const VSystemBar = mixins(
  applicationable("bar", [
    "height",
    "window"
  ]),
  Colorable,
  Themeable$1
  /* @vue/component */
).extend({
  name: "v-system-bar",
  props: {
    height: [Number, String],
    lightsOut: Boolean,
    window: Boolean
  },
  computed: {
    classes() {
      return {
        "v-system-bar--lights-out": this.lightsOut,
        "v-system-bar--absolute": this.absolute,
        "v-system-bar--fixed": !this.absolute && (this.app || this.fixed),
        "v-system-bar--window": this.window,
        ...this.themeClasses
      };
    },
    computedHeight() {
      if (this.height) {
        return isNaN(parseInt(this.height)) ? this.height : parseInt(this.height);
      }
      return this.window ? 32 : 24;
    },
    styles() {
      return {
        height: convertToUnit(this.computedHeight)
      };
    }
  },
  methods: {
    updateApplication() {
      return this.$el ? this.$el.clientHeight : this.computedHeight;
    }
  },
  render(h) {
    const data = {
      staticClass: "v-system-bar",
      class: this.classes,
      style: this.styles,
      on: this.$listeners
    };
    return h("div", this.setBackgroundColor(this.color, data), getSlot(this));
  }
});
const VTabsBar = mixins(
  BaseSlideGroup,
  SSRBootable,
  Themeable$1
  /* @vue/component */
).extend({
  name: "v-tabs-bar",
  provide() {
    return {
      tabsBar: this
    };
  },
  computed: {
    classes() {
      return {
        ...BaseSlideGroup.options.computed.classes.call(this),
        "v-tabs-bar": true,
        "v-tabs-bar--is-mobile": this.isMobile,
        // TODO: Remove this and move to v-slide-group
        "v-tabs-bar--show-arrows": this.showArrows,
        ...this.themeClasses
      };
    }
  },
  watch: {
    items: "callSlider",
    internalValue: "callSlider",
    $route: "onRouteChange"
  },
  methods: {
    callSlider() {
      if (!this.isBooted)
        return;
      this.$emit("call:slider");
    },
    genContent() {
      const render = BaseSlideGroup.options.methods.genContent.call(this);
      render.data = render.data || {};
      render.data.staticClass += " v-tabs-bar__content";
      return render;
    },
    onRouteChange(val, oldVal) {
      if (this.mandatory)
        return;
      const items = this.items;
      const newPath = val.path;
      const oldPath = oldVal.path;
      let hasNew = false;
      let hasOld = false;
      for (const item of items) {
        if (item.to === oldPath)
          hasOld = true;
        else if (item.to === newPath)
          hasNew = true;
        if (hasNew && hasOld)
          break;
      }
      if (!hasNew && hasOld)
        this.internalValue = void 0;
    }
  },
  render(h) {
    const render = BaseSlideGroup.options.render.call(this, h);
    render.data.attrs = {
      role: "tablist"
    };
    return render;
  }
});
const VTabsItems = VWindow.extend({
  name: "v-tabs-items",
  props: {
    mandatory: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        ...VWindow.options.computed.classes.call(this),
        "v-tabs-items": true
      };
    },
    isDark() {
      return this.rootIsDark;
    }
  },
  methods: {
    getValue(item, i) {
      return item.id || BaseItemGroup.options.methods.getValue.call(this, item, i);
    }
  }
});
const VTabsSlider = mixins(Colorable).extend({
  name: "v-tabs-slider",
  render(h) {
    return h("div", this.setBackgroundColor(this.color, {
      staticClass: "v-tabs-slider"
    }));
  }
});
const baseMixins$4 = mixins(
  Colorable,
  Proxyable$1,
  Themeable$1
);
const VTabs = baseMixins$4.extend().extend({
  name: "v-tabs",
  directives: {
    Resize: Resize$1
  },
  props: {
    activeClass: {
      type: String,
      default: ""
    },
    alignWithTitle: Boolean,
    backgroundColor: String,
    centerActive: Boolean,
    centered: Boolean,
    fixedTabs: Boolean,
    grow: Boolean,
    height: {
      type: [Number, String],
      default: void 0
    },
    hideSlider: Boolean,
    iconsAndText: Boolean,
    mobileBreakpoint: [String, Number],
    nextIcon: {
      type: String,
      default: "$next"
    },
    optional: Boolean,
    prevIcon: {
      type: String,
      default: "$prev"
    },
    right: Boolean,
    showArrows: [Boolean, String],
    sliderColor: String,
    sliderSize: {
      type: [Number, String],
      default: 2
    },
    vertical: Boolean
  },
  data() {
    return {
      resizeTimeout: 0,
      slider: {
        height: null,
        left: null,
        right: null,
        top: null,
        width: null
      },
      transitionTime: 300
    };
  },
  computed: {
    classes() {
      return {
        "v-tabs--align-with-title": this.alignWithTitle,
        "v-tabs--centered": this.centered,
        "v-tabs--fixed-tabs": this.fixedTabs,
        "v-tabs--grow": this.grow,
        "v-tabs--icons-and-text": this.iconsAndText,
        "v-tabs--right": this.right,
        "v-tabs--vertical": this.vertical,
        ...this.themeClasses
      };
    },
    isReversed() {
      return this.$vuetify.rtl && this.vertical;
    },
    sliderStyles() {
      return {
        height: convertToUnit(this.slider.height),
        left: this.isReversed ? void 0 : convertToUnit(this.slider.left),
        right: this.isReversed ? convertToUnit(this.slider.right) : void 0,
        top: this.vertical ? convertToUnit(this.slider.top) : void 0,
        transition: this.slider.left != null ? null : "none",
        width: convertToUnit(this.slider.width)
      };
    },
    computedColor() {
      if (this.color)
        return this.color;
      else if (this.isDark && !this.appIsDark)
        return "white";
      else
        return "primary";
    }
  },
  watch: {
    alignWithTitle: "callSlider",
    centered: "callSlider",
    centerActive: "callSlider",
    fixedTabs: "callSlider",
    grow: "callSlider",
    iconsAndText: "callSlider",
    right: "callSlider",
    showArrows: "callSlider",
    vertical: "callSlider",
    "$vuetify.application.left": "onResize",
    "$vuetify.application.right": "onResize",
    "$vuetify.rtl": "onResize"
  },
  mounted() {
    if (typeof ResizeObserver !== "undefined") {
      const obs = new ResizeObserver(() => {
        this.onResize();
      });
      obs.observe(this.$el);
      this.$on("hook:destroyed", () => {
        obs.disconnect();
      });
    }
    this.$nextTick(() => {
      window.setTimeout(this.callSlider, 30);
    });
  },
  methods: {
    callSlider() {
      if (this.hideSlider || !this.$refs.items || !this.$refs.items.selectedItems.length) {
        this.slider.width = 0;
        return false;
      }
      this.$nextTick(() => {
        const activeTab = this.$refs.items.selectedItems[0];
        if (!activeTab || !activeTab.$el) {
          this.slider.width = 0;
          this.slider.left = 0;
          return;
        }
        const el = activeTab.$el;
        this.slider = {
          height: !this.vertical ? Number(this.sliderSize) : el.scrollHeight,
          left: this.vertical ? 0 : el.offsetLeft,
          right: this.vertical ? 0 : el.offsetLeft + el.offsetWidth,
          top: el.offsetTop,
          width: this.vertical ? Number(this.sliderSize) : el.scrollWidth
        };
      });
      return true;
    },
    genBar(items, slider) {
      const data = {
        style: {
          height: convertToUnit(this.height)
        },
        props: {
          activeClass: this.activeClass,
          centerActive: this.centerActive,
          dark: this.dark,
          light: this.light,
          mandatory: !this.optional,
          mobileBreakpoint: this.mobileBreakpoint,
          nextIcon: this.nextIcon,
          prevIcon: this.prevIcon,
          showArrows: this.showArrows,
          value: this.internalValue
        },
        on: {
          "call:slider": this.callSlider,
          change: (val) => {
            this.internalValue = val;
          }
        },
        ref: "items"
      };
      this.setTextColor(this.computedColor, data);
      this.setBackgroundColor(this.backgroundColor, data);
      return this.$createElement(VTabsBar, data, [
        this.genSlider(slider),
        items
      ]);
    },
    genItems(items, item) {
      if (items)
        return items;
      if (!item.length)
        return null;
      return this.$createElement(VTabsItems, {
        props: {
          value: this.internalValue
        },
        on: {
          change: (val) => {
            this.internalValue = val;
          }
        }
      }, item);
    },
    genSlider(slider) {
      if (this.hideSlider)
        return null;
      if (!slider) {
        slider = this.$createElement(VTabsSlider, {
          props: { color: this.sliderColor }
        });
      }
      return this.$createElement("div", {
        staticClass: "v-tabs-slider-wrapper",
        style: this.sliderStyles
      }, [slider]);
    },
    onResize() {
      if (this._isDestroyed)
        return;
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = window.setTimeout(this.callSlider, 0);
    },
    parseNodes() {
      let items = null;
      let slider = null;
      const item = [];
      const tab = [];
      const slot = this.$slots.default || [];
      const length = slot.length;
      for (let i = 0; i < length; i++) {
        const vnode = slot[i];
        if (vnode.componentOptions) {
          switch (vnode.componentOptions.Ctor.options.name) {
            case "v-tabs-slider":
              slider = vnode;
              break;
            case "v-tabs-items":
              items = vnode;
              break;
            case "v-tab-item":
              item.push(vnode);
              break;
            default:
              tab.push(vnode);
          }
        } else {
          tab.push(vnode);
        }
      }
      return { tab, slider, items, item };
    }
  },
  render(h) {
    const { tab, slider, items, item } = this.parseNodes();
    return h("div", {
      staticClass: "v-tabs",
      class: this.classes,
      directives: [{
        name: "resize",
        modifiers: { quiet: true },
        value: this.onResize
      }]
    }, [
      this.genBar(tab, slider),
      this.genItems(items, item)
    ]);
  }
});
const baseMixins$3 = mixins(
  Routable,
  // Must be after routable
  // to overwrite activeClass
  factory$1("tabsBar"),
  Themeable$1
);
const VTab = baseMixins$3.extend().extend(
  /* @vue/component */
).extend({
  name: "v-tab",
  props: {
    ripple: {
      type: [Boolean, Object],
      default: true
    },
    tabValue: {
      required: false
    }
  },
  data: () => ({
    proxyClass: "v-tab--active"
  }),
  computed: {
    classes() {
      return {
        "v-tab": true,
        ...Routable.options.computed.classes.call(this),
        "v-tab--disabled": this.disabled,
        ...this.groupClasses
      };
    },
    value() {
      if (this.tabValue != null)
        return this.tabValue;
      let to = this.to || this.href;
      if (to == null)
        return to;
      if (this.$router && this.to === Object(this.to)) {
        const resolve = this.$router.resolve(
          this.to,
          this.$route,
          this.append
        );
        to = resolve.href;
      }
      return to.replace("#", "");
    }
  },
  methods: {
    click(e) {
      if (this.disabled) {
        e.preventDefault();
        return;
      }
      if (this.href && this.href.indexOf("#") > -1)
        e.preventDefault();
      if (e.detail)
        this.$el.blur();
      this.$emit("click", e);
      this.to || this.toggle();
    },
    toggle() {
      if (!this.isActive || !this.tabsBar.mandatory && !this.to) {
        this.$emit("change");
      }
    }
  },
  render(h) {
    const { tag, data } = this.generateRouteLink();
    data.attrs = {
      ...data.attrs,
      "aria-selected": String(this.isActive),
      role: "tab",
      tabindex: this.disabled ? -1 : 0
    };
    data.on = {
      ...data.on,
      keydown: (e) => {
        if (e.keyCode === keyCodes.enter)
          this.click(e);
        this.$emit("keydown", e);
      }
    };
    return h(tag, data, this.$slots.default);
  }
});
const VTabItem = VWindowItem.extend({
  name: "v-tab-item",
  props: {
    id: String
  },
  methods: {
    genWindowItem() {
      const item = VWindowItem.options.methods.genWindowItem.call(this);
      item.data.domProps = item.data.domProps || {};
      item.data.domProps.id = this.id || this.value;
      return item;
    }
  }
});
const baseMixins$2 = mixins(
  VTextField
);
const VTextarea = baseMixins$2.extend({
  name: "v-textarea",
  props: {
    autoGrow: Boolean,
    noResize: Boolean,
    rowHeight: {
      type: [Number, String],
      default: 24,
      validator: (v) => !isNaN(parseFloat(v))
    },
    rows: {
      type: [Number, String],
      default: 5,
      validator: (v) => !isNaN(parseInt(v, 10))
    }
  },
  computed: {
    classes() {
      return {
        "v-textarea": true,
        "v-textarea--auto-grow": this.autoGrow,
        "v-textarea--no-resize": this.noResizeHandle,
        ...VTextField.options.computed.classes.call(this)
      };
    },
    noResizeHandle() {
      return this.noResize || this.autoGrow;
    }
  },
  watch: {
    autoGrow(val) {
      this.$nextTick(() => {
        val ? this.calculateInputHeight() : this.$refs.input?.style.removeProperty("height");
      });
    },
    lazyValue() {
      this.autoGrow && this.$nextTick(this.calculateInputHeight);
    },
    rowHeight() {
      this.autoGrow && this.$nextTick(this.calculateInputHeight);
    }
  },
  mounted() {
    setTimeout(() => {
      this.autoGrow && this.calculateInputHeight();
    }, 0);
  },
  methods: {
    calculateInputHeight() {
      const input = this.$refs.input;
      if (!input)
        return;
      input.style.height = "0";
      const height = input.scrollHeight;
      const minHeight = parseInt(this.rows, 10) * parseFloat(this.rowHeight);
      input.style.height = Math.max(minHeight, height) + "px";
    },
    genInput() {
      const input = VTextField.options.methods.genInput.call(this);
      input.tag = "textarea";
      delete input.data.attrs.type;
      input.data.attrs.rows = this.rows;
      return input;
    },
    onInput(e) {
      VTextField.options.methods.onInput.call(this, e);
      this.autoGrow && this.calculateInputHeight();
    },
    onKeyDown(e) {
      if (this.isFocused && e.keyCode === 13) {
        e.stopPropagation();
      }
      this.$emit("keydown", e);
    }
  }
});
const VTimeline = mixins(
  Themeable$1
  /* @vue/component */
).extend({
  name: "v-timeline",
  provide() {
    return { timeline: this };
  },
  props: {
    alignTop: Boolean,
    dense: Boolean,
    reverse: Boolean
  },
  computed: {
    classes() {
      return {
        "v-timeline--align-top": this.alignTop,
        "v-timeline--dense": this.dense,
        "v-timeline--reverse": this.reverse,
        ...this.themeClasses
      };
    }
  },
  render(h) {
    return h("div", {
      staticClass: "v-timeline",
      class: this.classes
    }, this.$slots.default);
  }
});
const baseMixins$1 = mixins(
  Colorable,
  Themeable$1
  /* @vue/component */
);
const VTimelineItem = baseMixins$1.extend().extend({
  name: "v-timeline-item",
  inject: ["timeline"],
  props: {
    color: {
      type: String,
      default: "primary"
    },
    fillDot: Boolean,
    hideDot: Boolean,
    icon: String,
    iconColor: String,
    large: Boolean,
    left: Boolean,
    right: Boolean,
    small: Boolean
  },
  computed: {
    hasIcon() {
      return !!this.icon || !!this.$slots.icon;
    }
  },
  methods: {
    genBody() {
      return this.$createElement("div", {
        staticClass: "v-timeline-item__body"
      }, this.$slots.default);
    },
    genIcon() {
      if (this.$slots.icon) {
        return this.$slots.icon;
      }
      return this.$createElement(VIcon$1, {
        props: {
          color: this.iconColor,
          dark: !this.theme.isDark,
          small: this.small
        }
      }, this.icon);
    },
    genInnerDot() {
      const data = this.setBackgroundColor(this.color);
      return this.$createElement("div", {
        staticClass: "v-timeline-item__inner-dot",
        ...data
      }, [this.hasIcon && this.genIcon()]);
    },
    genDot() {
      return this.$createElement("div", {
        staticClass: "v-timeline-item__dot",
        class: {
          "v-timeline-item__dot--small": this.small,
          "v-timeline-item__dot--large": this.large
        }
      }, [this.genInnerDot()]);
    },
    genDivider() {
      const children = [];
      if (!this.hideDot)
        children.push(this.genDot());
      return this.$createElement("div", {
        staticClass: "v-timeline-item__divider"
      }, children);
    },
    genOpposite() {
      return this.$createElement("div", {
        staticClass: "v-timeline-item__opposite"
      }, this.$slots.opposite);
    }
  },
  render(h) {
    const children = [
      this.genBody(),
      this.genDivider()
    ];
    if (this.$slots.opposite)
      children.push(this.genOpposite());
    return h("div", {
      staticClass: "v-timeline-item",
      class: {
        "v-timeline-item--fill-dot": this.fillDot,
        "v-timeline-item--before": this.timeline.reverse ? this.right : this.left,
        "v-timeline-item--after": this.timeline.reverse ? this.left : this.right,
        ...this.themeClasses
      }
    }, children);
  }
});
var SelectingTimes = /* @__PURE__ */ ((SelectingTimes2) => {
  SelectingTimes2[SelectingTimes2["Hour"] = 1] = "Hour";
  SelectingTimes2[SelectingTimes2["Minute"] = 2] = "Minute";
  SelectingTimes2[SelectingTimes2["Second"] = 3] = "Second";
  return SelectingTimes2;
})(SelectingTimes || {});
const VTimePickerTitle = mixins(
  PickerButton
  /* @vue/component */
).extend({
  name: "v-time-picker-title",
  props: {
    ampm: Boolean,
    ampmReadonly: Boolean,
    disabled: Boolean,
    hour: Number,
    minute: Number,
    second: Number,
    period: {
      type: String,
      validator: (period) => period === "am" || period === "pm"
    },
    readonly: Boolean,
    useSeconds: Boolean,
    selecting: Number
  },
  methods: {
    genTime() {
      let hour = this.hour;
      if (this.ampm) {
        hour = hour ? (hour - 1) % 12 + 1 : 12;
      }
      const displayedHour = this.hour == null ? "--" : this.ampm ? String(hour) : pad(hour);
      const displayedMinute = this.minute == null ? "--" : pad(this.minute);
      const titleContent = [
        this.genPickerButton("selecting", SelectingTimes.Hour, displayedHour, this.disabled),
        this.$createElement("span", ":"),
        this.genPickerButton("selecting", SelectingTimes.Minute, displayedMinute, this.disabled)
      ];
      if (this.useSeconds) {
        const displayedSecond = this.second == null ? "--" : pad(this.second);
        titleContent.push(this.$createElement("span", ":"));
        titleContent.push(this.genPickerButton("selecting", SelectingTimes.Second, displayedSecond, this.disabled));
      }
      return this.$createElement("div", {
        class: "v-time-picker-title__time"
      }, titleContent);
    },
    genAmPm() {
      return this.$createElement("div", {
        staticClass: "v-time-picker-title__ampm",
        class: {
          "v-time-picker-title__ampm--readonly": this.ampmReadonly
        }
      }, [
        !this.ampmReadonly || this.period === "am" ? this.genPickerButton("period", "am", this.$vuetify.lang.t("$vuetify.timePicker.am"), this.disabled || this.readonly) : null,
        !this.ampmReadonly || this.period === "pm" ? this.genPickerButton("period", "pm", this.$vuetify.lang.t("$vuetify.timePicker.pm"), this.disabled || this.readonly) : null
      ]);
    }
  },
  render(h) {
    const children = [this.genTime()];
    this.ampm && children.push(this.genAmPm());
    return h("div", {
      staticClass: "v-time-picker-title"
    }, children);
  }
});
const VTimePickerClock = mixins(
  Colorable,
  Themeable$1
  /* @vue/component */
).extend({
  name: "v-time-picker-clock",
  props: {
    allowedValues: Function,
    ampm: Boolean,
    disabled: Boolean,
    double: Boolean,
    format: {
      type: Function,
      default: (val) => val
    },
    max: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    scrollable: Boolean,
    readonly: Boolean,
    rotate: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1
    },
    value: Number
  },
  data() {
    return {
      inputValue: this.value,
      isDragging: false,
      valueOnMouseDown: null,
      valueOnMouseUp: null
    };
  },
  computed: {
    count() {
      return this.max - this.min + 1;
    },
    degreesPerUnit() {
      return 360 / this.roundCount;
    },
    degrees() {
      return this.degreesPerUnit * Math.PI / 180;
    },
    displayedValue() {
      return this.value == null ? this.min : this.value;
    },
    innerRadiusScale() {
      return 0.62;
    },
    roundCount() {
      return this.double ? this.count / 2 : this.count;
    }
  },
  watch: {
    value(value) {
      this.inputValue = value;
    }
  },
  methods: {
    wheel(e) {
      e.preventDefault();
      const delta2 = Math.sign(-e.deltaY || 1);
      let value = this.displayedValue;
      do {
        value = value + delta2;
        value = (value - this.min + this.count) % this.count + this.min;
      } while (!this.isAllowed(value) && value !== this.displayedValue);
      if (value !== this.displayedValue) {
        this.update(value);
      }
    },
    isInner(value) {
      return this.double && value - this.min >= this.roundCount;
    },
    handScale(value) {
      return this.isInner(value) ? this.innerRadiusScale : 1;
    },
    isAllowed(value) {
      return !this.allowedValues || this.allowedValues(value);
    },
    genValues() {
      const children = [];
      for (let value = this.min; value <= this.max; value = value + this.step) {
        const color = value === this.value && (this.color || "accent");
        children.push(this.$createElement("span", this.setBackgroundColor(color, {
          staticClass: "v-time-picker-clock__item",
          class: {
            "v-time-picker-clock__item--active": value === this.displayedValue,
            "v-time-picker-clock__item--disabled": this.disabled || !this.isAllowed(value)
          },
          style: this.getTransform(value),
          domProps: { innerHTML: `<span>${this.format(value)}</span>` }
        })));
      }
      return children;
    },
    genHand() {
      const scale = `scaleY(${this.handScale(this.displayedValue)})`;
      const angle = this.rotate + this.degreesPerUnit * (this.displayedValue - this.min);
      const color = this.value != null && (this.color || "accent");
      return this.$createElement("div", this.setBackgroundColor(color, {
        staticClass: "v-time-picker-clock__hand",
        class: {
          "v-time-picker-clock__hand--inner": this.isInner(this.value)
        },
        style: {
          transform: `rotate(${angle}deg) ${scale}`
        }
      }));
    },
    getTransform(i) {
      const { x, y } = this.getPosition(i);
      return {
        left: `${50 + x * 50}%`,
        top: `${50 + y * 50}%`
      };
    },
    getPosition(value) {
      const rotateRadians = this.rotate * Math.PI / 180;
      return {
        x: Math.sin((value - this.min) * this.degrees + rotateRadians) * this.handScale(value),
        y: -Math.cos((value - this.min) * this.degrees + rotateRadians) * this.handScale(value)
      };
    },
    onMouseDown(e) {
      e.preventDefault();
      this.valueOnMouseDown = null;
      this.valueOnMouseUp = null;
      this.isDragging = true;
      this.onDragMove(e);
    },
    onMouseUp(e) {
      e.stopPropagation();
      this.isDragging = false;
      if (this.valueOnMouseUp !== null && this.isAllowed(this.valueOnMouseUp)) {
        this.$emit("change", this.valueOnMouseUp);
      }
    },
    onDragMove(e) {
      e.preventDefault();
      if (!this.isDragging && e.type !== "click" || !this.$refs.clock)
        return;
      const { width, top, left } = this.$refs.clock.getBoundingClientRect();
      const { width: innerWidth } = this.$refs.innerClock.getBoundingClientRect();
      const { clientX, clientY } = "touches" in e ? e.touches[0] : e;
      const center = { x: width / 2, y: -width / 2 };
      const coords = { x: clientX - left, y: top - clientY };
      const handAngle = Math.round(this.angle(center, coords) - this.rotate + 360) % 360;
      const insideClick = this.double && this.euclidean(center, coords) < (innerWidth + innerWidth * this.innerRadiusScale) / 4;
      const checksCount = Math.ceil(15 / this.degreesPerUnit);
      let value;
      for (let i = 0; i < checksCount; i++) {
        value = this.angleToValue(handAngle + i * this.degreesPerUnit, insideClick);
        if (this.isAllowed(value))
          return this.setMouseDownValue(value);
        value = this.angleToValue(handAngle - i * this.degreesPerUnit, insideClick);
        if (this.isAllowed(value))
          return this.setMouseDownValue(value);
      }
    },
    angleToValue(angle, insideClick) {
      const value = (Math.round(angle / this.degreesPerUnit) + (insideClick ? this.roundCount : 0)) % this.count + this.min;
      if (angle < 360 - this.degreesPerUnit / 2)
        return value;
      return insideClick ? this.max - this.roundCount + 1 : this.min;
    },
    setMouseDownValue(value) {
      if (this.valueOnMouseDown === null) {
        this.valueOnMouseDown = value;
      }
      this.valueOnMouseUp = value;
      this.update(value);
    },
    update(value) {
      if (this.inputValue !== value) {
        this.inputValue = value;
        this.$emit("input", value);
      }
    },
    euclidean(p0, p1) {
      const dx = p1.x - p0.x;
      const dy = p1.y - p0.y;
      return Math.sqrt(dx * dx + dy * dy);
    },
    angle(center, p1) {
      const value = 2 * Math.atan2(p1.y - center.y - this.euclidean(center, p1), p1.x - center.x);
      return Math.abs(value * 180 / Math.PI);
    }
  },
  render(h) {
    const data = {
      staticClass: "v-time-picker-clock",
      class: {
        "v-time-picker-clock--indeterminate": this.value == null,
        ...this.themeClasses
      },
      on: this.readonly || this.disabled ? void 0 : {
        mousedown: this.onMouseDown,
        mouseup: this.onMouseUp,
        mouseleave: (e) => this.isDragging && this.onMouseUp(e),
        touchstart: this.onMouseDown,
        touchend: this.onMouseUp,
        mousemove: this.onDragMove,
        touchmove: this.onDragMove
      },
      ref: "clock"
    };
    if (this.scrollable && data.on) {
      data.on.wheel = this.wheel;
    }
    return h("div", data, [
      h("div", {
        staticClass: "v-time-picker-clock__inner",
        ref: "innerClock"
      }, [
        this.genHand(),
        this.genValues()
      ])
    ]);
  }
});
const rangeHours24 = createRange(24);
const rangeHours12am = createRange(12);
const rangeHours12pm = rangeHours12am.map((v) => v + 12);
const range60 = createRange(60);
const selectingNames = { 1: "hour", 2: "minute", 3: "second" };
const VTimePicker = mixins(
  Picker,
  PickerButton
  /* @vue/component */
).extend({
  name: "v-time-picker",
  props: {
    activePicker: String,
    allowedHours: [Function, Array],
    allowedMinutes: [Function, Array],
    allowedSeconds: [Function, Array],
    disabled: Boolean,
    format: {
      type: String,
      default: "ampm",
      validator(val) {
        return ["ampm", "24hr"].includes(val);
      }
    },
    min: String,
    max: String,
    readonly: Boolean,
    scrollable: Boolean,
    useSeconds: Boolean,
    value: null,
    ampmInTitle: Boolean
  },
  data() {
    return {
      inputHour: null,
      inputMinute: null,
      inputSecond: null,
      lazyInputHour: null,
      lazyInputMinute: null,
      lazyInputSecond: null,
      period: "am",
      selecting: SelectingTimes.Hour
    };
  },
  computed: {
    selectingHour: {
      get() {
        return this.selecting === SelectingTimes.Hour;
      },
      set(v) {
        this.selecting = SelectingTimes.Hour;
      }
    },
    selectingMinute: {
      get() {
        return this.selecting === SelectingTimes.Minute;
      },
      set(v) {
        this.selecting = SelectingTimes.Minute;
      }
    },
    selectingSecond: {
      get() {
        return this.selecting === SelectingTimes.Second;
      },
      set(v) {
        this.selecting = SelectingTimes.Second;
      }
    },
    isAllowedHourCb() {
      let cb;
      if (this.allowedHours instanceof Array) {
        cb = (val) => this.allowedHours.includes(val);
      } else {
        cb = this.allowedHours;
      }
      if (!this.min && !this.max)
        return cb;
      const minHour = this.min ? Number(this.min.split(":")[0]) : 0;
      const maxHour = this.max ? Number(this.max.split(":")[0]) : 23;
      return (val) => {
        return val >= minHour * 1 && val <= maxHour * 1 && (!cb || cb(val));
      };
    },
    isAllowedMinuteCb() {
      let cb;
      const isHourAllowed = !this.isAllowedHourCb || this.inputHour === null || this.isAllowedHourCb(this.inputHour);
      if (this.allowedMinutes instanceof Array) {
        cb = (val) => this.allowedMinutes.includes(val);
      } else {
        cb = this.allowedMinutes;
      }
      if (!this.min && !this.max) {
        return isHourAllowed ? cb : () => false;
      }
      const [minHour, minMinute] = this.min ? this.min.split(":").map(Number) : [0, 0];
      const [maxHour, maxMinute] = this.max ? this.max.split(":").map(Number) : [23, 59];
      const minTime = minHour * 60 + minMinute * 1;
      const maxTime = maxHour * 60 + maxMinute * 1;
      return (val) => {
        const time = 60 * this.inputHour + val;
        return time >= minTime && time <= maxTime && isHourAllowed && (!cb || cb(val));
      };
    },
    isAllowedSecondCb() {
      let cb;
      const isHourAllowed = !this.isAllowedHourCb || this.inputHour === null || this.isAllowedHourCb(this.inputHour);
      const isMinuteAllowed = isHourAllowed && (!this.isAllowedMinuteCb || this.inputMinute === null || this.isAllowedMinuteCb(this.inputMinute));
      if (this.allowedSeconds instanceof Array) {
        cb = (val) => this.allowedSeconds.includes(val);
      } else {
        cb = this.allowedSeconds;
      }
      if (!this.min && !this.max) {
        return isMinuteAllowed ? cb : () => false;
      }
      const [minHour, minMinute, minSecond] = this.min ? this.min.split(":").map(Number) : [0, 0, 0];
      const [maxHour, maxMinute, maxSecond] = this.max ? this.max.split(":").map(Number) : [23, 59, 59];
      const minTime = minHour * 3600 + minMinute * 60 + (minSecond || 0) * 1;
      const maxTime = maxHour * 3600 + maxMinute * 60 + (maxSecond || 0) * 1;
      return (val) => {
        const time = 3600 * this.inputHour + 60 * this.inputMinute + val;
        return time >= minTime && time <= maxTime && isMinuteAllowed && (!cb || cb(val));
      };
    },
    isAmPm() {
      return this.format === "ampm";
    }
  },
  watch: {
    activePicker: "setPicker",
    selecting: "emitPicker",
    value: "setInputData"
  },
  mounted() {
    this.setInputData(this.value);
    this.$on("update:period", this.setPeriod);
  },
  methods: {
    genValue() {
      if (this.inputHour != null && this.inputMinute != null && (!this.useSeconds || this.inputSecond != null)) {
        return `${pad(this.inputHour)}:${pad(this.inputMinute)}` + (this.useSeconds ? `:${pad(this.inputSecond)}` : "");
      }
      return null;
    },
    emitValue() {
      const value = this.genValue();
      if (value !== null)
        this.$emit("input", value);
    },
    emitPicker(value) {
      let activePicker = "HOUR";
      if (value === SelectingTimes.Minute) {
        activePicker = "MINUTE";
      } else if (value === SelectingTimes.Second) {
        activePicker = "SECOND";
      }
      this.$emit("update:active-picker", activePicker);
    },
    setPicker(picker) {
      if (picker === "HOUR")
        this.selecting = SelectingTimes.Hour;
      else if (picker === "MINUTE")
        this.selecting = SelectingTimes.Minute;
      else if (picker === "SECOND" && this.useSeconds)
        this.selecting = SelectingTimes.Second;
    },
    setPeriod(period) {
      this.period = period;
      if (this.inputHour != null) {
        const newHour = this.inputHour + (period === "am" ? -12 : 12);
        this.inputHour = this.firstAllowed("hour", newHour);
        this.emitValue();
      }
    },
    setInputData(value) {
      if (value == null || value === "") {
        this.inputHour = null;
        this.inputMinute = null;
        this.inputSecond = null;
      } else if (value instanceof Date) {
        this.inputHour = value.getHours();
        this.inputMinute = value.getMinutes();
        this.inputSecond = value.getSeconds();
      } else {
        const [, hour, minute, , second, period] = value.trim().toLowerCase().match(/^(\d+):(\d+)(:(\d+))?([ap]m)?$/) || new Array(6);
        this.inputHour = period ? this.convert12to24(parseInt(hour, 10), period) : parseInt(hour, 10);
        this.inputMinute = parseInt(minute, 10);
        this.inputSecond = parseInt(second || 0, 10);
      }
      this.period = this.inputHour == null || this.inputHour < 12 ? "am" : "pm";
    },
    convert24to12(hour) {
      return hour ? (hour - 1) % 12 + 1 : 12;
    },
    convert12to24(hour, period) {
      return hour % 12 + (period === "pm" ? 12 : 0);
    },
    onInput(value) {
      if (this.selecting === SelectingTimes.Hour) {
        this.inputHour = this.isAmPm ? this.convert12to24(value, this.period) : value;
      } else if (this.selecting === SelectingTimes.Minute) {
        this.inputMinute = value;
      } else {
        this.inputSecond = value;
      }
      this.emitValue();
    },
    onChange(value) {
      this.$emit(`click:${selectingNames[this.selecting]}`, value);
      const emitChange = this.selecting === (this.useSeconds ? SelectingTimes.Second : SelectingTimes.Minute);
      if (this.selecting === SelectingTimes.Hour) {
        this.selecting = SelectingTimes.Minute;
      } else if (this.useSeconds && this.selecting === SelectingTimes.Minute) {
        this.selecting = SelectingTimes.Second;
      }
      if (this.inputHour === this.lazyInputHour && this.inputMinute === this.lazyInputMinute && (!this.useSeconds || this.inputSecond === this.lazyInputSecond))
        return;
      const time = this.genValue();
      if (time === null)
        return;
      this.lazyInputHour = this.inputHour;
      this.lazyInputMinute = this.inputMinute;
      this.useSeconds && (this.lazyInputSecond = this.inputSecond);
      emitChange && this.$emit("change", time);
    },
    firstAllowed(type2, value) {
      const allowedFn = type2 === "hour" ? this.isAllowedHourCb : type2 === "minute" ? this.isAllowedMinuteCb : this.isAllowedSecondCb;
      if (!allowedFn)
        return value;
      const range = type2 === "minute" ? range60 : type2 === "second" ? range60 : this.isAmPm ? value < 12 ? rangeHours12am : rangeHours12pm : rangeHours24;
      const first = range.find((v) => allowedFn((v + value) % range.length + range[0]));
      return ((first || 0) + value) % range.length + range[0];
    },
    genClock() {
      return this.$createElement(VTimePickerClock, {
        props: {
          allowedValues: this.selecting === SelectingTimes.Hour ? this.isAllowedHourCb : this.selecting === SelectingTimes.Minute ? this.isAllowedMinuteCb : this.isAllowedSecondCb,
          color: this.color,
          dark: this.dark,
          disabled: this.disabled,
          double: this.selecting === SelectingTimes.Hour && !this.isAmPm,
          format: this.selecting === SelectingTimes.Hour ? this.isAmPm ? this.convert24to12 : (val) => val : (val) => pad(val, 2),
          light: this.light,
          max: this.selecting === SelectingTimes.Hour ? this.isAmPm && this.period === "am" ? 11 : 23 : 59,
          min: this.selecting === SelectingTimes.Hour && this.isAmPm && this.period === "pm" ? 12 : 0,
          readonly: this.readonly,
          scrollable: this.scrollable,
          size: Number(this.width) - (!this.fullWidth && this.landscape ? 80 : 20),
          step: this.selecting === SelectingTimes.Hour ? 1 : 5,
          value: this.selecting === SelectingTimes.Hour ? this.inputHour : this.selecting === SelectingTimes.Minute ? this.inputMinute : this.inputSecond
        },
        on: {
          input: this.onInput,
          change: this.onChange
        },
        ref: "clock"
      });
    },
    genClockAmPm() {
      return this.$createElement("div", this.setTextColor(this.color || "primary", {
        staticClass: "v-time-picker-clock__ampm"
      }), [
        this.genPickerButton("period", "am", this.$vuetify.lang.t("$vuetify.timePicker.am"), this.disabled || this.readonly),
        this.genPickerButton("period", "pm", this.$vuetify.lang.t("$vuetify.timePicker.pm"), this.disabled || this.readonly)
      ]);
    },
    genPickerBody() {
      return this.$createElement("div", {
        staticClass: "v-time-picker-clock__container",
        key: this.selecting
      }, [
        !this.ampmInTitle && this.isAmPm && this.genClockAmPm(),
        this.genClock()
      ]);
    },
    genPickerTitle() {
      return this.$createElement(VTimePickerTitle, {
        props: {
          ampm: this.isAmPm,
          ampmReadonly: this.isAmPm && !this.ampmInTitle,
          disabled: this.disabled,
          hour: this.inputHour,
          minute: this.inputMinute,
          second: this.inputSecond,
          period: this.period,
          readonly: this.readonly,
          useSeconds: this.useSeconds,
          selecting: this.selecting
        },
        on: {
          "update:selecting": (value) => this.selecting = value,
          "update:period": (period) => this.$emit("update:period", period)
        },
        ref: "title",
        slot: "title"
      });
    }
  },
  render() {
    return this.genPicker("v-picker--time");
  }
});
const VToolbarTitle = createSimpleFunctional("v-toolbar__title");
const VToolbarItems = createSimpleFunctional("v-toolbar__items");
const VTooltip = mixins(Colorable, Delayable, Dependent, Menuable).extend({
  name: "v-tooltip",
  props: {
    closeDelay: {
      type: [Number, String],
      default: 0
    },
    disabled: Boolean,
    openDelay: {
      type: [Number, String],
      default: 0
    },
    openOnHover: {
      type: Boolean,
      default: true
    },
    openOnFocus: {
      type: Boolean,
      default: true
    },
    tag: {
      type: String,
      default: "span"
    },
    transition: String
  },
  data: () => ({
    calculatedMinWidth: 0,
    closeDependents: false
  }),
  computed: {
    calculatedLeft() {
      const { activator, content } = this.dimensions;
      const unknown = !this.bottom && !this.left && !this.top && !this.right;
      const activatorLeft = this.attach !== false ? activator.offsetLeft : activator.left;
      let left = 0;
      if (this.top || this.bottom || unknown) {
        left = activatorLeft + activator.width / 2 - content.width / 2;
      } else if (this.left || this.right) {
        left = activatorLeft + (this.right ? activator.width : -content.width) + (this.right ? 10 : -10);
      }
      if (this.nudgeLeft)
        left -= parseInt(this.nudgeLeft);
      if (this.nudgeRight)
        left += parseInt(this.nudgeRight);
      return `${this.calcXOverflow(left, this.dimensions.content.width)}px`;
    },
    calculatedTop() {
      const { activator, content } = this.dimensions;
      const activatorTop = this.attach !== false ? activator.offsetTop : activator.top;
      let top = 0;
      if (this.top || this.bottom) {
        top = activatorTop + (this.bottom ? activator.height : -content.height) + (this.bottom ? 10 : -10);
      } else if (this.left || this.right) {
        top = activatorTop + activator.height / 2 - content.height / 2;
      }
      if (this.nudgeTop)
        top -= parseInt(this.nudgeTop);
      if (this.nudgeBottom)
        top += parseInt(this.nudgeBottom);
      if (this.attach === false)
        top += this.pageYOffset;
      return `${this.calcYOverflow(top)}px`;
    },
    classes() {
      return {
        "v-tooltip--top": this.top,
        "v-tooltip--right": this.right,
        "v-tooltip--bottom": this.bottom,
        "v-tooltip--left": this.left,
        "v-tooltip--attached": this.attach === "" || this.attach === true || this.attach === "attach"
      };
    },
    computedTransition() {
      if (this.transition)
        return this.transition;
      return this.isActive ? "scale-transition" : "fade-transition";
    },
    offsetY() {
      return this.top || this.bottom;
    },
    offsetX() {
      return this.left || this.right;
    },
    styles() {
      return {
        left: this.calculatedLeft,
        maxWidth: convertToUnit(this.maxWidth),
        minWidth: convertToUnit(this.minWidth),
        top: this.calculatedTop,
        zIndex: this.zIndex || this.activeZIndex
      };
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      this.value && this.callActivate();
    });
  },
  mounted() {
    if (getSlotType(this, "activator", true) === "v-slot") {
      consoleError(`v-tooltip's activator slot must be bound, try '<template #activator="data"><v-btn v-on="data.on>'`, this);
    }
  },
  methods: {
    activate() {
      this.updateDimensions();
      requestAnimationFrame(this.startTransition);
    },
    deactivate() {
      this.runDelay("close");
    },
    genActivatorListeners() {
      const listeners = Activatable.options.methods.genActivatorListeners.call(this);
      if (this.openOnFocus) {
        listeners.focus = (e) => {
          this.getActivator(e);
          this.runDelay("open");
        };
        listeners.blur = (e) => {
          this.getActivator(e);
          this.runDelay("close");
        };
      }
      listeners.keydown = (e) => {
        if (e.keyCode === keyCodes.esc) {
          this.getActivator(e);
          this.runDelay("close");
        }
      };
      return listeners;
    },
    genActivatorAttributes() {
      return {
        "aria-haspopup": true,
        "aria-expanded": String(this.isActive)
      };
    },
    genTransition() {
      const content = this.genContent();
      if (!this.computedTransition)
        return content;
      return this.$createElement("transition", {
        props: {
          name: this.computedTransition
        }
      }, [content]);
    },
    genContent() {
      return this.$createElement(
        "div",
        this.setBackgroundColor(this.color, {
          staticClass: "v-tooltip__content",
          class: {
            [this.contentClass]: true,
            menuable__content__active: this.isActive,
            "v-tooltip__content--fixed": this.activatorFixed
          },
          style: this.styles,
          attrs: this.getScopeIdAttrs(),
          directives: [{
            name: "show",
            value: this.isContentActive
          }],
          ref: "content"
        }),
        this.getContentSlot()
      );
    }
  },
  render(h) {
    return h(this.tag, {
      staticClass: "v-tooltip",
      class: this.classes
    }, [
      this.showLazyContent(() => [this.genTransition()]),
      this.genActivator()
    ]);
  }
});
const baseMixins = mixins(
  Colorable,
  inject("treeview")
);
const VTreeviewNodeProps = {
  activatable: Boolean,
  activeClass: {
    type: String,
    default: "v-treeview-node--active"
  },
  color: {
    type: String,
    default: "primary"
  },
  disablePerNode: Boolean,
  expandIcon: {
    type: String,
    default: "$subgroup"
  },
  indeterminateIcon: {
    type: String,
    default: "$checkboxIndeterminate"
  },
  itemChildren: {
    type: String,
    default: "children"
  },
  itemDisabled: {
    type: String,
    default: "disabled"
  },
  itemKey: {
    type: String,
    default: "id"
  },
  itemText: {
    type: String,
    default: "name"
  },
  loadChildren: Function,
  loadingIcon: {
    type: String,
    default: "$loading"
  },
  offIcon: {
    type: String,
    default: "$checkboxOff"
  },
  onIcon: {
    type: String,
    default: "$checkboxOn"
  },
  openOnClick: Boolean,
  rounded: Boolean,
  selectable: Boolean,
  selectedColor: {
    type: String,
    default: "accent"
  },
  shaped: Boolean,
  transition: Boolean,
  selectionType: {
    type: String,
    default: "leaf",
    validator: (v) => ["leaf", "independent"].includes(v)
  }
};
const VTreeviewNode = baseMixins.extend().extend({
  name: "v-treeview-node",
  inject: {
    treeview: {
      default: null
    }
  },
  props: {
    level: Number,
    item: {
      type: Object,
      default: () => null
    },
    parentIsDisabled: Boolean,
    ...VTreeviewNodeProps
  },
  data: () => ({
    hasLoaded: false,
    isActive: false,
    // Node is selected (row)
    isIndeterminate: false,
    // Node has at least one selected child
    isLoading: false,
    isOpen: false,
    // Node is open/expanded
    isSelected: false
    // Node is selected (checkbox)
  }),
  computed: {
    disabled() {
      return getObjectValueByPath(this.item, this.itemDisabled) || !this.disablePerNode && (this.parentIsDisabled && this.selectionType === "leaf");
    },
    key() {
      return getObjectValueByPath(this.item, this.itemKey);
    },
    children() {
      const children = getObjectValueByPath(this.item, this.itemChildren);
      return children && children.filter((child) => !this.treeview.isExcluded(getObjectValueByPath(child, this.itemKey)));
    },
    text() {
      return getObjectValueByPath(this.item, this.itemText);
    },
    scopedProps() {
      return {
        item: this.item,
        leaf: !this.children,
        selected: this.isSelected,
        indeterminate: this.isIndeterminate,
        active: this.isActive,
        open: this.isOpen
      };
    },
    computedIcon() {
      if (this.isIndeterminate)
        return this.indeterminateIcon;
      else if (this.isSelected)
        return this.onIcon;
      else
        return this.offIcon;
    },
    hasChildren() {
      return !!this.children && (!!this.children.length || !!this.loadChildren);
    }
  },
  created() {
    this.treeview.register(this);
  },
  beforeDestroy() {
    this.treeview.unregister(this);
  },
  methods: {
    checkChildren() {
      return new Promise((resolve) => {
        if (!this.children || this.children.length || !this.loadChildren || this.hasLoaded)
          return resolve();
        this.isLoading = true;
        resolve(this.loadChildren(this.item));
      }).then(() => {
        this.isLoading = false;
        this.hasLoaded = true;
      });
    },
    open() {
      this.isOpen = !this.isOpen;
      this.treeview.updateOpen(this.key, this.isOpen);
      this.treeview.emitOpen();
    },
    genLabel() {
      const children = [];
      if (this.$scopedSlots.label)
        children.push(this.$scopedSlots.label(this.scopedProps));
      else
        children.push(this.text);
      return this.$createElement("div", {
        slot: "label",
        staticClass: "v-treeview-node__label"
      }, children);
    },
    genPrependSlot() {
      if (!this.$scopedSlots.prepend)
        return null;
      return this.$createElement("div", {
        staticClass: "v-treeview-node__prepend"
      }, this.$scopedSlots.prepend(this.scopedProps));
    },
    genAppendSlot() {
      if (!this.$scopedSlots.append)
        return null;
      return this.$createElement("div", {
        staticClass: "v-treeview-node__append"
      }, this.$scopedSlots.append(this.scopedProps));
    },
    genContent() {
      const children = [
        this.genPrependSlot(),
        this.genLabel(),
        this.genAppendSlot()
      ];
      return this.$createElement("div", {
        staticClass: "v-treeview-node__content"
      }, children);
    },
    genToggle() {
      return this.$createElement(VIcon$1, {
        staticClass: "v-treeview-node__toggle",
        class: {
          "v-treeview-node__toggle--open": this.isOpen,
          "v-treeview-node__toggle--loading": this.isLoading
        },
        slot: "prepend",
        on: {
          click: (e) => {
            e.stopPropagation();
            if (this.isLoading)
              return;
            this.checkChildren().then(() => this.open());
          }
        }
      }, [this.isLoading ? this.loadingIcon : this.expandIcon]);
    },
    genCheckbox() {
      return this.$createElement(VIcon$1, {
        staticClass: "v-treeview-node__checkbox",
        props: {
          color: this.isSelected || this.isIndeterminate ? this.selectedColor : void 0,
          disabled: this.disabled
        },
        on: {
          click: (e) => {
            e.stopPropagation();
            if (this.isLoading)
              return;
            this.checkChildren().then(() => {
              this.$nextTick(() => {
                this.isSelected = !this.isSelected;
                this.isIndeterminate = false;
                this.treeview.updateSelected(this.key, this.isSelected);
                this.treeview.emitSelected();
              });
            });
          }
        }
      }, [this.computedIcon]);
    },
    genLevel(level) {
      return createRange(level).map(() => this.$createElement("div", {
        staticClass: "v-treeview-node__level"
      }));
    },
    genNode() {
      const children = [this.genContent()];
      if (this.selectable)
        children.unshift(this.genCheckbox());
      if (this.hasChildren) {
        children.unshift(this.genToggle());
      } else {
        children.unshift(...this.genLevel(1));
      }
      children.unshift(...this.genLevel(this.level));
      return this.$createElement("div", this.setTextColor(this.isActive && this.color, {
        staticClass: "v-treeview-node__root",
        class: {
          [this.activeClass]: this.isActive
        },
        on: {
          click: () => {
            if (this.openOnClick && this.hasChildren) {
              this.checkChildren().then(this.open);
            } else if (this.activatable && !this.disabled) {
              this.isActive = !this.isActive;
              this.treeview.updateActive(this.key, this.isActive);
              this.treeview.emitActive();
            }
          }
        }
      }), children);
    },
    genChild(item, parentIsDisabled) {
      return this.$createElement(VTreeviewNode, {
        key: getObjectValueByPath(item, this.itemKey),
        props: {
          activatable: this.activatable,
          activeClass: this.activeClass,
          item,
          selectable: this.selectable,
          selectedColor: this.selectedColor,
          color: this.color,
          disablePerNode: this.disablePerNode,
          expandIcon: this.expandIcon,
          indeterminateIcon: this.indeterminateIcon,
          offIcon: this.offIcon,
          onIcon: this.onIcon,
          loadingIcon: this.loadingIcon,
          itemKey: this.itemKey,
          itemText: this.itemText,
          itemDisabled: this.itemDisabled,
          itemChildren: this.itemChildren,
          loadChildren: this.loadChildren,
          transition: this.transition,
          openOnClick: this.openOnClick,
          rounded: this.rounded,
          shaped: this.shaped,
          level: this.level + 1,
          selectionType: this.selectionType,
          parentIsDisabled
        },
        scopedSlots: this.$scopedSlots
      });
    },
    genChildrenWrapper() {
      if (!this.isOpen || !this.children)
        return null;
      const children = [this.children.map((c) => this.genChild(c, this.disabled))];
      return this.$createElement("div", {
        staticClass: "v-treeview-node__children"
      }, children);
    },
    genTransition() {
      return this.$createElement(VExpandTransition, [this.genChildrenWrapper()]);
    }
  },
  render(h) {
    const children = [this.genNode()];
    if (this.transition)
      children.push(this.genTransition());
    else
      children.push(this.genChildrenWrapper());
    return h("div", {
      staticClass: "v-treeview-node",
      class: {
        "v-treeview-node--leaf": !this.hasChildren,
        "v-treeview-node--click": this.openOnClick,
        "v-treeview-node--disabled": this.disabled,
        "v-treeview-node--rounded": this.rounded,
        "v-treeview-node--shaped": this.shaped,
        "v-treeview-node--selected": this.isSelected
      },
      attrs: {
        "aria-expanded": String(this.isOpen)
      }
    }, children);
  }
});
const VTreeviewNode$1 = VTreeviewNode;
function filterTreeItem(item, search, textKey) {
  const text = getObjectValueByPath(item, textKey);
  return text.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
}
function filterTreeItems(filter, item, search, idKey, textKey, childrenKey, excluded) {
  if (filter(item, search, textKey)) {
    return true;
  }
  const children = getObjectValueByPath(item, childrenKey);
  if (children) {
    let match = false;
    for (let i = 0; i < children.length; i++) {
      if (filterTreeItems(filter, children[i], search, idKey, textKey, childrenKey, excluded)) {
        match = true;
      }
    }
    if (match)
      return true;
  }
  excluded.add(getObjectValueByPath(item, idKey));
  return false;
}
const VTreeview = mixins(
  provide("treeview"),
  Themeable$1
  /* @vue/component */
).extend({
  name: "v-treeview",
  provide() {
    return { treeview: this };
  },
  props: {
    active: {
      type: Array,
      default: () => []
    },
    dense: Boolean,
    disabled: Boolean,
    filter: Function,
    hoverable: Boolean,
    items: {
      type: Array,
      default: () => []
    },
    multipleActive: Boolean,
    open: {
      type: Array,
      default: () => []
    },
    openAll: Boolean,
    returnObject: {
      type: Boolean,
      default: false
      // TODO: Should be true in next major
    },
    search: String,
    value: {
      type: Array,
      default: () => []
    },
    ...VTreeviewNodeProps
  },
  data: () => ({
    level: -1,
    activeCache: /* @__PURE__ */ new Set(),
    nodes: {},
    openCache: /* @__PURE__ */ new Set(),
    selectedCache: /* @__PURE__ */ new Set()
  }),
  computed: {
    excludedItems() {
      const excluded = /* @__PURE__ */ new Set();
      if (!this.search)
        return excluded;
      for (let i = 0; i < this.items.length; i++) {
        filterTreeItems(
          this.filter || filterTreeItem,
          this.items[i],
          this.search,
          this.itemKey,
          this.itemText,
          this.itemChildren,
          excluded
        );
      }
      return excluded;
    }
  },
  watch: {
    items: {
      handler() {
        const oldKeys = Object.keys(this.nodes).map((k) => getObjectValueByPath(this.nodes[k].item, this.itemKey));
        const newKeys = this.getKeys(this.items);
        const diff = arrayDiff(newKeys, oldKeys);
        if (!diff.length && newKeys.length < oldKeys.length)
          return;
        diff.forEach((k) => delete this.nodes[k]);
        const oldSelectedCache = [...this.selectedCache];
        this.selectedCache = /* @__PURE__ */ new Set();
        this.activeCache = /* @__PURE__ */ new Set();
        this.openCache = /* @__PURE__ */ new Set();
        this.buildTree(this.items);
        if (!deepEqual(oldSelectedCache, [...this.selectedCache]))
          this.emitSelected();
      },
      deep: true
    },
    active(value) {
      this.handleNodeCacheWatcher(value, this.activeCache, this.updateActive, this.emitActive);
    },
    value(value) {
      this.handleNodeCacheWatcher(value, this.selectedCache, this.updateSelected, this.emitSelected);
    },
    open(value) {
      this.handleNodeCacheWatcher(value, this.openCache, this.updateOpen, this.emitOpen);
    }
  },
  created() {
    const getValue = (key) => this.returnObject ? getObjectValueByPath(key, this.itemKey) : key;
    this.buildTree(this.items);
    for (const value of this.value.map(getValue)) {
      this.updateSelected(value, true, true);
    }
    for (const active of this.active.map(getValue)) {
      this.updateActive(active, true);
    }
  },
  mounted() {
    if (this.$slots.prepend || this.$slots.append) {
      consoleWarn("The prepend and append slots require a slot-scope attribute", this);
    }
    if (this.openAll) {
      this.updateAll(true);
    } else {
      this.open.forEach((key) => this.updateOpen(this.returnObject ? getObjectValueByPath(key, this.itemKey) : key, true));
      this.emitOpen();
    }
  },
  methods: {
    /** @public */
    updateAll(value) {
      Object.keys(this.nodes).forEach((key) => this.updateOpen(getObjectValueByPath(this.nodes[key].item, this.itemKey), value));
      this.emitOpen();
    },
    getKeys(items, keys2 = []) {
      for (let i = 0; i < items.length; i++) {
        const key = getObjectValueByPath(items[i], this.itemKey);
        keys2.push(key);
        const children = getObjectValueByPath(items[i], this.itemChildren);
        if (children) {
          keys2.push(...this.getKeys(children));
        }
      }
      return keys2;
    },
    buildTree(items, parent = null) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const key = getObjectValueByPath(item, this.itemKey);
        const children = getObjectValueByPath(item, this.itemChildren) ?? [];
        const oldNode = this.nodes.hasOwnProperty(key) ? this.nodes[key] : {
          isSelected: false,
          isIndeterminate: false,
          isActive: false,
          isOpen: false,
          vnode: null
        };
        const node = {
          vnode: oldNode.vnode,
          parent,
          children: children.map((c) => getObjectValueByPath(c, this.itemKey)),
          item
        };
        this.buildTree(children, key);
        if (this.selectionType !== "independent" && parent !== null && !this.nodes.hasOwnProperty(key) && this.nodes.hasOwnProperty(parent)) {
          node.isSelected = this.nodes[parent].isSelected;
        } else {
          node.isSelected = oldNode.isSelected;
          node.isIndeterminate = oldNode.isIndeterminate;
        }
        node.isActive = oldNode.isActive;
        node.isOpen = oldNode.isOpen;
        this.nodes[key] = node;
        if (children.length && this.selectionType !== "independent") {
          const { isSelected, isIndeterminate } = this.calculateState(key, this.nodes);
          node.isSelected = isSelected;
          node.isIndeterminate = isIndeterminate;
        }
        if (this.nodes[key].isSelected && (this.selectionType === "independent" || node.children.length === 0))
          this.selectedCache.add(key);
        if (this.nodes[key].isActive)
          this.activeCache.add(key);
        if (this.nodes[key].isOpen)
          this.openCache.add(key);
        this.updateVnodeState(key);
      }
    },
    calculateState(node, state) {
      const children = state[node].children;
      const counts = children.reduce((counts2, child) => {
        counts2[0] += +Boolean(state[child].isSelected);
        counts2[1] += +Boolean(state[child].isIndeterminate);
        return counts2;
      }, [0, 0]);
      const isSelected = !!children.length && counts[0] === children.length;
      const isIndeterminate = !isSelected && (counts[0] > 0 || counts[1] > 0);
      return {
        isSelected,
        isIndeterminate
      };
    },
    emitOpen() {
      this.emitNodeCache("update:open", this.openCache);
    },
    emitSelected() {
      this.emitNodeCache("input", this.selectedCache);
    },
    emitActive() {
      this.emitNodeCache("update:active", this.activeCache);
    },
    emitNodeCache(event, cache2) {
      this.$emit(event, this.returnObject ? [...cache2].map((key) => this.nodes[key].item) : [...cache2]);
    },
    handleNodeCacheWatcher(value, cache2, updateFn, emitFn) {
      value = this.returnObject ? value.map((v) => getObjectValueByPath(v, this.itemKey)) : value;
      const old = [...cache2];
      if (deepEqual(old, value))
        return;
      old.forEach((key) => updateFn(key, false));
      value.forEach((key) => updateFn(key, true));
      emitFn();
    },
    getDescendants(key, descendants = []) {
      const children = this.nodes[key].children;
      descendants.push(...children);
      for (let i = 0; i < children.length; i++) {
        descendants = this.getDescendants(children[i], descendants);
      }
      return descendants;
    },
    getParents(key) {
      let parent = this.nodes[key].parent;
      const parents = [];
      while (parent !== null) {
        parents.push(parent);
        parent = this.nodes[parent].parent;
      }
      return parents;
    },
    register(node) {
      const key = getObjectValueByPath(node.item, this.itemKey);
      this.nodes[key].vnode = node;
      this.updateVnodeState(key);
    },
    unregister(node) {
      const key = getObjectValueByPath(node.item, this.itemKey);
      if (this.nodes[key])
        this.nodes[key].vnode = null;
    },
    isParent(key) {
      return this.nodes[key].children && this.nodes[key].children.length;
    },
    updateActive(key, isActive) {
      if (!this.nodes.hasOwnProperty(key))
        return;
      if (!this.multipleActive) {
        this.activeCache.forEach((active) => {
          this.nodes[active].isActive = false;
          this.updateVnodeState(active);
          this.activeCache.delete(active);
        });
      }
      const node = this.nodes[key];
      if (!node)
        return;
      if (isActive)
        this.activeCache.add(key);
      else
        this.activeCache.delete(key);
      node.isActive = isActive;
      this.updateVnodeState(key);
    },
    updateSelected(key, isSelected, isForced = false) {
      if (!this.nodes.hasOwnProperty(key))
        return;
      const changed = /* @__PURE__ */ new Map();
      if (this.selectionType !== "independent") {
        for (const descendant of this.getDescendants(key)) {
          if (!getObjectValueByPath(this.nodes[descendant].item, this.itemDisabled) || isForced) {
            this.nodes[descendant].isSelected = isSelected;
            this.nodes[descendant].isIndeterminate = false;
            changed.set(descendant, isSelected);
          }
        }
        const calculated = this.calculateState(key, this.nodes);
        this.nodes[key].isSelected = isSelected;
        this.nodes[key].isIndeterminate = calculated.isIndeterminate;
        changed.set(key, isSelected);
        for (const parent of this.getParents(key)) {
          const calculated2 = this.calculateState(parent, this.nodes);
          this.nodes[parent].isSelected = calculated2.isSelected;
          this.nodes[parent].isIndeterminate = calculated2.isIndeterminate;
          changed.set(parent, calculated2.isSelected);
        }
      } else {
        this.nodes[key].isSelected = isSelected;
        this.nodes[key].isIndeterminate = false;
        changed.set(key, isSelected);
      }
      for (const [key2, value] of changed.entries()) {
        this.updateVnodeState(key2);
        if (this.selectionType === "leaf" && this.isParent(key2))
          continue;
        value === true ? this.selectedCache.add(key2) : this.selectedCache.delete(key2);
      }
    },
    updateOpen(key, isOpen) {
      if (!this.nodes.hasOwnProperty(key))
        return;
      const node = this.nodes[key];
      const children = getObjectValueByPath(node.item, this.itemChildren);
      if (children && !children.length && node.vnode && !node.vnode.hasLoaded) {
        node.vnode.checkChildren().then(() => this.updateOpen(key, isOpen));
      } else if (children && children.length) {
        node.isOpen = isOpen;
        node.isOpen ? this.openCache.add(key) : this.openCache.delete(key);
        this.updateVnodeState(key);
      }
    },
    updateVnodeState(key) {
      const node = this.nodes[key];
      if (node && node.vnode) {
        node.vnode.isSelected = node.isSelected;
        node.vnode.isIndeterminate = node.isIndeterminate;
        node.vnode.isActive = node.isActive;
        node.vnode.isOpen = node.isOpen;
      }
    },
    isExcluded(key) {
      return !!this.search && this.excludedItems.has(key);
    }
  },
  render(h) {
    const children = this.items.length ? this.items.filter((item) => {
      return !this.isExcluded(getObjectValueByPath(item, this.itemKey));
    }).map((item) => {
      const genChild = VTreeviewNode$1.options.methods.genChild.bind(this);
      return genChild(item, this.disabled || getObjectValueByPath(item, this.itemDisabled));
    }) : this.$slots.default;
    return h("div", {
      staticClass: "v-treeview",
      class: {
        "v-treeview--hoverable": this.hoverable,
        "v-treeview--dense": this.dense,
        ...this.themeClasses
      }
    }, children);
  }
});
const VVirtualScroll = Measurable.extend({
  name: "v-virtual-scroll",
  directives: { Scroll: Scroll$1 },
  props: {
    bench: {
      type: [Number, String],
      default: 0
    },
    itemHeight: {
      type: [Number, String],
      required: true
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    first: 0,
    last: 0,
    scrollTop: 0
  }),
  computed: {
    __bench() {
      return parseInt(this.bench, 10);
    },
    __itemHeight() {
      return parseInt(this.itemHeight, 10);
    },
    firstToRender() {
      return Math.max(0, this.first - this.__bench);
    },
    lastToRender() {
      return Math.min(this.items.length, this.last + this.__bench);
    }
  },
  watch: {
    height: "onScroll",
    itemHeight: "onScroll"
  },
  mounted() {
    this.last = this.getLast(0);
  },
  methods: {
    getChildren() {
      return this.items.slice(
        this.firstToRender,
        this.lastToRender
      ).map(this.genChild);
    },
    genChild(item, index) {
      index += this.firstToRender;
      const top = convertToUnit(index * this.__itemHeight);
      return this.$createElement("div", {
        staticClass: "v-virtual-scroll__item",
        style: { top },
        key: index
      }, getSlot(this, "default", { index, item }));
    },
    getFirst() {
      return Math.floor(this.scrollTop / this.__itemHeight);
    },
    getLast(first) {
      const height = parseInt(this.height || 0, 10) || this.$el.clientHeight;
      return first + Math.ceil(height / this.__itemHeight);
    },
    onScroll() {
      this.scrollTop = this.$el.scrollTop;
      this.first = this.getFirst();
      this.last = this.getLast(this.first);
    }
  },
  render(h) {
    const content = h("div", {
      staticClass: "v-virtual-scroll__container",
      style: {
        height: convertToUnit(this.items.length * this.__itemHeight)
      }
    }, this.getChildren());
    return h("div", {
      staticClass: "v-virtual-scroll",
      style: this.measurableStyles,
      directives: [{
        name: "scroll",
        modifiers: { self: true },
        value: this.onScroll
      }],
      on: this.$listeners
    }, [content]);
  }
});
const useOptions = {
  iconfont: "mdi",
  theme: {
    themes: {
      light: {
        primary: "#0099CC",
        secondary: "#F86821",
        accent: "#82B1FF",
        error: "#DB2435",
        info: "#2A7AB0",
        success: "#0DAA4B",
        warning: "#FDF5AA"
      }
    },
    options: {
      customProperties: true
    }
  }
};
const vuetifyOptions = {
  theme: {
    options: {
      customProperties: true
    }
  },
  breakpoint: {
    thresholds: {
      xs: 768,
      sm: 991,
      md: 1199
    },
    scrollBarWidth: 0
  }
};
export {
  ClickOutside,
  Intersect,
  Mutate,
  Resize,
  Ripple,
  Scroll,
  Touch,
  VAlert,
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAppBarTitle,
  VAutocomplete,
  VAvatar,
  VBadge,
  VBanner,
  VBottomNavigation,
  VBottomSheet,
  VBreadcrumbs,
  VBreadcrumbsDivider,
  VBreadcrumbsItem,
  VBtn,
  VBtnToggle,
  VCalendar,
  VCalendarCategory,
  VCalendarDaily,
  VCalendarMonthly,
  VCalendarWeekly,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCarousel,
  VCarouselItem,
  VCarouselReverseTransition,
  VCarouselTransition,
  VCheckbox,
  VChip,
  VChipGroup,
  VCol,
  VColorPicker,
  VColorPickerCanvas,
  VColorPickerSwatches,
  VCombobox,
  VContainer,
  VContent,
  VCounter,
  VData,
  VDataFooter,
  VDataIterator,
  VDataTable,
  VDataTableHeader,
  VDatePicker,
  VDatePickerDateTable,
  VDatePickerHeader,
  VDatePickerMonthTable,
  VDatePickerTitle,
  VDatePickerYears,
  VDialog,
  VDialogBottomTransition,
  VDialogTopTransition,
  VDialogTransition,
  VDivider,
  VEditDialog,
  VExpandTransition,
  VExpandXTransition,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFabTransition,
  VFadeTransition,
  VFileInput,
  VFlex,
  VFooter,
  VForm,
  VHover,
  VIcon$1 as VIcon,
  VImg,
  VInput,
  VItem,
  VItemGroup,
  VLabel,
  VLayout,
  VLazy,
  VList,
  VListGroup,
  VListItem,
  VListItemAction,
  VListItemActionText,
  VListItemAvatar,
  VListItemContent,
  VListItemGroup,
  VListItemIcon,
  VListItemSubtitle,
  VListItemTitle,
  VMain,
  VMenu,
  VMenuTransition,
  VMessages,
  VNavigationDrawer,
  VOtpInput,
  VOverflowBtn,
  VOverlay,
  VPagination,
  VParallax,
  VPicker,
  VProgressCircular,
  VProgressLinear,
  VRadio,
  VRadioGroup,
  VRangeSlider,
  VRating,
  VResponsive,
  VRow,
  VScaleTransition,
  VScrollXReverseTransition,
  VScrollXTransition,
  VScrollYReverseTransition,
  VScrollYTransition,
  VSelect,
  VSheet,
  VSimpleCheckbox,
  VSimpleTable,
  VSkeletonLoader,
  VSlideGroup,
  VSlideItem,
  VSlideXReverseTransition,
  VSlideXTransition,
  VSlideYReverseTransition,
  VSlideYTransition,
  VSlider,
  VSnackbar,
  VSpacer,
  VSparkline,
  VSpeedDial,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VSubheader,
  VSwitch,
  VSystemBar,
  VTab,
  VTabItem,
  VTabReverseTransition,
  VTabTransition,
  VTableOverflow,
  VTabs,
  VTabsItems,
  VTabsSlider,
  VTextField,
  VTextarea,
  VThemeProvider,
  VTimePicker,
  VTimePickerClock,
  VTimePickerTitle,
  VTimeline,
  VTimelineItem,
  VToolbar,
  VToolbarItems,
  VToolbarTitle,
  VTooltip,
  VTreeview,
  VTreeviewNode$1 as VTreeviewNode,
  VVirtualScroll,
  VVirtualTable,
  VWindow,
  VWindowItem,
  colors,
  Vuetify as default,
  goTo,
  useOptions,
  vuetifyOptions
};
