import store from "@/store"
import { buildHelpers } from "@/store/composition-helpers"
import * as PreapprovalService from "@/lib/api/public/preapprovals-service"
import Vue from "vue"
import { Step1RouteName } from "@/router"
import { ref, computed } from "vue"
import router from "@/router"
import { Registration } from "@/lib/models/registration"
import analytics, { logError } from "@/lib/analytics"
import { extractInvalidFieldsFromForm } from "@/lib/analytics/helpers"
import { isRuntimeError } from "@/lib/util/errors"

const { mapGettersAndSettersToComputed } = buildHelpers<Registration>(
  () => store, "registration"
)

async function findPreapproval(): Promise<void> {
  const registrationState = mapGettersAndSettersToComputed([
    "offerCode",
    "firstName",
    "lastName",
    "city",
    "stateCode",
    "addressLine1",
    "zipCode",
    "isPreapprovalMode",
    "lastFourSSN",
    "isPreapprovalModeUpswing"
  ])

  // Not possible due to form validation, but adding for typescript sake
  if(!registrationState.offerCode.value)
    return Promise.reject({ error_code: "Invalid offerCode input" })
  if(!registrationState.lastFourSSN.value)
    return Promise.reject({ error_code: "Invalid lastFourSSN input" })

  const response = await PreapprovalService.findPreapproval(
    {
      offer_code: registrationState.offerCode.value,
      last_four_ssn: registrationState.lastFourSSN.value
    }
  )
  if ("error_code" in response) {
    return Promise.reject(response)
  } else {
    //TODO Add generic transform to transform camel <-> snake case
    registrationState.offerCode.value = response.offer_code
    registrationState.firstName.value = response.first_name
    registrationState.lastName.value = response.last_name
    registrationState.city.value = response.city
    registrationState.stateCode.value = response.state_code
    registrationState.addressLine1.value = response.address_line1
    registrationState.zipCode.value = response.zip_code
    registrationState.isPreapprovalMode.value = true
    registrationState.isPreapprovalModeUpswing.value = response.upswing_flg
    return Promise.resolve()
  }
}

function buildValidateAndContinue(reactiveRefs: ReactiveRefs) {
  return async function (): Promise<void> {
    analytics.track("preapproved1submit")
    reactiveRefs.isLoading.value = true
    if (reactiveRefs.form.value?.validate()) {
      try {
        analytics.track("preapproval_validation_successful")
        await findPreapproval()
        analytics.track("preapproved1match", { failure_count: reactiveRefs.failureCount.value })
        await router.push({ name: Step1RouteName })
      } catch (e: any) {
        /* istanbul ignore if */
        if(isRuntimeError(e)){
          logError(e)
        }
        if ("error_code" in e && e.error_code == "preapproval_not_found") {
          reactiveRefs.failureCount.value += 1
          if (reactiveRefs.preapprovalFailed.value) {
            analytics.track("preapproved1nomatch", { failure_count: reactiveRefs.failureCount.value })
          } else {
            analytics.track("preapprovalnomatchwithretry", { failure_count: reactiveRefs.failureCount.value })
            reactiveRefs.alert.value.message = "We're sorry, the information you entered doesn't match any of our pre-approved offers. Please review your information to make sure it's correct."
            reactiveRefs.alert.value.type = "error"
            reactiveRefs.alert.value.show = true
          }
        } else {
          analytics.track("preapproval_backend_error")
          if (e !== undefined) {
            reactiveRefs.alert.value.message = e.message ?? e
            reactiveRefs.alert.value.type = "error"
            reactiveRefs.alert.value.show = true
          }
        }
      }
    } else {
      analytics.track("preapproval_invalid_input", { invalid_fields : extractInvalidFieldsFromForm(reactiveRefs.form.value) })
    }
    reactiveRefs.isLoading.value = false
  }
}

type ReactiveRefs = ReturnType<typeof buildReactiveRefs>

function buildReactiveRefs() {
  const alert = ref({
    show: false,
    type: "success",
    message: ""
  })

  const isLoading = ref(false)
  const failureCount = mapGettersAndSettersToComputed(
    ["preapprovalLocatorMismatchesCount"]
  ).preapprovalLocatorMismatchesCount

  const preapprovalFailed = computed(() => failureCount.value > 2)
  const formData = mapGettersAndSettersToComputed(["offerCode", "lastFourSSN"])
  const form = ref<Vue & { validate: () => boolean } | null>(null)
  return {
    formData,
    failureCount,
    isLoading,
    preapprovalFailed,
    alert,
    form
  }
}

export type UsePreapprovalLocator = {
  validateAndContinue: ReturnType<typeof buildValidateAndContinue>;
  findPreapproval: typeof findPreapproval;
} & { [key in keyof ReactiveRefs]: ReactiveRefs[key] }

export function usePreapprovalLocator(): UsePreapprovalLocator {
  const reactiveRefs = buildReactiveRefs()
  return {
    ...reactiveRefs,
    validateAndContinue: buildValidateAndContinue(reactiveRefs),
    findPreapproval
  }
}
