import { AnalyticsInstance, EventCallback, Payload, Storage } from "@/lib/analytics/analytics-types"
import Cookies from "js-cookie"
import { eventNames } from "@/lib/analytics/event-types"
import { CONSTANTS } from "analytics"
import Vue from "vue"


export function buildId(instance: Payload | AnalyticsInstance): string {
  const adobeId = getAdobeTrackingId()
  const customerId = getCustomerId(instance)
  if (typeof customerId === "string") {
    return customerId
  }
  if (adobeId) {
    return adobeId
  }
  if ("user" in instance) {
    return instance.user("anonymousId")
  } else {
    return instance.anonymousId
  }
}

export function getAdobeTrackingId(): (string | undefined) {
  const ADOBE_COOKIE_NAME = "s_ecid"
  return Cookies.get(ADOBE_COOKIE_NAME)
}

function getCustomerId(instance: Payload | AnalyticsInstance): (string | unknown) {
  if ("user" in instance) {
    return instance.user("traits").customerId
  } else if ("traits" in instance && instance.traits.customerId) {
    return instance.traits.customerId
  } else {
    return null
  }
}

/* This method prevents accidental circular event notifications */
export function buildPreventDuplicateErrors(maxAllowedErrorCount: number, maxAllowedCountPerError: number): (p: EventCallback) => void {
  const observedErrors = new Map<string, number>()
  let totalErrorCount = 0

  return ({ payload }: EventCallback) => {
    if (payload.properties.error) {
      if (totalErrorCount > maxAllowedErrorCount) {
        throw `Too many errors, > ${maxAllowedErrorCount}, disabling graceful error reporting`
      }
      const errorMessage = (payload.properties.error as Error).message
      const errorCount = observedErrors.get(errorMessage) || 0
      if (errorCount > maxAllowedCountPerError) {
        throw payload.properties.error
      }
      observedErrors.set(errorMessage, errorCount + 1)
      totalErrorCount += 1
    }
  }
}

// This "decorator" function is required to allow waiting for any asynchronous events to finish
// before executing callback
export function buildAnalyticsCallback<T extends EventCallback>(cb: (event: T) => void): (p: T) => void {
  return async (event: T) => {
    await event.payload.thenable
    cb(event)
  }
}

interface AnalyticPluginStatus {
  enabled: boolean,
  initialized: boolean,
  loaded: boolean
}

interface AnalyticPlugins {
  [key: string]: AnalyticPluginStatus
}

interface PluginStatusReport {
  [key: string]: boolean
}

export function analyticsPluginStatuses(instance: AnalyticsInstance): PluginStatusReport {
  const plugins = instance.getState("plugins") as AnalyticPlugins
  const report: PluginStatusReport = {}
  for (const [pluginName, pluginStatus] of Object.entries(plugins)) {
    report[pluginName] = (pluginStatus.enabled && pluginStatus.loaded)
  }

  return report
}

export type InfoHandler = (message: string, payload?: string | Record<string, string | boolean | unknown>) => Promise<void>
export type ErrorHandler = (error: Error | string, message?: string) => Promise<void>;
export function buildAnalyticsLogHandlers(instance: AnalyticsInstance): {
  logError: ErrorHandler,
  logWarning: ErrorHandler,
  logInfo: InfoHandler
} {

  const plugins = analyticsPluginStatuses(instance)

  async function logError(error: Error | string, message?: string): Promise<void> {
    if (typeof error === "string") error = new Error(error)
    return instance
      .track(
        eventNames.FATAL_ERROR,
        { error, severity: "error", message },
        { plugins }
      )
  }

  async function logWarning(error: Error | string, message?: string): Promise<void> {
    if (typeof error === "string") error = new Error(error)
    return instance
      .track(
        eventNames.WARN_ERROR,
        { error, severity: "warning", message },
        { plugins }
      )
  }

  async function logInfo(message: string, payload?: string | Record<string, string | boolean | unknown>): Promise<void> {
    return instance
      .track(
        eventNames.INFO,
        { severity: "info", message, payload },
        { plugins }
      )
  }

  return { logError, logWarning, logInfo }
}

/* Wraps storage such that anonymous_uuid is stored in cookie and the remaining is stored in
 * analytics library's storage */
export function buildStorage(analyticsStorage: Storage, cookieStorage: typeof Cookies): Storage {
  const ANONYMOUS_UUID_COOKIE_NAME = "anonymous_uuid"
  const isAnon = (key: string) => key === CONSTANTS.ANON_ID

  const getItem = (key: string) => {
    if (isAnon(key)) {
      return cookieStorage.get(ANONYMOUS_UUID_COOKIE_NAME)
    } else {
      return analyticsStorage.getItem(key)
    }
  }
  const setItem = (key: string, value: unknown) => {
    if (isAnon(key)) {
      const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN_NAME
      const values = {
        previous: getItem(key),
        current: value
      }
      cookieStorage.set(ANONYMOUS_UUID_COOKIE_NAME, value as string, { domain: cookieDomain })
      return values
    } else {
      return analyticsStorage.setItem(key, value as string)
    }
  }
  const removeItem = (key: string) => {
    if (isAnon(key)) {
      cookieStorage.remove(ANONYMOUS_UUID_COOKIE_NAME)
      return ["cookie"]
    } else {
      return analyticsStorage.removeItem(key)
    }
  }
  return { getItem, setItem, removeItem }
}

export const extractInvalidFieldsFromForm = (form: unknown): Array<unknown> | "form_not_defined" => {
  if (!form) {
    return "form_not_defined"
  }
  const isValueShown = process.env.VUE_APP_ENVIRONMENT !== "production"
  return (form as (Vue & { inputs: Array<{ valid: boolean, id: string, value: unknown }> }))
    .inputs
    .filter(i => !i.valid)
    .map(i => ({ [i.id]: isValueShown ? i.value : "*[REDACTED]*" }))
}
