import { ApiPublicService } from "@/lib/api/public-service"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import { createSeoTrackingRequestBody, CreateSeoTrackingRequest, CreateSeoTrackingResponse } from "@/lib/api/adapters/seo-tracking"
import { logError } from "@/lib/analytics"

const apiEndpoint = "/v4/public/seo_trackings"

export async function createSeoTracking(): Promise<CreateSeoTrackingResponse> {
  const { apiPublicServiceDepreciated } = useApiServicesDepreciated()
  const cnuPublicApiService: ApiPublicService = apiPublicServiceDepreciated.value!
  const requestBody : CreateSeoTrackingRequest = createSeoTrackingRequestBody()

  try {
    const response = await cnuPublicApiService.post<CreateSeoTrackingRequest, CreateSeoTrackingResponse>(apiEndpoint, requestBody)
    return Promise.resolve(response)
  } catch (err: any) {
    // TODO: we should have none of those
    logError(err)
    return Promise.reject(err)
  }
}
