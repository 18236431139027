import { AxiosError } from "axios"

/*
  Note on error types:
   * Runtime error: Ex. null.customer_id - in analytics
   * User Input Error: Ex. no product in state - not in analytics
   * Data error: - in analytics
   ** user impact: Ex. requesting invalid customer account
   ** Non user impact: Ex. seo end-point returned 404
*/

type KnownError = AxiosError & Error | string | Array<unknown> | undefined

interface ErrorType {
  message: string
  raw?: string
}


export const genericErrorMessage : ErrorType = {
  message: "Whoops, something went wrong on our end. Please check back later when we should be up and running."
}

// Determines if given exception is known, meaning - was this thrown by us intentionally
export function isUserOrDataError(e : KnownError) : boolean {
  if(!e) {
    return true
  }
  else if(typeof e === "string"){
    return true
  }
  else if (Array.isArray(e)){
    return true
  }
  else if(e.isAxiosError) {
    return true
  }
  // Handled V4 type of API error
  else if(typeof e === "object" && ("errors" in e || "error_code" in e || "error" in e)){
    return true
  }
  return false
}

export function isRuntimeError(e : KnownError) : boolean{
  return !isUserOrDataError(e)
}

export function handleOrchestratorError(error : any) : string {
  let errorString = ""
  if (error.errors) {
    // Sometimes, CNUAPP likes to switch up how it formats its errors.
    // If error is not an array (Ex. ['could not authenticate']) we pull the actual error messages out.
    if(!Array.isArray(error.errors)){
      try {
        if(typeof error.errors === "string"){
          errorString += `${error.errors}\n`
        } else {
          Object.values(error.errors).forEach((errorSection) => {
            Object.values(errorSection).forEach((message) => {
              errorString += `${message}\n`
            })
          })
        }
      }
      //In case something were to fail when trying to get the messages, just return generic message
      catch(e: any) {
        return genericErrorMessage.message
      }
    } else {
      // Otherwise (if it's an array of errors), display generic error message
      return genericErrorMessage.message
    }
  } else {
    return genericErrorMessage.message
  }

  return errorString
}
