import { Module } from "vuex"
import store, { useAccountStore, RootState } from "@/store"
import { buildHelpers } from "@/store/composition-helpers"
import { WritableComputedRef, ComputedRef, computed } from "vue"
import { getApproval } from "@/lib/api/secure/approvals-service"
import { CNUApproval } from "generated-cnuapp-types"
import { updateField, getField } from "vuex-map-fields"

const { mapGettersAndSettersToComputed } = buildHelpers<ApprovalStore>(
  () => store, "approval"
)

interface ApprovalStore {
  approvalId: string | undefined
  needsPreContractEbs: boolean | undefined
  ebsReportStale: boolean | null | undefined
  approvalStatus: string | undefined
  approved: boolean | undefined
  ebsExpiresAt: string | null | undefined
}

export const newApprovalState = () : ApprovalStore => {
  return {
    approvalId: "",
    needsPreContractEbs: false,
    ebsReportStale: null,
    approvalStatus: "",
    approved: false,
    ebsExpiresAt: null
  }
}
const approvalState : ApprovalStore = newApprovalState()

interface ApprovalStoreAccessors {
  approvalId: WritableComputedRef<string>,
  needsPreContractEbs:  WritableComputedRef<boolean>,
  ebsReportStale: WritableComputedRef<boolean>,
  approvalStatus: WritableComputedRef<string>,
  approved: WritableComputedRef<boolean>,
  ebsExpiresAt: WritableComputedRef<string>,
  fetchApproval(): Promise<void>,
  ebsExpirationTime: ComputedRef<string>,
  ebsExpirationDate: ComputedRef<string>
}

function useApprovalStore(): ApprovalStoreAccessors {
  const mapFields = mapGettersAndSettersToComputed(Object.keys(approvalState) as Array<keyof ApprovalStore>)
  function setApprovalData(val: CNUApproval): void {
    mapFields.approvalId.value = val.id
    mapFields.needsPreContractEbs.value = val.needs_pre_contract_ebs
    mapFields.ebsReportStale.value = val.ebs_report_stale
    mapFields.approvalStatus.value = val.status_cd
    mapFields.approved.value = val.approved
    mapFields.ebsExpiresAt.value = val.ebs_expires_at
  }

  async function fetchApproval(): Promise<void> {
    const { accountId, approvalId } = useAccountStore()
    const approval = await getApproval(accountId.value, approvalId.value)
    setApprovalData(approval)
  }

  const ebsExpirationTime: ComputedRef<string | null> = computed(() => {
    if (mapFields.ebsExpiresAt.value == null) {
      return null
    }

    const date = new Date(mapFields.ebsExpiresAt.value)
    return date.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", timeZoneName: "short" })
  })

  const ebsExpirationDate: ComputedRef<string | null> = computed(() => {
    if (mapFields.ebsExpiresAt.value == null) {
      return null
    }

    const date = new Date(mapFields.ebsExpiresAt.value)
    return date.toLocaleDateString("en-US")
  })

  return {
    ...mapFields,
    fetchApproval,
    ebsExpirationTime,
    ebsExpirationDate
  } as ApprovalStoreAccessors
}

const approvalModule: Module<ApprovalStore, RootState> = {
  namespaced: true,
  state: approvalState,
  getters: { getField },
  mutations: { updateField }
}

export default approvalModule
export { approvalState, useApprovalStore, ApprovalStore, ApprovalStoreAccessors }
