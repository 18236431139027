import { render, staticRenderFns } from "./app.vue?vue&type=template&id=4af22fa1"
import script from "./app.vue?vue&type=script&setup=true&lang=ts"
export * from "./app.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.23_babel-core@7.0.0-bridge.0_@babel+core@7.24.4__css_ryntzzlaebzeul653jqzwu2hre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports