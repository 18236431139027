import moment from "moment"
import { useSessionStore } from "@/store"

export type CreateSeoTrackingRequest = {
  tracking_url: string,
  tracking_data: {
    campaign: string,
    gclid: string,
    medium: string,
    network: string,
    adposition: string,
    click_id: string,
    device: string,
    group_cd: string,
    keyword: string,
    loc_physical_ms: string,
    match_type: string,
    order_item_id: string,
    source_type_cd: string,
    traffic_source: string,
    trkid: string
  },
  tracking_time: string,
  customer_id: string
}

export type CreateSeoTrackingResponse = {
  seo_tracking_id: string
}


export function createSeoTrackingRequestBody() : CreateSeoTrackingRequest {
  const store = useSessionStore()

  const body : CreateSeoTrackingRequest = {
    tracking_url: "/registration",
    tracking_data: {
      campaign: store.campaign.value ?? "",
      gclid: store.gclid.value ?? "",
      medium: store.medium.value ?? "",
      network: store.network.value ?? "",
      adposition: store.adposition.value ?? "",
      click_id: store.clickId.value ?? "",
      device: store.device.value ?? "",
      group_cd: store.groupCd.value ?? "",
      keyword: store.keyword.value ?? "",
      loc_physical_ms: store.locPhysicalMs.value ?? "",
      match_type: store.matchType.value ?? "",
      order_item_id: store.orderItemId.value ?? "",
      source_type_cd: store.sourceTypeCd.value ?? "",
      traffic_source: store.trafficSource.value ?? "",
      trkid: store.trkid.value ?? ""
    },
    tracking_time: moment().format("YYYY-MM-DD HH:mm:ss ZZ"),
    customer_id: ""
  }
  return body
}


export const SOURCE_PARAM_VALUES_MAPPING: Record<string, string> = {
  "google" : "goo",
  "wsi"    : "goo",
  "cj"     : "CJ",
  "bing"   : "bng",
  "yahoo"  : "yho",
  "msn"    : "MSN"
}

export const MATCH_TYPE_PARAM_VALUES_MAPPING: Record<string, string> = {
  "broad"  : "b",
  "exact"  : "e",
  "phrase" : "p",
  "a"      : "a",
  "b"      : "b",
  "e"      : "e",
  "p"      : "p"
}

export const NETWORK_PARAM_VALUES_MAPPING: Record<string, string> = {
  "g"               : "Search",
  "google_search"   : "Search",
  "s"               : "Content",
  "search_partner"  : "Content",
  "display_network" : "Display"
}

export function mapQueryToStore(queryParams: { [key: string]: string | (string | null)[] }) : void {
  const {
    campaign,
    gclid,
    medium,
    network,
    adposition,
    clickId,
    device,
    keyword,
    locPhysicalMs,
    matchType,
    orderItemId,
    trkid,
    trafficSource,
    groupCd,
    sourceTypeCd } = useSessionStore()

  if ("gclid" in queryParams) {
    gclid.value = String(queryParams["gclid"])
  }
  if ("campaign" in queryParams) {
    campaign.value = String(queryParams["campaign"])
  }
  if ("adgroup" in queryParams) {
    medium.value = String(queryParams["adgroup"])
  }
  else if ("ad_group" in queryParams) {
    medium.value = String(queryParams["ad_group"])
  }
  else if ("medium" in queryParams) {
    medium.value = String(queryParams["medium"])
  }
  if ("network" in queryParams) {
    network.value = NETWORK_PARAM_VALUES_MAPPING[String(queryParams["network"])] || String(queryParams["network"])
  }
  else if ("Network" in queryParams) {
    network.value = String(queryParams["Network"])
  }
  if ("adposition" in queryParams) {
    adposition.value = String(queryParams["adposition"])
  }
  if ("click_id" in queryParams) {
    clickId.value = String(queryParams["click_id"])
  }
  if ("devicetype" in queryParams) {
    device.value = String(queryParams["devicetype"])
  }
  if ("kw" in queryParams) {
    keyword.value = String(queryParams["kw"])
  }
  if ("loc_physical_ms" in queryParams) {
    locPhysicalMs.value = String(queryParams["loc_physical_ms"])
  }
  if ("match" in queryParams) {
    matchType.value = MATCH_TYPE_PARAM_VALUES_MAPPING[String(queryParams["match"])]
  }
  if ("id" in queryParams) {
    orderItemId.value = String(queryParams["id"])
  }
  if ("trkid" in queryParams) {
    trkid.value = String(queryParams["trkid"])
  }
  if("source" in queryParams) {
    const value = String(queryParams["source"])
    trafficSource.value = (Object.keys(SOURCE_PARAM_VALUES_MAPPING).includes(value) || Object.values(SOURCE_PARAM_VALUES_MAPPING).includes(value)) ?
      SOURCE_PARAM_VALUES_MAPPING[value] || value
      : "other"
  }
  else if ("src" in queryParams) {
    trafficSource.value = String(queryParams["src"])
  }
  // The two below are not initialized from query params, but instead they come from the pages controller
  // Since we're only handling the registrations here, we can probably ignore those?
  if ("group_cd" in queryParams) {
    groupCd.value = String(queryParams["group_cd"])
  }
  if ("source_type_cd" in queryParams) {
    sourceTypeCd.value = String(queryParams["source_type_cd"])
  }
}


