import { ContractState } from"./state"
import { ContractData } from "generated-cnuapp-types"
import { ContractAlert, ContractInput, ContractErrors } from "@/store/contract/state"

export enum MutationTypes {
  SET_CONTRACT_DATA                   = "SET_CONTRACT_DATA",
  SET_CUSTOMER_INPUT                  = "SET_CUSTOMER_INPUT",
  SET_DRAW_AMOUNT                     = "SET_DRAW_AMOUNT",
  SET_SSN_LAST4                       = "SET_SSN_LAST4",
  SET_CONTRACT_ERRORS                 = "SET_CONTRACT_ERRORS",
  SET_CONTRACT_ALERT                  = "SET_CONTRACT_ALERT",
  SET_SSN_ERROR_TEXT                  = "SET_SSN_ERROR_TEXT",
  INCREMENT_CONTRACT_PAGE_ERRORS      = "INCREMENT_CONTRACT_PAGE_ERRORS",
  SET_SUBMIT_IN_PROGRESS              = "SET_SUBMIT_IN_PROGRESS",
  SET_LAST_SUCCESSFUL_SUBMIT_ID       = "SET_LAST_SUCCESSFUL_SUBMIT_ID",
  SET_FLEX_LOAN_ACKNOWLEDGEMENT_COMPLETED = "SET_FLEX_LOAN_ACKNOWLEDGEMENT_COMPLETED",
  HAS_EXISTING_FLEX_LOAN              = "HAS_EXISTING_FLEX_LOAN",
  SET_VIEWED_RPB_POPUP                = "SET_VIEWED_RPB_POPUP",
  SET_SELECTED_REPAYMENT_METHOD       = "SET_SELECTED_REPAYMENT_METHOD"
}

type ContractMutation<Payload> = {(state: ContractState, payload: Payload): void};

export interface ContractMutations {
  [MutationTypes.SET_CONTRACT_DATA]: ContractMutation<ContractData>,
  [MutationTypes.SET_CUSTOMER_INPUT]: ContractMutation<ContractInput>,
  [MutationTypes.SET_DRAW_AMOUNT]: ContractMutation<number>,
  [MutationTypes.SET_SSN_LAST4]: ContractMutation<string>,
  [MutationTypes.SET_CONTRACT_ERRORS]: ContractMutation<ContractErrors>,
  [MutationTypes.SET_CONTRACT_ALERT]: ContractMutation<ContractAlert>,
  [MutationTypes.SET_SSN_ERROR_TEXT]: ContractMutation<string>,
  [MutationTypes.INCREMENT_CONTRACT_PAGE_ERRORS]: ContractMutation<void>,
  [MutationTypes.SET_SUBMIT_IN_PROGRESS]: ContractMutation<boolean>,
  [MutationTypes.SET_LAST_SUCCESSFUL_SUBMIT_ID]: ContractMutation<string>
  [MutationTypes.SET_FLEX_LOAN_ACKNOWLEDGEMENT_COMPLETED]: ContractMutation<boolean>
  [MutationTypes.HAS_EXISTING_FLEX_LOAN]: ContractMutation<string>
  [MutationTypes.SET_VIEWED_RPB_POPUP]: ContractMutation<boolean>,
  [MutationTypes.SET_SELECTED_REPAYMENT_METHOD]: ContractMutation<boolean>,
}

export const contractMutations: ContractMutations = {
  [MutationTypes.SET_CONTRACT_DATA](state, contractData: ContractData) {
    state.contractData = contractData
  },
  [MutationTypes.SET_CUSTOMER_INPUT](state, signature: ContractInput): void {
    state.customerInput = signature
  },
  [MutationTypes.SET_DRAW_AMOUNT](state, drawAmount: number) {
    state.customerInput.request_funds.draw_amount = drawAmount
  },
  [MutationTypes.SET_SSN_LAST4](state, ssnLast4: string) {
    state.customerInput.sign_agreement.ssn_last4 = ssnLast4
  },
  [MutationTypes.SET_CONTRACT_ERRORS](state, errors: ContractErrors) {
    state.contractErrors = errors
  },
  [MutationTypes.SET_CONTRACT_ALERT](state, alert: ContractAlert) {
    state.contractErrors.alert = alert
  },
  [MutationTypes.SET_SSN_ERROR_TEXT](state, errorText: string) {
    state.contractErrors.ssn_error_text = errorText
  },
  [MutationTypes.INCREMENT_CONTRACT_PAGE_ERRORS](state) {
    state.contractErrors.page_error_count++
  },
  [MutationTypes.SET_SUBMIT_IN_PROGRESS](state, submitting: boolean) {
    state.customerInput.submitting = submitting
  },
  [MutationTypes.SET_LAST_SUCCESSFUL_SUBMIT_ID](state, id: string) {
    state.contractGuards.last_successful_submit_id = id
  },
  [MutationTypes.SET_FLEX_LOAN_ACKNOWLEDGEMENT_COMPLETED](state, value: boolean) {
    state.customerInput.flex_loan_agreement.flex_loan_acknowledgement_completed = value
  },
  [MutationTypes.HAS_EXISTING_FLEX_LOAN](state, value: string) {
    state.customerInput.flex_loan_agreement.has_existing_flex_loan = value
  },
  [MutationTypes.SET_VIEWED_RPB_POPUP](state, value: boolean) {
    state.viewedRbpPopup = value
  },
  [MutationTypes.SET_SELECTED_REPAYMENT_METHOD](state, value: boolean) {
    state.selectedRepaymentMethod = value
  }
}

