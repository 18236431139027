<script setup lang="ts">
import { computed } from "vue"
import { VApp, VMain } from "lib-cnu-vuetify-2"
import {
  GlobalConfig
} from "lib-cnu-components"
import { CnuProvider } from "lib-component-providers-vue2"
import { ExternalRouteLinks } from "@/router"
import { useHead } from "unhead"

const Gondola = () => import("@/components/gondola.vue")
import(
  /* webpackPreload: true */
  /* webpackChunkName: "gondola" */
  "@/components/gondola.vue"
)

useHead({
  title: "CashNetUSA Online Loans",
  meta: [
    { charset: "utf-8" },
    { name: "page-name", content: "Brand: Reg1", vmid: "page-name" },
    { name: "description", content: "Apply for an online loan and get fast funding if approved! We offer installment loans or lines of credit depending on your state.", vmid: "description" }
  ],
  link: [
    { rel: "canonical", href: "https://apply.cashnetusa.com/step1", vmid: "canonical" }
  ]
})

const showGondola = computed(() => {
  return process.env.VUE_APP_ENVIRONMENT !== "production"
})
</script>
<template>
  <cnu-provider>
    <global-config :external-route-links="ExternalRouteLinks">
      <v-app>
        <v-main>
          <Gondola v-if="showGondola" />
          <router-view />
        </v-main>
      </v-app>
    </global-config>
  </cnu-provider>
</template>
