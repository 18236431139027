import Cookies from "js-cookie"

export type SeoTrackingCookie = {
  seo_tracking_id: string | null,
  tracking_complete: boolean
}

export interface SeoTrackingStorage {
  getSeoTrackingCookie: () => string
  getSeoTrackingId: () => string
  saveCookie: (seoTrackingCookie: SeoTrackingCookie) => void
  expire: () => void
}

class CookieSeoTrackingStorage implements SeoTrackingStorage {
  private seoTrackingCookieName: string
  private cookieDomain: string
  private secureCookies: boolean

  public constructor() {
    this.seoTrackingCookieName =
      process.env.VUE_APP_SEO_TRACKING_COOKIE_NAME || "seo_tracking"
    this.cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN_NAME || "localhost"
    this.secureCookies = process.env.VUE_APP_SECURE_SEO_COOKIES === "true"
  }

  public getSeoTrackingCookie(): string {
    return Cookies.get(this.seoTrackingCookieName) || ""
  }

  public getSeoTrackingId(): string {
    const cookie = Cookies.get(this.seoTrackingCookieName)
    if (cookie) return JSON.parse(cookie).seo_tracking_id
    return ""
  }

  public saveCookie(seoTrackingCookie: SeoTrackingCookie): void {
    Cookies.set(this.seoTrackingCookieName, seoTrackingCookie, { secure: this.secureCookies, domain: this.cookieDomain })
  }

  public expire() : void {
    Cookies.remove(this.seoTrackingCookieName, { secure: this.secureCookies, domain: this.cookieDomain })
  }
}

export default new CookieSeoTrackingStorage()
