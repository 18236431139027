import { Login, LoginAPIResponse } from "lib-wrapped-login-page-api"
import axios from "axios"
import CookieJWTStorage from "@/lib/api/cookie-jwt-storage"
import { useSessionStore } from "@/store"
import { logError } from "@/lib/analytics"
import { isRuntimeError } from "@/lib/util/errors"
export interface AuthenticateCustomerResponse {
  access_token: string;
  refresh_token: string;
  account_id: string;
}

interface UseLoginServer {
  authenticateCustomer(emailAddress: string, password: string, recaptcha: string): Promise<AuthenticateCustomerResponse>
  revokeRefreshToken(token: string): Promise<void>
}

const loginServerUrl = process.env.VUE_APP_LOGIN_SERVER_URI ?? ""
const clientId = process.env.VUE_APP_CNU_CLIENT_ID ?? ""

export function useLoginServer(): UseLoginServer {
  async function authenticate(emailAddress: string, password: string, captchaResult: string): Promise<AuthenticateCustomerResponse> {
    if (!captchaResult) {
      return Promise.reject("missing_captcha")
    }
    try {
      const result: LoginAPIResponse = await (Login
        .withServerUrl(loginServerUrl) as Login)
        .login({
          username: emailAddress,
          password: password,
          captcha: captchaResult,
          clientID: clientId,
          responseMode: "form_post"
        })

      if (result.hasError) {
        const message = result.error?.message ?? "Login server errored"
        logError(message)
        return Promise.reject(message)
      }
      if (result.result !== undefined) {
        return Promise.resolve({
          account_id: result.result["customer_id"],
          access_token: result.result["access_token"],
          refresh_token: result.result["refresh_token"]
        } as AuthenticateCustomerResponse)
      }
      const message = result.error?.message || "invalid login server result"
      logError(message)
      return Promise.reject(message)
    } catch(e: any) {
      /* TODO Figure out invalid credentials from general 500 errors and handle them precisely */
      /* istanbul ignore if */
      if(isRuntimeError(e)){
        logError(e)
      }
      return Promise.reject(e)
    }
  }

  async function authenticateCustomer(emailAddress: string, password: string, recaptcha: string): Promise<AuthenticateCustomerResponse> {
    const response = await authenticate(emailAddress, password, recaptcha)
    const { accountId } = useSessionStore()
    CookieJWTStorage.saveTokens(response.access_token, response.refresh_token)
    accountId.value = response.account_id
    return Promise.resolve(response)
  }

  async function revokeRefreshToken(token: string) : Promise<void> {
    try {
      await axios.post(`${loginServerUrl}/revoke`, {
        client_id: clientId,
        refresh_token: token
      })
      return Promise.resolve(undefined)
    } catch(e: any) {
      /*
        TODO Figure out invalid credentials from general 500 errors and handle them precisely
      */
      /* istanbul ignore if */
      if(isRuntimeError(e)){
        logError(e)
      }
    }
  }

  return {
    authenticateCustomer,
    revokeRefreshToken
  }
}

export default {}
