import { ApiSecureService } from "@/lib/api/secure-service"
import { useSessionStore } from "@/store"

import { updateAccountRequestBody } from "@/lib/api/adapters/accounts"
import { UpdateAccountRequest } from "generated-cnuapp-types"
import { buildAccountRequestConfig } from "@/lib/api/public/helpers"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import analytics, { logError } from "@/lib/analytics"
import { AccountFlowResponse, AccountResponse } from "lib-api-types"

const url = "/v4/secure/accounts"

export async function updateAccount(updatedValues: string[]): Promise<AccountResponse> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!

  const { accountId } = useSessionStore()
  const urlWithId = `${url}/${accountId.value}`
  const requestBody = updateAccountRequestBody(updatedValues)

  try {
    const response = await cnuSecureApiService.put<UpdateAccountRequest, AccountResponse>(urlWithId, requestBody, buildAccountRequestConfig())
    return Promise.resolve(response)
  }
  catch(e: any) {

    /* istanbul ignore if */
    if(e?.response?.status === 401) {
      return Promise.reject(e)
    }
    if (e?.response?.status === 422) {
      analytics.track("update_account_failed", { errors: e.errors, error_code: e.error_code })
      return Promise.reject(e.response.data)
    }
    if(e)
      logError(e)
    return Promise.reject(e)
  }
}

export async function getAccount(): Promise<AccountResponse> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!

  const { accountId } = useSessionStore()

  if (!accountId.value) {
    return Promise.reject("user not logged in")
  }

  const urlWithId = `${url}/${accountId.value}`
  try {
    const response = await cnuSecureApiService.get<AccountResponse>(urlWithId)
    return Promise.resolve(response)
  }
  catch(e: any) {
    /* istanbul ignore if */
    if(e?.response?.status === 401) {
      return Promise.reject(e)
    }
    else if (e?.response) {
      analytics.track("error_finding_account", { error_message: e.message })
      return Promise.reject(e.response.data)
    }
    else if(e) {
      logError(e)
    }
    return Promise.reject(e)
  }
}

export async function getAccountFlow(): Promise<AccountFlowResponse> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!

  const { accountId } = useSessionStore()

  if (!accountId.value) {
    return Promise.reject("user not logged in")
  }

  const urlWithId = `${url}/${accountId.value}/application_flows`
  try {
    const response = await cnuSecureApiService.get<AccountFlowResponse>(urlWithId)
    return Promise.resolve(response)
  }
  catch(e: any) {
    /* istanbul ignore if */
    if(e?.response?.status === 401) {
      return Promise.reject(e)
    }
    if(e?.response?.status === 404 && !!(e.response?.data?.message)){
      // TODO temporary until we normalize all error codes from back-end
      e.response.data.error_code = "no_application_flows_found"
      analytics.track("no_application_flows_found", { error_message: e.message, response_message: e.response.data.message })
      return Promise.reject(e.response.data)
    }
    else{
      if(e)
        logError(e)
      return Promise.reject(e)
    }
  }
}
