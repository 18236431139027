import { GovernmentIdentifier } from "."

export interface Person {
  firstName?: string
  middleName?: string
  lastName?: string
  dateOfBirth?: string
  martialStatus?: MartialStatus
  activeMilitary?: boolean
  governmentIdentifiers?: Array<GovernmentIdentifier>
}
export enum MartialStatus {
  Single = "single",
  Married = "married",
  Widowed = "widowed",
  Separated = "separated",
  Divorced = "divorced",
  Other = "other"
}
