import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from "axios"

import { RequestConfig } from "@/lib/api/types"
import analytics from "@/lib/analytics"

export class ApiPublicService {
  protected readonly instance: AxiosInstance

  public constructor(config?: AxiosRequestConfig, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create(config)
    config = config ?? {} as AxiosRequestConfig

    Object.assign(this.instance.defaults, config)
    this.instance.interceptors.request.use(this.setSessionId.bind(this))
  }

  protected setSessionId(config: AxiosRequestConfig): AxiosRequestConfig {
    config.headers = config.headers ?? {}
    config.headers["X-Session-Trace-Uuid"] = analytics.user("anonymousId")
    return config
  }

  public async get<T>(
    resource: string,
    slug?: string,
    config?: RequestConfig
  ): Promise<T> {
    try {
      const response = await this.instance.get(`${resource}${slug ? "/" + slug : ""}`, config)
      return this.success(response)
    } catch(e: any) { return Promise.reject(e) }
  }

  public async post<T, U>(
    resource: string,
    params: T,
    config?: RequestConfig
  ): Promise<U> {
    try {
      const response = await this.instance.post(`${resource}`, params, config)
      return this.success(response)
    } catch(e: any) { return Promise.reject(e) }
  }

  public async patch<T, U>(
    resource: string,
    slug: string,
    params: T,
    config?: RequestConfig
  ): Promise<U> {
    try {
      const response = await this.instance.patch(`${resource}${slug ? "/" + slug : ""}`, params, config)
      return this.success(response)
    } catch(e: any) { return Promise.reject(e) }
  }

  public async put<T, U>(
    resource: string,
    params: T,
    config?: RequestConfig
  ): Promise<U> {
    try {
      const response = await this.instance.put(`${resource}`, params, config)
      return this.success(response)
    } catch(e: any) { return Promise.reject(e) }
  }

  public async delete<T>(
    resource: string,
    config?: RequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const response = await this.instance.delete(resource, config)
      return this.success(response)
    } catch(e: any) { return Promise.reject(e) }
  }

  private success<T>(response: AxiosResponse<T>): T {
    return response.data
  }
}

let singleton : ApiPublicService|null
export function setSingleton(s : typeof singleton): void{
  singleton = s
}
