import { AnalyticsPlugin } from "analytics"
import {
  AnalyticsInstance,
  Config,
  ConfigCallback,
  EventCallback,
  Payload
} from "@/lib/analytics/analytics-types"
import { buildId } from "@/lib/analytics/helpers"
import { buildAnalyticsCallback, buildAnalyticsLogHandlers } from "@/lib/analytics/helpers"
import {
  Client,
  createInstance, enums, ListenerPayload,
  setLogger
} from "@optimizely/optimizely-sdk"

const optimizelyWebUrl = process.env.VUE_APP_OPTIMIZELY_WEB_URL
const queuedForceVariations: { experiment: string, variation: string}[] = []
const queuedForceFeatureVariable: { variableKey: string, value: string}[] = []
let optimizelyInstance: Client | undefined
let isInitialized = false

type DecisionListenerPayloadExtended = ListenerPayload & {type: string, decisionInfo?: Record<string, unknown>}

function normalizeProperties({ properties } : Payload) : Record<string, number|string|null>{
  const filteredProps : ReturnType<typeof normalizeProperties> = {}
  for(const prop in properties) {
    /* istanbul ignore if */
    if(!Object.prototype.hasOwnProperty.call(properties, prop))
      continue
    const val = properties[prop]
    if(typeof val === "string" || typeof val === "number")
      filteredProps[prop] = val
    else if(val instanceof Error && !!val.message){
      filteredProps[prop] = val.message
    }else if(val){
      filteredProps[prop] = JSON.stringify(val)
    }
  }
  return filteredProps
}

const impl = {
  initialize: ({ instance }: ConfigCallback) => {
    const optimizelyWeb = document.createElement("script")
    optimizelyWeb.setAttribute("src", optimizelyWebUrl ?? "")
    document.head.prepend(optimizelyWeb)
    optimizelyInstance = createInstance({
      sdkKey: process.env.VUE_APP_OPTIMIZELY_SDK,
      eventBatchSize: 1,
      eventFlushInterval: 1000,
      errorHandler: {
        handleError: buildAnalyticsLogHandlers(instance).logError
      }
    })

    // Per this doc: https://docs.developers.optimizely.com/full-stack/docs/notification-listeners - decision is invoked
    optimizelyInstance.notificationCenter.addNotificationListener<DecisionListenerPayloadExtended>(enums.NOTIFICATION_TYPES.DECISION, (payload) => {
      instance.track("OptimizelyFSLayerDecision", {
        experiment_type: payload.type,
        experiment_name: payload.decisionInfo?.experimentKey ?? "not_set",
        experiment_value: payload.decisionInfo?.variationKey ?? "not_set"
      })
    })

    optimizelyInstance.onReady().then(() => {
      isInitialized = true
    })

    // TODO Figure it out, was copied from original implementation
    setLogger(null)
  },

  track: buildAnalyticsCallback(({ payload, instance }: EventCallback) => {
    optimizelyInstance?.track(
      payload.event, buildId(instance), instance.user().traits, normalizeProperties(payload)
    )
  }),

  loaded: () => {
    return isInitialized
  },

  /* TODO If we implement alias / (AKA. userIdChanged event) concept into optimizely, then we should use: buildId(this.instance) */
  methods: {
    activate(this: { instance: AnalyticsInstance }, experiment: string): string | null | undefined {
      const forcedResult = queuedForceVariations.find((forced) => forced.experiment == experiment)
      if (forcedResult) return forcedResult.variation

      return optimizelyInstance?.activate(experiment, buildId(this.instance), this.instance.user().traits)
    },

    // Async call to activate experiment w/ optimizely for edge cases of instance not available yet
    async activateAsync(this: { instance: AnalyticsInstance }, experiment: string): Promise<string | null | undefined> {
      const user = buildId(this.instance)
      const traits = this.instance.user().traits
      const maxRetry = (2000 / 100)
      let retryCount = 0

      if (isInitialized && !optimizelyInstance?.getOptimizelyConfig()) return Promise.reject("MISSING_CONFIG")
      if (isInitialized) return Promise.resolve(optimizelyInstance?.activate(experiment, user, traits))

      return new Promise((resolve, reject) => {
        const retry = setInterval(() => {
          if (retryCount >= maxRetry) {
            clearInterval(retry)
            return reject("MAX_RETRY")
          }

          retryCount++
          if (!isInitialized) return
          clearInterval(retry)
          return resolve(optimizelyInstance?.activate(experiment, user, traits))
        }, 100)
      })
    },

    isFeatureEnabled(this: { instance: AnalyticsInstance }, experiment: string): boolean {
      return optimizelyInstance?.isFeatureEnabled(experiment, buildId(this.instance), this.instance.user().traits) || false
    },

    getFeatureVariableJSON(this: { instance: AnalyticsInstance }, featureKey: string, variableKey: string) {
      const forcedResult = queuedForceFeatureVariable.find(({ variableKey: forcedKey }) => forcedKey == variableKey)
      if (forcedResult) return JSON.parse(forcedResult.value)

      return optimizelyInstance?.getFeatureVariableJSON(featureKey, variableKey, buildId(this.instance), this.instance.user().traits)
    },

    getFeatureVariableBoolean(this: {instance: AnalyticsInstance }, featureKey: string, variableKey: string) {
      const forcedResult = queuedForceFeatureVariable.find(({ variableKey: forcedKey }) => forcedKey == variableKey)
      if (forcedResult) return forcedResult.value === "true"

      return optimizelyInstance?.getFeatureVariableBoolean(featureKey, variableKey, buildId(this.instance), this.instance.user().traits)
    },

    getFeatureVariableInteger(this: {instance: AnalyticsInstance }, featureKey: string, variableKey: string) {
      const forcedResult = queuedForceFeatureVariable.find(({ variableKey: forcedKey }) => forcedKey == variableKey)
      if (forcedResult) return Number(forcedResult.value)

      return optimizelyInstance?.getFeatureVariableInteger(featureKey, variableKey, buildId(this.instance), this.instance.user().traits)
    },

    // due to timing issue when defining a "user id", we are unable to utilize Optimizely's setForcedVariation/getForcedVariation
    setForcedVariation(this: { instance: AnalyticsInstance }, experiment: string, variation: string): void {
      queuedForceVariations.push({ experiment, variation })
    },

    setForcedFeatureVariable(this: { instance: AnalyticsInstance }, variableKey: string, value: string): void {
      queuedForceFeatureVariable.push({ variableKey, value })
    },

    instance(): typeof optimizelyInstance {
      return optimizelyInstance
    }
  }
}

export default function buildOptimizelyPlugin(config: Config): AnalyticsPlugin & typeof impl {
  if (config.enabled && !process.env.VUE_APP_OPTIMIZELY_SDK) {
    console.error("optimizely not configured")
    config.enabled = false
  }

  return {
    name: "optimizely",
    config: { ...config },
    ...impl
  }
}

