export interface PhoneNumber {
  type?: PhoneNumberType
  number?: string
  timeToCall?: PhoneNumberTimeToCall
  _default?: boolean
}
export enum PhoneNumberType {
  Home = "home",
  Mobile = "mobile",
  Work = "work",
  Other = "other"
}
export enum PhoneNumberTimeToCall {
  Morning = "morning",
  Afternoon = "afternoon",
  Evening = "evening",
  Other = "other"
}
