import { customRequiredValidator } from "./required.js";
export function customEmptyOrValidator(args) {
    const requiredValidator = customRequiredValidator();
    return (value) => {
        const requiredResult = requiredValidator(value);
        if (requiredResult !== true) {
            return true;
        }
        return args.rule(value);
    };
}
