export interface Income {
  frequency?: IncomeFrequency
  method?: IncomeMethod
  takeHomePerCheck?: number
  nextPayDate?: string
  followingPayDate?: string
}
export enum IncomeFrequency {
  Weekly = "weekly",
  Biweekly = "biweekly",
  TwiceMonthly = "twice_monthly",
  MonthlySpecificDate = "monthly_specific_date",
  MonthlyLastWorkingDay = "monthly_last_working_day",
  MonthlySpecificWeekday = "monthly_specific_weekday",
  MonthlyLastSpecificWeekday = "monthly_last_specific_weekday",
  Other = "other"
}
export enum IncomeMethod {
  Cash = "cash",
  DirectDeposit = "direct_deposit",
  PaperCheck = "paper_check"
}
