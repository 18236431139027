export function customRegexValidator(args) {
    const message = args.message;
    return (value) => {
        if (typeof value !== "string") {
            return message;
        }
        if (!args.regex.test(value)) {
            return message;
        }
        return true;
    };
}
