import { OrchestratorApiPublicService } from "@/lib/api/orchestrator-public-service"
import { OrchestratorApiSecureService } from "@/lib/api/orchestrator-secure-service"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import { CreateAccountRequest, updateAccountRequestBody, updateAccountRequestBodyNoStore } from "@/lib/api/adapters/accounts"
import { buildAccountRequestConfig } from "@/lib/api/public/helpers"
import { UpdateAccountRequest } from "generated-cnuapp-types"
import { AccountResponse, OrchestratorPathFlowRequest, OrchestratorResponse } from "lib-api-types"

import analytics, { logError } from "@/lib/analytics"
import { CreateEbanksAssetsLinkSessionRequest } from "./ebanks-service"

const BASE_PATH = ""

export interface OrchestratorStep {
  description: string,
  fail_step_id: number,
  id: number,
  name: string,
  process_id: number,
  process_type_id: number,
  start_time: number | null,
  success_step_id: number,
  tier: number
}

export interface OrchestratorPathFlowResponse {
  step?: OrchestratorStep,
  error?: string | null
}

export interface OrchestratorResponse<ResponseData> {
  route: string;
  flowUUID: string,
  data: ResponseData,
  errors: Array<string>
}

export interface OrchestratorPathFlowRequest {
  channel: string,
  context: any
}

interface SelectOfferRequest {
  offerUUID: string
}

export async function createOrchestratorFlow<R=unknown>(channel: string, context: any): Promise<OrchestratorResponse<R>> {
  const { orchestratorApiPublicServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorPublicApiService: OrchestratorApiPublicService = orchestratorApiPublicServiceDepreciated.value!

  try {
    const urlWithId = `${BASE_PATH}/orchestrator/create`
    const request = { channel: channel, context: context }
    const response = await orchestratorPublicApiService.post<OrchestratorPathFlowRequest, OrchestratorResponse<R>>(urlWithId, request)
    return response
  } catch(e: any) {
    /* istanbul ignore if */
    if(e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_finding_account", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw(e)
  }
}

export type RegistrationResponse = OrchestratorResponse<AccountResponse>

export async function submitStep1Data<R=unknown>(flowId: string, requestBody: CreateAccountRequest) : Promise<OrchestratorResponse<R>> {
  const { orchestratorApiPublicServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorPublicApiService: OrchestratorApiPublicService = orchestratorApiPublicServiceDepreciated.value!

  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowId}/reg1`
    const response = await orchestratorPublicApiService.post<CreateAccountRequest, OrchestratorResponse<R>>(urlWithId, requestBody, buildAccountRequestConfig())
    return response
  }  catch(e: any) {
    /* istanbul ignore if */
    if(e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_finding_account", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }
    throw e
  }
}

export async function submitStep2Data<R=unknown>(flowId: string, updatedFields: string []) : Promise<OrchestratorResponse<R>> {
  const { orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorSecureApiService: OrchestratorApiSecureService = orchestratorApiSecureServiceDepreciated.value!
  const requestBody = updateAccountRequestBody(updatedFields)

  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowId}/reg2`
    const response = await orchestratorSecureApiService.post<UpdateAccountRequest, OrchestratorResponse<R>>(urlWithId, requestBody, buildAccountRequestConfig())
    return response
  } catch(e: any) {
    /* istanbul ignore if */
    if(e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_finding_account", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw e
  }
}

export async function selectOffer<R=unknown>(flowUUID: string, offerUUID: string) : Promise<OrchestratorResponse<R>> {
  const { orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorSecureApiService: OrchestratorApiSecureService = orchestratorApiSecureServiceDepreciated.value!
  const requestBody = { offer_UUID: offerUUID }

  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowUUID}/offer`
    const response = await orchestratorSecureApiService.post<SelectOfferRequest, OrchestratorResponse<R>>(urlWithId, requestBody, buildAccountRequestConfig())
    return Promise.resolve(response)
  } catch (e: any) {
    /* istanbul ignore if */
    if (e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_selecting_offer", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw e
  }
}

export async function submitEBanksData<R = unknown>(flowId: string, payload: CreateEbanksAssetsLinkSessionRequest) : Promise<OrchestratorResponse<R>> {
  const { orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorSecureApiService: OrchestratorApiSecureService = orchestratorApiSecureServiceDepreciated.value!

  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowId}/ebs_pre_contract`
    const response = await orchestratorSecureApiService.post<CreateEbanksAssetsLinkSessionRequest, OrchestratorResponse<R>>(urlWithId, payload, buildAccountRequestConfig())
    return response
  } catch (e: any) {
    /* istanbul ignore if */
    if (e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_submitting_ebs_data", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw e
  }
}

export async function runEbsApproval<R = unknown>(flowId: string, approvalId: string): Promise<OrchestratorResponse<R>> {
  const { orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorSecureApiService: OrchestratorApiSecureService = orchestratorApiSecureServiceDepreciated.value!
  const payload = { approval_id: approvalId }

  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowId}/ebs_approval`
    const response = await orchestratorSecureApiService.post<any, OrchestratorResponse<R>>(urlWithId, payload, buildAccountRequestConfig())
    return response
  } catch (e: any) {
    /* istanbul ignore if */
    if (e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_polling_for_ebs", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw e
  }
}

export async function signContract<R = unknown>(flowId:string, contractBody: any) {
  const { orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorSecureApiService: OrchestratorApiSecureService = orchestratorApiSecureServiceDepreciated.value!
  const payload = contractBody

  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowId}/contract`
    const response = await orchestratorSecureApiService.post<any, OrchestratorResponse<R>>(urlWithId, payload, buildAccountRequestConfig())
    return response
  } catch (e: any) {
    /* istanbul ignore if */
    if (e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_submitting_contract", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw e
  }
}

export async function submitNcInterstitial<R = unknown>(flowId:string, body: string[]) {
  const { orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorSecureApiService: OrchestratorApiSecureService = orchestratorApiSecureServiceDepreciated.value!
  const requestBody = updateAccountRequestBodyNoStore(body)
  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowId}/nc_interstitial`
    const response = await orchestratorSecureApiService.post<any, OrchestratorResponse<R>>(urlWithId, requestBody, buildAccountRequestConfig())
    return response
  } catch (e: any) {
    /* istanbul ignore if */
    if (e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_submitting_contract", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw e
  }
}

export async function submitRefinanceOffer<R= unknown>(flowId:string, body: any) {
  const { orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorSecureApiService: OrchestratorApiSecureService = orchestratorApiSecureServiceDepreciated.value!

  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowId}/refi_offers`
    const response = await orchestratorSecureApiService.post<any, OrchestratorResponse<R>>(urlWithId, body, buildAccountRequestConfig())
    return response
  } catch (e: any) {
    /* istanbul ignore if */
    if (e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_submitting_refi_offers", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw e
  }
}

export async function submitLeads<R= unknown>(flowId:string, body: any, leadsData: any) {
  const { orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorSecureApiService: OrchestratorApiSecureService = orchestratorApiSecureServiceDepreciated.value!
  const requestBody = {
    ...updateAccountRequestBodyNoStore(body),
    ...leadsData
  }
  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowId}/leads_interstitial`
    const response = await orchestratorSecureApiService.post<any, OrchestratorResponse<R>>(urlWithId, requestBody, buildAccountRequestConfig())
    return response
  } catch (e: any) {
    /* istanbul ignore if */
    if (e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("leadsInterstitalFail", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw e
  }
}

export async function submitUpdate<R=unknown>(flowId: string, body: any, channelName: string) : Promise<OrchestratorResponse<R>> {
  const { orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorSecureApiService: OrchestratorApiSecureService = orchestratorApiSecureServiceDepreciated.value!
  const requestBody = updateAccountRequestBodyNoStore(body)

  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowId}/${channelName}_update`
    const response = await orchestratorSecureApiService.post<UpdateAccountRequest, OrchestratorResponse<R>>(urlWithId, requestBody, buildAccountRequestConfig())
    return response
  } catch(e: any) {
    /* istanbul ignore if */
    if(e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_finding_account", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw e
  }
}

export async function confirmLogin<R = unknown>(flowId: string, body: any) {
  const { orchestratorApiSecureServiceDepreciated } = useApiServicesDepreciated()
  const orchestratorSecureApiService: OrchestratorApiSecureService = orchestratorApiSecureServiceDepreciated.value!

  try {
    const urlWithId = `${BASE_PATH}/loan_application/${flowId}/leads_login`
    const response = await orchestratorSecureApiService.post<any, OrchestratorResponse<R>>(urlWithId, body, buildAccountRequestConfig())
    return response
  } catch (e: any) {
    /* istanbul ignore if */
    if (e?.response?.status === 401) return Promise.reject(e)
    else if (e?.response) {
      analytics.track("error_logging_in", { error_message: e.message })
      throw e.response.data
    } else if (e) {
      logError(e)
    }

    throw e
  }
}
