import Vue from "vue"
import { createHead } from "unhead"
//Plugins
import vuetify from "@/plugins/vuetify"
import "@/plugins/directives"
import "@/plugins/axios"
import "@/plugins/recaptcha"
import "@/plugins/analytics-setup"
import "@/vendor/genesys"

//router
import router from "@/router"
import store from "@/store"

import App from "@/app.vue"

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_ENVIRONMENT !== "production"
createHead()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app")
