import { Module } from "vuex"
import { RootState } from "@/store"
import { contractState, ContractState } from "./state"
import { getters } from "./getters"
import { actions } from "./actions"
import { contractMutations } from "./mutations"


const contractStateModule: Module<ContractState, RootState> = {
  namespaced: true,
  state: contractState,
  getters: { ...getters },
  mutations: { ...contractMutations },
  actions: { ...actions }
}

export default contractStateModule
export * from "./state"
export * from "./getters"
export * from "./mutations"
export * from "./actions"
export * from "./composables"

