export interface Registration {
  firstName: string|undefined
  lastName: string|undefined
  primaryPhoneNumber: string|undefined
  secondaryPhoneNumber: string|undefined
  dateOfBirth: string|undefined
  ssn: string|undefined
  addressLine1: string|undefined
  addressLine2: string|undefined
  zipCode: string|undefined
  stateCode: string|undefined
  city: string|undefined
  timeAtResidence: TimeAtResidence|undefined
  emailAddress: string|undefined
  password: string|undefined
  contactPreferences: boolean|undefined
  licenseNumber: string|undefined
  licenseIssuingStateCode: string|undefined
  licenseExpirationDate: string|undefined
  employmentStatus: EmploymentStatus|undefined
  employerCompanyName: string|undefined
  workPosition: string|undefined
  employerIndustry: Industry|undefined
  timeAtJob: TimeAtJob|undefined
  workPhone: string|undefined
  followingPaydate: string|undefined
  grossPayPerCheck: string|undefined
  hourlyOrSalaried: HourlyOrSalaried|undefined
  netPayPerCheck: string|undefined
  nextPaydate: string|undefined
  paymentFrequency: string|undefined
  wagePayment: WagePayment|undefined
  bankAccountNumber: string|undefined
  bankRoutingNumber: string|undefined
  creditReportConsent: boolean|undefined
  formSections: Array<FormSections>|undefined
  flowName: string|undefined
  flowAction: FlowActions|undefined
  flowButtonText: string|undefined
  offerCode: string|undefined
  lastFourSSN: string|undefined
  preapprovalSsnMismatchesCount: number
  preapprovalLocatorMismatchesCount: number
  isPreapprovalMode: boolean
  isPreapprovalModeUpswing: boolean
  isFromReg1: boolean
  leadUUID: string
  leadOfferId: string
  leadProviderId: string
  leadProviderName: string
  offerLead: boolean
}

export enum TimeAtResidence {
  LessThanOneMonth = "less_than_1_month",
  OneMonth = "1_month",
  TwoMonths = "2_months",
  FourToSixMonths = "4_to_6_months",
  SevenMonthsToOneYear = "7_months_to_1_year",
  OneYearToTwoYears = "1_year_to_2_years",
  MoreThanTwoYears = "more_than_2_years"
}

export enum EmploymentStatus {
  Employed = "employment",
  SelfEmployed = "self_employment",
  Benefits = "benefits"
}

export enum Industry {
  AccommodationAndFoodServices = "Accommodation and Food Services",
  AdministrativeAndSupportAndWasteManagement = "Administrative and Support and Waste Management",
  Agriculture = "Agriculture",
  Forestry = "Forestry",
  FishingAndHunting = "Fishing & Hunting",
  Arts = "Arts",
  EntertainmentAndRecreation = "Entertainment and Recreation",
  EducationalServices = "Educational Services",
  FinanceAndInsurance = "Finance and Insurance",
  GroceryOrPharmacy = "Grocery or Pharmacy",
  HealthCareAndSocialAssistance = "Health Care and Social Assistance",
  Information = "Information",
  Manufacturing = "Manufacturing",
  Military = "Military",
  Mining = "Mining",
  Professional = "Professional",
  ScientificAndTechnicalServices = "Scientific and Technical Services",
  PublicAdministration = "Public Administration",
  RealEstateAndRentalAndLeasing = "Real Estate and Rental and Leasing",
  RetailTrade = "Retail Trade",
  Security = "Security",
  TransportationAndWarehousing = "Transportation and Warehousing",
  Utilities = "Utilities",
  WholesaleTrade = "Wholesale Trade",
  OtherServices = "Other Services",
  Benefits = "Benefits"
}

export enum TimeAtJob {
  LessThanOneMonth = "less_than_1_month",
  OneMonth = "1_month",
  TwoMonths = "2_months",
  ThreeMonths = "3_months",
  FourToSixMonths = "4_to_6_months",
  SevenMonthsToOneYear = "7_months_to_1_year",
  OneYearToTwoYears = "1_year_to_2_years",
  MoreThanTwoYears = "more_than_2_years"
}

export enum HourlyOrSalaried {
  Salary = "Salary",
  Hourly = "Hourly",
  Benefits = "Benefits"
}

export enum WagePayment {
  DirectDeposit = "direct_deposit",
  PaperCheck = "paper_check",
  Cash = "cash"
}

export enum PaymentFrequency {
  Weekly = "weekly",
  Biweekly = "biweekly",
  TwiceMonthly = "twice_monthly",
  SpecificDate = "specific_date",
  LastWorkingDay = "last_working_day"
}

export enum FormSections {
  AccountInfo = "account_info",
  CheckingAccount = "checking_account",
  Consents = "consents",
  ContactPreferences = "contact_preferences",
  Employment = "employment",
  HomeAddress = "home_address",
  IdentityVerification = "identity_verification",
  Income = "income",
  Payschedule = "payschedule",
  PersonalDetails = "personal_details",
  SourceOfIncome = "employment_status",
}

export enum FlowActions {
  CreateNewUser = "createUser",
  Continue = "continue",
  LeadsUpdate = "leads"
}

export interface AuthenticateCustomerArgs {
  vueRoot: Vue,
  loginServerUrl: string,
  clientId: string
}

export interface Substitution {
  key: string,
  value: string
}

export interface DynamicName {
  name: string,
  substitutions: Array<Substitution>
}

