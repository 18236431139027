import { createOffers } from "@/lib/api/secure/offers-service"
import { WritableComputedRef, computed } from "vue"
import { useNamespacedMutations, useNamespacedGetters } from "vuex-composition-helpers"
import store, { OffersState, OffersGetters, offersState } from "@/store"
import { MultiOfferResponse, Offer } from "generated-cnuapp-types"
import { buildHelpers } from "@/store/composition-helpers"

const { mapGettersAndSettersToComputed } = buildHelpers<OffersState>(
  () => store, "offers"
)

type OfferStoreAccessors = {
  setOffersData(offers: MultiOfferResponse): void,
  fetchOffers(): Promise<void>,
  setSelectedOffer(selectedOffer: Offer): void
} & {
  [key in keyof OffersState]: WritableComputedRef<OffersState[key]>
}

let updatedValues: Record<string, boolean> = {}

const useOfferStore = function (): OfferStoreAccessors {
  const { keys, getField } = useNamespacedGetters<OffersGetters>(store, "offers", [
    "getField",
    "keys"
  ])

  const { updateField } = useNamespacedMutations(store, "offers", ["updateField"])

  const mapComputed: Record<string, WritableComputedRef<string | boolean | undefined>> = {}

  for (const key of keys?.value ?? []) {
    mapComputed[key] = computed<string | boolean | undefined>({
      get: () => getField.value(key.toString()),
      set: value => {
        updateField({ path: key.toString(), value })
        updatedValues[key] = true
      }
    })
  }

  const fetchOffers = async () => {
    let offers : MultiOfferResponse
    const mapFields = mapGettersAndSettersToComputed(Object.keys(offersState) as Array<keyof OffersState>)
    if (mapFields.offersPromise.value == undefined) {
      mapFields.offersPromise.value = createOffers()
    }
    try {
      offers = await mapFields.offersPromise.value
      mapFields.offersPromise.value = undefined
    }
    catch (error: any) {
      return Promise.reject(error)
    }
    if (offers) {
      setOffersData(offers)
    }

    updatedValues = {}
  }

  const setOffersData = (offerResponse : MultiOfferResponse) => {
    const mapFields = mapGettersAndSettersToComputed(Object.keys(offersState) as Array<keyof OffersState>)
    mapFields.offersPayload.value = offerResponse.data
  }

  const setSelectedOffer = (selectedOffer : Offer) => {
    const mapFields = mapGettersAndSettersToComputed(Object.keys(offersState) as Array<keyof OffersState>)
    mapFields.selectedOffer.value = selectedOffer
  }

  return {
    ...mapComputed,
    setOffersData,
    fetchOffers,
    setSelectedOffer
  } as unknown as OfferStoreAccessors
}

export { useOfferStore, OfferStoreAccessors }
