import { OfferPayload, Offer, MultiOfferResponse } from "generated-cnuapp-types"

interface RefinanceOffers {
  id: number,
  totalAmountDue: number,
  loanAmount: number,
  recurringPayment: number,
  productCd: string,
  loanTerm: string,
  apr: number,
  cashOutAmount: number
}

interface RefinanceData {
  offers: RefinanceOffers[],
  paydateSchedule: PaydateSchedule,
  refinanceApplicationId: string
  previousBalance: number
  loanId: string
}

interface PaydateSchedule {
  human_frequency: string
  next_paydate: string
  following_paydate: string
}

const newOffer = function (): OffersState {
  return {
    offersPayload: undefined,
    selectedOffer: undefined,
    offersPromise: undefined,
    refinanceData: undefined
  }
}

interface OffersState {
  offersPayload: OfferPayload | undefined,
  selectedOffer: Offer | undefined,
  offersPromise: Promise<MultiOfferResponse> | undefined,
  refinanceData: RefinanceData | undefined
}

const offersState: OffersState = newOffer()

export { offersState, OffersState, newOffer }
