window.io_global_object_name = "IGLOO";
window.IGLOO = window.IGLOO || {
  bb_callback: null,
  loader: {
    version: "general5",
    trace_handler: function (msg) {
      console.log(msg);
    },
  },
};
