import { FormSections } from "@/lib/models"
import { RegistrationStoreAccessors, useRegistrationStore, useSessionStore } from "@/store"
import { ComputedRef } from "vue"
import { UpdateAccountRequestBankAccountTypeEnum, UpdateAccountRequest, PreapprovalRequest } from "generated-cnuapp-types"

//The key names on the right should match the update-account-request generated file. https://git.enova.com/cashnet/web-acquisitions/blob/master/src/generated/cnuapp/api-models/update-account-request.ts#L4
const requestFieldsMapping: { [key: string]: string } = {
  firstName:"first_name",
  lastName:"last_name",
  primaryPhoneNumber:"primary_phone_number",
  secondaryPhoneNumber:"secondary_phone_number",
  dateOfBirth:"date_of_birth",
  addressLine1:"address_line1",
  addressLine2:"address_line2",
  city:"city",
  stateCode:"state",
  zipCode:"zip_code",
  timeAtResidence:"time_at_residence",
  employmentStatus:"employment_status",
  wagePayment:"wage_payment",
  hourlyOrSalaried:"hourly_or_salaried",
  netPayPerCheck:"net_pay_per_check",
  grossPayPerCheck:"gross_pay_per_check",
  paymentFrequency:"payment_frequency",
  nextPaydate:"next_paydate",
  followingPaydate:"following_paydate",
  employerCompanyName:"employer_company_name",
  employerIndustry:"employer_industry",
  workPosition:"work_position",
  workPhone:"work_phone",
  timeAtJob:"time_at_job",
  contactPreferences:"no_marketing_call_flg",
  licenseNumber:"license_number",
  licenseIssuingStateCode:"license_issuing_state_code",
  licenseExpirationDate:"license_expiration_date",
  bankAccountNumber:"bank_account_number",
  bankRoutingNumber:"bank_routing_number",
  creditReportConsent: "consent_to_electronic_communication",
  leadUUID: "lead_uuid",
  leadOfferId: "lead_offer_id"
}

export interface CreateAccountRequest {
  first_name: string
  last_name: string
  home_phone: string
  mobile_phone: string | null
  date_of_birth: string
  social_security_number: string
  line1: string
  line2: string | null
  postal_code: string
  region: string
  city: string
  time_at_residence_cd: string
  login: string
  passwd: string
  preapproval: PreapprovalRequest
  is_preapproval_mode: boolean
  preapproval_ssn_mismatches_count: number
  no_marketing_call_flg: boolean
}
export function createAccountRequestBody(recaptcha: string): CreateAccountRequest {
  const {
    firstName,
    lastName,
    primaryPhoneNumber,
    secondaryPhoneNumber,
    dateOfBirth,
    ssn,
    addressLine1,
    addressLine2,
    zipCode,
    stateCode,
    city,
    timeAtResidence,
    emailAddress,
    password,
    isPreapprovalMode,
    offerCode,
    preapprovalSsnMismatchesCount,
    contactPreferences,
    lastFourSSN
  } = useRegistrationStore()

  let dob = dateOfBirth.value ?? ""
  if (dob.length > 0) {
    const temp = dob.split("/")
    dob = `${temp[2]}-${temp[0]}-${temp[1]}`
  }

  let social = ssn.value ?? ""
  if (social.length > 0) {
    social = social.replace(/-/g, "")
  }

  return {
    first_name: firstName.value ?? "",
    last_name: lastName.value ?? "",
    home_phone: primaryPhoneNumber.value ?? "",
    mobile_phone: secondaryPhoneNumber.value ?? "",
    date_of_birth: dob,
    social_security_number: social,
    line1: addressLine1.value ?? "",
    line2: addressLine2.value ?? "",
    postal_code: zipCode.value ?? "",
    region: stateCode.value ?? "",
    city: city.value ?? "",
    time_at_residence_cd: timeAtResidence.value ?? "",
    login: emailAddress.value ?? "",
    passwd: password.value ?? "",
    preapproval: {
      offer_code: offerCode.value ?? "",
      last_four_ssn: lastFourSSN.value ?? ""
    },
    is_preapproval_mode: isPreapprovalMode.value ?? false,
    preapproval_ssn_mismatches_count: preapprovalSsnMismatchesCount.value,
    no_marketing_call_flg: !contactPreferences.value,
    recaptcha: recaptcha
  }
}

export function updateAccountRequestBody(updatedValues: string[]): UpdateAccountRequest {
  const requestBody: UpdateAccountRequest = {}
  const regStore = useRegistrationStore()
  if (updatedValues.length !== 0) {
    updatedValues.forEach(function(property){
      if (regStore[property as keyof RegistrationStoreAccessors]) {
        const result: ComputedRef = regStore[property as keyof RegistrationStoreAccessors] as ComputedRef
        Object.assign(requestBody, { [requestFieldsMapping[property]]: property === "contactPreferences" ? !result.value : result.value })
      }
    })
    requestBody.bank_account_type = regStore.bankAccountNumber.value ? UpdateAccountRequestBankAccountTypeEnum.Checking : undefined
    requestBody.black_box = useSessionStore().iovationFraudForceFingerprint.value
  }

  return requestBody
}

export function updateAccountRequestBodyNoStore(updatedValues: any): UpdateAccountRequest {
  const requestBody: UpdateAccountRequest = {}
  if (updatedValues.length !== 0) {
    for (const property in updatedValues) {
      if (requestFieldsMapping[property]) {
        Object.assign(requestBody, { [requestFieldsMapping[property]]: updatedValues[property] })
      }
    }
    requestBody.bank_account_type = updatedValues.bankAccountNumber ? UpdateAccountRequestBankAccountTypeEnum.Checking : undefined
    requestBody.black_box = useSessionStore().iovationFraudForceFingerprint.value
  }
  return requestBody
}


type PartialFormSections = Partial<Record<FormSections, boolean>>
export function mapFieldNamesToSections(fields: Array<string>): Array<FormSections> {
  const formSections: PartialFormSections = {}
  fields.forEach(field => {
    if (isPersonalDetails(field)) formSections[FormSections.PersonalDetails] = true
    if (isHomeAddress(field)) formSections[FormSections.HomeAddress] = true
    if (isIdentityVerification(field)) formSections[FormSections.IdentityVerification] = true
    if (isSourceOfIncome(field)) formSections[FormSections.SourceOfIncome] = true
    if (isEmployment(field)) formSections[FormSections.Employment] = true
    if (isPayschedule(field) && !formSections[FormSections.Income]) formSections[FormSections.Payschedule] = true
    if (isIncome(field)) {
      formSections[FormSections.Income] = true
      delete formSections[FormSections.Payschedule]
    }
    if (isCheckingAccount(field)) formSections[FormSections.CheckingAccount] = true
    if (isConsents(field)) formSections[FormSections.Consents] = true
    if (isContactPreferences(field)) formSections[FormSections.ContactPreferences] = true
  })

  return Object.keys(formSections) as Array<FormSections>
}

function isPersonalDetails(field: string) {
  return ["first_name",
    "last_name",
    "primary_phone_number",
    "secondary_phone_number",
    "date_of_birth",
    "social_security_number"].includes(field)
}

function isHomeAddress(field: string) {
  return ["address_line1", "address_line2", "city", "zip_code", "state", "time_at_residence"].includes(field)
}

function isIdentityVerification(field: string) {
  return ["drivers_license", "drivers_license_region", "drivers_license_expiration"].includes(field)
}
function isSourceOfIncome(field: string) {
  return ["employment_status"].includes(field)
}
function isEmployment(field: string) {
  return [
    "employer_company_name",
    "work_position",
    "time_at_job",
    "work_phone",
    "employer_industry",
    "employment_status",
    "company_name"
  ].includes(field)
}
function isIncome(field: string) {
  return ["wage_payment", "net_pay_per_check", "gross_pay_per_check"].includes(field)
}
function isPayschedule(field: string) {
  return ["next_paydate", "following_paydate", "payment_frequency", "income_next_date1", "income_next_date2", "income_payment_period"].includes(field)
}
function isCheckingAccount(field: string) {
  return ["bank_account_number", "bank_routing_number", "bank_account_type"].includes(field)
}
function isConsents(field: string) {
  return ["consent_to_electronic_communication"].includes(field)
}
function isContactPreferences(field: string) {
  return ["no_marketing_call_flg"].includes(field)
}


export default {}
