import { AnalyticsPlugin } from "analytics"
import { Config, EventCallback, Payload, Severity } from "@/lib/analytics/analytics-types"
import { buildAnalyticsCallback } from "@/lib/analytics/helpers"

function severityLevels(severity: Severity): (typeof console.log | typeof console.warn | typeof console.error) {
  switch (severity) {
  case "warning":
    return console.warn
  case "error":
    return console.error
  default:
    return console.log
  }
}

function presentPayload(payload: Payload, payloadType: string): Array<unknown> {
  if (payload.properties && payload.properties.error) {
    const presented = [payload.properties.error]
    if (payload.properties.message)
      presented.push(payload.properties.message)
    return presented
  } else if (payload.traits && payload.traits.error) {
    const presented = [payload.traits.error]
    if (payload.traits.message)
      presented.push(payload.traits.message)
    return presented
  } else {
    return [`[${payloadType}]`, payload]
  }
}

interface ConsoleConfig {
  maxSeverity: Severity
}

const defaultSeverity = "info"
const impl = {
  track: buildAnalyticsCallback(({ payload, config }: EventCallback) => {
    const { severity } = payload.properties
    if ((severity || defaultSeverity) <= (config as ConsoleConfig).maxSeverity)
      severityLevels(severity as Severity)(...presentPayload(payload, "track"))
  }),
  identify: buildAnalyticsCallback(({ payload }: EventCallback) => {
    severityLevels(defaultSeverity)(...presentPayload(payload, "identify"))
  }),
  page: buildAnalyticsCallback(({ payload, config }: EventCallback) => {
    const { severity } = payload.properties
    if ((severity || defaultSeverity) <= (config as ConsoleConfig).maxSeverity)
      severityLevels(severity as Severity)(...presentPayload(payload, "page"))
  })
}

// Purpose: outputs all events into console to make debugging easier
export default function buildConsolePlugin(config: Config & { maxSeverity: Severity }): AnalyticsPlugin & typeof impl {
  return {
    name: "console",
    config: { ...config },
    ...impl
  }
}

