import { GetterTree } from "vuex"
import { RootState } from "@/store"
import { SessionState } from "./state"

interface SessionGetters extends GetterTree<SessionState, RootState> {
  keys(state: SessionState): Array<string>
}

const sessionGetters: SessionGetters = {
  keys(state: SessionState): Array<string> {
    return Object.keys(state)
  }
}

export { sessionGetters, SessionGetters }
