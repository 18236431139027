import moment from "moment";
const DAY_MILLISECONDS = 1000 * 3600 * 24;
function daysWithin(startDate, dateValue) {
    const diff = Math.abs(startDate.getTime() - dateValue.getTime());
    const diffDays = Math.round(diff / DAY_MILLISECONDS);
    return diffDays;
}
function isWeekend(date) {
    return date.getDay() === 6 || date.getDay() === 0;
}
// directlyFollowingMonths verify if the dates passed by parameter have
// directly following months eg: Jan - Feb
function directlyFollowingMonths(startDate, finalDate) {
    return (startDate.getMonth() + 1) % 12 === finalDate.getMonth() % 12;
}
export function customPaymentFrequencyValidator(args) {
    const format = args.format ?? "MM/DD/YYYY";
    return (value) => {
        if (typeof value !== "string") {
            return "Please enter a valid date.";
        }
        const startDate = moment(args.startDate, format, true).toDate();
        const dateValue = moment(value, format, true).toDate();
        switch (String(args.frequency)) {
            case "weekly": {
                if (!args.message)
                    args.message = "Your paydates must be weekly.";
                return daysWithin(startDate, dateValue) === 7 || args.message;
            }
            case "biweekly": {
                if (!args.message)
                    args.message = "Your paydates must be biweekly.";
                return daysWithin(startDate, dateValue) === 14 || args.message;
            }
            case "twice_monthly": {
                if (!args.message)
                    args.message = "Your paydates must be twice monthly.";
                const days = daysWithin(startDate, dateValue);
                return (days >= 5 && days <= 20) || args.message;
            }
            case "specific_date": {
                if (!args.message)
                    args.message =
                        "Your paydates do not correspond to the payment period indicated.";
                const days = daysWithin(startDate, dateValue);
                return (days >= 26 && days <= 34) || args.message;
            }
            case "last_working_day": {
                if (!args.message)
                    args.message =
                        "Your paydates do not correspond to the payment period indicated.";
                return ((directlyFollowingMonths(startDate, dateValue) &&
                    !isWeekend(startDate) &&
                    !isWeekend(dateValue)) ||
                    args.message);
            }
            case "specific_day": {
                if (!args.message)
                    args.message =
                        "Your paydates do not correspond to the payment period indicated.";
                const isSameWeekday = startDate.getDay() === dateValue.getDay();
                const sameWeekOfMonth = Math.ceil(startDate.getDate() / 7) ===
                    Math.ceil(dateValue.getDate() / 7);
                return ((isSameWeekday &&
                    directlyFollowingMonths(startDate, dateValue) &&
                    sameWeekOfMonth) ||
                    args.message);
            }
            case "last_day": {
                if (!args.message)
                    args.message =
                        "Your paydates do not correspond to the payment period indicated.";
                const nextEndOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
                const followingEndOfMonth = new Date(dateValue.getFullYear(), dateValue.getMonth() + 1, 0);
                const isLastWeekNextDate = daysWithin(startDate, nextEndOfMonth) < 7;
                const isLastWeekFollowingDate = daysWithin(dateValue, followingEndOfMonth) < 7;
                const isSameWeekday = startDate.getDay() === dateValue.getDay();
                return ((isSameWeekday &&
                    !isWeekend(startDate) &&
                    !isWeekend(dateValue) &&
                    isLastWeekNextDate &&
                    isLastWeekFollowingDate) ||
                    args.message);
            }
            default: {
                if (!args.message)
                    args.message =
                        "Your paydates do not correspond to the payment period indicated.";
                return args.message;
            }
        }
    };
}
