export function customMinLengthValidator(args) {
    const message = args.message ?? `Must be at least ${args.length} characters long.`;
    return (value) => {
        if (typeof value !== "string") {
            return message;
        }
        if (value.length < args.length) {
            return message;
        }
        return true;
    };
}
