/* iovation javascript */
require("@/vendor/iovation/config.js")
require("@/vendor/iovation/loader_only.js")

import analytics, { logError } from "@/lib/analytics"

const onDemandFingerprintFetcher = (): string | undefined => {
  try {
    const iovationBlackBox = window.IGLOO.getBlackbox()
    if (iovationBlackBox.finished) {
      analytics.track("IOVATION_FINGERPRINT_OBTAINED")
      return iovationBlackBox.blackbox
    } else {
      analytics.track("IOVATION_FINGERPRINT_NOT_FINISHED")
    }
  } catch (e: any) {
    logError(e, "IOVATION_FINGERPRINT_FAILED")
  }

  return undefined
}

export async function getFingerprint(): Promise<string | undefined> {
  return onDemandFingerprintFetcher()
}
