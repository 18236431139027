const claimsIndex = 1
import { logError } from "@/lib/analytics"

export default function parseJwt(token: string) : Record<string, unknown> {
  try {
    return JSON.parse(atob(token.split(".")[claimsIndex]))
  } catch (e: any) {
    logError(e)
    return {}
  }
}
