import { Income } from "."

export interface Employment {
  employmentType?: EmploymentType
  paymentType?: PaymentType
  companyName?: string
  industryType?: string
  workPosition?: string
  workStartDate?: string
  income?: Income
}
export enum EmploymentType {
  Employment = "employment",
  PartTime = "part_time",
  Contractor = "contractor",
  SelfEmployment = "self_employment",
  Benefits = "benefits",
  Pension = "pension",
  Unemployed = "unemployed",
  Other = "other"
}
export enum PaymentType {
  Hourly = "hourly",
  Salary = "salary"
}
