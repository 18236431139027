/* This was designed to be a "poor-man's way of dependency injection, but we figured out how to use jest.spy
 * so this is no longer necessary */

/* TODO:
 **  Delete this file and reference all dependencies directly / distribute singletons to appropriate classes
 **  Use jest.spy in all places where we use this pattern to mock
*/

import { computed, reactive, WritableComputedRef } from "vue"
import { JWTService } from "@/lib/api/types"

import { ApiPublicService } from "@/lib/api/public-service"
import { ApiSecureService } from "@/lib/api/secure-service"
import { OrchestratorApiPublicService } from "@/lib/api/orchestrator-public-service"
import { OrchestratorApiSecureService } from "@/lib/api/orchestrator-secure-service"

interface ApiServicesDepreciated {
  publicService: ApiPublicService | null
  secureService: ApiSecureService | null
  orchestratorPublicService: OrchestratorApiPublicService | null
  orchestratorSecureService: OrchestratorApiSecureService | null
}

export interface UseApiServicesDepreciated {
  initializeServices(cnuConfig: JWTService, orchestratorConfig: JWTService): void
  apiPublicServiceDepreciated: WritableComputedRef<ApiPublicService | null>
  apiSecureServiceDepreciated: WritableComputedRef<ApiSecureService | null>
  orchestratorApiPublicServiceDepreciated: WritableComputedRef<OrchestratorApiPublicService | null>
  orchestratorApiSecureServiceDepreciated: WritableComputedRef<OrchestratorApiSecureService | null>
}

const apiServices: ApiServicesDepreciated = reactive({
  publicService: null,
  secureService: null,
  orchestratorPublicService: null,
  orchestratorSecureService: null
})

export function useApiServicesDepreciated(): UseApiServicesDepreciated {
  function initializeServices(cnuConfig: JWTService, orchestratorConfig: JWTService): void {
    apiPublicServiceDepreciated.value = new ApiPublicService(cnuConfig)
    apiSecureServiceDepreciated.value = new ApiSecureService(cnuConfig)
    orchestratorApiPublicServiceDepreciated.value = new OrchestratorApiPublicService(orchestratorConfig)
    orchestratorApiSecureServiceDepreciated.value = new OrchestratorApiSecureService(orchestratorConfig)
  }

  const apiPublicServiceDepreciated: WritableComputedRef<ApiPublicService | null> = computed<ApiPublicService | null>({
    get: () => apiServices.publicService,
    set(value: ApiPublicService | null) { apiServices.publicService = value }
  })

  const apiSecureServiceDepreciated: WritableComputedRef<ApiSecureService | null> = computed<ApiSecureService | null>({
    get: () => apiServices.secureService,
    set(value: ApiSecureService | null) { apiServices.secureService = value }
  })

  const orchestratorApiPublicServiceDepreciated: WritableComputedRef<OrchestratorApiPublicService | null> = computed<OrchestratorApiPublicService | null>({
    get: () => apiServices.orchestratorPublicService,
    set(value: OrchestratorApiPublicService | null) { apiServices.orchestratorPublicService = value }
  })

  const orchestratorApiSecureServiceDepreciated: WritableComputedRef<OrchestratorApiSecureService | null> = computed<OrchestratorApiSecureService | null>({
    get: () => apiServices.orchestratorSecureService,
    set(value: OrchestratorApiSecureService | null) { apiServices.orchestratorSecureService = value }
  })

  return {
    initializeServices,
    apiPublicServiceDepreciated,
    apiSecureServiceDepreciated,
    orchestratorApiPublicServiceDepreciated,
    orchestratorApiSecureServiceDepreciated
  }
}

export default {}
