import { Module } from "vuex"
import store, { RootState } from "@/store"
import { buildHelpers } from "@/store/composition-helpers"
import { Ref } from "vue"
import { AccountResponse } from "@/lib/api/adapters/accounts"
import { getAccount } from "@/lib/api/secure/accounts-service"
import { updateField, getField } from "vuex-map-fields"
import { genericErrorMessage } from "@/lib/util/errors"
import moment from "moment"

const { mapGettersAndSettersToComputed } = buildHelpers<AccountStore>(
  () => store, "account"
)

interface AccountStore {
  accountId: string
  firstName: string
  isUpswingBrandingEligible: boolean
  hasPulledData: boolean
  stateCode: string
  hasLoans: boolean
  eligibleForLoan: boolean
  approvalId: string
  bankAccountNumber: string
  bankName: string
  isLead: boolean
  nextPaydate: string
  followingPaydate: string
  paymentFrequency: string
  isPreapproved: boolean
}

export const newAccountState = () : AccountStore => {
  return {
    accountId: "",
    firstName: "",
    isUpswingBrandingEligible: false,
    hasPulledData: false,
    stateCode: "",
    hasLoans: false,
    eligibleForLoan: true,
    approvalId: "",
    bankAccountNumber: "",
    bankName: "",
    isLead: false,
    nextPaydate: "",
    followingPaydate: "",
    paymentFrequency: "",
    isPreapproved: false
  }
}
const accountState : AccountStore = newAccountState()

interface AccountStoreAccessors {
  accountId: Ref<string>
  firstName:  Ref<string>
  isUpswingBrandingEligible: Ref<boolean>
  hasPulledData: Ref<boolean>
  stateCode: Ref<string>
  hasLoans: Ref<boolean>
  eligibleForLoan: Ref<boolean>
  approvalId: Ref<string>
  bankAccountNumber: Ref<string>
  bankName: Ref<string>
  nextPaydate: Ref<string>
  followingPaydate: Ref<string>
  paymentFrequency: Ref<string>
  setAccountData(val: AccountResponse): void
  resetState(): void
  refreshAccountState(): Promise<void>,
  isLead: Ref<boolean>,
  isPreapproved: Ref<boolean>
}

function useAccountStore(): AccountStoreAccessors {
  const mapFields = mapGettersAndSettersToComputed(Object.keys(accountState) as Array<keyof AccountStore>)
  function setAccountData(val: AccountResponse): void {
    try {
      mapFields.accountId.value = val.account_id
      mapFields.firstName.value = val.first_name
      mapFields.isUpswingBrandingEligible.value = val.upswing.is_branding_eligible
      mapFields.stateCode.value = val.address.region
      mapFields.hasLoans.value = val.has_loans
      mapFields.eligibleForLoan.value = val.loan_eligibility.eligible
      mapFields.approvalId.value = val.approval_id
      mapFields.bankAccountNumber.value = val.bank_account_number
      mapFields.bankName.value = val.bank_name
      mapFields.isLead.value = val.lead_flag
      mapFields.nextPaydate.value = val.next_paydate ? moment(val.next_paydate).format("MM/DD/YYYY") : ""
      mapFields.followingPaydate.value = val.following_paydate ? moment(val.following_paydate).format("MM/DD/YYYY") : ""
      mapFields.paymentFrequency.value = val.payment_frequency
      mapFields.hasPulledData.value = true
      mapFields.isPreapproved.value = val.preapproval_flg
    }
    catch {
      throw genericErrorMessage
    }
  }

  function resetState(): void {
    type AccountAttributes = keyof AccountStore
    const account: AccountStore = newAccountState();
    (Object.keys(account) as Array<AccountAttributes>)
      .forEach((key: string) => {
        mapFields[key as keyof AccountStore].value = account[key as keyof AccountStore]
      })
  }

  async function refreshAccountState(): Promise<void> {
    const account = await getAccount()
    setAccountData(account)
  }

  return {
    ...mapFields,
    resetState,
    refreshAccountState,
    setAccountData
  } as AccountStoreAccessors
}

const accountModule: Module<AccountStore, RootState> = {
  namespaced: true,
  state: accountState,
  getters: { getField },
  mutations: { updateField }
}

export default accountModule
export { accountState, useAccountStore, AccountStore, AccountStoreAccessors }
