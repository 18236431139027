import { ApiSecureService } from "@/lib/api/secure-service"
import { useSessionStore } from "@/store"

import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import { logError } from "@/lib/analytics"
import { RequiredActionsResponse } from "generated-cnuapp-types"

const url = "/v4/secure/accounts"

export async function getRequiredActions(): Promise<RequiredActionsResponse> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!

  const { accountId } = useSessionStore()

  if (!accountId.value) {
    logError("getRequiredActions attempted for non-logged in user")
    return Promise.reject("user not logged in")
  }

  const urlWithId = `${url}/${accountId.value}/required_actions`
  try {
    const response = await cnuSecureApiService.get<RequiredActionsResponse>(urlWithId)
    return Promise.resolve(response)
  }
  catch(e) {
    logError(`Required Actions API Error: ${e}`)
    return Promise.reject(e)
  }
}
