import { ActionTree, ActionContext } from "vuex"
import { RootState } from "@/store"
import { ContractState, defaultContractData, defaultContractErrors, defaultSignature } from "./state"
import { ContractMutations, MutationTypes } from "./mutations"
import { ContractData } from "generated-cnuapp-types"
import { LoanCreateResponse } from "generated-cnuapp-types"
import { getContract, submitContract, switchRepaymentMethod } from "@/lib/api/secure/contract-service"
import { useContractStore, useSessionStore } from "@/store"
import { logError } from "@/lib/analytics"

enum ActionTypes {
  populateContractData = "populateContractData",
  clearContractData    = "clearContractData",
  submitContract       = "submitContract",
  switchRepaymentMethod = "switchRepaymentMethod",
  updateViewedRbpPopup = "updateViewedRbpPopup"
}

type AugmentedActionContext = {
  commit<K extends keyof ContractMutations>(
    key: K,
    payload: Parameters<ContractMutations[K]>[1]
  ): ReturnType<ContractMutations[K]>
} & Omit<ActionContext<ContractState, RootState>, "commit">

type ContractAction<Payload, Result> = {(context: AugmentedActionContext, payload: Payload): Promise<Result>};

export interface Actions {
  [ActionTypes.populateContractData]: ContractAction<ContractData, void>;
  [ActionTypes.clearContractData]: ContractAction<void, void>;
  [ActionTypes.submitContract]: ContractAction<void, LoanCreateResponse>;
  [ActionTypes.switchRepaymentMethod]: ContractAction<string, void>;
  [ActionTypes.updateViewedRbpPopup]: ContractAction<boolean, void>;

}

export const actions: ActionTree<ContractState, RootState> & Actions = {
  async [ActionTypes.populateContractData]({ commit }): Promise<void> {
    const { accountId } = useSessionStore()
    if (!accountId.value) {
      logError("getContract attempted for non-logged in user")
      return Promise.reject("user not logged in")
    }

    const contractData = await getContract(accountId.value)
    commit(MutationTypes.SET_CONTRACT_DATA, contractData)
    if (contractData.metadata?.loan_type === "oec") {
      commit(MutationTypes.SET_DRAW_AMOUNT, contractData.metadata?.amount || 0)
    }
  },

  [ActionTypes.clearContractData]({ commit }): Promise<void> {
    commit(MutationTypes.SET_CONTRACT_DATA, defaultContractData)
    commit(MutationTypes.SET_CONTRACT_ERRORS, defaultContractErrors)
    commit(MutationTypes.SET_CUSTOMER_INPUT, defaultSignature)
    commit(MutationTypes.SET_SELECTED_REPAYMENT_METHOD, false)
    return Promise.resolve()
  },

  [ActionTypes.updateViewedRbpPopup]({ commit }, payload): Promise<void> {
    commit(MutationTypes.SET_VIEWED_RPB_POPUP, payload)
    return Promise.resolve()
  },

  async [ActionTypes.submitContract](): Promise<LoanCreateResponse> {
    const { accountId } = useSessionStore()

    const { contractStoreData, submissionFormData } = useContractStore()
    const contractId = contractStoreData.value.metadata["loan_offer_id"]

    return await submitContract(accountId.value, contractId, submissionFormData.value)
  },

  async [ActionTypes.switchRepaymentMethod]({ state, commit }, newRepaymentMethod: string): Promise<void> {
    if(state.contractData?.metadata?.repayment_method === newRepaymentMethod) {
      return
    }

    const { accountId } = useSessionStore()

    let newContractData: ContractData

    try {
      newContractData = await switchRepaymentMethod(accountId.value, newRepaymentMethod)
    }
    catch (error: any) {
      commit(MutationTypes.INCREMENT_CONTRACT_PAGE_ERRORS, undefined)
      throw error
    }
    commit(MutationTypes.SET_CUSTOMER_INPUT, defaultSignature)
    commit(MutationTypes.SET_CONTRACT_DATA, newContractData)
  }
}
