import moment from "moment";
import { customDateValidator } from "./date.js";
export function customFutureDateValidator(args) {
    const message = args?.message ?? "Please enter a date in the future.";
    const format = args?.format ?? "MM/DD/YYYY";
    const dateArgs = {
        tooEarlyMessage: message,
        dateInvalidMessage: args?.dateInvalidMessage,
        minDate: moment(new Date()).add(1, "days").format(format),
        format
    };
    return customDateValidator(dateArgs);
}
