import { ApiSecureService } from "@/lib/api/secure-service"
import { ContractData } from "generated-cnuapp-types"
import { CreateLoanRequest } from "generated-cnuapp-types"
import { LoanCreateResponse } from "generated-cnuapp-types"
import { CreateScheduleRequest, ScheduleCreateResponse } from "lib-cnu-components"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import analytics, { logError } from "@/lib/analytics"

const url = "/v4/secure/accounts"

export async function getContract(accountId: string): Promise<ContractData> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!
  const urlWithId = `${url}/${accountId}/contract`

  try {
    const response = await cnuSecureApiService.get<ContractData>(urlWithId)
    if (response) {
      return Promise.resolve(response)
    }
    return Promise.reject("empty contract response from CNUAPP")
  }
  catch (e: any) {
    logError(`Contract API Error: ${e}`)
    return Promise.reject(e)
  }
}

export async function submitContract(accountId: string, contractId: string, submitData: CreateLoanRequest): Promise<LoanCreateResponse> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!
  const urlWithId = `${url}/${accountId}/loan_offers/${contractId}`

  try {
    return cnuSecureApiService.post<CreateLoanRequest, LoanCreateResponse>(urlWithId, submitData)
  }
  catch (e: any) {
    logError(`Contract API Error: ${e}`)
    return Promise.reject(e)
  }
}

export async function switchRepaymentMethod(accountId: string, newPaymentMethod: string): Promise<ContractData> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!

  const urlWithId = `${url}/${accountId}/switch_contract_repayment?new_repayment_method=${newPaymentMethod}`
  try {
    const response = await cnuSecureApiService.post<void, ContractData>(urlWithId, undefined)
    if (response) {
      return response
    }
    throw "empty repayment switch response from CNUAPP"
  }
  catch (e: any) {
    logError(`Contract Repayment Switch API Error: ${e}`)
    throw e
  }
}

export async function createAmortizationSchedule(accountId: string, scheduleData: CreateScheduleRequest): Promise<ScheduleCreateResponse> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!
  const urlWithId = `${url}/${accountId}/oec_amortization`

  try {
    return cnuSecureApiService.post<CreateScheduleRequest, ScheduleCreateResponse>(urlWithId, scheduleData)
  }
  catch (e: any) {
    logError(`Contract Amortization Schedule API Error: ${e}`)
    analytics.track("scheduleCreateFailure")
    return Promise.reject(e)
  }
}

export async function updateScheduleOffer(accountId: string, scheduleOfferId: number): Promise<void> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!
  const urlWithId = `${url}/${accountId}/oec_amortization/${scheduleOfferId}`

  try {
    cnuSecureApiService.put<void, void>(urlWithId, undefined)
  }
  catch (e: any) {
    logError(`Contract Schedule Offer Update API Error: ${e}`)
    analytics.track("scheduleOfferUpdateFailure")
    return Promise.reject(e)
  }
}
