export interface BankAccount {
  accountType?: BankAccountType
  accountNumber?: string
  routingNumber?: string
  bankName?: string
  bankState?: string
  bankPhone?: string
  startDate?: string
  status?: string
  bad?: boolean
}

export enum BankAccountType {
  Checking = "checking",
  Savings = "savings",
  Other = "other"
}
