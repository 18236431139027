import Cookies from "js-cookie"
import { JWTStorage } from "@/lib/api/types/index"

class CookieJWTStorage implements JWTStorage {
  private accessTokenCookieName: string
  private refreshTokenCookieName: string
  private cookieDomain: string
  private secureCookies: boolean

  public constructor() {
    this.accessTokenCookieName =
      process.env.VUE_APP_ACCESS_TOKEN_KEY_NAME || "access_token"
    this.refreshTokenCookieName =
      process.env.VUE_APP_REFRESH_TOKEN_KEY_NAME || "refresh_token"
    this.cookieDomain = process.env.VUE_APP_JWT_COOKIE_DOMAIN_NAME || "localhost"
    this.secureCookies = process.env.VUE_APP_SECURE_JWT_COOKIES === "true"
  }

  public getAccessToken(): string {
    return Cookies.get(this.accessTokenCookieName) || ""
  }

  public getRefreshToken(): string {
    return Cookies.get(this.refreshTokenCookieName) || ""
  }

  public saveTokens(accessToken: string, refreshToken: string): void {
    Cookies.set(this.accessTokenCookieName, accessToken, { secure: this.secureCookies, domain: this.cookieDomain })
    Cookies.set(this.refreshTokenCookieName, refreshToken, { secure: this.secureCookies, domain: this.cookieDomain })
  }

  public expire(): void {
    Cookies.remove(this.accessTokenCookieName, { secure: this.secureCookies, domain: this.cookieDomain })
    Cookies.remove(this.refreshTokenCookieName, { secure: this.secureCookies, domain: this.cookieDomain })
  }
}

export default new CookieJWTStorage()
