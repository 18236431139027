export function customNumberComparisonValidator(args) {
    return (value) => {
        let parsedValue = NaN;
        if (typeof value === "number") {
            parsedValue = value;
        }
        if (typeof value === "string") {
            parsedValue = Number(value.replace(/,/g, ""));
        }
        if (Number.isNaN(parsedValue)) {
            return args.message;
        }
        if (!args.valid(parsedValue)) {
            return args.message;
        }
        return true;
    };
}
