import { ApiSecureService } from "@/lib/api/secure-service"
import analytics, { logError } from "@/lib/analytics"
import { useSessionStore } from "@/store"
import { HandleLeadsInterstitialPageSubmissionRequest, HandleLeadsInterstitialPageSubmissionResponse } from "@/lib/api/adapters/leads"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"

const url = "/v4/secure/leads"

export async function updateLeadsInterstitialSubmission(requestBody: HandleLeadsInterstitialPageSubmissionRequest): Promise<void> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!

  const { accountId } = useSessionStore()
  const urlWithId = `${url}/${accountId.value}/interstitial_submit`

  try {
    await cnuSecureApiService.post<HandleLeadsInterstitialPageSubmissionRequest, HandleLeadsInterstitialPageSubmissionResponse>(urlWithId, requestBody)
    analytics.track("update_interstitial_succeeded", { account_id: accountId.value })
    return Promise.resolve(undefined)
  }
  catch(e: any) {
    /* istanbul ignore if */
    if(e)
      logError(e)

    if(e.response?.data?.errors){
      analytics.track("update_interstitial_failed", { account_id: accountId.value, error_code: e.response.status, error_message: e.message, errors: e.response.data.errors.join(", ") })
    } else {
      analytics.track("update_interstitial_failed_no_data", { account_id: accountId.value, error_code: e?.response?.status })
    }
    if(e?.response?.status === 401) {
      return Promise.reject(e)
    }
    if(e?.response?.status === 404 && !!(e.response?.data?.errors)){
      return Promise.reject(e.response.data)
    }
    return Promise.reject(e)
  }
}

