export function customExactLengthValidator(args) {
    const message = args.message ?? `This field must have ${args.length} character(s).`;
    return (value) => {
        if (typeof value !== "string") {
            return message;
        }
        if (value.length !== args.length) {
            return message;
        }
        return true;
    };
}
