import { GetterTree } from "vuex"
import { RootState } from "@/store"
import { newRegistration, RegistrationState } from "@/store/registration"

interface RegistrationGetters extends GetterTree<RegistrationState, RootState> {
  newRegistration(): RegistrationState
  keys(state:RegistrationState): Array<string>
}
const registrationGetters: RegistrationGetters = {
  newRegistration(): RegistrationState {
    return newRegistration()
  },
  keys(state: RegistrationState): Array<string> {
    return Object.keys(state)
  }
}

export { RegistrationGetters, registrationGetters }
