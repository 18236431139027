import { useLoginServer } from "@/composables/use-login-server"
import { createAccount } from "@/lib/api/public/accounts-service"
import { AccountResponse } from "@/lib/api/adapters/accounts"
import { updateAccount } from "@/lib/api/secure/accounts-service"
import { getState } from "@/lib/api/public/zip-code-service"
import { Registration, FlowActions } from "@/lib/models"
import { ComputedRef, WritableComputedRef, computed, Ref } from "vue"
import { useNamespacedMutations, useNamespacedGetters } from "vuex-composition-helpers"
import store, { useAccountStore, useSessionStore, RegistrationState, RegistrationGetters } from "@/store"
import { canStateRegister } from "@/lib/api/public/states-service"
import router, { ExternalRouteLinks } from "@/router"
import CookieSeoTrackingStorage from "@/lib/api/cookie-seo-tracking-storage"
import { updateSeoTracking } from "@/lib/api/secure/update-seo-tracking-service"
import { updateLeadsInterstitialSubmission } from "@/lib/api/secure/leads-service"
import { mapStoreToLeadsInterstitialRequest } from "@/lib/api/adapters/leads"
import analytics, { logError, logWarning } from "@/lib/analytics"
import { genericErrorMessage, isRuntimeError } from "@/lib/util/errors"
import { FormSections } from "@/lib/models"
import { getAdobeTrackingId } from "@/lib/analytics/helpers"
import { submitStep2Data } from "@/lib/api/secure/orchestrator-service"
import { translateOrchestratorRoute } from "@/router/routing-helpers"
import { VerifyReCaptcha } from "lib-wrapped-login-page-api"

type RegistrationStoreAccessors = {
  newRegistration: ComputedRef<RegistrationState>,
  setRegionCity(val: string): Promise<void>,
  completeRegistration(): Promise<void>,
  regionDropdownDisabled: ComputedRef<boolean>,
  shouldIgnoreValidation: ComputedRef<boolean>,
  resetPassword(): void,
  resetState(): void,
  updateRegistration(): Promise<string>,
  updateLeadsRegistration(): Promise<string>,
  resetUpdatedValues() : void
  setAccountData(account: AccountResponse): void
  submitAction(): Promise<string>,
  setIsFromReg1(fromReg1: boolean): void
  formSectionComparator: ReturnType<typeof buildFormSectionComparator>,
  setDataFromAccount(): void,
  checkReCaptcha(): Promise<string>
} & {
    [key in keyof Registration]: Ref<Registration[key]>
  }


function buildFormSectionComparator(): (a: FormSections, b: FormSections) => number {
  const sectionOrdering = [
    FormSections.PersonalDetails,
    FormSections.HomeAddress,
    FormSections.AccountInfo,
    FormSections.SourceOfIncome,
    FormSections.Employment,
    FormSections.Income,
    FormSections.Payschedule,
    FormSections.CheckingAccount,
    FormSections.Consents,
    FormSections.IdentityVerification,
    FormSections.ContactPreferences
  ].reduce((sectionOrdering, section, i) => {
    sectionOrdering[section] = i + 1
    return sectionOrdering
  }, {} as Record<FormSections, number>)

  const undeclaredPriority = Object.keys(sectionOrdering).length + 1

  return (a: FormSections, b: FormSections): number => {
    const orderOfA = sectionOrdering[a] || undeclaredPriority
    const orderOfB = sectionOrdering[b] || undeclaredPriority
    return orderOfA - orderOfB
  }
}

let updatedValues: Record<string, boolean> = {}

function resetUpdatedValues() : void {
  updatedValues = {}
}

const useRegistrationStore = function (): RegistrationStoreAccessors {
  const { newRegistration, keys, getField } = useNamespacedGetters<RegistrationGetters>(store, "registration", [
    "newRegistration",
    "keys",
    "getField"
  ])

  const { updateField } = useNamespacedMutations(store, "registration", ["updateField"])

  const mapComputed: Record<string, WritableComputedRef<string | boolean | undefined>> = {}

  for (const key of keys?.value ?? []) {
    mapComputed[key] = computed<string | boolean | undefined>({
      get: () => getField.value(key.toString()),
      set: value => {
        updateField({ path: key.toString(), value })
        updatedValues[key] = true
      }
    })
  }

  mapComputed["zipCode"] = computed({
    get: () => getField.value("zipCode"),
    set: value => {
      if (value?.length === 5 && value != getField.value("zipCode")) {
        setRegionCity(value)
      }
      updateField({ path: "zipCode", value: value ?? "" })
      updatedValues["zipCode"] = true
    }
  })

  const regionDropdownDisabled: ComputedRef<boolean> = computed(() => {
    const zipCode = getField.value("zipCode")
    return (zipCode.length as number) < 5
  })

  async function setRegionCity(zip: string): Promise<void> {
    try {
      const response = await getState(zip)

      if (response.region) {
        updateField({ path: "stateCode", value: response.region })
        updatedValues["stateCode"] = true
      }
      if (response.city) {
        updateField({ path: "city", value: response.city })
        updatedValues["city"] = true
      }
    } catch (e: any) {
      /* istanbul ignore if */
      if (isRuntimeError(e)) {
        logError(e)
      }
    }
  }

  async function submitAction(): Promise<string> {
    switch (getField.value("flowAction")) {
    case FlowActions.LeadsUpdate: {
      return updateLeadsRegistration()
    }
    case FlowActions.Continue: {
      return updateRegistration()
    }
    default: {
      return updateRegistration()
    }
    }
  }

  // This code should be removed when we fully switch to using the Orchestrator to manage Step1 submit.
  async function completeRegistration(): Promise<void> {
    // TODO: figure out how to get vuex-map-fields be type aware
    const stateCd: string = getField.value("stateCode") ?? ""
    const recaptchaResult = await checkReCaptcha("account_create")
    try {
      await canStateRegister(stateCd)
    } catch (err: any) {
      const message = `Sorry - we do not offer loans in ${stateCd}.`
      return Promise.reject(message)
    }
    let account: AccountResponse | null

    try {
      account = await createAccount(recaptchaResult)
    } catch (e: any) {
      if (typeof e === "object" && "errors" in e) {
        if ("brand" in e["errors"]) {
          router.push({ name: ExternalRouteLinks.LoginExistingCustomer })
          return Promise.reject(undefined)
        }

        if (typeof e["errors"] === "object") {
          const response: string = parseErrors(e["errors"])
          if (response.includes("account")) {
            router.push({ name: ExternalRouteLinks.LoginExistingCustomer })
            return Promise.reject(undefined)
          }

          if ("error_code" in e) {
            if (e["error_code"] == "PreapprovalSsnMismatch") {
              const { preapprovalSsnMismatchesCount, isPreapprovalMode } = useRegistrationStore()
              if (!isPreapprovalMode) {
                // Sanity check
                logWarning(e, "Data inconsistency, received preapproval mismatch error, but isPreapprovalMode=false")
              }
              preapprovalSsnMismatchesCount.value += 1
              analytics.track("reg1preapprovalmatcherror")
            }
          }
          return Promise.reject(response)
        }
      }
      logError(e, genericErrorMessage.message)
      return Promise.reject(genericErrorMessage)
    }
    updatedValues = {}
    setAccountData(account)
    mapComputed.isFromReg1.value = true

    const { authenticateCustomer } = useLoginServer()
    const email = getField.value("emailAddress") ?? ""
    const passwd = getField.value("password") ?? ""

    try {
      const result = await checkReCaptcha()
      const response = await authenticateCustomer(email, passwd, result)
      analytics.identify(account.account_id.toString() || getAdobeTrackingId() || account.uuid, {})

      try {
        await updateSeoTracking(CookieSeoTrackingStorage.getSeoTrackingId(), response.account_id)
        CookieSeoTrackingStorage.saveCookie({ seo_tracking_id: null, tracking_complete: true })
      } catch (error: any) {
        /* istanbul ignore if */
        if (isRuntimeError(error)) {
          logError(error)
        }
      }
    } catch (error: any) {
      genericErrorMessage.raw = error

      return Promise.reject(genericErrorMessage)
    }

    return Promise.resolve()
  }

  async function updateLeadsRegistration(): Promise<string> {
    try {
      await updateRegistration()
    } catch (error: any) {
      analytics.track("update_leads_registration_failed")
      logError(error)
      return Promise.reject(error)
    }

    try {
      await updateLeadsInterstitialSubmission(
        mapStoreToLeadsInterstitialRequest(
          getField.value("leadUUID") ?? "",
          getField.value("leadOfferId") ?? ""
        )
      )
    } catch (error: any) {
      analytics.track("leads_interstitial_submission_failed", { lead_uuid: getField.value("leadUUID") })
      logError(error)
    }
    // Ultimately this should return a next-route, when the Orchestrator supplies it.
    return Promise.resolve("")
  }

  async function updateRegistration(): Promise<string> {
    await useSessionStore().tryFetchIovationFraudForceFingerprint()
    let nextStep = ExternalRouteLinks.NewContract

    let account: AccountResponse | null

    // The presence of the Orchestrator flow ID is currently the way we tell if we should be passing
    // data through the Orchestrator in lieu of going straight to CNU.
    const { orchestratorFlow } = useSessionStore()

    const updatedFields = Object.keys(updatedValues)
    if (orchestratorFlow.value && orchestratorFlow.value !== "") {
      try {
        const orchestratorResponse = await submitStep2Data<AccountResponse>(orchestratorFlow.value, updatedFields)
        // I am asking codecov to ignore this block because codecov doesn't recognize
        // that the functional tests are exercising it.
        /* istanbul ignore if */
        if (orchestratorResponse.errors.length !== 0 || orchestratorResponse.data.errors) {
          analytics.track("reg2orchestratorerror")
          const errorString = orchestratorResponse.data.errors.base || orchestratorResponse.data.errors.brand
          logError(errorString)
          // If Orchestrator says to stay on the current page, then display the error text as an alert.
          if (orchestratorResponse.route == "reg2") {
            throw errorString
          }
          // Otherwise just go where the Orchestrator tells us to.
          router.push({ name: translateOrchestratorRoute(orchestratorResponse.route) })
          throw undefined
        }
        account = orchestratorResponse.data.account

        if(orchestratorResponse.route !== "await_send_email_confirmation" && account) {
          account.approval_id = orchestratorResponse.data.approval?.id
        }

        nextStep = translateOrchestratorRoute(orchestratorResponse.route)
      } catch (e: any) {
        logError(e, genericErrorMessage.message)
        throw genericErrorMessage
      }
    } else {
      // This code should be removed when we fully switch to using the Orchestrator to process Step2 submit.
      try {
        account = await updateAccount(updatedFields)
      }
      catch (error: unknown) {
        if (error && typeof error === "object" && "errors" in error) {
          if (typeof error["errors" as keyof typeof error] === "object") {
            throw parseErrors(error["errors" as keyof typeof error])
          }
        }
        throw genericErrorMessage
      }
    }
    if (account) {
      setAccountData(account)
      updatedValues = {}
    }
    return nextStep
  }

  function parseErrors(json: Record<string, unknown>): string {
    const errors = [...flattenJson(json)].join("\n")
    return errors || genericErrorMessage.message
  }

  function flattenJson(json: Record<string, unknown>): Set<string> {
    const result = new Set<string>()

    Object.keys(json).forEach(key => {
      switch (Object.prototype.toString.call(json[key])) {
      case "[object String]":
        result.add(json[key] as string)
        break
      case "[object Object]":
      case "[object Array]": {
        const value = (json[key] as Record<string, unknown>)
        if (Object.keys(value).length) {
          flattenJson(value).forEach((value) => result.add(value))
        }
        break
      }
      }
    })

    return result
  }

  function resetPassword(): void {
    mapComputed["password"].value = ""

    delete updatedValues.password
  }

  function resetState(): void {
    type RegistrationAttributes = keyof Registration
    const nonResetAttributes = new Set<RegistrationAttributes>(["preapprovalLocatorMismatchesCount"])
    const registration: RegistrationState = newRegistration.value;
    (Object.keys(registration) as Array<RegistrationAttributes>)
      .filter(k => !nonResetAttributes.has(k))
      .forEach((key: string) => {
        updateField({ path: key, value: registration[key as keyof RegistrationState] })
        updatedValues = {}
      })
  }

  function setAccountData(account: AccountResponse): void {
    useAccountStore().setAccountData(account)
    mapComputed.isPreapprovalMode.value = !!account.preapproval_flg
  }

  function setIsFromReg1(isFromReg1: boolean): void {
    mapComputed.isFromReg1.value = isFromReg1
  }

  function setDataFromAccount(): void {
    const {
      paymentFrequency,
      nextPaydate,
      followingPaydate
    } = useAccountStore()

    mapComputed.paymentFrequency.value = paymentFrequency.value
    mapComputed.nextPaydate.value = nextPaydate.value
    mapComputed.followingPaydate.value = followingPaydate.value
  }

  async function checkReCaptcha(action = "login"): Promise<string> {
    let token

    try {
      token = await VerifyReCaptcha(action)
    }
    catch (e: any) {
      logWarning(e, "failed_to_load_recaptcha")
      return Promise.reject(genericErrorMessage)
    }
    return token as string
  }

  return {
    ...mapComputed,
    formSectionComparator: buildFormSectionComparator(),
    newRegistration,
    regionDropdownDisabled,
    completeRegistration,
    updateLeadsRegistration,
    updateRegistration,
    setRegionCity,
    resetPassword,
    resetState,
    resetUpdatedValues,
    setAccountData,
    submitAction,
    setIsFromReg1,
    setDataFromAccount,
    checkReCaptcha
  } as RegistrationStoreAccessors
}

export { useRegistrationStore, RegistrationStoreAccessors }
