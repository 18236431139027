var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CnuProvider,
    [
      _c(
        _setup.GlobalConfig,
        { attrs: { "external-route-links": _setup.ExternalRouteLinks } },
        [
          _c(
            _setup.VApp,
            [
              _c(
                _setup.VMain,
                [
                  _setup.showGondola ? _c(_setup.Gondola) : _vm._e(),
                  _c("router-view"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }