import { Module } from "vuex"
import { RootState } from "@/store"
import { sessionState, SessionState } from "./state"
import { sessionGetters } from "./getters"
import { updateField, getField } from "vuex-map-fields"

const sessionModule: Module<SessionState, RootState> = {
  namespaced: true,
  state: sessionState,
  getters: { ...sessionGetters, getField },
  mutations: { updateField }
}

export default sessionModule
export * from "./state"
export * from "./getters"
export * from "./composables"
