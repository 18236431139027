import { ApiPublicService } from "@/lib/api/public-service"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"

import {
  PreapprovalRequest,
  Preapproval, PreapprovalResponseError
} from "generated-cnuapp-types"

import analytics, { logError } from "@/lib/analytics"

const apiEndpoint = "/v4/public/preapprovals/lookup"

export async function findPreapproval (preapprovalRequest : PreapprovalRequest): Promise<Preapproval|PreapprovalResponseError> {
  const { apiPublicServiceDepreciated } = useApiServicesDepreciated()
  const cnuPublicApiService: ApiPublicService = apiPublicServiceDepreciated.value!

  try {
    const response : Preapproval|PreapprovalResponseError = await cnuPublicApiService.post<PreapprovalRequest, Preapproval|PreapprovalResponseError>(apiEndpoint, preapprovalRequest)

    return Promise.resolve(response)
  } catch(e: any) {
    if (e.response?.status == 404 && e.response?.data?.error_code == "preapproval_not_found") {
      analytics.track("preapproval_not_found", { errors: e.errors, error_code: e.error_code })
      return Promise.reject(e.response.data as PreapprovalResponseError)
    }
    logError(e)
    const responseError = { error_code: "no_response", message: "Sorry, we had a problem on our side. We're looking into it." }
    return Promise.reject(responseError as PreapprovalResponseError)
  }
}

