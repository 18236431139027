import Vue from "vue"
import Vuex from "vuex"
import registrationModule, { registrationState, RegistrationState } from "@/store/registration"
import accountModule, { AccountStore } from "@/store/account"
import offersModule, { OffersState } from "@/store/offers"
import sessionModule, { sessionState, SessionState } from "@/store/session"
import approvalModule, { ApprovalStore } from "@/store/approval"
import contractModule, { ContractState } from "@/store/contract"
import VuexPersistence from "vuex-persist"

Vue.use(Vuex)
// Need to set vue instance variables to show devtools in staging
// vuex is just another vue instance
Vue.config.devtools = process.env.VUE_APP_ENVIRONMENT !== "production"

interface State {
  registration: RegistrationState
  account: AccountStore
  session: SessionState
  offers: OffersState
  approval: ApprovalStore
  contract: ContractState
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { ssn: _ssn, password: _pass, ...persistKeys } = registrationState
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { accountId: _accountId, ...persistSessionKeys } = sessionState

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: (state: State) => {
    const persistState: Record<string, unknown> = {}
    for (const key of Object.keys(persistKeys)) {
      persistState[key] = state.registration[key as keyof RegistrationState]
    }
    const persistSessionState: Record<string, unknown> = {}
    for (const key of Object.keys(persistSessionKeys)) {
      persistSessionState[key] = state.session[key as keyof SessionState]
    }
    return {
      registration: {
        ...persistState
      },
      account: state.account,
      session: { ...persistSessionState },
      offers: state.offers,
      approval: state.approval,
      contract: state.contract
    }
  }
})

const store = new Vuex.Store<State>({
  modules: {
    registration: registrationModule,
    account: accountModule,
    session: sessionModule,
    offers: offersModule,
    approval: approvalModule,
    contract: contractModule
  },
  plugins: [vuexSession.plugin]
})

export default store
type Store = typeof store

// TODO: Not yet fully clear what purpose it serves...
type RootState = unknown
export { State, RootState, Store }

export * from "@/store/registration"
export * from "@/store/account"
export * from "@/store/session"
export * from "@/store/offers"
export * from "@/store/approval"
export * from "@/store/contract"
