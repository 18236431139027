import { ApiPublicService } from "@/lib/api/public-service"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import analytics, { logError } from "@/lib/analytics"

const apiEndpoint = "/v4/public/zip_codes"

export async function getState (zipCode: string): Promise<ZipCodeResponse> {
  const { apiPublicServiceDepreciated } = useApiServicesDepreciated()
  const cnuPublicApiService: ApiPublicService = apiPublicServiceDepreciated.value!

  try {
    const response = await cnuPublicApiService.get<ZipCodeResponse>(apiEndpoint, zipCode)
    return Promise.resolve(response)
  } catch(err: any) {
    if (err.response?.status == 404) {
      analytics.track("no_state_found_for_postal_code", { error_message: err.message, zip_code: zipCode })
      return Promise.reject(err.response.data)
    }
    logError(err)
    return Promise.reject(err)
  }
}

export type ZipCodeResponse = {
  city: string
  region: string
  error: string
}
