import { Module } from "vuex"
import { RootState } from "@/store"
import { registrationState, RegistrationState } from "./state"
import { registrationGetters } from "./getters"
import { updateField, getField } from "vuex-map-fields"

const registrationModule: Module<RegistrationState, RootState> = {
  namespaced: true,
  state: registrationState,
  getters: { ...registrationGetters, getField },
  mutations: { updateField }
}

export default registrationModule
export * from "./state"
export * from "./getters"
export * from "./composables"
