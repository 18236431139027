import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import { internalRoutes } from "@/router/routes"
import { externalRoutes } from "@/router/external-routes"
import { useRegistrationStore, useSessionStore, useAccountStore } from "@/store"
import { getAccount } from "@/lib/api/secure/accounts-service"
import analytics, { logError } from "@/lib/analytics"
import { getAdobeTrackingId } from "@/lib/analytics/helpers"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes: constructRoutes(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    if (to.hash) return { selector: to.hash }

    return { x: 0, y: 0 }
  }
})

function constructRoutes(): Array<RouteConfig> {
  return [
    ...Object.values(internalRoutes),
    ...Object.values(externalRoutes)
  ]
}

router.beforeEach(async (to, from, next) => {
  let account
  try {
    const { isFromReg1, setAccountData } = useRegistrationStore()
    const { isLoggedIn } = useSessionStore()
    const { hasPulledData } = useAccountStore()
    if(isLoggedIn.value && !isFromReg1.value){
      if (!hasPulledData.value) {
        account = await getAccount()
        // TODO Create page loading experience, even though call below should be fast
        setAccountData(account)
      }
    }
  }
  catch(e: any) {
    logError(e)
  }
  if(account) {
    analytics.identify(account.account_id.toString() || getAdobeTrackingId() || account.uuid, {})
  } else {
    analytics.identify(getAdobeTrackingId() || analytics.user("userId"), {})
  }
  next()
})

router.beforeEach((to, from, next) => {
  if (process.env.VUE_APP_ENVIRONMENT == "production") return next()

  const { query } = to
  for (const [param, value] of Object.entries(query)) {
    const regex = /(force-opty-)(.+)/.exec(param)
    if (!regex) continue
    const optyKey = regex[2]
    analytics.plugins.optimizely.setForcedVariation(optyKey, value)
  }

  for (const [param, value] of Object.entries(query)) {
    const regex = /(force-optyfeature-)(.+)/.exec(param)
    if (!regex) continue
    const variableKey = regex[2]
    analytics.plugins.optimizely.setForcedFeatureVariable(variableKey, value)
  }

  next()
})

router.afterEach(async (from, to) => {
  analytics.page()
  analytics.track("routingChange", { from_path: from.path, to_path: to.path })
})

export default router
export * from "./routes"
export * from "./routing-helpers"
export * from "./external-routes"
