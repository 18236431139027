import Analytics from "analytics"
import * as storage from "@analytics/storage-utils"
import buildConsolePlugin from "@/lib/analytics/plugin-console"
import { AnalyticsInstance } from "@/lib/analytics/analytics-types"
import buildAdobePlugin from "@/lib/analytics/plugin-adobe"
import buildOptimizelyPlugin from "@/lib/analytics/plugin-optimizely"
import buildDatadogPlugin from "@/lib/analytics/plugin-datadog"
import Cookies from "js-cookie"

import {
  buildPreventDuplicateErrors,
  buildAnalyticsLogHandlers,
  buildStorage
} from "@/lib/analytics/helpers"

declare let window: { analytics: unknown } & Window

const isDeployedMode = process.env.VUE_APP_ENVIRONMENT === "production" || process.env.VUE_APP_ENVIRONMENT === "staging"

// Used when local manual testing / debugging required
const IS_ALL_PLUGINS_ENABLED = process.env.VUE_APP_ANALYTICS_IS_ALL_PLUGINS_ENABLED === "true"


// See Demo for API: https://analytics-demo.netlify.app/
const analytics = Analytics({
  app: process.env.VUE_APP_CLIENT_ID,
  debug: !isDeployedMode,
  plugins: [
    buildConsolePlugin({ enabled: IS_ALL_PLUGINS_ENABLED || !isDeployedMode, maxSeverity: "info" }),
    buildAdobePlugin({ enabled: IS_ALL_PLUGINS_ENABLED || isDeployedMode }),
    buildOptimizelyPlugin({ enabled: IS_ALL_PLUGINS_ENABLED || isDeployedMode }),
    buildDatadogPlugin({ enabled: IS_ALL_PLUGINS_ENABLED || isDeployedMode })
    // TODO add SEO tracking
  ]
}) as AnalyticsInstance

// This was previously in the configuration above, not actually doing anything.  This now sets the storage to use this instead anon id wrapped code.
analytics.storage = buildStorage(storage, Cookies)

// Error specific configuration

analytics.on("trackStart", buildPreventDuplicateErrors(150, 5))

const analyticsLogHandlers = buildAnalyticsLogHandlers(analytics)
export const logError = analyticsLogHandlers.logError
export const logWarning = analyticsLogHandlers.logWarning
export const logInfo = analyticsLogHandlers.logInfo

// Useful for debug purposes
window.analytics = analytics

export default analytics

