import { ApiSecureService } from "@/lib/api/secure-service"
import { useApiServicesDepreciated } from "@/lib/api/composition/use-api-service"
import { logError } from "@/lib/analytics"

export async function updateSeoTracking (seoTrackingId: string, accountId: string): Promise<string> {
  const { apiSecureServiceDepreciated } = useApiServicesDepreciated()
  const cnuSecureApiService: ApiSecureService = apiSecureServiceDepreciated.value!

  try {
    const apiEndpoint = `/v4/secure/accounts/${accountId}/seo_trackings/${seoTrackingId}`
    const response = await cnuSecureApiService.put<string, string>(apiEndpoint, "")
    return Promise.resolve(response)
  } catch(err: any) {
    /* istanbul ignore if */
    if(err?.response?.status === 401) {
      return Promise.reject(err)
    }
    // TODO: we should have none of those
    if(err)
      logError(err)
    //TODO Use code from JSON returned from an app as well to be more precise that this is a graceful error
    if (err?.response?.status === 422) {
      return Promise.reject(err.response.data)
    }
    return Promise.reject(err)
  }
}
