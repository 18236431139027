import CookieJWTStorage from "@/lib/api/cookie-jwt-storage"
import parseJwt from "@/lib/util/parse-jwt"

interface SessionState {
  accountId: string,
  stateCd: string,
  iovationFraudForceFingerprint: string,
  campaign: string,
  gclid: string,
  medium: string,
  network: string,
  adposition: string,
  clickId: string,
  device: string,
  keyword: string,
  locPhysicalMs: string,
  matchType: string,
  orderItemId: string,
  trkid: string,
  trafficSource: string,
  groupCd: string,
  sourceTypeCd: string,
  sessionId: string,
  contractId: string,
  orchestratorFlow: string
}

const newSession = function() : SessionState {
  let accountId = ""
  const token = CookieJWTStorage.getAccessToken()
  if(token){
    const parsedToken = parseJwt(token)
    accountId = String(parsedToken.user_id || "")
  }

  return {
    accountId,
    stateCd: "",
    iovationFraudForceFingerprint: "",
    campaign: "",
    gclid: "",
    medium: "",
    network: "",
    adposition: "",
    clickId: "",
    device: "",
    keyword: "",
    locPhysicalMs: "",
    matchType: "",
    orderItemId: "",
    trkid: "",
    trafficSource: "",
    groupCd: "",
    sourceTypeCd: "",
    sessionId: "",
    contractId: "",
    orchestratorFlow: ""
  }
}


const sessionState: SessionState = newSession()

export { sessionState, SessionState, newSession }
